<template>
	<span :style="{ 'margin-left': size / 8 + 'px' }">
		<span
			v-for="(deptPerson, index) in department.people"
			:key="`dept-person-${department.id}-${deptPerson.person_id}`"
		>
			<span v-if="index < max">
				<person-avatar
					v-if="people[deptPerson.person_id]"
					:person="people[deptPerson.person_id]"
					:size="size || 22"
					class="shadow-sm position-relative border border-white"
					:style="{ 'z-index': 50 - index, 'margin-left': size / -4 + 'px' }"
					data-bs-toggle="tooltip"
					:title="
						[people[deptPerson.person_id].name, deptPerson.title, roles[deptPerson.role].name]
							.filter(Boolean)
							.join(', ')
					"
				></person-avatar>
			</span>
		</span>
		<span
			v-if="department.people.length > max"
			class="rounded-circle fw-bold shadow-sm text-center position-relative d-inline-block border border-white bg-info-lighter"
			:style="{
				width: size + 'px',
				'line-height': size + 'px',
				'margin-left': size / -4 + 'px',
			}"
		>
			{{ department.people.length - max }}
		</span>
	</span>
</template>

<script>
import PersonAvatar from '@/components/PersonAvatar.vue'
import { mapState } from 'vuex'
import { Tooltip } from 'bootstrap'

export default {
	name: 'DepartmentPeople',
	components: { PersonAvatar },
	props: {
		department: {
			type: Object,
			required: true,
		},
		size: {
			type: Number,
			default: 22,
		},
		max: {
			type: Number,
			default: 99,
		},
	},
	computed: {
		...mapState(['people', 'roles']),
	},
	created() {
		this.$store.dispatch(
			'loadPeople',
			(this.department.people || []).map(dp => dp.person_id)
		)

		setTimeout(() => {
			document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
				new Tooltip(el)
			})
		}, 1100)
	},
}
</script>
