<template>
	<div class="venue-overview">
		<div v-if="venue">
			<venue-header :venue="venue"></venue-header>

			<div v-if="states.created" class="row justify-content-center mb-5">
				<div class="col-md-10 col-lg-8">
					<div class="card">
						<div class="card-body">
							<h5 class="text-center mb-3">
								<u>{{ venue.name }}</u> venue is created 🎉
							</h5>

							<p class="mb-2">
								To be ready to accept bookings, a few more details are needed:
							</p>

							<ul class="nicer-list">
								<li>Add description &amp; pictures</li>
								<li>Choose the reservation mode: predefiend slots or custom time</li>
								<li>Add pricing</li>
								<li>Add fields for that appear on booking page</li>
							</ul>

							<p class="card-text text-center">
								<router-link :to="`/${j.slug}/venues/${venue.slug}/settings`" class="btn btn-primary">
									Continue to description &amp; pictures →
								</router-link>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="venue.status === 'draft'" class="alert alert-warning text-dark mb-4">
				The venue is in <u>DRAFT</u> mode. Add the required details, then publish to make it available to book
				<button class="btn btn-sm btn-success ms-3" data-bs-toggle="modal" data-bs-target="#venue-public-id">
					Publish venue
				</button>
			</div>
			<div v-else-if="venue.status === 'archived'" class="alert alert-danger text-dark mb-4">
				The venue is in <u>ARCHIVED</u> mode. To re-enable reservations make it public again
				<button class="btn btn-sm btn-success ms-3" @click="publishVenue">
					Publish venue
				</button>
			</div>

			<div v-if="venue.status !== 'draft'" class="card bg-light mb-4">
				<div class="card-body">
					<div class="row align-items-center text-center">
						<div class="col">
							<h5 class="mb-1 text-warning">
								{{ venueStats ? venueStats.pending_count : '-' }}
							</h5>
							<p class="subtitle mb-0">Pending bookings</p>
						</div>
						<div class="col">
							<h5 class="mb-1 text-success">{{ venueStats ? venueStats.approved_count : '-' }}</h5>
							<p class="subtitle mb-0">Upcoming reservations</p>
						</div>
						<div class="col">
							<h5 class="mb-1">
								<span v-if="venueStats">{{ venueStats.payments_amount | currency }}</span>
								<span v-else class="text-muted">-</span>
							</h5>
							<p class="subtitle mb-0">Money made so far</p>
						</div>
						<div class="col">
							<p class="mb-0">
								📍
								<a target="_blank" :href="venue.location.url">
									{{ venue.location.name }}
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-6 col-lg-5 mb-3">
					<div class="card">
						<div class="card-body p-3">
							<div
								v-if="venue.images.length"
								id="venueImagesCarousel"
								class="carousel slide mb-3"
								data-bs-ride="carousel"
							>
								<div class="carousel-inner">
									<div
										class="carousel-item"
										v-for="(image, index) in venue.images"
										:key="`venue-${image.id}`"
										:class="{ active: index === 0 }"
									>
										<img :src="image.src" class="img-fluid rounded-1" :alt="image.title" />
									</div>
									<div v-show="venue.images.length > 1">
										<button
											class="carousel-control-prev me-0"
											type="button"
											data-bs-target="#venueImagesCarousel"
											data-bs-slide="prev"
										>
											<span
												class="carousel-control-prev-icon bg-light rounded"
												aria-hidden="true"
											></span>
										</button>
										<button
											class="carousel-control-next"
											type="button"
											data-bs-target="#venueImagesCarousel"
											data-bs-slide="next"
										>
											<span
												class="carousel-control-next-icon bg-light rounded"
												aria-hidden="true"
											></span>
										</button>
									</div>
								</div>
							</div>
							<div v-else class="mb-3 text-center text-muted">
								<p>No pictures yet 😕</p>
								<router-link
									type="button"
									:to="{
										path: `/${j.slug}/venues/${venue.slug}/settings`,
										hash: '#images',
									}"
									class="btn btn-sm btn-outline-primary "
								>
									Add pictures
								</router-link>
							</div>

							<div
								class="mb-3"
								v-html="
									readMore
										? enrichContent(venue.description)
										: firstParagraphOrWords(venue.description, 25)
								"
							></div>

							<div v-if="readMore">
								<h5>Amenities</h5>
								<div class="mb-3" v-html="venue.amenities_text"></div>

								<h5>Checking in</h5>
								<div class="mb-3" v-html="venue.message_check_in"></div>

								<h5>Checking out</h5>
								<div class="mb-3" v-html="venue.message_check_out"></div>
							</div>

							<p
								v-if="
									venue.amenities_text ||
										venue.message_check_in ||
										venue.message_check_out ||
										venue.description.split(' ').length > 25
								"
								class="card-text text-end"
							>
								<button class="btn btn-sm bg-light text-primary" @click="readMore = !readMore">
									{{ readMore ? 'Show less' : 'Show more' }}
								</button>
							</p>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-7">
					<h4>Reservation Calendar</h4>

					<div class="card mb-3">
						<div class="card-body">
							<VenueCalendarAdmin :venue="venue" :options="calendarOptions" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>

		<!-- Publish venue modal -->
		<div class="modal fade venue-public" id="venue-public-id" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<form @submit.prevent="publishVenue">
						<div v-if="venue" class="modal-content">
							<div class="modal-header">
								<h5 class="modal-title">Publish venue</h5>
								<button
									type="button"
									class="btn-close"
									data-bs-dismiss="modal"
									aria-label="Close"
								></button>
							</div>
							<div class="modal-body">
								<p class="mb-3">Ready to accept reservations for this venue?</p>

								<table class="table">
									<tbody>
										<tr>
											<td>Online payments</td>
											<td>
												<small v-if="departments">{{
													deptPayments ? '✅' : `🔴 Not ready for "${venueDepartment.name}"`
												}}</small>
											</td>
										</tr>
										<tr>
											<td>Location</td>
											<td>✅</td>
										</tr>
										<tr>
											<td>Description</td>
											<td>
												<span v-if="venue.description">✅</span>
												<button
													v-else
													@click="goTo('settings')"
													class="btn btn-outline-primary btn-sm "
												>
													Add description
												</button>
											</td>
										</tr>
										<tr>
											<td>Amenities</td>
											<td>
												<span v-if="venue.amenities_text">✅</span>
												<button
													v-else
													@click="goTo('settings')"
													class="btn btn-outline-primary btn-sm "
												>
													Edit amenities
												</button>
											</td>
										</tr>
										<tr>
											<td>Check in/out message</td>
											<td>
												<span v-if="venue.message_check_in && venue.message_check_out">✅</span>
												<button
													v-else
													@click="goTo('settings')"
													class="btn btn-outline-primary btn-sm "
												>
													Edit messages
												</button>
											</td>
										</tr>
										<tr>
											<td>Pictures</td>
											<td>
												<span v-if="venue.images.length"
													>✅ {{ venue.images.length }} picture{{
														venue.images.length > 1 ? 's' : ''
													}}</span
												>
												<button
													v-else
													@click="goTo('settings')"
													class="btn btn-outline-primary btn-sm "
												>
													Add pictures
												</button>
											</td>
										</tr>
										<tr>
											<td>Availability</td>
											<td>
												<template v-if="venue.reservation_mode === 'slots'">
													<span v-if="venue.slots.length"
														>✅ {{ venue.slots.length }} slots</span
													>
													<button
														v-else
														@click="goTo('availability')"
														class="btn btn-outline-primary btn-sm "
													>
														Add slots
													</button>
												</template>
												<template v-else>
													<span>✅ Hour blocks</span>
												</template>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div class="modal-footer">
								<button
									type="submit"
									class="btn btn-primary"
									:disabled="
										!venue.description || !venue.images.length || !venueDepartment || !deptPayments
									"
								>
									Publish venue
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.carousel-control-next-icon:after {
	content: '>';
	font-size: 22px;
	color: #5e81f4;
}
.carousel-control-prev-icon:after {
	content: '<';
	font-size: 22px;
	color: #5e81f4;
}
</style>

<script>
import { Modal } from 'bootstrap'
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import heyGovApi, { handleResponseError } from '@/api.js'
import { enrichContent, firstParagraphOrWords, truncateString } from '@/utils.js'

import VenueCalendarAdmin from '@/components/venues/VenueCalendarAdmin'
import VenueHeader from './VenueHeader.vue'

export default {
	name: 'Venue',
	metaInfo() {
		return {
			title: this.venue?.name || this.$route.params.venueSlug,
		}
	},
	components: { VenueHeader, VenueCalendarAdmin },
	data() {
		return {
			states: {
				created: false,
			},
			venue: null,
			venueStats: null,
			state: 'loading',
			readMore: false,
			calendarOptions: {
				todayBtn: true, // Display button that returns you to today date
				includeRules: true, // Include rules in calendar for reservation
				eventsSize: 'col-md-12',
				calendarSize: 'col-md-12',
			},
			disabled: '',
			$modalPublish: null,
		}
	},
	computed: {
		...mapState(['j', 'departments']),
		...mapGetters(['currentRole']),
		venueDepartment() {
			return this.departments?.find(d => d.id == this.venue.department_id)
		},
		deptPayments() {
			return (
				this.venueDepartment.stripe_account_status === 'ready' ||
				this.venueDepartment.justifi_account ||
				this.venueDepartment.municipay_account ||
				this.venueDepartment.paygov_account
			)
		},
	},
	created() {
		if (this.$route.query.created) {
			this.states.created = true
		}

		if (this.currentRole === 'CITIZEN') {
			this.$router.replace(`/${this.j.slug}/venues/${this.$route.params.venueSlug}/info`)
		} else {
			this.getVenue()
			this.$store.dispatch('loadDepartments')
		}
	},
	updated() {
		this.$modalPublish = new Modal(document.getElementById('venue-public-id'))
		// If is in mounted() it doesn't get venue-public-id element !!!
	},
	methods: {
		enrichContent,
		firstParagraphOrWords,
		truncateString,

		getVenue() {
			this.states.venue = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}?expand=images`).then(
				({ data }) => {
					this.venue = data
					this.states.venue = 'loaded'
				},
				error => {
					Vue.toasted.error(`Error loading venue info (${error})`)
					this.states.venue = 'error'
				}
			)

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}/stats`).then(({ data }) => {
				this.venueStats = data
			}, handleResponseError(`Error loading venue stats ({error})`))
		},
		publishVenue() {
			heyGovApi
				.put(`/${this.j.slug}/venues/${this.venue.slug}`, {
					status: 'public',
				})
				.then(({ data }) => {
					this.venue.status = data.status
					this.$modalPublish.hide()
					Vue.toasted.success(`"${this.venue.name}" is now public`)
				}, handleResponseError(`Error publishing venue ({error})`))
		},
		goTo(path) {
			this.$modalPublish.hide()
			return this.$router.push(`/${this.j.slug}/venues/${this.venue.slug}/${path}`)
		},
	},
}
</script>
