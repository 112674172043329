<template>
	<div class="poll-thread-create">
		<h3 class="text-primary">Report an Issue in {{ j.name }}</h3>

		<div class="row justify-content-center my-3">
			<div class="col-11 col-sm-10 col-md-9 col-lg-8">
				<div class="heygov-progress mb-4">
					<div class="row">
						<div class="col col-6">
							<button
								v-if="steps[step].back"
								class="btn btn-sm"
								@click="step = steps[step].back || step"
								title="Back to previous step"
							>
								← {{ steps[step].backText }}
							</button>
							<p v-else class="mb-2">{{ steps[step].textLeft }}</p>
						</div>
						<div class="col col-6 text-end">
							<p class="mb-2">{{ steps[step].textRight }}</p>
						</div>
					</div>
					<div class="progress">
						<div class="progress-bar" :style="{ width: `${steps[step].percent}%` }"></div>
					</div>
				</div>

				<form v-if="step === 'message'" @submit.prevent="sendMessage">
					<div class="form-group mb-3">
						<label class="form-label" :for="`heygov-file-${thread.uuid}`"
							>Add a photo about the issue</label
						>

						<div class="row">
							<div v-for="file in threadFiles" :key="file.uuid" class="col col-4 position-relative">
								<button
									v-if="file.id"
									class="btn-file-remove"
									@click.prevent="removeFile(file)"
									title="Remove file"
								>
									X
								</button>
								<img :src="file.url || file.preview" :alt="file.name" class="img-fluid img-thumbnail" />
								<small v-if="file.status === 'error'" class="text-danger">Error uploading file</small>
							</div>
							<div class="col" :class="{ 'col-4': threadFiles.length }">
								<label
									class="heygov-file-upload"
									:for="`heygov-file-${thread.uuid}`"
									@dragover="dragover"
									@dragleave="dragleave"
									@drop="drop"
									@click="handlePhotoUpload"
								>
									<p class="heygov-file-upload-icon">
										<img
											src="https://files.heygov.com/assets/icon-thumbnail.png"
											alt="Upload file"
											width="30"
										/>
									</p>
									<small v-if="threadFiles.length" class="text-muted">Add photo</small>
									<div v-else>
										<p><small>By adding a photo you will help us resolve it faster</small></p>
										<p v-if="device.platform === 'web'">
											<small class="text-muted">Drop a file here, or click to browse</small>
										</p>
									</div>
								</label>
								<input
									type="file"
									multiple
									:id="`heygov-file-${thread.uuid}z`"
									@change="handleFileInput($event)"
									class="d-none"
								/>
							</div>
						</div>
					</div>

					<!--
					<p>{{ labelsInImages }}</p>
					1
					<ul>
						<li v-for="service in servicesByMatchedLabels.Top" :key="service.id">{{ service.name }} - {{ service.keywords }} - <strong>{{ service.matchedLabels }}</strong></li>
					</ul>
					2
					<ul>
						<li v-for="service in servicesByMatchedLabels.More" :key="service.id">{{ service.name }} - {{ service.keywords }} - <code>{{ service.matchedLabels }}</code></li>
					</ul>
					-->

					<div class="form-group mb-3">
						<label class="form-label" for="thread-message">Describe your issue</label>
						<textarea
							class="form-control"
							id="thread-message"
							rows="4"
							required
							v-model="message"
							placeholder="Tell us more about the issue"
						></textarea>
					</div>

					<div class="text-end">
						<button class="btn btn-primary" :disabled="filesAreUploading">Report issue</button>
					</div>
				</form>

				<form v-if="step === 'meta'" @submit.prevent="updateThreadMeta">
					<h3 class="text-center">Thank you for reporting this issue!</h3>
					<p class="text-center mb-4"><small>Care to give more info?</small></p>

					<div class="row justify-content-center">
						<div class="heygov-col heygov-col-75">
							<div class="form-group mb-3">
								<label class="form-label" for="thread-category">Which category fits the best?</label>
								<div v-if="states.showAllCategories || servicesByMatchedLabels.Top.length < 2">
									<select id="thread-category" class="form-control" v-model="thread.service_id">
										<option v-for="service in services" :key="service.id" :value="service.id">{{
											service.name
										}}</option>
									</select>
								</div>
								<div v-else>
									<span
										v-for="service in servicesByMatchedLabels.Top"
										:key="service.id"
										class="badge bg-info-lighter text-info me-1"
										:class="{
											'bg-info-lighter text-info': thread.service_id == service.id,
											'border border-info': thread.service_id != service.id,
										}"
										@click="thread.service_id = service.id"
										>{{ service.name }}</span
									>
									<span
										class="badge bg-info-lighter text-info border border-info"
										@click="states.showAllCategories = true"
										>Other</span
									>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="thread-location">Where is this happening?</label>

								<div class="input-group">
									<gmap-autocomplete
										@place_changed="setThreadLocationFromAutocomplete"
										:select-first-on-enter="true"
									>
										<template v-slot:default="slotProps">
											<input
												id="thread-location"
												class="form-control"
												ref="input"
												:value="thread.location.name"
												placeholder="Detailed location"
												v-on="slotProps.listeners"
											/>
										</template>
									</gmap-autocomplete>
									<button class="btn btn-sm" @click.prevent="myLocation" title="My current location">
										{{ states.geolocation === 'loading' ? '🌀' : '📍' }}
									</button>
								</div>

								<div v-if="thread.location && thread.location.lat">
									<muni-map
										:includeVenues="false"
										:includeDepartments="false"
										:height="250"
										:adjustZoom="+1"
										:markers="[
											{
												position: thread.location,
												icon: 'https://files.heygov.com/assets/icon-map-pin-new.svg',
												draggable: true,
											},
										]"
										@marker_dragend="setThreadLocationFromMapMarker"
									></muni-map>

									<p class="text-center text-muted">
										<small>Drag the pin on the map to choose another location.</small>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div class="text-end">
						<button class="btn btn-primary">Continue</button>
					</div>
				</form>

				<form v-else-if="step === 'person'" @submit.prevent="updateThreadPerson">
					<h3 class="text-center">Thanks, we got that too!</h3>
					<p class="text-center mb-4">Want to be notified when we fix it? Add your info below</p>

					<div class="row justify-content-center mb-2">
						<div class="col col-8">
							<div class="form-group mb-3">
								<label class="form-label" for="heygov-person-name">Your name</label>
								<input
									type="text"
									class="form-control"
									placeholder="Full name please"
									autocomplete="name"
									v-model="person.name"
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="heygov-person-email">Your email</label>
								<input
									type="email"
									class="form-control"
									placeholder="Email address"
									autocomplete="email"
									required
									v-model="person.email"
								/>
							</div>
						</div>
					</div>

					<div class="text-end">
						<button type="button" class="btn btn-sm btn-outline-primary mx-2" @click="step = 'sent'">
							Remain anonymous
						</button>
						<button class="btn btn-primary">Continue</button>
					</div>
				</form>

				<div v-else-if="step === 'sent'">
					<h3 class="text-center">We got all the details. Thank you!</h3>
					<p class="text-center mb-3">
						<span v-if="person.email">We’ll reply soon over email.</span>
						<span v-else>Check regulary Issue details to see progress.</span>
					</p>

					<p class="text-center mb-2">
						<img
							src="https://files.heygov.com/assets/illustration-pablo-success.jpg"
							width="250"
							alt="HeyGov Sent"
						/>
					</p>

					<div class="text-center">
						<router-link :to="`/${j.slug}`" class="btn btn-primary m-2"
							>Back to {{ j.type }} overview</router-link
						>
						<router-link :to="`/${j.slug}/threads/${thread.uuid}`" class="btn btn-outline-primary m-2"
							>View your Reported issue</router-link
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.progress {
	height: 7px;

	.progress-bar {
		height: 9px;
	}
}

.btn-file-remove {
	position: absolute;
	top: -9px;
	right: 0;

	width: 20px;
	background: pink;
	border: 0;
	border-radius: 50%;
	padding: 0;

	line-height: 20px;
	text-align: center;
	cursor: pointer;
}

.heygov-file-upload {
	border-radius: 8px;
	background: #fff;
	display: inline-block;
	width: 100%;
	height: 130px;
	text-align: center;
	cursor: pointer;
	margin-bottom: 0;
	border: 1px dashed #f0f0f0;
	transition: transform 0.2s ease-out;

	&.dragover {
		background: var(--heygov-blue-light);
		border-color: #666;
		transform: scale(1.05);
	}

	p {
		color: #4e4e4e;

		small {
			font-size: 14px;
			text-transform: none;
		}
	}

	p.heygov-file-upload-icon {
		font-size: 32px;
		line-height: 32px;
		margin: 25px auto 10px;
	}

	p:not(.heygov-file-upload-icon) {
		margin-bottom: 5px;
	}
}
</style>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { formatDistanceToNow } from 'date-fns'
import { getGoogleMapsAPI } from 'gmap-vue'
import { Geolocation } from '@capacitor/geolocation'
import { Camera, CameraResultType } from '@capacitor/camera'

import heyGovApi from '@/api.js'
import { generateId } from '@/utils.js'

import MuniMap from '@/components/MuniMap.vue'

export default {
	name: 'ThreadCreate',
	components: { MuniMap },
	data() {
		return {
			states: {
				geolocation: 'idle',
				showAllCategories: false,
			},
			steps: {
				message: {
					textLeft: "Let's get started!",
					textRight: 'Step 1 of 3',
					percent: 10,
					back: false,
				},
				meta: {
					textLeft: 'One step ahead',
					textRight: 'Step 2 of 3',
					percent: 40,
					back: 'message',
					backText: 'Issue details',
				},
				person: {
					textLeft: 'Almost done',
					textRight: 'Step 3 of 3',
					percent: 80,
					back: 'meta',
					backText: 'Issue info',
				},
				sent: {
					textLeft: 'Done',
					textRight: 'Thank you',
					percent: 100,
					back: false,
				},
			},
			step: 'message',
			thread: {
				uuid: generateId('is', 8),
				service_id: null,
				location: {
					name: '',
					lat: '',
					lng: '',
				},
				messages: [],
			},
			message: '',
			person: {
				name: '',
				email: '',
			},
			labelsInImages: [],
			geocoder: null,
		}
	},
	created() {
		this.$store.dispatch('loadServices')
		//this.loadPoll()
	},
	computed: {
		...mapState(['j', 'account', 'services', 'device']),
		...mapGetters(['auth', 'servicesByGroup']),
		threadFiles() {
			return this.thread.messages.filter(m => m.type === 'file')
		},
		filesAreUploading() {
			return this.thread.messages.filter(m => m.type === 'file' && m.status === 'uploading').length > 0
		},
		servicesByMatchedLabels() {
			let servicesByLabels = {
				Top: [],
				More: [],
			}

			this.services.forEach(s => {
				const words = this.message
					.split(/[ !.]+/g)
					.filter(w => w && w.length > 3)
					.map(w => w.toLowerCase())

				s.matchedLabels = (s.keywords || '').split(',').filter(k => {
					return this.labelsInImages.includes(k) || words.includes(k)
				})

				if (s.matchedLabels.length || s.name.includes('Accessibility')) {
					servicesByLabels.Top.push(s)
				} else {
					servicesByLabels.More.push(s)
				}
			})

			servicesByLabels.Top.sort((a, b) => b.matchedLabels.length - a.matchedLabels.length)

			return servicesByLabels
		},
		google: getGoogleMapsAPI,
	},
	methods: {
		formatDistanceToNow,

		dragover(event) {
			event.preventDefault()

			// Add some visual fluff to show the user can drop its files
			if (!event.currentTarget.classList.contains('dragover')) {
				event.currentTarget.classList.add('dragover')
			}
		},
		dragleave(event) {
			event.currentTarget.classList.remove('dragover')
		},
		drop(event) {
			event.preventDefault()
			this.dragleave(event)

			Array.from(event.dataTransfer.files).forEach((file, index) => {
				setTimeout(() => {
					this.uploadFile(file)
				}, 250 * index)
			})
		},
		handlePhotoUpload() {
			Camera.getPhoto({
				resultType: CameraResultType.DataUrl,
			}).then(
				re => {
					const blob = this.dataURItoBlob(re.dataUrl)
					const file = new File(
						[blob],
						`image-${this.device.platform}-${new Date().toISOString()}.${re.format}`,
						{ type: blob.type }
					)

					this.uploadFile(file, re.dataUrl)
				},
				error => {
					console.error(`Photo error ~ ${error.message}`)
				}
			)
		},
		dataURItoBlob(dataURI) {
			// convert base64/URLEncoded data component to raw binary data held in a string
			var byteString
			if (dataURI.split(',')[0].indexOf('base64') >= 0) byteString = atob(dataURI.split(',')[1])
			else byteString = unescape(dataURI.split(',')[1])

			// separate out the mime component
			var mimeString = dataURI
				.split(',')[0]
				.split(':')[1]
				.split(';')[0]

			// write the bytes of the string to a typed array
			var ia = new Uint8Array(byteString.length)
			for (var i = 0; i < byteString.length; i++) {
				ia[i] = byteString.charCodeAt(i)
			}

			return new Blob([ia], { type: mimeString })
		},
		handleFileInput($event) {
			Array.from($event.target.files).forEach((file, index) => {
				setTimeout(() => {
					this.uploadFile(file)
				}, 250 * index)
			})
		},
		uploadFile(file, dataUrl) {
			let threadFile = {
				id: null,
				message: file.name,
				type: 'file',
				data: {
					size: file.size,
					type: file.type,
				},
				preview: null,
				status: 'uploading',
			}

			// image preview
			if (dataUrl) {
				threadFile.preview = dataUrl
			} else {
				const reader = new FileReader()
				reader.onload = e => {
					threadFile.preview = e.target.result
				}
				reader.readAsDataURL(file)
			}

			// prepare file data
			var form = new FormData()
			form.append('file', file)
			form.append('message', threadFile.message)
			form.append('type', threadFile.type)

			// upload the file
			heyGovApi
				.post(`/${this.j.slug}/widget/threads/${this.thread.uuid}/files`, form, {
					params: { hey: this.device.uuid },
				})
				.then(
					({ data }) => {
						threadFile.id = data.id
						threadFile.data.url = data.data.url

						// set thread location, if found
						if (data.data.location) {
							this.thread.location = data.data.location
						}

						// update found labels
						if (data.data.labelAnnotations) {
							data.data.labelAnnotations.forEach(label => {
								this.labelsInImages.push(label.description.toLowerCase())
							})
						}

						threadFile.status = 'uploaded'
					},
					error => {
						Vue.toasted.show(`Error uploading file ~ ${error.message}`, { type: 'error' })
						threadFile.status = 'error'
					}
				)

			// add file in UI thread
			this.thread.messages.push(threadFile)
		},
		removeFile(file) {
			heyGovApi.delete(`/${this.j.slug}/widget/threads/${this.thread.uuid}/files/${file.id}`)
			this.thread.messages = this.thread.messages.filter(m => m.id !== file.id)
		},
		sendMessage() {
			let message = {
				message: this.message,
				type: 'text',
			}

			this.thread.messages.push(message)

			heyGovApi
				.post(`/${this.j.slug}/widget/threads/${this.thread.uuid}/messages`, message, {
					params: { hey: this.device.uuid, source: this.device.platform },
				})
				.then(
					({ data }) => {
						console.log(data)
					},
					error => {
						alert(`Error sending message ~ ${error.message}`)
					}
				)

			this.step = 'meta'
		},

		geocode() {
			if (!this.geocoder) {
				this.geocoder = new this.google.maps.Geocoder()
			}

			this.geocoder.geocode(
				{ location: { lat: this.thread.location.lat, lng: this.thread.location.lng } },
				(results, status) => {
					if (status == 'OK') {
						this.thread.location.name = results[0].formatted_address
					} else {
						alert(`Oops, couldn't convert coordinates to address ~ ${status}`)
					}
				}
			)
		},
		myLocation() {
			this.states.geolocation = 'loading'

			Geolocation.getCurrentPosition().then(
				position => {
					this.thread.location.name = `${position.coords.latitude},${position.coords.longitude}`
					this.thread.location.lat = position.coords.latitude
					this.thread.location.lng = position.coords.longitude
					this.geocode()

					this.states.geolocation = 'idle'
				},
				error => {
					alert(`Oops, couldn't get your location ~ ${error.message || error}`)
					this.states.geolocation = 'error'
				}
			)
		},
		setThreadLocationFromAutocomplete(place) {
			if (place.formatted_address.includes(place.name)) {
				this.thread.location.name = place.formatted_address
			} else {
				this.thread.location.name = `${place.name}, ${place.formatted_address}`
			}
			this.thread.location.lat = place.geometry.location.lat()
			this.thread.location.lng = place.geometry.location.lng()
		},
		setThreadLocationFromMapMarker(e) {
			this.thread.location.lat = e.latLng.lat()
			this.thread.location.lng = e.latLng.lng()
			this.geocode()
		},

		updateThreadMeta() {
			const params = {
				hey: this.device.uuid,
				silent: 1,
			}

			heyGovApi
				.put(
					`/${this.j.id}/threads/${this.thread.uuid}`,
					{
						location: JSON.stringify(this.thread.location),
						service_id: this.thread.service_id,
					},
					{ params }
				)
				.catch(error => {
					console.log(`Error updating thread ~ ${error.message}`)
				})

			this.step = this.auth ? 'sent' : 'person'
		},

		updateThreadPerson() {
			heyGovApi
				.post(`/${this.j.id}/threads/${this.thread.uuid}/person`, this.person, {
					params: { hey: this.device.uuid },
				})
				.then(
					() => {
						/* no auto auth
				if (data.auth) {
					this.$store.commit('setAuth', data.auth.token)
					this.$store.commit('setAuthJurisdictions', data.auth.jurisdictions)
					this.$store.commit('setCurrentJurisdiction', data.auth.jurisdictions[0])
				}
				*/

						this.step = 'sent'
					},
					error => {
						Vue.toasted.show(error.message, { type: 'error' })
					}
				)
		},
	},
}
</script>
