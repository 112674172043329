<template>
	<div class="poll-detail">

		<div v-if="poll">
			<h2 class="text-primary">{{ poll.title }} <small v-if="currentRole === 'ADMIN'" class="badge">{{ poll.status }}</small></h2>

			<ul class="nav nav-tabs mb-4">
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}`" class="nav-link">Straw poll page</router-link>
				</li>
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}/edit`" class="nav-link disabled">Edit</router-link>
				</li>
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}/responses`" class="nav-link active">Responses <span class="badge bg-light text-gray">{{ poll.responses }}</span></router-link>
				</li>
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}/summary`" class="nav-link">Summary</router-link>
				</li>
			</ul>

			<div class="bg-white rounded mb-2 p-2">
				<div class="row align-items-center">
					<div class="col-6">
						{{ answers.length || '-' }} answers collected in {{ formatDistanceToNow(new Date(poll.created_at)) }}
					</div>
					<div class="col-6 text-end">
						<router-link :to="`/${j.slug}/polls/${poll.slug}?newResponse=yes`" class="btn btn-sm btn-outline-primary ms-2">➕ Add response</router-link>
						<a :href="`${apiUrl + j.slug}/polls/${poll.slug}/answers-csv`" class="btn btn-sm btn-outline-dark ms-2">↓ Download CSV</a>
					</div>
				</div>
			</div>

			<div class="has-max-width mb-4" style="overflow-x: auto">
				<table class="table" style="width: 150vw">
					<tr>
						<th class="border-0"></th>
						<th v-for="question in poll.questions" :key="question.id" class="border-0" style="min-width: 250px">
							<span v-html="doMarkdown(question.title)"></span>
						</th>
						<th class="border-0" style="min-width: 200px">Device / Browser</th>
						<th class="border-0" style="min-width: 100px">IP</th>
					</tr>
					<tr>
						<td class="border-0 pt-0"></td>
						<td v-for="question in poll.questions" :key="question.id" class="border-0 pt-0">
							<div v-if="responseStats[question.id] && question.type === 'boolean'" class="progress" style="height: 14px">
								<div class="progress-bar bg-success" role="progressbar" :style="{width: `${responseStats[question.id].yes / answers.length * 100}%`}">{{ responseStats[question.id].yes }} yes</div>
								<div class="progress-bar bg-danger" role="progressbar" :style="{width: `${responseStats[question.id].no / answers.length * 100}%`}">{{ responseStats[question.id].no }} no</div>
							</div>
							<div v-else-if="responseStats[question.id] && question.type === 'text'" class="progress" style="height: 14px">
								<div class="progress-bar bg-info" role="progressbar" :style="{width: `${responseStats[question.id] / answers.length * 100}%`}">{{ responseStats[question.id] }} text answers</div>
							</div>
							<p v-else-if="responseStats[question.id]" class="mb-0"><pre>{{ responseStats[question.id] }}</pre></p>
						</td>
						<td class="border-0 pt-0">
							<div v-if="responseStats.devices" class="progress" style="height: 14px">
								<div class="progress-bar bg-primary" role="progressbar" :style="{width: `${responseStats.devices.mobile / answers.length * 100}%`}" title="Phones">{{ responseStats.devices.mobile }} phone(s)</div>
								<div class="progress-bar bg-secondary" role="progressbar" :style="{width: `${responseStats.devices.tablet / answers.length * 100}%`}" title="Tablets">{{ responseStats.devices.tablet }} tablet(s)</div>
								<div class="progress-bar bg-info" role="progressbar" :style="{width: `${responseStats.devices.desktop / answers.length * 100}%`}" title="Desktop">{{ responseStats.devices.desktop }} desktop(s)</div>
							</div>
						</td>
						<td class="border-0 pt-0"></td>
					</tr>
					<tr v-for="(answer, index) in answers" :key="answer.id">
						<td>
							<p class="mb-1"><strong>{{ index + 1 }}.</strong></p>
							<p class="mb-1">{{ new Date(answer.created_at).toLocaleDateString() }}</p>
							<p class="mb-0">{{ new Date(answer.created_at).toLocaleTimeString('en', { timeStyle: 'short' }) }}</p>
						</td>
						<td v-for="question in poll.questions" :key="question.id">
							<span v-if="answer.answersByQuestionId[question.id]">
								<span v-if="question.type === 'boolean'">
									<span v-if="answer.answersByQuestionId[question.id] == 1" class="badge text-success bg-success-lighter font-size-16">Yes</span>
									<span v-else class="badge text-danger bg-danger-lighter font-size-16">No</span>
								</span>
								<span v-else-if="question.type === 'text'">"{{ answer.answersByQuestionId[question.id] }}"</span>
								<span v-else-if="question.type === 'contact'">
									<p class="mb-1"><span class="text-muted">Name:</span> {{ answer.answersByQuestionId[question.id].name }}</p>
									<p class="mb-1"><span class="text-muted">Email:</span> {{ answer.answersByQuestionId[question.id].email }}</p>
									<p class="mb-0"><span class="text-muted">Address:</span> <a v-if="answer.answersByQuestionId[question.id].address" :href="`https://www.google.com/maps/place/${answer.answersByQuestionId[question.id].address}/`" target="_blank">{{ answer.answersByQuestionId[question.id].address }}</a></p>
								</span>
								<pre v-else>{{ answer.answersByQuestionId[question.id] }}</pre>
							</span>
						</td>
						<td>
							<code v-if="answer.from_user_agent">{{ [`${answer.user_agent.device.vendor || ''} ${answer.user_agent.device.model || ''}`, answer.user_agent.os.name, `${answer.user_agent.browser.name} ${answer.user_agent.browser.major || ''}`].map(s => s.trim()).filter(Boolean).slice(0, 2).join(' / ') }}</code>
						</td>
						<td>
							<span v-if="answer.from_ip"><span class="text-break">{{ answer.from_ip }}</span> (US)</span>
						</td>
					</tr>
				</table>
			</div>

		</div>

		<div v-if="state === 'loading'" class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>

	</div>
</template>

<script>
import heyGovApi from '@/api.js'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
//import { pick } from 'lodash'
import { formatDistanceToNow } from 'date-fns'

export default {
	name: 'Poll',
	components: { },
	data() {
		return {
			state: 'idle',
			states: {
				shortDescription: true,
			},
			poll: null,
			answers: [],
			responseStats: {},
		}
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.push('/')
		} else {
			this.loadPoll()
		}
	},
	computed: {
		...mapState([ 'j', 'account', 'apiUrl' ]),
		...mapGetters([ 'auth', 'currentRole' ]),
	},
	methods: {
		formatDistanceToNow,
		loadPoll() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.slug}/polls/${this.$route.params.pollSlug}`).then(({ data }) => {
				//data.questions = data.questions

				this.poll = data
				this.state = 'idle'

				this.loadAnswers()
			}, error => {
				Vue.toasted.show(error, { type: 'error' })
				this.state = 'error'
			})
		},
		loadAnswers() {
			heyGovApi.get(`${this.j.slug}/polls/${this.$route.params.pollSlug}/answers`).then(({ data }) => {
				this.answers = data

				this.doStats()
			}, error => {
				Vue.toasted.show(`Couldn't load answers ~ ${error}`, { type: 'error' })
				this.state = 'error'
			})
		},
		doStats() {

			// stats from answers
			this.poll.questions.forEach(question => {
				const answers = this.answers
									.map(a => a.answers) // return only answer object
									.map(a => a.find(answerQuestion => answerQuestion.id === question.id)) // find response only for current question
									.map(a => a.answer) // return answer value

				if (question.type === 'boolean') {
					this.responseStats[question.id] = {
						yes: answers.filter(a => a == 1).length,
						no: answers.filter(a => a == 0).length,
					}
				} else if (question.type === 'text') {
					this.responseStats[question.id] = answers.length
				}
			})

			// stats from UA
			const devices = this.answers.map(a => a.user_agent.device.type || 'desktop')
			this.responseStats.devices = {
				mobile: devices.filter(d => d === 'mobile').length,
				tablet: devices.filter(d => d === 'tablet').length,
				desktop: devices.filter(d => d === 'desktop').length,
			}

		},
		doMarkdown(html) {

			// TODO add markdown parser

			html = html.replace(/__([a-z0-9 ]+)__/gi, '<u>$1</u>')

			return html
		},
	},
}
</script>
