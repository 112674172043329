<template>
	<div id="payment-element-properties" class="p-1 mb-1">
		<!-- Element Payment settings -->
		<div class="row mb-2">
			<!-- Department payment -->
			<div class="form-group col-md-5">
				<label for="payment-department" class="ms-2">Department</label>
				<select
					class="form-select"
					id="payment-department"
					@change="resetPaymentMethods()"
					v-model="current.data.paymentDepartment"
				>
					<option value="">Choose...</option>
					<option v-for="(department, index) in activeDepartments" :key="index" :value="department.id">
						{{ department.name }} {{ departmentsWithOnlinePayments.includes(department.id) ? '💳' : '' }}
					</option>
				</select>
			</div>
			<!-- #Department payment -->

			<!-- Price -->
			<div class="form-group col-md-5">
				<label for="price" class="ms-2">
					Price
					<a
						data-bs-toggle="tooltip"
						title="This price will be used if there no calculated price from Payment logic"
					>
						<font-awesome-icon role="button" :icon="['fas', 'info-circle']" class="fa-fw text-dark" />
					</a>
				</label>
				<div class="input-group mb-3">
					<select class="form-select" id="payment-department" v-model="current.data.currency">
						<option value="usd">
							$ (USD)
						</option>
						<option value="eur" disabled>
							€ (EUR)
						</option>
					</select>

					<input
						type="text"
						class="form-control"
						inputmode="numeric"
						v-model="current.data.price"
						id="price"
						aria-label="Price"
						aria-describedby="currency-addon"
					/>
				</div>
			</div>
			<!-- #Price -->

			<!-- Quantity -->
			<div class="form-group col-md-2">
				<label for="quantity" class="ms-2">
					Quantity
					<a
						role="button"
						data-bs-toggle="tooltip"
						title="This field is calculated automatically if there is Payment logic"
					>
						<font-awesome-icon :icon="['fas', 'info-circle']" class="fa-fw text-dark" />
					</a>
				</label>
				<input
					type="number"
					class="form-control"
					v-model="current.data.quantity"
					id="quantity"
					min="0"
					max="100"
				/>
			</div>
			<!-- #Quantity -->
		</div>

		<div class="row">
			<div class="col form-group mb-3">
				<label for="payment-type" class="form-label">
					Payment methods
					<span data-bs-toggle="tooltip" title="Select one or more payment methods">
						<font-awesome-icon :icon="['fas', 'info-circle']" class="text-dark" />
					</span>
				</label>

				<div>
					<div class="form-check form-check-inline">
						<input
							class="form-check-input"
							type="checkbox"
							id="payment-online"
							value="online"
							v-model="current.data.paymentType"
							:disabled="isOptionDisabled()"
						/>
						<label class="form-check-label" for="payment-online">
							Online payment
						</label>
					</div>
					<div class="form-check form-check-inline">
						<input
							class="form-check-input"
							type="checkbox"
							id="payment-offline"
							v-model="current.data.paymentType"
							value="offline"
						/>
						<label class="form-check-label" for="payment-offline">
							In person (card/cash)
						</label>
					</div>
				</div>
			</div>

			<div v-if="current.data.paymentType.includes('online')" class="col form-group mb-3">
				<label for="payment-type-automatic" class="form-label">
					Online payment flow
					<span
						data-bs-toggle="tooltip"
						title="Select how payments are captured. Charge: money is taken after form is Submitted. Authorize: money is taken after form is Approved"
					>
						<font-awesome-icon :icon="['fas', 'info-circle']" class="text-dark" />
					</span>
				</label>

				<div>
					<div class="form-check form-check-inline">
						<input
							class="form-check-input"
							type="radio"
							id="payment-type-automatic"
							value="automatic"
							v-model="current.data.paymentFlow"
						/>
						<label class="form-check-label" for="payment-type-automatic">
							Charge
						</label>
					</div>
					<div class="form-check form-check-inline">
						<input
							class="form-check-input"
							type="radio"
							id="payment-type-manual"
							v-model="current.data.paymentFlow"
							value="manual"
						/>
						<label class="form-check-label" for="payment-type-manual">
							Authorize
						</label>
					</div>
				</div>
			</div>
		</div>

		<!-- Type of payment -->
	</div>
</template>

<script>
import { Tooltip } from 'bootstrap'

export default {
	name: 'PaymentElementProperties',
	props: ['current', 'activeDepartments'],
	data() {
		return {}
	},
	computed: {
		departmentsWithOnlinePayments() {
			return this.activeDepartments
				.filter(
					d =>
						d.stripe_account_status === 'ready' ||
						d.justifi_account_status === 'enabled' ||
						d.paygov_account ||
						d.municipay_account ||
						d.bridgepay_account
				)
				.map(s => s.id)
		},
	},

	mounted() {
		Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
			tooltipNode => new Tooltip(tooltipNode)
		)
	},
	methods: {
		// Disable payment methods if department ton't have stripe account activated
		isOptionDisabled() {
			return !this.departmentsWithOnlinePayments.includes(this.current.data.paymentDepartment)
		},

		resetPaymentMethods() {
			this.current.data.paymentType = []
		},
	},
}
</script>
