<template>
	<div v-if="!currentField.pdf">
		<div class="input-group mb-3">
			<span
				v-if="currentField.data.currency === 'USD'"
				class="input-group-text"
				:id="'currency-addon-' + currentField.id"
				>$</span
			>

			<input
				style="border-top-right-radius: 15px; border-bottom-right-radius: 15px"
				type="text"
				:class="'form-control ' + currentField.class"
				inputmode="numeric"
				v-model="currentField.value"
				:placeholder="currentField.placeholder"
				:id="'fsf-' + currentField.id"
				aria-label="Price"
				:aria-describedby="'currency-addon-' + currentField.id"
			/>

			<span
				v-if="currentField.data.currency === 'EUR'"
				class="input-group-text"
				:id="'currency-addon-' + currentField.id"
				>€</span
			>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Payment',
	props: ['currentField'],
	methods: {
		getRedactedFields() {
			if (this.$route.query.redacted) {
				return true
			}
		},
	},
}
</script>
