<template>
	<input
		v-if="!currentField.pdf"
		type="time"
		:class="'form-control ' + currentField.class"
		:placeholder="currentField.placeholder"
		v-model="currentField.value"
		:id="'fsf-' + currentField.id"
		:required="currentField.required == 1"
	/>

	<!-- PSD preview -->
	<div v-else>
		<div v-if="currentField.display !== 'form'">
			<label class="pdf-label form-label" for="">{{ currentField.label }}</label>
			<div v-if="getRedactedFields() && currentField.redacted" class="pdf-redacted"></div>
			<div v-else class="pdf-field">
				{{ currentField.value }}
			</div>
		</div>
			<small v-if="currentField.help" class="text-muted pdf-help">{{ currentField.help }}</small>
	</div>
</template>

<script>
export default {
	name: 'Time',
	props: ['currentField'],
	methods: {
		getRedactedFields() {
			if (this.$route.query.redacted) {
				return true
			}
		},
	},
}
</script>
