<template>
	<div class="hg-event-details-page" :style="generalStyle">
		<div v-if="states.event === 'loading'" class="rounded-1 bg-neutral-50 p-6 text-center">
			<span class="spinner-border spinner-border-sm text-primary" role="status"></span> Loading event..
		</div>
		<div v-else-if="states.event === 'loaded'">
			<div class="row">
				<div class="col">
					<h2>{{ event.name }}</h2>

					<span>
						<span v-if="!event.multi_day">
							{{
								event.starts_at | dateLocal(undefined, { timeZone: j.timezone, dateStyle: 'long' })
							}}</span
						>
						<span v-if="event.all_day"><strong class="text-uppercase ms-2">All day</strong></span>
						<span v-if="!event.all_day && !event.multi_day">
							@
							{{ getTime(event.starts_at) }}
							<span v-if="event.metadata && !event.metadata.hide_end_time">
								→
								{{ getTime(event.ends_at) }}</span
							>
						</span>
						<span v-if="event.multi_day && !event.all_day" class="d-inline">
							{{ event.starts_at | dateLocal(undefined, { timeZone: j.timezone, dateStyle: 'long' }) }}
							@
							{{ getTime(event.starts_at) }}

							<span v-if="event.metadata && !event.metadata.hide_end_time">
								→
								{{ event.ends_at | dateLocal(undefined, { timeZone: j.timezone, dateStyle: 'long' }) }}
								@
								{{ getTime(event.ends_at) }}
							</span>

							<strong v-if="event.days_length > 1" class="ms-2">{{ event.days_length + 1 }} days</strong>
						</span>
					</span>
					<!-- Event icon -->
					<span
						v-if="event.parent || event.type === 'recurrent'"
						class="badge bg-success-lighter text-success ms-2"
					>
						{{ event.parent ? event.parent.recurrence.frequency : event.recurrence.frequency }}
						<font-awesome-icon :icon="['fas', 'arrows-rotate']" />
					</span>
				</div>
				<div class="col-auto">
					<span class="d-inline-flex add-to-calendar-custom me-2">
						<div class="dropdown">
							<button
								class="btn btn-sm btn-dark btn-dropdown-toggle"
								type="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<font-awesome-icon :icon="['fas', 'calendar-alt']" />
								Add to calendar
							</button>
							<ul class="dropdown-menu">
								<li>
									<a
										class="dropdown-item"
										target="_blank"
										:href="
											`https://www.google.com/calendar/render?action=TEMPLATE&amp;text=${event.name}&amp;ctz=${j.timezone}&amp;dates=${startDate}/${endDate}&amp;details=${event.description}&amp;recur=${eventRecurrenceRules}`
										"
										>Google</a
									>
								</li>
								<li>
									<a
										class="dropdown-item"
										target="_blank"
										:href="
											`data:text/calendar;charset=utf-8,BEGIN:VCALENDAR%0D%0AVERSION:2.0%0D%0ABEGIN:VEVENT%0D%0ADTSTAMP:${startDate}%0D%0ADTSTART:${startDate}%0D%0ADTEND:${endDate}%0D%0ADESCRIPTION:${
												event.description
											}%0D%0ASUMMARY:${event.name}%0D%0ALOCATION:${
												event.location && event.location.address ? event.location.address : ''
											}%0D%0ASTATUS:${
												event.status
											}%0D%0A${eventRecurrenceRules}%0D%0ASEQUENCE:0%0D%0AEND:VEVENT%0D%0AEND:VCALENDAR`
										"
										>iCal</a
									>
								</li>
							</ul>
						</div>
					</span>
					<a
						type="button"
						:href="`/${j.slug}/events-embed`"
						class="btn btn-sm btn-primary"
						@click="backButtonPostMessage"
					>
						<font-awesome-icon :icon="['fas', 'arrow-left']" />
						Back</a
					>
				</div>
			</div>

			<hr />

			<div class="row pb-3">
				<div class="col-md-7">
					<h5>Details</h5>
					<div class="pb-3">
						<label class="form-label">Date:</label>
						<div>
							{{ event.starts_at | dateLocal(undefined, { timeZone: j.timezone, dateStyle: 'long' }) }}
						</div>
					</div>
					<div class="pb-3">
						<label class="form-label">Time:</label>
						<div>
							<span v-if="event.all_day"><strong class="text-uppercase">All day</strong></span>
							<span v-if="event.multi_day" class="d-inline">
								{{
									event.starts_at | dateLocal(undefined, { timeZone: j.timezone, dateStyle: 'long' })
								}}
								@
								{{ getTime(event.starts_at) }}

								<span v-if="event.metadata && !event.metadata.hide_end_time">
									→
									{{
										event.ends_at
											| dateLocal(undefined, { timeZone: j.timezone, dateStyle: 'long' })
									}}
									@
									{{ getTime(event.ends_at) }}
								</span>
							</span>
							<span v-if="!event.all_day && !event.multi_day">
								{{ getTime(event.starts_at) }}
								<span v-if="event.metadata && !event.metadata.hide_end_time">
									→
									{{ getTime(event.ends_at) }}</span
								>
							</span>
						</div>
					</div>

					<div class="pb-3">
						<label class="form-label">
							{{ event.categories && event.categories.length > 1 ? 'Categories:' : 'Category:' }}</label
						>
						<span v-if="categories.length && event.categories && event.categories.length" class="d-block"
							><span v-for="cat in event.categories" :key="`event.category-id-${cat}`">
								{{ findCategoryValue(cat, 'name') || '' }}
								<small>
									<font-awesome-icon
										:icon="['fas', 'circle']"
										:style="{
											color: findCategoryAdditionalValue(cat, 'style', 'backgroundColor')
												? findCategoryAdditionalValue(cat, 'style', 'backgroundColor')
												: '',
										}"
									/>
								</small>
							</span>
						</span>
						<span v-else class="d-block">No category added</span>
					</div>
					<div v-if="event.description" class="pb-3">
						<div class="pb-3">
							<label class="form-label">Description:</label>
							<div v-html="event.description"></div>
						</div>
					</div>
				</div>
				<div v-if="event.image_url" class="col">
					<img :src="getPublicFileUrl(event.image_url)" :alt="event.name" class="img-fluid rounded-1" />
				</div>
			</div>

			<!-- Location -->
			<div v-if="event.metadata && event.metadata.hide_location === false" class="row">
				<div class="col-md-6 col-sm-12">
					<div class="pb-3">
						<label for="" class="form-label">Location:</label>
						<!-- Event location -->
						<span v-if="event.location && Object.keys(event.location).length !== 0" class="d-block">
							{{ event.location.address }}</span
						>
						<!-- Venue(s) location -->
						<span v-else-if="event.venues && event.venues.length && venues.length" class="d-block">
							<span v-for="ev in event.venues" :key="`${ev}-venue`" class="d-block my-2">
								<b>{{ venues.find(v => v.id === ev).name }}:</b>
								{{ venues.find(v => v.id === ev).location.name }}
							</span>
						</span>
						<!-- Jurisdiction location -->
						<span v-else class="d-block">{{ j.location.name }}</span>
					</div>
					<div class="pb-3">
						<muni-map
							class="mb-3 rounded rounded-1"
							:height="400"
							:markers="markers"
							:includeVenues="eventVenues"
							:includeDepartments="false"
							:event="event"
						></muni-map>
					</div>
				</div>
			</div>

			<div v-if="upcomingEvents.length" class="hg-upcoming-events-style">
				<h5>Upcoming events</h5>

				<div class="row row-cols row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 gx-3 mb-3">
					<div class="col my-2" v-for="ue of upcomingEvents" :key="`upcoming-events-${ue.pid}`">
						<router-link
							:to="`/${j.slug}/events-embed/${ue.pid}`"
							class="card shadow h-100 text-decoration-none text-dark"
						>
							<div class="card-body p-2">
								<div class="row mb-2 g-3">
									<div class="col-auto">
										<div class="border rounded-1">
											<div
												class="bg-primary-50 text-primary-400 text-center px-2"
												style="border-radius: 1rem 1rem 0 0; min-width: 55px"
											>
												<small>{{
													new Date(ue.starts_at).toLocaleString('default', {
														month: 'long',
														timeZone: j.timezone,
													})
												}}</small>
											</div>
											<div class="text-center fw-light fs-3">
												{{
													new Date(ue.starts_at).toLocaleString('default', {
														day: 'numeric',
														timeZone: j.timezone,
													})
												}}
											</div>
										</div>
									</div>
									<div class="col">{{ ue.name }}</div>
								</div>

								<p v-if="ue.categories && ue.categories.length" class="mb-2">
									Category:
									<span
										v-for="cat in ue.categories"
										:key="cat"
										class="badge bg-neutral-100 text-neutral-500"
										>{{ findCategoryValue(cat, 'name') }}</span
									>
								</p>
								<p v-if="ue.all_day" class="mb-2">
									All day
								</p>
								<p class="card-text">
									Time:
									<span v-if="ue.multi_day" class="d-inline">
										{{ getDate(ue.starts_at) }}

										<span v-if="ue.metadata && !ue.metadata.hide_end_time">
											→
											{{ getDate(ue.ends_at) }}</span
										>
									</span>
									<span v-else-if="!ue.all_day && !ue.multi_day">
										{{ getTime(ue.starts_at) }}
										<span v-if="ue.metadata && !ue.metadata.hide_end_time">
											→
											{{ getTime(ue.ends_at) }}</span
										>
									</span>
								</p>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="rounded-1 bg-danger-50 p-6 text-center">Error loading event details ({{ error }})</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'
import { format, getDate, parseISO, addYears, differenceInDays, addMonths } from 'date-fns'

import heyGovApi from '@/api.js'
import { truncateString, getPublicFileUrl } from '@/utils.js'

import MuniMap from '@/components/MuniMap.vue'

export default {
	name: 'EventEmbed',
	components: { MuniMap },
	data() {
		return {
			states: {
				event: 'loading',
				categories: 'loading',
				events: 'loading',
			},
			event: null,
			error: '',
			categories: [],
			mapOptions: {
				zoom: 12,
			},
			generalStyle: null,
			upcomingEvents: [],
		}
	},
	computed: {
		...mapState(['j', 'venues']),
		eventRecurrenceRules() {
			if (!this.event.recurrence && !this.event.parent) {
				return
			}

			let recurrenceObject
			if (this.event.recurrence) {
				recurrenceObject = this.event.recurrence
			} else {
				recurrenceObject = this.event.parent.recurrence
			}

			const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA']
			const frequency = recurrenceObject.frequency ? recurrenceObject.frequency.toUpperCase() : ''
			const interval = recurrenceObject.interval ? recurrenceObject.interval : ''
			const byDay = recurrenceObject.day_in_week ? days[recurrenceObject.day_in_week] : ''
			const every = this.getEventRecurrenceEvery()

			const until = recurrenceObject.ends_on
				? format(parseISO(recurrenceObject.ends_on), `yyyyMMdd'T'HHmmss'Z'`)
				: format(addYears(new Date(), 2), `yyyyMMdd'T'HHmmss'Z'`)

			const monthDay = !recurrenceObject.on_different_date ? getDate(parseISO(this.event.starts_at_local)) : ''

			let by = ''
			if (every && byDay) {
				by = `BYDAY=${every}${byDay}`
			} else if (monthDay) {
				by = `BYMONTHDAY=${monthDay}`
			}

			return `RRULE:FREQ=${frequency};INTERVAL=${interval};UNTIL=${until};${by}`
		},

		markers() {
			let locations = new Array()
			if (this.event.location && Object.keys(this.event.location).length !== 0) {
				locations.push({
					position: this.event.location,
				})
			}
			if (this.event.venues && this.venues && this.venues.length > 0) {
				for (const ev of this.event.venues) {
					locations.push({
						position: this.venues.find(v => v.id === ev).location,
					})
				}
			}
			if (!this.event.location && !this.event.venues) {
				locations.push({
					position: this.j.location,
				})
			}

			return locations
		},
		startDate() {
			if (!this.event.starts_at) {
				return
			}
			return format(parseISO(this.event.starts_at), `yyyyMMdd'T'HHmmss`, { timeZone: this.j.timezone })
		},
		endDate() {
			if (!this.event.ends_at) {
				return
			}
			return format(parseISO(this.event.ends_at), `yyyyMMdd'T'HHmmss`, { timeZone: this.j.timezone })
		},
		eventVenues() {
			if (this.event.venues && this.event.venues.length) {
				return true
			}
			return false
		},
		websiteUrl() {
			return '*'
		},
	},
	created() {
		this.loadCategories()
		this.loadEvent()
		this.$store.dispatch('getVenues')

		// set event style
		if (this.j.style) {
			this.generalStyle = this.j.style.event || this.j.style
		}
	},
	updated() {
		this.getIframeHeight()
	},
	methods: {
		getPublicFileUrl,
		truncateString,

		loadEvent() {
			this.states.event = 'loading'

			heyGovApi.get(`${this.j.slug}/events/${this.$route.params.id}`).then(
				({ data }) => {
					this.event = data

					// Check if all day event
					if (
						new Date(this.event.starts_at).toLocaleTimeString('en-US', { timeZone: this.j.timezone }) ==
							'12:00:00 AM' &&
						new Date(this.event.ends_at).toLocaleTimeString('en-US', { timeZone: this.j.timezone }) ==
							'11:59:00 PM'
					) {
						this.event.all_day = true
					}
					if (
						new Date(this.event.starts_at).toLocaleDateString('en-US', {
							timeZone: this.j.timezone,
						}) !== new Date(this.event.ends_at).toLocaleDateString('en-US', { timeZone: this.j.timezone })
					) {
						this.event.multi_day = true
						this.event.days_length = differenceInDays(
							parseISO(this.event.ends_at_local),
							parseISO(this.event.starts_at_local)
						)
					} else {
						this.event.multi_day = false
					}

					this.states.event = 'loaded'
					this.resetPostMessage()
					this.getIframeHeight()

					this.loadUpcomingEvents()
				},
				error => {
					if (error.response?.status === 404) {
						this.error = 'Event not found'
					} else {
						this.error = error.response?.data?.message || error.message
					}

					this.states.event = 'error'
				}
			)
		},

		loadCategories() {
			this.states.categories = 'loading'

			heyGovApi.get(`/${this.j.slug}/events/categories`).then(
				data => {
					this.categories = data.data
					this.states.categories = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading categories (${error.message})`)
					this.states.categories = 'error'
				}
			)
		},
		loadUpcomingEvents() {
			this.states.events = 'loading'

			const date_start = new Date()
			const date_end = addMonths(date_start, 12)
			const params = {
				date_start,
				date_end,
				limit: 6,
			}

			heyGovApi.get(`/${this.j.slug}/events?expand=parent`, { params }).then(
				({ data }) => {
					this.upcomingEvents = data
						.filter(e => e.pid !== this.$route.params.id)
						.map(e => {
							e.all_day =
								(new Date(e.starts_at).toLocaleTimeString('en-US', { timeZone: this.j.timezone }) ==
									'12:00:00 AM' &&
									new Date(e.ends_at).toLocaleTimeString('en-US', { timeZone: this.j.timezone })) ==
								'11:59:00 PM'

							return e
						})

					this.states.events = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading related events (${error.message})`)
					this.states.events = 'error'
				}
			)
		},
		backButtonPostMessage() {
			const targetWindow = window.opener || window.parent
			targetWindow.postMessage(
				{
					heygov_action: 'url-params',
					heygov_url_params: { view: 'heygov-events-month', jurisdiction: this.j.slug, back: true },
				},
				this.websiteUrl
			)
		},
		resetPostMessage() {
			// Send post message to iframe parent page
			const targetWindow = window.parent || window.opener
			targetWindow.postMessage(
				{
					heygov_action: 'url-params',
					heygov_url_params: {
						view: 'heygov-event',
						pid: this.event.pid,
						uuid: this.event.pid, //todo phase out uuid
						back: false,
					},
				},
				this.websiteUrl
			)
		},
		getEveryValue(value) {
			let every = ''
			if (value === 'first') {
				every = 1
			} else if (value === 'second') {
				every = 2
			} else if (value === 'third') {
				every = 3
			} else if (value === 'fourth') {
				every = 4
			} else if (value === 'fifth') {
				every = 5
			} else if (value === 'last') {
				every = -1
			}
			return every
		},
		getEventRecurrenceEvery() {
			if (this.event.recurrence && this.event.recurrence.every) {
				return this.getEveryValue(this.event.recurrence.every)
			} else if (this.event.parent && this.event.parent.recurrence && this.event.parent.recurrence.every) {
				return this.getEveryValue(this.event.parent.recurrence.every)
			} else {
				return ''
			}
		},
		getTime(time) {
			return new Date(time).toLocaleTimeString(undefined, {
				timeZone: this.j.timezone,
				hour: 'numeric',
				minute: 'numeric',
			})
		},
		getDate(date) {
			return new Date(date).toLocaleTimeString(undefined, {
				timeZone: this.j.timezone,
				month: 'numeric',
				day: 'numeric',
			})
		},
		getIframeHeight() {
			const targetWindow = window.opener || window.parent
			return targetWindow.postMessage(
				{
					element: '.heygov-events',
					heygov_action: 'set-element-style',
					styles: {
						height: `${document.body.scrollHeight}px`,
					},
				},
				this.websiteUrl
			)
		},
		findCategoryValue(cat, value) {
			const category = this.categories.find(category => category.id == cat)
			return category?.[value]
		},
		findCategoryAdditionalValue(cat, value, additionalValue) {
			return this.findCategoryValue(cat, value)?.[additionalValue]
		},
	},
	watch: {
		'$route.params.id': 'loadEvent',
	},
}
</script>

<style scoped>
.hg-event-details-page {
	padding-top: 80px;
}
@media (min-width: 1200px) {
	.hg-event-details-page {
		font-size: 1.5rem !important;
		line-height: 1.5;
	}
}
@media (min-width: 992px) {
	.hg-event-details-page {
		font-size: 1.2rem !important;
	}
}
</style>
