<template>
	<span
		class="venue-booking-datetime"
		:title="timezones ? 'Date/Time details' : `${venueBooking.starts_at} - ${venueBooking.ends_at}`"
		data-bs-toggle="popover"
		data-bs-trigger="hover focus"
		data-bs-placement="top"
	>
		{{
			new Date(venueBooking.starts_at).toLocaleString(locale, {
				dateStyle: 'medium',
				timeStyle: 'short',
				timeZone: j.timezone,
			})
		}}
		→
		{{
			new Date(venueBooking.ends_at).toLocaleString(locale, {
				dateStyle: sameDay ? undefined : 'medium',
				timeStyle: 'short',
				timeZone: j.timezone,
			})
		}}
	</span>
</template>

<script>
import { mapState } from 'vuex'
import { Popover } from 'bootstrap'
import { formatDistanceStrict, formatDistanceToNow } from 'date-fns'

export default {
	name: 'VenueBookingStatus',
	props: {
		venueBooking: Object,
		timezones: Boolean,
	},
	data() {
		return {
			locale: 'default', // TODO get from person prefs or region settings
			localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			timezoneShort: {
				'America/Chicago': 'CDT',
				'Europe/Bucharest': 'EEST',
			},
			deviceLabel: '🖥️',
		}
	},
	computed: {
		...mapState(['j', 'device']),
		sameDay() {
			return (
				new Date(this.venueBooking.starts_at).toLocaleDateString('default', {
					timeZone: this.j.timezone,
				}) ===
				new Date(this.venueBooking.ends_at).toLocaleDateString('default', {
					timeZone: this.j.timezone,
				})
			)
		},
	},
	created() {
		if (['ios', 'android'].includes(this.device.platform)) {
			this.deviceLabel = '📱'
		}
	},
	mounted() {
		if (this.timezones) {
			const that = this
			const booking = this.venueBooking

			new Popover(this.$el, {
				html: true,
				content() {
					return `
                        <p class="mb-2">
							<strong>${that.timezoneShort[that.j.timezone] || that.j.timezone} - Venue</strong>:
                            ${new Date(booking.starts_at).toLocaleString(that.locale, {
								dateStyle: 'medium',
								timeStyle: 'short',
								timeZone: that.j.timezone,
							})} - ${new Date(booking.ends_at).toLocaleTimeString(that.locale, {
						timeStyle: 'short',
						timeZone: that.j.timezone,
					})}
                        </p>
                        <p class="mb-2">
                            <strong>${that.timezoneShort[that.localTimezone] || that.localTimezone} ${
						that.deviceLabel
					}</strong>:
                            ${new Date(booking.starts_at).toLocaleString(that.locale, {
								dateStyle: 'medium',
								timeStyle: 'short',
							})} - ${new Date(booking.ends_at).toLocaleTimeString(that.locale, {
						timeStyle: 'short',
					})}
                        </p>
                        <p class="mb-2">
                            <strong>When:</strong> ${formatDistanceToNow(new Date(booking.starts_at), {
								addSuffix: true,
							})}
                        </p>
                        <p class="mb-0">
                            <strong>Duration:</strong> ${formatDistanceStrict(
								new Date(booking.starts_at),
								new Date(booking.ends_at)
							)}
                        </p>
                    `
				},
			})
		}
	},
}
</script>
