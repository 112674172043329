<template>
	<div class="modal-content">
		<form @submit.prevent="handleSubmit">
			<div class="modal-header">
				<h5 class="modal-title my-0">{{ title }}</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<p class="mb-4">
					Use this form to send a request for an agenda item. A person you specify will be notified via email
					or phone.
				</p>
				<div class="form-group mb-3">
					<label class="form-label" for="speaker-presenter">Send to</label>
					<div v-if="speaker">
						<person-link :id="speaker.id" :avatar="24">{{ speaker.name }}</person-link>
						<button class="btn btn-sm btn-outline-secondary ms-2" @click="clearSpeaker">
							Change
						</button>
						<div class="alert alert-info mt-2" role="alert">
							<i class="fas fa-info-circle me-2"></i>
							This person will be notified
							<template v-if="speaker.phone && speaker.email">
								via phone and email.
							</template>
							<template v-else-if="speaker.phone">
								via phone.
							</template>
							<template v-else-if="speaker.email">
								via email.
							</template>
						</div>
					</div>
					<!-- TODO support default speaker, doesn't work now -->
					<people-search
						v-else
						id="speaker-presenter"
						@person="setSpeaker"
						:limit="5"
						:required="false"
						:placeholder="'Name/email/phone of a person from jurisdiction'"
					></people-search>
				</div>
				<div class="form-row mb-3">
					<label class="form-label" for="agenda-item-comment">Comment</label>
					<textarea
						class="form-control"
						id="agenda-item-comment"
						v-model="comment"
						placeholder="Comment that only logged in clerks see, to share notes about an agenda item (optional)"
					></textarea>
				</div>
			</div>
			<div class="modal-footer">
				<button :disabled="loading || !speaker" class="btn btn-primary">Send request</button>
			</div>
		</form>
	</div>
</template>

<script>
import PeopleSearch from '@/components/PeopleSearch.vue'
import PersonLink from '@/components/PersonLink.vue'

export default {
	name: 'RequestItemForm',
	components: { PeopleSearch, PersonLink },
	props: {
		title: {
			type: String,
			required: true,
		},
		defaultText: {
			type: String,
			default: '',
		},
		defaultSpeakerId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			comment: this.defaultText,
			speaker: null,
			loading: false,
		}
	},
	methods: {
		handleSubmit() {
			this.loading = true
			this.$emit('submit', {
				comment: this.comment,
				speaker_id: this.speaker.id,
				after: () => {
					this.loading = false
					this.comment = this.defaultText
					this.speaker = null
				},
			})
		},
		setSpeaker(speaker) {
			this.speaker = speaker
		},
		clearSpeaker() {
			this.speaker = null
		},
	},
}
</script>
