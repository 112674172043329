<template>
	<div class="upgrade-page w-100">
		<div class="card">
			<div class="card-body text-center p-3">
				<!--HeyReserve-->
				<div v-if="$route.query.feature === 'venues'" class="mt-2">
					<div class="row align-items-center mx-2">
						<div class="col-sm-12 col-md-6 col-lg-6 text-start">
							<h4 class="text-start">
								Your current plan does not have
								<span class="text-hey-reserve">HeyReserve</span> added, at the moment
							</h4>
							<div>
								<p>
									Here’s a small taste of the things that you’re missing out on by not having a modern
									booking system:
								</p>
								<ul class="nicer-list">
									<li>
										Allow your residents to make online bookings of your municipal facilities
									</li>
									<li>Let them pay for the rental fee and deposit with a credit card</li>
									<li>Generate more revenue</li>
									<li>
										The public can see which facilities are available for booking at a glance
									</li>
									<li>Save your office mountains of time by offering an online booking system</li>
									<li>
										Auto-sends reservation rules &amp; check-in/out procedures on their reserved
										date
									</li>
									<li>
										1-click return a renter’s deposit after approved check-out
									</li>
								</ul>
							</div>
						</div>
						<div class="col-sm-12 col-md-6 my-5">
							<img
								src="https://files.heygov.com/assets/upgrade-hey-reserve.png"
								alt="HeyReserve"
								class="img-fluid"
							/>
						</div>
					</div>

					<div class="card bg-hey-reserve-light hey-reserve-upgrade mt-3">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12 col-md-6 mt-3">
									<div class="text-start">
										<h4>Unlock <span class="text-hey-reserve">HeyReserve</span></h4>
										<small>
											HeyReserve is a paid upgrade on your account. Contact a local government
											specialist to establish your needs and get a personalized quote.
										</small>
									</div>
								</div>
								<div class="col mt-3">
									<h6 class="text-uppercase">
										Learn how to upgrade to HeyReserve
									</h6>

									<div class="my-3">
										<a class="btn btn-primary px-5" @click="scrollToCalendar()">
											Book now!
										</a>
									</div>

									<p class="mb-4">
										<img
											src="https://secure.gravatar.com/avatar/8091d7ab979c2b6760c46bb0c45a4cb6?s=500"
											class="rounded-circle mx-2"
											width="50"
											alt="Dustin"
										/>

										<img
											src="https://ca.slack-edge.com/T02DNDXJ8-U0137RUMLHH-30ec98c29a17-512"
											class="rounded-circle mx-2"
											width="50"
											alt="Atanas"
										/>
										<img
											src="https://ca.slack-edge.com/T02DNDXJ8-U02J0J93XTN-3f63970eaeaa-512"
											class="rounded-circle mx-2"
											width="50"
											alt="Meagan"
										/>
									</p>
								</div>
							</div>

							<div class="card">
								<div class="card-body">
									<div
										class="calendly-inline-widget"
										data-url="https://calendly.com/d/2xz-yhn-thd/upgrade-your-heygov?primary_color=fcab1c"
										style="min-width:320px;height:700px;"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- HeyLicense -->
				<div v-else-if="$route.query.feature === 'forms'" class="mt-2">
					<div class="row align-items-center my-5 mx-2">
						<div class="col-sm-12 col-md-6 text-start">
							<h4 class="text-start">
								Your current plan does not have <span class="text-hey-licence">HeyLicense </span> added,
								at the moment
							</h4>
							<p>
								Here are some things you’re missing out on by not having a modern online form builder
								and workflow approval solution:
							</p>
							<div class="mb-3">
								<ul class="nicer-list">
									<li>Provide your residents with the convenience of a 100% paperless system</li>
									<li>
										Improve interoffice efficiency with a cloud-based application and reporting
										system
									</li>
									<li>
										Say goodbye to relying on paper and PDFs and shuffling them from department to
										department!
									</li>
									<li>
										Have a seamless payment system integrated for forms &amp; applications that
										require payment
									</li>
									<li>
										Invite other staff and departments to participate in a form’s online review and
										approval system
									</li>
									<li>
										Applicants can see how far along their application is in the review &amp;
										approval process
									</li>
									<li>
										You and your staff operates more efficiently by relying on a cloud-based
										application &amp; review process
									</li>
								</ul>
							</div>
						</div>
						<div class="col-sm-12 col-md-6">
							<img
								src="https://files.heygov.com/assets/upgrade-hey-licence.png"
								alt="HeyLicense"
								class="img-fluid"
							/>
						</div>
					</div>
					<div class="card bg-hey-licence-light hey-licence-upgrade px-3 pb-3">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12 col-md-6 mt-4">
									<div class="text-start">
										<h4>Unlock <span class="text-hey-licence">HeyLicense </span></h4>
										<small>
											HeyLicense is a paid upgrade. Contact a local government specialist to
											establish your needs and get a personalized quote.
										</small>
									</div>
								</div>
								<div class="col mt-4">
									<div>
										<h6 class="text-uppercase">Learn how to upgrade to HeyLicense</h6>
										<div class="my-3">
											<a class="btn btn-primary px-5" @click="scrollToCalendar()">
												Book now!
											</a>
										</div>
										<p class="mb-2">
											<img
												src="https://secure.gravatar.com/avatar/8091d7ab979c2b6760c46bb0c45a4cb6?s=500"
												class="rounded-circle mx-2"
												width="50"
												alt="Dustin"
											/>

											<img
												src="https://ca.slack-edge.com/T02DNDXJ8-U0137RUMLHH-30ec98c29a17-512"
												class="rounded-circle mx-2"
												width="50"
												alt="Atanas"
											/>
											<img
												src="https://ca.slack-edge.com/T02DNDXJ8-U02J0J93XTN-3f63970eaeaa-512"
												class="rounded-circle mx-2"
												width="50"
												alt="Meagan"
											/>
										</p>
									</div>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-body">
								<div
									class="calendly-inline-widget"
									data-url="https://calendly.com/d/2xz-yhn-thd/upgrade-your-heygov?primary_color=fcab1c"
									style="min-width:320px;height:700px;"
								></div>
							</div>
						</div>
					</div>
				</div>

				<!-- HeyGov Pay -->
				<div v-else-if="$route.query.feature === 'payments'" class="mt-2">
					<div class="row align-items-center my-5 mx-3">
						<div class="col-sm-12 col-md-6 text-start">
							<h4 class="text-start">
								Your current plan does not have
								<span class="text-heygov-pay">HeyGov Pay</span> added, at the moment
							</h4>
							<p>
								Here are some things you’re missing out on by not having a modern online payment
								solution:
							</p>

							<div class="mb-3">
								<ul class="nicer-list">
									<li>
										Provide your residents with a convenient way to accept credit card payments
									</li>
									<li>
										Constituents can pay for municipal services &amp; fees (e.g. utility payments,
										licenses, permits and taxes, etc.)
									</li>
									<li>
										Accept in person credit card payments with a POS (point-of-sale) terminal
									</li>
									<li>Send a one-off payment request via email or text</li>
								</ul>
							</div>
						</div>
						<div class="col-sm-12 col-md-6">
							<img
								src="https://files.heygov.com/assets/upgrad-heygov-pay.png"
								alt="HeyGovPay"
								class="img-fluid"
							/>
						</div>
					</div>
					<div class="card bg-heygov-pay-light heygov-pay-upgrade  pb-0">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12 col-md-6  mt-4">
									<div class="text-start">
										<h4>Unlock <span class="text-heygov-pay">HeyGov Pay</span></h4>
										<small
											>HeyGov Pay is a paid upgrade. Contact a local government specialist to
											establish your needs and get a personalized quote.</small
										>
									</div>
								</div>
								<div class="col mt-3">
									<h6 class="text-uppercase ">Learn how to upgrade to HeyGov Pay</h6>
									<div class="my-3">
										<a class="btn btn-primary px-5" @click="scrollToCalendar()">
											Book now!
										</a>
									</div>

									<p class="mb-4">
										<img
											src="https://secure.gravatar.com/avatar/8091d7ab979c2b6760c46bb0c45a4cb6?s=500"
											class="rounded-circle mx-2"
											width="50"
											alt="Dustin"
										/>
										<img
											src="https://ca.slack-edge.com/T02DNDXJ8-U0137RUMLHH-30ec98c29a17-512"
											class="rounded-circle mx-2"
											width="50"
											alt="Atanas"
										/>
										<img
											src="https://ca.slack-edge.com/T02DNDXJ8-U02J0J93XTN-3f63970eaeaa-512"
											class="rounded-circle mx-2"
											width="50"
											alt="Meagan"
										/>
									</p>
								</div>
							</div>

							<div class="card">
								<div class="card-body">
									<div
										class="calendly-inline-widget"
										data-url="https://calendly.com/d/2xz-yhn-thd/upgrade-your-heygov?primary_color=fcab1c"
										style="min-width:320px;height:700px;"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- Hey311 -->

				<div v-else-if="$route.query.feature === 'issues'" class="mt-2">
					<div class="row align-items-center my-5 mx-3">
						<div class="col-sm-12 col-md-6 text-start">
							<h4 class="text-start">
								Your current plan does not have <span class="text-hey-311">Hey311 </span> added, at the
								moment
							</h4>

							<p>
								Here are some things you’re missing out on by not having a public issue reporting and
								tracking system:
							</p>

							<div class="mb-3">
								<ul class="nicer-list">
									<li>
										Provide residents with an easy and fast reporting system via web or mobile
									</li>
									<li>
										Issues are automatically geolocated
									</li>
									<li>
										View a heatmap of all reported incidents
									</li>
									<li>Assign incidents to specific departments or individuals</li>
									<li>
										Track incidences and automatically keep the reporter abreast of status changes
									</li>
									<li>Create an instant work-order a department to resolve an issue</li>
									<li>Communication to the relevant parties throughout the process.</li>
								</ul>
							</div>
						</div>
						<div class="col-sm-12 col-md-6">
							<img
								src="https://assets.website-files.com/60c0bf119dbe7a93934b9782/60e88a308a1990cd87f96cf6_Untitled%20design.png"
								alt="Hey311"
								class="img-fluid"
							/>
						</div>
					</div>
					<div class="card bg-hey-311-light hey-311-upgrade px-3 pb-3">
						<div class="card-body">
							<div class="row">
								<div class="col-sm-12 col-md-6">
									<div class="text-start">
										<h4>Unlock <span class="text-hey-311">Hey311</span></h4>
										<small
											>Hey311 is a paid upgrade. Contact a local government specialist to
											establish your needs and get a personalized quote.</small
										>
									</div>
								</div>
								<div class="col mt-3">
									<h6 class="text-uppercase">Learn how to upgrade to Hey311</h6>
									<div class="my-3">
										<a class="btn btn-primary px-5" @click="scrollToCalendar()">
											Book now!
										</a>
									</div>
									<p class="mb-2">
										<img
											src="https://secure.gravatar.com/avatar/8091d7ab979c2b6760c46bb0c45a4cb6?s=500"
											class="rounded-circle mx-2"
											width="50"
											alt="Dustin"
										/>
										<img
											src="https://ca.slack-edge.com/T02DNDXJ8-U0137RUMLHH-30ec98c29a17-512"
											class="rounded-circle mx-2"
											width="50"
											alt="Atanas"
										/>
										<img
											src="https://ca.slack-edge.com/T02DNDXJ8-U02J0J93XTN-3f63970eaeaa-512"
											class="rounded-circle mx-2"
											width="50"
											alt="Meagan"
										/>
									</p>
								</div>
							</div>
						</div>
						<div class="card">
							<div class="card-body">
								<div
									class="calendly-inline-widget"
									data-url="https://calendly.com/d/2xz-yhn-thd/upgrade-your-heygov?primary_color=fcab1c"
									style="min-width:320px;height:700px;"
								></div>
							</div>
						</div>
					</div>
				</div>

				<div v-else>
					<div class="mt-5 mx-3">
						<h2 class="text-center mb-4">
							Get access to <span class="text-secondary">HeyGov</span> cutting-edge digital municipal
							features
						</h2>
						<div class="row">
							<div class="col-sm-12 col-md-6 text-center mt-5">
								<p>
									Want to learn what's cooking in HeyGov ovens? Want to see how your city will be
									taken into the future?
								</p>

								<div class="text-center mt-5">
									<div class="col mt-3">
										<h6 class="text-uppercase">Learn how to upgrade HeyGov</h6>
										<div class="my-3">
											<a class="btn btn-primary px-5" @click="scrollToCalendar()">
												Book now!
											</a>
										</div>
										<p class="mb-2">
											<img
												src="https://secure.gravatar.com/avatar/8091d7ab979c2b6760c46bb0c45a4cb6?s=500"
												class="rounded-circle mx-2"
												width="50"
												alt="Dustin"
											/>
											<img
												src="https://ca.slack-edge.com/T02DNDXJ8-U0137RUMLHH-30ec98c29a17-512"
												class="rounded-circle mx-2"
												width="50"
												alt="Atanas"
											/>
											<img
												src="https://ca.slack-edge.com/T02DNDXJ8-U02J0J93XTN-3f63970eaeaa-512"
												class="rounded-circle mx-2"
												width="50"
												alt="Meagan"
											/>
										</p>
									</div>
								</div>
							</div>
							<div class="col-sm-12 col-md-6">
								<img
									src="https://assets.website-files.com/60c0bf119dbe7a93934b9782/616093b7f68bd1419ac9b1d7_New%20Hero%20Image-p-1600.png"
									alt="HeyGov"
									class="img-fluid"
								/>
							</div>
							<div class="card">
								<div class="card-body">
									<div
										class="calendly-inline-widget"
										data-url="https://calendly.com/d/2xz-yhn-thd/upgrade-your-heygov?primary_color=fcab1c"
										style="min-width:320px;height:700px;"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.hey-reserve-upgrade {
	border: 1px solid #7879f1 !important;
}
.hey-licence-upgrade {
	border: solid 1px #22a6ff !important;
}
.heygov-pay-upgrade {
	border: 1px solid #02cea7 !important;
}
.hey-311-upgrade {
	border: 1px solid #ff5d46 !important;
}
</style>

<script>
export default {
	mounted() {
		let calendlyScript = document.createElement('script')
		calendlyScript.setAttribute('src', 'https://assets.calendly.com/assets/external/widget.js')
		document.head.appendChild(calendlyScript)
	},
	methods: {
		scrollToCalendar() {
			setTimeout(() => {
				document
					.querySelector('.calendly-inline-widget')
					.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
			}, 200)
		},
	},
}
</script>
