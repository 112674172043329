<template>
	<div id="name-input-properties" class="bg-light p-3 rounded-1 mb-3">
		<div class="row mb-3">
			<div class="col-5">Chose what fields need for name</div>

			<div class="form-group col-7">
				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						type="checkbox"
						v-model="current.data.firstName"
						id="name-first-name"
					/>
					<label class="form-check-label" for="name-first-name"> First Name </label>
				</div>
				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						type="checkbox"
						v-model="current.data.middleName"
						id="name-middle-name"
					/>
					<label class="form-check-label" for="name-middle-name"> Middle Name </label>
				</div>
				<div class="form-check form-check-inline">
					<input
						class="form-check-input"
						type="checkbox"
						v-model="current.data.lastName"
						id="name-last-name"
					/>
					<label class="form-check-label" for="name-last-name"> Last Name </label>
				</div>
			</div>
		</div>
		<!-- #Name input checkbox for choosing what field needs -->

		<!-- Labels for NameInputElement fields -->
		<div class="row">
			<div class="form-group col-md" v-show="current.data.firstName">
				<label for="label"> First name label </label>
				<input class="form-control form-control-sm" v-model="current.data.firstNameLabel" />
			</div>
			<div class="form-group col-md" v-show="current.data.middleName">
				<label for="label"> Middle name label </label>
				<input class="form-control form-control-sm" v-model="current.data.middleNameLabel" />
			</div>
			<div class="form-group col-md" v-show="current.data.lastName">
				<label for="label"> Last name label </label>
				<input class="form-control form-control-sm" v-model="current.data.lastNameLabel" />
			</div>
		</div>
		<!-- #Labes for NameInputElement fields -->
	</div>
</template>

<script>
export default {
	name: 'NameInputElementProperties',
	props: ['current'],
	methods: {},
}
</script>
