<template>
	<div>
		<div class="editor-toolbar">
			<a
				role="button"
				tabindex="0"
				class="btn btn-ai-actions"
				data-bs-container="body"
				data-bs-toggle="popover"
				data-bs-placement="bottom"
				titlee="ClerkMinutes AI Actions"
				data-bs-content="Select a section of the minutes (title + content) then click on one of the AI actions: Make Longer, Make Shorter, Describe Your Change, or Rewrite in style"
			>
				<font-awesome-icon :icon="['fas', 'magic-wand-sparkles']" class="text-primary-400 me-2" />
				<span class="text-ai">AI Actions</span>
			</a>

			<span class="toolbar-separator"></span>

			<template v-if="editor">
				<div class="dropdown">
					<button
						class="btn dropdown-toggle"
						type="button"
						id="editor-line-type"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						<font-awesome-icon :icon="['fas', 'heading']" />
					</button>
					<ul class="dropdown-menu" aria-labelledby="editor-line-type">
						<li>
							<button
								class="dropdown-item"
								@click="
									editor
										.chain()
										.focus()
										.toggleHeading({ level: 1 })
										.run()
								"
								:class="{ 'text-primary': editor.isActive('heading', { level: 1 }) }"
							>
								Heading 1
							</button>
						</li>
						<li>
							<button
								class="dropdown-item"
								@click="
									editor
										.chain()
										.focus()
										.toggleHeading({ level: 2 })
										.run()
								"
								:class="{ 'text-primary': editor.isActive('heading', { level: 2 }) }"
							>
								Heading 2
							</button>
						</li>
						<li>
							<button
								class="dropdown-item"
								@click="
									editor
										.chain()
										.focus()
										.toggleHeading({ level: 3 })
										.run()
								"
								:class="{ 'text-primary': editor.isActive('heading', { level: 3 }) }"
							>
								Heading 3
							</button>
						</li>
					</ul>
				</div>

				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleBold()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.toggleBold()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('bold') }"
				>
					<font-awesome-icon :icon="['fas', 'bold']" />
				</button>
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleItalic()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.toggleItalic()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('italic') }"
				>
					<font-awesome-icon :icon="['fas', 'italic']" />
				</button>
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleUnderline()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.toggleUnderline()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('underline') }"
				>
					<font-awesome-icon :icon="['fas', 'underline']" />
				</button>

				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleStrike()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.toggleStrike()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('strike') }"
				>
					<font-awesome-icon :icon="['fas', 'strikethrough']" />
				</button>
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleBlockquote()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.toggleBlockquote()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('blockquote') }"
				>
					<font-awesome-icon :icon="['fas', 'quote-right']" />
				</button>
				<button class="btn" @click="setLink" :class="{ 'btn-primary': editor.isActive('link') }">
					<font-awesome-icon :icon="['fas', 'link']" />
				</button>

				<span class="toolbar-separator"></span>

				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleBulletList()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('bulletList') }"
				>
					<font-awesome-icon :icon="['fas', 'list-ul']" />
				</button>
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.toggleOrderedList()
							.run()
					"
					:class="{ 'btn-primary': editor.isActive('orderedList') }"
				>
					<font-awesome-icon :icon="['fas', 'list-ol']" class="icon alt" />
				</button>

				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.setHorizontalRule()
							.run()
					"
				>
					<font-awesome-icon :icon="['fas', 'ruler-horizontal']" />
				</button>

				<span class="toolbar-separator"></span>

				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.undo()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.undo()
							.run()
					"
				>
					<font-awesome-icon :icon="['fas', 'undo']" />
				</button>
				<button
					class="btn"
					@click="
						editor
							.chain()
							.focus()
							.redo()
							.run()
					"
					:disabled="
						!editor
							.can()
							.chain()
							.focus()
							.redo()
							.run()
					"
				>
					<font-awesome-icon :icon="['fas', 'redo']" />
				</button>
			</template>
		</div>
		<my-bubble-menu
			ref="bubble-menu"
			:editor="editor"
			:onSetLink="setLink"
			:onDiscard="forceCloseBubbleMenu"
			@ai-action="handleAIAction"
			@make-longer-context-menu="$emit('make-longer-context-menu')"
			@make-shorter-context-menu="$emit('make-shorter-context-menu')"
			@describe-your-change-context-menu="$emit('describe-your-change-context-menu')"
		/>
		<editor-content :editor="editor" />
	</div>
</template>

<style lang="scss">
.editor-toolbar {
	display: flex;
	top: 68px;
	position: sticky;
	padding: 5px;
	background-color: rgba(250, 250, 250, 0.8);
	backdrop-filter: blur(10px);
	border: 1px solid #dedede;
	border-radius: 10px;
	z-index: 99;
	margin-bottom: 20px;

	.btn {
		padding: 5px 10px;
		border-radius: 5px;
		border: 0;

		&:hover {
			background-color: #e2e2e2;
		}
	}

	.toolbar-separator {
		display: inline-block;
		width: 1px;
		height: 34px;
		background-color: #dedede;
		margin: 0 5px;
	}
}

.tiptap {
	> * + * {
		margin-top: 0.75em;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-top: 1.7rem;
		margin-bottom: 0.7rem;
	}

	:first-child {
		margin-top: 0px;
	}

	blockquote {
		border-left: 4px solid #ccc;
		margin-bottom: 5px;
		margin-top: 5px;
		padding-left: 16px;
	}

	.is-editor-empty:first-child::before {
		color: #adb5bd;
		content: attr(data-placeholder);
		float: left;
		height: 0;
		pointer-events: none;
	}
}

.ProseMirror {
	outline: none;
	cursor: text;
}
</style>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import Typography from '@tiptap/extension-typography'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import { Popover } from 'bootstrap'

import MyBubbleMenu from './BubbleMenu.vue'

export default {
	components: {
		EditorContent,
		MyBubbleMenu,
	},
	props: {
		value: {
			type: String,
			default: '',
		},
		placeholder: {
			type: String,
			default: '',
		},
	},
	data() {
		return { editor: null }
	},
	watch: {
		value(value) {
			if (this.editor.getHTML() !== value) {
				this.editor.commands.setContent(value, false)
			}
		},
		placeholder(placeholder) {
			const p = this.editor.extensionManager.extensions.find(extension => extension.name === 'placeholder')
			p.options['placeholder'] = placeholder
			this.editor.view.dispatch(this.editor.state.tr)
		},
	},
	methods: {
		handleAIAction(payload) {
			this.$emit('ai-action', payload)
		},
		forceCloseBubbleMenu() {
			this.$refs['bubble-menu'].$destroy()
		},
		setLink() {
			if (this.editor.isActive('link')) {
				this.editor
					.chain()
					.focus()
					.extendMarkRange('link')
					.unsetLink()
					.run()
				return
			}

			const { view, state } = this.editor
			const { from, to } = view.state.selection
			const text = state.doc.textBetween(from, to, '')

			if (!text) {
				alert('Please select some text to create a link.')
				return
			}

			const previousUrl = this.editor.getAttributes('link').href
			const url = window.prompt('URL', previousUrl)

			// user closed the prompt window
			if (url === null) {
				return
			}

			// user entered an empty string as a URL
			if (url === '') {
				this.editor
					.chain()
					.focus()
					.extendMarkRange('link')
					.unsetLink()
					.run()

				return
			}

			// user entered URL, update the link
			this.editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.setLink({ href: url })
				.run()
		},
	},
	mounted() {
		this.editor = new Editor({
			content: this.value,
			extensions: [
				StarterKit,
				Typography,
				Link,
				TaskList,
				Highlight,
				Underline,
				TaskItem.configure({ nested: true }),
				Placeholder.configure({
					placeholder: this.placeholder,
					emptyEditorClass: 'is-editor-empty has-text-primary',
				}),
			],
			autofocus: true,
			onUpdate: () => {
				this.$emit('input', this.editor.getHTML())
			},
		})

		new Popover(document.querySelector('.btn-ai-actions'), {
			trigger: 'focus',
		})
	},
	beforeDestroy() {
		this.editor.destroy()
	},
}
</script>
