<template>
	<div class="page-form">
		<div v-if="form">
			<form-page-head :form="form"></form-page-head>

			<div v-if="form.status === 'draft'" class="alert alert-warning text-dark mb-4">
				👋 {{ formStatuses.draft.text }}
				<button class="btn btn-sm btn-success ms-2" @click="form.status = 'public'">
					Publish form
				</button>
			</div>
			<div v-else-if="form.status === 'archived'" class="alert alert-danger mb-4">
				{{ formStatuses.archived.text }}
				<button class="btn btn-sm btn-success ms-2" @click="form.status = 'public'">
					Publish form
				</button>
			</div>

			<div class="row">
				<div class="col-lg-5 mb-4">
					<div class="card">
						<div class="card-body">
							<p class="card-text mb-2">
								Status:
								<select
									v-if="states.editStatus"
									class="form-select form-select-sm d-inline-block"
									v-model="form.status"
									style="max-width: 50%"
								>
									<template v-for="(statusInfo, status) in formStatuses">
										<option
											v-if="!(status === 'draft' && form.requests_count)"
											:key="`form-status-${status}`"
											:value="status"
											>{{ statusInfo.name }}</option
										>
									</template>
								</select>
								<span
									v-else
									class="badge text-capitalize"
									:class="[formStatuses[form.status].class]"
									@click="states.editStatus = true"
									>{{ form.status }}</span
								>
							</p>
							<p class="card-text mb-2">
								This {{ form.type }} has <strong>{{ form.steps.length }} steps</strong> &amp;
								<strong>{{ formFields.length }} fields</strong>
							</p>
							<p class="card-text">
								Created on: <strong>{{ form.created_at | dateLocal }}</strong
								><span v-if="form.published_at"
									>, published on <strong>{{ form.published_at | dateLocal }}</strong></span
								>
							</p>

							<h5>Share</h5>
							<p class="card-text">
								<a
									:href="`${apiUrl + j.slug}/forms/${form.slug}/pdf`"
									target="_blank"
									class="btn btn-sm btn-outline-dark me-2 my-1"
									><font-awesome-icon :icon="['fas', 'file-pdf']" /> PDF template</a
								>

								<button
									class="btn btn-sm btn-outline-dark me-2 my-1"
									data-bs-toggle="modal"
									data-bs-target="#form-share-person"
									@click="startPersonShare"
								>
									<font-awesome-icon :icon="['fas', 'paper-plane']" /> Send by text or email
								</button>

								<button class="btn btn-sm btn-outline-dark me-2 my-1" @click="copyFormUrl">
									<font-awesome-icon :icon="['fas', 'clipboard-list']" /> Copy link
								</button>

								<button class="btn btn-sm btn-outline-dark my-1" disabled>
									<font-awesome-icon :icon="['fas', 'qrcode']" /> Generate QR code
								</button>
							</p>

							<p v-if="form.upload_file" class="card-text">
								<a :href="form.upload_file" target="_blank" class="text-muted"
									><small
										><font-awesome-icon :icon="['fas', 'file-pdf']" /> Original PDF form</small
									></a
								>
							</p>
						</div>
					</div>
				</div>
				<div class="col mb-4">
					<div class="card">
						<div class="card-body">
							<h5>
								<strong class="text-highlight">{{ form.requests_count.toLocaleString() }}</strong>
								{{ form.application_prefix }}
								applications received so far
							</h5>

							<p class="card-text mb-2">
								<router-link :to="`/${j.slug}/form-requests?form_id=${form.id}&status=draft`"
									>Drafts started:
									<strong v-if="formStats">{{ formStats.drafts }}</strong></router-link
								>
							</p>

							<div v-if="form.requests_count >= 5">
								<p class="card-text mb-2">
									Time to fill:
									<strong v-if="formStats">~{{ Math.ceil(formStats.fillTime) }} minutes</strong>
								</p>
								<p class="card-text">
									Time to review:
									<strong v-if="formStats"
										>~{{
											formStats.reviewTime > 100
												? `${Math.round(formStats.reviewTime / 60)} hours`
												: `${Math.round(formStats.reviewTime)} minutes`
										}}</strong
									>
								</p>

								<div class="bg-warning-lighter rounded-1 py-4 text-center">
									Charts will be generated after the first 100 applications
								</div>
							</div>
							<div v-else>
								<p class="card-text mb-2">Time to fill: <i class="text-muted">unknown</i></p>
								<p class="card-text">Time to review: <i class="text-muted">unknown</i></p>

								<div class="bg-warning-lighter rounded-1 py-4 text-center">
									Stats will be generated after the first 5 applications
								</div>
							</div>

							<div class="dropdown mt-3">
								<button
									class="btn btn-sm btn-outline-dark dropdown-toggle"
									type="button"
									id="form-submissions-export-dropdown"
									data-bs-toggle="dropdown"
									aria-expanded="false"
									:disabled="!form.requests_count"
								>
									Export submissions (CSV)
								</button>
								<ul class="dropdown-menu" aria-labelledby="form-submissions-export-dropdown">
									<li>
										<a
											class="dropdown-item"
											:href="
												`${apiUrl + j.slug}/form-requests/export/${
													form.slug
												}?status=approved&format=csv&date_range=7-days&exported_by=${auth.id}`
											"
											>✅ Last 7 days - approved</a
										>
									</li>
									<li>
										<a
											class="dropdown-item"
											:href="
												`${apiUrl + j.slug}/form-requests/export/${
													form.slug
												}?status=approved,declined,expired,sent,working,revoked&format=csv&date_range=7-days&exported_by=${
													auth.id
												}`
											"
											>⚪️ Last 7 days - all</a
										>
									</li>
									<li>
										<a
											class="dropdown-item"
											:href="
												`${apiUrl + j.slug}/form-requests/export/${
													form.slug
												}?status=approved&format=csv&date_range=30-days&exported_by=${auth.id}`
											"
											>✅ Last 30 days - approved</a
										>
									</li>
									<li>
										<a
											class="dropdown-item"
											:href="
												`${apiUrl + j.slug}/form-requests/export/${
													form.slug
												}?status=approved,declined,expired,sent,working,revoked&format=csv&date_range=30-days&exported_by=${
													auth.id
												}`
											"
											>⚪️ Last 30 days - all</a
										>
									</li>
									<li>
										<a
											class="dropdown-item"
											:href="
												`${apiUrl + j.slug}/form-requests/export/${
													form.slug
												}?status=approved&format=csv&exported_by=${auth.id}`
											"
											>✅ All submissions - approved</a
										>
									</li>
									<li>
										<a
											class="dropdown-item"
											:href="
												`${apiUrl + j.slug}/form-requests/export/${
													form.slug
												}?status=approved,declined,expired,sent,working,revoked&format=csv&exported_by=${
													auth.id
												}`
											"
											>⚪️ All submissions - all</a
										>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>

			<h5 class="my-0 d-inline-block">Latest submissions</h5>
			<router-link :to="`/${j.slug}/form-requests?form_id=${form.id}`" class="btn btn-link btn-sm ms-1">
				View all →
			</router-link>

			<div class="card mb-4">
				<div class="card-body">
					<div class="card-table">
						<form-requests-table :forms="[form]"></form-requests-table>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>

		<!-- Modal search people -->
		<div class="modal fade" id="form-share-person" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="form" class="modal-content modal-height">
					<div class="modal-header">
						<h5 class="modal-title my-0">Send {{ form.type }} by email or text</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="form-group mb-3">
							<label class="form-label">Where to send?</label>

							<people-search
								@person="setFormSharePerson"
								@personQuery="setFormSharePersonQuery"
								:limit="5"
								:required="true"
								:placeholder="'Email or phone'"
							></people-search>
						</div>

						<div v-if="formShare.person" class="alert alert-info mb-3">
							Sending {{ form.type }} to <strong>{{ formShare.person.name }}</strong
							>:
							<code>{{
								[formShare.person.email, formShare.person.phone].filter(Boolean).join(', ')
							}}</code>
						</div>
						<div v-else-if="validEmailOrPhone(formShare.emailOrPhone)" class="alert alert-info mb-3">
							Sending {{ form.type }} to: <code>{{ formShare.emailOrPhone }}</code>
						</div>
						<div v-else-if="formShare.emailOrPhone" class="alert alert-danger mb-3">
							"<code>{{ formShare.emailOrPhone }}</code
							>" is not a valid email or phone number.
						</div>
					</div>
					<div class="modal-footer">
						<button
							class="btn btn-primary px-5"
							:disabled="!formShare.person && !validEmailOrPhone(formShare.emailOrPhone)"
							@click="invitePeople"
						>
							Send form
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import { Modal } from 'bootstrap'

import heyGovApi from '@/api.js'
import { appUrl, copyToClipboard, tracker, validEmailOrPhone, isEmail } from '@/utils.js'
import { formStatuses } from '@/actions/forms.js'

import FormPageHead from '@/views/Forms/FormPageHead.vue'
import FormRequestsTable from '@/views/Forms/FormRequestsTable.vue'
import PeopleSearch from '@/components/PeopleSearch.vue'

export default {
	name: 'Form',
	metaInfo() {
		return {
			title: `${this.form?.name || this.$route.params.formSlug}`,
		}
	},
	components: { FormPageHead, FormRequestsTable, PeopleSearch },
	data() {
		return {
			states: {
				editStatus: false,
			},
			form: null,
			state: 'loading',
			formStatuses,
			formFields: [],
			formStats: null,

			$modalSendForm: null,
			formShare: {
				person: null,
				description: '',
				emailOrPhone: '',
			},

			url: window.location.protocol + '//' + window.location.hostname,
		}
	},
	computed: {
		...mapState(['j', 'apiUrl']),
		...mapGetters(['auth', 'currentRole', 'isStaff']),
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.replace(`/${this.j.slug}/forms/${this.$route.params.formSlug}/send`)
		} else {
			this.getForm()
		}
	},
	mounted() {
		this.$modalSendForm = new Modal(document.getElementById('form-share-person'))
	},
	methods: {
		validEmailOrPhone,

		getForm() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.slug}/forms/${this.$route.params.formSlug}?expand=steps`).then(
				({ data }) => {
					this.formFields.push(
						...data.steps
							.map(s => s.fields)
							.flat()
							.map(field => {
								//todo remove this when api returns fields data/options in JSON format
								if (field.data && typeof field.data === 'string') {
									field.data = JSON.parse(field.data)
								}
								if (field.options && typeof field.options === 'string') {
									field.options = JSON.parse(field.options)
								}

								return field
							})
					)
					this.form = data

					if (data.requests_count >= 5) {
						this.loadFormStats()
					}

					this.state = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading form (${error})`)
					this.state = 'error'
				}
			)
		},
		loadFormStats() {
			heyGovApi.get(`${this.j.slug}/forms/${this.$route.params.formSlug}/stats`).then(
				({ data }) => {
					this.formStats = data
				},
				error => {
					Vue.toasted.error(`Error loading form stats (${error})`)
				}
			)
		},

		updateForm(fields, successMessage) {
			this.form = { ...this.form, ...fields }

			heyGovApi.put(`/${this.j.slug}/forms/${this.form.slug}`, fields).then(
				() => {
					Vue.toasted.success(successMessage || `"${this.form.name}" is updated`)
				},
				error => {
					const msg = error.response?.data?.title || error.message || 'Server error. Please try again'
					Vue.toasted.error(`Couldn't update form (${msg})`)
				}
			)
		},

		copyFormUrl() {
			tracker.event('form-share-copy', this.$route.params.formSlug)
			copyToClipboard(location.href, '📋 Form link is copied')
		},

		startPersonShare() {
			tracker.event('form-share-open', this.$route.params.formSlug)
		},
		setFormSharePerson(person) {
			this.$set(this.formShare, 'person', person)
		},
		setFormSharePersonQuery(query) {
			this.formShare.emailOrPhone = query
			this.formShare.person = null
		},
		invitePeople() {
			tracker.event('form-share-send', this.$route.params.formSlug)
			const data = {
				form: appUrl(`${this.j.slug}/forms/${this.form.slug}`),
			}

			if (this.formShare.person) {
				data.email = this.formShare.person.email
				data.phone = this.formShare.person.phone
			} else if (validEmailOrPhone(this.formShare.emailOrPhone) && isEmail(this.formShare.emailOrPhone)) {
				data.email = this.formShare.emailOrPhone
			} else if (validEmailOrPhone(this.formShare.emailOrPhone)) {
				data.phone = this.formShare.emailOrPhone
			}

			heyGovApi.post(`/${this.j.slug}/forms/${this.form.slug}/send-email-text`, data).then(
				() => {
					Vue.toasted.success('Form link was sent!')
					this.$modalSendForm.hide()
				},
				error => {
					Vue.toasted.error(`Error sending form (${error})`)
				}
			)
		},
	},
	watch: {
		'form.status'(status, statusOld) {
			if (statusOld && statusOld !== status) {
				this.updateForm({ status }, `Form status changed to "${status}"`)
			}
		},
	},
}
</script>
