<template>
	<router-view v-if="jurisdictionOk && j" :isEmbed="isEmbed" @heightchange="$emit('heightchange')"></router-view>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

import { loadGoogleFonts } from '@/lib/fonts.js'

export default {
	name: 'Jurisdiction',
	props: {
		isEmbed: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			jurisdictionOk: false,
		}
	},
	computed: {
		...mapState(['j']),
	},
	created() {
		if (this.j && this.j.slug === this.$route.params.jurisdiction) {
			this.jurisdictionOk = true
		}

		// check for valid jurisdiction in url
		this.$store.dispatch('setJurisdiction', this.$route.params.jurisdiction).then(
			jurisdiction => {
				if (jurisdiction.style?.fontFamily) {
					loadGoogleFonts([
						{
							family: jurisdiction.style.fontFamily,
							weights: jurisdiction.style.fontWeight === 'bold' ? [400, 700] : [400],
						},
					])
				}

				this.jurisdictionOk = true
			},
			error => {
				console.warn(error.message)
				Vue.toasted.error(error.message)
				this.$router.push('/select-municipality')
			}
		)
	},
}
</script>
