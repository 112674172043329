<template>
	<div class="page-venue-group-overview">
		<div
			v-if="venueGroup.status === 'draft'"
			class="d-flex justify-content-between align-items-center alert alert-warning text-dark mb-4"
		>
			<div>
				<span class="me-1">👋</span> "{{ venueGroup.name }}" is in draft mode. Add
				{{ venueGroup.label || 'Venue' }}s, tweak settings, then make it public to accept reservations.
			</div>
			<button class="btn btn-sm btn-success" @click="publishVenueGroup">Publish</button>
		</div>

		<div class="card mb-4">
			<div class="card-body">
				<div class="row align-items-center mb-4">
					<div class="col-auto">
						<h5 class="mb-0">Reservations</h5>
					</div>
					<div class="col-auto">
						<ul class="nav nav-pills nav-pills-sm2">
							<li class="nav-item">
								<button
									class="nav-link py-1"
									:class="tab == 'pending' ? venueBookingStatuses.pending.class : ''"
									@click="loadReservationsTab('pending')"
								>
									Needs review
								</button>
							</li>
							<li class="nav-item">
								<button
									class="nav-link py-1"
									:class="tab == 'approved' ? venueBookingStatuses.approved.class : ''"
									@click="loadReservationsTab('approved')"
								>
									Upcoming
								</button>
							</li>
							<li class="nav-item">
								<button
									class="nav-link py-1"
									:class="tab == 'progress' ? venueBookingStatuses.progress.class : ''"
									@click="loadReservationsTab('progress')"
								>
									Happening now
								</button>
							</li>
							<li class="nav-item">
								<button
									class="nav-link py-1"
									:class="tab == 'ended' ? venueBookingStatuses.ended.class : ''"
									@click="loadReservationsTab('ended')"
								>
									Needs inspection
								</button>
							</li>
						</ul>
					</div>
				</div>

				<div class="card-table">
					<table class="table table-sm table-hover">
						<thead>
							<tr>
								<th class="d-none d-md-table-cell">{{ venueGroup.venue_label || 'Venue' }}</th>
								<th class="d-none d-md-table-cell">Date</th>
								<th>Guest</th>
								<th>Status</th>
								<th>Booked on</th>
							</tr>
						</thead>
						<tbody>
							<router-link
								v-for="reservation in reservations"
								:key="reservation.id"
								tag="tr"
								:to="
									`/${j.slug}/venues/${
										venues.find(v => v.id == reservation.venue_id).slug
									}/bookings/${reservation.pid || reservation.uuid}`
								"
							>
								<td>
									<template v-if="venues.find(v => v.id === reservation.venue_id)">
										{{ venues.find(v => v.id === reservation.venue_id).name }}
									</template>
									<small v-else>[{{ reservation.venue_id }}]</small>
								</td>
								<td>
									<venue-booking-datetime :venueBooking="reservation" />
								</td>
								<td><person-link :id="reservation.person_id" :avatar="16"></person-link></td>
								<td><venue-booking-status :venueBooking="reservation"></venue-booking-status></td>
								<td>{{ reservation.booked_at | dateLocal }}</td>
							</router-link>

							<tr v-if="reservationsState === 'loading'">
								<td colspan="5" class="text-center py-3">
									<span class="spinner-border spinner-border-sm"></span> Loading..
								</td>
							</tr>
							<tr v-else-if="reservationsState === 'loaded' && !reservations.length">
								<td colspan="5" class="text-center py-3">
									Awaiting <strong>{{ venueBookingStatuses[tab].name }}</strong> reservations
								</td>
							</tr>
							<tr v-else-if="reservationsState === 'error'">
								<td colspan="5" class="text-center text-danger-400 py-3">
									Error loading reservations ({{ reservationsState }})
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<p v-if="reservationsTotal > 5" class="mt-2 mb-0 text-center">
					<router-link
						:to="`/${j.slug}/venues/group/${venueGroup.slug}/reservations?status=${tab}`"
						class="btn btn-sm btn-outline-dark"
						>See all reservations</router-link
					>
				</p>
			</div>
		</div>

		<!-- <div class="card bg-light mb-4">
			<div class="card-body">
				<div class="row align-items-center text-center mb-4">
					<div class="col">
						<h5 class="mb-1 text-primary">
							{{ venues.length }}
						</h5>
						<p class="subtitle mb-0">Venues</p>
					</div>
					<div class="col">
						<h5 class="mb-1 text-warning">{{ '-' }}</h5>
						<p class="subtitle mb-0">Pending reservations</p>
					</div>
					<div class="col">
						<h5 class="mb-1 text-success">{{ '-' }}</h5>
						<p class="subtitle mb-0">Upcoming reservations</p>
					</div>
				</div>

				<div class="bg-white p-3 border border-dashed text-center rounded-1">
					[reservations chart here]
				</div>
			</div>
		</div> -->

		<div class="row">
			<div class="col-7">
				<div class="card">
					<div class="card-body">
						<div class="row align-items-center mb-3">
							<div class="col">
								<h5 class="mb-0">{{ pluralize(venueGroup.venue_label || 'Venue', 2) }}</h5>
							</div>
							<div class="col-auto">
								<div class="dropdown">
									<button
										class="btn btn-sm py-1 btn-outline-primary dropdown-toggle"
										type="button"
										id="venue-group-actions-dropdown"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										⠇
									</button>
									<ul class="dropdown-menu" aria-labelledby="venue-group-actions-dropdown">
										<li>
											<button
												class="dropdown-item"
												data-bs-toggle="modal"
												data-bs-target="#modal-venue-add"
												@click="loadVenuesWithoutGroup"
											>
												Add existing {{ venueGroup.venue_label || 'venue' }}
											</button>
										</li>
										<li>
											<router-link
												:to="
													`/${j.slug}/venues?action=create-venue&venue_group_id=${venueGroup.id}`
												"
												class="dropdown-item"
												>Create {{ venueGroup.venue_label || 'venue' }}</router-link
											>
										</li>
									</ul>
								</div>
							</div>
						</div>

						<div class="card-table">
							<table class="table table-sm table-hover">
								<thead>
									<tr>
										<th>Name</th>
										<th>Slots</th>
										<th v-if="venueGroup.filter1">{{ venueGroup.filter1 }}</th>
										<th v-if="venueGroup.filter2">{{ venueGroup.filter2 }}</th>
										<th>Reservation</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="venue in venues"
										:key="venue.id"
										@mouseenter="activeVenue = venue.id"
										@mouseleave="activeVenue = null"
									>
										<td>
											<router-link :to="`/${j.slug}/venues/${venue.slug}`">{{
												venue.name
											}}</router-link>

											<!-- <span
												v-if="isStaff"
												class="badge text-capitalize ms-1"
												:class="
													venue.reservation_mode === 'slots'
														? 'bg-primary-50 text-primary-400'
														: 'bg-warning-50 text-warning-400'
												"
												>{{ venue.reservation_mode }}</span
											> -->

											<span
												v-if="venue.status !== 'public'"
												class="ms-1"
												:title="venueStatuses[venue.status].name"
												>⚪️</span
											>
										</td>
										<td>
											<template v-for="slot in venue.slots.filter(s => !s.deleted_at)">
												<span
													v-if="slot.type === 'daily'"
													:key="`slot-${slot.id}-daily`"
													class="badge bg-neutral-50 text-primary-300 me-1"
													>{{ slot.name }}
													<small class=" text-primary-200"
														>({{ slot.price | currency }})</small
													></span
												>
												<span
													v-else-if="slot.type === 'multi'"
													:key="`slot-${slot.id}-multi`"
													class="badge bg-neutral-100 text-primary-300 me-1"
													>{{ slot.name }}
													<small class=" text-primary-200"
														>({{ slot.price | currency }})</small
													></span
												>
												<span
													v-else-if="slot.type === 'dates'"
													:key="`slot-${slot.id}-dates`"
													class="badge bg-neutral-200 text-primary-400 me-1"
													>{{ slot.name }}
													<small class=" text-primary-300"
														>({{ slot.price | currency }})</small
													></span
												>
											</template>
										</td>
										<th v-if="venueGroup.filter1">
											<template v-if="venueAttr(venue, venueGroup.filter1)">
												{{ venueAttr(venue, venueGroup.filter1).value }}
											</template>
											<small v-else class="text-neutral-300">-</small>
										</th>
										<th v-if="venueGroup.filter2">
											<template v-if="venueAttr(venue, venueGroup.filter2)">
												{{ venueAttr(venue, venueGroup.filter2).value }}
											</template>
											<small v-else class="text-neutral-300">-</small>
										</th>
										<td>
											<small class="text-neutral-300">-</small>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col">
				<venue-group-map
					v-if="venues.length"
					ref="venueGroupMap"
					:venueGroup="venueGroup"
					:venues="venues"
					:activeVenue="activeVenue"
					:height="500"
					class="rounded"
				></venue-group-map>
			</div>
		</div>

		<!-- Add Venue Modal -->
		<div class="modal fade" id="modal-venue-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<form @submit.prevent="addVenue">
						<div class="modal-header">
							<h5 class="modal-title">Add venues to {{ venueGroup.name }}</h5>

							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>
								Choose the new venues you want to add to <strong>{{ venueGroup.name }}</strong
								>.
							</p>

							<div class="form-group mb-4">
								<label class="form-label" for="group-venues">Venues</label>

								<multiselect
									v-model="venuesToAdd"
									:multiple="true"
									:options="venuesWithoutGroup"
									track-by="id"
									label="name"
									placeholder="Select venues"
									:hideSelected="true"
								>
									<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								</multiselect>
							</div>

							<p class="card-text">
								If you want to create a new venue instead, create it from <strong>Venues</strong> page.
							</p>

							<div class="alert alert-warning text-dark">
								⚠️ Not functional yet. Go to a <strong>Venue settings</strong> page, and choose the
								group from there.
							</div>
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary" :disabled="!venuesToAdd.length">Add venues</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import pluralize from 'pluralize'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import heyGovApi from '@/api.js'
import { venueStatuses, venueBookingStatuses } from '@/actions/venues.js'

import PersonLink from '@/components/PersonLink.vue'
import VenueBookingDatetime from '@/components/venues/VenueBookingDatetime.vue'
import VenueBookingStatus from '@/components/venues/VenueBookingStatus.vue'
import VenueGroupMap from '@/components/venues/VenueGroupMap.vue'

export default {
	name: 'VenueGroupOverview',
	components: { Multiselect, PersonLink, VenueBookingDatetime, VenueBookingStatus, VenueGroupMap },
	props: {
		venueGroup: {
			type: Object,
			required: true,
		},
		venues: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			venueStatuses,
			venueBookingStatuses,

			venuesToAdd: [],
			venuesWithoutGroup: [],

			tab: '',
			reservationsTotal: 0,
			reservationsState: 'loading',
			reservations: [],

			activeVenue: null,
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['currentRole', 'isStaff']),
	},
	created() {
		this.loadReservationsTab('pending')
	},
	methods: {
		pluralize,

		loadReservationsTab(status) {
			if (this.tab === status) {
				return
			}

			this.reservationsTotal = 0
			this.reservationsState = 'loading'
			this.tab = status
			this.reservations = []

			const params = {
				venue_group_id: this.venueGroup.id,
				status,
				limit: 5,
			}

			if (['approved', 'progress'].includes(params.status)) {
				params.orderBy = 'starts_at'
				params.order = 'asc'
			}

			heyGovApi.get(`${this.j.slug}/venue-bookings`, { params }).then(({ data, headers }) => {
				this.reservationsTotal = Number(headers['x-total'])
				this.reservations.push(...data)

				this.reservationsState = 'loaded'
			})
		},

		loadVenuesWithoutGroup() {
			this.venuesWithoutGroup = []

			heyGovApi.get(`${this.j.slug}/venues?venue_group_id=null`).then(({ data }) => {
				this.venuesWithoutGroup = data
			})
		},

		publishVenueGroup() {
			this.$emit('updateVenueGroup', {
				fields: {
					status: 'public',
				},
				message: `${this.venueGroup.name} is now public 🎉`,
			})
		},

		addVenue() {
			alert('coming soon')
		},
		venuePrices(venue) {
			let prices = []

			if (venue.reservation_mode === 'slots') {
				prices = venue.slots.filter(s => !s.deleted_at).map(s => s.price)
			} else if (venue.reservation_mode === 'time') {
				for (const day in venue.availability.days) {
					if (venue.availability.days[day].enabled) {
						prices.push(Number(venue.availability.days[day].price || 0))
					}
				}
			}

			return prices
		},
		venueAttr(venue, attr) {
			return venue.attributes.find(a => a.id == attr)
		},
	},
}
</script>
