<template>
	<div class="container mt-4">
		<div class="row justify-content-center">
			<div class="col-md-8">
				<div class="card">
					<div class="card-header">
						<h4 class="mb-0">Fulfill Agenda Item Change Request</h4>
					</div>
					<div class="card-body">
						<template v-if="agendaItem.status !== 'change_requested'">
							<div class="alert alert-warning">
								You are not allowed to make changes to this agenda item.
							</div>
						</template>
						<template v-else-if="!changesAllowed">
							<div class="alert alert-warning mb-3">
								The time to make changes to the meeting agenda has passed.
							</div>
						</template>
						<template v-else>
							<template v-if="isSubmitted">
								<div class="alert alert-success">
									Thank you, your updates were submitted successfully.
								</div>
							</template>
							<template v-else>
								<div v-if="$route.query.message" class="alert alert-info mb-3">
									{{ $route.query.message }}
								</div>
								<login-form
									v-if="!auth"
									@loginSuccess="onLoginSuccess"
									:redirectUrl="$route.fullPath"
									:allowRegistration="true"
									loginMessage="Great, logged in to your account"
								/>
								<edit-agenda-item-form
									v-else
									:isForModal="false"
									@submit="handleSubmit"
									:defaultItem="agendaItem"
								/>
							</template>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'
import EditAgendaItemForm from '@/views/Meetings/EditAgendaItemForm/EditAgendaItemForm.vue'
import LoginForm from '@/components/LoginForm.vue'

export default {
	name: 'RequestedAgendaItemChangesForm',
	components: { EditAgendaItemForm, LoginForm },
	props: {
		meeting: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			agendaItem: this.meeting.agenda_items.find(item => item.id == this.$route.query.item_id),
			isSubmitted: false,
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['auth']),
		changesAllowed() {
			const now = new Date()
			const meetingStart = new Date(this.meeting.starts_at)
			if (now > meetingStart) {
				return false
			}
			if (!this.meeting.agenda_cut_off_hours) {
				return true
			}
			const cutoffTime = new Date(meetingStart.getTime() - this.meeting.agenda_cut_off_hours * 60 * 60 * 1000)
			return now < cutoffTime
		},
	},
	methods: {
		async handleSubmit(item, after) {
			try {
				const { meetingId } = this.$route.params

				const resp = await heyGovApi.put(`${this.j.slug}/meetings/${meetingId}/agenda-items/${item.id}`, {
					title: item.title,
					speaker_id: item.speaker_id,
					text: item.text,
					files: item.files,
					status: 'needs_review',
				})

				const { data: updatedItem } = resp

				this.$toasted.success('Agenda item changes submitted successfully')
				this.$emit('submitRequestedChangesForAgendaItem', updatedItem)
				this.isSubmitted = true
			} catch (error) {
				handleResponseError('Error submitting agenda item changes ({error})')(error)
			} finally {
				after()
			}
		},
		onLoginSuccess() {
			this.$forceUpdate()
		},
	},
}
</script>
