<template>
	<div class="venue-applicant-info">
		<div v-if="venue">
			<venue-header :venue="venue"></venue-header>

			<div class="card">
				<div class="card-body step-fields-wrapper">
					<p class="mb-2">
						These are fields that appear on Booking page, when a person wants to make a reservation.
					</p>
					<p class="mb-4">
						Name, Email and Payment fields are added automatically and are required. Add any extra fields
						that this venue may need.
					</p>

					<div class="row justify-content-center">
						<div class="col-md-10 col-lg-8 mb-3">
							<div class="form-group mb-3">
								<label class="form-label" for="field-email">Email</label>
								<input
									type="email"
									id="field-email"
									class="form-control"
									placeholder="Your email"
									disabled
								/>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="field-name">Name</label>
								<input
									type="text"
									id="field-name"
									class="form-control"
									placeholder="Your name"
									disabled
								/>
							</div>

							<div v-if="venue.fields.length" class="active-elements">
								<p class="text-center text-muted mb-0 mt-2">Additional fields</p>

								<draggable
									:list="venue.fields"
									class="venue-fields"
									@end="updateFieldsOrder(venue.fields)"
									:sort="true"
								>
									<!-- The form elements starts (on the right) -->
									<div
										v-for="(field, index) in venue.fields"
										:key="index"
										v-bind="field"
										class="form-group form-group-builder p-2 pt-3 mt-1 position-relative rounded"
									>
										<div
											class="element-infos
															d-flex
															align-items-center
															justify-content-start
															fs-6
														"
										>
											<span class="element-type bg-secondary">{{ field.name }}</span>
											<span
												class="element-conditions bg-info"
												v-if="drawElementConditions(field).length > 0"
											>
												<a
													role="button"
													:title="drawElementConditions(field)"
													data-bs-toggle="tooltip"
													data-bs-html="true"
												>
													<font-awesome-icon :icon="['fas', 'microchip']" class="fa-fw" />
												</a>
											</span>
										</div>

										<div class="element-wrapper">
											<div
												v-if="field.type === 'TextElement'"
												class="text-element"
												v-html="field.value"
											></div>
											<div v-else>
												<label
													class="element-label form-label"
													:for="'fsf-' + field.id"
													v-if="displayLabel(field)"
												>
													{{ field.label }}
													<span v-if="field.required" title="Required" class="text-danger-300"
														>*</span
													>
													<div title="Redacted" v-if="field.redacted" class="file-redacted">
														*
													</div>
												</label>

												<component
													:is="field.type"
													:currentField="field"
													class="element"
													v-on:change="$emit('change', $event.target.value)"
												></component>

												<small v-if="field.type !== 'TextElement'" class="element-help">
													{{ field.help }}
												</small>
											</div>

											<!-- Actions list -->

											<div class="element-action-buttons">
												<a
													class="btn btn-sm btn-icon-info"
													style=""
													title="Update element"
													@click="editField(field)"
												>
													<font-awesome-icon :icon="['fas', 'pencil-alt']" class="fa-fw" />
												</a>
											</div>
										</div>
									</div>
								</draggable>
							</div>

							<p class="text-center my-3">
								<button
									@click="displayModalWithFields()"
									class="btn btn-sm btn-outline-primary btn-big-icon"
								>
									<font-awesome-icon :icon="['fas', 'plus-circle']" />
									Add field
								</button>
							</p>

							<div class="form-group">
								<label class="form-label" for="field-payment">Payment</label>

								<div class="input-group">
									<span class="input-group-text">$</span>
									<input
										type="text"
										id="field-payment"
										class="form-control"
										placeholder="Credit Card details to pay the calculated price"
										readonly
									/>
									<button
										class="input-group-text btn btn-outline-primary"
										data-bs-toggle="modal"
										data-bs-target="#modal-pricing"
									>
										<font-awesome-icon :icon="['fas', 'filter-circle-dollar']" /><span
											v-if="venue.price_conditions.length"
											class="ms-2"
											>{{ venue.price_conditions.length }}</span
										>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>

		<!-- Modal for pricing conditions -->
		<div class="modal fade" id="modal-pricing" data-bs-backdrop="static" data-bs-keyboard="false">
			<div class="modal-dialog modal-lg modal-dialog-centered">
				<div class="modal-content">
					<form @submit.prevent="savePriceConditions">
						<div class="modal-header">
							<h5 class="modal-title">
								<font-awesome-icon :icon="['fas', 'filter-circle-dollar']" class="fa-fw" /> Price
								conditions
							</h5>
							<button
								type="button"
								v-if="!states.priceConditionsUpdated"
								class="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							></button>
						</div>

						<div v-if="venue" class="modal-body">
							<p class="lead">
								Make the reservation price change depending on the Fields answers.
							</p>

							<div v-if="!venue.fields.length" class="alert alert-warning text-center">
								ℹ️ You need to add at least one Field before you can add price conditions.
							</div>

							<div v-else-if="!venue.price_conditions.length" class="text-center text-muted mb-3">
								<p class="mb-2">There are no price conditions added yet.</p>
								<button
									class="btn btn-sm btn-big-icon btn-outline-secondary"
									@click="addPriceCondition"
								>
									<font-awesome-icon :icon="['fas', 'plus-circle']" />
									Add first price condition
								</button>
							</div>

							<div v-else>
								<div
									v-for="priceCondition in venue.price_conditions"
									:key="priceCondition.id"
									class="bg-light p-2 rounded-1 my-3 position-relative"
								>
									<button
										class="btn btn-sm btn-icon-danger position-absolute"
										style="top: -6px; right: -6px;"
										type="button"
										role="button"
										@click="removePriceCondition(priceCondition)"
									>
										<font-awesome-icon :icon="['fas', 'times']" />
									</button>

									<div class="row align-items-center gx-2 mb-2">
										<div class="form-group col-6">
											<input
												type="text"
												class="form-control form-control-sm"
												v-model="priceCondition.name"
												placeholder="Price name, ex: Friends special, SUMMER PROMO"
											/>
										</div>
									</div>

									<p class="mb-0">When these conditions are met:</p>

									<div class="row align-items-center gx-2 mb-2">
										<div class="form-group col-3">
											<select class="form-select form-select-sm" v-model="priceCondition.slot">
												<option :value="null">{{
													venue.reservation_mode === 'slots'
														? 'Any slot'
														: 'Is hourly booking'
												}}</option>
												<option v-for="slot in venue.slots" :key="slot.id" :value="slot.id">{{
													slot.name
												}}</option>
											</select>
										</div>

										<div class="form-group col-auto">&amp;</div>

										<div class="form-group col">
											<select
												class="form-select form-select-sm"
												v-model="priceCondition.field"
												required
											>
												<option
													v-for="field in venue.fields"
													:key="`price-cond-${priceCondition.id}-field-${field.id}`"
													:value="field.id"
													>{{ field.label }}</option
												>
											</select>
										</div>

										<div class="form-group col-auto">
											<select
												class="form-select form-select-sm"
												v-model="priceCondition.op"
												required
												style="max-width: 115px; padding-right: 1.9rem;"
											>
												<option value="=">=</option>
												<option value="!=">≠</option>
												<option value="empty">Empty</option>
												<option value="!empty">Not empty</option>

												<option
													v-for="(label, op) in priceConditionOperators(priceCondition)"
													:key="`price-cond-${priceCondition.id}-op-${op}`"
													:value="op"
													>{{ label }}</option
												>
											</select>
										</div>

										<div class="form-group col">
											<select
												v-if="
													['SelectListElement', 'RadioButtonElement'].includes(
														getFieldType(priceCondition.field)
													) && ['=', '!='].includes(priceCondition.op)
												"
												class="form-select form-select-sm"
												v-model="priceCondition.value"
												required
											>
												<option
													v-for="option in getFieldById(priceCondition.field).options"
													:key="`price-cond-${priceCondition.id}-opt-${option.optionValue}`"
													:value="option.optionValue"
													>{{ option.optionLabel }}</option
												>
											</select>
											<input
												v-else-if="
													priceCondition.op != 'empty' && priceCondition.op != '!empty'
												"
												type="text"
												class="form-control form-control-sm"
												v-model="priceCondition.value"
												required
											/>
										</div>
									</div>

									<p class="mb-0">Apply this price rule:</p>

									<div class="row align-items-center gx-2">
										<div class="form-group col">
											<select class="form-select form-select-sm" v-model="priceCondition.type">
												<option value="add">Increase price</option>
												<option value="sub">Decrease price</option>
												<option value="add-deposit">Increase deposit</option>
												<option value="sub-deposit">Decrease deposit</option>
											</select>
										</div>

										<div class="form-group col">
											<select
												class="form-select form-select-sm"
												v-model="priceCondition.amountType"
											>
												<option value="fixed">With fixed amount</option>
												<option value="percentage">By percentage</option>
											</select>
										</div>

										<div class="form-group col">
											<input
												type="number"
												class="form-control form-control-sm"
												v-model="priceCondition.amount"
												step="0.01"
												required
											/>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div
							v-if="(venue && venue.price_conditions.length) || states.priceConditionsUpdated"
							class="modal-footer justify-content-between"
						>
							<button
								v-if="venue && venue.price_conditions.length"
								class="btn btn-sm btn-big-icon btn-outline-secondary"
								type="button"
								role="button"
								@click="addPriceCondition"
							>
								<font-awesome-icon :icon="['fas', 'plus-circle']" />
								Add price condition
							</button>
							<span v-else> </span>

							<button v-if="states.priceConditionsUpdated" class="btn btn-sm btn-primary">
								Save price conditions
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- Field Modal - (Modal for adding, editing and working with field properties) -->
		<div class="modal fade" id="modal-field" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
			<div class="modal-dialog modal-lg modal-dialog-centered">
				<!-- Fields List -->
				<div class="modal-content" v-if="isNewField && !isEditField">
					<div class="modal-header bg-primary">
						<h5 class="modal-title text-white">Add field</h5>
						<button
							type="button"
							@click="cancelAddingField()"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>

					<div class="modal-body pt-3">
						<ElementsList :enabledElements="enabledElements" @addElement="addField" />
					</div>

					<div class="modal-footer justify-content-between">
						<button class="btn btn-link text-danger" @click="cancelAddingField()">Cancel</button>
						<button
							:disabled="!('col' in selectedField)"
							@click="editAddedField()"
							class="btn btn-primary ms-3"
						>
							Next
						</button>
					</div>
				</div>
				<!-- ### Elements List -->

				<!-- Element Properties -->
				<div class="modal-content" v-if="isEditField">
					<div class="modal-header bg-primary">
						<h5 class="modal-title text-white">
							<span v-if="isNewField">Add field</span>
							<span v-else>Edit field "{{ selectedField.label }}"</span>
						</h5>
						<button
							type="button"
							@click="isNewField ? cancelAddingField() : cancelUpdatingField(selectedField)"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>

					<div class="modal-body">
						<FieldProperties
							:current="selectedField"
							:fields="venue.fields"
							:isNewField="isNewField"
							@fieldRemoved="cancelAddingField"
							v-bind="venue.fields"
						/>
					</div>

					<div class="modal-footer justify-content-between">
						<button v-if="isNewField" @click="cancelAddingField()" class="btn btn-link text-danger">
							Cancel
						</button>
						<button
							v-if="!isNewField"
							@click="cancelUpdatingField(selectedField)"
							class="btn btn-link text-danger"
						>
							Cancel
						</button>
						<button v-if="!isNewField" @click="updateField()" class="btn btn-primary ms-2">
							Update
						</button>
						<button v-if="isNewField" @click="addSelectedField()" class="btn btn-primary ms-2">
							Save
						</button>
					</div>
				</div>
				<!-- ### Element Properties -->
			</div>
		</div>
		<!-- ### Element Modal -->
	</div>
</template>

<style scoped>
.carousel-control-next-icon:after {
	content: '>';
	font-size: 22px;
	color: #5e81f4;
}
.carousel-control-prev-icon:after {
	content: '<';
	font-size: 22px;
	color: #5e81f4;
}
</style>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { Modal, Tooltip } from 'bootstrap'
import draggable from 'vuedraggable'

import heyGovApi from '@/api.js'
import { truncateString, handleResponseError } from '@/utils.js'

import VenueHeader from './VenueHeader.vue'
import ElementsList from '@/components/form-builder/ElementsList'
import FieldProperties from '@/components/venues/FieldProperties'
import TextInputElement from '@/components/form-builder/TextInputElement'
import TextareaInputElement from '@/components/form-builder/TextareaInputElement'
import NumberInputElement from '@/components/form-builder/NumberInputElement'
import DatePickerElement from '@/components/form-builder/DatePickerElement'
import TimeElement from '@/components/form-builder/TimeElement'
import PhoneInputElement from '@/components/form-builder/PhoneInputElement'
import FileUploadElement from '@/components/form-builder/FileUploadElement'
import SignatureElement from '@/components/form-builder/SignatureElement'
import RadioButtonElement from '@/components/form-builder/RadioButtonElement'
import CheckboxElement from '@/components/form-builder/CheckboxElement'
import SelectListElement from '@/components/form-builder/SelectListElement'
import AddressInputElement from '@/components/form-builder/AddressInputElement'

export default {
	name: 'VenueApplicantInfo',
	metaInfo() {
		return {
			title: `Fields & Pricing for ${this.venue?.name || 'venue'}`,
		}
	},
	components: {
		VenueHeader,
		draggable,
		ElementsList,
		FieldProperties,
		TextInputElement,
		TextareaInputElement,
		NumberInputElement,
		DatePickerElement,
		TimeElement,
		PhoneInputElement,
		FileUploadElement,
		SignatureElement,
		CheckboxElement,
		RadioButtonElement,
		SelectListElement,
		AddressInputElement,
	},
	data() {
		return {
			states: {
				priceConditionsUpdated: false,
			},
			state: 'loading',
			venue: null,

			isNewField: false,
			isEditField: false,
			selectedField: {
				data: {},
			},
			oldField: {
				data: {},
			},
			$modalField: null,
			enabledElements: [
				'TextInputElement',
				'TextareaInputElement',
				'NumberInputElement',
				'DatePickerElement',
				'TimeElement',
				'PhoneInputElement',
				'FileUploadElement',
				'CheckboxElement',
				'RadioButtonElement',
				'SelectListElement',
				'AddressInputElement',
				'SignatureElement',
				'TextElement',
			],

			$modalPricing: null,
			priceConditionNew: {
				slot: null,
				field: null,
				op: '=',
				value: '',
				type: 'add',
				amountType: 'fixed',
				amount: null,
			},
		}
	},
	computed: {
		...mapState(['j']),
	},
	created() {
		this.loadVenue()
	},
	mounted() {
		this.$modalField = new Modal(document.getElementById('modal-field'))
		this.$modalPricing = new Modal(document.getElementById('modal-pricing'))

		Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
			tooltipNode => new Tooltip(tooltipNode)
		)
	},
	updated() {
		Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach(
			tooltipNode => new Tooltip(tooltipNode)
		)
	},
	methods: {
		loadVenue() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}?expand=fields`).then(
				({ data }) => {
					data.fields = data.fields.map(field => {
						if (field.type === 'CheckboxElement' || field.type === 'FileUploadElement') {
							field.value = JSON.parse(field.value)
						}

						return field
					})

					this.venue = data

					this.state = 'loaded'
				},
				error => {
					Vue.toasted.error(`Error loading venue (${error})`)
					this.state = 'error'
				}
			)
		},
		updateVenue(fields, successMessage) {
			heyGovApi.put(`${this.j.slug}/venues/${this.venue.slug}`, fields).then(
				() => {
					Vue.toasted.success(successMessage || 'Venue updated')
				},
				error => {
					Vue.toasted.error(`Error updating venue data (${error})`)
				}
			)
		},

		// Reordering fields in venue
		updateFieldsOrder: function(list) {
			// Get order of fields
			var items = list.map(function(item, index) {
				return { id: item.id, order: index }
			})

			heyGovApi
				.post(`/${this.j.slug}/venue-fields/update-order`, {
					list: JSON.stringify(items),
				})
				.then(
					() => {
						Vue.toasted.success(`Fields order is saved`)
					},
					error => {
						Vue.toasted.error(error)
					}
				)
		},

		// Element condition label for fields
		drawElementConditions(field) {
			let conditionString = ''
			if (field.data?.conditions?.length > 0) {
				field.data.conditions.forEach(condition => {
					const label = condition.element ? this.getFieldById(condition.element).label : ''

					conditionString +=
						conditionString +
						condition.type +
						' ' +
						truncateString(label, 48) +
						' ' +
						condition.condition +
						' ' +
						condition.conditionValue
				})
			}
			return conditionString
		},

		// Get element by ID from form elements
		getFieldById(id) {
			return this.venue.fields.find(field => field.id === id)
		},
		getFieldType(id) {
			return this.getFieldById(id)?.type
		},

		// Display available fields for adding
		displayModalWithFields() {
			this.isNewField = true
			this.$modalField.show()
		},

		// Cancel adding new Field
		cancelAddingField() {
			this.selectedField = {
				data: {},
			}
			this.isEditField = false
			this.isNewField = false
			this.$modalField.hide()
		},

		// Add element to venue from fields list
		addField(field) {
			this.selectedField = field
		},

		// Switch to ElementProperties when new field is chosen
		editAddedField() {
			if (this.selectedField.name === 'Payment' && this.paymentElement) {
				Vue.toasted.error('Payment element already exist in this venue!')
			} else {
				this.isEditField = true
			}
		},

		// Add selected field to venue
		addSelectedField() {
			console.log('addSelectedField')

			if (
				this.selectedField.type === 'DatePickerElement' &&
				((this.selectedField.data.maxDate === '' && !this.selectedField.data.useDynamicMaxDate) ||
					(this.selectedField.data.minDate === '' && !this.selectedField.data.useDynamicMinDate))
			) {
				if (this.selectedField.data.maxDate === '' && !this.selectedField.data.useDynamicMaxDate) {
					Vue.toasted.error('You need to select maxDate')
				}
				if (this.selectedField.data.minDate === '' && !this.selectedField.data.useDynamicMinDate) {
					Vue.toasted.error('You need to select minDate')
				}
			} else if (
				this.selectedField.type === 'YearElement' &&
				((this.selectedField.data.maxYear === '' && !this.selectedField.data.useDynamicMaxYear) ||
					(this.selectedField.data.minYear === '' && !this.selectedField.data.useDynamicMinYear))
			) {
				if (this.selectedField.data.minYear === '' && !this.selectedField.data.useDynamicMinYear) {
					Vue.toasted.error('You need to add minYear')
				}
				if (this.selectedField.data.maxYear === '' && !this.selectedField.data.useDynamicMaxYear) {
					Vue.toasted.error('You need to add maxYear')
				}
			} else if (
				this.selectedField.type === 'NameInputElement' &&
				!this.selectedField.data.firstName &&
				!this.selectedField.data.lastName &&
				!this.selectedField.data.middleName
			) {
				Vue.toasted.error('You need to enable atleast one input (first name, last name or middle name)')
			} else if (this.selectedField.type !== 'TextElement' && this.selectedField.label.length < 1) {
				Vue.toasted.error('You need to enter label for field')
			} else {
				heyGovApi
					.post(`/${this.j.slug}/venue-fields/`, {
						...this.selectedField,
						venue_id: this.venue.id,
					})
					.then(response => {
						let value = response.data.value
						if (response.data.type === 'CheckboxElement' || response.data.type === 'FileUploadElement') {
							value = JSON.parse(response.data.value)
						}

						this.venue.fields.push({
							...response.data,
							data: JSON.parse(response.data.data),
							value,
							options: JSON.parse(response.data.options),
						})

						this.selectedField = {
							data: {},
						}
						this.isEditField = false
						this.isNewField = false

						this.$modalField.hide()

						Vue.toasted.success(`Element successfully added!`)
					}, handleResponseError(`Couldn't add field ({error})`))
			}
		},

		// Update edited field
		updateField() {
			if (
				this.selectedField.type === 'DatePickerElement' &&
				((this.selectedField.data.maxDate === '' && !this.selectedField.data.useDynamicMaxDate) ||
					(this.selectedField.data.minDate === '' && !this.selectedField.data.useDynamicMinDate))
			) {
				if (this.selectedField.data.maxDate === '' && !this.selectedField.data.useDynamicMaxDate) {
					Vue.toasted.error('You need to select maxDate')
				}
				if (this.selectedField.data.minDate === '' && !this.selectedField.data.useDynamicMinDate) {
					Vue.toasted.error('You need to select minDate')
				}
			} else if (
				this.selectedField.type === 'YearElement' &&
				((this.selectedField.data.maxYear === '' && !this.selectedField.data.useDynamicMaxYear) ||
					(this.selectedField.data.minYear === '' && !this.selectedField.data.useDynamicMinYear))
			) {
				if (this.selectedField.data.minYear === '' && !this.selectedField.data.useDynamicMinYear) {
					Vue.toasted.error('You need to add minYear')
				}
				if (this.selectedField.data.maxYear === '' && !this.selectedField.data.useDynamicMaxYear) {
					Vue.toasted.error('You need to add maxYear')
				}
			} else if (
				this.selectedField.type === 'NameInputElement' &&
				!this.selectedField.data.firstName &&
				!this.selectedField.data.lastName &&
				!this.selectedField.data.middleName
			) {
				Vue.toasted.error('You need to enable at least one input (first name, last name or middle name)')
			} else if (this.selectedField.type !== 'TextElement' && this.selectedField.label.length < 1) {
				Vue.toasted.error('You need to enter label for field')
			} else {
				heyGovApi
					.put(`/${this.j.slug}/venue-fields/${this.selectedField.id}`, {
						...this.selectedField,
					})
					.then(() => {
						this.$modalField.hide()
						this.isEditField = false
						this.selectedField = {
							data: {},
						}
						Vue.toasted.success(`Field successfully updated!`)
					}, handleResponseError(`Couldn't update field ({error})`))
			}
		},

		// Display element label
		displayLabel(field) {
			let display = false
			if (field.type === 'NameInput') {
				if (field.data.displayLabel) {
					display = true
				} else {
					display = false
				}
			} else {
				display = !['ParagraphInput'].includes(field.type)
			}
			return display
		},

		// Edit field
		editField(field) {
			this.oldField = JSON.parse(JSON.stringify(field))
			this.selectedField = field
			this.isEditField = true
			this.$modalField.show()
		},

		// Cancel updating Element
		cancelUpdatingField(element) {
			element = { ...this.oldField }

			this.venue.fields.forEach(e => {
				if (e.id === element.id) {
					Object.keys(e).map(function(key) {
						e[key] = element[key]
					})
				}
			})

			this.isEditField = false
			this.isNewField = false
			this.$modalField.hide()
			this.selectedField = {
				data: {},
			}
			this.oldField = {
				data: {},
			}
		},

		addPriceCondition() {
			this.venue.price_conditions.push({
				id: Math.floor(Math.random() * 99999),
				...this.priceConditionNew,
			})
		},
		removePriceCondition(priceCondition) {
			if (confirm('For sure?')) {
				this.venue.price_conditions = this.venue.price_conditions.filter(p => p.id !== priceCondition.id)
			}
		},
		priceConditionOperators(priceCondition) {
			const field = this.getFieldById(priceCondition.field)
			const operators = {}

			if (['TextInputElement'].includes(field?.type)) {
				operators['contains'] = 'Contains'
			} else if (['NumberInputElement'].includes(field?.type)) {
				operators['>'] = '>'
				operators['<'] = '<'
			}

			return operators
		},
		savePriceConditions() {
			this.updateVenue(
				{
					price_conditions: this.venue.price_conditions,
				},
				'Price conditions are saved'
			)

			this.states.priceConditionsUpdated = false
			this.$modalPricing.hide()
		},
	},
	watch: {
		'venue.price_conditions': {
			handler(newValue, oldValue) {
				if (oldValue) {
					this.states.priceConditionsUpdated = true
				}
			},
			deep: true,
		},
	},
}
</script>
