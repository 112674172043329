<template>
	<div class="poll-detail">
		<div v-if="poll">
			<h2 class="text-primary">
				{{ poll.title }}
				<small v-if="currentRole === 'ADMIN'" class="badge">{{ poll.status }}</small>
			</h2>

			<ul class="nav nav-tabs mb-4">
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}`" class="nav-link">Straw poll page</router-link>
				</li>
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}/edit`" class="nav-link disabled">Edit</router-link>
				</li>
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}/responses`" class="nav-link"
						>Responses <span class="badge bg-light text-gray">{{ poll.responses }}</span></router-link
					>
				</li>
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}/summary`" class="nav-link active"
						>Summary</router-link
					>
				</li>
			</ul>

			<div v-if="['active', 'draft'].includes(poll.status)">
				<div class="alert alert-info">
					Straw poll summary can be created only after the polling time completed.
				</div>
			</div>
			<div v-else class="">
				<p>
					Create a Straw Poll page that informs the citizens about the Straw Poll ending and infos about their
					responses.
				</p>

				<form @submit.prevent="updateSummary">
					<div class="card mb-3">
						<div class="card-header">
							<h5 class="mb-0">Straw poll summary</h5>
						</div>
						<div class="card-body">
							<div class="form-group">
								<label for="poll-summary-title">Summary title</label>
								<input
									class="form-control"
									id="poll-summary-title"
									v-model="poll.summary_title"
									placeholder="Straw Poll results are in"
								/>
							</div>
							<div class="form-group">
								<label for="poll-summary-text">Message</label>
								<textarea
									class="form-control"
									id="poll-summary-text"
									rows="5"
									v-model="poll.summary_text"
									placeholder="A nice message summarizing how the poll went, and the general takeaway"
								></textarea>
							</div>
							<div class="form-group">
								<label for="poll-summary-images">Images</label>
								<div class="row">
									<div v-for="file in poll.summary_files" :key="`poll-img-${file}`" class="col-2">
										<button
											type="button"
											class="close position-absolute bg-light rounded px-1"
											style="top: -9px; right: 12px"
											@click="removeFile(file)"
											aria-label="Close"
										>
											<span aria-hidden="true">&times;</span>
										</button>
										<a :href="file" target="_blank"
											><img :src="file" class="img-thumbnail" alt="Image"
										/></a>
									</div>
									<div v-if="states.fileUploading" class="col-2 text-center">
										<div class="spinner-border" role="status"></div>
									</div>
									<div class="col-2">
										<label
											for="summary-image-upload"
											class="d-block border border-dashed rounded text-center bg-light cursor-pointer py-3 px-2"
										>
											<p>
												<img
													src="https://files.heygov.com/assets/icon-thumbnail.png"
													alt="Upload file"
													width="30"
												/>
											</p>
											<p class="mb-0"><small>Upload image</small></p>
										</label>
										<input
											type="file"
											multiple
											id="summary-image-upload"
											@change="handleFileInput($event)"
											class="d-none"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row my-5">
						<div class="col-6">
							<div class="custom-control custom-checkbox">
								<input
									type="checkbox"
									class="custom-control-input"
									id="poll-send-notifications"
									v-model="poll.people_notified"
									:disabled="poll.people_notified == '1' && poll.status === 'completed'"
								/>
								<label class="custom-control-label" for="poll-send-notifications"
									>Send notification to poll responders</label
								>
							</div>
							<div class="custom-control custom-checkbox mb-3">
								<input type="checkbox" class="custom-control-input" id="poll-send-notice" disabled />
								<label class="custom-control-label" for="poll-send-notice"
									>Post as notice on website <small class="text-muted">(coming soon)</small></label
								>
							</div>

							<button class="btn btn-primary" @click="updateType = 'publish'">
								{{ poll.status === 'closed' ? 'Publish summary' : 'Update summary' }}
							</button>
						</div>
						<div class="col-6 text-end">
							<button
								v-if="poll.status === 'closed'"
								class="btn btn-outline-primary"
								@click="updateType = 'update'"
							>
								Save draft
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div v-if="state === 'loading'" class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>
	</div>
</template>

<script>
import heyGovApi from '@/api.js'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { random } from 'lodash'
import { formatDistanceToNow } from 'date-fns'

export default {
	name: 'Poll',
	components: {},
	data() {
		return {
			state: 'idle',
			states: {
				fileUploading: false,
			},
			poll: null,
			answers: [],
			updateType: 'update',
		}
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.push('/')
		} else {
			this.loadPoll()
		}
	},
	computed: {
		...mapState(['j', 'account', 'apiUrl']),
		...mapGetters(['auth', 'currentRole']),
	},
	methods: {
		formatDistanceToNow,
		loadPoll() {
			this.state = 'loading'

			heyGovApi
				.get(`${this.j.slug}/polls/${this.$route.params.pollSlug}`, { params: { nocache: random(0, 999999) } })
				.then(
					({ data }) => {
						//data.questions = data.questions

						this.poll = data
						this.state = 'idle'

						this.loadAnswers()
					},
					error => {
						Vue.toasted.show(error, { type: 'error' })
						this.state = 'error'
					}
				)
		},
		loadAnswers() {
			heyGovApi.get(`${this.j.slug}/polls/${this.$route.params.pollSlug}/answers`).then(
				({ data }) => {
					this.answers = data
				},
				error => {
					Vue.toasted.show(`Couldn't load answers ~ ${error}`, { type: 'error' })
					this.state = 'error'
				}
			)
		},
		updateSummary() {
			const toUpdate = {
				summary_title: this.poll.summary_title,
				summary_text: this.poll.summary_text,
			}

			if (this.updateType === 'publish') {
				toUpdate.status = 'completed'
				toUpdate.people_notified = this.poll.people_notified ? 1 : 0
			}

			heyGovApi.put(`${this.j.slug}/polls/${this.poll.id}`, toUpdate).then(
				({ data }) => {
					this.poll.status = data.status

					if (this.updateType === 'publish') {
						Vue.toasted.show('Straw poll summary is published', { type: 'success' })
						this.$router.push(`/${this.j.slug}/polls/${this.poll.slug}`)
					} else {
						Vue.toasted.show('Straw poll summary is saved', { type: 'success' })
					}
				},
				error => {
					Vue.toasted.show(error, { type: 'error' })
				}
			)
		},
		handleFileInput($event) {
			Array.from($event.target.files).forEach((file, index) => {
				setTimeout(() => {
					this.uploadFile(file)
				}, 250 * index)
			})
		},
		uploadFile(file) {
			this.states.fileUploading = true

			// prepare file data
			var form = new FormData()
			form.append('file', file)

			// upload the file
			heyGovApi.post(`/${this.j.slug}/polls/${this.poll.id}/summary/file`, form).then(
				({ data }) => {
					this.poll.summary_files.push(data.url)
					this.states.fileUploading = false
				},
				error => {
					Vue.toasted.show(`Error uploading file ~ ${error.message}`, { type: 'error' })
					this.states.fileUploading = false
				}
			)
		},
		removeFile(file) {
			if (confirm('Really remove this file?')) {
				heyGovApi.delete(`/${this.j.slug}/polls/${this.poll.id}/summary/file`, { params: { file } })
				this.poll.summary_files = this.poll.summary_files.filter(f => f !== file)
			}
		},
	},
}
</script>
