<template>
	<div v-if="state === 'loaded'" class="page-venue-booking">
		<div v-if="!isEmbed" class="row">
			<div class="col">
				<h3 class="mb-3">
					<router-link :to="`/${j.slug}/venues`">HeyReserve</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/venues/${venue.slug}`">{{ venue.name }}</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
					Reservation {{ $route.params.bookingUuid }}
				</h3>
			</div>
			<div class="col-auto"></div>
		</div>

		<div class="row justify-content-center">
			<div class="col-lg-8">
				<div v-if="$route.query.sent" class="card mb-4">
					<div class="card-body s-5 text-center">
						<img
							src="https://edge.heygov.com/illustrations/ok-check.jpg"
							height="200"
							alt="Booking sent"
							class="mb-3"
						/>

						<div v-if="['pending', 'working'].includes(venueBooking.status)">
							<p class="lead mb-4">
								{{
									venue.message_submitted ||
										'Your reservation has been sent to the venue. We will notify you when it has been reviewed.'
								}}
							</p>

							<p class="mb-0">
								<router-link :to="`/${j.slug}`" class="btn mx-2"
									>Back to {{ j.type }} overview</router-link
								>
								<a class="btn btn-primary mx-2" href="#booking-info">
									View details
								</a>
							</p>
						</div>
					</div>
				</div>

				<template v-if="!isEmbed">
					<div id="booking-info" class="card mb-3">
						<div class="row g-0">
							<div class="col-5">
								<img :src="venue.main_image" class="img-fluid rounded-start m-1" :alt="venue.name" />
							</div>
							<div class="col-7">
								<div class="card-body px-3 py-2">
									<h4 class="card-title">{{ venue.name }}</h4>

									<p class="card-text mb-0">
										<small class="text-secondary">From</small>
									</p>
									<p class="card-text mb-1">
										<strong>{{
											new Date(venueBooking.starts_at).toLocaleString(j.locale || 'en-US', {
												dateStyle: 'medium',
												timeStyle: 'short',
												timeZone: j.timezone,
											})
										}}</strong>
									</p>

									<p class="card-text mb-0">
										<small class="text-secondary">Until</small>
									</p>
									<p class="card-text mb-1">
										<strong>{{
											new Date(venueBooking.ends_at).toLocaleString(j.locale || 'en-US', {
												dateStyle: 'medium',
												timeStyle: 'short',
												timeZone: j.timezone,
											})
										}}</strong>
									</p>

									<div class="row">
										<div class="col">
											<p class="card-text mb-0">
												<small class="text-secondary">Price</small>
											</p>
											<p class="card-text mb-0">
												<strong>{{ venueBooking.price | currency }}</strong>
											</p>
										</div>
										<div class="col">
											<p class="card-text mb-0">
												<small class="text-secondary">Deposit</small>
											</p>
											<p class="card-text mb-0">
												<span
													:class="venueBooking.deposit ? 'fw-bolder' : 'text-neutral-300'"
													>{{ venueBooking.deposit | currency }}</span
												>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="accordion mb-3" id="booking-status-sent">
						<div class="accordion-item border-0">
							<h2 class="accordion-header" id="booking-status-sent-heading">
								<button
									class="accordion-button bg-white collapsed"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#booking-status-sent-content"
									aria-expanded="false"
									aria-controls="booking-status-sent-content"
								>
									<font-awesome-icon :icon="['fas', 'check']" class="text-success fa-fw me-2" />
									<strong>Reservation sent</strong>
								</button>
							</h2>
							<div
								id="booking-status-sent-content"
								class="accordion-collapse collapse bg-white"
								aria-labelledby="booking-status-sent-heading"
								data-bs-parent="#booking-status-sent"
							>
								<div class="accordion-body">
									<p>
										Reservation made on
										<strong>{{ venueBooking.booked_at | dateLocal }}</strong> at
										<strong>{{ venueBooking.booked_at | timeLocal }}</strong>
									</p>

									<div
										v-for="field in venue.fields.filter(f => f.type !== 'TextElement')"
										:key="`venue-field-${field.id}`"
										class="venue-field mb-3"
									>
										<span class="me-2"
											>{{ field.label }}<span v-if="!field.label.endsWith('?')">:</span></span
										>

										<file-upload-element
											v-if="field.type === 'FileUploadElement'"
											:currentField="{
												...field,
												value: venueBooking.fields_answers[field.id],
											}"
											:venueBooking="venueBooking"
											mode="preview"
										></file-upload-element>
										<signature-element
											v-else-if="field.type === 'SignatureElement'"
											:currentField="{
												...field,
												value: venueBooking.fields_answers[field.id],
											}"
											mode="preview"
										></signature-element>
										<strong v-else-if="venueBooking.fields_answers[field.id]">{{
											displayFieldAnswer(field, venueBooking.fields_answers[field.id])
										}}</strong
										><i v-else class="text-muted">no answer</i>
									</div>

									<hr class="bg-gray" />

									<p v-if="payment" class="mb-0">
										Paid <strong>{{ payment.total_paid | currency }}</strong>
									</p>
								</div>
							</div>
						</div>
					</div>

					<div
						v-if="['declined', 'canceled'].includes(venueBooking.status)"
						class="accordion mb-3"
						id="booking-status-declined"
					>
						<div class="accordion-item border-0">
							<h2 class="accordion-header" id="booking-status-declined-heading">
								<button
									class="accordion-button bg-danger-lighter"
									:class="{ collapsed: !statuses.declined }"
									type="button"
									data-bs-toggle="collapse"
									data-bs-target="#booking-status-declined-content"
									aria-expanded="true"
									aria-controls="booking-status-declined-content"
								>
									<font-awesome-icon :icon="['fas', 'ban']" class="text-danger fa-fw me-2" />

									<strong v-if="venueBooking.status === 'declined'">Reservation declined</strong>
									<strong v-else>Canceled</strong>
								</button>
							</h2>
							<div
								id="booking-status-declined-content"
								class="accordion-collapse collapse bg-white"
								:class="{ show: statuses.declined }"
								aria-labelledby="booking-status-declined-heading"
								data-bs-parent="#booking-status-declined"
							>
								<div class="accordion-body">
									<div v-if="venueBooking.status === 'declined'">
										<p class="mb-2">
											Your reservation was declined with message "{{
												venueBooking.review_message
											}}".
										</p>
										<p v-if="payment" class="mb-0">
											The full payment of <strong>{{ payment.total_paid | currency }}</strong> has
											been sent back to
											<payment-method :payment-method="payment.payment_method" />
										</p>
									</div>
									<p v-else class="mb-0">
										Your reservation has been canceled 😬
									</p>
								</div>
							</div>
						</div>
					</div>
					<div v-else>
						<div class="accordion mb-3" id="booking-status-review">
							<div class="accordion-item border-0">
								<h2 class="accordion-header" id="booking-status-review-heading">
									<button
										class="accordion-button bg-white"
										:class="{ collapsed: !statuses.review }"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#booking-status-review-content"
										aria-expanded="true"
										aria-controls="booking-status-review-content"
									>
										<span v-if="venueBooking.status === 'pending'">
											<font-awesome-icon
												:icon="['fas', 'dot-circle']"
												class="text-muted fa-fw me-2"
											/>
											<strong>Awaiting confirmation</strong>
										</span>
										<span v-else>
											<font-awesome-icon
												:icon="['fas', 'check']"
												class="text-success fa-fw me-2"
											/>
											<strong>Reservation confirmed</strong>
										</span>
									</button>
								</h2>
								<div
									id="booking-status-review-content"
									class="accordion-collapse collapse bg-white"
									:class="{ show: statuses.review }"
									aria-labelledby="booking-status-review-heading"
									data-bs-parent="#booking-status-review"
								>
									<div class="accordion-body">
										<p v-if="venueBooking.status === 'pending'" class="mb-0">
											Someone at the venue needs to review your reservation 🧑‍💻
										</p>
										<p v-else class="mb-0">
											Your reservation has been approved with message "{{
												venueBooking.review_message
											}}"
										</p>
									</div>
								</div>
							</div>
						</div>

						<div class="accordion mb-3" id="booking-status-progress">
							<div class="accordion-item border-0">
								<h2 class="accordion-header" id="booking-status-progress-heading">
									<button
										class="accordion-button bg-white"
										:class="{ collapsed: !statuses.progress }"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#booking-status-progress-content"
										aria-expanded="true"
										aria-controls="booking-status-progress-content"
									>
										<span v-if="['ended', 'completed'].includes(venueBooking.status)">
											<font-awesome-icon
												:icon="['fas', 'check']"
												class="text-success fa-fw me-2"
											/>
											<strong>It has happened</strong>
										</span>
										<span v-else-if="venueBooking.status === 'progress'">
											<font-awesome-icon
												:icon="['fas', 'dot-circle']"
												class="text-success fa-fw me-2"
											/>
											<strong>Happening now</strong>
										</span>
										<span v-else>
											<font-awesome-icon
												:icon="['fas', 'ellipsis']"
												class="text-muted fa-fw me-2"
											/>
											<strong>Checking in</strong>
										</span>
									</button>
								</h2>
								<div
									id="booking-status-progress-content"
									class="accordion-collapse collapse bg-white"
									:class="{ show: statuses.progress }"
									aria-labelledby="booking-status-progress-heading"
									data-bs-parent="#booking-status-progress"
								>
									<div class="accordion-body">
										<p v-if="['ended', 'completed'].includes(venueBooking.status)" class="mb-0">
											Your reservation has been ended. Check below for the check-out details.
										</p>
										<p v-else-if="['progress'].includes(venueBooking.status)" class="mb-0">
											Your reservation is happening now 🎉
										</p>
										<div v-else>
											<p class="mb-2">The time for your reservation will come soon.</p>
											<p class="mb-0">{{ venue.message_check_in }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="accordion mb-3" id="booking-status-complete">
							<div class="accordion-item border-0">
								<h2 class="accordion-header" id="booking-status-complete-heading">
									<button
										class="accordion-button bg-white"
										:class="{ collapsed: !statuses.completed }"
										type="button"
										data-bs-toggle="collapse"
										data-bs-target="#booking-status-complete-content"
										aria-expanded="true"
										aria-controls="booking-status-complete-content"
									>
										<span v-if="venueBooking.status === 'completed'">
											<font-awesome-icon
												:icon="['fas', 'check']"
												class="text-success fa-fw me-2"
											/>
											<strong>Checked-out and inspected</strong>
										</span>
										<span v-else-if="venueBooking.status === 'ended'">
											<font-awesome-icon
												:icon="['fas', 'dot-circle']"
												class="text-success fa-fw me-2"
											/>
											<strong>Checked-out and awaiting inspection</strong>
										</span>
										<span v-else>
											<font-awesome-icon
												:icon="['fas', 'ellipsis']"
												class="text-muted fa-fw me-2"
											/>
											<strong>Checking out</strong>
										</span>
									</button>
								</h2>
								<div
									id="booking-status-complete-content"
									class="accordion-collapse collapse bg-white"
									:class="{ show: statuses.completed }"
									aria-labelledby="booking-status-complete-heading"
									data-bs-parent="#booking-status-complete"
								>
									<div class="accordion-body">
										<p v-if="venueBooking.status === 'completed'" class="mb-0">
											Someone inspected the venue and said "{{
												venueBooking.inspection_details
											}}".
											<span v-if="payment"
												>Refunded amount: {{ payment.refunded | currency }}</span
											>
										</p>
										<p v-else-if="venueBooking.status === 'ended'" class="mb-0">
											The venue has been checked-out and is awaiting inspection. You'll receive a
											notification when this happens.
										</p>
										<div v-else>
											<p class="mb-2">
												Here's how to ensure a smooth check-out and receive your full deposit
												back.
											</p>
											<p class="mb-0">{{ venue.message_check_out }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
	<div v-else class="text-center py-4">
		<span class="spinner-border"></span>
	</div>
</template>

<script>
import Vue from 'vue'
import heyGovApi from '@/api.js'
import { mapGetters, mapState } from 'vuex'
import { displayFieldAnswer } from '@/actions/form-builder.js'

import FileUploadElement from '@/components/form-builder/FileUploadElement'
import SignatureElement from '@/components/form-builder/SignatureElement'
import PaymentMethod from '../../components/payments/PaymentMethod.vue'
import { differenceInHours } from 'date-fns'

export default {
	name: 'VenueBookingInfo',
	metaInfo() {
		return {
			title: `Booking for ${this.venue?.name || this.$route.params.venueSlug}`,
		}
	},
	components: { PaymentMethod, FileUploadElement, SignatureElement },
	props: {
		isEmbed: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			state: 'loading',
			venue: null,
			venueBooking: null,
			payment: null,
			statuses: {
				review: false,
				declined: false,
				progress: false,
				completed: false,
			},
		}
	},
	computed: {
		...mapState(['j', 'forms', 'departments']),
		...mapGetters(['auth']),
		selectedSlot() {
			return this.venue.slots.find(slot => slot.id == this.venueBooking.venue_slot_id)
		},
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadVenueAndBooking()
	},
	methods: {
		displayFieldAnswer,
		differenceInHours,

		loadVenueAndBooking() {
			this.state = 'loading'

			Promise.all([
				heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}?expand=fields`),
				heyGovApi.get(`${this.j.slug}/venue-bookings/${this.$route.params.bookingUuid}?expand=payment`),
			]).then(
				([venueResponse, venueBookingResponse]) => {
					this.venue = venueResponse.data
					this.venueBooking = venueBookingResponse.data
					this.state = 'loaded'

					if (this.venueBooking.status === 'pending') {
						this.statuses.review = true
					} else if (this.venueBooking.status === 'declined' || this.venueBooking.status === 'canceled') {
						this.statuses.declined = true
					} else if (this.venueBooking.status === 'approved' || this.venueBooking.status === 'progress') {
						this.statuses.progress = true
					} else if (this.venueBooking.status === 'ended' || this.venueBooking.status === 'completed') {
						this.statuses.completed = true
					}

					this.payment = venueBookingResponse.data.payment || null
				},
				error => {
					Vue.toasted.error(`Error loading venue and booking (${error})`)
					this.state = 'error'
				}
			)
		},
	},
}
</script>
