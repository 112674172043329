<template>
	<p>Create event</p>
</template>

<script>
export default {
	name: 'EventCreate',
	data() {
		return {}
	},
}
</script>
