<template>
	<div class="element-edit">
		<!-- Settings -->
		<form id="settings">
			<!-- Label -->
			<div v-if="this.current.type !== 'TextElement'" class="form-group mb-3">
				<label for="label" class="form-label">Label</label>

				<input class="form-control" id="label" v-model="current.label" />
			</div>

			<vue-editor
				v-if="current.type === 'TextElement'"
				v-model="current.value"
				:customModules="customModulesForEditor"
				:editorOptions="editorSettings"
				:editor-toolbar="editorToolbar"
			></vue-editor>

			<!-- Number Input settings -->
			<div class="row" v-if="current.type === 'NumberInputElement'">
				<div class="form-group col-md-4 mb-3">
					<label for="min" class="form-label">Min</label>
					<input type="number" class="form-control form-control-sm" v-model="current.data.min" id="min" />
				</div>

				<div class="form-group col-md-4 mb-3">
					<label for="max" class="form-label">Max</label>
					<input type="number" class="form-control form-control-sm" v-model="current.data.max" id="max" />
				</div>
			</div>

			<!-- Placeholder -->
			<div class="form-group mb-3" v-if="displayPlaceholder()">
				<label for="placeholder" class="form-label">Placeholder text</label>

				<input
					class="form-control form-control-sm"
					v-model="current.placeholder"
					placeholder="What text to show when the field is empty?"
				/>
			</div>

			<!-- Date Input settings -->
			<div v-if="current.type === 'DatePickerElement'" class="row">
				<!-- Min -->
				<div class="form-group col-md-6 mb-3">
					<label for="max" class="form-label">Min date</label>
					<div class="form-check form-check-inline ms-1 float-end">
						<input
							type="checkbox"
							v-model="current.data.useDynamicMinDate"
							class="form-check-input"
							id="useDynamicMinDate"
							role="button"
						/>
						<label class="form-check-label" for="useDynamicMinDate" role="button">
							dynamic min date
						</label>
					</div>
					<input
						v-if="!current.data.useDynamicMinDate"
						type="date"
						class="form-control form-control-sm"
						v-model="current.data.minDate"
					/>

					<select
						v-if="current.data.useDynamicMinDate"
						v-model="current.data.minDateDynamic"
						class="form-select form-select-sm"
					>
						<option
							v-for="(dateElement, index) in getDateElements()"
							:key="index"
							:value="'date-' + dateElement.id"
						>
							{{ dateElement.label }}
						</option>
						<option value="today">Today's date</option>
					</select>
				</div>

				<!-- Max -->
				<div class="form-group col-md-6 mb-3">
					<label for="max" class="form-label">Max date</label>
					<div class="form-check form-check-inline ms-1 float-end">
						<input
							type="checkbox"
							v-model="current.data.useDynamicMaxDate"
							class="form-check-input"
							id="useDynamicMaxDate"
							role="button"
						/>
						<label class="form-check-label" for="useDynamicMaxDate" role="button">
							dynamic max date
						</label>
					</div>
					<input
						v-if="!current.data.useDynamicMaxDate"
						type="date"
						class="form-control form-control-sm"
						v-model="current.data.maxDate"
					/>

					<select
						v-if="current.data.useDynamicMaxDate"
						v-model="current.data.maxDateDynamic"
						class="form-select form-select-sm"
					>
						<option
							v-for="(dateElement, index) in getDateElements()"
							:key="index"
							:value="'date-' + dateElement.id"
						>
							{{ dateElement.label }}
						</option>
						<option value="today">Today's date</option>
					</select>
				</div>
			</div>

			<!-- Help & size -->
			<div class="row">
				<!-- Help -->
				<div class="form-group col-md-8 mb-3" v-if="current.type !== 'TextElement'">
					<label for="help" class="form-label">Help text</label>

					<input
						class="form-control form-control-sm"
						v-model="current.help"
						placeholder="Text that appears under the field input"
					/>
				</div>
			</div>
			<!-- #Help & size -->

			<!-- Required -->
			<div class="form-check form-check-inline mb-3" v-if="displayRequired()">
				<input
					type="checkbox"
					v-model="current.required"
					class="form-check-input"
					id="required"
					role="button"
				/>
				<label class="form-check-label" for="required" role="button">Make field required</label>
			</div>

			<table
				v-if="['RadioButtonElement', 'SelectListElement', 'CheckboxElement'].includes(current.type)"
				class="table table-hover"
			>
				<thead>
					<tr>
						<th @dblclick="states.showOptionValues = !states.showOptionValues">Option</th>
						<th v-if="states.showOptionValues">Value</th>
						<th v-if="current.type === 'CheckboxElement'">Additional</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(option, optionIndex) in current.options.filter(
							o => states.showRemovedOptions || !o.removed
						)"
						:key="`field-${current.id}-opt-${optionIndex}`"
						:class="{ 'bg-danger-50': option.removed }"
					>
						<td>
							<input type="text" v-model="option.optionLabel" class="form-control form-control-sm" />
						</td>
						<td v-if="states.showOptionValues">
							<input
								type="text"
								v-model="option.optionValue"
								class="form-control form-control-sm"
								readonly
							/>
						</td>
						<td v-if="current.type === 'CheckboxElement'">
							<div class="form-check ms-2">
								<input
									type="checkbox"
									v-model="option.addAdditionalValue"
									class="form-check-input"
									:id="`field-${current.id}-opt-${optionIndex}-extra`"
									role="button"
								/>
								<label class="form-check-label" :for="`field-${current.id}-opt-${optionIndex}-extra`">
									Additional value</label
								>
							</div>
						</td>
						<td class="text-end">
							<button
								type="button"
								v-if="option.removed"
								@click="restoreOption(option)"
								class="btn btn-sm btn-icon-info"
								title="Restore option"
							>
								<font-awesome-icon :icon="['fas', 'undo']" />
							</button>
							<button
								v-else
								type="button"
								@click="removeOption(option)"
								class="btn btn-sm btn-icon-danger"
							>
								<font-awesome-icon :icon="['fas', 'times']" />
							</button>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="1">
							<button
								type="button"
								@click="addOptionItem"
								class="btn btn-sm btn-big-icon btn-outline-primary"
							>
								Add option <font-awesome-icon :icon="['fas', 'plus-circle']" />
							</button>
						</td>
						<td colspan="3">
							<div
								v-if="current.options.filter(o => o.removed).length"
								class="form-check form-switch float-end"
							>
								<input
									type="checkbox"
									class="form-check-input"
									v-model="states.showRemovedOptions"
									id="show-remove-options"
								/>
								<label class="form-check-label text-neutral-400" for="show-remove-options">
									Show removed options
								</label>
							</div>
						</td>
					</tr>
				</tfoot>
			</table>

			<!-- Advanced Features Accordion -->
			<div class="advanced-features shadow-sm rounded mt-3">
				<div
					class="p-3 rounded d-flex justify-content-between align-items-center bg-light"
					data-bs-toggle="collapse"
					data-bs-target="#advanced-features"
					aria-expanded="false"
					aria-controls="advanced-features"
					role="button"
				>
					<h5 class="mb-0">Advanced options</h5>
					<font-awesome-icon :icon="['fas', 'chevron-down']" />
				</div>
				<div class="body collapse" id="advanced-features">
					<div class="row p-3">
						<!-- Class - additional class to apply on field -->
						<div class="form-group col-md-6 mb-2">
							<label for="class" class="ms-2">Class</label>
							<input
								class="form-control"
								v-model="current.class"
								placeholder="enter class for field (optional)"
							/>
						</div>

						<!-- Prepend - prepend for field -->
						<div
							class="form-group col-md-6 mb-2"
							v-if="current.type === 'TextInputElement' || current.type === 'NumberInputElement'"
						>
							<label for="prepend" class="ms-2">Prepend</label>
							<input
								class="form-control"
								v-model="current.data.prepend"
								placeholder="Prepend for field"
							/>
						</div>

						<!-- Append - prepend for field -->
						<div
							class="form-group col-md-6 mb-2"
							v-if="current.type === 'TextInputElement' || current.type === 'NumberInputElement'"
						>
							<label for="append" class="ms-2">Append</label>
							<input
								class="form-control"
								v-model="current.data.append"
								placeholder="Append for element"
							/>
						</div>

						<!-- NumberInputElement advanced features -->
						<div class="col-md-12 mb-2" v-if="current.type === 'NumberInputElement'">
							<hr />
							<!-- Standard Number or Input type numeric -->
							<div class="form-group">
								<div class="form-check">
									<input
										type="checkbox"
										v-model="current.data.standardNumberInput"
										class="form-check-input"
										id="standardNumberInput"
										role="button"
									/>
									<label role="button" class="form-check-label" for="standardNumberInput">
										standard</label
									>
									<small>
										<font-awesome-icon :icon="['fas', 'info-circle']" class="fa-fw text-primary" />
										(If checked using standard number input if not using inputmode numeric )
									</small>
								</div>
							</div>

							<div class="form-group">
								<div class="form-check form-check-inline mt-2">
									<input
										type="checkbox"
										v-model="current.data.useDynamicValue"
										class="form-check-input"
										id="useDynamicValue"
										role="button"
									/>
									<label class="form-check-label" for="useDynamicValue" role="button">
										use dynamic value
									</label>
								</div>
							</div>

							<div class="row">
								<div class="col-6" v-if="current.data.useDynamicValue">
									<label for="">Element</label>
									<select v-model="current.data.valueElement" name="" class="form-select" id="">
										<template v-for="(dateElement, index) in getDateElements()">
											<option :key="index" :value="'date-year-' + dateElement.id">
												{{ dateElement.label }}
											</option>
										</template>
										<option value="current">Current year</option>
									</select>
								</div>
								<div class="col-6" v-if="current.data.useDynamicValue">
									<label for="">Value calculation</label>
									<select v-model="current.data.valueCalculation" name="" class="form-select" id="">
										<option value="days-from-date-to-now">Days from date</option>
										<option value="years-from-date-to-now">Years from date</option>
									</select>
								</div>
							</div>
						</div>

						<!-- Multiple select or multiple upload-->
						<div
							class="col-md-12 mb-2"
							v-if="current.type === 'SelectListElement' || current.type === 'FileUploadElement'"
						>
							<div class="form-check ms-2">
								<input
									type="checkbox"
									v-model="current.data.multiple"
									class="form-check-input"
									id="multiple"
									role="button"
								/>
								<label class="form-check-label" for="multiple" role="button"> multiple</label>
							</div>
						</div>

						<!-- Check radio inline -->
						<div
							class="col-md-12 mb-2"
							v-if="current.type === 'RadioButtonElement' || current.type === 'CheckboxElement'"
						>
							<div class="form-check">
								<input
									type="checkbox"
									v-model="current.data.check_radio_inline"
									class="form-check-input"
									id="check_radio_inline"
								/>
								<label class="form-check-label" for="check_radio_inline">
									display options inline
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- #Advanced Features Accordion -->

			<!-- Remove Element Accordion -->
			<div class="remove shadow-sm rounded mt-3" v-if="!isNewField">
				<div
					class="p-3 rounded d-flex justify-content-between align-items-center bg-danger-lighter"
					data-bs-toggle="collapse"
					data-bs-target="#remove"
					aria-expanded="false"
					aria-controls="remove"
					@click="removeElementInput = ''"
					role="button"
				>
					<h5 class="text-danger mb-0">Remove element</h5>
					<font-awesome-icon :icon="['fas', 'chevron-down']" class="fa-fw text-danger" />
				</div>
				<div class="body collapse" id="remove">
					<div class="p-3">
						<h5 class="text-center">Are you sure want to remove this element?</h5>
						<br />

						<div class="d-flex justify-content-end align-items-center">
							<a
								class="btn btn-link"
								data-bs-toggle="collapse"
								data-bs-target="#remove"
								aria-expanded="false"
								aria-controls="remove"
								@click="removeElementInput = ''"
							>
								Cancel
							</a>
							<a class="btn btn-danger" @click="removeElement">
								Remove
							</a>
						</div>
					</div>
				</div>
			</div>
			<!-- #Remove Element Accordion -->
		</form>
		<!-- #Settings -->

		<!-- Element conditions switch -->
		<div class="form-check form-switch mb-3 mt-3">
			<input
				type="checkbox"
				role="button"
				class="form-check-input"
				v-model="conditionalLogic"
				id="conditionalLogic"
			/>
			<label role="button" class="form-check-label" for="conditionalLogic">{{
				current.type === 'PaymentElement' ? 'Payment logic' : 'Conditional logic'
			}}</label>
		</div>
		<!-- #Element conditions switch -->

		<!-- Element Conditions -->
		<div class="shadow-sm rounded" v-if="current.type !== 'PaymentElement' && conditionalLogic">
			<div class="p-3">
				<span><font-awesome-icon :icon="['fas', 'microchip']" class="" /> Conditional logic</span>
			</div>
			<div class="p-3 pt-0">
				<form :model="current">
					<div class="fs-6 mb-3">
						in this section we specify conditions when some element need to appear or when some element is
						required, based on another element. ⚠️ be careful with multiple conditions.
					</div>
					<div class="row">
						<div class="col-md-3">Type</div>
						<div class="col-md-3">Element</div>
						<div class="col-md-2">Condition</div>
						<div class="col-md-2">Value</div>
					</div>

					<template v-for="(condition, conditionIndex) in current.data.conditions">
						<div :key="`div-option-${conditionIndex}`" class="mt-2">
							<div class="row">
								<div class="col-md-3 d-flex align-items-center">
									<select class="form-select" v-model="condition.type">
										<option value="visible-if" selected>Visible If</option>
										<option value="required-if" disabled>Required If (soon)</option>
									</select>
								</div>
								<div class="col-md-3 d-flex align-items-center">
									<select class="form-select" v-model="condition.element">
										<option
											v-for="(el, elIndex) in fieldConditionFields(fields)"
											:key="elIndex"
											:value="el.id"
										>
											{{ conditionFieldString(el) }}
										</option>
									</select>
								</div>

								<div class="col-md-2 d-flex align-items-center">
									<select
										class="form-select"
										@change="condition.conditionValue = ''"
										v-model="condition.condition"
									>
										<option value="equal" selected>=</option>
										<option value="notEqual">!=</option>
										<option value="empty">Empty</option>
										<option value="notEmpty">Not Empty</option>
									</select>
								</div>

								<div class="col-md-2 d-flex align-items-center">
									<input
										v-if="conditionValueField('text', condition)"
										type="text"
										name="optionValue"
										v-model="condition.conditionValue"
										class="form-control"
									/>
									<select
										v-if="conditionValueField('select', condition)"
										class="form-select"
										v-model="condition.conditionValue"
									>
										<option
											v-for="(condV, condI) in conditionElementOptions(condition)"
											:key="condI"
											:value="condV.optionValue"
										>
											{{ truncateString(condV.optionLabel, 48) }}
										</option>
									</select>
								</div>

								<div class="col-md-2 d-flex align-items-center">
									<a
										@click="removeElementCondition(conditionIndex)"
										class="btn btn-sm btn-icon-danger"
										title="Remove condition"
									>
										<font-awesome-icon :icon="['fas', 'times']" />
									</a>
								</div>
							</div>
						</div>
					</template>
					<div class="mt-4">
						<a @click="addElementCondition()" class="btn btn-primary btn-sm">
							<font-awesome-icon :icon="['fas', 'plus-circle']" class="fa-fw" />
							New condition
						</a>
					</div>
				</form>
			</div>
		</div>
		<!-- #Element Conditions -->

		<!-- Offcanvas with variables -->
		<div
			class="offcanvas offcanvas-end"
			tabindex="-1"
			id="variablesOffcanvas"
			aria-labelledby="variablesOffcanvasLabel"
		>
			<div class="offcanvas-header">
				<h5 class="offcanvas-title" id="variablesOffcanvasLabel">Available variables</h5>

				<button
					type="button"
					class="btn-close text-reset"
					data-bs-dismiss="offcanvas"
					aria-label="Close"
				></button>
			</div>
			<div class="offcanvas-body">
				<small class="mb-2">
					Copy the variable with parentheses and paste in the text where you want the value to appear. Click
					on the variable to copy it.
				</small>

				<!-- Available Variables -->
				<div v-if="current.type === 'TextElement'" class="row mb-2 mt-2">
					<div class="col-12">
						<table class="table table-hover">
							<thead>
								<tr>
									<th class="w-50">Element</th>
									<th class="w-50">Variabe</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="element in fields">
									<template v-if="displayVariableElement(element)">
										<tr :key="'el-variable-' + element.id">
											<td>
												{{ element.label }}
											</td>
											<td>
												<span
													role="button"
													title="Display element value"
													@click="copyToClipboard(printVariable(element))"
													class="badge bg-success-lighter text-success me-1"
													>{{ printVariable(element) }}</span
												>

												<span
													v-if="element.type === 'DatePickerElement'"
													title="Calculate years from this date"
													@click="copyToClipboard(printDateVariable(element))"
													role="button"
													class="badge bg-success-lighter text-success me-1 mt-2"
													>{{ printDateVariable(element) }}</span
												>
											</td>
										</tr>
									</template>
								</template>
								<tr>
									<td>Current year</td>
									<td>
										<span
											title="Display current year"
											@click="copyToClipboard('{{ year }}')"
											role="button"
											class="badge bg-success-lighter text-success me-1"
											>{{ '{{' }} year }}</span
										>
									</td>
								</tr>
								<tr>
									<td>Next year</td>
									<td>
										<span
											title="Display next year"
											@click="copyToClipboard('{{ nextyear }}')"
											role="button"
											class="badge bg-success-lighter text-success me-1"
											>{{ '{{' }} nextyear }}</span
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.remove-price-condition {
	position: absolute;
	right: 5px;
	top: -40px;
}
.price-condition-price-wrapper {
	margin-top: -13px;
	padding-left: 10px;
	.form-control {
		border-color: var(--bs-primary);
	}
	#currency-addon {
		background-color: var(--bs-primary);
		border-color: var(--bs-primary);
	}
}
</style>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { VueEditor, Quill } from 'vue2-editor'
import QuillResize from 'quill-resize-module'

import heyGovApi from '@/api.js'
import { copyToClipboard, generateId, truncateString } from '@/utils.js'

Quill.register('modules/resize', QuillResize)

export default {
	name: 'FieldProperties',
	components: {
		VueEditor,
	},
	data() {
		return {
			states: {
				showOptionValues: false,
				showRemovedOptions: false,
			},
			optionsAdded: [],
			showOptionValues: false,
			showColumnProps: false,
			removeElementInput: '',
			editorToolbar: [
				[{ header: [false, 1, 2, 3, 4] }],
				['bold', 'italic', 'underline', 'strike'], // toggled buttons
				[{ align: '' }, { align: 'center' }],
				[{ list: 'ordered' }, { list: 'bullet' }],
				['link'],
			],
			customModulesForEditor: [{ alias: 'QuillResize', module: QuillResize }],
			editorSettings: {
				modules: {
					QuillResize: { modules: ['Resize', 'DisplaySize'] },
				},
			},
			conditionalLogic: false,
			displayAllOptions: false,
		}
	},
	props: ['current', 'fields', 'isNewField'],
	computed: {
		...mapState(['j', 'departments']),
		...mapGetters(['activeDepartments']),
	},
	methods: {
		copyToClipboard,
		truncateString,

		// Condition fields, remove current field from options
		fieldConditionFields(fields) {
			let newFields = [...fields]
			let indexCurrentField = 1

			newFields.forEach((e, i) => {
				if (e.id === this.current.id) {
					indexCurrentField = i
				}
			})

			newFields.splice(indexCurrentField, 1)
			return newFields
		},

		// Create better string for selecting fields in conditions
		conditionFieldString(el) {
			let fieldString = ''
			let substring = ''
			if (el.type === 'TextInput') {
				el.value = el.value.replace(/<\/?[^>]+(>|$)/g, '')
			}

			fieldString = truncateString(el.label + substring, 48)

			return fieldString
		},

		// Show element value field based on element type (text or select)
		conditionValueField(fieldType, condition) {
			let showField = false
			if (condition.element != null && condition.element != '') {
				let element = this.fields.find(x => x.id === condition.element)

				if (
					fieldType === 'text' &&
					(element.type === 'TextInputElement' ||
						element.type === 'EmailInputElement' ||
						element.type === 'PhoneInputElement' ||
						(element.type === 'NumberInputElement' && element.data.useDynamicValue) ||
						(element.type === 'YearInput' &&
							(element.data.useDynamicMinYear || !element.data.useDynamicMaxYear)) ||
						element.type === 'TableInput' ||
						element.type === 'TextareaInputElement') &&
					(condition.condition === 'equal' || condition.condition === 'notEqual')
				) {
					showField = true
				} else if (
					fieldType === 'select' &&
					(element.type === 'RadioButtonElement' ||
						element.type === 'CheckboxElement' ||
						element.type === 'SelectListElement' ||
						(element.type === 'YearInput' &&
							!element.data.useDynamicMinYear &&
							!element.data.useDynamicMaxYear)) &&
					(condition.condition === 'equal' || condition.condition === 'notEqual')
				) {
					showField = true
				}
			}

			return showField
		},

		// In element conditions add select input for conditionValue with element options if element have options
		conditionElementOptions(condition) {
			let options = []
			if (condition.element != '') {
				let element = this.fields.find(x => x.id === condition.element)
				if (element.options !== null) {
					element.options.forEach(option => {
						options.push(option)
					})
				}
			}
			return options
		},

		// Add element condition
		addElementCondition() {
			let id = Date.now()
			if (this.fields.length > 1) {
				if (!('conditions' in this.current.data)) {
					this.current.data = {
						...this.current.data,
						conditions: [
							{
								id,
								type: 'visible-if',
								element: '',
								condition: 'equal',
								conditionValue: '',
							},
						],
					}
				} else {
					let conditionObject = {
						id,
						type: 'visible-if',
						element: '',
						condition: 'equal',
						conditionValue: '',
					}
					this.current.data.conditions.push(conditionObject)
				}
			} else {
				Vue.toasted.show(`There is no other elements in venue to make conditions!`)
			}
		},

		// Remove element condition
		removeElementCondition(index) {
			this.current.data.conditions.splice(index, 1)
		},

		// Add new option item for Select List, Checkbox, Radio button
		addOptionItem() {
			const optionValue = generateId('opt')
			this.optionsAdded.push(optionValue)

			this.current.options.push({
				optionLabel: '',
				optionValue,
				selected: false,
				removed: false,
				addAdditionalValue: false,
				additionalValue: '',
			})
		},

		// Remove option item for Select List, Checkbox, Radio button
		removeOption(option) {
			if (this.optionsAdded.includes(option.optionValue)) {
				this.current.options = this.current.options.filter(x => x.optionValue !== option.optionValue)
			} else if (confirm('For sure remove this option?')) {
				option.removed = true
			}
		},
		restoreOption(option) {
			option.removed = false
		},

		// Remove field from venue
		removeElement() {
			if (this.elementUsedInRequests) {
				heyGovApi
					.put(`/${this.j.slug}/venue-fields/${this.current.id}`, {
						removed: true,
					})
					.then(
						() => {
							this.current.removed = true
							Vue.toasted.show(`"${this.current.label}" element is removed!`)
						},
						error => {
							Vue.toasted.error(error)
						}
					)
			} else {
				// Remove from database
				heyGovApi.delete(`/${this.j.slug}/venue-fields/${this.current.id}`).then(
					() => {
						this.fields.splice(this.current.order_number, 1)
						Vue.toasted.show(`"${this.current.label}" element is removed!`)
					},
					error => {
						Vue.toasted.error(error.message)
					}
				)
			}

			this.$emit('fieldRemoved')
		},

		// Display placeholder
		displayPlaceholder() {
			return [
				'TextInputElement',
				'NumberInputElement',
				'EmailInputElement',
				'PhoneInputElement',
				'AddressInputElement',
				'TextareaInputElement',
			].includes(this.current.type)
		},

		// Display required
		displayRequired() {
			return !['TextElement', 'PaymentElement'].includes(this.current.type)
		},

		displayVariableElement(element) {
			return !['TextElement', 'PaymentElement'].includes(element.type)
		},

		// Get Date input elements from venue
		getDateElements() {
			return this.fields.filter(element => element.name === 'DatePicker' && element.id !== this.current.id)
		},

		printVariable(element) {
			return '{{ field' + element.id + ' }}'
		},

		printDateVariable(element) {
			return '{{ dateToYears(' + element.label + ') }}'
		},
	},
	created() {
		this.$store.dispatch('loadDepartments')
	},
	updated() {
		if (
			!this.conditionalLogic &&
			(this.current.data?.conditions?.length > 0 || this.current.data?.priceConditions?.length > 0)
		) {
			this.conditionalLogic = true
		}
	},
}
</script>
