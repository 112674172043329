<template>
	<table v-if="mode === 'preview'" class="table table-hover table-sm">
		<thead>
			<tr class="bg-info-lighter">
				<th
					v-for="(column, index) in currentField.data.tableColumns"
					:key="index"
					:style="'width:' + column.width"
					class="fw-normal py-1 align-middle"
				>
					{{ column.label }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="data in currentField.value" :key="`table1-${currentField.id}-data-${data.id}`">
				<td
					v-for="column in currentField.data.tableColumns"
					:key="`table1-${currentField.id}-data-${data.id}-${column.prop}`"
				>
					{{ data[column.prop] }}
				</td>
			</tr>
		</tbody>
	</table>

	<div v-else-if="!currentField.pdf" :class="currentField.class" :id="'fsf-' + currentField.id">
		<div class="d-none d-md-block">
			<table class="table table-hover">
				<thead>
					<tr class="bg-info-lighter">
						<th
							v-for="(column, index) in filterColumnsWithoutJoin()"
							:key="index"
							:style="'width:' + column.width"
							class="fw-normal py-1 align-middle"
						>
							<template v-if="columnsToJoin(column).length > 0">
								<span style="font-size:12px;vertical-align: super;">(1) </span
								><span>{{ column.label }}</span>
								<template v-for="(c, i) in columnsToJoin(column)">
									<div :key="'c-prop-' + c.prop">
										<span>
											<span style="font-size:12px;vertical-align: super;"> ({{ i + 2 }}) </span>
											{{ c.label }}
										</span>
									</div>
								</template>
							</template>
							<template v-else>
								{{ column.label }}
							</template>
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(data, indexData) in currentField.value"
						:key="`table1-${currentField.id}-data-${data.id}`"
						class="on-parent"
					>
						<td
							v-for="(column, index) in filterColumnsWithoutJoin()"
							:key="column.id + '-' + index"
							:style="'width:' + column.width"
							class="align-middle"
						>
							<template v-if="columnsToJoin(column).length > 0">
								<small
									style="position:absolute;margin-left:5px;font-size:10px;margin-top:2px"
									:title="column.label"
									>(1)</small
								>
								<input
									v-if="column.inputType !== 'text'"
									class="form-control form-control-sm mb-1"
									:type="column.inputType"
									v-model="data[column.prop]"
								/>
								<template v-if="column.inputType === 'text'">
									<select
										:class="'form-select form-select-sm mb-1'"
										v-if="column.valueType !== 'default'"
										v-model="data[column.prop]"
									>
										<option
											v-for="item in createOptionsArray(column.predefinedValues)"
											:key="item"
											:value="item"
											:label="item"
										></option>
									</select>
									<input
										v-if="column.valueType === 'default'"
										class="form-control form-control-sm mb-1"
										:type="column.inputType"
										v-model="data[column.prop]"
									/>
								</template>
								<template v-for="(c, index) in columnsToJoin(column)">
									<small
										style="position:absolute;margin-left:5px;font-size:10px;margin-top:2px"
										:key="'info-' + index"
										:title="c.label"
										>({{ index + 2 }})</small
									>
									<input
										:key="'c-prop-' + c.prop"
										v-if="c.inputType !== 'text'"
										class="form-control form-control-sm mb-1"
										:type="c.inputType"
										v-model="data[c.prop]"
									/>
									<template v-if="c.inputType === 'text'">
										<select
											:key="'c-prop-' + c.prop"
											:class="'form-select form-select-sm mb-1'"
											v-if="c.valueType !== 'default'"
											v-model="data[c.prop]"
										>
											<option
												v-for="item in createOptionsArray(c.predefinedValues)"
												:key="item"
												:value="item"
												:label="item"
											></option>
										</select>
										<input
											:key="'c-prop-' + c.prop"
											v-if="c.valueType === 'default'"
											class="form-control form-control-sm mb-1"
											:type="c.inputType"
											v-model="data[c.prop]"
										/>
									</template>
								</template>
							</template>
							<template v-else>
								<input
									v-if="column.inputType !== 'text'"
									class="form-control form-control-sm"
									:type="column.inputType"
									v-model="data[column.prop]"
								/>
								<template v-if="column.inputType === 'text'">
									<select
										:class="'form-select form-select-sm'"
										v-if="column.valueType !== 'default'"
										v-model="data[column.prop]"
									>
										<option
											v-for="item in createOptionsArray(column.predefinedValues)"
											:key="item"
											:value="item"
											:label="item"
										></option>
									</select>
									<input
										v-if="column.valueType === 'default'"
										class="form-control form-control-sm"
										:type="column.inputType"
										v-model="data[column.prop]"
									/>
								</template>
							</template>
						</td>
						<td>
							<button
								v-if="currentField.value.length > 1"
								type="button"
								class="btn btn-sm btn-icon-danger mt-1 show-on-hover"
								@click="handleDelete(indexData)"
							>
								<font-awesome-icon :icon="['fas', 'times']" />
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="d-md-none">
			<template v-for="(data, indexData) in currentField.value">
				<div :key="`table2-${currentField.id}-data-${data.id}`" class="card p-3 mb-3">
					<button
						v-if="currentField.value.length > 1"
						type="button"
						class="btn btn-sm btn-icon-danger position-absolute"
						style="top: -5px; right: -5px"
						@click="handleDelete(indexData)"
					>
						<font-awesome-icon :icon="['fas', 'times']" />
					</button>

					<div class="form-group mb-2" :key="index" v-for="(column, index) in currentField.data.tableColumns">
						<label class="form-label small mb-1">{{ column.label }}</label>

						<input
							v-if="column.inputType !== 'text'"
							class="form-control form-control-sm"
							:type="column.inputType"
							v-model="data[column.prop]"
						/>
						<template v-else-if="column.inputType === 'text'">
							<input
								v-if="column.valueType === 'default'"
								class="form-control form-control-sm"
								:type="column.inputType"
								v-model="data[column.prop]"
							/>
							<select v-else class="form-select form-select-sm" v-model="data[column.prop]">
								<option
									v-for="item in createOptionsArray(column.predefinedValues)"
									:key="item"
									:value="item"
									>{{ item }}</option
								>
							</select>
						</template>
					</div>
				</div>
			</template>
		</div>

		<button
			class="btn btn-primary btn-sm mb-2 add-column-btn"
			type="button"
			@click="handleAdd"
			v-if="currentField.value.length < (currentField.data.maxLines || 10)"
		>
			{{ currentField.data.addButtonText }}
		</button>
	</div>
	<!-- PDF Display -->
	<div v-else>
		<div v-if="currentField.display !== 'form'">
			<label class="pdf-label form-label" for="">{{ currentField.label }} </label>

			<!-- If table data is redacted -->
			<div v-if="this.$route.query.redacted && currentField.redacted" class="pdf-redacted">
				<div class="pdf-redacted"></div>
				<div class="pdf-redacted"></div>
			</div>
			<div v-else>
				<table class="table table-bordered border-dark table-element">
					<thead>
						<tr class="bg-info-lighter">
							<th
								v-for="(column, index) in filterColumnsWithoutJoin()"
								:key="`column-one-${index}`"
								:style="'width:' + column.width"
								class="border border-dark border-1"
							>
								<template v-if="columnsToJoin(column).length > 0">
									<span style="font-size:12px;vertical-align: super;">(1) </span
									><span>{{ column.label }}</span>
									<template v-for="(c, i) in columnsToJoin(column)">
										<div :key="'c-prop-' + c.prop">
											<span>
												<span style="font-size:12px;vertical-align: super;">
													({{ i + 2 }})
												</span>
												{{ c.label }}
											</span>
										</div>
									</template>
								</template>
								<template v-else>
									{{ column.label }}
								</template>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(data, index) in currentField.value" :key="`table-${currentField.id}-${index}`">
							<td
								v-for="(column, index) in filterColumnsWithoutJoin()"
								:key="`column-two-${index}`"
								:style="'width:' + column.width"
								class="border border-dark border-1"
							>
								<template v-if="columnsToJoin(column).length > 0">
									<div class="row mx-0 column-row">
										<small v-if="data[column.prop]" class="position-absolute">(1)</small>
										<span
											v-if="column.inputType === 'date' && data[column.prop]"
											class="border border-0 bg-transparent d-block ps-5"
											>{{
												new Date(data[column.prop]).toLocaleDateString('en-US', {
													timeZone: 'UTC',
												})
											}}</span
										>

										<span
											v-else-if="column.inputType !== 'text' && column.inputType !== 'date'"
											:type="column.inputType"
											class="ps-5"
										>
											{{ data[column.prop] }}</span
										>

										<template v-if="column.inputType === 'text'">
											<span :type="column.inputType" class="ps-5">
												{{ data[column.prop] }}
											</span>
										</template>
									</div>
									<div
										v-for="(c, index) in columnsToJoin(column)"
										class="row mx-0 border border-dark border-bottom-0 border-top-1 border-start-0 border-end-0 column-row"
										:key="'info-' + index"
									>
										<small v-if="data[column.prop]" class="position-absolute"
											>({{ index + 2 }})</small
										>

										<span
											v-if="column.inputType === 'date' && data[c.prop]"
											class="border border-0 bg-transparent d-block ps-5"
											>{{ data[c.prop] }}</span
										>
										<template v-else-if="c.inputType === 'text'">
											<span :key="'c-prop-' + c.prop" class="ps-5 float-right" :type="c.inputType"
												>{{ data[c.prop] }}
											</span>
										</template>
										<span v-else :key="'c-prop-' + c.prop" class="ps-5" :type="c.inputType">
											{{ data[c.prop] }}
										</span>
									</div>
								</template>
								<template v-else>
									<span
										v-if="column.inputType === 'date' && data[column.prop]"
										class="border-0 bg-transparent d-block"
										>{{
											new Date(data[column.prop]).toLocaleDateString('en-US', {
												timeZone: 'UTC',
											})
										}}</span
									>
									<span
										v-else-if="column.inputType === 'text'"
										class="border-0 bg-transparent d-block"
										:type="column.inputType"
									>
										{{ data[column.prop] }}
									</span>
									<span v-else class="border-0 bg-transparent d-block" :type="column.inputType">{{
										data[column.prop]
									}}</span>
								</template>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<small v-if="currentField.help" class="text-muted ps-2 pdf-help">{{ currentField.help }}</small>
	</div>
</template>

<style scoped>
.table-element.table-bordered > tbody {
	border-top: 1px solid black;
}
.column-row {
	overflow-wrap: break-word;
	flex-wrap: wrap;
	min-height: 20px;
}
</style>

<script>
export default {
	name: 'Table',
	props: {
		currentField: {
			type: Object,
			required: true,
		},
		mode: String,
	},
	created() {
		// if value is not array, prepare it to hold table rows
		if (!Array.isArray(this.currentField.value)) {
			this.currentField.value = []
		}

		// if no table data is present in value, add first empty line
		if (!this.currentField.value.length) {
			this.handleAdd()
		}
	},
	methods: {
		handleDelete(index) {
			this.currentField.value.splice(index, 1)
		},
		handleAdd() {
			let newEle = {
				id: new Date().getTime(),
				edit: true,
			}
			this.currentField.data.tableColumns.forEach(function(ele) {
				newEle[ele.prop] = ''
			})

			this.currentField.value.push(newEle)
		},
		createOptionsArray(values) {
			let options = []
			options = values.split(';')
			return options
		},
		filterColumnsWithoutJoin() {
			return this.currentField.data.tableColumns.filter(col => {
				if (col.joinWith === undefined) {
					return col
				}
				if (col.joinWith && col.joinWith.length > 0 && col.joinWith === 'single') {
					return col
				}
			})
		},
		columnsToJoin(column) {
			let joinedColumns = []
			joinedColumns = this.currentField.data.tableColumns.filter(col => col.joinWith === column.prop)
			return joinedColumns
		},
	},
}
</script>
