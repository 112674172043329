<template>
	<div class="home py-5">
		<template v-if="$route.query.for === 'clerkminutes'">
			<h3 class="mb-3 text-center">
				<img src="https://edge.heygov.com/app-assets/feature-clerkminutes.png" alt="ClerkMinutes" height="32" />
				ClerkMinutes Login
			</h3>
			<p class="mb-3 text-center">
				Manage your meeting minutes and agendas with ClerkMinutes.
			</p>
		</template>
		<template v-else>
			<h3 class="mb-3 text-center">👋🏼 Login to HeyGov</h3>
			<p class="mb-3 text-center">
				{{ $route.query.message || 'Log in to see your submitted requests or form applications' }}
			</p>
		</template>

		<div class="row justify-content-center">
			<div class="col-10 col-sm-9 col-md-8 col-lg-7 col-xl-6">
				<div class="card">
					<div class="card-body">
						<form @submit.prevent="login">
							<!--<p class="mb-4">or <router-link to="/account/register">create an account</router-link></p>-->

							<div v-if="error" class="alert alert-warning" role="alert">{{ error }}</div>
							<div v-if="info" class="alert alert-info" role="alert">{{ info }}</div>

							<div class="form-group mb-3">
								<label class="form-label" for="email">Email</label>
								<input
									type="email"
									class="form-control"
									id="email"
									autocomplete="username"
									autofocus
									v-model="account.email"
									placeholder="Your email"
									required
								/>
							</div>

							<div v-if="loginType === 'code'" class="form-group mb-3">
								<p v-if="codeSent" class="text-muted text-center mb-3">
									We sent you a login link. Please check your inbox
								</p>

								<button
									id="resend-code"
									type="button"
									class="btn btn-sm float-end"
									@click="resendCode"
									aria-label="Resend login code"
								>
									Resend code
								</button>
								<label class="form-label" for="code">Login code</label>
								<input
									type="text"
									class="form-control"
									id="code"
									autocomplete="one-time-code"
									v-model="account.code"
									placeholder="Paste login code"
									required
								/>
							</div>
							<div v-else-if="loginType === 'password'">
								<div class="form-group mb-3">
									<button
										id="toggle-password"
										type="button"
										class="btn btn-sm float-end"
										@click="togglePassword"
										aria-label="Show password as plain text. Warning: this will display your password on the screen."
									>
										Show password
									</button>
									<label class="form-label" for="password">Password</label>
									<input
										type="password"
										class="form-control"
										id="password"
										v-model="account.password"
										autocomplete="current-password"
										placeholder="Your password"
										required
										minlength="6"
									/>
								</div>
							</div>

							<div class="d-grid">
								<button class="btn btn-primary" id="login-btn" :disabled="state === 'loading'">
									Continue
								</button>
							</div>

							<!--

							<div class="form-group row align-items-center mb-0">
								<div class="col-auto">
									<button class="btn btn-primary" :disabled="state === 'loading'">Sign in</button>
								</div>
								<div class="col text-end">
									<router-link to="/account/reset-pass"><small>Forgot password?</small></router-link>
								</div>
							</div>
							-->

							<div v-if="loginType === 'password'" class="mt-5">
								<hr class="mb-4" />

								<p class="text-center mb-1">
									<button class="btn btn-sm" @click="resendCode">Login with link instead</button>
								</p>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'

import { identify } from '@/utils.js'
import { validateEmail } from '../lib/strings.js'

export default {
	name: 'AccountLogin',
	data() {
		return {
			state: 'idle',
			codeSent: false,
			loginType: 'email',
			account: {
				email: '',
				code: '',
				password: '',
				preferLink: false,
			},
			error: '',
			info: '',
			isLoginFromLink: false,
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['auth']),
	},
	created() {
		// person is already logged in, redirect to the page they need to see
		if (this.auth) {
			if (this.$route.query.redirect?.startsWith('/:jurisdiction/')) {
				// need to replace :jurisdiction with a jurisdiction slug

				if (this.j) {
					this.$router.push(this.$route.query.redirect.replace(':jurisdiction', this.j.slug))
				} else {
					this.$router.push({ path: '/select-municipality', query: this.$route.query })
				}
			} else {
				this.$router.push(this.$route.query.redirect || '/')
			}
		} else if (this.$route.query.email && this.$route.query.code) {
			this.loginType = 'code'
			this.account.email = atob(this.$route.query.email)
			this.account.code = this.$route.query.code
			this.isLoginFromLink = true

			this.login()
		}
	},
	methods: {
		togglePassword($event) {
			const $password = document.getElementById('password')

			if ($password.type === 'password') {
				$password.type = 'text'
				$event.target.textContent = 'Hide password'
				$event.target.setAttribute('aria-label', 'Hide password.')
			} else {
				$password.type = 'password'
				$event.target.textContent = 'Show password'
				$event.target.setAttribute(
					'aria-label',
					'Show password as plain text. ' + 'Warning: this will display your password on the screen.'
				)
			}
		},
		resendCode() {
			this.loginType = 'link'
			this.account.code = ''
			this.account.password = ''
			this.account.preferLink = true
			this.login()
		},
		login() {
			this.error = ''

			try {
				this.account.email = validateEmail(this.account.email)
			} catch (error) {
				this.error = `Email error: ${error.message}`
				return
			}

			this.state = 'loading'

			this.$store.dispatch('authRequest', this.account).then(
				({ data }) => {
					this.state = 'idle'

					// This is a successful authentication
					if (data.auth) {
						if (this.j || data.jurisdictions.length === 1) {
							const to = this.$route.query.redirect || '/'
							this.$router.push(to.replace(':jurisdiction', this.j.slug))
						} else {
							this.$router.push({ path: '/select-municipality', query: this.$route.query })
						}

						identify(this.auth)
					} else if (data.loginType && data.loginType === 'code') {
						this.loginType = data.loginType
						this.codeSent = true

						setTimeout(() => {
							// wait a bit until html elements are rendered
							document.getElementById('login-btn').innerText = `Login with code`
							document.getElementById('code').focus()
							Vue.toasted.show('Login code is sent to your inbox')
						}, 100)
					} else if (data.loginType) {
						this.loginType = data.loginType

						setTimeout(() => {
							// wait a bit until html elements are rendered
							document.getElementById('login-btn').innerText = `Login with password`
							document.getElementById('password').focus()
						}, 100)
					}
				},
				error => {
					if (this.isLoginFromLink && error.response.data.detail.includes('invalid')) {
						this.isLoginFromLink = false

						this.info = 'The login link you used expired, and we just sent you a new one over email'
						this.account.code = ''
						this.login()
					} else {
						this.state = 'error'
						this.error = error.response ? error.response.data.detail : error.message
						console.error(error)
					}
				}
			)
		},
	},
}
</script>
