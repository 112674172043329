<template>
	<div class="page-venue-booking-review">
		<div v-if="states.booking === 'idle'">
			<div class="row">
				<div class="col">
					<h3 class="mb-3">
						<router-link :to="`/${j.slug}/venues`">HeyReserve</router-link>
						<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
						<template v-if="venue.venue_group_id">
							<router-link
								v-if="venueGroups.find(g => g.id == venue.venue_group_id)"
								:to="
									`/${j.slug}/venues/group/${
										venueGroups.find(g => g.id == venue.venue_group_id).slug
									}`
								"
								>{{ venueGroups.find(g => g.id == venue.venue_group_id).name }}</router-link
							>
							<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
						</template>
						<router-link :to="`/${j.slug}/venues/${venue.slug}`">{{ venue.name }}</router-link>
						<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
						Reservation on
						{{
							new Date(venueBooking.starts_at).toLocaleDateString('default', {
								dateStyle: 'medium',
								timeZone: j.timezone,
							})
						}}
						<small v-if="venueBookingSlot" class="text-neutral-500">({{ venueBookingSlot.name }})</small>
					</h3>
				</div>
				<!-- <div v-if="isStaff" class="col-auto">
					<button class="btn btn-sm btn-outline-dark">menu</button>
				</div> -->
			</div>

			<div class="row mb-4">
				<div class="col-7">
					<div class="card">
						<div class="card-body">
							<div class="row">
								<div class="col">
									<h4 class="mb-3">Reservation details</h4>
								</div>
								<div class="col-auto">
									<venue-booking-status :venueBooking="venueBooking" :tooltip="true" />
								</div>
							</div>

							<p class="mb-2">
								Received on
								<strong>{{ venueBooking.booked_at | dateTimeLocal }}</strong> and assigned to
								<strong v-if="venueDepartment">{{ venueDepartment.name }}</strong
								>.
							</p>
							<p class="mb-3">
								Booking date:
								<strong
									><venue-booking-datetime :venueBooking="venueBooking" :timezones="true"
								/></strong>
								<span v-if="venueBookingSlot" class="badge bg-primary-50 text-dark mx-1">
									{{ venueBookingSlot.name }}
								</span>
								<button
									v-if="['pending', 'approved'].includes(venueBooking.status)"
									class="btn btn-sm bg-neutral-100 py-1 px-2"
									@click="editVenueBookingDates"
									data-bs-toggle="modal"
									data-bs-target="#venue-booking-change-date"
								>
									Edit
								</button>
							</p>

							<p class="mb-1">
								Reservation price:
								<span :class="venueBooking.price ? 'fw-bolder' : 'text-neutral-400'">{{
									venueBooking.price | currency
								}}</span>
							</p>
							<p class="mb-3">
								Deposit:
								<span :class="venueBooking.deposit ? 'fw-bolder' : 'text-neutral-400'">{{
									venueBooking.deposit | currency
								}}</span>
								<span
									v-if="venueBooking.deposit && venueBooking.deposit_status === 'succeeded'"
									class="badge badge-xs bg-success-lighter text-success ms-2"
									>We have it</span
								>
								<span
									v-else-if="venueBooking.deposit && venueBooking.deposit_status === 'refunded'"
									class="badge badge-xs bg-primary-50 text-primary-400 ms-2"
									>Refunded {{ venueBooking.deposit_refunded | currency }}</span
								>
							</p>

							<div v-if="payment">
								<p v-if="payment.status === 'requires_capture'" class="mb-2">
									<strong>{{ payment.amount | currency }}</strong>
									<template v-if="payment.fee"> + {{ payment.fee | currency }} fee</template> is
									authorized from
									<payment-method :payment-method="payment.payment_method" :tooltip="true" />, but
									<u>not yet captured</u>. Confirm the reservation to capture the money, or decline to
									release.
								</p>
								<p v-else-if="payment.status === 'succeeded'" class="mb-2">
									Paid <strong>{{ payment.amount | currency }}</strong>
									<template v-if="payment.fee"> + {{ payment.fee | currency }} fee</template> with
									<payment-method :payment-method="payment.payment_method" :tooltip="true" />
								</p>
								<p v-else-if="payment.status === 'canceled'" class="mb-2">
									<strong>{{ payment.total_paid | currency }}</strong> was authorized from
									<payment-method :payment-method="payment.payment_method" :tooltip="true" />, then
									canceled and released back to the card.
								</p>
								<pre v-else>{{ payment }}</pre>

								<p v-if="payment.refunded">{{ payment.refunded | currency }} is refunded.</p>
							</div>

							<hr class="bg-primary-100" />

							<person-card
								v-if="people[venueBooking.person_id]"
								:person="people[venueBooking.person_id]"
							></person-card>

							<hr class="bg-primary-100" />

							<h6 class="mb-3">Checkout info</h6>

							<div
								v-for="field in venue.fields.filter(f => f.type !== 'TextElement')"
								:key="`venue-field-${field.id}`"
								class="venue-field mb-3"
								:class="`venue-field-${field.type} venue-field-${field.id}`"
							>
								<span class="me-2"
									>{{ field.label }}<span v-if="!field.label.endsWith('?')">:</span></span
								>

								<file-upload-element
									v-if="field.type === 'FileUploadElement'"
									:currentField="{
										...field,
										value: venueBooking.fields_answers[field.id],
									}"
									:venueBooking="venueBooking"
									mode="preview"
								></file-upload-element>
								<signature-element
									v-else-if="field.type === 'SignatureElement'"
									:currentField="{
										...field,
										value: venueBooking.fields_answers[field.id],
									}"
									mode="preview"
								></signature-element>
								<strong v-else-if="venueBooking.fields_answers[field.id]">{{
									displayFieldAnswer(field, venueBooking.fields_answers[field.id])
								}}</strong
								><i v-else class="text-muted">no answer</i>
							</div>

							<hr class="bg-gray" />

							<!-- Status sections -->
							<!--{{ venueBookingStatuses[venueBooking.status].text }}-->
							<div v-if="venueBooking.status === 'draft'" class="alert alert-warning">
								<person-link :id="venueBooking.person_id" :avatar="16"></person-link> started the
								booking, but hasn't filled out all the fields and paid yet.
							</div>

							<p
								v-else-if="
									venueBooking.status === 'pending' &&
										(personPartOfDepartment(venue.department_id) || isStaff)
								"
								class="card-text"
							>
								<button
									class="btn btn-primary me-2 px-5"
									data-bs-toggle="modal"
									data-bs-target="#venue-booking-approve"
									@click="venueBookingReview.message = venue.message_approved"
								>
									Confirm booking
								</button>

								<button
									class="btn btn-outline-danger me-2 px-5"
									data-bs-toggle="modal"
									data-bs-target="#venue-booking-decline"
									@click="venueBookingReview.message = venue.message_declined"
								>
									Decline
								</button>
							</p>

							<p v-else-if="venueBooking.status === 'pending'" class="card-text">
								This booking can be approved or declined only by
								<strong v-if="venueDepartment">{{ venueDepartment.name }} </strong> members:
								<department-people
									v-if="venueDepartment"
									:department="venueDepartment"
								></department-people>
							</p>

							<p v-else-if="venueBooking.status === 'declined'" class="card-text">
								<span class="badge" :class="[venueBookingStatuses[venueBooking.status].class]">{{
									venueBookingStatuses[venueBooking.status].name
								}}</span>
								<span v-if="venueBooking.reviewed_by">
									by <person-link :id="venueBooking.reviewed_by" :avatar="16"></person-link
								></span>
								on {{ new Date(venueBooking.reviewed_at).toLocaleString() }}.
							</p>

							<p v-else-if="venueBooking.status === 'canceled'" class="card-text">
								<span class="badge" :class="[venueBookingStatuses[venueBooking.status].class]">{{
									venueBookingStatuses[venueBooking.status].name
								}}</span>
								by <person-link :id="venueBooking.reviewed_by" :avatar="16"></person-link> on
								{{ new Date(venueBooking.reviewed_at).toLocaleString() }}.
							</p>

							<div v-else-if="venueBooking.status === 'approved'" class="row">
								<div class="col">
									<p class="card-text">
										<venue-booking-status :venue-booking="venueBooking" />
										<span v-if="venueBooking.reviewed_by" class="ms-1"
											>by <person-link :id="venueBooking.reviewed_by" :avatar="18"></person-link
										></span>
										on {{ venueBooking.reviewed_at | dateLocal }}.
									</p>
								</div>
								<div class="col-auto">
									<button
										class="btn btn-sm btn-link text-danger"
										@click="startVenueBookingCancel"
										data-bs-toggle="modal"
										data-bs-target="#venue-booking-cancel"
									>
										Cancel booking
									</button>
								</div>
							</div>

							<div v-else-if="venueBooking.status === 'progress'" class="row align-items-center">
								<div class="col">
									<p class="card-text">
										Reservation is
										<venue-booking-status :venueBooking="venueBooking" :tooltip="false" /> ends
										{{ venueBooking.ends_at | dateToNow }}
									</p>
								</div>
								<div class="col-auto">
									<button
										class="btn btn-sm btn-link text-danger"
										@click="startVenueBookingCancel"
										data-bs-toggle="modal"
										data-bs-target="#venue-booking-cancel"
									>
										Cancel reservation
									</button>
								</div>
							</div>

							<div v-else-if="venueBooking.status === 'ended'">
								<p class="card-text">
									The booking ended. Inspect the venue and refund deposit to finalize booking.
								</p>

								<p class="card-text">
									<button
										class="btn btn-primary me-2"
										data-bs-toggle="modal"
										data-bs-target="#venue-booking-complete"
										@click="venueBookingReview.message = venue.message_completed"
									>
										Finalize booking
									</button>
								</p>
							</div>

							<div v-else-if="venueBooking.status === 'completed'">
								<p class="card-text">
									This booking is completed and
									<strong>{{ venueBooking.deposit_refunded | currency }}</strong> of deposit refunded.
								</p>
								<div class="bg-light p-2 rounded-1">
									<person-link :id="venueBooking.inspected_by" :avatar="16" /> "{{
										venueBooking.inspection_message
									}}"
								</div>
							</div>

							<p v-else class="card-text">
								This booking status is <strong>{{ venueBooking.status }}</strong>
							</p>
						</div>
					</div>
				</div>

				<div class="col position-relative">
					<div class="card mb-4">
						<div class="card-header-tabs p-2">
							<ul class="nav nav-tabs my-0">
								<li class="nav-item">
									<strong class="nav-link disabled text-dark">Related bookings</strong>
								</li>
								<li class="nav-item">
									<span
										class="nav-link cursor-pointer"
										:class="{ active: relatedBookingsTab === 'venue' }"
										@click="loadRelatedBookings('venue')"
										>Same venue</span
									>
								</li>
								<li class="nav-item">
									<span
										class="nav-link cursor-pointer"
										:class="{ active: relatedBookingsTab === 'person' }"
										@click="loadRelatedBookings('person')"
										>Same person</span
									>
								</li>
							</ul>
						</div>
						<div class="card-body pt-2">
							<div class="card-table">
								<table class="table table-hover mb-0">
									<tbody>
										<router-link
											v-for="venueBooking in venueBookings.before"
											:key="`booking-related-before-${venueBooking.id}`"
											:to="
												`/${j.slug}/venues/${
													venues.find(v => v.id === venueBooking.venue_id).slug
												}/bookings/${venueBooking.uuid}`
											"
											tag="tr"
											class="cursor-pointer"
										>
											<td>
												{{
													new Date(venueBooking.starts_at).toLocaleString('en-US', {
														dateStyle: 'short',
														timeStyle: 'short',
														timeZone: 'America/Chicago',
													})
												}}
											</td>
											<td>
												<venue-booking-status
													:venueBooking="venueBooking"
													format="icon"
													:tooltip="true"
												/>
											</td>
											<td>
												<person-link
													v-if="relatedBookingsTab === 'venue'"
													:id="venueBooking.person_id"
													:avatar="16"
												></person-link>
												<span v-else-if="venues.find(v => v.id === venueBooking.venue_id)">{{
													venues.find(v => v.id === venueBooking.venue_id).name
												}}</span>
											</td>
										</router-link>
										<tr v-if="!venueBookings.before.length">
											<td colspan="4" class="text-muted text-center">
												No other previous bookings
											</td>
										</tr>

										<tr style="border-left: 3px solid var(--bs-primary)">
											<td>
												{{
													new Date(venueBooking.starts_at).toLocaleString('en-US', {
														dateStyle: 'short',
														timeStyle: 'short',
														timeZone: 'America/Chicago',
													})
												}}
											</td>
											<td>
												<venue-booking-status
													:venueBooking="venueBooking"
													format="icon"
													:tooltip="true"
												/>
											</td>
											<td>
												<person-link
													v-if="relatedBookingsTab === 'venue'"
													:id="venueBooking.person_id"
													:avatar="16"
												></person-link>
												<span v-else-if="venues.find(v => v.id === venueBooking.venue_id)">{{
													venues.find(v => v.id === venueBooking.venue_id).name
												}}</span>
											</td>
										</tr>

										<router-link
											v-for="venueBooking in venueBookings.after"
											:key="`booking-related-after-${venueBooking.id}`"
											:to="`/${j.slug}/venues/${venue.slug}/bookings/${venueBooking.uuid}`"
											class="cursor-pointer"
											custom
											v-slot="{ navigate }"
										>
											<tr @click="navigate" @keypress.enter="navigate">
												<td>
													{{
														new Date(venueBooking.starts_at).toLocaleString('en-US', {
															dateStyle: 'short',
															timeStyle: 'short',
															timeZone: 'America/Chicago',
														})
													}}
												</td>
												<td>
													<venue-booking-status
														:venueBooking="venueBooking"
														format="icon"
														:tooltip="true"
													/>
												</td>
												<td>
													<person-link
														v-if="relatedBookingsTab === 'venue'"
														:id="venueBooking.person_id"
														:avatar="16"
													></person-link>
													<span
														v-else-if="venues.find(v => v.id === venueBooking.venue_id)"
														>{{
															venues.find(v => v.id === venueBooking.venue_id).name
														}}</span
													>
												</td>
											</tr>
										</router-link>

										<tr v-if="!venueBookings.after.length">
											<td colspan="4" class="text-muted text-center">
												No other bookings after
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div class="card">
						<div class="card-header">
							<h5 class="mb-0">Reservation timeline</h5>
						</div>
						<div class="card-body pt-0 px-3">
							<activity-timeline :activities="venueBookingActivities"></activity-timeline>
						</div>
						<div class="card-footer text-center">
							<button
								class="btn btn-sm btn-outline-warning mx-2"
								data-bs-toggle="modal"
								data-bs-target="#venue-booking-send-message"
								@click="venueBookingActivity.privacy = 'private'"
							>
								Add internal note <font-awesome-icon :icon="['fas', 'sticky-note']" />
							</button>
							<button
								class="btn btn-sm btn-outline-info"
								data-bs-toggle="modal"
								data-bs-target="#venue-booking-send-message"
								@click="venueBookingActivity.privacy = 'public'"
							>
								Send a message <font-awesome-icon :icon="['fas', 'paper-plane']" />
							</button>
						</div>
					</div>
				</div>
			</div>

			<!--
			<div class="card mb-5">
				<div class="card-header">
					<h5 class="my-0">🛡 Risk insights</h5>
				</div>
				<div class="card-body">
					<p class="mb-4">HeyGov helps you detect fraudulent bookings.</p>

					<div class="row">
						<div v-if="people[venueBooking.person_id]" class="col-6">
							<h6 class="subtitle">Applicant</h6>

							<p class="mb-1">
								Name: <strong>{{ people[venueBooking.person_id].name }}</strong>
							</p>
							<p class="mb-1">
								Email: <strong>{{ people[venueBooking.person_id].email }}</strong>
							</p>
							<p class="mb-1">
								Email verified: ✅
								<small class="text-muted"
									>Applicant confirmed their address email by clicking a link</small
								>
							</p>
							<p v-if="people[venueBooking.person_id].name" class="mb-1">
								How similar are applicant name and email?
								<strong
									>{{
										(
											compareTwoStrings(
												people[venueBooking.person_id].name,
												people[venueBooking.person_id].email.split('@').shift()
											) * 100
										).toFixed(2)
									}}%</strong
								>
							</p>
							<p class="mb-1">Approval rate for forms associated with this email: <strong>60%</strong></p>
							<p>
								Device:
								<strong
									>🖥
									{{
										[
											venueBooking.ua.device ? venueBooking.ua.device.name : null,
											venueBooking.ua.os.name,
											venueBooking.ua.browser.name,
										]
											.filter(Boolean)
											.join(' / ')
									}}</strong
								>
							</p>
						</div>

						<div class="col-6">
							<h6 class="subtitle">Locations</h6>

							<Gmap-Map
								style="width: 100%; height: 200px;"
								:zoom="3"
								:center="{ lat: 40.27782811954598, lng: -94.89448385642757 }"
								class="mb-3"
							>
								<gmap-marker
									v-if="venueBooking.ip_info"
									:position="{ lat: venueBooking.ip_info.loc[0], lng: venueBooking.ip_info.loc[1] }"
								></gmap-marker>
								<gmap-marker :position="{ lat: 43.0203535, lng: -89.4738675 }"></gmap-marker>
							</Gmap-Map>

							<p class="mb-1">
								Applicant IP:
								<strong
									><span v-if="venueBooking.ip_info">{{ flag(venueBooking.ip_info.country) }}</span>
									{{ venueBooking.from_ip }}</strong
								>
								<small v-if="venueBooking.ip_info" class="text-muted ms-1">{{
									[
										venueBooking.ip_info.city,
										venueBooking.ip_info.region,
										venueBooking.ip_info.country,
									].join(', ')
								}}</small>
							</p>
						</div>
					</div>
				</div>
			</div>

			-->
		</div>

		<div v-else class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>

		<div class="modal fade" id="venue-booking-approve" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="venueBookingApprove">
					<div v-if="venueBooking" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Confirm booking</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p class="mb-2">You're confirming this booking 🙌</p>

							<p v-if="payment && payment.status === 'requires_capture'" class="mb-4">
								The authorized payment of
								<strong>{{ payment.amount | currency }} + {{ payment.fee | currency }} fee</strong> will
								be captured from
								<payment-method :payment-method="payment.payment_method" :tooltip="true" />
							</p>
							<p v-else-if="payment && payment.status === 'succeeded'" class="mb-4">
								The payment of
								<strong>{{ payment.amount | currency }} + {{ payment.fee | currency }} fee</strong> from
								<payment-method :payment-method="payment.payment_method" :tooltip="true" /> is in
								{{ j.type }} balance.
							</p>
							<p v-else-if="payment" class="mb-4">
								The payment of
								<strong>{{ payment.amount | currency }} + {{ payment.fee | currency }} fee</strong> from
								<payment-method :payment-method="payment.payment_method" :tooltip="true" /> is
								<strong>{{ payment.status }}</strong
								>.
							</p>

							<div class="form-group mb-3">
								<label class="form-label" for="venue-booking-confirm-msg">Confirmation message</label>

								<textarea
									class="form-control"
									id="venue-booking-confirm-msg"
									v-model="venueBookingReview.message"
									required
									placeholder="Type a nice message"
								></textarea>
							</div>

							<div class="form-group mb-3">
								<label class="form-label">Attach documents</label>

								<div
									v-for="file in venue.public_files"
									:key="`venue-file-${file.file}`"
									class="form-check hover"
								>
									<input
										class="form-check-input"
										type="checkbox"
										v-model="venueBookingReview.files"
										:value="file.file"
										:id="`venue-file-input-${file.file}`"
									/>
									<label class="form-check-label" :for="`venue-file-input-${file.file}`">
										{{ file.name }}
										<a
											:href="getPublicFileUrl(file.file)"
											target="_blank"
											class="ms-2 text-neutral-500"
											><small>Open file</small></a
										>
									</label>
								</div>

								<div>
									<label
										class="d-inline-block text-primary-300 cursor-pointer mt-1"
										for="upload-venue-file"
										>Upload file</label
									>
								</div>
								<input
									type="file"
									id="upload-venue-file"
									class="d-none"
									accept="application/*"
									@change="uploadFile($event)"
								/>
							</div>

							<p class="mb-0">
								A confirmation will be sent to
								<person-link :id="venueBooking.person_id" :avatar="16" /> by email and app notification.
							</p>
						</div>
						<div class="modal-footer">
							<button class="btn btn-outline-primary" :disabled="states.bookingStatus === 'loading'">
								Confirm booking
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="modal fade" id="venue-booking-decline" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="venueBookingDecline">
					<div v-if="venueBooking" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Decline booking</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>So you're declining this booking 😬</p>

							<div class="form-group mb-3">
								<label class="form-label">Rejection message</label>

								<textarea
									required
									v-model="venueBookingReview.message"
									placeholder="Type a nice message"
									class="form-control"
								></textarea>
							</div>

							<p v-if="payment && payment.status === 'requires_capture'" class="mb-2">
								The authorized payment of
								<strong>{{ payment.amount | currency }} + {{ payment.fee | currency }} fee</strong> will
								be released back to
								<payment-method :payment-method="payment.payment_method" :tooltip="true" />
							</p>
							<p v-else-if="payment && payment.status === 'succeeded'" class="mb-2">
								The payment of
								<strong>{{ payment.amount | currency }} + {{ payment.fee | currency }} fee</strong> will
								be refunded to
								<payment-method :payment-method="payment.payment_method" :tooltip="true" />
							</p>

							<p class="mb-0">
								A decline notification will be sent to
								<person-link :id="venueBooking.person_id" :avatar="16" /> by email and app notification.
							</p>
						</div>
						<div class="modal-footer">
							<button class="btn btn-outline-danger" :disabled="states.bookingStatus === 'loading'">
								Decline booking
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="modal fade" id="venue-booking-cancel" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="venueBookingCancel">
					<div v-if="venueBooking" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Cancel reservation</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>Cancel a confirmed or in progress reservation.</p>

							<div class="form-group mb-3">
								<label class="form-label" for="booking-cancel-message">Reason for cancelling</label>

								<textarea
									required
									id="booking-cancel-message"
									class="form-control"
									v-model="venueBookingCancelData.message"
									placeholder="Type a message"
								></textarea>
							</div>

							<div v-if="payment" class="bg-neutral-50 rounded-1 p-2 mb-3">
								<p class="mb-1">
									Venue payment: <strong>{{ payment.amount | currency }}</strong>
									<span v-if="payment.fee">
										+ <strong>{{ payment.fee | currency }}</strong> fee</span
									>
								</p>
								<p class="mb-2">
									Payment method:
									<payment-method :payment-method="payment.payment_method" :tooltip="true" />
								</p>

								<div class="form-group">
									<label
										v-if="['cash', 'check'].includes(payment.payment_method.type)"
										class="form-label"
										for="venue-booking-cancel-refund"
										>How much was refunded?</label
									>
									<label v-else class="form-label" for="venue-booking-cancel-refund"
										>Refund to card amount</label
									>

									<input
										type="number"
										class="form-control form-control-sm"
										id="venue-booking-cancel-refund"
										v-model="venueBookingCancelData.payment_refund"
										required
										step="0.01"
										min="0"
										:max="payment.amount"
									/>
								</div>
							</div>

							<p class="mb-0">
								A cancellation notification will be sent to
								<person-link :id="venueBooking.person_id" :avatar="16" /> by email and app notification.
							</p>
						</div>
						<div class="modal-footer">
							<button class="btn btn-outline-danger" :disabled="states.bookingStatus === 'loading'">
								Cancel reservation
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="modal fade" id="venue-booking-complete" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="venueBookingComplete">
					<div v-if="venueBooking" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Finalize booking</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>So the venue inspection is done 🧐</p>

							<div class="form-group mb-3">
								<label class="form-label">Deposit refund amount</label>

								<div class="input-group">
									<span class="input-group-text">$</span>
									<input class="form-control" required v-model="venueBookingReview.deposit_refund" />
								</div>
							</div>

							<div
								v-if="venueBookingReview.deposit_refund > venueBooking.deposit"
								class="alert alert-warning d-flex"
							>
								<span class="me-2">⚠️</span>
								<span
									>You're refunding more than the deposit paid. The extra amount will be deducted from
									balance.</span
								>
							</div>

							<div class="form-group mb-3">
								<label class="form-label">Inspection and refund message</label>

								<textarea
									class="form-control"
									required
									v-model="venueBookingReview.message"
									placeholder="The place looks fine"
								></textarea>
							</div>

							<p v-if="payment" class="mb-2">
								{{ venueBookingReview.deposit_refund | currency }} will be refunded to
								<payment-method :payment-method="payment.payment_method" :tooltip="true" />
							</p>

							<p class="mb-0">
								A confirmation message about inspection and refund will be sent to
								<person-link :id="venueBooking.person_id" :avatar="16" /> by email and app notification.
							</p>
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary" :disabled="states.bookingStatus === 'loading'">
								Finalize booking
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div class="modal fade" id="venue-booking-change-date" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="updateVenueBookingDates">
					<div v-if="venueBooking" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Change date or time</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>Change the date or time for this reservation.</p>

							<p class="mb-1">
								Starts at:
								<strong>{{
									new Date(venueBooking.starts_at).toLocaleString('default', {
										dateStyle: 'medium',
										timeStyle: 'short',
										timeZone: j.timezone,
									})
								}}</strong>
							</p>
							<p class="mb-1">
								Ends at:
								<strong>{{
									new Date(venueBooking.ends_at).toLocaleString('default', {
										dateStyle: 'medium',
										timeStyle: 'short',
										timeZone: j.timezone,
									})
								}}</strong>
							</p>
							<p class="mb-3">
								Duration:
								<strong>{{
									formatDistanceStrict(
										new Date(venueBooking.starts_at),
										new Date(venueBooking.ends_at)
									)
								}}</strong>
							</p>

							<hr class="bg-primary-100" />

							<div class="mb-3 row">
								<label for="venue-booking-edit-starts-at" class="col-sm-3 col-form-label"
									>New Start</label
								>
								<div class="col-sm-9">
									<input
										type="datetime-local"
										class="form-control"
										id="venue-booking-edit-starts-at"
										v-model="venueBookingEdit.starts_at_local"
										required
										@change="venueBookingEditStartDate"
									/>
								</div>
							</div>

							<div class="mb-3 row">
								<label for="venue-booking-edit-ends-at" class="col-sm-3 col-form-label">New End</label>
								<div class="col-sm-9">
									<input
										type="datetime-local"
										class="form-control"
										id="venue-booking-edit-ends-at"
										v-model="venueBookingEdit.ends_at_local"
										required
										@change="venueBookingEditEndDate"
									/>
								</div>
							</div>

							<p
								v-if="
									venueBookingEdit.starts_at_local &&
										venueBookingEdit.ends_at_local &&
										formatDistanceStrict(
											new Date(venueBookingEdit.starts_at_local),
											new Date(venueBookingEdit.ends_at_local)
										) !=
											formatDistanceStrict(
												new Date(venueBooking.starts_at),
												new Date(venueBooking.ends_at)
											)
								"
							>
								ℹ️ The new duration of
								<code>{{
									formatDistanceStrict(
										new Date(venueBookingEdit.starts_at_local),
										new Date(venueBookingEdit.ends_at_local)
									)
								}}</code>
								is different than the current one.
							</p>

							<p class="mb-0">
								A notification will be sent to
								<person-link
									class="bg-neutral-100 rounded-1 p-1"
									:id="venueBooking.person_id"
									:avatar="16"
								/>
								by email, text, and app notification.
							</p>
						</div>
						<div class="modal-footer">
							<button
								class="btn btn-primary"
								:disabled="
									!venueBookingEdit.starts_at_local ||
										!venueBookingEdit.ends_at_local ||
										states.bookingEdit === 'loading'
								"
							>
								Change date &amp; time
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Send message modal -->
		<div class="modal fade" id="venue-booking-send-message" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="venueBookingSendMessage">
					<div v-if="venueBooking" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">
								<span v-if="venueBookingActivity.privacy === 'public'">Send a message</span>
								<span v-else>Add internal note</span>
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p v-if="venueBookingActivity.privacy === 'public'">
								Your message will be delivered to the guest via email and in app notification. You will
								see it in the reservation timeline.
							</p>
							<div v-else>
								<p class="mb-2">ℹ️ Internal note: visible to {{ j.type }} staff only.</p>
								<p>
									This note is attached only to the timeline of this reservation. It will be
									accessible exclusively to authorized staff and departments within this reservation.
								</p>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" v-if="venueBookingActivity.privacy === 'public'"
									>What is your message?</label
								>
								<label class="form-label" v-else>Internal note</label>

								<textarea
									class="form-control"
									v-model="venueBookingActivity.message"
									required
									minlength="2"
									placeholder="Type a nice message"
								></textarea>
							</div>

							<div class="form-group">
								<label class="form-label" for="venue-booking-activity-file"
									>Documents &amp; files</label
								>

								<div class="row row-cols row-cols-3">
									<div class="col mb-2" v-for="file in venueBookingActivity.files" :key="file.size">
										<file-preview :file="file" @remove="activityFileRemove" />
									</div>
								</div>

								<p>
									<label role="button" for="venue-booking-activity-file" class="text-primary-400"
										>Add attachment</label
									>
								</p>

								<input
									class="form-control d-none"
									id="venue-booking-activity-file"
									type="file"
									multiple
									accept="image/*,application/pdf"
									@change="activityFileUpload($event)"
								/>
							</div>

							<div v-if="venueBookingActivityFileSize / 1024 / 1024 > 10" class="alert alert-danger">
								<strong>File size exceeeded!</strong> Max attachments size is 10MB.
							</div>
						</div>
						<div class="modal-footer">
							<button
								class="btn px-5"
								:class="venueBookingActivity.privacy === 'public' ? 'btn-primary' : 'btn-warning'"
								:disabled="venueBookingActivityFileSize / 1024 / 1024 > 10"
							>
								Send
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import heyGovApi from '@/api.js'
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import { flag } from 'country-emoji'
import { getPublicFileUrl, handleResponseError, mimeTypes, sendEvent } from '@/utils.js'
import { venueBookingStatuses } from '@/actions/venues.js'
import { displayFieldAnswer } from '@/actions/form-builder.js'

import ActivityTimeline from '@/components/ActivityTimeline.vue'
import FileUploadElement from '@/components/form-builder/FileUploadElement'
import SignatureElement from '@/components/form-builder/SignatureElement'
import DepartmentPeople from '@/components/DepartmentPeople.vue'
import FilePreview from '@/components/FilePreview.vue'
import PersonCard from '@/components/PersonCard.vue'
import PersonLink from '@/components/PersonLink.vue'
import VenueBookingStatus from '@/components/venues/VenueBookingStatus.vue'
import VenueBookingDatetime from '@/components/venues/VenueBookingDatetime.vue'
//import VenueWithSlot from '@/components/venues/VenueWithSlot.vue'
import PaymentMethod from '@/components/payments/PaymentMethod.vue'

import { add, differenceInMinutes, format, formatDistanceStrict, formatDistanceToNow, sub } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { Modal } from 'bootstrap'

export default {
	name: 'VenueBookingReview',
	metaInfo() {
		return {
			title: `Review booking for ${this.venue?.name || this.$route.params.venueSlug}`,
		}
	},
	components: {
		FilePreview,
		ActivityTimeline,
		FileUploadElement,
		SignatureElement,
		PersonCard,
		PersonLink,
		DepartmentPeople,
		VenueBookingStatus,
		VenueBookingDatetime,
		//VenueWithSlot,
		PaymentMethod,
	},
	data() {
		return {
			states: {
				booking: 'loading',
				bookingStatus: 'idle',
				bookingEdit: 'idle',
			},
			venueBookingStatuses,
			venue: null,
			venueBooking: null,
			venueBookingActivities: [],
			relatedBookingsTab: 'venue',
			relatedBookingsAbortController: null,
			venueBookings: {
				before: [],
				after: [],
			},
			payment: null,

			// edit venue booking
			$modalRescheduleBooking: null,
			venueBookingEdit: {
				starts_at_local: null,
				ends_at_local: null,
			},

			venueBookingReview: {
				message: '',
				deposit_refund: 0,
				files: [],
			},
			venueBookingCancelData: {
				message: '',
				payment_refund: 0,
			},

			$modalSendMessage: null,
			venueBookingActivity: {
				type: 'info',
				privacy: 'public',
				message: '',
				files: [],
			},
		}
	},
	computed: {
		...mapState(['j', 'people', 'departments', 'venues', 'venueGroups']),
		...mapGetters(['currentRole', 'auth', 'isStaff']),
		venueDepartment() {
			return (this.departments || []).find(d => d.id === this.venue.department_id)
		},
		venueBookingSlot() {
			return this.venue?.slots.find(s => s.id === this.venueBooking.venue_slot_id)
		},
		venueBookingActivityFileSize() {
			return this.venueBookingActivity.files.reduce((acc, file) => acc + file.size, 0)
		},
	},
	created() {
		if (!this.auth) {
			this.$router.push(`/account/login?redirect=${encodeURIComponent(this.$route.fullPath)}`)
		} else if (this.currentRole === 'CITIZEN') {
			this.$router.push(
				`/${this.j.slug}/venues/${this.$route.params.venueSlug}/bookings-info/${this.$route.params.bookingUuid}`
			)
		} else {
			this.loadVenueAndBooking()
			this.$store.dispatch('loadDepartments')
			this.$store.dispatch('getVenues')
			this.$store.dispatch('getVenueGroups')
		}
	},
	mounted() {
		this.$modalSendMessage = new Modal(document.getElementById('venue-booking-send-message'))
		this.$modalApproveBooking = new Modal(document.getElementById('venue-booking-approve'))
		this.$modalDeclineBooking = new Modal(document.getElementById('venue-booking-decline'))
		this.$modalCancelBooking = new Modal(document.getElementById('venue-booking-cancel'))
		this.$modalCompleteBooking = new Modal(document.getElementById('venue-booking-complete'))
		this.$modalRescheduleBooking = new Modal(document.getElementById('venue-booking-change-date'))
	},
	methods: {
		flag,
		formatDistanceStrict,
		formatDistanceToNow,
		displayFieldAnswer,
		getPublicFileUrl,

		// TODO move to helpers/actions files
		personPartOfDepartment(department_id) {
			return this.j.roles?.find(d => d.department_id === department_id)
		},

		loadVenueAndBooking() {
			this.states.booking = 'loading'
			this.venue = null
			this.venueBooking = null
			this.venueBookingActivities = []
			this.venueBookings.before = []
			this.venueBookings.after = []
			this.payment = null

			// load venue and booking info
			Promise.all([
				heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}?expand=fields`),
				heyGovApi.get(`${this.j.slug}/venue-bookings/${this.$route.params.bookingUuid}?expand=payment`),
			]).then(
				([venue, venueBooking]) => {
					this.venue = venue.data
					this.venueBooking = venueBooking.data
					this.states.booking = 'idle'
					this.venueBookingReview.deposit_refund = this.venueBooking.deposit

					this.payment = venueBooking.data.payment

					this.loadRelatedBookings('venue', true)
					this.loadActivities()
				},
				error => {
					this.states.booking = 'error'
					Vue.toasted.error(`Error loading booking info (${error.message})`)
				}
			)
		},
		loadRelatedBookings(tab, initalLoad = false) {
			this.relatedBookingsTab = tab
			this.venueBookings.before = []
			this.venueBookings.after = []

			if (this.relatedBookingsAbortController) {
				this.relatedBookingsAbortController.abort()
			}

			this.relatedBookingsAbortController = new AbortController()

			const params = {
				perPage: 3,
				exclude: this.venueBooking.id,
				order: 'asc',
				orderBy: 'starts_at',
			}

			if (tab === 'venue') {
				params.venue_id = this.venue.id
			} else if (tab === 'person') {
				params.person_id = this.venueBooking.person_id
			}

			Promise.all([
				heyGovApi.get(`${this.j.slug}/venue-bookings`, {
					params: {
						...params,
						starts_before: this.venueBooking.starts_at,
					},
					signal: this.relatedBookingsAbortController.signal,
				}),
				heyGovApi.get(`${this.j.slug}/venue-bookings`, {
					params: {
						...params,
						starts_after: this.venueBooking.starts_at,
					},
					signal: this.relatedBookingsAbortController.signal,
				}),
			]).then(
				([bookingsBefore, bookingsAfter]) => {
					this.venueBookings.before.push(...bookingsBefore.data)
					this.venueBookings.after.push(...bookingsAfter.data)
				},
				error => {
					if (error.code !== 'ERR_CANCELED') {
						Vue.toasted.error(`Error loading related bookings (${error.message})`)
					}
				}
			)

			if (initalLoad === false) {
				sendEvent('Load related bookings', {
					booking: this.venueBooking.uuid,
					related: tab,
					venue: this.venue.name,
					feature: 'venues',
				})
			}
		},

		addActivityMessage(message) {
			return heyGovApi
				.post(`${this.j.slug}/venue-bookings/${this.venueBooking.pid}/activity`, message)
				.then(({ data }) => {
					this.venueBookingActivities.unshift(data)
				})
				.catch(handleResponseError(`Couldn't send message ({error})`))
		},
		loadActivities() {
			const params = {}

			if (this.venueBookingActivities.length) {
				params.after = this.venueBookingActivities[0].id
			}

			heyGovApi
				.get(`${this.j.slug}/venue-bookings/${this.$route.params.bookingUuid}/activities`, { params })
				.then(({ data }) => {
					this.$store.dispatch(
						'loadPeople',
						data.map(a => a.person_id)
					)
					this.venueBookingActivities.unshift(...data)
				})
		},

		venueBookingApprove() {
			this.states.bookingStatus = 'loading'

			heyGovApi
				.post(`${this.j.slug}/venue-bookings/${this.venueBooking.uuid}/review-approved`, {
					...this.venueBookingReview,
					capturePayment: 1,
				})
				.then(
					({ data }) => {
						this.venueBooking.status = data.status
						this.venueBooking.reviewed_at = data.reviewed_at
						this.venueBooking.reviewed_by = this.auth.id

						if (this.payment) {
							this.payment.status = 'succeeded'
						}

						Vue.toasted.success('Booking approved')
						this.$modalApproveBooking.hide()
						this.states.bookingStatus = 'idle'
					},
					error => {
						Vue.toasted.error(`Error approving booking (${error.message})`)
						this.states.bookingStatus = 'error'
					}
				)
		},

		venueBookingDecline() {
			this.states.bookingStatus = 'loading'

			heyGovApi
				.post(`${this.j.slug}/venue-bookings/${this.venueBooking.uuid}/decline`, this.venueBookingReview)
				.then(
					({ data }) => {
						this.venueBooking.status = data.status
						this.venueBooking.reviewed_at = data.reviewed_at
						this.venueBooking.reviewed_by = this.auth.id

						if (this.payment) {
							this.payment.status = 'canceled'
						}

						Vue.toasted.show('Booking is declined')
						this.$modalDeclineBooking.hide()
						this.states.bookingStatus = 'idle'

						this.loadActivities()
					},
					error => {
						const msg = error.response?.data?.message || error.message
						Vue.toasted.error(`Error declining booking (${msg})`)
						this.states.bookingStatus = 'error'
					}
				)
		},

		startVenueBookingCancel() {
			if (this.payment) {
				this.venueBookingCancelData.payment_refund = this.payment.amount
			}

			sendEvent('Cancel confirmed booking', {
				booking: this.venueBooking.uuid,
				venue: this.venue.name,
				feature: 'venues',
			})
		},
		venueBookingCancel() {
			this.states.bookingStatus = 'loading'

			heyGovApi
				.post(`${this.j.slug}/venue-bookings/${this.venueBooking.uuid}/cancel`, this.venueBookingCancelData)
				.then(
					({ data }) => {
						this.venueBooking.status = data.status
						this.venueBooking.reviewed_at = new Date()
						this.venueBooking.reviewed_by = this.auth.id
						this.venueBooking.review_message = this.venueBookingCancelData.message
						this.venueBooking.deposit_status = data.deposit_status
						this.venueBooking.deposit_refunded = data.deposit_refunded

						if (this.payment) {
							this.payment.refunded = Number(this.venueBookingCancelData.payment_refund)
						}

						Vue.toasted.info('Reservation is canceled')
						this.$modalCancelBooking.hide()
						this.states.bookingStatus = 'idle'
					},
					error => {
						Vue.toasted.error(`Error cancelling booking (${error.message})`)
						this.states.bookingStatus = 'error'
					}
				)
		},

		venueBookingComplete() {
			this.states.bookingStatus = 'loading'

			heyGovApi
				.post(`${this.j.slug}/venue-bookings/${this.venueBooking.uuid}/complete`, this.venueBookingReview)
				.then(
					({ data }) => {
						this.venueBooking.status = data.status
						this.venueBooking.inspected_by = data.inspected_by
						this.venueBooking.inspection_message = data.inspection_message
						this.venueBooking.deposit_status = data.deposit_status
						this.venueBooking.deposit_refunded = data.deposit_refunded

						this.loadActivities()

						Vue.toasted.info('Booking completed')
						this.$modalCompleteBooking.hide()
						this.states.bookingStatus = 'idle'
					},
					error => {
						Vue.toasted.error(`Error completing booking (${error.message})`)
						this.states.bookingStatus = 'error'
					}
				)
		},

		uploadFile($event) {
			const file = $event.target.files[0]

			if (file.size >= 10485760) {
				// filesize that Postmark or Sendgrid can handle
				Vue.toasted.error('File size too large! Upload one less than 10MB please')
			} else if (!mimeTypes.documents.includes(file.type)) {
				Vue.toasted.error(
					'This file is not an acceped document format. Please upload only text or document files'
				)
			} else {
				const formFile = new FormData()
				formFile.append('file', file)

				heyGovApi.post(`/${this.j.slug}/venues/${this.venue.slug}/file`, formFile).then(({ data }) => {
					this.venue.public_files.push(data)
					this.venueBookingReview.files.push(data.file)

					Vue.toasted.success(`File is uploaded`)
				}, handleResponseError('Error uploading file ({error})'))
			}
		},

		editVenueBookingDates() {
			this.venueBookingEdit.starts_at_local = formatInTimeZone(
				new Date(this.venueBooking.starts_at),
				this.j.timezone,
				"yyyy-MM-dd'T'HH:mm"
			)
			this.venueBookingEdit.ends_at_local = formatInTimeZone(
				new Date(this.venueBooking.ends_at),
				this.j.timezone,
				"yyyy-MM-dd'T'HH:mm"
			)
		},
		venueBookingEditStartDate() {
			if (
				this.venueBookingEdit.starts_at_local &&
				this.venueBookingEdit.ends_at_local &&
				this.venueBookingEdit.starts_at_local > this.venueBookingEdit.ends_at_local
			) {
				const minutes = differenceInMinutes(
					new Date(this.venueBooking.ends_at),
					new Date(this.venueBooking.starts_at)
				)

				this.venueBookingEdit.ends_at_local = format(
					add(new Date(this.venueBookingEdit.starts_at_local), { minutes }),
					"yyyy-MM-dd'T'HH:mm"
				)
			}
		},
		venueBookingEditEndDate() {
			if (
				this.venueBookingEdit.ends_at_local &&
				this.venueBookingEdit.starts_at_local &&
				this.venueBookingEdit.ends_at_local < this.venueBookingEdit.starts_at_local
			) {
				const minutes = differenceInMinutes(
					new Date(this.venueBooking.ends_at),
					new Date(this.venueBooking.starts_at)
				)

				this.venueBookingEdit.starts_at_local = format(
					sub(new Date(this.venueBookingEdit.ends_at_local), { minutes }),
					"yyyy-MM-dd'T'HH:mm"
				)
			}
		},
		updateVenueBookingDates() {
			this.states.bookingEdit = 'loading'
			heyGovApi
				.put(`${this.j.slug}/venue-bookings/${this.venueBooking.uuid}`, this.venueBookingEdit)
				.then(({ data }) => {
					this.venueBooking.starts_at = data.starts_at
					this.venueBooking.ends_at = data.ends_at

					Vue.toasted.success('Booking date and time updated')
					this.$modalRescheduleBooking.hide()

					this.loadRelatedBookings(this.relatedBookingsTab)
					this.loadActivities()
				}, handleResponseError('Error updating booking dates ({error})'))
				.finally(() => {
					this.states.bookingEdit = 'idle'
				})
		},

		activityFileUpload($event) {
			$event.target.files.forEach(file => {
				const existingFile = this.venueBookingActivity.files.find(
					f => f.type === file.type && f.lastModified === file.lastModified && f.size === file.size
				)

				if (file.size > 1024 * 1024 * 10) {
					Vue.toasted.error(`File size for "${file.name}" is too large! Max 10mb please`)
				} else if (!existingFile) {
					this.venueBookingActivity.files.push(file)
				}
			})
		},
		activityFileRemove(file) {
			this.venueBookingActivity.files = this.venueBookingActivity.files.filter(f => f !== file)
		},
		venueBookingSendMessage() {
			const body = new FormData()
			body.set('message', `"${this.venueBookingActivity.message}"`)
			body.set('privacy', this.venueBookingActivity.privacy)
			body.set('sendEmail', 1)
			body.set('type', this.venueBookingActivity.privacy === 'public' ? 'info' : 'warning')

			// append files
			this.venueBookingActivity.files.forEach(file => body.append('files', file))

			heyGovApi
				.post(`${this.j.slug}/venue-bookings/${this.venueBooking.pid}/activity`, body)
				.then(({ data }) => {
					this.venueBookingActivities.unshift(data)

					if (this.venueBookingActivity.type === 'public') {
						Vue.toasted.success(`The message was sent to guest`)
					} else {
						Vue.toasted.success(`The note was sent to department members`)
					}

					this.venueBookingActivity.message = ''
					this.venueBookingActivity.files = []
					this.$modalSendMessage.hide()
				})
				.catch(handleResponseError(`Couldn't send message ({error})`))
		},
	},
	watch: {
		'$route.params.bookingUuid'() {
			this.loadVenueAndBooking()
		},
	},
}
</script>
