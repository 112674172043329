<template>
	<div class="page-payments-utilities-settings">
		<div class="row align-items-center mb-3">
			<div class="col-12">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/payments`">HeyGov Pay</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/payments/${$route.params.departmentId}`">{{
						selectedDepartment ? selectedDepartment.name : '...'
					}}</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/payments/${$route.params.departmentId}/billing-accounts`"
						>Billing Accounts</router-link
					>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
					Import bills
				</h3>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<p class="card-text lead">
					Import a new batch of bills for the accounts in
					<strong v-if="selectedDepartment">{{ selectedDepartment.name }}</strong
					><template
						v-if="selectedDepartment && !selectedDepartment.name.toLowerCase().includes('department')"
					>
						department</template
					>.
				</p>

				<div class="mb-3">
					<label class="form-label">From which application?</label>

					<div>
						<div
							v-for="(integration, id) in paymentIntegrations"
							:key="`int-${id}`"
							class="form-check form-check-inline"
						>
							<input
								class="form-check-input"
								type="radio"
								:id="`import-${id}`"
								v-model="selectedIntegration"
								:value="id"
							/>
							<label class="form-check-label" :for="`import-${id}`">{{ integration.name }}</label>
						</div>
					</div>
				</div>

				<div class="row align-items-center mb-4">
					<div class="col-10 col-lg-5 form-group">
						<label for="file" class="form-label">Bills file</label>

						<input
							type="file"
							class="form-control"
							:accept="paymentIntegrations[selectedIntegration].acceptFile"
							@change="uploadFile"
						/>
					</div>
					<div v-if="selectedIntegration === 'workhorse'" class="col-2 text-center">
						<span class="badge bg-warning-50 text-dark">- or -</span>
					</div>
					<div v-if="selectedIntegration === 'workhorse'" class="col-10 col-lg-5 form-group">
						<label for="file" class="form-label">Bills &amp; balances</label>

						<div>
							<button
								class="btn btn-outline-primary"
								@click="importFromFtp"
								:disabled="
									!selectedDepartment.workhorse_integration ||
										!selectedDepartment.workhorse_integration.enabled
								"
							>
								Import "PayBillOut.csv" from FTP
							</button>
						</div>
					</div>
				</div>

				<p v-if="states.file === 'uploading'" class="">
					<span class="spinner-border spinner-border-sm"></span> Uploading file
				</p>
				<div v-else-if="states.file === 'uploaded'">
					<h5 class="mb-3 mt-4">Preview bills</h5>
					<p class="mb-2">
						<strong>{{ fileData.length }}</strong> bills totaling
						<strong>{{ numberToCurrency(stats.totalAmount) }}</strong> are ready to be uploaded. Here's a
						sample:
					</p>

					<div class="card-table bg-light mb-3">
						<table class="table table-hover table-bills">
							<thead>
								<tr>
									<th>Account</th>
									<!-- <th>Bill</th> -->
									<th>Name</th>
									<th>Service address</th>
									<th>Balance</th>
									<th>Due date</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="bill in fileData" :key="`acct-bill-${bill.account}-${bill.bill_number}`">
									<td>
										<code>{{ bill.account }}</code>
									</td>
									<!-- <td>{{ bill.bill_number }}</td> -->
									<td>{{ bill.name }}</td>
									<td>{{ bill.address | formatAddress }}</td>
									<td>{{ bill.amount | currency }}</td>
									<td>{{ bill.bill_due_date | dateLocal }}</td>
								</tr>
							</tbody>
						</table>
					</div>

					<p class="card-text">
						<button
							class="btn btn-primary px-5"
							@click="importBills"
							:disabled="!fileData.length || states.bills == 'importing'"
						>
							Import bills now
						</button>
					</p>

					<p v-if="states.bills == 'importing'">
						<span class="spinner-border spinner-border-sm"></span> Importing, it may take a minute or two.
					</p>
					<div v-else-if="states.bills === 'imported'" class="alert alert-success">
						All bills are imported and residents can start paying.
						<router-link :to="`/${j.slug}/payments/${$route.params.departmentId}/billing-accounts`"
							>Go back to Billing Accounts</router-link
						>
					</div>
					<div v-else-if="states.bills === 'error'" class="alert alert-danger">
						{{ fileError }}
					</div>
				</div>
				<div v-else-if="states.file === 'error'" class="alert alert-danger">
					{{ fileError }}
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.table-bills tbody {
	display: block;
	max-height: 300px;
	overflow-y: scroll;
}
.table-bills thead,
.table-bills tbody tr {
	display: table;
	width: 100%;
	table-layout: fixed;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'

import heyGovApi from '@/api.js'
import { numberToCurrency } from '@/utils.js'
import { paymentIntegrations } from '@/actions/payments.js'

export default {
	name: 'BillingAccountsImport',
	data() {
		return {
			states: {
				file: 'idle',
				bills: 'idle',
			},
			lastTypeUsed: '',
			paymentIntegrations,
			selectedIntegration: 'workhorse',
			file: null,
			fileData: null,
			fileError: '',
			stats: {
				totalAmount: 0,
			},
		}
	},
	computed: {
		...mapState(['j', 'departments']),
		...mapGetters(['departcurrentRolements']),
		selectedDepartment() {
			return (this.departments || []).find(d => d.id == this.$route.params.departmentId)
		},
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.push(`/${this.j.slug}/quick-pay`)
		} else {
			this.$store.dispatch('loadDepartments').then(() => {
				this.selectedIntegration = this.selectedDepartment.billing_accounts
			})
		}
	},
	methods: {
		numberToCurrency,

		importFromFtp() {
			this.states.file = 'uploading'
			this.states.bills = 'idle'
			this.lastTypeUsed = 'ftp'

			// prepare file data
			var form = new FormData()
			//form.append('file', this.file)
			form.append('department_id', this.$route.params.departmentId)
			form.append('integration', `${this.selectedIntegration}-ftp`)

			// upload the file
			heyGovApi.post(`/${this.j.slug}/billing-accounts/import`, form).then(
				({ data }) => {
					this.fileData = data.bills
					this.stats.totalAmount = data.totalAmount || 0

					this.states.file = 'uploaded'
				},
				error => {
					this.states.file = 'error'

					this.fileError =
						error.response?.data?.message ||
						`We can't import this file. Please contact HeyGov for more detailed information and email them the file you used now (${error.message})`
				}
			)
		},
		uploadFile($event) {
			this.file = $event.target.files[0]
			this.states.file = 'uploading'
			this.states.bills = 'idle'
			this.lastTypeUsed = 'file'

			// prepare file data
			var form = new FormData()
			form.append('file', this.file)
			form.append('department_id', this.$route.params.departmentId)
			form.append('integration', this.selectedIntegration)

			// upload the file
			heyGovApi
				.post(`/${this.j.slug}/billing-accounts/import?department_id=${this.$route.params.departmentId}`, form)
				.then(
					({ data }) => {
						this.stats.totalAmount = data.totalAmount || 0
						this.fileData = data.bills

						this.states.file = 'uploaded'
					},
					error => {
						this.states.file = 'error'

						this.fileError =
							error.response?.data?.message ||
							`We can't import this file. Please contact HeyGov for more detailed information and email them the file you used now (${error.message})`
					}
				)
		},
		importBills() {
			this.states.bills = 'importing'

			// prepare file data
			var form = new FormData()
			form.append('file', this.file)
			form.append('message', 'upload-bills')
			form.append('department_id', this.$route.params.departmentId)

			if (this.lastTypeUsed === 'ftp') {
				form.append('integration', `${this.selectedIntegration}-ftp`)
			} else {
				form.append('integration', this.selectedIntegration)
			}

			// upload the file
			heyGovApi.post(`/${this.j.slug}/billing-accounts/import?import=yes`, form).then(
				() => {
					this.states.bills = 'imported'
				},
				error => {
					this.states.bills = 'error'
					this.fileError = error.message
				}
			)
		},
	},
	watch: {},
}
</script>
