<template>
	<Bar :chart-data="data" :chart-options="options" :height="height" />
</template>

<script>
import 'chart.js/auto'
import { Bar } from 'vue-chartjs/legacy'

export default {
	components: { Bar },
	props: ['data', 'options', 'height'],
}
</script>

<style></style>
