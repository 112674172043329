<template>
	<gmap-map
		class="muni-map"
		:center="center || j.location"
		:zoom="j.location.zoom + adjustZoom"
		:style="{ height: `${height}px` }"
		:options="mapOptions"
		ref="muniMap"
		@zoom_changed="onZoomChanged"
	>
		<gmap-marker
			v-for="(location, index) in markers"
			:key="`extra-marker-${index}`"
			:position="location.position"
			:icon="location.icon"
			:label="location.label"
			:draggable="location.draggable || false"
			@dragend="markerDragEnd"
		></gmap-marker>

		<gmap-heatmap-layer v-if="heatmapLayer.length" :data="heatmapLayer" :options="mapHeatmapOptions" />
	</gmap-map>
</template>

<style scoped>
.muni-map {
	width: 100%;
	min-height: 100px;
	border-radius: 8px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'
import { getGoogleMapsAPI } from 'gmap-vue'
import pluralize from 'pluralize'

// docs for region boundary
// https://developers.google.com/maps/documentation/javascript/dds-boundaries/start

// docs for Advanced Markers
// https://developers.google.com/maps/documentation/javascript/advanced-markers/overview

export default {
	name: 'MuniMap',
	props: {
		center: {
			type: Object,
			default: null,
		},
		mapType: {
			type: String,
			default: 'roadmap',
		},
		mapTypeControl: {
			type: Boolean,
			default: true,
		},
		fullscreenControl: {
			type: Boolean,
			default: true,
		},
		markers: {
			type: Array,
			default: () => [],
		},
		heatmap: Array,
		height: Number,
		adjustZoom: {
			type: Number,
			default: 0,
		},
		includeVenues: {
			default: true,
		},
		venuesAvailability: {
			type: Object,
			default: () => ({}),
		},
		includeDepartments: {
			type: Boolean,
			default: true,
		},
		event: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			states: {
				localityBoundary: false,
				muniMarkers: false,
			},
			mapOptions: {
				mapId: process.env.VUE_APP_GOOGLE_MAPS_MAP_ID,
				mapTypeId: this.mapType,
				mapTypeControl: this.mapTypeControl,
				streetViewControl: false,
				fullscreenControl: this.fullscreenControl,
			},
			mapHeatmapOptions: {
				maxIntensity: 5,
				dissipating: true,
			},
			bounds: null,
		}
	},
	computed: {
		...mapState(['j', 'venues', 'departments']),
		...mapGetters(['currentRole']),
		google: getGoogleMapsAPI,
		heatmapLayer() {
			let heatmapData = []

			if (this.google && this.heatmap) {
				heatmapData = this.heatmap.map(h => {
					return {
						location:
							h.location instanceof this.google.maps.LatLng
								? h.location
								: new this.google.maps.LatLng(h.location),
						weight: h.weight,
					}
				})
			}

			return heatmapData
		},
	},
	created() {},
	mounted() {
		// At this point, the child GmapMap has been mounted, but its map has not been initialized.

		this.$refs.muniMap.$mapPromise.then(map => {
			const mapCapabilities = map.getMapCapabilities(map)

			if (mapCapabilities.isDataDrivenStylingAvailable) {
				this.mapLocalityBoundary(map)
			}

			if (mapCapabilities.isAdvancedMarkersAvailable) {
				this.mapMuniMarkers(map)
			}
		})
	},
	methods: {
		mapLocalityBoundary(map) {
			// If the map has the ability to show the region boundary, then show it.
			if (!this.states.localityBoundary && this.j.location.place_id) {
				const localityLayer = map.getFeatureLayer('LOCALITY')

				const featureStyleOptions = {
					strokeColor: '#810FCB',
					strokeOpacity: 0.3,
					strokeWeight: 2.0,
					fillColor: '#810FCB',
					fillOpacity: 0.1,
				}

				// Apply the style to a single boundary.
				localityLayer.style = options => {
					if (options.feature.placeId == this.j.location.place_id) {
						return featureStyleOptions
					}
				}

				this.states.localityBoundary = true
			}
		},
		mapMuniMarkers(map) {
			// If the map has the ability to show the region boundary, then show it.
			if (!this.states.muniMarkers) {
				Promise.all([this.$store.dispatch('loadDepartments'), this.$store.dispatch('getVenues')]).then(
					([departments, venues]) => {
						const infoWindow = new this.google.maps.InfoWindow()

						this.bounds = new this.google.maps.LatLngBounds()

						// markers for departments
						departments.forEach(department => {
							if (this.includeDepartments === true && department.location?.lat) {
								const pin = new this.google.maps.marker.PinElement({
									background: '#fff3e8',
									borderColor: '#e2a21f',
									glyphColor: '#f3d9a5',
									glyph: new URL(department.location.icon_svg.replace('.svg', '.png')),
								})

								const marker = new this.google.maps.marker.AdvancedMarkerElement({
									content: pin.element,
									map,
									position: { lat: department.location.lat, lng: department.location.lng },
									title: `Department: ${department.name}`,
								})

								marker.addListener('click', () => {
									//const { target } = domEvent;

									const content = `
										<div class="info-window">
											<h6>${department.name}</h6>
											<p class="mb-2"><strong>${department.people.length}</strong> ${pluralize('member', department.people.length)}</p>
											<p class="mb-0">Address: ${department.location.address
												.split(', ')
												.slice(0, 2)
												.join(', ')}</p>
										</div>
									`

									infoWindow.close()
									infoWindow.setContent(content)
									infoWindow.open(marker.map, marker)
								})
							}
						})

						// markers for venues
						if (this.event?.venues) {
							venues = venues.filter(v => this.event.venues.includes(v.id))
						}

						venues.forEach(venue => {
							if (
								this.includeVenues === true ||
								this.includeVenues == venue.id ||
								(Array.isArray(this.includeVenues) && this.includeVenues.includes(venue.id))
							) {
								const position = new this.google.maps.LatLng(venue.location.lat, venue.location.lng)

								let glyph

								const nameParts = venue.name.split(' ')

								if (Number.isInteger(Number(nameParts.at(-1)))) {
									glyph = nameParts.at(-1)
								} else if (venue.location.icon_svg) {
									glyph = new URL(venue.location.icon_svg.replace('.svg', '.png'))
								}

								const pin = new this.google.maps.marker.PinElement({
									background: '#f7f6ff',
									borderColor: '#7879f1',
									glyphColor: '#7879f1',
									glyph,
								})

								const marker = new this.google.maps.marker.AdvancedMarkerElement({
									content: pin.element,
									map,
									position,
									title: `Venue: ${venue.name}`,
								})

								marker.addListener('click', () => {
									//const { target } = domEvent;
									const label = pluralize('reservation', venue.bookings_count)

									const staffInfo =
										this.currentRole === 'CITIZEN'
											? ''
											: `<p class="mb-2"><strong>${venue.bookings_count}</strong> ${label} approved</p>`

									const content = `
								<div class="info-window">
									<h6>${venue.name}</h6>
									${staffInfo}
									<p class="mb-0">Address: ${venue.location.address
										.split(', ')
										.slice(0, 2)
										.join(', ')}</p>
								</div>
							`

									infoWindow.close()
									infoWindow.setContent(content)
									infoWindow.open(marker.map, marker)
								})

								this.bounds.extend(position)
							}
						})
					}
				)

				this.states.muniMarkers = true
			}
		},

		zoomOnVenues(padding = 10) {
			this.$refs.muniMap.fitBounds(this.bounds, padding)
		},

		onZoomChanged(payload) {
			this.$emit('zoom_changed', payload)
		},
		markerDragEnd(payload) {
			this.$emit('marker_dragend', payload)
		},
	},
}
</script>
