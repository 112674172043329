<template>
	<div v-if="mode === 'pdf'">
		<div>
			<label class="pdf-label form-label">{{ currentField.label }}</label>
			<div v-if="!currentField.value">
				<div class="d-flex align-items-end pdf-field">
					<span
						>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;/</span
					>
				</div>
			</div>
			<div v-else>
				<div v-if="currentField.redacted && $route.query.redacted" class="pdf-redacted"></div>
				<div v-else class="pdf-field">
					{{ new Date(currentField.value).toLocaleDateString() }}
				</div>
			</div>
		</div>
		<small v-if="currentField.help" class="text-muted pdf-help">{{ currentField.help }}</small>
	</div>
	<input
		v-else
		type="date"
		:class="'form-control ' + currentField.class"
		:min="currentField.data.minDate"
		:max="currentField.data.maxDate"
		v-model="currentField.value"
		:id="'fsf-' + currentField.id"
		:required="currentField.required == 1"
	/>
</template>

<script>
export default {
	name: 'DatePicker',
	props: {
		currentField: {
			type: Object,
			required: true,
		},
		mode: {
			type: String,
			default: 'fill',
		},
	},
}
</script>
