<template>
	<div class="page-form">
		<div v-if="form">
			<form-page-head :form="form"></form-page-head>

			<div class="row justify-content-center">
				<div class="col-md-10 col-lg-8">
					<div class="card">
						<div class="card-body text-center">
							<h2>✅ The form is created!</h2>

							<img
								src="https://edge.heygov.com/illustrations/form-applications.jpg"
								width="350"
								class="mb-3"
								alt="HeyGov Success"
							/>

							<p class="card-text text-center">
								The HeyGov team will digitize your <strong>{{ form.name }}</strong> form. You will get
								an email when it's ready for you. After digitization you can review it and have edits
								made.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import heyGovApi from '@/api.js'
import Vue from 'vue'
import FormPageHead from '@/views/Forms/FormPageHead.vue'

export default {
	name: 'FormUploadSuccess',
	components: { FormPageHead },
	data() {
		return {
			form: null,
		}
	},
	computed: {
		...mapState(['j']),
	},
	created() {
		this.getFormData()
	},
	methods: {
		getFormData() {
			heyGovApi.get(`${this.j.slug}/forms/${this.$route.params.formSlug}`).then(
				({ data }) => {
					this.form = data
					// console.log(this.form)
				},
				error => {
					Vue.toasted.error(error.message)
					this.state = 'error'
				}
			)
		},
	},
}
</script>
