<template>
	<div class="invited-page py-5">
		<div class="row justify-content-center">
			<div class="col-md-10 col-lg-8 text-center">
				<h1>Hey {{ account.name }}</h1>
				<h2 class="mb-4">Welcome to HeyGov</h2>

				<p>
					HeyGov is a platform that helps your {{ j.type }} provide online services to citizens. Here are the
					things set up so far:
				</p>
			</div>
		</div>

		<h5>Departments:</h5>
		<div class="row row-cols-1 row-cols-lg-2">
			<div v-for="department in activeDepartments" :key="`hg-dept-${department.id}`" class="col">
				<div class="card mb-2">
					<div class="card-body p-2">
						<div class="row align-items-center">
							<div class="col-auto">
								<img
									:src="
										department.logo ||
											`https://eu.ui-avatars.com/api/?size=100&background=${department.color.slice(
												1
											)}&name=${department.name}`
									"
									class="rounded shadow"
									width="50"
									height="50"
									:alt="department.name"
								/>
							</div>
							<div class="col">
								<h3 class="my-0">{{ department.name }}</h3>
							</div>
							<div v-if="department.people.find(p => p.person_id === auth.id)" class="col">
								👋 You're a
								<span
									class="badge"
									:class="roles[department.people.find(p => p.person_id === auth.id).role].class"
									>{{ roles[department.people.find(p => p.person_id === auth.id).role].name }}</span
								>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="isStaff || currentRole === 'ADMIN'" class="col">
				<router-link :to="`/departments?action=new`" class="card card-link mb-2" style="border-style: dashed">
					<div class="card-body p-2">
						<div class="row align-items-center">
							<div class="col-auto">
								<img
									:src="`https://eu.ui-avatars.com/api/?size=100&background=ccc&name=+`"
									class="rounded shadow"
									width="50"
									height="50"
									alt="New department"
								/>
							</div>
							<div class="col">
								Add another department
							</div>
						</div>
					</div>
				</router-link>
			</div>
		</div>

		<h5 class="mt-4">HeyGov services:</h5>
		<div class="row row-cols-2 row-cols-lg-4">
			<div v-for="feature in j.features" :key="`hg-feature-${feature}`" class="col my-2">
				<div class="card">
					<div class="card-body">
						<h3 class="card-title">
							<img
								:src="heyGovFeatures[feature].logo"
								:alt="heyGovFeatures[feature].name"
								:height="20"
								class="float-start me-2"
							/>
							{{ heyGovFeatures[feature].name }}
						</h3>

						<p class="card-text">{{ heyGovFeatures[feature].description }}</p>
					</div>
					<div
						v-if="heyGovFeatures[feature].actionLink && currentRole !== 'WORKER'"
						class="card-footer text-center"
					>
						<router-link :to="`/${j.slug}/${heyGovFeatures[feature].actionLink}`">{{
							heyGovFeatures[feature].actionText
						}}</router-link>
					</div>
				</div>
			</div>
		</div>

		<h4 class="mt-4">👋 Next steps:</h4>
		<div class="row row-cols-1 row-cols-lg-2">
			<div class="col my-1">
				<div class="card">
					<div class="card-body">
						<h3 class="card-title">Update your account</h3>

						<p class="card-text">
							<router-link to="/account">Click here</router-link> to update your account with a profile
							picture or add a password.
						</p>
					</div>
				</div>
			</div>
			<div class="col my-1">
				<div class="card">
					<div class="card-body">
						<h3 class="card-title">Use the services</h3>

						<p class="card-text">
							Use the menus on the left to take a look around HeyGov. Each section has help sections
							available.
						</p>
					</div>
				</div>
			</div>
		</div>

		<h4 class="mt-4">🙋 Get help:</h4>
		<div class="row row-cols-1 row-cols-lg-2">
			<div class="col my-1">
				<div class="card">
					<div class="card-body">
						<h3 class="card-title">Drop-in Demo</h3>

						<p class="card-text">
							We have weekly online demos on how HeyGov works.
							<a
								href="https://us02web.zoom.us/meeting/register/tZwtc-GqrTosGdzpXeQHP7YvTSw3dp5gc-fg"
								target="_blank"
								>Click here to join the next one.</a
							>
						</p>
					</div>
				</div>
			</div>
			<div class="col my-1">
				<div class="card">
					<div class="card-body">
						<h3 class="card-title">Training call</h3>

						<p class="card-text">
							Need more in depth info about HeyGov?
							<a href="https://calendly.com/heygov_app" target="_blank">Schedule a call with us</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

//import heyGovApi from '@/api.js'
import { heyGovFeatures } from '@/utils.js'

export default {
	name: 'Invited',
	data() {
		return {
			heyGovFeatures,
		}
	},
	created() {
		this.$store.dispatch('loadDepartments')
	},
	computed: {
		...mapState(['j', 'roles', 'account', 'departments']),
		...mapGetters(['auth', 'currentRole', 'isStaff', 'activeDepartments']),
	},
	methods: {},
}
</script>
