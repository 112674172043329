<template>
	<div class="venue-calendar">
		<div class="venue-calendar-header row align-items-center mb-3">
			<div class="col-auto">
				<button class="calendar-back py-1 px-3" :disabled="disablePrevMonth()" @click="setMonth(monthPrev)">
					<font-awesome-icon :icon="['fas', 'chevron-left']" />
				</button>
			</div>
			<div class="col text-center">
				{{ format(month, 'MMMM yyyy') }}
			</div>
			<div class="col-auto">
				<button class="calendar-forward py-1 px-3" @click="setMonth(monthNext)">
					<font-awesome-icon :icon="['fas', 'chevron-right']" />
				</button>
			</div>
		</div>

		<div class="calendar-days bg-light text-center rounded-1 py-2 mb-4">
			<div v-for="day in days" :key="day" class="calendar-day week-day">
				<strong>{{ day }}</strong>
			</div>

			<div v-for="day in prevMonthDays" :key="`prev-month-${day}`" class="calendar-day prev-month-day"></div>

			<div
				v-for="(day, index) in monthDays()"
				:key="`month-${day}`"
				class="calendar-day venue-day"
				:class="{
					'calendar-day-today': day === todayYMD,
					'calendar-day-selected': day === daySelected,
					'calendar-day-available': availability[day] && availability[day].available,
				}"
			>
				<span @click="daySelected = day">
					{{ index + 1 }}
				</span>
			</div>
		</div>

		<!-- List venue availability for selected date -->
		<p v-if="!daySelected.startsWith(format(month, 'yyyy-MM'))" class="text-muted text-center">
			Select a date to see availability
		</p>
		<p v-else-if="!availability[daySelected]">
			There is no availability {{ format(parseISO(daySelected), 'MMMM do') }}
		</p>
		<div v-else-if="venue.reservation_mode === 'slots'" class="venue-slots">
			<h4>Availability on {{ format(parseISO(daySelected), 'MMMM do') }}</h4>

			<div
				v-for="(slot, index) in availability[daySelected].slots"
				:key="slot.id"
				class="venue-slot bg-success-lighter px-3 py-2 rounded-1"
				:class="{ disabled: !slot.available, 'mb-3': availability[daySelected].slots.length - 1 !== index }"
			>
				<div class="row align-items-center">
					<div class="col my-1">
						<p class="mb-1">{{ venue.slots.find(s => s.id === slot.id).name }}</p>
						<p class="mb-0">
							<strong>{{ slotLocalTime(slot.starts_at) }} → {{ slotLocalTime(slot.ends_at) }}</strong>
						</p>
					</div>

					<div class="col-12 col-lg-auto my-1">
						<a
							v-if="isEmbed && slot.available"
							:href="`/${j.slug}/venues/${venue.slug}/booking/${daySelected}/${slot.id}`"
							target="heygov-app"
							class="btn btn-primary btn-sm"
						>
							<span class="fs-6">Book now for ${{ slot.price }}</span>
						</a>
						<router-link
							v-else-if="slot.available"
							:to="`/${j.slug}/venues/${venue.slug}/booking/${daySelected}/${slot.id}`"
							class="btn btn-primary btn-sm"
						>
							<span class="fs-6">Book now for ${{ slot.price }}</span>
						</router-link>
						<div v-else class="d-flex justify-content-center align-items-center disabled">
							<span class="fw-bold fs-6 pe-3">{{ slot.reason }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="venue.reservation_mode === 'time'" class="venue-time">
			<h4 class="mb-3">Reserve on {{ format(parseISO(daySelected), 'MMMM do') }}</h4>

			<div v-if="venue.availability.days[format(parseISO(daySelected), 'E')].enabled">
				<p class="mb-2">
					Opening hours:
					<strong
						>{{ venue.availability.days[format(parseISO(daySelected), 'E')].starts_at }} →
						{{ venue.availability.days[format(parseISO(daySelected), 'E')].ends_at }}</strong
					>
				</p>

				<p class="mb-2">
					Price per hour:
					<strong>{{ venue.availability.days[format(parseISO(daySelected), 'E')].price | currency }}</strong>
				</p>

				<div class="row">
					<div class="col-lg-6">
						<div class="form-floating mb-3">
							<select class="form-control" id="venue-book-start-time" v-model="bookingHours.start">
								<option
									v-for="(hour, index) in venueStartHours(
										venue.availability.days[format(parseISO(daySelected), 'E')]
									)"
									:key="`venue-start-h-${index}`"
									:value="hour"
									>{{ hour | timeLocal }}</option
								>
							</select>
							<label for="venue-book-start-time">Start time</label>
						</div>
					</div>
					<div class="col-lg-6 mb-3">
						<div class="form-floating">
							<select class="form-control" id="venue-book-start-time" v-model="bookingHours.end">
								<option
									v-for="(hour, index) in venueEndHours(
										venue.availability.days[format(parseISO(daySelected), 'E')]
									)"
									:key="`venue-end-h-${index}`"
									:value="hour"
									>{{ hour | timeLocal }}</option
								>
							</select>
							<label for="venue-book-start-time">End time</label>
						</div>
					</div>
				</div>

				<div v-if="bookingHours.start && bookingHours.end">
					<p
						v-if="differenceInHours(bookingHours.end, bookingHours.start) < Number(venue.availability.min)"
						class="card-text"
					>
						<strong>{{ venue.name }}</strong> can be booked for minimum
						{{ pluralize('hour', Number(venue.availability.min), true) }}
					</p>
					<p
						v-else-if="
							differenceInHours(bookingHours.end, bookingHours.start) > Number(venue.availability.max)
						"
						class="card-text"
					>
						<strong>{{ venue.name }}</strong> can be booked for maximum
						{{ pluralize('hour', Number(venue.availability.max), true) }}
					</p>
					<template v-else>
						<p class="mb-2">
							Hours selected:
							<strong>{{ differenceInHours(bookingHours.end, bookingHours.start) }}</strong>
						</p>

						<p class="mb-3">
							Subtotal:
							<strong>{{
								(venue.availability.days[format(parseISO(daySelected), 'E')].price *
									differenceInHours(bookingHours.end, bookingHours.start))
									| currency
							}}</strong>
						</p>

						<p class="card-text">
							<a
								v-if="isEmbed"
								:href="
									`/${j.slug}/venues/${venue.slug}/booking/${daySelected}/${format(
										bookingHours.start,
										'HH:mm'
									)}-${format(bookingHours.end, 'HH:mm')}`
								"
								target="heygov-app"
								class="btn btn-primary"
								:class="{ disabled: !bookingHoursAvailable }"
								>Continue booking</a
							>
							<router-link
								v-else
								:to="
									`/${j.slug}/venues/${venue.slug}/booking/${daySelected}/${format(
										bookingHours.start,
										'HH:mm'
									)}-${format(bookingHours.end, 'HH:mm')}`
								"
								class="btn btn-primary"
								:class="{ disabled: !bookingHoursAvailable }"
								>Continue booking</router-link
							>

							<span v-if="!bookingHoursAvailable && bookingHoursReason" class="text-danger-400 ms-2">{{
								bookingHoursReason
							}}</span>
						</p>
					</template>
				</div>
			</div>
			<p v-else class="card-text mb-2">
				<strong>{{ venue.name }}</strong> is not available on {{ format(parseISO(daySelected), 'EEEE') }}
			</p>
		</div>
		<pre v-else>{{ venue.reservation_mode }}</pre>
	</div>
</template>

<style lang="scss" scoped>
@import '@/assets/variables';

.venue-calendar {
	.calendar-back,
	.calendar-forward {
		border-radius: 0.5rem;

		&:hover {
			background-color: $neutral-50;
		}
	}

	.venue-calendar-header {
		button {
			background-color: transparent;
			border: none;

			&:disabled {
				cursor: not-allowed;
				color: #898c90;
			}
		}
	}

	.calendar-days {
		display: grid;
		grid-template-columns: repeat(7, 1fr);

		.calendar-day {
			padding: 3px;

			span {
				transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
			}

			&.calendar-day-today span {
				border: 1px solid var(--bs-primary);
			}

			&.venue-day span {
				cursor: not-allowed;
				display: inline-block;
				width: 50px;
				line-height: 50px;
				border-radius: 2rem;
				color: #898c90;
				text-decoration: line-through;
			}

			&.calendar-day-available span {
				color: #2a9836;
				cursor: pointer;
				font-weight: bold;
				text-decoration: none;

				&:hover {
					background-color: #f0f0f0;
				}
			}

			&.calendar-day-selected span {
				background-color: var(--bs-primary);
				color: #fff;
				font-weight: bold;

				&:hover {
					background-color: var(--bs-primary);
				}
			}
		}
	}

	.venue-slot.disabled {
		color: #898c90 !important;
		background-color: #dfdede !important;
	}

	@media (max-width: 800px) {
		.calendar-days .calendar-day {
			padding: 2px;

			&.venue-day span {
				width: 40px;
				line-height: 40px;
			}
		}
	}

	@media (max-width: 480px) {
		.calendar-days .calendar-day.venue-day span {
			width: 35px;
			line-height: 35px;
		}
	}
}
</style>

<script>
import {
	parse,
	parseISO,
	format,
	getDaysInMonth,
	getDay,
	startOfDay,
	startOfMonth,
	add,
	sub,
	differenceInHours,
} from 'date-fns'
import { mapState } from 'vuex'
import Vue from 'vue'
import pluralize from 'pluralize'

import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'

export default {
	name: 'VenueCalendarPublic',
	props: {
		venue: {
			type: Object,
			required: true,
		},
		isEmbed: {
			type: Boolean,
			default: false,
		},
		options: Object,
	},
	data() {
		const todayYMD = format(new Date(), 'yyyy-MM-dd')
		let daySelected = todayYMD

		if (this.$route.query.date) {
			try {
				let date = parseISO(this.$route.query.date)
				date = format(date, 'yyyy-MM-dd')

				if (date < todayYMD) {
					throw new Error('In the past')
				}

				daySelected = date
			} catch (err) {
				Vue.toasted.error(`Problem with date ${this.$route.query.date} - ${err.message}`)
			}
		}

		return {
			today: startOfDay(new Date()),
			todayYMD,
			daySelected,
			month: null,
			monthPrev: null,
			monthNext: null,

			availabilityLoaded: [],
			availability: {},

			bookingHoursAvailable: false,
			bookingHoursReason: '',
			bookingHours: {
				start: null,
				end: null,
			},
		}
	},
	computed: {
		...mapState(['j', 'account']),
		days() {
			return this.account?.start_of_week === 'Mon'
				? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
				: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
		},
		prevMonthDays() {
			return getDay(this.month) - (this.account?.start_of_week === 'Mon' ? 1 : 0)
		},
	},
	created() {
		this.setMonth(parseISO(this.daySelected))
	},
	methods: {
		format,
		parseISO,
		differenceInHours,
		pluralize,

		setMonth(date) {
			this.month = startOfMonth(date)
			this.monthPrev = sub(date, { months: 1 })
			this.monthNext = add(date, { months: 1 })

			// load availability for this and next month
			this.loadAvailability(format(this.month, 'yyyy-MM'))
			this.loadAvailability(format(this.monthNext, 'yyyy-MM'))

			this.$emit('calendar-updated')

			setTimeout(() => {
				this.$emit('calendar-updated')
			}, 500)
		},
		monthDays() {
			const days = []
			const daysInMonth = getDaysInMonth(this.month)

			for (let i = 0; i < daysInMonth; i++) {
				days.push(format(add(this.month, { days: i }), 'yyyy-MM-dd'))
			}

			return days
		},
		disablePrevMonth() {
			return format(this.monthPrev, 'yyyy-MM') < format(this.today, 'yyyy-MM')
		},

		loadAvailability(forDate) {
			if (!this.availabilityLoaded.includes(forDate)) {
				heyGovApi(`${this.j.slug}/venues/${this.venue.slug}/availability/${forDate}`).then(({ data }) => {
					this.availability = { ...this.availability, ...data.days }

					this.$emit('calendar-updated')
				}, handleResponseError(`Couldn't load venue availability for ${forDate} ({error})`))

				this.availabilityLoaded.push(forDate)
			}
		},

		slotLocalTime(time) {
			const slotDate = parse(time, 'HH:mm:ss', parseISO(this.daySelected))
			const text = slotDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })

			if (text.startsWith(0)) {
				return text.substr(1)
			}

			return text
		},

		venueStartHours(day) {
			const hours = []

			const startHour = parse(day.starts_at, 'HH:mm', new Date())
			const endHour = parse(day.ends_at, 'HH:mm', new Date())

			const diff = differenceInHours(endHour, startHour)

			for (let i = 0; i < diff; i++) {
				hours.push(add(startHour, { hours: i }))
			}

			return hours
		},

		venueEndHours(day) {
			const hours = []

			const startHour = this.bookingHours.start || parse(day.starts_at, 'HH:mm', new Date())
			const endHour = parse(day.ends_at, 'HH:mm', new Date())

			const diff = Math.min(differenceInHours(endHour, startHour), this.venue.availability.max)

			for (let i = 0; i < diff; i++) {
				hours.push(add(startHour, { hours: i + 1 }))
			}

			return hours
		},

		checkHoursAvailability() {
			this.bookingHoursAvailable = false
			this.bookingHoursReason = ''

			if (this.bookingHours.start && this.bookingHours.end) {
				const interval = {
					start: `${this.daySelected} ${format(this.bookingHours.start, 'HH:mm')}`,
					end: `${this.daySelected} ${format(this.bookingHours.end, 'HH:mm')}`,
				}

				heyGovApi
					.post(`${this.j.slug}/venues/${this.venue.slug}/availability-interval`, interval)
					.then(({ data }) => {
						this.bookingHoursAvailable = data.available
						this.bookingHoursReason = data.reason
					})
					.catch(handleResponseError(`Couldn't check availability for ${this.daySelected} ({error})`))
			}
		},
	},
	watch: {
		daySelected() {
			this.bookingHours.start = null
			this.bookingHours.end = null

			this.$emit('calendar-updated')

			setTimeout(() => {
				this.$emit('calendar-updated')
			}, 500)
		},
		bookingHours: {
			handler() {
				this.checkHoursAvailability()
			},
			deep: true,
		},
	},
}
</script>
