<template>
	<router-link
		:to="`/${j.slug}/forms/${form.slug}`"
		class="card card-form text-dark ratio ratio-1x1"
		:id="`form-card-${form.slug}`"
		:class="{ 'has-icon': form.icon, 'has-button': actionButton }"
		:style="{ 'background-color': shadeColor(formColor, 10) }"
	>
		<font-awesome-icon
			v-if="form.icon"
			:icon="['fas', form.icon]"
			class="form-icon"
			:style="{ color: formColor }"
		/>
		<div class="card-body p-3">
			<h6 class="card-title">{{ form.name }}</h6>

			<p v-if="currentRole !== 'CITIZEN'" class="card-text">
				<span v-if="form.status !== 'public'" class="badge me-2" :class="[formStatuses[form.status].class]">{{
					formStatuses[form.status].name
				}}</span>
				<span v-if="form.has_certificate === 'pdf'" class="badge bg-success-50 text-success-400 me-2"
					>Certificate Attached</span
				>
				<span v-else-if="form.has_certificate === 'qrc'" class="badge bg-success-50 text-success-400 me-2"
					>QR code</span
				>
				<span v-if="form.is_template" class="badge bg-warning-lighter text-warning me-2">Template</span>
			</p>

			<div v-if="actionButton" class="form-action-button text-center d-grid">
				<button class="btn btn-sm btn-primary">{{ form.textAction || 'Get this form' }}</button>
			</div>

			<h3 v-if="form.price" class="my-0 form-price">{{ form.price }}</h3>
		</div>
	</router-link>
</template>

<style lang="scss" scoped>
.card-form {
	overflow: hidden;

	&.has-icon {
		background-color: rgba(224, 224, 224, 0.4);
	}

	.form-icon {
		right: -1.5rem !important;
		position: absolute;
		left: auto;
		width: 10rem;
		color: #e0e0e0;
		opacity: 0.5;
	}

	&:not(.has-button) .form-price {
		position: absolute;
		bottom: 1rem;
	}

	.form-action-button {
		position: absolute;
		bottom: 1rem;
		left: 0.5rem;
		right: 0.5rem;

		.btn {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}
}

@media (max-width: 575.98px) {
	.card-form .form-icon {
		width: 7rem;
	}
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import { formStatuses } from '@/actions/forms.js'
import { shadeColor } from '@/utils.js'

export default {
	name: 'FormCard',
	props: {
		form: {
			type: Object,
			required: true,
		},
		actionButton: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			formStatuses,
		}
	},
	computed: {
		...mapGetters(['currentRole']),
		...mapState(['j', 'departments']),
		formColor() {
			let color = null

			if (this.departments && this.form.department_id) {
				color = this.departments.find(d => d.id === this.form.department_id)?.color
			}

			return color
		},
	},
	methods: {
		shadeColor,
	},
}
</script>
