<template>
	<div class="page-payments-utilities-settings">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/payments`">HeyGov Pay</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/payments/${$route.params.departmentId}`">{{
						selectedDepartment ? selectedDepartment.name : '...'
					}}</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-1" />
					Billing Accounts
				</h3>
			</div>
			<div v-if="selectedDepartment && selectedDepartment.billing_accounts" class="col-auto">
				<button
					class="btn btn-sm btn-outline-dark me-2"
					data-bs-toggle="modal"
					data-bs-target="#modal-billing-accounts-settings"
				>
					<font-awesome-icon :icon="['fas', 'cog']" class="me-2" /> Settings
				</button>
				<button
					class="btn btn-sm btn-primary me-2"
					data-bs-toggle="modal"
					data-bs-target="#modal-billing-accounts-add"
				>
					<font-awesome-icon :icon="['fas', 'plus']" class="me-1" /> Add account
				</button>
				<router-link
					:to="`/${j.slug}/payments/${$route.params.departmentId}/billing-accounts/import`"
					class="btn btn-sm btn-primary"
					><font-awesome-icon :icon="['fas', 'file-import']" class="me-2" /> Import bills</router-link
				>
			</div>
		</div>

		<div class="row row-cols-2 row-cols-lg-3 gx-3 mb-3">
			<div class="col">
				<div class="card h-100">
					<div class="card-body p-3 text-center">
						<p class="text-neutral-500 mb-2">Paid bills</p>
						<div class="progress">
							<div class="progress-bar bg-success" :style="{ width: `${paidBillsPercentage}%` }">
								<span v-if="paidBillsPercentage > 50" class="px-1"
									>{{ stats.paid }} of {{ stats.total }}</span
								>
							</div>
							<span v-if="paidBillsPercentage <= 50" class="px-1"
								>{{ stats.paid }} of {{ stats.total }}</span
							>
						</div>
					</div>
				</div>
			</div>
			<div class="col d-none d-lg-block">
				<div class="card h-100">
					<div class="card-body p-3 text-center">
						<p class="text-neutral-500 mb-2">HeyGov accounts linked</p>
						<div class="progress">
							<div
								class="progress-bar bg-warning"
								:style="{ width: `${(stats.heygov / stats.total) * 100}%` }"
							>
								<span v-if="heygovLinkedPercentage > 50" class="px-1">{{ stats.heygov }} linked</span>
							</div>
							<span v-if="heygovLinkedPercentage <= 50" class="px-1">{{ stats.heygov }} linked</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col">
				<div class="card h-100">
					<div class="card-body p-0">
						<div v-for="log in logs" :key="log.id" class="py-1 px-2 hover">
							<small class="text-neutral-400 me-1">{{
								formatDistanceToNowStrict(new Date(log.created_at), { addSuffix: true })
							}}</small>
							{{ log.message }}
						</div>
						<p v-if="!logs.length" class="text-center my-2">
							<small class="text-neutral-400">No account logs yet</small>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div class="bar-filters border rounded bg-white p-1 mb-2">
			<div class="row align-items-center g-2">
				<div class="col-sm-6 col-lg-3">
					<input
						type="search"
						class="form-control form-control-sm"
						v-model="filters.q"
						placeholder="Search account or name"
					/>
				</div>

				<div class="col-sm-6 col-lg-3">
					<input
						type="search"
						class="form-control form-control-sm"
						v-model="filters.address_line1"
						placeholder="Address"
					/>
				</div>

				<div class="col-sm-6 col-lg-3">
					<select class="form-select form-select-sm" v-model="filters.address_postal_code">
						<option :value="null">All ZIP codes</option>
						<option v-for="(number, code) in stats.postalCodes" :key="code" :value="code"
							>{{ code }} ({{ number }})</option
						>
					</select>
				</div>
			</div>
		</div>

		<div v-if="selectedDepartment && !selectedDepartment.billing_accounts" class="text-center py-6">
			<p>Contact HeyGov to enable billing accounts for this account.</p>
			<p v-if="isStaff" class="text-neutral-200">
				<span class="cursor-pointer" @click="enableBillingAccounts()">enable now</span>
			</p>
		</div>
		<div v-else class="card">
			<div class="card-body">
				<p class="card-text">
					Use this feature to allow your residents to pay their utility or tax bills online.
				</p>

				<div class="card-table mb-3">
					<table class="table table-hover">
						<thead>
							<tr>
								<th @click="sortTable('account_number', 'asc')" class="hover cursor-pointer">
									Account
									<small v-if="sorting.orderBy === 'account_number'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th @click="sortTable('name', 'asc')" class="hover cursor-pointer">
									Name
									<small v-if="sorting.orderBy === 'name'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th>Service address</th>
								<th @click="sortTable('balance', 'asc')" class="hover cursor-pointer">
									Balance
									<small v-if="sorting.orderBy === 'balance'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<!-- <th>Bill date</th> -->
								<th @click="sortTable('last_bill_due_date', 'asc')" class="hover cursor-pointer">
									Due date
									<small v-if="sorting.orderBy === 'last_bill_due_date'">{{
										sorting.order === 'desc' ? '▲' : '▼'
									}}</small>
								</th>
								<th>HeyGov account</th>
							</tr>
						</thead>

						<tbody>
							<tr v-for="account in billingAccounts" :key="account.id">
								<td>
									<router-link
										:to="
											`/${j.slug}/payments/${selectedDepartment.id}/billing-accounts/${account.uuid}`
										"
									>
										{{ account.account_number }}
									</router-link>
								</td>
								<td>{{ account.name }}</td>
								<td
									@mouseenter="activePostalCode = account.address.postal_code.slice(0, 5)"
									@mouseleave="activePostalCode = ''"
								>
									{{ account.address.line1 }},<span
										class="d-inline-block rounded-1 px-1 ms-1"
										:class="{
											'bg-warning-50 text-warning-300':
												activePostalCode === account.address.postal_code.slice(0, 5),
										}"
										>{{ account.address.postal_code }}</span
									>
								</td>
								<td>
									<span
										:class="{
											'text-danger-400': account.balance > 0,
											'text-success-400': account.balance < 0,
										}"
										>{{ account.balance | currency }}</span
									>
								</td>
								<!-- <td>{{ account.last_bill_date | dateLocal('en-US', { timeZone: j.timezone }) }}</td> -->
								<td>{{ account.last_bill_due_date | dateLocal('en-US', { timeZone: j.timezone }) }}</td>
								<td>
									<person-link
										v-if="account.person_id"
										:id="account.person_id"
										:avatar="18"
									></person-link>
								</td>
							</tr>
						</tbody>

						<tfoot>
							<tr v-if="states.billingAccounts === 'loading'">
								<td colspan="7">
									<p class="my-5 text-center text-neutral-400">
										<span class="spinner-border spinner-border-sm"></span> Loading accounts
									</p>
								</td>
							</tr>
							<tr v-else-if="states.billingAccounts === 'loaded' && !billingAccounts.length">
								<td colspan="7">
									<p class="my-3 text-center text-neutral-400">There are no billing accounts yet</p>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<!-- Pagination -->
				<div v-if="pag.total && states.billingAccounts !== 'loading'" class="row align-items-center">
					<div class="col-lg-4">
						<span class="me-2 text-muted">Per page</span>
						<div class="d-inline-flex">
							<select class="form-select form-select-sm" v-model="pag.limit">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>
					<div class="col-4 text-center">
						Showing {{ pag.limit * (pag.page - 1) + 1 }}-{{ Math.min(pag.limit * pag.page, pag.total) }} of
						{{ pag.total }}
					</div>
					<div class="col-lg-4">
						<nav v-if="pag.pages > 1" class="float-end">
							<ul class="pagination mb-0">
								<li class="page-item">
									<a class="page-link" @click="pag.page = 1">First</a>
								</li>
								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="p == pag.page ? 'active' : ''"
								>
									<a v-if="Math.abs(pag.page - p) < 4" class="page-link" @click="pag.page = p">
										{{ p }}
									</a>
								</li>
								<li class="page-item">
									<a class="page-link" @click="pag.page = pag.pages">Last</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-billing-accounts-settings" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div v-if="jurisdictionSettings" class="modal-content">
					<form @submit.prevent="saveJurisdictionSettings">
						<div class="modal-header">
							<h5 class="modal-title">
								Billing accounts settings
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>
								Settings for Billing Accounts. If you have Billing Accounts for multiple departments,
								these settings will apply to all of them.
							</p>

							<div class="form-group mb-3">
								<label for="bank-holder" class="form-label">Fields for account finder</label>

								<div class="card-table">
									<table class="table table-hover">
										<tbody>
											<tr v-for="field in baFields" :key="field.id">
												<td>
													<div class="form-check form-switch">
														<input
															class="form-check-input"
															type="checkbox"
															role="switch"
															:id="`billing-accounts-${field.id}`"
															v-model="
																jurisdictionSettings.billing_accounts.fields[field.id]
																	.enabled
															"
														/>
														<label
															class="form-check-label"
															:for="`billing-accounts-${field.id}`"
															>{{ field.name }}</label
														>
													</div>
												</td>
												<td>
													<div class="form-check">
														<input
															class="form-check-input"
															type="checkbox"
															v-model="
																jurisdictionSettings.billing_accounts.fields[field.id]
																	.required
															"
															:disabled="
																!jurisdictionSettings.billing_accounts.fields[field.id]
																	.enabled
															"
															:id="`billing-accounts-${field.id}-required`"
														/>
														<label
															class="form-check-label"
															:for="`billing-accounts-${field.id}-required`"
														>
															Make required
														</label>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>

							<div
								v-if="!jurisdictionSettings.billing_accounts.fields.account_number.enabled"
								class="alert alert-danger"
							>
								⚠️ We recommend enabling Account Number and making it required. By not doing so,
								residents could be able to search and view bills &amp; personal info of other residents.
							</div>
						</div>
						<div class="modal-footer justify-content-end">
							<button class="btn btn-primary px-5">
								Save settings
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-billing-accounts-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<form @submit.prevent="createBillingAccount">
						<div class="modal-header">
							<h5 class="modal-title">
								Add a Billing Account
							</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<p>
								Create a Billing Account for a resident.
							</p>

							<div class="form-group mb-3">
								<label for="billing-account-number" class="form-label">Account number</label>

								<input
									type="text"
									class="form-control"
									id="billing-account-number"
									placeholder="1234"
									v-model="billingAccountNew.account_number"
									required
								/>
							</div>

							<div class="form-group mb-3">
								<label for="billing-account-name" class="form-label">Name</label>

								<input
									type="text"
									class="form-control"
									id="billing-account-name"
									placeholder="Resident, company, or organization name"
									v-model="billingAccountNew.name"
									required
								/>
							</div>

							<div class="form-group mb-3">
								<label for="billing-account-address-line1" class="form-label">Address</label>

								<gmap-autocomplete
									@place_changed="setBillingAccountAddress"
									:select-first-on-enter="true"
									:options="autocompleteOptions"
								>
									<template v-slot:default="slotProps">
										<div class="form-floating mb-3">
											<input
												id="billing-account-address-line1"
												class="form-control"
												ref="input"
												:value="billingAccountNew.address.line1"
												placeholder="Street address"
												required
												v-on="slotProps.listeners"
												@focus="states.address = 'idle'"
												@blur="setBillingAccountAddressPart"
											/>
											<label for="billing-account-address-line1">Address</label>
										</div>
									</template>
								</gmap-autocomplete>

								<div class="form-floating mb-3">
									<input
										type="text"
										class="form-control"
										id="billing-account-address-line2"
										placeholder="Apartment, suite, etc. (optional)"
										v-model="billingAccountNew.address.line2"
									/>
									<label for="billing-account-address-line2">Apartment, suite, etc. (optional)</label>
								</div>

								<div class="row gx-3">
									<div class="col-12 col-lg-5">
										<div class="form-floating mb-3">
											<input
												type="text"
												class="form-control"
												id="billing-account-address-city"
												placeholder="City"
												v-model="billingAccountNew.address.city"
												required
											/>
											<label for="billing-account-address-city">City</label>
										</div>
									</div>
									<div class="col-6 col-lg-4">
										<div class="form-floating mb-3">
											<select
												class="form-select"
												id="billing-account-address-state"
												placeholder="State"
												v-model="billingAccountNew.address.state"
												required
											>
												<option v-for="(label, code) in usStates" :key="code" :value="code">{{
													label
												}}</option>
											</select>
											<label for="billing-account-address-state">State</label>
										</div>
									</div>
									<div class="col-6 col-lg-3">
										<div class="form-floating mb-3">
											<input
												type="text"
												class="form-control"
												id="billing-account-address-postal-code"
												placeholder="Postal code"
												v-model="billingAccountNew.address.postal_code"
												required
												inputmode="numeric"
												minlength="5"
											/>
											<label for="billing-account-address-postal-code">ZIP code</label>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label for="billing-account-balance" class="form-label">Balance</label>

								<input
									type="number"
									class="form-control"
									id="billing-account-balance"
									placeholder="Balance that needs to be paid"
									v-model="billingAccountNew.balance"
									required
									step="0.01"
								/>
							</div>
						</div>
						<div class="modal-footer justify-content-end">
							<button class="btn btn-primary px-5">
								Add account
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Modal } from 'bootstrap'
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import { debounce } from 'vue-debounce'
import { formatDistanceToNowStrict } from 'date-fns'

import heyGovApi, { handleResponseError } from '@/api.js'
import { sendEvent } from '@/utils.js'
import { usStates } from '@/lib/geo.js'

import PersonLink from '@/components/PersonLink.vue'

export default {
	name: 'BillingAccounts',
	metaInfo() {
		return {
			title: `Billing accounts for ${this.selectedDepartment?.name}`,
		}
	},
	components: {
		PersonLink,
	},
	data() {
		return {
			states: {
				billingAccounts: 'loading',
			},
			pag: {
				limit: localStorage.getItem('billing-accounts-limit') || 50,
				total: 0,
				page: 1,
				pages: 0,
			},
			sorting: {
				orderBy: localStorage.getItem('billing-accounts-orderBy') || 'id',
				order: localStorage.getItem('billing-accounts-order') || 'asc',
			},
			filters: {
				q: '',
				address_line1: '',
				address_postal_code: null,
			},
			billingAccounts: [],
			activePostalCode: '',
			stats: {
				total: 0,
				paid: 0,
				heygov: 0,
				postalCodes: {},
			},
			logs: [],

			$modalBillingAccountCreate: null,
			billingAccountNew: {
				department_id: this.$route.params.departmentId,
				account_number: '',
				name: '',
				address: {
					line1: '',
					line2: '',
					city: '',
					state: '',
					postal_code: '',
					country: 'US',
				},
				balance: null,
			},

			baFields: [
				{
					id: 'account_number',
					name: 'Account number',
				},
				{
					id: 'name',
					name: 'Name',
				},
				{
					id: 'address_line1',
					name: 'Address',
				},
				{
					id: 'address_postal_code',
					name: 'ZIP code',
				},
			],
			jurisdictionSettings: null,

			autocompleteOptions: {
				componentRestrictions: { country: 'us' },
				fields: ['geometry', 'address_components', 'url', 'place_id'],
			},
			usStates,
		}
	},
	computed: {
		...mapGetters(['isStaff']),
		...mapState(['j', 'departments']),
		selectedDepartment() {
			return (this.departments || []).find(d => d.id == this.$route.params.departmentId)
		},
		loadBillingAccountsDebounced() {
			return debounce(this.loadBillingAccounts, 250)
		},
		paidBillsPercentage() {
			return this.stats.total ? Math.round((this.stats.paid / this.stats.total) * 100) : 0
		},
		heygovLinkedPercentage() {
			return this.stats.total ? Math.round((this.stats.heygov / this.stats.total) * 100) : 0
		},
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.push(`/${this.j.slug}/quick-pay`)
		} else {
			this.$store.dispatch('loadDepartments')
			this.loadBillingAccounts()
			this.loadBillingAccountsStats()
			this.loadJurisdictionSettings()

			sendEvent('View Billing Accounts', {
				department_id: this.$route.params.departmentId,
				department: this.selectedDepartment?.name,
				feature: 'payments',
			})
		}
	},
	mounted() {
		this.$modalBillingAccountCreate = new Modal(document.getElementById('modal-billing-accounts-add'))
	},
	methods: {
		formatDistanceToNowStrict,

		loadBillingAccounts() {
			this.states.billingAccounts = 'loading'

			const params = {
				department_id: this.$route.params.departmentId,
				...this.filters,
				...this.sorting,
				limit: this.pag.limit,
				page: this.pag.page,
			}

			heyGovApi(`${this.j.slug}/billing-accounts`, {
				params,
			}).then(({ data, headers }) => {
				this.pag.total = Number(headers['x-total'])
				this.pag.pages = Math.ceil(this.pag.total / this.pag.limit)
				this.billingAccounts.push(...data)
				this.states.billingAccounts = 'loaded'
			}, handleResponseError(`Couldn't load utility accounts ({error})`))
		},
		loadBillingAccountsStats() {
			heyGovApi(`${this.j.slug}/billing-accounts/stats/${this.$route.params.departmentId}`).then(({ data }) => {
				this.stats.total = data.total
				this.stats.paid = data.paid
				this.stats.heygov = data.heygov

				const sortedArray = Object.entries(data.postalCodes).sort(([, a], [, b]) => b - a)

				this.stats.postalCodes = Object.fromEntries(sortedArray)
			}, handleResponseError(`Couldn't load accounts stats ({error})`))

			heyGovApi(`${this.j.slug}/billing-accounts/logs/${this.$route.params.departmentId}?limit=3`).then(
				({ data }) => {
					this.logs.push(...data)
				},
				handleResponseError(`Couldn't load accounts logs ({error})`)
			)
		},
		loadJurisdictionSettings() {
			heyGovApi(`${this.j.slug}`).then(({ data }) => {
				data.features_options.billing_accounts ||= {
					fields: {
						account_number: {
							enabled: true,
							required: true,
						},
						name: {
							enabled: false,
							required: false,
						},
						address_line1: {
							enabled: false,
							required: false,
						},
						address_postal_code: {
							enabled: false,
							required: false,
						},
					},
				}

				this.jurisdictionSettings = data.features_options
			}, handleResponseError(`Couldn't load settings ({error})`))
		},

		saveJurisdictionSettings() {
			this.$store.commit('updateCurrentJurisdiction', {
				features_options: this.jurisdictionSettings,
			})
			this.$store.dispatch('saveCurrentJurisdiction', { message: '✔️ Settings are saved' }).then(() => {
				// hide QuickPay settings modal
			})
		},

		sortTable(orderBy, defaultOrder = 'asc') {
			if (this.sorting.orderBy === orderBy) {
				// if the same column is clicked, reverse the sort order
				this.sorting.order = this.sorting.order === 'asc' ? 'desc' : 'asc'
			} else {
				// if a new column is clicked, start with the default order
				this.sorting.order = defaultOrder
			}

			this.sorting.orderBy = orderBy
		},

		enableBillingAccounts() {
			if (confirm('For sure enable Billing Accounts?')) {
				heyGovApi
					.put(`${this.j.slug}/departments/${this.$route.params.departmentId}`, {
						billing_accounts: 1,
					})
					.then(() => {
						this.selectedDepartment.billing_accounts = 1
					}, handleResponseError(`Couldn't enable billing accounts ({error})`))
			}
		},

		setBillingAccountAddress(place) {
			let street = ''
			let route = ''

			place.address_components.forEach(part => {
				if (part.types.includes('street_number')) {
					street = part.long_name
				} else if (part.types.includes('route')) {
					route = part.long_name
				} else if (part.types.includes('locality')) {
					this.billingAccountNew.address.city = part.long_name
				} else if (part.types.includes('administrative_area_level_1')) {
					this.billingAccountNew.address.state = part.short_name
				} else if (part.types.includes('postal_code')) {
					this.billingAccountNew.address.postal_code = part.long_name
				} else if (part.types.includes('country')) {
					this.billingAccountNew.address.country = part.short_name
				}
			})

			this.billingAccountNew.address.line1 = `${street} ${route}`.trim()
			this.billingAccountNew.address.lat = place.geometry.location.lat()
			this.billingAccountNew.address.lng = place.geometry.location.lng()
		},
		setBillingAccountAddressPart($event) {
			this.billingAccountNew.address.line1 = $event.target.value
		},
		createBillingAccount() {
			heyGovApi.post(`${this.j.slug}/billing-accounts`, this.billingAccountNew).then(({ data }) => {
				Vue.toasted.success(`Billing account ${data.account_number} created`)
				this.$modalBillingAccountCreate.hide()
				this.$router.push(
					`/${this.j.slug}/payments/${this.$route.params.departmentId}/billing-accounts/${data.uuid}`
				)
			}, handleResponseError("Couldn't create billing account ({error})"))
		},
	},
	watch: {
		filters: {
			handler() {
				this.states.billingAccounts = 'loading'

				if (this.pag.page === 1) {
					this.billingAccounts = []
					this.loadBillingAccountsDebounced()
				} else {
					this.pag.page = 1
				}
			},
			deep: true,
		},
		'pag.page'() {
			this.billingAccounts = []
			this.loadBillingAccounts()
		},
		'pag.limit'() {
			this.billingAccounts = []
			this.loadBillingAccounts()
			localStorage.setItem('billing-accounts-limit', this.pag.limit)
		},
		sorting: {
			deep: true,
			handler() {
				if (this.pag.page !== 1) {
					this.pag.page = 1
				} else {
					this.billingAccounts = []
					this.loadBillingAccounts()
				}
				localStorage.setItem('billing-accounts-orderBy', this.sorting.orderBy)
				localStorage.setItem('billing-accounts-order', this.sorting.order)
			},
		},
	},
}
</script>
