<template>
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title">Meeting Settings</h5>
			<button @click="$emit('close')" type="button" class="btn-close" aria-label="Close"></button>
		</div>
		<form @submit.prevent="handleSubmit">
			<div v-if="loading" class="modal-body text-center">
				<div class="spinner-border" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>
			</div>
			<div v-else class="modal-body">
				<div v-if="!departmentId" class="alert alert-warning" role="alert">
					Please set a department for this meeting before changing settings.
				</div>
				<div class="mb-3">
					<label for="municipality_logo" class="form-label">Municipality Logo</label>
					<select v-model="settings.municipality_logo" id="municipality_logo" class="form-select">
						<option :value="null">No Logo</option>
						<option value="department">Department Logo</option>
						<option value="jurisdiction">Municipality Logo</option>
					</select>
				</div>
				<div class="mb-3">
					<label for="header_alignment" class="form-label">Header Alignment</label>
					<select v-model="settings.header_alignment" id="header_alignment" class="form-select">
						<option value="left">Left</option>
						<option value="center">Center</option>
					</select>
				</div>
				<div class="mb-3">
					<label for="titles_font" class="form-label">Titles Font</label>
					<input type="text" v-model="settings.titles_font" id="titles_font" class="form-control" />
				</div>
				<div class="mb-3">
					<label for="content_font" class="form-label">Content Font</label>
					<input type="text" v-model="settings.content_font" id="content_font" class="form-control" />
				</div>
				<div class="mb-3">
					<label for="main_items_numbering" class="form-label">Main Items Numbering Format</label>
					<select v-model="settings.main_items_numbering" id="main_items_numbering" class="form-select">
						<option value="alphabetic">A, B, C</option>
						<option value="numeric">1, 2, 3</option>
						<option value="roman">I, II, III</option>
					</select>
				</div>
				<div class="mb-3">
					<label for="sub_items_numbering" class="form-label">Sub-items Numbering Format</label>
					<select v-model="settings.sub_items_numbering" id="sub_items_numbering" class="form-select">
						<option value="alphabetic">a, b, c</option>
						<option value="numeric">1, 2, 3</option>
						<option value="roman">i, ii, iii</option>
					</select>
				</div>
				<div class="mb-3 form-check">
					<input
						type="checkbox"
						v-model="settings.show_item_description"
						id="show_item_description"
						class="form-check-input"
					/>
					<label for="show_item_description" class="form-check-label">Show Item Description</label>
				</div>
				<div class="mb-3 form-check">
					<input
						type="checkbox"
						v-model="settings.show_speaker_name"
						id="show_speaker_name"
						class="form-check-input"
					/>
					<label for="show_speaker_name" class="form-check-label">Show Speaker Name</label>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-secondary" @click="$emit('close')">Close</button>
				<button type="submit" class="btn btn-primary" :disabled="loading || !departmentId">Save changes</button>
			</div>
		</form>
	</div>
</template>

<script>
import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'

export default {
	name: 'MeetingSettingsForm',
	props: {
		departmentId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			settings: {},
			loading: true,
		}
	},
	mounted() {
		this.fetchSettings()
	},
	methods: {
		async fetchSettings() {
			this.loading = true
			try {
				const response = await heyGovApi.get(
					`${this.$store.state.j.slug}/meetings/template-settings/${this.departmentId}`
				)
				this.settings = response.data
			} catch (error) {
				handleResponseError('Error fetching meeting settings ({error})')(error)
			} finally {
				this.loading = false
			}
		},
		async handleSubmit() {
			this.loading = true
			try {
				await heyGovApi.put(
					`${this.$store.state.j.slug}/meetings/template-settings/${this.departmentId}`,
					this.settings
				)
				this.$emit('submit', this.settings)
			} catch (error) {
				handleResponseError('Error updating meeting settings ({error})')(error)
			} finally {
				this.loading = false
			}
		},
	},
}
</script>
