<template>
	<div class="form-header">
		<div class="row">
			<div class="col">
				<h3 class="mb-3">
					<router-link :to="`/${j.slug}/forms`">HeyLicense</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" /> {{ form.name }}
					<small v-if="form.status !== 'public'" class="badge" :class="[formStatuses[form.status].class]">{{
						formStatuses[form.status].name
					}}</small>
				</h3>
			</div>
			<div class="col-auto d-none d-lg-block">
				<div class="dropdown">
					<button
						class="btn btn-sm py-1 btn-outline-dark dropdown-toggle"
						type="button"
						id="form-actions-dropdown"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						⠇
					</button>
					<ul class="dropdown-menu" aria-labelledby="form-actions-dropdown">
						<li>
							<router-link :to="`/${j.slug}/forms/${form.slug}/editForm`" class="dropdown-item"
								>Edit form</router-link
							>
						</li>
						<li v-if="form.status === 'public'">
							<button class="dropdown-item text-danger-400">Archive form</button>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<nav v-if="currentRole !== 'CITIZEN'" class="mb-4">
			<ul
				class="nav nav-pills nav-pills-form hide-scrollbar mb-2"
				style="flex-wrap: nowrap;min-width: 100%;overflow-x: scroll;"
			>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/forms/${form.slug}`"
						class="nav-link"
						:class="{ active: $route.name === 'Form' }"
						>Summary</router-link
					>
				</li>
				<li class="nav-item">
					<router-link :to="`/${j.slug}/form-requests?form_id=${form.id}`" class="nav-link"
						>Submissions
						<span class="badge bg-warning-lighter text-warning">{{
							form.requests_count
						}}</span></router-link
					>
				</li>
				<li v-if="['ADMIN', 'EDITOR'].includes(currentRole)" class="nav-item">
					<router-link
						:to="`/${j.slug}/forms/${form.slug}/formBuilder`"
						class="nav-link"
						:class="{ active: $route.name === 'FormBuilder' }"
						>🏗 Form builder</router-link
					>
				</li>
				<li v-if="['ADMIN', 'EDITOR'].includes(currentRole)" class="nav-item">
					<router-link
						:to="`/${j.slug}/forms/${form.slug}/editForm`"
						class="nav-link"
						:class="{ active: $route.name === 'FormEdit' }"
						>Settings</router-link
					>
				</li>

				<!-- <li class="nav-item">
					<router-link
						:to="`/${j.slug}/forms/${this.form.slug}/share`"
						class="nav-link"
						:class="{ active: $route.name === 'FormShare' }"
						>Share <font-awesome-icon :icon="['fas', 'share']" class="text-neutral-300" />
					</router-link>
				</li> -->
				<li class="nav-item ms-md-auto">
					<router-link
						:to="`/${j.slug}/forms/${form.slug}/send`"
						class="nav-link"
						:class="{ active: $route.name === 'FormSend' }"
						>Send application <font-awesome-icon :icon="['fas', 'paper-plane']" class="ms-1 text-info"
					/></router-link>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { formStatuses } from '@/actions/forms.js'

export default {
	name: 'FormPageHead',
	props: {
		form: Object,
	},
	data() {
		return {
			formStatuses,
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['currentRole']),
	},
	mounted() {
		const $active = document.querySelector('.nav-pills-form .nav-link.active')
		if ($active) {
			$active.parentElement.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'end' })
		}
	},
}
</script>
