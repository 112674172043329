<template>
	<div class="notifications">
		<div class="card">
			<div class="card-header">
				<h4 class="my-0">Your notifications</h4>
			</div>
			<div class="card-body card-notifications py-3 px-0">
				<notification
					v-for="notification in notifications.slice(0, 25)"
					:key="`notifications-page-notification-${notification.id}`"
					:notification="notification"
				></notification>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.card-notifications .row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
</style>

<script>
import { mapState } from 'vuex'
//import Notification from '@/components/Notification.vue'

export default {
	name: 'Notifications',
	components: { Notification: () => import('@/components/Notification.vue') },
	computed: {
		...mapState(['notifications']),
	},
	created() {
		this.$store.commit('markNotificationsAsSeen')
	},
}
</script>
