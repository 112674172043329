<template>
	<div class="page-venue-admin-booking">
		<div v-if="venue" class="row justify-content-center">
			<div class="col-lg-8 col-md-10">
				<h3 class="mb-3">
					<router-link :to="`/${j.slug}/venues`">Venues</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/venues/${venue.slug}?date=${$route.params.date}`">{{
						venue.name
					}}</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
					New booking
				</h3>

				<div class="card mb-4">
					<div class="row g-0">
						<div class="col-2">
							<img :src="venue.main_image" class="img-fluid rounded-start m-1" :alt="venue.name" />
						</div>
						<div class="col-7">
							<div class="card-body px-3 py-2">
								<h4 class="card-title">{{ venue.name }}</h4>
								<p>{{ firstParagraphOrWords(venue.description, 20) }}</p>
							</div>
						</div>
					</div>
				</div>

				<div class="card mb-3">
					<div class="card-body">
						<h3 class="mb-3 text-muted">Booking date &amp; time</h3>

						<div class="form-group mb-3">
							<label for="booking-date" class="form-label">Date</label>

							<input type="date" class="form-control" id="booking-date" v-model="bookingInfo.date" />
						</div>

						<div class="row">
							<div class="col-lg-6">
								<div class="form-group mb-3">
									<label for="booking-starts-at" class="form-label">From</label>

									<input
										type="time"
										class="form-control"
										id="booking-starts-at"
										v-model="bookingInfo.starts_at"
									/>
								</div>
							</div>
							<div class="col-lg-6">
								<div class="form-group mb-3">
									<label for="booking-ends-at" class="form-label">To</label>

									<input
										type="time"
										class="form-control"
										id="booking-ends-at"
										v-model="bookingInfo.ends_at"
									/>
								</div>
							</div>
						</div>

						<p>
							Hours selected:
							<small v-if="bookingHours === false" class="text-neutral-400">(not selected yet)</small
							><strong v-else>{{ bookingHours }}</strong>
						</p>

						<p class="card-text">
							<router-link
								:to="
									`/${j.slug}/venues/${venue.slug}/booking-admin/${bookingInfo.date}/${bookingInfo.starts_at}-${bookingInfo.ends_at}`
								"
								class="btn btn-primary"
								:class="{ disabled: !bookingHours || bookingHours < 1 }"
								>Continue</router-link
							>
						</p>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="text-center py-5" role="status">
			<span class="spinner-border"></span>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

import heyGovApi from '@/api.js'
import { firstParagraphOrWords } from '@/utils.js'
import { differenceInHours, parseISO } from 'date-fns'

export default {
	components: {},
	data() {
		return {
			states: {
				venue: 'loading',
			},
			venue: null,
			selectedSlot: null,

			booking: null,
			bookingInfo: {
				date: this.$route.params.date,
				starts_at: null,
				ends_at: null,
			},
		}
	},
	computed: {
		...mapState(['j']),
		bookingHours() {
			if (!this.bookingInfo.starts_at || !this.bookingInfo.ends_at) return false

			const startsAt = parseISO(`${this.bookingInfo.date}T${this.bookingInfo.starts_at}`)
			const endsAt = new Date(`${this.bookingInfo.date}T${this.bookingInfo.ends_at}`)

			return differenceInHours(endsAt, startsAt)
		},
	},
	created() {
		this.loadVenue()
	},
	methods: {
		firstParagraphOrWords,

		loadVenue() {
			this.states.venue = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}`).then(
				({ data }) => {
					this.venue = data
					this.states.venue = 'loaded'
				},
				error => {
					Vue.toasted.error(`Erorr loading venue data (${error.message})`)
					this.states.venue = 'error'
				}
			)
		},
	},
}
</script>
