<template>
	<div class="page-ui-kit">
		<ul class="nav nav-tabs mb-4">
			<li class="nav-item">
				<router-link to="/dev/debug" class="nav-link">API &amp; Debug</router-link>
			</li>
			<li class="nav-item">
				<router-link to="/dev/ui-kit" class="nav-link active">UI Kit</router-link>
			</li>
		</ul>

		<h3>UI Kit</h3>

		<p class="mb-2">
			HeyGov uses Bootstrap with a skin on top. See <code>/src/assets/design.scss</code> to see the HeyGov custom
			stuff.
		</p>
		<p class="mb-2">
			For detailed info on how to use Bootstrap, see here
			<a href="https://getbootstrap.com/docs/4.6">https://getbootstrap.com/docs/4.6</a>
		</p>
		<p>Icons are from <a href="https://fontawesome.com/icons">FontAwesome</a></p>

		<p class="mb-1">What we have:</p>
		<ul class="nicer-list">
			<li><a href="#colors">Colors</a></li>
			<li><a href="#fonts">Fonts &amp; Typography</a></li>
			<li><a href="#icons">Icons</a></li>
			<li><a href="#alerts">Alerts</a></li>
			<li><a href="#badges">Badges</a></li>
			<li><a href="#buttons">Buttons</a></li>
			<li><a href="#cards">Cards</a></li>
			<li><a href="#dropdowns">Dropdowns</a></li>
			<li><a href="#forms">Forms</a></li>
			<li><a href="#modals">Modals / Popups</a></li>
			<li><a href="#navs">Navs / Tabs</a></li>
			<li><a href="#bars">Filters &amp; Actions bars</a></li>
			<li><a href="#progress">Progress</a></li>
			<li><a href="#spinners">Spinners</a></li>
			<li><a href="#toasts">Toasts</a></li>
		</ul>

		<div class="card my-4" id="colors">
			<div class="card-header">
				<h5 class="mb-0">Colors</h5>
			</div>
			<div class="card-body">
				<h6 class="card-text">Brand colors</h6>

				<div class="row mb-4">
					<div class="col">
						<div class="bg-primary p-2 rounded">
							<p class="mb-2"><code>#5E81F4</code></p>
							<p class="text-white mb-0">primary</p>
						</div>
					</div>
					<div class="col">
						<div class="bg-secondary p-2 rounded">
							<p class="mb-2"><code>#FCAB1C</code></p>
							<p class="text-white mb-0">secondary</p>
						</div>
					</div>
				</div>

				<h6 class="card-text">Module colors</h6>
				<div class="row mb-4">
					<div class="col">
						<div class="bg-hey-reserve p-2">
							<p class="mb-2"><code>#7879F1</code></p>
							<p class="text-white mb-0">hey-reserve</p>
						</div>
						<div class="bg-hey-reserve-light p-2">
							<p class="mb-2"><code>#B9C4FF</code></p>
							<p class="text-gray mb-0">hey-reserve-light</p>
						</div>
					</div>
					<div class="col">
						<div class="bg-hey-licence p-2">
							<p class="mb-2"><code>#22A6FF</code></p>
							<p class="text-white mb-0">hey-licence</p>
						</div>
						<div class="bg-hey-licence-light p-2">
							<p class="mb-2"><code>#EFF9FF</code></p>
							<p class="text-gray mb-0">hey-licence-light</p>
						</div>
					</div>
					<div class="col">
						<div class="bg-hey-311 p-2">
							<p class="mb-2"><code>#FF5D46</code></p>
							<p class="text-white mb-0">hey-311</p>
						</div>
						<div class="bg-hey-311-light p-2">
							<p class="mb-2"><code>#FFF3F1</code></p>
							<p class="text-gray mb-0">hey-311-light</p>
						</div>
					</div>
					<div class="col">
						<div class="bg-heygov-pay p-2">
							<p class="mb-2"><code>#02CEA7</code></p>
							<p class="text-white mb-0">heygov-pay</p>
						</div>
						<div class="bg-heygov-pay-light p-2">
							<p class="mb-2"><code>#F0FFFC</code></p>
							<p class="text-gray mb-0">heygov-pay-light</p>
						</div>
					</div>
					<div class="col">
						<div class="bg-hey-polling p-2">
							<p class="mb-2"><code>#FF7A00</code></p>
							<p class="text-white mb-0">hey-polling</p>
						</div>
						<div class="bg-hey-polling-light p-2">
							<p class="mb-2"><code>#FFF6EE</code></p>
							<p class="text-gray mb-0">hey-polling-light</p>
						</div>
					</div>
				</div>

				<h6 class="card-text">UI colors</h6>

				<div class="row mb-4">
					<div class="col">
						<div class="bg-dark p-2">
							<p class="mb-2"><code>#343a40</code></p>
							<p class="text-white mb-0">dark</p>
						</div>
						<div class="bg-gray p-2">
							<p class="mb-2"><code>#898c90</code></p>
							<p class="text-white mb-0">gray</p>
						</div>
						<div class="bg-light p-2">
							<p class="mb-2"><code>#f8f9fa</code></p>
							<p class="text-gray mb-0">light</p>
						</div>
					</div>
					<div class="col">
						<div class="bg-success p-2">
							<p class="mb-2"><code>#2A9836</code></p>
							<p class="text-white mb-0">success</p>
						</div>
						<div class="bg-success-light p-2">
							<p class="mb-2"><code>#8dd384</code></p>
							<p class="text-white mb-0">success-light</p>
						</div>
						<div class="bg-success-lighter p-2">
							<p class="mb-2"><code>#e4f7e6</code></p>
							<p class="text-gray mb-0">success-lighter</p>
						</div>
					</div>
					<div class="col">
						<div class="bg-info p-2">
							<p class="mb-2"><code>#17a2b8</code></p>
							<p class="text-white mb-0">info</p>
						</div>
						<div class="bg-info-light p-2">
							<p class="mb-2"><code>#b9c4ff</code></p>
							<p class="text-white mb-0">info-light</p>
						</div>
						<div class="bg-info-lighter p-2">
							<p class="mb-2"><code>#ecf2ff</code></p>
							<p class="text-gray mb-0">info-lighter</p>
						</div>
					</div>
					<div class="col">
						<div class="bg-warning p-2">
							<p class="mb-2"><code>#fcab1c</code></p>
							<p class="text-white mb-0">warning</p>
						</div>
						<div class="bg-warning-light p-2">
							<p class="mb-2"><code>#fff7e1</code></p>
							<p class="text-white mb-0">warning-light</p>
						</div>
						<div class="bg-warning-lighter p-2">
							<p class="mb-2"><code>#fff7e1</code></p>
							<p class="text-gray mb-0">warning-lighter</p>
						</div>
					</div>
					<div class="col">
						<div class="bg-danger p-2">
							<p class="mb-2"><code>#E83333</code></p>
							<p class="text-white mb-0">danger</p>
						</div>
						<div class="bg-danger-light p-2">
							<p class="mb-2"><code>#f9c7c7</code></p>
							<p class="text-white mb-0">danger-light</p>
						</div>
						<div class="bg-danger-lighter p-2">
							<p class="mb-2"><code>#FFE6E6</code></p>
							<p class="text-gray mb-0">danger-lighter</p>
						</div>
					</div>
				</div>

				<h6 class="card-text">How to use the colors:</h6>

				<p class="card-text mb-2">
					In CSS use CSS variable with color name, ex: <code>background-color: var(--bs-red);</code>
				</p>
				<p class="card-text mb-2">
					For elements background use Bootstrap background utility, ex: <code>class="bg-success"</code>
				</p>
				<p class="card-text mb-2">
					For text color use Background text utility, ex: <code>class="text-dark"</code>
				</p>
				<p class="card-text">
					Learn more about usage in Bootstrap docs
					<a href="https://getbootstrap.com/docs/5.0/customize/color/"
						>https://getbootstrap.com/docs/5.0/customize/color/</a
					>
				</p>
			</div>
		</div>

		<div class="card my-4" id="fonts">
			<div class="card-header">
				<h5 class="mb-0">Fonts &amp; Typography</h5>
			</div>
			<div class="card-body">
				<h1>h1. HeyGov uses 2 fonts</h1>
				<h2>h2. First, "Agrandir" for headings</h2>
				<h3 class="font-weight-normal">h3. that go from h1-h6,</h3>
				<h4>h4. depending what's</h4>
				<h5>h5. h6. needed in a section</h5>
				<h6 class="font-weight-normal mb-4">can also be thin <code>class="font-weight-normal"</code></h6>

				<p class="mb-2">Second, "Roboto" for body text</p>
				<p class="mb-2">Used in content, buttons, forms, etc</p>
			</div>
		</div>

		<div class="card my-4" id="icons">
			<div class="card-header">
				<h5 class="mb-0">Icons</h5>
			</div>
			<div class="card-body">
				<p class="mb-2">
					Any icon from FontAwesome can be used in the app. Colors can be changed with
					<code>.text-[color_name]</code> classes.
				</p>
				<p>
					<font-awesome-icon :icon="['fas', 'city']" class="me-2" />
					<font-awesome-icon :icon="['far', 'bell']" class="me-2 text-primary" />
					<font-awesome-icon :icon="['fas', 'vote-yea']" class="text-danger me-2" />
				</p>
				<p>
					<button class="btn btn-dark me-2">
						<font-awesome-icon :icon="['fas', 'sign-out-alt']" /> Log out
					</button>
					<button class="btn btn-outline-primary me-2">
						<font-awesome-icon :icon="['fas', 'chart-bar']" /> Reports
					</button>
				</p>

				<p class="card-text">Before using an icon, make sure it's included in <code>/src/main.js</code></p>
			</div>
		</div>

		<div class="card my-4" id="alerts">
			<div class="card-header">
				<h5 class="mb-0">Alerts</h5>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-lg-6">
						<div class="alert alert-success" role="alert">A simple success alert—check it out!</div>
					</div>
					<div class="col-lg-6">
						<div class="alert alert-danger" role="alert">A simple danger alert—check it out!</div>
					</div>
					<div class="col-lg-6">
						<div class="alert alert-warning" role="alert">A simple warning alert—check it out!</div>
					</div>
					<div class="col-lg-6">
						<div class="alert alert-info" role="alert">A simple info alert—check it out!</div>
					</div>
					<div class="col-lg-6">
						<div class="alert alert-dark" role="alert">A simple dark alert—check it out!</div>
					</div>
				</div>

				<p class="card-text">
					More about alerts there
					<a href="https://getbootstrap.com/docs/4.6/components/alerts/"
						>https://getbootstrap.com/docs/4.6/components/alerts/</a
					>
				</p>
			</div>
		</div>

		<div class="card my-4" id="badges">
			<div class="card-header">
				<h5 class="mb-0">Badges</h5>
			</div>
			<div class="card-body">
				<p class="mb-1">Badges</p>
				<p>
					<span class="badge bg-success-lighter text-success me-2">Success</span>
					<span class="badge bg-danger-lighter text-danger me-2">Danger</span>
					<span class="badge bg-warning-lighter text-warning me-2">Warning</span>
					<span class="badge bg-info-lighter text-info me-2">Info</span>
					<span class="badge bg-light text-gray me-2">Light</span>
					<span class="badge bg-gray text-dark me-2">Dark</span>
				</p>
				<p>
					<span class="badge bg-success-lighter text-success me-2">Approved</span>
					<span class="badge bg-danger-lighter text-danger me-2">Declined</span>
					<span class="badge bg-warning-lighter text-warning me-2">Received</span>
					<span class="badge bg-info-lighter text-info me-2">In Progress</span>
				</p>

				<p class="card-text">
					More about badges there
					<a href="https://getbootstrap.com/docs/5.0/components/badge/"
						>https://getbootstrap.com/docs/5.0/components/badge/</a
					>
				</p>
			</div>
		</div>

		<div class="card my-4" id="buttons">
			<div class="card-header">
				<h5 class="mb-0">Buttons</h5>
			</div>
			<div class="card-body">
				<p class="mb-1">Normal buttons</p>
				<p>
					<button type="button" class="btn btn-primary m-1">Primary</button>
					<button type="button" class="btn btn-secondary m-1">Secondary</button>
					<button type="button" class="btn btn-success m-1">Success</button>
					<button type="button" class="btn btn-danger m-1">Danger</button>
					<button type="button" class="btn btn-warning m-1">Warning</button>
					<button type="button" class="btn btn-info m-1">Info</button>
					<button type="button" class="btn btn-light m-1">Light</button>
					<button type="button" class="btn btn-dark m-1">Dark</button>
				</p>

				<p class="mb-1">Outline buttons</p>
				<p>
					<button type="button" class="btn btn-outline-primary m-1">Primary</button>
					<button type="button" class="btn btn-outline-secondary m-1">Secondary</button>
					<button type="button" class="btn btn-outline-success m-1">Success</button>
					<button type="button" class="btn btn-outline-danger m-1">Danger</button>
					<button type="button" class="btn btn-outline-warning m-1">Warning</button>
					<button type="button" class="btn btn-outline-info m-1">Info</button>
					<button type="button" class="btn btn-outline-light m-1">Light</button>
					<button type="button" class="btn btn-outline-dark m-1">Dark</button>
				</p>

				<p class="mb-1">Link buttons</p>
				<p>
					<button type="button" class="btn btn-link m-1">Link</button>
					<button type="button" class="btn btn-link text-info m-1">Restore department</button>
					<button type="button" class="btn btn-link text-warning m-1">Don't click this</button>
					<button type="button" class="btn btn-link text-danger m-1">Remove this thing</button>
				</p>

				<p class="mb-1">Sizes</p>
				<p>
					<button class="btn btn-sm btn-primary m-1">Small</button>
					<button class="btn btn-secondary m-1">Normal</button>
					<button class="btn btn-lg btn-success m-1">Large</button>
					<button class="btn btn-sm btn-outline-danger m-1">.btn-sm</button>
					<button class="btn btn btn-outline-warning m-1">.btn</button>
					<button class="btn btn-lg btn-outline-info m-1">.btn-lg</button>
				</p>

				<p class="mb-1">Buttons with icons</p>
				<p>
					<button class="btn btn-sm btn-icon-success m-1">
						<font-awesome-icon :icon="['fas', 'tag']" />
					</button>
					<button class="btn btn-sm btn-icon-warning m-1">
						<font-awesome-icon :icon="['fas', 'undo']" />
					</button>
					<button class="btn btn-sm btn-icon-danger m-1">
						<font-awesome-icon :icon="['fas', 'times']" />
					</button>
					<button class="btn btn-sm btn-icon-primary m-1">
						<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
					</button>

					<button class="btn btn-icon-success m-1">
						<font-awesome-icon :icon="['fas', 'vote-yea']" />
					</button>
					<button class="btn btn-icon-info m-1">
						<font-awesome-icon :icon="['fas', 'user-tie']" />
					</button>
					<button class="btn btn-icon-danger m-1">
						<font-awesome-icon :icon="['fas', 'shield-alt']" />
					</button>

					<button type="button" class="btn btn-big-icon bg-info-lighter text-info m-1">
						Add member <font-awesome-icon :icon="['fas', 'plus-circle']" />
					</button>
					<button type="button" class="btn btn-big-icon btn-primary m-1">
						New element <font-awesome-icon :icon="['fas', 'plus-circle']" />
					</button>
					<button type="button" class="btn btn-sm btn-big-icon btn-success m-1">
						Add member <font-awesome-icon :icon="['fas', 'plus-circle']" />
					</button>
				</p>

				<p class="mb-1">Loading buttons</p>
				<p>
					<button type="button" disabled class="btn btn-primary m-1">
						<span class="spinner-border spinner-border-sm me-2"></span> Continue
					</button>
					<button type="button" disabled class="btn btn-success m-1">
						<span class="spinner-border spinner-border-sm me-2"></span> Pay <strong>$25</strong>
					</button>
					<button type="button" disabled class="btn btn-info m-1">
						<span class="spinner-grow spinner-grow-sm me-2"></span> Save account
					</button>
				</p>

				<p class="card-text">
					More about alerts there
					<a href="https://getbootstrap.com/docs/5.0/components/buttons/"
						>https://getbootstrap.com/docs/5.0/components/buttons/</a
					>
				</p>
			</div>
		</div>

		<div class="row my-2" id="cards">
			<div class="col-lg-6 my-2">
				<div class="card">
					<img src="https://files.heygov.com/assets/illustration-pablo-success.jpg" class="card-img-top" />
					<div class="card-body">
						<h5 class="card-title">Card with image</h5>
						<p class="card-text">Can have text too</p>
					</div>
				</div>
			</div>
			<div class="col-lg-6 my-2">
				<div class="card">
					<div class="card-header">
						<h5 class="mb-0">Standard card</h5>
					</div>
					<div class="card-body">
						<h5 class="card-title">With subtitle</h5>
						<p class="card-text">And text inside</p>
						<p class="card-text">And text inside</p>
						<p class="card-text text-muted">(Used for Account settings, Polls)</p>
					</div>
					<div class="card-footer">Footer details, can be a date, status, button, etc</div>
				</div>
			</div>
			<div class="col-lg-6 my-2">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Card with inline title</h5>
						<p class="card-text">And more text here</p>
						<p class="card-text text-muted">(Used for News &amp; Issues on homepage)</p>
					</div>
				</div>
			</div>
			<div class="col-lg-6 my-2">
				<div class="card bg-primary text-white">
					<div class="card-header">
						<h5 class="mb-0">Card with primary color</h5>
					</div>
					<div class="card-body text-white">
						<p class="card-text">Card that attracts attention</p>
						<p class="card-text text-muted">(Used for active straw poll)</p>
					</div>
				</div>
			</div>
		</div>

		<div class="card my-4" id="dropdowns">
			<div class="card-header">
				<h5 class="mb-0">Dropdowns</h5>
			</div>
			<div class="card-body">
				<div class="dropdown mb-3">
					<button
						class="btn btn-primary dropdown-toggle"
						type="button"
						id="dropdownMenuButton"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						Dropdown button
					</button>

					<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<a class="dropdown-item" href="#">Action</a>
						<a class="dropdown-item" href="#">Another action</a>
						<a class="dropdown-item" href="#">Something else here</a>
					</div>
				</div>

				<div class="btn-group mb-3">
					<button type="button" class="btn btn-outline-secondary">Split dropdown</button>
					<button
						type="button"
						class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span class="sr-only">Toggle Dropdown</span>
					</button>
					<div class="dropdown-menu">
						<a class="dropdown-item" href="#">Action</a>
						<a class="dropdown-item" href="#">Another action</a>
						<a class="dropdown-item" href="#">Something else here</a>
						<div class="dropdown-divider"></div>
						<a class="dropdown-item" href="#">Separated link</a>
					</div>
				</div>

				<p class="card-text">
					For more info check
					<a href="https://getbootstrap.com/docs/4.6/components/dropdowns/"
						>https://getbootstrap.com/docs/4.6/components/dropdowns/</a
					>
				</p>
			</div>
		</div>

		<div class="card my-4" id="forms">
			<div class="card-header">
				<h5 class="mb-0">Forms</h5>
			</div>
			<div class="card-body">
				<form class="mb-3">
					<div class="form-group">
						<label for="form-name">Name</label>
						<input type="text" class="form-control" id="form-name" placeholder=".form-control" />
					</div>
					<div class="form-group">
						<label for="form-email">Email address (with large input)</label>
						<input
							type="email"
							class="form-control form-control-lg"
							id="form-email"
							placeholder=".form-control .form-control-lg"
							aria-describedby="emailHelp"
						/>
						<small id="emailHelp" class="form-text text-muted"
							>We'll never share your email with anyone else.</small
						>
					</div>
					<div class="form-group">
						<label for="form-message">Message</label>
						<textarea
							class="form-control"
							id="form-message"
							placeholder="Your issue or question"
						></textarea>
					</div>
					<div class="form-group form-check">
						<input type="checkbox" class="form-check-input" id="exampleCheck1" />
						<label class="form-check-label" for="exampleCheck1">Report as anonoymous</label>
					</div>
					<button type="submit" class="btn btn-primary">Send</button>
				</form>

				<p class="card-text">
					For more form elements check
					<a href="https://getbootstrap.com/docs/4.6/components/forms/"
						>https://getbootstrap.com/docs/4.6/components/forms/</a
					>
				</p>
			</div>
		</div>

		<div class="card my-4" id="modals">
			<div class="card-header">
				<h5 class="mb-0">Modal / Popup</h5>
			</div>
			<div class="card-body">
				[example here]

				<p class="card-text">
					For more info check
					<a href="https://getbootstrap.com/docs/4.6/components/modal/"
						>https://getbootstrap.com/docs/4.6/components/modal/</a
					>
				</p>
			</div>
		</div>

		<div class="card my-4" id="navs">
			<div class="card-header">
				<h5 class="mb-0">Navs / Tabs</h5>
			</div>
			<div class="card-body">
				<p class="mb-1 text-muted">Standard navigation</p>
				<ul class="nav mb-4">
					<li class="nav-item">
						<a class="nav-link active" href="#">Active</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#">Link</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#">Link</a>
					</li>
					<li class="nav-item">
						<a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
					</li>
				</ul>

				<p class="mb-1 text-muted">Tabs</p>
				<ul class="nav nav-tabs mb-4">
					<li class="nav-item">
						<a class="nav-link active" href="#">Active</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#">Link</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#">Link</a>
					</li>
					<li class="nav-item">
						<a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
					</li>
				</ul>

				<p class="mb-1 text-muted">Pills</p>
				<ul class="nav nav-pills mb-4">
					<li class="nav-item">
						<a class="nav-link active" href="#">Active</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#">Link</a>
					</li>
					<li class="nav-item">
						<a class="nav-link" href="#">Link</a>
					</li>
					<li class="nav-item">
						<a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
					</li>
				</ul>

				<p class="card-text">
					For more info check
					<a href="https://getbootstrap.com/docs/4.6/components/navs/"
						>https://getbootstrap.com/docs/4.6/components/navs/</a
					>
				</p>
			</div>
		</div>

		<div id="bars">
			<div class="bar-filters border rounded bg-white p-1">
				<div class="row align-items-center justify-content-around g-2">
					<div class="col-sm-6 col-lg-3">
						<input type="search" class="form-control" placeholder="🔍 Search anything.." />
					</div>

					<div class="col-sm-6 col-lg-3">
						<select class="form-select">
							<option value="">Status</option>
							<option>Other statuses here</option>
						</select>
					</div>

					<div class="col-sm-6 col-lg-3">
						<people-search placeholder="🔍 People search"></people-search>
					</div>

					<div class="col-sm-6 col-lg-3">
						<select class="form-select">
							<option value="">Payment</option>
							<option>Other payment statuses</option>
						</select>
					</div>
				</div>
			</div>
		</div>

		<div class="card my-4" id="progress">
			<div class="card-header">
				<h5 class="mb-0">Progress</h5>
			</div>
			<div class="card-body">
				<div class="progress mb-3">
					<div
						class="progress-bar bg-success"
						role="progressbar"
						style="width: 25%"
						aria-valuenow="25"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
				<div class="progress mb-3">
					<div
						class="progress-bar bg-info"
						role="progressbar"
						style="width: 50%"
						aria-valuenow="50"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
				<div class="progress mb-3">
					<div
						class="progress-bar bg-warning"
						role="progressbar"
						style="width: 75%"
						aria-valuenow="75"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
				<div class="progress mb-3">
					<div
						class="progress-bar bg-danger"
						role="progressbar"
						style="width: 100%"
						aria-valuenow="100"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>

				<p class="card-text">
					For more info check
					<a href="https://getbootstrap.com/docs/4.6/components/progress/"
						>https://getbootstrap.com/docs/4.6/components/progress/</a
					>
				</p>
			</div>
		</div>

		<div class="card my-4" id="spinners">
			<div class="card-header">
				<h5 class="mb-0">Spinners</h5>
			</div>
			<div class="card-body">
				<p class="mb-1">Border spinner</p>
				<div class="mb-3">
					<div class="spinner-border text-primary" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-border text-secondary" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-border text-success" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-border text-danger" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-border text-warning" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-border text-info" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-border text-light" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-border text-dark" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				</div>

				<p class="mb-1">Growing spinner</p>
				<div class="mb-3">
					<div class="spinner-grow text-primary" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-grow text-secondary" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-grow text-success" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-grow text-danger" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-grow text-warning" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-grow text-info" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-grow text-light" role="status">
						<span class="sr-only">Loading...</span>
					</div>
					<div class="spinner-grow text-dark" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				</div>

				<p class="card-text">
					For more info check
					<a href="https://getbootstrap.com/docs/4.6/components/spinners/"
						>https://getbootstrap.com/docs/4.6/components/spinners/</a
					>
				</p>
			</div>
		</div>

		<div class="card my-4" id="toasts">
			<div class="card-header">
				<h5 class="mb-0">Toasts</h5>
			</div>
			<div class="card-body">
				<p>
					<button class="btn btn-outline-dark me-2" @click="toast(`I'm here`)">Show toast</button>
					<button
						class="btn btn-outline-success me-2"
						@click="toast('That action was successful', { type: 'success' })"
					>
						Show success message
					</button>
					<button
						class="btn btn-outline-danger me-2"
						@click="toast(`That didn't went as planned 😕`, { type: 'error' })"
					>
						Show error message
					</button>
				</p>

				<p class="card-text">
					For more info about Toasts check here
					<a href="https://github.com/shakee93/vue-toasted">https://github.com/shakee93/vue-toasted</a>
				</p>
			</div>
		</div>
	</div>
</template>

<style scoped>
.card {
	scroll-margin-top: 5rem;
}
</style>

<script>
import Vue from 'vue'
import PeopleSearch from '@/components/PeopleSearch.vue'

export default {
	name: 'PageUiKit',
	components: { PeopleSearch },
	methods: {
		toast(msg, options) {
			Vue.toasted.show(msg, options)
		},
	},
}
</script>
