<template>
	<div class="people-search">
		<input
			:id="id"
			type="search"
			class="form-control"
			:class="childClass"
			:placeholder="placeholder || `Search people in ${j.name}..`"
			v-model="q"
			@focus="onFocus"
			@keydown="onKey"
			v-debounce.250ms="loadPeople"
			:required="required"
			tabindex="0"
		/>
		<div
			v-if="people.length > 0"
			class="people-search-results rounded-3 shadow position-absolute bg-white
								"
		>
			<table class="table table-borderless mb-0">
				<tbody>
					<tr
						v-for="(p, index) in people"
						:key="`people-from-search-${p.id}-${p.name}`"
						@click="selectPerson(p)"
						class="cursor-pointer"
						:class="{ highlight: index === highlight }"
						@mouseover="mouseOver(index)"
					>
						<td
							v-for="field in fieldsToShow"
							:key="`column-${field}`"
							class="p-3 table-column text-truncate"
						>
							<div v-if="field === 'name'">
								<person-avatar :person="p" :size="22" :preview="false" class="me-1"></person-avatar>
								<span v-html="p.nameHighlight || p.anonymous_name"></span>
							</div>

							<div v-else>
								<span v-if="p[field]" v-html="p[`${field}Highlight`] || p[field]"></span>
								<i v-else class="text-muted">-</i>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.people-search-results {
	z-index: 1000;
}

table {
	.table-column {
		width: 237px;
	}

	tr.highlight {
		td {
			background-color: #ecf2ff;
		}

		&:first-child {
			td:first-child {
				border-top-left-radius: 30px !important;
			}
			td:last-child {
				border-top-right-radius: 30px !important;
			}
		}

		&:last-child {
			td:first-child {
				border-bottom-left-radius: 30px !important;
			}
			td:last-child {
				border-bottom-right-radius: 30px !important;
			}
		}
	}
}
</style>

<script>
import { mapState } from 'vuex'
import heyGovApi from '@/api.js'
import PersonAvatar from '@/components/PersonAvatar.vue'

export default {
	name: 'PeopleSearch',
	components: { PersonAvatar },
	props: {
		placeholder: String,
		limit: {
			type: Number,
			default: 5,
		},
		fields: Array,
		childClass: String,
		required: Boolean,
		defaultSpeakerId: {
			type: Number,
			default: null,
		},
		id: {
			type: String,
			default: undefined,
		},
	},
	data() {
		return {
			q: '',
			lastQuery: '',
			people: [],
			highlight: 0,
			fieldsToShow: ['name', 'email', 'phone'],
		}
	},
	computed: {
		...mapState(['j']),
	},
	created: function() {
		if (this.fields) {
			this.fieldsToShow = this.fields
		}

		window.addEventListener('click', e => {
			// close dropdown when clicked outside
			if (!this.$el.contains(e.target)) {
				this.people = []
			}
		})
	},
	mounted() {
		if (this.defaultSpeakerId) {
			this.loadPersonById(this.defaultSpeakerId)
		}
	},
	methods: {
		async loadPersonById(id) {
			try {
				const person = await this.$store.dispatch('loadPerson', id)
				if (person) {
					this.selectPerson(person)
				}
			} catch (error) {
				alert(`Error loading person (${error})`)
			}
		},
		selectPerson(person) {
			this.$emit('person', person)
			this.q = (person.name || person.email).trim()
			this.lastQuery = this.q
			this.people = []
		},
		onFocus($event) {
			// on focus, display the list of results again
			if (this.q.length) {
				this.loadPeople($event)
			}
		},
		async loadPeople(event) {
			console.log('load people from', event.type)

			if (this.lastQuery !== this.q) {
				this.$emit('personQuery', this.q)
			}

			if (!this.q.length) {
				this.$emit('person', null)
				this.$emit('noPerson', true)
				this.people = []
				return
			}

			const params = { q: this.q, limit: this.limit }

			try {
				const { data } = await heyGovApi.get(`/${this.j.slug}/people`, { params })
				this.people = data.map(person => {
					const query = new RegExp(`(${this.q})`, 'gi')
					person.nameHighlight = (person.name || '').replace(query, '<u>$1</u>')
					person.emailHighlight = (person.email || '').replace(query, '<u>$1</u>')
					return person
				})
			} catch (error) {
				alert(`Error loading people (${error})`)
			}
		},
		onKey: function(event) {
			switch (event.keyCode) {
				case 13:
					this.people.forEach((p, index) => {
						if (index === this.highlight) {
							this.selectPerson(p)
						}
					})
					break
				case 38:
					if (this.highlight === 0) {
						this.highlight = this.people.length - 1
					} else {
						this.highlight--
					}
					break
				case 40:
					if (this.highlight === this.people.length - 1) {
						this.highlight = 0
					} else {
						this.highlight++
					}
					break
			}
		},
		mouseOver(index) {
			this.highlight = index
		},
	},
}
</script>
