<template>
	<table class="table table-hover mb-0">
		<thead>
			<tr>
				<th>#</th>
				<th v-if="forms.length > 1" class="d-none d-md-table-cell">Form</th>
				<th>Date</th>
				<th class="d-none d-md-table-cell">Applicant</th>
				<th>Status</th>
				<th>
					<span v-if="forms.length === 1">{{ formInfoColumn(forms[0]) }}</span>
					<span v-else>Info</span>
				</th>
				<!--<th class="d-none d-md-table-cell">Municipal amount</th>-->
				<th class="d-none d-md-table-cell">Payment status</th>
			</tr>
		</thead>
		<transition-group :name="states.initialLoad ? 'no-transition' : 'transition-highlight'" tag="tbody">
			<tr
				v-for="formRequest in formRequests.slice(0, 5)"
				:key="`all-form-request-${formRequest.pid}`"
				class="cursor-pointer"
				@click="
					$router.push(
						`/${j.slug}/forms/${formInfo(formRequest.form_id).slug}/request-review/${formRequest.uuid}`
					)
				"
			>
				<td>{{ formRequest.application_number || formRequest.id }}</td>
				<td v-if="forms.length > 1" class="d-none d-md-table-cell">
					<div class="text-truncate" style="width: 200px">
						{{ formInfo(formRequest.form_id).name }}
					</div>
				</td>
				<td>
					<time
						:datetime="new Date(formRequest.sent_at).toLocaleString()"
						:title="new Date(formRequest.sent_at).toLocaleString()"
						data-bs-toggle="tooltip"
						>{{ formRequest.sent_at | dateToNow(true) }}</time
					>
				</td>
				<td class="d-none d-md-table-cell">
					<person-link
						v-if="people[formRequest.person_id]"
						:id="formRequest.person_id"
						:avatar="18"
					></person-link>
				</td>
				<td>
					<form-request-status :formRequest="formRequest" :tooltip="true" />
				</td>
				<td>
					<span
						v-if="formInfo(formRequest.form_id).info_column1 && formInfo(formRequest.form_id).steps.length"
					>
						{{
							displayFieldAnswer(
								formInfo(formRequest.form_id)
									.steps.map(s => s.fields)
									.flat()
									.find(f => f.id == formInfo(formRequest.form_id).info_column1),
								formRequest.answers[formInfo(formRequest.form_id).info_column1],
								true
							)
						}}
					</span>
					<span v-else-if="formInfo(formRequest.form_id).info_column1">
						{{ formRequest.answers[formInfo(formRequest.form_id).info_column1] }}
					</span>
				</td>
				<!--
				<td class="d-none d-md-table-cell">
					<span
						v-if="
							formFieldType(formRequest.form_id, 'PaymentElement') &&
								formRequest.answers[formFieldType(formRequest.form_id, 'PaymentElement').id]
						"
					>
						{{ formFieldType(formRequest.form_id, 'PaymentElement').label }}:
						<strong
							>{{
								formRequest.answers[formFieldType(formRequest.form_id, 'PaymentElement').id].price
									| currency
							}}
						</strong>
					</span>
				</td>
				-->
				<td class="d-none d-md-table-cell">
					<span
						v-if="
							formFieldType(formRequest.form_id, 'PaymentElement') &&
								formRequest.answers[formFieldType(formRequest.form_id, 'PaymentElement').id]
						"
					>
						<span
							v-if="
								['succeeded', 'requires_capture'].includes(
									formRequest.answers[formFieldType(formRequest.form_id, 'PaymentElement').id].status
								)
							"
						>
							<payment-status
								:payment="formRequest.answers[formFieldType(formRequest.form_id, 'PaymentElement').id]"
								:tooltip="true"
							/>
							<p class="mb-0">
								<payment-field-summary
									:payment="
										formRequest.answers[formFieldType(formRequest.form_id, 'PaymentElement').id]
									"
								></payment-field-summary>
							</p>
						</span>
						<payment-status
							v-else
							:payment="formRequest.answers[formFieldType(formRequest.form_id, 'PaymentElement').id]"
							:tooltip="true"
						/>
					</span>
				</td>
			</tr>
		</transition-group>

		<tfoot>
			<tr v-if="state === 'loading'">
				<td colspan="7" class="text-center py-3">
					<div class="spinner-border spinner-border-sm" role="status"></div>
				</td>
			</tr>
			<tr v-else-if="state === 'idle' && !formRequests.length">
				<td colspan="7" class="text-center">
					<img src="https://edge.heygov.com/illustrations/form-applications.jpg" width="200" class="mb-2" />
					<p class="text-muted mb-1">Awaiting form submissions</p>
				</td>
			</tr>
		</tfoot>
	</table>
</template>

<style scoped>
.transition-highlight-enter-active,
.transition-highlight-leave-active {
	transition: all 1s;
}
.transition-highlight-enter {
	background-color: var(--bs-secondary);
}
.transition-highlight-enter-to {
	background-color: #ffffff;
}
.transition-highlight-leave-to {
	height: 0;
	opacity: 0;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'
import heyGovApi from '@/api.js'
import Vue from 'vue'
import { format } from 'date-fns'

import { displayFieldAnswer } from '@/actions/form-builder.js'

import PersonLink from '@/components/PersonLink.vue'
import FormRequestStatus from '@/components/forms/FormRequestStatus.vue'
import PaymentStatus from '@/components/payments/PaymentStatus.vue'
import PaymentFieldSummary from '../../components/forms/PaymentFieldSummary.vue'

export default {
	name: 'FormRequestsSummary',
	components: { PersonLink, FormRequestStatus, PaymentStatus, PaymentFieldSummary },
	props: {
		form: Object,
		forms: Array,
	},
	data() {
		return {
			state: 'loading',
			states: {
				initialLoad: true,
			},
			cachedFormFieldTypes: {},
			formRequests: [],
			formRequestsTimer: null,
		}
	},
	computed: {
		...mapState(['j', 'people']),
		...mapGetters(['currentRole']),
	},
	created() {
		this.loadFormRequests()

		this.formRequestsTimer = setInterval(() => {
			this.states.initialLoad = false
			this.loadFormRequests()
		}, 15000)
	},
	methods: {
		displayFieldAnswer,

		loadFormRequests() {
			const params = {
				limit: 5,
				status: 'sent,working,approved',
			}

			if (this.formRequests.length) {
				params.sent_after = format(new Date(this.formRequests[0].sent_at), 'yyyy-MM-dd HH:mm:ss')
			}

			// if requests summary is inside a form page,
			// get form requests only for this form
			if (this.forms.length === 1) {
				params.form_id = this.forms[0].id
			}

			heyGovApi.get(`/${this.j.slug}/form-requests`, { params }).then(
				({ data }) => {
					const peopleIds = data.map(fr => fr.person_id)
					this.formRequests.unshift(...data)
					this.$store.dispatch('loadPeople', peopleIds)
					this.state = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading all form requests (${error.message})`)
					this.state = 'error'
				}
			)
		},
		formInfo(formId) {
			return this.forms.find(f => f.id === formId)
		},
		formInfoColumn(form) {
			const fields = form.steps.map(s => s.fields).flat()
			const info = fields.find(f => f.id == form.info_column1)

			return info ? info.label : 'Info'
		},
		formFieldType(formId, fieldType) {
			const cacheKey = `${formId}-${fieldType}`

			if (!this.cachedFormFieldTypes[cacheKey]) {
				const form = this.formInfo(formId)
				const fields = form.steps.map(s => s.fields).flat()

				this.cachedFormFieldTypes[cacheKey] = fields.find(f => f.type === fieldType)
			}

			return this.cachedFormFieldTypes[cacheKey]
		},
	},
	beforeDestroy() {
		clearInterval(this.formRequestsTimer)
	},
}
</script>
