<template>

	<div class="home py-5">

		<h3 class="mb-3 text-center">Set a new password for your account 🙈</h3>

		<div class="row justify-content-center">
			<div class="col-8 col-md-6 col-lg-4">
				<div class="card">
					<div class="card-body">
						<form @submit.prevent="newPass">

							<p class="mb-4">or <router-link to="/account/login">Log in</router-link></p>

							<div v-if="error" class="alert alert-warning" role="alert">{{ error }}</div>

							<div class="form-group">
								<label for="password">Password</label>
								<input type="password" class="form-control" id="password" minlength="6" autofocus autocomplete="new-password" v-model="account.password" placeholder="Your new & secure password" required>
							</div>

							<button class="btn btn-primary" :disabled="state === 'loading'">Continue</button>

						</form>
					</div>
				</div>
			</div>
		</div>

	</div>

</template>

<script>
import Vue from 'vue'
import heyGovApi from '@/api.js'

export default {
	name: 'AccountNewPass',
	data() {
		return {
			state: 'idle',
			account: {
				password: '',
			},
			error: '',
			info: 'Fill your email address, and we\'ll send a message with instructions for resetting your password.',
		}
	},
	methods: {
		newPass() {
			this.state = 'loading'
			this.info = ''

			heyGovApi.post('account/new-pass', {
				token: this.$route.params.token,
				password: this.account.password,
			})
				.then(({ data }) => {
					this.$store.commit('setAuthJurisdictions', data.jurisdictions)
					this.$store.commit('setCurrentJurisdiction', data.jurisdictions[0])
					this.$store.commit('setAuth', data.token)

					this.$router.push(this.$route.query.redirect || '/')
				})
				.catch(error => {
					this.state = 'error'
					Vue.toasted.show(error, { type: 'error' })
				})

		}
	},
}
</script>
