<template>

	<div class="home py-5">

		<h3 class="mb-3 text-center">✌️ So you logged out</h3>

		<p class="text-center">Maybe <router-link to="/account/login">log in again</router-link>, or check the <router-link to="/">public municipality page</router-link></p>

	</div>

</template>

<script>
export default {
	name: 'AccountLogout',
}
</script>
