<template>
	<div v-if="!currentField.pdf">
		<vue-phone-number-input
			:class="currentField.class"
			:id="'fsf-' + currentField.id"
			v-model="pNumber"
			:default-country-code="defaultCountry"
			:preferred-countries="preferedCountries"
			class="mb-2"
			@update="setPhoneNumber"
			:border-radius="20"
		/>
	</div>
	<!-- PDF View -->
	<div v-else>
		<div v-if="currentField.display !== 'form'">
			<label class="pdf-label form-label" for="">{{ currentField.label }}</label>

			<div v-if="currentField.value === null">
				<div class="pdf-field d-flex align-items-end">
					<span
						>(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-</span
					>
				</div>
			</div>
			<div v-else>
				<div v-if="$route.query.redacted && currentField.redacted" class="pdf-redacted"></div>
				<div v-else class="pdf-field">
					{{ pNumberFormatted }}
				</div>
			</div>
		</div>
		<small v-if="currentField.help" class="text-muted pdf-help">{{ currentField.help }}</small>
	</div>
</template>

<style lang="scss">
@import '@/assets/phone_input';
</style>

<script>
import parsePhoneNumber from 'libphonenumber-js'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
	name: 'PhoneInput',
	props: ['currentField'],
	components: { VuePhoneNumberInput },
	data() {
		return {
			defaultCountry: 'US',
			preferedCountries: ['US', 'CA'],
			pNumber: '',
			pNumberFormatted: '',
		}
	},
	created() {
		this.preparePhoneNumber()
	},
	methods: {
		preparePhoneNumber() {
			if (this.currentField.value) {
				const parsed = parsePhoneNumber(this.currentField.value)

				if (parsed && parsed.isValid) {
					this.defaultCountry = parsed.country
					this.pNumber = parsed.nationalNumber
					this.pNumberFormatted =
						parsed.countryCallingCode === '1' ? parsed.formatNational() : parsed.formatInternational()
				} else {
					this.pNumber = this.currentField.value
				}
			}
		},
		setPhoneNumber(payload) {
			if (payload.countryCallingCode && payload.phoneNumber) {
				this.currentField.value = '+' + payload.countryCallingCode + ' ' + payload.phoneNumber
			} else {
				this.currentField.value = ''
			}
		},
	},
}
</script>
