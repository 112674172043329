<template>
	<router-link :to="`/${j.slug}/polls/${poll.slug}`" class="card card-link bg-primary text-white">
		<div class="card-header">
			<div class="row align-items-center">
				<div class="col">
					<p class="my-0">
						By
						<strong>{{
							poll.department_id && departments
								? departments.find(d => d.id == poll.department_id).name
								: j.name
						}}</strong>
					</p>
				</div>
				<div class="col-auto">
					<router-link
						v-if="currentRole === 'ADMIN'"
						:to="`/${j.slug}/polls/${poll.slug}/responses`"
						class="btn btn-sm btn-dark me-2"
						>Responses</router-link
					>

					<span v-if="poll.status === 'completed'" class="btn btn-sm btn-light">See results</span>
					<span
						v-else-if="pollAnswers[poll.slug] || ['closed', 'completed'].includes(poll.status)"
						class="btn btn-sm btn-light"
						>✅ Completed</span
					>
					<span v-else class="btn btn-sm text-white btn-secondary">Respond now</span>
				</div>
			</div>
		</div>
		<div class="card-body">
			<h3 class="card-title mb-0">{{ poll.title }}</h3>
		</div>
		<div class="card-footer bg-primary border-0">
			<div class="row align-items-center">
				<div class="col">
					<p class="my-0">{{ poll.responses }} {{ poll.responses == 1 ? 'response' : 'responses' }}</p>
				</div>
				<div class="col-auto">
					<small v-if="poll.status === 'active'"
						>🔴 only {{ formatDistanceToNow(new Date(poll.closes_at)) }} left</small
					>
					<small v-else-if="['closed', 'completed'].includes(poll.status)"
						>Closed {{ formatDistanceToNow(new Date(poll.closes_at), { addSuffix: true }) }}</small
					>
				</div>
			</div>
		</div>
	</router-link>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { formatDistanceToNow } from 'date-fns'

export default {
	name: 'PollCard',
	props: ['poll'],
	data() {
		return {}
	},
	methods: {
		formatDistanceToNow
	},
	computed: {
		...mapState(['j', 'departments', 'pollAnswers']),
		...mapGetters(['currentRole'])
	}
}
</script>
