<template>
	<div class="venue-overview">
		<div v-if="venue">
			<venue-header :venue="venue"></venue-header>

			<div class="card">
				<div class="card-body">
					<h4>Share link</h4>

					<p class="card-text">
						Your venue is now published and ready to be shared with the world! Copy this link to share your
						form on the website, social media, or via email.
					</p>

					<div class="input-group input-group-sm mb-4">
						<input type="text" class="form-control" :value="shareLink" readonly />
						<button class="btn btn-outline-dark" type="button" @click="copyToClipboard(shareLink)">
							Copy link
						</button>
					</div>

					<!-- <div class="input-group input-group-sm mb-4">
						<input type="text" class="form-control" :value="shareShortLink" readonly />
						<button class="btn btn-outline-dark" type="button" @click="copyToClipboard(shareShortLink)">
							Copy short link
						</button>
					</div> -->

					<!-- <div v-if="isStaff" class="mb-4">
						<h4>Share link as QR code</h4>

						<p class="card-text">
							Save and print this QR code to share your venue with the world. Anyone can scan it with
							their phone to open the venue booking page.
						</p>

						<div class="bg-warning-100 p-2 rounded-1" style="height: 250px; width: 250px">
							[...]
						</div>
					</div> -->

					<h4>Embed venue</h4>

					<p class="card-text">
						Copy and paste this code to embed the venue into your own website.
					</p>

					<div class="form-group mb-3">
						<textarea class="form-control rounded-1" rows="3" readonly v-model="embedCode"> </textarea>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import heyGovApi from '@/api.js'
import { appUrl, copyToClipboard } from '@/utils.js'

import VenueHeader from './VenueHeader.vue'

export default {
	name: 'VenueShare',
	metaInfo() {
		return {
			title: this.venue?.name || this.$route.params.venueSlug,
		}
	},
	components: { VenueHeader },
	data() {
		return {
			states: {
				venue: 'loading',
			},
			venue: null,
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['currentRole', 'isStaff']),
		shareLink() {
			return appUrl(`${this.j.slug}/venues/${this.$route.params.venueSlug}/info`)
		},
		shareShortLink() {
			return `heygov.co/${this.j.slug}/${this.$route.params.venueSlug}`
		},
		embedCode() {
			const src = appUrl(`${this.j.slug}/venues/${this.$route.params.venueSlug}/embed`)

			let html = `<iframe src="${src}" id="heygov-venue-${this.venue.slug}" width="100%" height="600" frameborder="0"></iframe>
<script src="https://edge.heygov.com/heygov.js" data-heygov-jurisdiction="${this.j.slug}" />`

			return html
		},
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.replace(`/${this.j.slug}/venues/${this.$route.params.venueSlug}/info`)
		} else {
			this.getVenue()
		}
	},
	methods: {
		copyToClipboard,

		getVenue() {
			this.states.venue = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}`).then(
				({ data }) => {
					this.venue = data
					this.states.venue = 'loaded'
				},
				error => {
					Vue.toasted.error(`Error loading venue info (${error})`)
					this.states.venue = 'error'
				}
			)
		},
	},
}
</script>
