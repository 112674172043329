const evaluate = require('safe-evaluate-expression')

const venueStatuses = {
	draft: {
		name: 'Draft',
		class: 'bg-gray text-dark',
		text: 'The venue is in draft mode. Publish venue to make it available to citizens',
	},
	public: {
		name: 'Public',
		class: 'bg-success-lighter text-success',
		text: 'The venue is public, and citizens can make reservation',
	},
	archived: {
		name: 'Archived',
		class: 'bg-danger-lighter text-danger',
		text:
			'The venue is archived. It is hidden from public view, no new reservations are allowed, but previous reservations are still available',
	},
}

const venueBookingStatuses = {
	draft: {
		name: 'Draft',
		class: 'bg-gray text-dark',
		text: 'Reservation started, but not paid & submitted',
	},
	pending: {
		name: 'Pending',
		class: 'bg-warning-lighter text-warning',
		text: 'Reserved and paid, awaiting confirmation',
		icon: 'fa-clock',
	},
	approved: {
		name: 'Confirmed',
		class: 'bg-success-50 text-success-400',
		text: 'Reservation reviewed and confirmed',
		icon: 'fa-thumbs-up',
	},
	progress: {
		name: 'In progress',
		class: 'bg-success-lighter text-success',
		text: 'Booking in progress, happening right now',
		icon: 'fa-hourglass',
	},
	ended: {
		name: 'Checked out',
		class: 'bg-warning-lighter text-warning',
		text: 'Booking time ended & checked out. Needs inspection',
		icon: 'fa-check',
	},
	completed: {
		name: 'Completed',
		class: 'bg-info-lighter text-info',
		text: 'Venue was inspected, deposit (maybe) returned',
		icon: 'fa-check-double',
	},
	declined: {
		name: 'Declined',
		class: 'bg-danger-lighter text-danger',
		text: 'Booking reviewed and declined',
		icon: 'fa-hand',
	},
	canceled: {
		name: 'Canceled',
		class: 'bg-danger-lighter text-danger',
		text: 'Canceled reservation',
		icon: 'fa-ban',
	},
}

const getConditionElementValue = conditionElement => {
	// get final value for condition element
	let conditionElementValue = conditionElement.value || ''

	if (conditionElementValue && conditionElement.type === 'CheckboxElement') {
		conditionElementValue = conditionElementValue.values
	} else if (conditionElementValue && conditionElement.type === 'RadioButtonElement') {
		conditionElementValue = conditionElementValue.value
	}

	// remove whitespace from condition value
	if (typeof conditionElementValue === 'string') {
		conditionElementValue = conditionElementValue.trim()
	}

	return conditionElementValue
}

// Payment conditions - handleVenuePayment function generating payment price based on conditions or if there is no conditions based on entered price
// TODO: This function needs to be updated for venue price conditions use, logic is here conditions are the same like in form builder stored in venue.payment_methods column need to calculation is the same also
const handleVenuePayment = (venue, fields) => {
	let paymentValue = 0
	let itemsNumber = 1
	// Check is there priceConditions, if not just use price from data.price
	if (venue && 'conditions' in venue.payment_methods && venue.payment_methods.conditions.length > 0) {
		// We have conditions for payment
		// In first pass trough conditions we checking for price if
		let priceIfCount = 0
		venue.payment_methods.conditions.forEach(condition => {
			// Find element from condition - condition.element contains only id of element, we need object
			let conditionElement = fields.find(item => item.id === condition.element)

			if (!conditionElement) {
				return
			}

			const conditionElementValue = getConditionElementValue(conditionElement)

			// If condition type is price-if and conditionElement type is TableElement
			if (condition?.type === 'price-if' && conditionElement.type === 'TableElement') {
				if (condition.condition === 'equal') {
					if (Array.isArray(conditionElement.value)) {
						let tableData = conditionElement.value
						itemsNumber = tableData.length
						tableData.forEach(item => {
							let multipleConditions =
								'"' + item[condition.column] + '" === "' + condition.conditionValue + '" '

							condition.logicConditions.forEach(cond => {
								multipleConditions += createConditionsString(cond, item)
							})

							if (evaluate(multipleConditions)) {
								paymentValue += parseInt(condition.price)
								item.price = parseInt(condition.price)
								priceIfCount += 1
							}
						})
					}
				}

				if (condition.condition === 'notEqual') {
					if (Array.isArray(conditionElement.value)) {
						let tableData = conditionElement.value

						tableData.forEach(item => {
							let multipleConditions =
								'"' + item[condition.column] + '" !== "' + condition.conditionValue + '" '
							condition.logicConditions.forEach(cond => {
								multipleConditions += createConditionsString(cond, item)
							})

							if (evaluate(multipleConditions)) {
								paymentValue += parseInt(condition.price)
								item.price = parseInt(condition.price)
								priceIfCount += 1
							}
						})
					}
				}

				if (condition.condition === 'empty') {
					if (Array.isArray(conditionElement.value)) {
						let tableData = conditionElement.value

						tableData.forEach(item => {
							let multipleConditions = item[condition.column].length + ' === 0 '
							condition.logicConditions.forEach(cond => {
								multipleConditions += createConditionsString(cond, item)
							})

							if (evaluate(multipleConditions)) {
								paymentValue += parseInt(condition.price)
								item.price = parseInt(condition.price)
								priceIfCount += 1
							}
						})
					}
				}

				if (condition.condition === 'notEmpty') {
					if (Array.isArray(conditionElement.value)) {
						let tableData = conditionElement.value

						tableData.forEach(item => {
							let multipleConditions = item[condition.column].length + ' > 0 '
							condition.logicConditions.forEach(cond => {
								multipleConditions += createConditionsString(cond, item)
							})

							if (evaluate(multipleConditions)) {
								paymentValue += parseInt(condition.price)
								item.price = parseInt(condition.price)
								priceIfCount += 1
							}
						})
					}
				}
			} else if (condition?.type === 'price-if' && conditionElement.type !== 'TableElement') {
				if (condition.condition === 'equal') {
					if (conditionElementValue == condition.conditionValue) {
						paymentValue = parseInt(condition.price)
						priceIfCount += 1
					}
				}

				if (condition.condition === 'notEqual') {
					if (conditionElementValue != condition.conditionValue) {
						paymentValue = parseInt(condition.price)
						priceIfCount += 1
					}
				}

				if (condition.condition === 'empty') {
					if (conditionElementValue.length === 0) {
						paymentValue = parseInt(condition.price)
						priceIfCount += 1
					}
				}

				if (condition.condition === 'notEmpty') {
					if (conditionElementValue.length > 0) {
						paymentValue = parseInt(condition.price)
						priceIfCount += 1
					}
				}
			}
		})

		// If there is no price-if conditions use price from price field
		if (priceIfCount === 0) {
			paymentValue = parseInt(venue?.payment_method?.price)
		}

		// In second pass trough conditions we checking for add price if (adding price on price if)
		venue.payment_methods.conditions.forEach(condition => {
			// Find element from condition - condition.element contains only id of element, we need object
			let conditionElement = fields.find(item => item.id === condition.element)

			if (!conditionElement) {
				return
			}

			const conditionElementValue = getConditionElementValue(conditionElement)

			if (condition.type === 'add-price-if' && conditionElement.type !== 'TableElement') {
				if (condition.condition === 'equal') {
					if (
						Array.isArray(conditionElementValue) &&
						conditionElementValue.includes(condition.conditionValue)
					) {
						paymentValue += parseInt(condition.price) * itemsNumber
					} else if (conditionElementValue == condition.conditionValue) {
						paymentValue += parseInt(condition.price) * itemsNumber
					}
				} else if (condition.condition === 'notEqual') {
					if (Array.isArray(conditionElementValue)) {
						if (!conditionElementValue.includes(condition.conditionValue)) {
							paymentValue += parseInt(condition.price) * itemsNumber
						}
					} else if (conditionElementValue != condition.conditionValue) {
						paymentValue += parseInt(condition.price) * itemsNumber
					}
				} else if (condition.condition === 'empty') {
					if (conditionElementValue.length === 0) {
						paymentValue += parseInt(condition.price) * itemsNumber
					}
				} else if (condition.condition === 'notEmpty') {
					if (conditionElementValue.length > 0) {
						paymentValue += parseInt(condition.price) * itemsNumber
					}
				} else if (condition.condition === 'equalToValue') {
					if (conditionElementValue.length > 0) {
						paymentValue += conditionElementValue * itemsNumber
					}
				}
			}
		})
	} else {
		// We don't have conditions for payment we use payment and quantity from data
		paymentValue = venue?.payment_method?.price
	}

	if (venue) {
		venue.payment_method.price = Number(paymentValue)
	}

	return itemsNumber
}

// Create condition - create condition in string format with concatenation
const createConditionsString = (condition, item) => {
	let conditionString = ''
	if (condition) {
		if (condition.condition === 'equal') {
			conditionString =
				' ' +
				condition.operation +
				' ' +
				'"' +
				item[condition.column] +
				'"' +
				' === ' +
				'"' +
				condition.conditionValue +
				'"'
		} else if (condition.condition === 'notEqual') {
			conditionString =
				' ' +
				condition.operation +
				' ' +
				'"' +
				item[condition.column] +
				'"' +
				' !== ' +
				'"' +
				condition.conditionValue +
				'"'
		} else if (condition.condition === 'empty') {
			conditionString = ' ' + condition.operation + ' ' + item[condition.column].length + ' === 0 '
		} else if (condition.condition === 'notEmpty') {
			conditionString = ' ' + condition.operation + ' ' + item[condition.column].length + ' > 0 '
		}
	}

	return conditionString
}

export { venueStatuses, venueBookingStatuses, handleVenuePayment }
