<template>
	<div class="forms-page">
		<div v-if="currentRole !== 'CITIZEN' && forms.length" class="d-none d-md-block">
			<div class="row align-items-center mb-2">
				<div class="col">
					<h4 class="my-0 d-inline-block">Latest submissions</h4>
					<router-link :to="`/${j.slug}/form-requests`" class="btn btn-link btn-sm ms-1">
						View all →
					</router-link>
				</div>
				<div class="col-auto">
					-
				</div>
			</div>

			<div class="card mb-5">
				<div class="card-body">
					<div class="card-table">
						<form-requests-table :forms="forms"></form-requests-table>
					</div>
				</div>
			</div>
		</div>

		<div v-if="forms.length && ownFormRequests.length" class="mb-5">
			<h2>Your permits &amp; licenses</h2>

			<div v-if="ownFormRequests.filter(fr => Object.keys(fr.required_changes).length).length" class="card mb-4">
				<div class="card-body">
					<div class="row align-items-center cursor-pointer" @click="toggleSection('attn')">
						<div class="col">
							<h3 class="my-0">Requires attention</h3>
						</div>
						<div class="col-auto">
							<span class="badge bg-danger-lighter text-danger py-1"
								>{{ ownFormRequests.filter(fr => Object.keys(fr.required_changes).length).length }}
								<font-awesome-icon
									:icon="['fas', states.attn ? 'chevron-up' : 'chevron-down']"
									class="ms-1"
							/></span>
						</div>
					</div>

					<div v-if="states.attn">
						<p class="my-2">Please submit any changes requested by your administrator here</p>

						<div
							class="row row-cols"
							:class="{
								'row-cols-2 row-cols-lg-3 row-cols-xl-4':
									ownFormRequests.filter(fr => Object.keys(fr.required_changes).length).length > 1,
							}"
						>
							<div
								v-for="formRequest in ownFormRequests.filter(
									fr => Object.keys(fr.required_changes).length
								)"
								:key="`own-attn-form-request-${formRequest.pid}`"
								class="col my-2"
							>
								<router-link
									:to="
										`/${j.slug}/forms/${
											forms.find(f => formRequest.form_id === f.id).slug
										}/request-info/${formRequest.uuid}`
									"
									class="card card-link h-100 bg-danger-lighter shadow-sm"
								>
									<div class="card-body p-3">
										<h6 class="mb-0">
											{{ forms.find(f => formRequest.form_id === f.id).name }}
										</h6>
									</div>
									<div class="card-footer border-0 bg-transparent px-3 pt-0">
										<small
											>Sent
											{{
												formatDistanceToNow(new Date(formRequest.sent_at), { addSuffix: true })
											}}</small
										>
									</div>
									<span
										class="badge bg-danger position-absolute"
										style="bottom: -0.7rem;right:-0.3rem"
										>{{ Object.keys(formRequest.required_changes).length }}</span
									>
								</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="ownFormRequests.filter(fr => fr.status === 'draft').length" class="card mb-4">
				<div class="card-body">
					<div class="row align-items-center cursor-pointer" @click="toggleSection('drafts')">
						<div class="col">
							<h3 class="my-0">Drafts</h3>
						</div>
						<div class="col-auto">
							<span class="badge bg-info-lighter text-info py-1"
								>{{ ownFormRequests.filter(fr => fr.status === 'draft').length }}
								<font-awesome-icon
									:icon="['fas', states.drafts ? 'chevron-up' : 'chevron-down']"
									class="ms-1"
							/></span>
						</div>
					</div>

					<div v-if="states.drafts">
						<p class="my-2">Tap on a form to continue your application</p>

						<div class="row row-cols row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
							<div
								v-for="formRequest in ownFormRequests.filter(fr => fr.status === 'draft')"
								:key="`own-draft-form-request-${formRequest.pid}`"
								class="col my-2 position-relative"
							>
								<router-link
									:to="
										`/${j.slug}/forms/${
											forms.find(f => formRequest.form_id === f.id).slug
										}/send?formRequest=${formRequest.uuid}`
									"
									class="card card-link h-100 bg-info-lighter shadow-sm"
								>
									<div class="card-body p-3">
										<h6 class="mb-0">
											{{ forms.find(f => formRequest.form_id === f.id).name }}
										</h6>
									</div>
									<div class="card-footer border-0 bg-transparent px-3 pt-0">
										<small
											>Started
											{{
												formatDistanceToNow(new Date(formRequest.created_at), {
													addSuffix: true,
												})
											}}</small
										>
									</div>
								</router-link>

								<button
									class="btn btn-sm btn-icon-danger position-absolute show-on-hover"
									style="top: -5px; right: 10px"
									@click="deleteFormRequest(formRequest)"
								>
									<font-awesome-icon :icon="['fas', 'times']" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="ownFormRequests.filter(fr => fr.status !== 'draft').length" class="card mb-4">
				<div class="card-body">
					<div class="row align-items-center cursor-pointer" @click="toggleSection('requests')">
						<div class="col">
							<h3 class="my-0">License history</h3>
						</div>
						<div class="col-auto">
							<span class="badge bg-success-lighter text-success py-1"
								>{{ ownFormRequests.filter(fr => fr.status !== 'draft').length }}
								<font-awesome-icon
									:icon="['fas', states.requests ? 'chevron-up' : 'chevron-down']"
									class="ms-1"
							/></span>
						</div>
					</div>

					<div v-if="states.requests">
						<p class="mt-2">Your licenes and permits in {{ j.name }}</p>

						<router-link
							v-for="(formRequest, index) in ownFormRequests.filter(fr => fr.status !== 'draft')"
							:key="`own-form-request-${formRequest.pid}`"
							:to="
								`/${j.slug}/forms/${forms.find(f => formRequest.form_id === f.id).slug}/request-info/${
									formRequest.uuid
								}`
							"
							class="d-block text-dark my-3 border-light pb-3"
							:class="{
								'border-bottom': index < ownFormRequests.filter(fr => fr.status !== 'draft').length - 1,
							}"
						>
							<h6>{{ forms.find(f => formRequest.form_id === f.id).name }}</h6>

							<div class="row">
								<div class="col-6">
									<span
										class="badge"
										:class="[formRequestStatusesApplicant[formRequest.status].class]"
										>{{ formRequestStatusesApplicant[formRequest.status].name }}</span
									>
								</div>
								<div class="col-6 text-end">
									<small>
										{{
											formatDistanceToNow(new Date(formRequest.sent_at), { addSuffix: true })
										}}</small
									>
								</div>
							</div>
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">Available permits &amp; licenses</h2>
			</div>
			<div
				v-if="['ADMIN', 'EDITOR'].includes(currentRole) && forms.filter(f => f.status === 'archived').length"
				class="col-auto"
			>
				<button class="btn btn-outline btn-sm text-danger" @click="states.archived = !states.archived">
					{{ states.archived ? 'Hide archived' : 'Show archived' }}
				</button>
			</div>
			<div v-if="currentRole !== 'CITIZEN'" class="col-auto">
				<select class="form-select form-select-sm" v-model="formsSort" style="max-width: 200px">
					<option value="requests_count">Sort: Most submitted</option>
					<option value="name">Sort: Alphabetical</option>
				</select>
			</div>
			<div v-if="['ADMIN', 'EDITOR'].includes(currentRole) && j.slug !== 'lakedelton.org'" class="col-auto">
				<router-link
					:to="`/${j.slug}/forms/create`"
					class="btn btn-sm btn-big-icon d-none d-lg-inline-block"
					:class="forms.length ? 'btn-outline-primary' : 'btn-primary'"
				>
					<font-awesome-icon :icon="['fas', 'plus-circle']" /> Create form
				</router-link>
			</div>
		</div>

		<div class="card mb-4">
			<div class="card-body p-3">
				<div class="row row-cols row-cols-2 row-cols-md-3 row-cols-lg-4 gx-3 mb-3">
					<div class="col my-2" v-for="form in sortedForms" :key="`form-${form.id}`">
						<form-card :form="form" class="h-100"></form-card>
					</div>
				</div>

				<div v-if="state === 'idle' && !forms.length" class="text-center">
					<p>There are no Forms for your {{ j.type }} yet</p>
					<p>
						<router-link to="/" class="btn text-primary">← Back to {{ j.type }} overview</router-link>
					</p>
				</div>

				<div v-if="state === 'loading'" class="text-center">
					<div class="spinner-border" role="status"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import { formatDistanceToNow } from 'date-fns'

import heyGovApi from '@/api.js'
import { setting } from '@/utils.js'
import { formRequestStatuses, formRequestStatusesApplicant } from '@/actions/forms.js'

import FormCard from '@/components/forms/FormCard.vue'
import FormRequestsTable from '@/views/Forms/FormRequestsTable.vue'

export default {
	name: 'Forms',
	metaInfo: {
		title: `Forms`,
	},
	components: { FormCard, FormRequestsTable },
	data() {
		return {
			state: 'idle',
			states: {
				attn: true,
				drafts: false,
				requests: false,
				archived: false,
			},
			ownFormRequests: [],
			formRequestStatuses,
			formRequestStatusesApplicant,
			formsSort: 'name',
		}
	},
	computed: {
		...mapState(['j', 'forms']),
		...mapGetters(['currentRole', 'auth']),
		sortedForms() {
			let forms = this.forms

			if (!this.states.archived) {
				forms = forms.filter(f => f.status !== 'archived')
			}

			return forms.sort((a, b) => {
				return this.formsSort === 'requests_count'
					? b.requests_count - a.requests_count
					: a.name.localeCompare(b.name)
			})
		},
	},
	created() {
		// load settings from user pref storage
		Promise.all([setting('forms-attn-state'), setting('forms-drafts-state'), setting('forms-requests-state')]).then(
			sections => {
				if (sections[0] !== null) {
					this.states.attn = sections[0]
				}
				if (sections[1] !== null) {
					this.states.drafts = sections[1]
				}
				if (sections[2] !== null) {
					this.states.requests = sections[2]
				}
			}
		)

		this.$store.dispatch('loadDepartments')
		this.getForms()
		this.loadOwnFormRequests()
	},
	methods: {
		formatDistanceToNow,
		setting,

		getForms() {
			this.state = 'loading'

			this.$store.dispatch('getForms').then(
				forms => {
					// Load steps for each form. Needed for form submissions table
					if (this.currentRole !== 'CITIZEN') {
						forms
							.filter(f => !f.steps.length)
							.forEach(form => {
								heyGovApi.get(`${this.j.slug}/forms/${form.slug}/steps-fields`).then(({ data }) => {
									form.steps.push(...data)
								})
							})
					}

					this.state = 'idle'
				},
				error => {
					this.state = 'error'
					Vue.toasted.error(`Error loading forms (${error.message})`)
				}
			)
		},

		loadOwnFormRequests() {
			if (this.auth) {
				heyGovApi.get(`/${this.j.slug}/form-requests?own=1`).then(
					({ data }) => {
						this.ownFormRequests.push(...data)
					},
					error => {
						Vue.toasted.error(`Error loading own form requests (${error.message})`)
					}
				)
			}
		},

		deleteFormRequest(formRequest) {
			if (confirm('For sure delete this form application?')) {
				heyGovApi.delete(`/${this.j.slug}/form-requests/${formRequest.pid}`).then(
					() => {
						this.ownFormRequests = this.ownFormRequests.filter(fr => fr.id !== formRequest.id)
					},
					error => {
						Vue.toasted.error(`Error deleting form submission (${error.message})`)
					}
				)
			}
		},

		toggleSection(section) {
			this.states[section] = !this.states[section]
			this.setting(`forms-${section}-state`, this.states[section])
		},
	},
}
</script>
