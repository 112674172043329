<template>
	<div class="activity-timeline">
		<div class="activity-timeline-box container">
			<template v-for="(activity, activityIndex) in activities">
				<div
					:key="`act-${activityIndex}`"
					class="row align-items-center hover py-2"
					:class="[`activity-type-${activity.type}`, `hover-${activity.type}`]"
					:id="`activity-timeline-item-${activity.id}`"
				>
					<div class="col-auto">
						<person-avatar
							v-if="people[activity.person_id]"
							:person="people[activity.person_id]"
						></person-avatar>
					</div>
					<div class="col">
						<p class="mb-1">
							<template v-if="people[activity.person_id]"
								><small class="text-neutral-500">{{ people[activity.person_id].name }}</small> &middot;
							</template>
							<small class="text-neutral-400">{{ activity.created_at | dateTimeLocal }}</small>
						</p>
						<p class="mb-0">{{ activity.message }}</p>
						<p v-if="activity.files && activity.files.length" class="mb-0">
							<a v-for="(file, index) in activity.files" :key="index" :href="file" target="_blank"
								><small class="rounded px-1 bg-neutral-50 border me-1 mb-1">{{
									truncateString(file.split('/').at(-1))
								}}</small></a
							>
						</p>
					</div>
				</div>
				<div
					v-if="
						activities[activityIndex + 1] &&
							dateDiffInSeconds(activity.created_at, activities[activityIndex + 1].created_at) > 60 * 60
					"
					:key="`act-${activityIndex}-time`"
					class="activity-timeline-time text-center"
				>
					<small class="text-neutral-400"
						>↕ &nbsp;
						{{
							formatDistanceStrict(
								new Date(activities[activityIndex + 1].created_at),
								new Date(activity.created_at)
							)
						}}</small
					>
				</div>
			</template>
		</div>
	</div>
</template>

<style scoped>
.activity-timeline {
	max-height: 50vh;
	overflow-y: scroll;
	margin-left: -28px;
	margin-right: -28px;
}
</style>

<script>
import { formatDistanceStrict } from 'date-fns'
import { mapState } from 'vuex'

import { truncateString } from '@/utils.js'

import PersonAvatar from '@/components/PersonAvatar.vue'

export default {
	name: 'ActivityTimeline',
	components: { PersonAvatar },
	props: {
		activities: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			formatDistanceStrict,
		}
	},
	computed: {
		...mapState(['j', 'people']),
	},
	created() {
		//const peopleIds = this.activities.map(activity => activity.person_id)
	},
	methods: {
		truncateString,

		dateDiffInSeconds(newerDate, olderDate) {
			return (new Date(newerDate) - new Date(olderDate)) / 1000
		},
	},
}
</script>
