<template>
	<div v-if="venue" class="venue-detail">
		<venue-header :venue="venue"></venue-header>

		<div class="card mb-5">
			<form @submit.prevent="updateVenue">
				<div class="card-body">
					<div class="row">
						<div class="col-md-5">
							<div class="form-group mb-3">
								<label class="form-label" for="venue-name">Name</label>
								<input class="form-control" id="venue-name" v-model="venue.name" />
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="venue-department">Department</label>
								<select class="form-select" v-model="venue.department_id" id="venue-department">
									<option value="">Select department</option>
									<option
										v-for="department in activeDepartments"
										:key="`form-dept-${department.id}`"
										:value="department.id"
										:disabled="
											venue.status === 'public' &&
												!department.stripe_account &&
												!department.justifi_account &&
												!department.paygov_account &&
												!department.bridgepay_account &&
												!department.municipay_account
										"
										>{{ department.name }}</option
									>
								</select>
								<span class="form-text">ℹ️ Payments will be routed to this department</span>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="venue-group">Group</label>

								<select class="form-select" v-model="venue.venue_group_id" id="venue-group">
									<option :value="null">No group</option>
									<option v-for="group in venueGroups" :key="group.id" :value="group.id">{{
										group.name
									}}</option>
								</select>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="venue-payment-code">Payment code</label>

								<input
									class="form-control"
									id="venue-payment-code"
									v-model="venue.payment_code"
									placeholder="EXAMPLE123"
								/>
								<span class="form-text"
									>Accounting applications may call this "Charge code", "Ledger number" or other
									names.</span
								>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="venue-attrs">Attributes</label>

								<div v-for="(attr, index) in venue.attributes" :key="index" class="row g-2">
									<div class="col mb-2">
										<input
											type="text"
											class="form-control form-control-sm"
											placeholder="Label"
											v-model="attr.label"
											required
										/>
									</div>
									<div class="col">
										<input
											type="text"
											class="form-control form-control-sm"
											placeholder="Value"
											v-model="attr.value"
											required
										/>
									</div>
								</div>

								<p v-if="venue.attributes.length">
									<strong
										class="text-primary-400 cursor-pointer"
										@click="venue.attributes.push({ value: '', label: '' })"
										>Add attribute</strong
									>
								</p>
								<p v-else class="text-neutral-400">
									Attributes are characteristics or properties for a venue, that can be used for
									filtering or grouping. Example: size, color, length, etc.
									<strong
										class="text-primary-400 cursor-pointer"
										@click="venue.attributes.push({ value: '', label: '' })"
										>Add one now</strong
									>
								</p>
							</div>
						</div>
						<div class="col-md-7">
							<div class="form-group mb-3">
								<label class="form-label" for="venue-location">Location</label>

								<gmap-autocomplete
									v-if="venue.location"
									@place_changed="setVenueLocation"
									:select-first-on-enter="true"
									:options="autocompleteOptions"
								>
									<template v-slot:default="slotProps">
										<input
											id="venue-location"
											ref="input"
											:value="venue.location.name"
											placeholder="Venue address"
											class="form-control"
											required
											v-on="slotProps.listeners"
										/>
									</template>
								</gmap-autocomplete>
							</div>

							<muni-map
								class="mb-1"
								:height="430"
								:adjust-zoom="2"
								:center="{ lat: venue.location.lat, lng: venue.location.lng }"
								:include-venues="venuesInSameGroup.filter(v => v.id !== venue.id).map(v => v.id)"
								:include-departments="false"
								:markers="[
									{
										position: { lat: venue.location.lat, lng: venue.location.lng },
										label: Number.isInteger(Number(venue.name.at(-1)))
											? venue.name.at(-1)
											: venue.name.at(1),
										draggable: true,
									},
								]"
								@marker_dragend="updateVenueLocation"
							></muni-map>
							<span class="form-text"
								>ℹ️ The pin can be dragged and dropped for a more precise location.</span
							>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex justify-content-between align-items-center">
					<a
						href="https://townweb.notion.site/HeyReserve-562449ca907749398141da5549227d18"
						target="heygov-help"
						>🙋 Learn how HeyReserve works</a
					>
					<button class="btn btn-primary px-5">Save venue info</button>
				</div>
			</form>
		</div>

		<div class="card mb-5">
			<form @submit.prevent="updateVenueTexts">
				<div class="card-body">
					<h5>Texts &amp; pictures</h5>

					<div class="form-group mb-3">
						<label class="form-label" for="description">Description</label>
						<textarea class="form-control" id="description" rows="5" v-model="venue.description"></textarea>
					</div>

					<div class="form-group mb-3">
						<label class="form-label" for="amenities">Venue amenities</label>

						<vue-editor
							v-model="venue.amenities_text"
							:editor-toolbar="editorToolbar"
							placeholder="What should people expect here?"
						></vue-editor>
					</div>

					<div class="form-group mb-3">
						<label class="form-label" for="rules">Venue rules &amp; guides</label>

						<vue-editor
							:editor-toolbar="editorToolbar"
							v-model="venue.text_rules"
							placeholder="What are the venue rules to follow?"
						></vue-editor>
					</div>

					<div class="row mb-3">
						<div class="col-md-6 col-lg-6">
							<div class="form-group mb-3">
								<label class="form-label" for="check-in">Check In message</label>
								<textarea
									rows="4"
									class="form-control"
									id="check-in"
									v-model="venue.message_check_in"
								></textarea>
							</div>
						</div>

						<div class="col-md-6 col-lg-6">
							<div class="form-group mb-3">
								<label class="form-label" for="check-out">Check Out message</label>
								<textarea
									rows="4"
									class="form-control"
									id="check-out"
									v-model="venue.message_check_out"
								></textarea>
							</div>
						</div>
					</div>

					<div class="form-group mb-4" id="images">
						<label class="form-label">Pictures</label>

						<draggable
							:list="venue.images"
							@end="updateImageOrder"
							:sort="true"
							draggable=".col-image"
							class="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 g-3"
						>
							<div
								v-for="(image, index) in venue.images"
								:key="`venue-image-${image.id}`"
								class="col col-image on-parent"
							>
								<div
									class="card rounded-1"
									:class="{ 'border border-secondary': image.src === venue.main_image }"
								>
									<img
										:src="image.src"
										class="card-img-top rounded-1"
										:alt="image.title"
										style="aspect-ratio: 4/3; object-fit: cover;"
									/>

									<div class="card-body p-2">
										<div class="input-group mb-0">
											<input
												type="text"
												:class="{ 'border-0': image.title }"
												class="form-control form-control-sm p-2"
												placeholder="Caption"
												v-model="image.title"
												@keyup="buttonIsVisible = index"
											/>

											<button
												v-if="buttonIsVisible === index"
												class="btn btn-outline-primary btn-sm px-1"
												type="button"
												@click="updateImageInfo(image)"
											>
												Save
											</button>
										</div>

										<div class="dropdown three-dots show-on-hover">
											<button
												class="btn btn-sm btn-icon-info"
												role="button"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
											</button>
											<ul class="dropdown-menu">
												<li v-if="image.src !== venue.main_image">
													<a class="dropdown-item" @click="setImageDefault(image)"
														>Make default</a
													>
												</li>
												<li>
													<span class="dropdown-item text-danger" @click="deleteImage(image)"
														>Delete</span
													>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

							<div v-if="states.imageUploading" class="col">
								<div
									class="card rounded-1 bg-light border justify-content-center align-items-center"
									style="aspect-ratio: 4/3"
								>
									<div class="spinner-border" role="status"></div>
								</div>
							</div>

							<div class="col">
								<label
									class="card rounded-1 bg-neutral-50 border border-dashed cursor-pointer justify-content-center align-items-center"
									style="aspect-ratio: 4/3"
									for="upload-image"
								>
									<font-awesome-icon :icon="['fas', 'plus-circle']" />
									<div class="mt-2">Upload photo</div>
								</label>
								<input
									type="file"
									id="upload-image"
									class="d-none"
									accept="image/*"
									@change="uploadImage($event)"
								/>
							</div>
						</draggable>
					</div>

					<div class="form-group mb-4" id="documents">
						<label class="form-label" for="upload-file">Public documents</label>

						<div class="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 g-3">
							<div
								v-for="file in venue.public_files"
								:key="`venue-file-${file.file}`"
								class="col col-file position-relative on-parent"
							>
								<a
									:href="getPublicFileUrl(file.file)"
									target="_blank"
									class="d-block rounded-1 ratio ratio-4x3 bg-neutral-200 border"
								>
									<span class="d-flex align-items-center justify-content-center text-center">
										{{ file.name }}
									</span>
								</a>

								<div class="dropdown three-dots show-on-hover">
									<button
										class="btn btn-sm btn-icon-info"
										role="button"
										data-bs-toggle="dropdown"
										aria-expanded="false"
									>
										<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
									</button>
									<ul class="dropdown-menu">
										<li>
											<span class="dropdown-item text-infoo">Rename</span>
										</li>
										<li>
											<span class="dropdown-item text-danger" @click="deleteFile(file)"
												>Delete</span
											>
										</li>
									</ul>
								</div>
							</div>

							<div v-if="states.fileUploading" class="col">
								<div
									class="card rounded-1 bg-light border justify-content-center align-items-center"
									style="aspect-ratio: 4/3"
								>
									<div class="spinner-border" role="status"></div>
								</div>
							</div>

							<div class="col">
								<label
									class="card rounded-1 bg-neutral-50 border border-dashed cursor-pointer justify-content-center align-items-center"
									style="aspect-ratio: 4/3"
									for="upload-file"
								>
									<font-awesome-icon :icon="['fas', 'plus-circle']" />
									<div class="mt-2">Upload doc</div>
								</label>
								<input
									type="file"
									id="upload-file"
									class="d-none"
									accept="application/*"
									@change="uploadFile($event)"
								/>
							</div>
						</div>
					</div>

					<hr class="bg-gray my-4" />

					<p class="subtitle">Messages</p>

					<div class="form-group mb-3">
						<label class="form-label" for="message_submitted">Reservation submitted</label>
						<input
							class="form-control"
							id="message_submitted"
							v-model="venue.message_submitted"
							placeholder="Ex: Thank you for placing reservation"
						/>
					</div>

					<div class="form-group mb-3">
						<label class="form-label" for="message_approved">Booking confirmed</label>
						<input
							class="form-control"
							id="message_approved"
							v-model="venue.message_approved"
							placeholder="Ex: Your booking has been confirmed"
						/>
					</div>

					<div class="form-group mb-3">
						<label class="form-label" for="message_declined">Reservation declined</label>
						<input
							class="form-control"
							id="message_declined"
							v-model="venue.message_declined"
							placeholder="Ex: Your booking has been declined"
						/>
					</div>

					<div class="form-group mb-3">
						<label class="form-label" for="message_auto_declined">Reservation auto declined</label>
						<input
							class="form-control"
							id="message_auto_declined"
							v-model="venue.message_auto_declined"
							placeholder="Ex: Your booking has been declined and full payment is refunded"
						/>
						<span class="form-text"
							>Reservations are auto-declined if not reviewed: 1h before booking date OR 5 days after
							reservation date</span
						>
					</div>

					<div class="form-group mb-3">
						<label class="form-label" for="message_upcoming">Booking coming up</label>
						<input
							class="form-control"
							id="message_upcoming"
							v-model="venue.message_upcoming"
							placeholder="Ex: Your booking is coming up soon"
						/>
						<span class="form-text">Message sent 24 hours before the booking takes place</span>
					</div>

					<div class="form-group mb-3">
						<label class="form-label" for="message_completed">Booking completed</label>
						<input
							class="form-control"
							id="message_completed"
							v-model="venue.message_completed"
							placeholder="Ex: Thank you for booking with us"
						/>
					</div>
				</div>
				<div
					class="card-footer text-end
				"
				>
					<button class="btn btn-primary px-5">Save venue</button>
				</div>
			</form>
		</div>

		<div class="card bg-danger-lighter">
			<div class="card-header">
				<h5 class="my-0">Danger zone</h5>
			</div>
			<div class="card-body">
				<form @submit.prevent="updateSlug">
					<div class="form-group mb-3">
						<label for="venue-slug" class="form-label">Venue url</label>
						<p class="mb-2">
							⚠️ Previous links posted on website, facebook, etc will stop working. Please update them
							with the new link.
						</p>
						<div class="input-group mb-3">
							<span class="input-group-text">/{{ j.slug }}/venues/</span>
							<input
								type="text"
								class="form-control"
								id="venue-slug"
								required
								minlength="4"
								v-model="slug"
							/>
						</div>
					</div>
					<p class="card-text">
						<button class="btn btn-outline-dark">Update link</button>
					</p>
				</form>

				<hr class="bg-gray my-4" />

				<h5>Archive venue and reservations</h5>
				<p class="card-text">
					Archive will hide the venue from public view, and no new reservations will be accepted. All data
					will remain available for {{ j.type }} staff.
				</p>
				<p class="card-text">
					<button
						:disabled="venue.status === 'archived'"
						class="btn btn-outline-danger"
						@click="archiveVenue"
					>
						Archive venue
					</button>
				</p>

				<div v-if="isStaff || !venue.bookings_count">
					<hr class="bg-gray my-4" />
					<h5>Delete venue</h5>
					<p class="card-text">
						🚨 This will delete <u>EVERYTHING</u> linked to this venue: time slots, events, reservations and
						uploaded files.
					</p>

					<p class="card-text">
						<button class="btn btn-danger" @click="removeVenue">Delete venue</button>
					</p>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="text-center">
		<div class="spinner-border" role="status"></div>
	</div>
</template>

<style lang="scss" scoped>
.three-dots {
	position: absolute;
	top: -10px;
	right: -6px;
}
</style>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { VueEditor } from 'vue2-editor'
import draggable from 'vuedraggable'

import heyGovApi from '@/api.js'
import { handleResponseError, getPublicFileUrl, mimeTypes } from '@/utils.js'

import MuniMap from '@/components/MuniMap.vue'
import VenueHeader from './VenueHeader.vue'

export default {
	name: 'Venue',
	metaInfo() {
		return {
			title: `Pictures & texts for ${this.venue?.name || 'venue'}`,
		}
	},
	components: { MuniMap, VenueHeader, VueEditor, draggable },
	data() {
		return {
			venue: null,
			state: 'idle',
			states: {
				imageUploading: false,
				fileUploading: false,
			},
			image: {},
			editorToolbar: [
				['bold', 'italic', 'underline'],
				[{ list: 'ordered' }, { list: 'bullet' }],
				[{ align: '' }, { align: 'center' }],
				['link'],
			],
			buttonIsVisible: null,
			imageDefault: 'border border-secondary border-2',
			isActive: false,
			changed: false,
			slug: this.$route.params.venueSlug,

			autocompleteOptions: {
				componentRestrictions: { country: 'us' },
				fields: ['formatted_address', 'name', 'geometry', 'place_id', 'url', 'icon', 'icon_mask_base_uri'],
			},
		}
	},
	computed: {
		...mapState(['j', 'venues', 'venueGroups']),
		...mapGetters(['currentRole', 'isStaff', 'activeDepartments']),
		venuesInSameGroup() {
			if (!this.venues || !this.venue.venue_group_id) {
				return []
			}

			return this.venues.filter(v => v.venue_group_id === this.venue.venue_group_id)
		},
	},
	created() {
		this.getVenue()
		this.$store.dispatch('loadDepartments')
		this.$store.dispatch('getVenues')
		this.$store.dispatch('getVenueGroups')
		this.scrollToImage()
	},
	beforeRouteLeave(to, from, next) {
		if (this.changed === true) {
			const confirmed = window.confirm(
				'Are you sure you want to leave before click the update venue button? Unsaved changes will be lost 😟'
			)
			if (confirmed) {
				next()
			} else {
				next(false)
			}
		} else {
			next()
		}
	},
	methods: {
		getPublicFileUrl,

		getVenue() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}?expand=images`).then(
				({ data }) => {
					this.venue = data
					this.state = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading venue (${error})`)
					this.state = 'error'
				}
			)
		},
		loadVenueGroups() {
			heyGovApi.get(`${this.j.slug}/venue-groups`).then(({ data }) => {
				this.venueGroups = data
			}, handleResponseError(`Error loading venue groups ({error})`))
		},

		setVenueLocation(place) {
			this.venue.location.name = place.name
			this.venue.location.address = place.formatted_address
			this.venue.location.place_id = place.place_id
			this.venue.location.lat = place.geometry.location.lat()
			this.venue.location.lng = place.geometry.location.lng()
			this.venue.location.url = place.url
			this.venue.location.icon = place.icon
			this.venue.location.icon_svg = `${place.icon_mask_base_uri}.svg`
		},
		updateVenueLocation(event) {
			this.venue.location.lat = event.latLng.lat()
			this.venue.location.lng = event.latLng.lng()
		},
		updateVenue() {
			heyGovApi
				.put(`/${this.j.slug}/venues/${this.venue.slug}`, {
					name: this.venue.name,
					department_id: this.venue.department_id,
					venue_group_id: this.venue.venue_group_id,
					payment_code: this.venue.payment_code,
					location: this.venue.location,
					attributes: this.venue.attributes,
				})
				.then(() => {
					this.changed = false
					Vue.toasted.success(`"${this.venue.name}" is updated`)
				}, handleResponseError('Error updating venue ({error})'))
		},
		updateVenueTexts() {
			heyGovApi
				.put(`/${this.j.slug}/venues/${this.venue.slug}`, {
					description: this.venue.description,
					amenities_text: this.venue.amenities_text,
					text_rules: this.venue.text_rules,
					message_check_in: this.venue.message_check_in,
					message_check_out: this.venue.message_check_out,
					message_submitted: this.venue.message_submitted,
					message_approved: this.venue.message_approved,
					message_declined: this.venue.message_declined,
					message_auto_declined: this.venue.message_auto_declined,
					message_upcoming: this.venue.message_upcoming,
					message_completed: this.venue.message_completed,
				})
				.then(() => {
					this.changed = false
					Vue.toasted.success(`"${this.venue.name}" is updated`)
				}, handleResponseError('Error updating venue ({error})'))
		},
		getImageInfo(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader()

				reader.onload = function(e) {
					const image = new Image()

					image.onerror = reject
					image.onload = function() {
						resolve({
							type: file.type,
							size: file.size,
							width: this.width,
							height: this.height,
						})
					}

					image.src = e.target.result
				}

				reader.readAsDataURL(file)
			})
		},
		async uploadImage($event) {
			const file = $event.target.files[0]
			const imageInfo = await this.getImageInfo(file)

			if (imageInfo.size / 1024 / 1024 > 10) {
				Vue.toasted.error('Image size too large! Upload one less than 10MB please')
			} else if (imageInfo.width < 800 || imageInfo.height < 500) {
				Vue.toasted.error('Please upload a higher quality image, at least 800x500px')
			} else {
				this.states.imageUploading = true

				const formFile = new FormData()
				formFile.append('file', file)

				heyGovApi
					.post(`/${this.j.slug}/venues/${this.venue.slug}/image`, formFile)
					.then(
						({ data }) => {
							this.venue.images.push(data)

							if (this.venue.images.length === 1) {
								this.setImageDefault(this.venue.images[0])
							}

							Vue.toasted.success(`Venue picture is uploaded`)
						},
						error => {
							Vue.toasted.error(`Error uploading image ~ ${error.message}`)
						}
					)
					.finally(() => {
						this.states.imageUploading = false
					})
			}
		},
		updateImageInfo(image) {
			heyGovApi
				.put(`/${this.j.slug}/venues/${this.venue.id}/image/${image.id}`, {
					title: image.title,
				})
				.then(
					() => {
						Vue.toasted.show(`Image info is updated`)
						this.buttonIsVisible = null
					},
					error => {
						Vue.toasted.error(error)
					}
				)
		},
		deleteImage(image) {
			if (confirm('Are you sure?')) {
				this.venue.images = this.venue.images.filter(img => img.id !== image.id)

				heyGovApi.delete(`/${this.j.slug}/venues/${this.venue.id}/image/${image.id}`).then(() => {
					/* If image is default set next index image to be default */
					if (this.venue.main_image === image.src && this.venue.images.length > 1) {
						this.setImageDefault(this.venue.images[0])
					}

					Vue.toasted.show(`Image is removed`)
				}, Vue.toasted.error)
			}
		},
		scrollToImage() {
			if (this.$route.hash) {
				setTimeout(() => {
					document.getElementById('images').scrollIntoView({ behavior: 'smooth' })
				}, 250)
			}
		},
		setImageDefault(image) {
			this.venue.images = [image, ...this.venue.images.filter(i => i.id !== image.id)]
			this.updateImageOrder()
		},
		updateImageOrder() {
			this.venue.main_image = this.venue.images[0].src

			const list = this.venue.images.map(function(item, index) {
				return { id: item.id, ord: index }
			})

			heyGovApi
				.post(`/${this.j.slug}/venues/${this.venue.id}/image-order`, {
					default: this.venue.images[0].src,
					list: JSON.stringify(list),
				})
				.then(
					() => {
						Vue.toasted.show(`Venue pictures are updated`)
					},
					error => {
						Vue.toasted.error(`Error updating order for images (${error})`)
					}
				)

			this.changed = false
		},
		removeVenue() {
			if (confirm('Last chance 🤷 Really delete everything?')) {
				heyGovApi.delete(`/${this.j.slug}/venues/${this.venue.id}`).then(() => {
					Vue.toasted.show(`"${this.venue.name}" is removed`)
					this.$router.push(`/${this.j.slug}/venues`)
				}, Vue.toasted.error)
			}
		},
		archiveVenue() {
			if (confirm('For sure do you want to archive this venue?')) {
				this.venue.status = 'archived'

				heyGovApi
					.put(`/${this.j.slug}/venues/${this.venue.slug}`, {
						status: this.venue.status,
					})
					.then(
						() => {
							this.$router.push(`/${this.j.slug}/venues/${this.venue.slug}`)
							Vue.toasted.show(`"${this.venue.name}" is archived`)
							this.changed = false
						},
						error => {
							Vue.toasted.error(`Error updating venue (${error})`)
						}
					)
			}
		},
		updateSlug() {
			if (this.venue.slug === this.slug) {
				Vue.toasted.show(`Nothign to update 😑`)
			} else {
				heyGovApi
					.put(`/${this.j.slug}/venues/${this.venue.slug}`, {
						slug: this.slug,
					})
					.then(
						({ data }) => {
							this.venue.slug = data.slug

							//Update venue slug in url
							this.$router.push(`/${this.j.slug}/venues/${this.venue.slug}/settings`)
							Vue.toasted.success(`${this.venue.name} slug is updated`)
							this.changed = false
						},
						error => {
							Vue.toasted.error(error.response?.data?.title || 'Server error. Please try again')
							this.slug = this.$route.params.venueSlug
						}
					)
			}
		},

		uploadFile($event) {
			const file = $event.target.files[0]

			if (file.size / 1024 / 1024 > 10) {
				Vue.toasted.error('File size too large! Upload one less than 10MB please')
			} else if (!mimeTypes.documents.includes(file.type)) {
				Vue.toasted.error('This file is not an acceped document format. Please upload PDF or DOC(X)')
			} else {
				this.states.fileUploading = true

				const formFile = new FormData()
				formFile.append('file', file)

				heyGovApi
					.post(`/${this.j.slug}/venues/${this.venue.slug}/file?store=1`, formFile)
					.then(({ data }) => {
						this.venue.public_files.push(data)

						Vue.toasted.success(`Venue file is uploaded`)
					}, handleResponseError('Error uploading file ({error})'))
					.finally(() => {
						this.states.fileUploading = false
					})
			}
		},
		deleteFile(file) {
			if (confirm('Are you sure?')) {
				this.venue.public_files = this.venue.public_files.filter(f => f.file !== file.file)

				heyGovApi
					.delete(`/${this.j.slug}/venues/${this.venue.slug}/file`, { params: { file: file.file } })
					.then(() => {
						Vue.toasted.show(`File is removed`)
					}, handleResponseError('Error removing file ({error})'))
			}
		},
	},
	watch: {
		venue: {
			deep: true,
			handler(newValue, oldValue) {
				if (oldValue) {
					this.changed = true
				}
			},
		},
	},
}
</script>
