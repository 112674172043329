<template>
	<div
		class="bg-neutral-100 border rounded-1 text-center position-relative on-parent"
		:class="{ 'px-2 py-3': !file.type.startsWith('image/') }"
	>
		<div class="btn-file-remove position-absolute bg-danger-50 rounded-circle show-on-hover" @click="removeFile">
			<font-awesome-icon :icon="['fas', 'trash']" class="text-danger-400" />
		</div>

		<img
			v-if="file.type.startsWith('image/')"
			ref="imgPreview"
			class="rounded-1"
			:alt="file.name"
			:title="file.name"
		/>
		<template v-else>
			<font-awesome-icon :icon="['fas', faFileIcon]" class="text-primary mb-2" />

			<p class="text-neutral-500 mb-0 text-truncate" :title="file.name">
				<small>{{ file.name }}</small>
			</p>
		</template>
	</div>
</template>

<style scoped>
.btn-file-remove {
	top: -6px;
	right: -5px;
	width: 28px;
	line-height: 28px;
	font-size: 0.8rem;
	cursor: pointer;
}

img {
	object-fit: cover;
	width: 100%;
}
</style>

<script>
export default {
	name: 'FilePreview',
	props: {
		file: {
			type: File,
			required: true,
		},
		removeButton: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {}
	},
	computed: {
		faFileIcon() {
			let icon = 'file'

			if (this.file.type === 'application/pdf') {
				icon = 'file-pdf'
			} else if (this.file.type.startsWith('text/')) {
				icon = 'file-alt'
			} else if (this.file.type.startsWith('audio/')) {
				icon = 'file-audio'
			} else if (this.file.type.startsWith('video/')) {
				icon = 'file-video'
			} else if (this.file.type.startsWith('image/')) {
				icon = 'image'
			}

			return icon
		},
	},
	mounted() {
		if (this.file.type.startsWith('image/')) {
			const reader = new FileReader()

			reader.addEventListener(
				'load',
				() => {
					this.$refs.imgPreview.src = reader.result
				},
				false
			)

			reader.readAsDataURL(this.file)
		}
	},
	methods: {
		removeFile() {
			this.$emit('remove', this.file)
		},
	},
}
</script>
