<template>
	<Pie :chart-data="data" :chart-options="options" :height="height" :width="width || height" />
</template>

<script>
import 'chart.js/auto'
import { Pie } from 'vue-chartjs/legacy'

export default {
	components: { Pie },
	props: ['data', 'options', 'height', 'width'],
}
</script>
