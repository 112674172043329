<template>
	<div class="form-detail">
		<div v-if="form">
			<form-page-head :form="form"></form-page-head>

			<!-- Form general data -->
			<div class="card mb-5">
				<form @submit.prevent="updateForm">
					<div class="card-body">
						<div class="row">
							<div class="col-md-6 col-lg-8">
								<div class="form-group mb-3">
									<label class="form-label" for="form-name"
										>Name <span class="text-danger">*</span></label
									>
									<input class="form-control" id="form-name" v-model="form.name" />
								</div>
							</div>

							<div class="col-md-6 col-lg-4">
								<!--
								<div class="form-group mb-3">
									<label for="form-price">Price</label>
									<input class="form-control" id="form-price" v-model="form.price" placeholder="" />
								</div>
								-->

								<div class="form-group mb-3">
									<label class="form-label" for="form-department">Department</label>
									<select class="form-select" id="form-department" v-model="form.department_id">
										<option value="">Select a department</option>
										<option
											v-for="department in activeDepartments"
											:key="`form-dept-${department.id}`"
											:value="department.id"
											>{{ department.name }}</option
										>
									</select>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="col-md-6 col-lg-8">
								<div class="form-group mb-3">
									<label class="form-label" for="form-description">Description</label>
									<textarea
										class="form-control"
										id="form-description"
										rows="5"
										v-model="form.description"
									></textarea>
								</div>

								<div class="form-group mb-3">
									<label class="form-label" for="form-icon">Icon</label>

									<div class="icon-picker border rounded-1">
										<label
											class="text-neutral-300"
											:class="{ selected: !form.icon }"
											@click="form.icon = null"
										>
											-
										</label>
										<label
											v-for="icon in formIcons"
											:key="`form-icons-${icon}`"
											:class="{ selected: icon === form.icon }"
											@click="form.icon = icon"
										>
											<font-awesome-icon :icon="['fas', icon]" />
										</label>
									</div>
								</div>
							</div>

							<div class="col-md-6 col-lg-4">
								<div class="form-group mb-3">
									<label class="form-label" for="form-type">Form type</label>
									<select class="form-select" id="form-type" v-model="form.type">
										<option value="form">Form</option>
										<option value="permit">Permit</option>
										<option value="license">License</option>
										<option value="application">Application</option>
									</select>
								</div>

								<div class="form-group mb-3">
									<label class="form-label" for="form-info-column1">Info column 1</label>
									<select class="form-select" id="form-info-column1" v-model="form.info_column1">
										<option :value="null">-</option>
										<option
											v-for="field in formFields.filter(f => f.type !== 'TextElement')"
											:key="`fc1-${field.id}`"
											:value="field.id"
											>{{ field.label }}</option
										>
									</select>
								</div>

								<div class="form-group mb-3">
									<label class="form-label" for="form-info-column2">Info column 2</label>
									<select class="form-select" id="form-info-column2" v-model="form.info_column2">
										<option :value="null">-</option>
										<option
											v-for="field in formFields.filter(f => f.type !== 'TextElement')"
											:key="`fc2-${field.id}`"
											:value="field.id"
											>{{ field.label }}</option
										>
									</select>
								</div>

								<div class="form-group mb-3">
									<label class="form-label" for="form-map-column">Map / Geolocation column</label>
									<select class="form-select" id="form-map-column" v-model="form.geo_column">
										<option :value="null">-</option>
										<option
											v-for="field in formFields.filter(f => f.type === 'AddressInputElement')"
											:key="`fc2-${field.id}`"
											:value="field.id"
											>{{ field.label }}</option
										>
									</select>
								</div>

								<div class="form-group mb-3" v-if="isStaff">
									<label class="form-label" for="form-publishing-date">Date published</label>
									<input type="date" class="form-control" v-model="form.published_at" />
								</div>
							</div>
						</div>

						<hr class="bg-gray my-4" />

						<p class="subtitle">Submissions workflow</p>

						<!--Form Expiry date -->
						<div class="row">
							<div class="col-md-6">
								<div class="form-group mb-3">
									<label for="form-application-prefix" class="form-label"
										>{{ form.type }} prefix</label
									>

									<input
										type="text"
										class="form-control"
										id="form-application-prefix"
										v-model="form.application_prefix"
										maxlength="8"
										placeholder="DOG, HG, BOAT-{year}, etc"
									/>

									<span class="form-text"
										>Next submission will be <code>{{ nextApplicationNumber }}</code></span
									>
								</div>
							</div>

							<div class="col-md-6">
								<div class="form-group mb-3">
									<label for="form-application-number" class="form-label"
										>Next {{ form.type }} number</label
									>

									<input
										type="number"
										class="form-control"
										id="form-application-number"
										v-model="form.application_next_number"
										min="1"
									/>
									<span class="form-text"
										>This number automatically increases with each submission received</span
									>
								</div>
							</div>

							<div class="col-md-6">
								<div class="form-group mb-3 date-picker-wrapper">
									<label for="expiry_date" class="form-label">Do form applications expire?</label>
									<select
										class="form-select"
										id="expiry_date"
										v-model="form.expiry_date"
										@change="handleSelectChange"
									>
										<option :value="null">No</option>
										<option value="14">Two Weeks after form approved</option>
										<option value="30">One Month after form approved</option>
										<option value="180">Six Months after form approved</option>
										<option value="365">One Year after form approved</option>
										<option value="03-01">Next March 01</option>
										<option value="06-30">Next June 30</option>
										<option value="10-31">Next October 31</option>
										<option value="12-31">Next December 31</option>
										<option v-if="dateValue" :value="dateValue">{{ longMonthName }}</option>
										<option value="custom-date">Custom Date</option>
									</select>
									<div v-if="isCalendarVisible">
										<date-picker
											v-model="dateValue"
											:disabled-date="getDisabledDate"
											value-type="YYYY-MM-DD"
											type="month"
											inline
										></date-picker>
										<date-picker
											v-model="dateValue"
											:disabled-date="getDisabledDate"
											value-type="YYYY-MM-DD"
											type="date"
											@change="handleDatePickerDaySelect"
											inline
										></date-picker>
									</div>
								</div>
							</div>

							<div class="col-md-6">
								<div class="form-group mb-3">
									<label for="auto-approve" class="form-label">Automatic approval</label>
									<div class="form-check">
										<input
											class="form-check-input"
											type="checkbox"
											v-model="form.auto_approve"
											id="auto-approve"
										/>
										<label class="form-check-label" for="auto-approve">
											Auto approve {{ pluralize(form.type) }} if all steps are completed + online
											payment (if needs payment)
										</label>
									</div>
								</div>
							</div>

							<div class="col-md-6">
								<div class="form-group mb-3">
									<label class="form-label" for="form-submissions-manage-role"
										>Who can see &amp; manage submissions?</label
									>

									<select
										class="form-select"
										id="form-submissions-manage-role"
										v-model="form.submissions_manage_role"
										:disabled="!form.department_id"
									>
										<option :value="null">Members from any department</option>
										<option v-if="form.department_id" value="department"
											>Only "{{ departments.find(d => d.id == form.department_id).name }}"
											members</option
										>
									</select>
								</div>
							</div>

							<div v-if="isStaff" class="col-md-6">
								<div class="form-group bg-danger-50 rounded-1 p-2 mb-3">
									<label for="form-has-certificate" class="form-label">Certificate</label>

									<select class="form-select" v-model="form.has_certificate">
										<option :value="null">No certificate</option>
										<option value="qrc">QR Code</option>
										<option value="pdf">PDF</option>
									</select>
								</div>
							</div>
						</div>

						<hr class="bg-gray my-4" />

						<p class="subtitle">Form messages</p>

						<div class="form-group mb-3">
							<label class="form-label" for="textAction">Form button label</label>
							<input
								class="form-control"
								id="textAction"
								v-model="form.textAction"
								placeholder="Ex: 'Apply for this permit', 'License your dog'"
							/>
							<p class="form-text">Text to appear in buttons to start form application</p>
						</div>
						<div class="form-group mb-3">
							<label class="form-label" for="endMessage">Form submitted</label>
							<input
								class="form-control"
								id="endMessage"
								v-model="form.endMessage"
								placeholder="Ex: Form is sent, the board will review it."
							/>
						</div>
						<div class="form-group mb-3">
							<label class="form-label" for="approvedMessage">Form is approved</label>
							<input
								class="form-control"
								id="approvedMessage"
								v-model="form.approvedMessage"
								placeholder="Ex: You’re all done! You can start building now!"
							/>
						</div>
						<div class="form-group mb-3">
							<label class="form-label" for="deniedMessage">Request Denied</label>
							<input
								class="form-control"
								id="deniedMessage"
								v-model="form.deniedMessage"
								placeholder="Ex: The board said no 😕"
							/>
						</div>

						<hr class="bg-gray my-4" />

						<!-- Delete/Upload Form PDF -->
						<div class="form-group mb-3">
							<label for="upload_file" class="form-label">Original form document</label>

							<div v-if="form.upload_file">
								<div class="position-relative" style="max-width: 200px">
									<button
										type="button"
										class="btn-close position-absolute bg-light rounded px-1"
										style="right: 1rem"
										@click="removePdf()"
										aria-label="Close"
									></button>

									<a :href="form.upload_file" target="_blank"
										><img
											class="img-thumbnail"
											src="https://files.heygov.com/assets/icon-pdf.jpg"
											alt="Form PDF"
											width="200"
											height="100"
									/></a>
								</div>
							</div>

							<div v-else>
								<label
									for="upload_file"
									class="
												d-block
												border border-dashed
												rounded
												text-center
												bg-light
												cursor-pointer
												py-3
												px-2
											"
								>
									<p class="mb-0 p-4">
										<font-awesome-icon
											:icon="['fas', 'file-pdf']"
											class="fa-fw me-1 fa-3x bg-light text-primary"
										/>Upload your file
									</p>
								</label>
								<input type="file" id="upload_file" @change="uploadFile($event)" class="d-none" />
							</div>

							<div v-if="states.fileUploading" class="col-2 text-center">
								<div class="spinner-border" role="status"></div>
							</div>

							<span class="form-text">Form used as reference to build the HeyGov form</span>
						</div>

						<p class="text-end">
							<button class="btn btn-primary px-5">Save form</button>
						</p>
					</div>
				</form>
			</div>
			<div class="card bg-danger-lighter">
				<div class="card-header">
					<h5 class="my-0">Danger zone</h5>
				</div>

				<div class="card-body">
					<form @submit.prevent="updateFormSlug">
						<div class="form-group mb-3">
							<label for="form-slug" class="form-label">Form url</label>
							<p class="mb-2">
								⚠️ Previous links posted on website, facebook, etc will stop working. Please update them
								with the new link.
							</p>

							<div class="input-group mb-3">
								<span class="input-group-text">/{{ j.slug }}/forms/</span>
								<input
									type="text"
									class="form-control"
									id="form-slug"
									v-model="slug"
									required
									minlength="4"
								/>
							</div>
						</div>
						<p class="card-text">
							<button class="btn btn-outline-dark">
								Update link
							</button>
						</p>
					</form>

					<hr class="bg-gray my-4" />

					<h5>Archive form and applications</h5>
					<p class="card-text">
						Archive will hide the form from public view, and no new applications will be accepted. All
						current data will remain available for {{ j.type }} staff.
					</p>
					<p class="card-text">
						<button
							:disabled="form.status === 'archived'"
							class="btn btn-outline-danger"
							@click="archiveForm"
						>
							Archive form
						</button>
					</p>

					<div v-if="isStaff || !form.requests_count">
						<hr class="bg-gray my-4" />
						<h5>Delete form</h5>
						<p class="card-text">
							🚨 This action will delete <u>EVERYTHING</u> linked to this form: fields, applications,
							uploaded files and PDF templates.
						</p>

						<p class="card-text">
							<button class="btn btn-danger" @click="removeForm">Delete form</button>
						</p>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/assets/date-picker';
@import '@/assets/variables';

.icon-picker {
	padding: 5px;
	background-color: white;

	label {
		color: black;
		display: inline-block;
		text-align: center;
		cursor: pointer;
		width: 45px;
		height: 45px;
		border-radius: 6px;
		font-size: 30px;
		margin: 6px;

		&:hover {
			background-color: $neutral-100;
		}

		&.selected {
			background-color: $primary-100;
		}
	}
}
</style>

<script>
import heyGovApi from '@/api.js'
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import { format, parseISO } from 'date-fns'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import pluralize from 'pluralize'

import { shadeColor } from '@/utils.js'
import FormPageHead from '@/views/Forms/FormPageHead.vue'

export default {
	name: 'FormEdit',
	components: { FormPageHead, DatePicker },
	data() {
		return {
			form: null,
			state: 'loading',
			states: {
				fileUploading: false,
			},
			formIcons: [
				'paw',
				'home',
				'cocktail',
				'hand-paper',
				'tree',
				'ban',
				'sailboat',
				'fire',
				'burst',
				'faucet-drip',
				//'basketball-hoop',
				'road',
				'building-columns',
				'landmark-flag',
				//'siren',
				'calendar-days',
				'handshake-angle',
				//'shovel',
				'percent',
				'map',
			],
			isCalendarVisible: false,
			dateValue: '',
			longMonthName: '',
			slug: this.$route.params.formSlug,
		}
	},
	computed: {
		...mapState(['j', 'departments']),
		...mapGetters(['currentRole', 'activeDepartments', 'isStaff']),
		formColor() {
			let color = null

			if (this.departments && this.form.department_id) {
				const department = this.departments.find(d => d.id === this.form.department_id)
				if (department) {
					color = shadeColor(department.color, 50)
				}
			}

			return color
		},
		nextApplicationNumber() {
			let next = `${this.form.application_next_number}`

			if (this.form.application_prefix) {
				next = `${this.form.application_prefix}-${next}`
			}

			return next.replace('--', '-')
		},
		formFields() {
			return this.form.steps.map(s => s.fields).flat()
		},
	},

	created() {
		this.$store.dispatch('loadDepartments')
		this.getFormData()
	},

	methods: {
		pluralize,

		getDisabledDate(date) {
			return date < new Date()
		},
		getFormData() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.slug}/forms/${this.$route.params.formSlug}?expand=steps`).then(
				({ data }) => {
					if (data.published_at) {
						data.published_at = format(parseISO(data.published_at), 'yyyy-MM-dd')
					}

					this.form = data

					if (
						data.expiry_date &&
						data.expiry_date.includes('-') &&
						!['03-01', '06-30', '12-31'].includes(data.expiry_date)
					) {
						this.dateValue = data.expiry_date
						this.longMonthName = format(parseISO(this.dateValue), 'yyyy MMMM dd')
					}

					this.state = 'loaded'
				},
				error => {
					Vue.toasted.error(`Error loading the form (${error.message})`)
					this.state = 'error'
				}
			)
		},
		updateForm() {
			if (this.form.expiry_date === 'custom-date') {
				alert('Please select a date from the calendar or choose one of the options from the dropdown')
				return
			}

			heyGovApi
				.put(`/${this.j.slug}/forms/${this.form.slug}`, {
					name: this.form.name,
					description: this.form.description,
					department_id: this.form.department_id,
					icon: this.form.icon,
					published_at: this.form.published_at,
					expiry_date: this.form.expiry_date,
					auto_approve: this.form.auto_approve,
					has_certificate: this.form.has_certificate,
					approvedMessage: this.form.approvedMessage,
					endMessage: this.form.endMessage,
					deniedMessage: this.form.deniedMessage,
					textAction: this.form.textAction,
					type: this.form.type,
					application_prefix: this.form.application_prefix,
					application_next_number: this.form.application_next_number,
					submissions_manage_role: this.form.submissions_manage_role,
					info_column1: this.form.info_column1,
					info_column2: this.form.info_column2,
					geo_column: this.form.geo_column,
				})
				.then(
					() => {
						Vue.toasted.success(`"${this.form.name}" is updated`)
						this.isCalendarVisible = false
					},
					error => {
						const msg = error.response?.data?.title || error.message || 'Server error. Please try again'
						Vue.toasted.error(`Couldn't update form (${msg})`)
					}
				)
		},
		removeForm() {
			if (confirm('Last chance 🤷 Really delete everything?')) {
				heyGovApi.delete(`/${this.j.slug}/forms/${this.form.slug}`).then(() => {
					Vue.toasted.show(`"${this.form.name}" is removed`)
					this.$router.push(`/${this.j.slug}/forms`)
				}, Vue.toasted.error)
			}
		},
		uploadFile($event) {
			const file = $event.target.files[0]
			this.states.fileUploading = true

			// prepare file data
			var formFile = new FormData()
			formFile.append('file', file)

			// upload the file
			heyGovApi.post(`/${this.j.slug}/forms/${this.form.id}/file`, formFile).then(
				({ data }) => {
					this.form.upload_file = data.url
					this.states.fileUploading = false
				},
				error => {
					Vue.toasted.error(`Error uploading pdf ~ ${error.message}`)
					this.states.fileUploading = false
				}
			)
		},
		removePdf() {
			if (confirm('Really remove this file?')) {
				heyGovApi.delete(`/${this.j.slug}/forms/${this.form.id}/file`)
				this.form.upload_file = null
			}
		},
		getFormPrice() {
			let price = this.form.price
			let slice = price.split('-')
			const formatter = Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'usd',
			})

			if (slice.length == 2) {
				let priceFrom = formatter.format(slice[0])
				let priceTo = formatter.format(slice[1])
				return priceFrom + ' - ' + priceTo
			} else {
				let singlePrice = formatter.format(slice[0])
				return singlePrice
			}
		},
		handleDatePickerDaySelect() {
			this.form.expiry_date = this.dateValue // even though we could use value from from argument, we use this.dateValue to sync two date-pickers
			this.longMonthName = format(parseISO(this.dateValue), 'yyyy MMMM dd')
			this.isCalendarVisible = false
		},
		handleSelectChange(event) {
			this.isCalendarVisible = event.target.value === 'custom-date'
		},
		updateFormSlug() {
			if (this.form.slug === this.slug) {
				Vue.toasted.show(`Nothing to update 😑`)
			} else {
				heyGovApi
					.put(`/${this.j.slug}/forms/${this.form.slug}`, {
						slug: this.slug,
					})
					.then(
						({ data }) => {
							this.form.slug = data.slug
							//  Update form slug in url
							this.$router.push(`/${this.j.slug}/forms/${this.form.slug}/editForm`)
							Vue.toasted.show(`${this.form.name} slug is updated`)
						},
						error => {
							Vue.toasted.error(
								error.response?.data?.title || error.message || 'Server error. Please try again'
							)
							this.slug = this.$route.params.formSlug
						}
					)
			}
		},
		archiveForm() {
			if (confirm('For sure archive form?')) {
				heyGovApi
					.put(`/${this.j.slug}/forms/${this.form.slug}`, {
						status: 'archived',
					})
					.then(
						() => {
							this.$router.push(`/${this.j.slug}/forms/${this.form.slug}`)
							Vue.toasted.show(`"${this.form.name}" is archived`)
						},
						error => {
							Vue.toasted.error(error)
						}
					)
			}
		},
	},
}
</script>
