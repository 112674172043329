<template>
	<div>
		<div class="mb-3">
			Card readers:
			<span
				v-for="(reader, index) in terminalReaders"
				:key="reader.id"
				class="badge mx-1"
				:class="index ? 'bg-light text-dark' : 'bg-primary-50 text-primary-400'"
				>{{ reader.label }}</span
			>
			<span v-if="isStaff" class="badge bg-light text-gray mx-1">{{ terminalReaderState }}</span>
		</div>

		<template v-if="terminalError">
			<div class="alert alert-danger mb-3">{{ terminalError }}</div>

			<p class="text-center">
				<button class="btn btn-sm btn-outline-dark" @click="stripeTerminalDiscoverReaders">
					Try again
				</button>

				<button class="btn btn-sm btn-outline-dark mx-1" @click="$emit('payment-cancel')">
					Cancel payment
				</button>
			</p>
		</template>

		<p v-else-if="['discovering', 'connecting'].includes(terminalReaderState)" class="text-center py-4">
			Connecting to card readers <span class="spinner-border spinner-border-sm"></span>
		</p>

		<div v-else-if="terminalReaderState === 'no-readers'" class="">
			<p class="text-center card-text my-5">There are no connected card readers.</p>
			<div class="d-flex justify-content-between mb-3">
				<router-link
					:to="`/${j.slug}/payments/${payment.department_id}`"
					class="btn btn-sm btn-outline-dark mx-auto"
					>Connect a card reader</router-link
				>
				<button class="btn btn-sm btn-outline-dark mx-auto" @click="$emit('payment-cancel')">
					Close payment
				</button>
			</div>
		</div>

		<div v-else>
			<ol v-if="payment.status === 'requires_payment_method'" class="mb-3">
				<li>
					Awaiting card
					<span
						v-if="this.states.terminalCollectPayment === 'loading'"
						class="spinner-border spinner-border-sm"
					></span>
					<button
						v-if="this.states.terminalCollectPayment === 'loading'"
						class="btn btn-sm btn-outline-dark ms-2"
						@click="cancelPaymentInTerminal"
					>
						Cancel
					</button>
				</li>
				<li>Capturing payment</li>
				<li>Payment done</li>
			</ol>
			<ol v-else-if="payment.status === 'requires_capture'" class="mb-3">
				<li>Awaiting card ✅</li>
				<li>
					Capturing payment
					<span v-if="!paymentCardReaderError" class="spinner-border spinner-border-sm"></span>
				</li>
				<li>Payment done</li>
			</ol>
			<ol v-else-if="payment.status === 'succeeded'" class="mb-3">
				<li>Awaiting card ✅</li>
				<li>Capturing payment ✅</li>
				<li>Payment done ✅</li>
			</ol>

			<div v-if="paymentCardReaderError">
				<div class="alert alert-danger" v-html="paymentCardReaderError"></div>
			</div>

			<div v-if="this.states.terminalCollectPayment === 'error'" class="text-center">
				<button class="btn btn-sm btn-primary mx-1" @click="stripeTerminalCollectPayment">
					Try again
				</button>
				<button class="btn btn-sm btn-outline-dark mx-1" @click="$emit('payment-cancel')">
					Cancel payment
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import heyGovApi from '@/api.js'

export default {
	components: {},
	props: {
		payment: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			states: {
				paymentCardReader: 'idle',
				terminalCollectPayment: 'idle',
			},

			// Stripe Terminal
			terminal: null,
			terminalError: '',
			terminalReaderConnected: false,
			terminalReaderState: 'discovering',
			terminalReaders: [],
			paymentCardReaderError: '',
		}
	},
	computed: {
		...mapState(['j', 'stripePublishableTestKey', 'stripePublishableKey']),
		...mapGetters(['isStaff']),
	},
	created() {
		this.stripeTerminalStart()
	},
	methods: {
		stripeTerminalStart() {
			this.paymentCardReaderError = ''

			if (this.terminalReaderState !== 'connected') {
				this.terminal = window.StripeTerminal.create({
					onFetchConnectionToken: this.stripeTerminalConnectionToken,
					onUnexpectedReaderDisconnect() {
						// In this function, your app should notify the user that the reader disconnected.
						// You can also include a way to attempt to reconnect to a reader.
						this.terminalError = 'Disconnected from reader'
					},
					onConnectionStatusChange(status) {
						console.log('terminal status changed', status)
					},
				})

				this.stripeTerminalDiscoverReaders()
			}
		},
		cancelPaymentInTerminal() {
			this.terminal.cancelCollectPaymentMethod().then(() => {
				this.paymentCardReaderError = 'Payment was canceled'
				this.$emit('payment-cancel')
			})
		},
		async stripeTerminalConnectionToken() {
			const re = await heyGovApi(
				`${this.j.slug}/departments/${this.payment.department_id}/stripe-terminal-connection-token`
			)

			return re.data.secret
		},
		stripeTerminalDiscoverReaders() {
			var config = {
				simulated: ['localhost'].includes(window.location.hostname),
			}

			this.terminalError = ''
			this.terminalReaders = []
			this.terminalReaderState = 'discovering'

			this.terminal.discoverReaders(config).then(discoverResult => {
				if (discoverResult.error) {
					this.terminalError = discoverResult.error
				} else if (discoverResult.discoveredReaders.length === 0) {
					this.terminalReaderState = 'no-readers'
				} else {
					this.terminalReaders = discoverResult.discoveredReaders
					this.stripeTerminalConnectReader(this.terminalReaders[0])
				}
			})
		},
		stripeTerminalConnectReader(reader, cancelCurrentPaymentCollect = false) {
			if (cancelCurrentPaymentCollect) {
				this.cancelPaymentInTerminal()
			}

			this.terminalReaderState = 'connecting'

			this.terminal.connectReader(reader).then(connectResult => {
				if (connectResult.error) {
					this.terminalReaderState = 'error'
					this.terminalError = `Error connecting to "${reader.label}". ${connectResult.error.message}`
				} else {
					this.terminalReaderState = 'connected'
					this.terminalReaderConnected = reader.id
					console.log('terminal.connectReader', connectResult)
					this.stripeTerminalCollectPayment()
				}
			})
		},

		stripeTerminalCollectPayment() {
			//this.terminal.setSimulatorConfiguration({ testCardNumber: '4242424242424242' })
			//this.terminal.setSimulatorConfiguration({ testCardNumber: '4000000000009995' }) // card with insufficient funds

			this.states.terminalCollectPayment = 'loading'
			this.paymentCardReaderError = ''

			this.terminal.collectPaymentMethod(this.payment.paymentIntent.client_secret).then(result => {
				console.log('terminal.collectPaymentMethod', result)

				if (result.error) {
					this.paymentCardReaderError = result.error
					this.states.terminalCollectPayment = 'error'
				} else {
					this.terminal.processPayment(result.paymentIntent).then(result => {
						console.log('terminal.processPayment', result)

						if (result.error) {
							let errorCode = ''

							/* if (result.error.decline_code) {
								errorCode += ` (${result.error.decline_code})`
							}

							if (result.error.doc_url) {
								errorCode += ` <a href="${result.error.doc_url}" target="_blank">See detailed error info</a>`
							} */

							this.paymentCardReaderError = result.error.message + errorCode
							this.states.terminalCollectPayment = 'error'
						} else if (result.paymentIntent) {
							const cardCountry =
								result.paymentIntent.charges.data[0].payment_method_details.card_present.country

							if (cardCountry === 'US') {
								this.payment.status = result.paymentIntent.status
								this.payment.paymentIntent.status = result.paymentIntent.status
								this.states.terminalCollectPayment = 'success'
								this.terminalCapturePayment()
							} else {
								this.paymentCardReaderError = 'Payment error, non-US card used'
								this.states.terminalCollectPayment = 'error'
							}
						}
					})
				}
			})
		},
		terminalCapturePayment() {
			heyGovApi.post(`/${this.j.slug}/payments/${this.payment.uuid}/capture`).then(
				({ data }) => {
					this.payment.status = data.status

					this.$emit('payment-success', this.payment)
				},
				error => {
					this.paymentCardReaderError = error.message
				}
			)
		},
	},
}
</script>
