<template>
	<div class="mt-2 shadow rounded-1 p-2">
		<router-link :to="`events-embed/${event.uuid}`" class="text-decoration-none text-dark">
			<h6 class="hg-events-underline">{{ event.name }}</h6>
		</router-link>

		<small class="d-block py-2">
			{{ event.starts_at | dateLocal(undefined, { timeZone: j.timezone, dateStyle: 'long' }) }}
			@
			{{ getTime(event.starts_at) }}

			<span v-if="event.metadata && !event.metadata.hide_end_time">
				→
				<span v-if="isMultiDay">{{
					event.ends_at | dateLocal(undefined, { timeZone: j.timezone, dateStyle: 'long' })
				}}</span>
				{{ getTime(event.ends_at) }}</span
			>
			<span v-if="event.type === 'recurrent' || event.type === 'recurrence'" class="ms-1">
				<font-awesome-icon :icon="['fas', 'arrows-rotate']" />
			</span>
		</small>
		<span v-if="event.description">
			<small v-html="truncateString(event.description, 35)"></small>
		</span>
	</div>
</template>
<script>
import { mapState } from 'vuex'
import { truncateString } from '@/utils.js'
export default {
	name: 'EventDetails',
	props: {
		event: Object,
	},
	methods: {
		truncateString,
		getTime(time) {
			return new Date(time).toLocaleTimeString(undefined, {
				timeZone: this.j.timezone,
				hour: 'numeric',
				minute: 'numeric',
			})
		},
	},
	computed: {
		...mapState(['j']),
		isMultiDay() {
			if (
				new Date(this.event.starts_at).toLocaleDateString('en-US', {
					timeZone: this.j.timezone,
				}) !== new Date(this.event.ends_at).toLocaleDateString('en-US', { timeZone: this.j.timezone })
			) {
				return true
			}
			return false
		},
	},
}
</script>
