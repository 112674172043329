<template>
	<div v-if="!currentField.pdf" class="text-element-editor" v-html="currentField.value"></div>
</template>

<script>
export default {
	name: 'TextHtml',
	props: ['currentField'],
}
</script>
