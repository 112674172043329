<template>
	<div class="container mt-4">
		<div class="row justify-content-center">
			<div class="col-md-8">
				<div class="card">
					<div class="card-header">
						<h4 class="mb-0">Fulfill Agenda Item Request</h4>
					</div>
					<div class="card-body">
						<template v-if="isSubmitted">
							<div class="alert alert-success mb-3">
								Your agenda item has been submitted for review.
							</div>
						</template>
						<template v-else-if="!changesAllowed">
							<div class="alert alert-warning mb-3">
								The time to add something to the meeting has passed.
							</div>
						</template>
						<template v-else>
							<div v-if="$route.query.message" class="alert alert-info mb-3">
								{{ $route.query.message }}
							</div>
							<login-form
								v-if="!auth"
								@loginSuccess="onLoginSuccess"
								:redirectUrl="$route.fullPath"
								:allowRegistration="true"
								loginMessage="Great, logged in to your account"
							/>
							<add-agenda-item-form
								v-else
								:is-for-modal="false"
								:defaultSpeaker="auth"
								@submit="handleSubmit"
							/>
						</template>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'
import AddAgendaItemForm from '@/views/Meetings/AddAgendaItemForm/AddAgendaItemForm.vue'
import LoginForm from '@/components/LoginForm.vue'

export default {
	name: 'RequestedAgendaItemForm',
	components: { AddAgendaItemForm, LoginForm },
	data() {
		return { isSubmitted: false }
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['auth']),
		changesAllowed() {
			const now = new Date()
			const meetingStart = new Date(this.meeting.starts_at)
			if (now > meetingStart) {
				return false
			}
			if (!this.meeting.agenda_cut_off_hours) {
				return true
			}
			const cutoffTime = new Date(meetingStart.getTime() - this.meeting.agenda_cut_off_hours * 60 * 60 * 1000)
			return now < cutoffTime
		},
	},
	methods: {
		async handleSubmit(item, after) {
			try {
				const { meetingId } = this.$route.params
				const response = await heyGovApi.post(`${this.j.slug}/meetings/${meetingId}/agenda-items`, {
					...item,
					order: 0,
					parent_id: 0,
					status: 'needs_review',
				})

				if (item.files.length) {
					const formData = new FormData()
					item.files.forEach(file => formData.append('files', file))
					await heyGovApi.post(
						`${this.j.slug}/meetings/${meetingId}/agenda-items/${response.data.id}/files`,
						formData
					)
				}

				this.$toasted.success('Agenda item added successfully')
				this.$emit('submitRequestedAgendaItem')
				this.isSubmitted = true
			} catch (error) {
				handleResponseError('Error adding agenda item ({error})')(error)
			} finally {
				after()
			}
		},
		onLoginSuccess() {
			// Refresh the component or perform any necessary actions after successful login
			this.$forceUpdate()
		},
	},
}
</script>
