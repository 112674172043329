<template>
	<div class="page-quick-pay-billing-account">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/quick-pay`">Payments portal</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-1" />
			Payments
		</h3>

		<div class="row justify-content-center mb-3">
			<div class="col-lg-10 col-xl-8">
				<div v-if="!auth" class="card">
					<div class="card-body text-center">
						<p class="card-text mb-4">To see your payments history, please log in first.</p>
						<p class="card-text">
							<router-link to="/account/login" class="btn btn-primary px-5">Log in</router-link>
						</p>
					</div>
				</div>

				<div v-else class="card">
					<div class="card-body">
						<div class="card-table">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>Date</th>
										<th>Amount</th>
										<th>Fee</th>
										<th>Description</th>
									</tr>
								</thead>
								<tbody>
									<router-link
										v-for="payment in payments"
										:key="payment.id"
										:to="`/${j.slug}/quick-pay/payments/${payment.uuid}`"
										tag="tr"
										class="cursor-pointer"
									>
										<td>{{ payment.updated_at | dateLocal }}</td>
										<td>{{ payment.amount | currency }}</td>
										<td>{{ payment.fee | currency }}</td>
										<td>
											<payment-status
												v-if="payment.status !== 'succeeded' || payment.refunded"
												:payment="payment"
												class="me-1"
											/>
											<entity-link v-if="payment.source.includes('_')" :entity="payment.source">{{
												payment.source
											}}</entity-link>
											<span v-else>{{ payment.description }}</span>
										</td>
									</router-link>
								</tbody>
								<tfoot v-if="!payments.length">
									<tr v-if="states.payments === 'loaded'">
										<td colspan="6" class="text-neutral-400 text-center">
											<p>You haven't made any payments yet..</p>

											<router-link :to="`/${j.slug}/quick-pay`" class="btn btn-sm btn-primary"
												>Make a payment</router-link
											>
										</td>
									</tr>
									<tr v-else-if="states.payments === 'loading'">
										<td colspan="6" class="text-neutral-400 text-center">
											<span class="spinner-border spinner-border-sm"></span> Loading payments..
										</td>
									</tr>
									<tr v-else>
										<td colspan="6" class="text-danger-400 text-center">
											Error loading payments
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'

import heyGovApi from '@/api.js'

import EntityLink from '@/components/EntityLink.vue'
import PaymentStatus from '@/components/payments/PaymentStatus.vue'

export default {
	name: 'QuickPayPaymentsHistory',
	metaInfo: {
		title: 'Payments',
	},
	components: {
		EntityLink,
		PaymentStatus,
	},
	data() {
		return {
			states: {
				payments: 'loading',
			},
			payments: [],
		}
	},
	computed: {
		...mapState(['j', 'apiUrl']),
		...mapGetters(['auth']),
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.$store.dispatch('getForms')
		this.$store.dispatch('getVenues')

		if (this.auth) {
			this.loadPayments()
		}
	},
	methods: {
		loadPayments() {
			heyGovApi.get(`/${this.j.slug}/payments?own=1`).then(
				({ data }) => {
					this.payments.push(...data)
					this.states.payments = 'loaded'
				},
				error => {
					this.states.payments = 'error'
					Vue.toasted.error(`Error loading payments (${error.message})`)
				}
			)
		},
	},
}
</script>
