<template>
	<div class="venue-header">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/venues`">HeyReserve</router-link>
					<template v-if="venue.venue_group_id"
						><font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
						<router-link
							:to="`/${j.slug}/venues/group/${venueGroups.find(g => g.id == venue.venue_group_id).slug}`"
							>{{ venueGroups.find(g => g.id == venue.venue_group_id).name }}</router-link
						></template
					>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" /> {{ venue.name }}
					<small v-if="venue.status !== 'public'" class="badge" :class="[venueStatuses[venue.status].class]">
						{{ venueStatuses[venue.status].name }}
					</small>
				</h3>
			</div>
			<div class="col-auto d-none d-lg-block">
				<div class="dropdown">
					<button
						class="btn btn-sm py-1 btn-outline-dark dropdown-toggle"
						type="button"
						id="venue-actions-dropdown"
						data-bs-toggle="dropdown"
						aria-expanded="false"
					>
						⠇
					</button>
					<ul class="dropdown-menu" aria-labelledby="venue-actions-dropdown">
						<li>
							<router-link :to="`/${j.slug}/venues/${venue.slug}/settings`" class="dropdown-item"
								>Edit venue</router-link
							>
						</li>
						<li>
							<button class="dropdown-item" @click="duplicateVenue">Duplicate venue</button>
						</li>
						<li v-if="venue.status === 'public'">
							<button class="dropdown-item text-danger-400 disabled">Archive venue</button>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<nav v-if="currentRole !== 'CITIZEN'" class="mb-4">
			<ul
				class="nav nav-pills nav-pills-form hide-scrollbar mb-2"
				style="flex-wrap: nowrap;min-width: 100%;overflow-x: scroll;"
			>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/venues/${this.$route.params.venueSlug}`"
						class="nav-link"
						:class="{ active: $route.name === 'Venue' }"
						>Overview</router-link
					>
				</li>
				<li class="nav-item">
					<router-link :to="`/${j.slug}/venues/bookings?venue_id=${venue.id}`" class="nav-link"
						>Reservations
						<span class="badge bg-warning-lighter text-warning">{{
							venue.bookings_count
						}}</span></router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/venues/${this.$route.params.venueSlug}/availability`"
						class="nav-link"
						:class="{ active: $route.name === 'VenueAvailability' }"
						>Availability</router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/venues/${this.$route.params.venueSlug}/fields-pricing`"
						class="nav-link"
						:class="{ active: $route.name === 'VenueFieldsPricing' }"
						>Fields &amp; Pricing</router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/venues/${this.$route.params.venueSlug}/settings`"
						class="nav-link"
						:class="{ active: $route.name === 'VenueSettings' }"
						>Settings</router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/venues/${this.$route.params.venueSlug}/import`"
						class="nav-link text-neutral-200"
						:class="{ active: $route.name === 'VenueImport' }"
						>Import</router-link
					>
				</li>

				<li class="nav-item ms-auto">
					<router-link
						:to="`/${j.slug}/venues/${this.$route.params.venueSlug}/share`"
						class="nav-link"
						:class="{ active: $route.name === 'VenueShare' }"
						>Share <font-awesome-icon :icon="['fas', 'share']" class="text-neutral-300" />
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/venues/${this.$route.params.venueSlug}/info`"
						class="nav-link"
						:class="{ active: $route.name === 'VenuePublicPage' }"
						>Public page</router-link
					>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import { hgApi } from '@/api.js'
import { venueStatuses } from '@/actions/venues.js'

export default {
	name: 'VenueHeader',
	props: {
		venue: Object,
	},
	data() {
		return {
			venueStatuses,
		}
	},
	computed: {
		...mapState(['j', 'venueGroups']),
		...mapGetters(['currentRole', 'isStaff']),
	},
	created() {
		this.$store.dispatch('getVenueGroups')
	},
	mounted() {
		const $active = document.querySelector('.nav-pills-form .nav-link.active')
		if ($active) {
			$active.parentElement.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'end' })
		}
	},
	methods: {
		duplicateVenue() {
			const name = prompt(`Enter a name for the duplicated venue`, `${this.venue.name} (copy)`)

			if (name) {
				hgApi(`${this.j.slug}/venues/${this.venue.slug}/duplicate`, {
					json: { name },
				})
					.then(response => {
						if (response.ok) {
							return response.json()
						} else {
							throw new Error(response.statusText)
						}
					})
					.then(newVenue => {
						Vue.toasted.success(`Venue is duplicated as "${newVenue.name}"`)
						this.$router.push(`/${this.j.slug}/venues/${newVenue.slug}`)
						window.location.reload()
					})
					.catch(error => {
						Vue.toasted.error(`Error duplicating venue (${error.message})`)
					})
			}
		},
	},
}
</script>
