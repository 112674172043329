<template>
	<span>
		<strong>{{
			(payment.amount / 100).toLocaleString('en-US', {
				style: 'currency',
				currency: 'usd'
			})
		}}</strong>
		{{ ['card', 'transfer'].includes(payment.payment_method) ? 'online' : 'in person' }}
		<span v-if="payment.payment_method === 'card'"
			>with card
			<span v-if="payment.payment_method_details" class="border rounded px-1">
				<font-awesome-icon :icon="['fab', 'cc-' + payment.payment_method_details.brand]" />
				{{ payment.payment_method_details.last4 }}
			</span>
		</span>
		<span v-else-if="payment.payment_method === 'card_present'">with card</span>
		<span v-else-if="payment.payment_method === 'cash'">with cash</span>
		<span v-else-if="payment.payment_method === 'check'"
			>with check
			<span v-if="payment.note" class="border rounded px-1">
				<font-awesome-icon :icon="['fas', 'money-check-alt']" />
				{{ payment.note.slice(-4) }}
			</span></span
		>
		<span v-else>{{ payment }}</span>
	</span>
</template>

<script>
export default {
	name: 'PaymentFieldSummary',
	props: ['payment']
}
</script>
