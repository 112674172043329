<template>
	<div v-if="mode === 'preview'">
		<h2
			v-if="currentField.value && currentField.value.type === 'font'"
			class="bg-white border rounded-1 my-0 px-3 font-cursive"
		>
			{{ currentField.value.name }}
		</h2>
		<img
			v-else-if="currentField.value && currentField.value.type === 'draw'"
			:src="currentField.value.image"
			height="35"
			class="border rounded-1"
		/>
		<i v-else-if="!currentField.value" class="text-muted">No signature</i>
		<pre v-else>{{ currentField.value }}</pre>
	</div>
	<div v-else-if="!currentField.pdf" class="field-signature">
		<p v-if="currentField.data.prefillWithAccount">
			HeyGov generated a few signatures based on your name, but you can draw yours too.
		</p>
		<p v-else>Draw your signature</p>

		<div class="row justify-content-center gx-3">
			<div v-if="autofill" class="col-12 col-lg-5">
				<div
					class="bg-white text-center border rounded-1 cursor-pointer px-3 my-2"
					:class="{
						'border-primary': selectedSignature === 'font-full',
					}"
					@click="setFontSignature('full', nameForSignature)"
				>
					<h2 class="my-3 font-cursive">{{ nameForSignature }}</h2>
				</div>
			</div>

			<div v-if="autofill" class="col-lg-3">
				<div
					class="bg-white text-center border rounded-1 cursor-pointer px-3 my-2"
					:class="{
						'border-primary': selectedSignature === 'font-initials',
					}"
					@click="setFontSignature('initials', initialsForSignature)"
				>
					<h2 class="my-3 font-cursive">
						{{ initialsForSignature }}
					</h2>
				</div>
			</div>

			<div :class="'col'">
				<div
					class="bg-white text-center border rounded-1 cursor-pointer px-3 my-2"
					:class="{
						'border-primary': selectedSignature === 'draw',
					}"
					data-bs-toggle="offcanvas"
					:data-bs-target="`#offcanvas-${sigRef}`"
				>
					<img
						v-if="selectedSignature === 'draw'"
						:src="currentField.value.image"
						alt="Signature"
						height="60"
					/>
					<p v-else class="my-3 text-muted">
						🖌 Draw signature
					</p>
				</div>
			</div>
		</div>

		<div
			class="offcanvas offcanvas-bottom"
			tabindex="-1"
			:id="`offcanvas-${sigRef}`"
			aria-labelledby="offcanvas-sig-label"
			style="height: 360px"
		>
			<div class="offcanvas-header">
				<h5 class="offcanvas-title" id="offcanvas-sig-label">Your signature</h5>
				<button
					type="button"
					class="btn-close text-reset"
					data-bs-dismiss="offcanvas"
					aria-label="Close"
				></button>
			</div>
			<div class="offcanvas-body px-3 py-1">
				<div class="row justify-content-center">
					<div class="col-md-8 col-xl-6">
						<vue-signature-pad
							v-if="mounted"
							:ref="sigRef"
							class="bg-white rounded border mb-2"
							width="100%"
							height="220px"
						></vue-signature-pad>

						<div class="row">
							<div class="col">
								<p class="tsext-end">
									<button class="btn btn-sm px-1" type="button" @click="signatureUndo">
										Undo
									</button>
									<button
										class="btn btn-sm text-danger px-1 ms-1"
										type="button"
										@click="signatureClear"
									>
										Clear signature
									</button>
								</p>
							</div>
							<div class="col-auto">
								<button
									type="button"
									role="button"
									class="btn btn-sm btn-primary"
									@click="saveSignature"
								>
									Save signature
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { Offcanvas } from 'bootstrap'
import { VueSignaturePad } from 'vue-signature-pad'

import { generateId } from '@/utils'

export default {
	name: 'Signature',
	components: { VueSignaturePad },
	props: {
		currentField: Object,
		mode: String,
	},
	data() {
		return {
			$offcanvasSignature: null,
			mounted: false,
			sigRef: generateId('sig', 8),
		}
	},
	computed: {
		...mapState(['account']),
		nameForSignature() {
			return this.account?.name
		},
		initialsForSignature() {
			return this.account?.name
				?.split(' ')
				.map(n => n[0])
				.join(' ')
		},
		selectedSignature() {
			let selected = null

			if (this.currentField.value?.type === 'font') {
				selected = `${this.currentField.value.type}-${this.currentField.value.signatureType}`
			} else if (this.currentField.value?.type === 'draw') {
				selected = `draw`
			}

			return selected
		},
		autofill() {
			return this.nameForSignature && this.currentField.data.prefillWithAccount
		},
	},
	mounted() {
		const $offcanvasSignatureElement = document.getElementById(`offcanvas-${this.sigRef}`)

		if ($offcanvasSignatureElement) {
			this.$offcanvasSignature = new Offcanvas($offcanvasSignatureElement)
		}

		setTimeout(() => {
			this.mounted = true
		}, 300)
	},
	methods: {
		setFontSignature(signatureType, name) {
			this.$set(this.currentField, 'value', {
				type: 'font',
				signatureType,
				name,
			})
		},
		saveSignature() {
			const { isEmpty, data } = this.$refs[this.sigRef].saveSignature()

			if (isEmpty) {
				alert('Please draw your signature')
			} else {
				// save signature as image & svg data
				this.$set(this.currentField, 'value', {
					type: 'draw',
					image: data,
					data: this.$refs[this.sigRef].toData(),
				})

				this.$offcanvasSignature.hide()
			}
		},

		signatureUndo() {
			this.$refs[this.sigRef].undoSignature()
		},
		signatureClear() {
			this.$refs[this.sigRef].clearSignature()
		},
	},
}
</script>
