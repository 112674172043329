<template>
	<div class="page-payments-quickpay-settings">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h3 class="my-0">
					<router-link :to="`/${j.slug}/payments`">HeyGov Pay</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" /> Payments portal
					settings
				</h3>
			</div>
			<div class="col-auto">
				<router-link :to="`/${j.slug}/quick-pay`" class="btn btn-sm btn-outline-primary"
					>👀 View Payments Portal</router-link
				>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<p class="card-text">
					Use this feature to enable online payments for bills, taxes or even donations by your citizens. When
					enabled, a payment page will be available on HeyGov website and mobile app.
				</p>
				<p class="card-text">
					Contact <a href="mailto:support@heygov.com" class="text-highlight">support@heygov.com</a> if you
					want it embedded on your website.
				</p>

				<form @submit.prevent="savePaymentsSettings">
					<div class="form-check form-switch mb-3">
						<input
							class="form-check-input"
							type="checkbox"
							role="switch"
							v-model="payments_options.enabled"
							id="bills-ps"
						/>
						<label class="form-check-label" for="bills-ps">Enable Payments Portal</label>
					</div>

					<div :class="{ 'disabled opacity-50': !payments_options.enabled }">
						<div class="form-group mb-3">
							<label class="form-label" for="payments-title">Title for Payments Portal</label>
							<input
								type="text"
								class="form-control"
								id="payments-title"
								v-model="payments_options.title"
								required
								placeholder="ex: Bills payment, Utility payments, etc"
							/>
						</div>

						<div class="form-group mb-3">
							<label class="form-label" for="payments-description">Description for Payments Portal</label>
							<textarea
								class="form-control"
								id="payments-description"
								v-model="payments_options.description"
								placeholder="We recommend a short and clear text"
							/>
						</div>

						<div class="form-group mb-3">
							<label class="form-label" for="payments-video">Payments how-to video</label>
							<input
								type="url"
								class="form-control"
								id="payments-video"
								v-model="payments_options.video"
								placeholder="YouTube video URL"
							/>
						</div>

						<div class="card-table">
							<table class="table table-hover mb-5">
								<thead>
									<tr>
										<th>Payment type</th>
										<th>Department</th>
										<th>Help text</th>
										<th>Installments</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="type in payments_options.types"
										:key="`pt-type-${type.value}`"
										class="on-parent"
									>
										<td>
											<input
												type="text"
												class="form-control form-control-sm"
												placeholder="Payment type"
												v-model="type.name"
												required
											/>
										</td>
										<td>
											<select
												class="form-select form-select-sm"
												v-model="type.department"
												required
												style="max-width: 200px"
											>
												<option :value="null"></option>
												<option
													v-for="department in paymentDepartments"
													:key="`p-dept-${department.id}`"
													:value="department.id"
													>{{ department.name }}</option
												>
											</select>
										</td>
										<td>
											<input
												type="text"
												class="form-control form-control-sm"
												placeholder="Help text for payment type"
												v-model="type.help"
												required
											/>
										</td>
										<td>
											<div class="form-check">
												<input
													class="form-check-input"
													type="checkbox"
													v-model="type.installments"
													:id="`pt-type-${type.value}-installments`"
												/>
												<label
													class="form-check-label"
													:for="`pt-type-${type.value}-installments`"
												>
													Installments
												</label>
											</div>
											<p
												v-if="type.installments"
												class="mb-0 text-muted"
												@click="
													type.installments_2_disclaimer = prompt(
														'2nd installment disclaimer',
														type.installments_2_disclaimer
													)
												"
											>
												2nd disclaimer:
												<span v-if="type.installments_2_disclaimer" class="text-dark">{{
													truncateString(type.installments_2_disclaimer, 10)
												}}</span>
												<span v-else class="text-muted">-</span>
											</p>
										</td>
										<td>
											<a
												:href="`/${j.slug}/quick-pay/pay?pay=${type.value}`"
												target="heygov-quickpay"
												class="btn btn-sm btn-icon-info"
											>
												<font-awesome-icon :icon="['fas', 'arrow-up-right-from-square']" />
											</a>
											<button
												type="button"
												@click="removePaymentType(type.value)"
												class="btn btn-sm btn-icon-danger show-on-hover ms-2"
											>
												<font-awesome-icon :icon="['fas', 'times']" />
											</button>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td colspan="5">
											<button
												type="button"
												@click="addPaymentType"
												class="btn btn-sm btn-big-icon btn-outline-info"
											>
												Add payment type <font-awesome-icon :icon="['fas', 'plus-circle']" />
											</button>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>

					<p class="card-text text-center">
						<button class="btn btn-primary px-5">Save settings</button>
					</p>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { truncateString } from '@/utils'

export default {
	name: 'QuickPaySettings',
	metaInfo: {
		title: `QuickPay settings`,
	},
	data() {
		return {
			payments_options: {},
		}
	},
	computed: {
		...mapState(['j', 'departments']),
		paymentDepartments() {
			return (this.departments || []).filter(d => d.stripe_account || d.justifi_account || d.municipay_account)
		},
	},
	created() {
		this.$store.dispatch('loadDepartments')

		this.payments_options = {
			enabled: false,
			title: '',
			description: '',
			types: [
				{
					value: 'utility-bill',
					name: 'Utility bill',
					department: null,
					help: 'Utility bill type & number',
					installments: false,
				},
				{
					value: 'income-tax',
					name: 'Income tax',
					department: null,
					help: 'Tax invoice account & number',
					installments: true,
				},
			],
			fields: ['name', 'email', 'details', 'amount', 'signature'],
			...(this.j.features_options.payments_options || {}),
		}
	},
	methods: {
		truncateString,
		prompt(text, def) {
			return prompt(text, def || '')
		},

		addPaymentType() {
			this.payments_options.types.push({
				value: `type-${Math.floor(Math.random() * 99999)}`,
				name: '',
				help: '',
				installments: false,
			})
		},
		removePaymentType(id) {
			if (confirm('For sure remove this payment type?')) {
				this.payments_options.types = this.payments_options.types.filter(type => type.value !== id)
			}
		},
		savePaymentsSettings() {
			this.$store.commit('updateCurrentJurisdiction', {
				features_options: { ...this.j.features_options, payments_options: this.payments_options },
			})
			this.$store.dispatch('saveCurrentJurisdiction', { message: 'QuickPay settings are saved' }).then(() => {
				// hide QuickPay settings modal
			})
		},
	},
	watch: {},
}
</script>
