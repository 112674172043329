<template>
	<router-link v-if="url" :to="url">{{ label }}</router-link>
</template>

<script>
import heyGovApi from '@/api.js'
import { mapState } from 'vuex'

export default {
	name: 'EntityLink',
	props: {
		entity: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			url: null,
			label: '',
			entityEndpoints: {
				fr_: 'form-requests',
				vb_: 'venue-bookings',
				ba_: 'billing-accounts',
				bl_: 'billing-accounts/bills',
			},
		}
	},
	computed: {
		...mapState(['j', 'forms', 'venues']),
	},
	created() {
		const entityPrefix = this.entity.slice(0, 3)

		if (this.entityEndpoints[entityPrefix]) {
			heyGovApi(`/${this.j.slug}/${this.entityEndpoints[entityPrefix]}/${this.entity}`).then(
				({ data }) => {
					if (this.entity.startsWith('vb_')) {
						const venue = this.venues.find(v => v.id === data.venue_id)

						const bookingDate = new Date(data.starts_at).toLocaleDateString('default', {
							timeZone: this.j.timezone,
							dateStyle: 'short',
						})
						this.url = `/${this.j.slug}/venues/${venue.slug}/bookings/${data.uuid}`
						this.label = `${venue.name} - ${bookingDate}`
					} else if (this.entity.startsWith('fr_')) {
						const form = this.forms.find(f => f.id === data.form_id)

						this.url = `/${this.j.slug}/forms/${form.slug}/request-review/${data.uuid}`
						this.label = data.application_number ? data.application_number : `${form.name} ${data.id}`
					} else if (this.entity.startsWith('ba_')) {
						this.url = `/${this.j.slug}/payments/${data.department_id}/billing-accounts/${data.uuid}`
						this.label = `Billing Account ${data.account_number}`
					} else if (this.entity.startsWith('bl_')) {
						this.url = `/${this.j.slug}/payments/${data.billingAccount.department_id}/billing-accounts/${data.billingAccount.uuid}`
						this.label = `Bill ${data.bill_number} - Account ${data.billingAccount.account_number}`
					}
				},
				error => {
					console.log(`Couldn't load entity ${this.entity}, ${error.message}`)
				}
			)
		} else {
			console.warn('unknown EntityLink', this.entity)
		}
	},
}
</script>
