<template>
	<router-link
		:to="bookLink"
		class="card card-link card-venue rounded-1 p-2 text-dark"
		:class="{ disabled: !availability.available }"
	>
		<div class="card-image">
			<img
				:src="venue.main_image || '/images/placeholder.jpg'"
				class="card-img-top rounded-1"
				:alt="venue.name"
			/>
		</div>

		<div class="card-body px-0 py-2">
			<h5 class="card-title my-0">{{ venue.name }}</h5>

			<div v-if="venue.attributes.length" class="row row-cols row-cols-2 mt-0">
				<div v-for="attr in venue.attributes" :key="attr.id" class="mt-1">
					{{ attr.label }}: <strong>{{ attr.value }}</strong>
				</div>
			</div>
		</div>

		<div class="card-footer px-0 pb-0 pt-2 text-center">
			<button v-if="availability.available" class="btn btn-sm btn-primary">
				Book now
				<span class="ms-2 text-primary-50"
					><template v-if="venue.price_conditions.length || availability.slot.deposit">from </template
					>{{ availability.price | currency((currency = 'usd'), (locale = 'en-US'), true) }}</span
				>
			</button>
			<span class="text-danger-400">{{ availability.reason }}</span>
		</div>
	</router-link>
</template>

<style scoped lang="scss">
.card-link.disabled {
	pointer-events: none;
	opacity: 0.8;
}

.card-img-top {
	aspect-ratio: 3 / 2;
	object-fit: cover;
}
</style>

<script>
import { format } from 'date-fns'
import { mapState } from 'vuex'

export default {
	name: 'VenueCardBook',
	props: {
		venue: {
			type: Object,
			required: true,
		},
		availability: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapState(['j']),
		bookLink() {
			let url = `/${this.j.slug}/venues/${this.venue.slug}/booking/`

			if (this.availability.slot?.type === 'dates') {
				url += `${format(new Date(), 'yyyy')}/${this.availability.slot.id}`
			} else if (this.availability.slot) {
				url += `${this.availability.start}:${this.availability.end}/${this.availability.slot.id}`
			}

			return url
		},
	},
}
</script>
