<template>
	<span v-if="pp" class="cursor-pointer text-info hover-underline" @click="openPreview">
		<person-avatar v-if="avatar" :person="pp" :size="avatar" :preview="false" :tooltip="false"></person-avatar>
		{{ pp.name || pp.email }}</span
	>
</template>

<script>
import { mapState } from 'vuex'
import { Offcanvas } from 'bootstrap'
import PersonAvatar from '@/components/PersonAvatar.vue'

export default {
	name: 'PersonLink',
	components: { PersonAvatar },
	props: {
		avatar: {
			type: Number,
			default: null,
		},
		id: {
			type: Number,
			default: null,
		},
		person: {
			type: Object,
			default: null,
		},
		preview: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			p: null,
		}
	},
	computed: {
		...mapState(['j', 'people']),
		pp() {
			return this.person || this.people[this.p]
		},
	},
	created() {
		if (this.person) {
			this.p = this.person.id
		} else if (this.id) {
			this.$store.dispatch('loadPerson', this.id)
			this.p = this.id
		}
	},
	methods: {
		openPreview($event) {
			if (this.preview) {
				$event.preventDefault()
				$event.stopPropagation()

				this.$store.commit('setPreviewPersonId', this.p)

				const of = new Offcanvas(document.getElementById('person-preview'))
				of.show()
			} else {
				this.$router.push(`/${this.j.slug}/people/${this.p}`)
			}
		},
	},
}
</script>
