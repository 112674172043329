<template>
	<div class="page-venue-group-settings">
		<div class="card mb-4">
			<div class="card-header">
				<h5 class="card-title my-0">Venue Group settings</h5>
			</div>
			<div class="card-body">
				<form @submit.prevent="saveSettings">
					<div class="row row-cols-1 row-cols-md-2 mb-3">
						<div class="col form-group mb-3">
							<label class="form-label" for="venue-group-name">Name</label>
							<input
								class="form-control"
								id="venue-group-name"
								v-model="venueGroup.name"
								required
								minlength="3"
							/>
						</div>

						<div class="col form-group mb-3">
							<label class="form-label" for="venue-group-label">Venue label</label>
							<input
								class="form-control"
								id="venue-group-label"
								v-model="venueGroup.venue_label"
								placeholder="Specific name for venues, ex: Boat slip, Site, Pavilion"
							/>
						</div>

						<div class="col form-group mb-3">
							<label class="form-label" for="venue-group-department">Department</label>
							<select class="form-select" id="venue-group-department" v-model="venueGroup.department_id">
								<option v-for="department in departments" :key="department.id" :value="department.id">{{
									department.name
								}}</option>
							</select>
						</div>

						<div class="col form-group mb-3">
							<label class="form-label" for="venue-group-map">Map</label>
							<select class="form-select" id="venue-group-map" v-model="venueGroup.map">
								<option value="hidden">No map</option>
								<option value="roadmap">Roadmap (standard)</option>
								<option value="satellite">Satellite</option>
								<option value="hybrid">Hybrid</option>
							</select>
						</div>

						<div class="col form-group mb-3">
							<label class="form-label" for="venue-slot-type">Available slot types</label>
							<select class="form-select" id="venue-slot-type" v-model="venueGroup.slot_type">
								<option value="multi">Multiple days</option>
								<option value="both">Multiple days + Seasonal</option>
							</select>
						</div>
					</div>

					<p class="card-text text-center">
						<button class="btn btn-primary px-5" :disabled="states.settings === 'saving'">
							Save settings
						</button>
					</p>
				</form>
			</div>
		</div>

		<div class="card mb-6">
			<div class="card-header">
				<h5 class="card-title my-0">Text &amp; Messages</h5>
			</div>
			<div class="card-body">
				<form @submit.prevent="saveMessages">
					<div class="form-group mb-3">
						<label class="form-label" for="venue-group-message-welcome">Welcome message</label>
						<input
							class="form-control"
							id="venue-group-message-welcome"
							v-model="venueGroup.message_welcome"
							required
							minlength="5"
						/>
					</div>

					<p class="card-text text-center">
						<button class="btn btn-primary px-5" :disabled="states.messages === 'saving'">
							Save messages
						</button>
					</p>
				</form>
			</div>
		</div>

		<div class="card">
			<div class="card-header bg-danger-50 text-danger-400">
				<h5 class="card-title my-0">Danger zone</h5>
			</div>
			<div class="card-body">
				<form @submit.prevent="updateSlug">
					<div class="form-group mb-3">
						<label for="venue-group-slug" class="form-label">Venue Group URL</label>
						<p class="mb-2">
							⚠️ Previous links posted on website, facebook, etc will stop working. Please update them
							with the new link.
						</p>
						<div class="input-group mb-3">
							<span class="input-group-text">app.heygov.com/{{ j.slug }}/venues/group/</span>
							<input
								type="text"
								class="form-control"
								id="venue-group-slug"
								required
								minlength="4"
								v-model="slug"
							/>
						</div>
					</div>
					<p class="card-text">
						<button class="btn btn-outline-dark" :disabled="true">Update link</button>
					</p>
				</form>

				<hr class="bg-gray my-4" />

				<h5>Archive venue group and reservations</h5>
				<p class="card-text">
					Archive will hide the venue group & venues from public view, and no new reservations will be
					accepted. All data will remain available for {{ j.type }} staff.
				</p>
				<p class="card-text">
					<button
						:disabled="1 || venueGroup.status === 'archived'"
						class="btn btn-outline-danger"
						@click="archiveVenue"
					>
						Archive venue
					</button>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import heyGovApi, { handleResponseError } from '@/api.js'

export default {
	name: 'VenueGroupSettings',
	components: {},
	props: {
		venueGroup: {
			type: Object,
			required: true,
		},
		venues: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			states: {
				settings: 'idle',
				messages: 'idle',
			},
			slug: this.venueGroup.slug,
			settingsError: '',
		}
	},
	computed: {
		...mapState(['j', 'departments']),
		...mapGetters(['currentRole', 'isStaff']),
	},
	created() {
		this.$store.dispatch('loadDepartments')
	},
	methods: {
		saveSettings() {
			this.states.settings = 'saving'

			heyGovApi
				.put(`${this.j.slug}/venue-groups/${this.venueGroup.slug}`, {
					name: this.venueGroup.name,
					department_id: this.venueGroup.department_id,
					map: this.venueGroup.map,
					venue_label: this.venueGroup.venue_label,
					slot_type: this.venueGroup.slot_type,
				})
				.then(() => {
					this.states.settings = 'saved'
					Vue.toasted.success('Settings saved')
				}, handleResponseError('Error updating venue group settings ({error})'))
				.finally(() => {
					this.states.settings = 'idle'
				})
		},
		saveMessages() {
			this.states.messages = 'saving'

			this.$emit('updateVenueGroup', {
				fields: {
					message_welcome: this.venueGroup.message_welcome,
				},
				message: 'Messages saved',
			})

			setTimeout(() => {
				this.states.messages = 'idle'
			}, 3000)
		},

		archiveVenue() {
			this.$emit('updateVenueGroup', {
				fields: {
					status: 'archived',
				},
				message: 'Venue group is marked as archived',
			})
		},
	},
}
</script>
