<template>
	<div class="page-home">
		<h1>HeyGov Home</h1>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'Home',
	data() {
		return {
			state: 'idle',
			error: '',
		}
	},
	computed: {
		...mapState(['j', 'jurisdictions']),
	},
	created() {
		if (this.j && this.j.role && ['WORKER', 'EDITOR', 'ADMIN'].includes(this.j.role)) {
			this.$router.push(`/${this.j.slug}/dashboard`)
		} else if (this.j) {
			this.$router.push(`/${this.j.slug}`)
		} else {
			this.$router.push(`/select-municipality`)
		}
	},
	methods: {},
}
</script>
