<template>
	<div class="page-quick-pay-billing-account">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/quick-pay`">Payments portal</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-1" />
			Account {{ billingAccount ? billingAccount.account_number : '...' }}
		</h3>

		<div class="row justify-content-center mb-3">
			<div class="col-md-10 col-lg-8 col-xl-6">
				<div v-if="billingAccount">
					<div class="card mb-4">
						<div class="card-body">
							<h4 class="mb-3">
								Billing account <code>{{ billingAccount.account_number }}</code>
							</h4>

							<p class="mb-2"><span class="text-neutral-400">Name:</span> {{ billingAccount.name }}</p>
							<p class="mb-2">
								<span class="text-neutral-400">Address:</span>
								{{ billingAccount.address | formatAddress }}
							</p>
							<p class="card-text">
								<span class="text-neutral-400">Balance:</span> {{ billingAccount.balance | currency }}
								<span v-if="billingAccount.balance < 0" class="text-muted ms-1"
									>This will be credited to the next bill</span
								>
							</p>
							<p v-if="billingAccount.balance > 0.5" class="card-text">
								<router-link
									:to="`/${j.slug}/quick-pay/pay?account=${billingAccount.uuid}`"
									class="btn btn-sm btn-primary"
									>Pay now</router-link
								>
							</p>
						</div>
					</div>

					<ul class="nav nav-pills nav-pills-form nav-billing mx-auto mb-2">
						<li class="nav-item">
							<span
								class="nav-link cursor-pointer"
								:class="{ active: states.view === 'bills' }"
								@click="states.view = 'bills'"
								>Bills</span
							>
						</li>
						<li class="nav-item">
							<span
								class="nav-link cursor-pointer"
								:class="{ active: states.view === 'activity' }"
								@click="states.view = 'activity'"
								>History</span
							>
						</li>
					</ul>

					<template v-if="states.view === 'bills'">
						<div
							v-for="(bill, billIndex) in billingAccount.bills"
							:key="bill.id"
							class="card bg-light mb-3"
						>
							<div class="card-body">
								<div class="row">
									<div class="col-lg-6">
										<p class="lead mb-2">
											<span class="text-neutral-400">Bill date:</span>
											{{ bill.bill_date | dateLocal }}
										</p>
										<p class="mb-2"><span class="text-neutral-400">Name:</span> {{ bill.name }}</p>
										<p class="mb-2">
											<span class="text-neutral-400">Address:</span>
											{{ bill.address | formatAddress }}
										</p>
									</div>
									<div class="col-lg-6">
										<p class="mb-2">
											<span class="text-neutral-400">Due date:</span>
											{{ bill.bill_due_date | dateLocal('en-US', { timeZone: j.timezone }) }}
										</p>
										<p v-if="bill.bill_period_start" class="mb-2">
											<span class="text-neutral-400">Period start:</span>
											{{ bill.bill_period_start | dateLocal('en-US', { timeZone: j.timezone }) }}
										</p>
										<p v-if="bill.bill_period_end" class="mb-2">
											<span class="text-neutral-400">Period end:</span>
											{{ bill.bill_period_end | dateLocal('en-US', { timeZone: j.timezone }) }}
										</p>
									</div>
								</div>

								<div class="card-table mb-3 mt-3">
									<table class="table">
										<tbody>
											<tr
												v-for="lineItem in bill.line_items"
												:key="`bill-${bill.id}-${lineItem.name}`"
											>
												<td>{{ lineItem.name }}</td>
												<td>{{ lineItem.amount | currency }}</td>
											</tr>
											<tr>
												<td class="text-muted">Total</td>
												<td>
													<p class="mb-1">
														<strong>{{ bill.amount | currency }}</strong>
													</p>

													<p
														v-for="payment in bill.payments"
														:key="`bill-${bill.id}-payment-${payment.id}`"
														class="mb-1"
													>
														<span class="badge bg-success-50 text-success-300">
															Paid {{ payment.amount | currency }} (+{{
																payment.fee | currency
															}}
															fee) on
															{{ payment.captured_at || payment.updated_at | dateLocal }}
														</span>
													</p>

													<p
														v-if="
															bill.status !== 'past_due_no_pay' &&
																billIndex === 0 &&
																sum(bill.payments.map(p => p.amount)) < bill.amount
														"
														class="mb-0"
													>
														<router-link
															:to="
																`/${j.slug}/quick-pay/pay?account=${billingAccount.uuid}&bill=${bill.uuid}`
															"
															class="btn btn-sm btn-primary"
															>Pay bill now</router-link
														>
													</p>
												</td>
											</tr>
										</tbody>
										<tfoot>
											<tr v-if="bill.status === 'past_due' && billIndex === 0">
												<td colspan="2" class="text-end">
													<span class="card-text text-primary-400">
														You will be informed about your late fee by your clerk
													</span>
												</td>
											</tr>
											<tr v-else-if="bill.status !== 'paid'">
												<td colspan="2" class="text-end">
													<small class="text-neutral-300"
														>Online payment fee is not included in total</small
													>
												</td>
											</tr>
										</tfoot>
									</table>
								</div>

								<p class="card-text text-neutral-500" v-html="bill.text.replace(/\n/g, '<br>')"></p>
							</div>
						</div>
					</template>
					<div v-else class="card">
						<div class="card-body">
							<div class="card-table">
								<table class="table table-hover">
									<tbody>
										<tr v-for="activity in billingAccount.activities" :key="activity.id">
											<td>
												<small class="text-neutral-400">{{
													activity.created_at | dateLocal
												}}</small>
											</td>
											<td>{{ activity.message }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div v-else-if="states.billingAccount === 'loading'" class="text-center py-5">
					Loading billing account details..
				</div>

				<div v-else class="text-center py-5">
					Error loading billing account info
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
.nav-billing {
	width: 200px;
}
.nav-billing .nav-link {
	width: 94px;
	text-align: center;
}
</style>

<script>
import { mapState } from 'vuex'
import { sum } from 'lodash-es'
import Vue from 'vue'

import heyGovApi from '@/api.js'

export default {
	name: 'QuickPayBillingAccount',
	data() {
		return {
			states: {
				billingAccount: 'loading',
				view: 'bills',
			},
			billingAccount: null,
		}
	},
	computed: {
		...mapState(['j']),
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadBillingAccount()
	},
	methods: {
		sum,

		loadBillingAccount() {
			heyGovApi
				.get(
					`/${this.j.slug}/billing-accounts/${this.$route.params.accountUuid}?expand=bills,activities,bills.payments`
				)
				.then(
					({ data }) => {
						this.billingAccount = data
						this.states.billingAccount = 'loaded'
					},
					error => {
						this.states.billingAccount = 'error'
						Vue.toasted.error(`Error loading payment info (${error.message})`)
					}
				)
		},
	},
}
</script>
