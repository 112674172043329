<template>
	<div class="page-venue-group-bookings">
		<div class="card mb-4">
			<div class="card-body">
				<div class="card-table mb-3">
					<table class="table table-hover">
						<thead>
							<tr class="text-uppercase text-muted fw-normal">
								<td class="px-0">
									<label class="ps-3 pe-2" for="select-all-submissions">
										<input
											type="checkbox"
											id="select-all-reservations"
											ref="selectAllReservations"
											v-model="allReservationsSelected"
											@click.prevent="selectAll"
										/>
									</label>
								</td>
								<td v-if="tableColumns.includes('venue_id')">Boat slip</td>
								<td
									v-if="tableColumns.includes('starts_at')"
									@click="sortTable('starts_at', 'desc')"
									class="hover cursor-pointer"
								>
									Date
									<span v-if="sorting.orderBy === 'starts_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</span>
								</td>
								<td v-if="tableColumns.includes('person_id')">Guest</td>
								<td v-if="tableColumns.includes('status')">Status</td>
								<td
									v-if="tableColumns.includes('price')"
									@click="sortTable('price', 'desc')"
									class="hover cursor-pointer"
								>
									Price
									<span v-if="sorting.orderBy === 'price'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</span>
								</td>
								<td v-if="tableColumns.includes('payment_status')">Payment status</td>
								<td
									v-if="tableColumns.includes('deposit')"
									@click="sortTable('deposit', 'desc')"
									class="hover cursor-pointer"
								>
									Deposit
									<span v-if="sorting.orderBy === 'deposit'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</span>
								</td>
								<td v-if="tableColumns.includes('deposit_status')">Deposit status</td>

								<td
									v-if="tableColumns.includes('booked_at')"
									@click="sortTable('booked_at', 'desc')"
									class="hover cursor-pointer"
								>
									Booked on
									<span v-if="sorting.orderBy === 'booked_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</span>
								</td>
							</tr>
						</thead>
						<tbody>
							<template v-for="booking in bookings">
								<router-link
									v-if="venues.length"
									:key="booking.id"
									class="on-parent"
									:class="{ 'bg-primary-50': selectedReservations.includes(booking.id) }"
									:to="
										`/${j.slug}/venues/${
											venues.find(v => v.id === booking.venue_id).slug
										}/bookings/${booking.uuid}`
									"
									tag="tr"
								>
									<td class="py-1 px-0">
										<label
											class="py-1 ps-3 pe-2"
											:for="`vb-check-${booking.id}`"
											@click="$event.stopPropagation()"
										>
											<input
												type="checkbox"
												v-model="selectedReservations"
												:value="booking.id"
												:id="`vb-check-${booking.id}`"
											/>
										</label>
									</td>
									<td v-if="tableColumns.includes('venue_id')">
										<router-link
											v-if="venues.length"
											:to="
												`/${j.slug}/venues/${venues.find(v => v.id === booking.venue_id).slug}`
											"
											>{{ venues.find(v => v.id === booking.venue_id).name }}</router-link
										>
									</td>
									<td v-if="tableColumns.includes('starts_at')">
										{{
											new Date(booking.starts_at).toLocaleString('en-US', {
												dateStyle: 'short',
												timeStyle: 'short',
												timeZone: j.timezone,
											})
										}}
									</td>
									<td v-if="tableColumns.includes('person_id')">
										<person-link :id="booking.person_id" :avatar="16">{{
											booking.person_id
										}}</person-link>
									</td>
									<td v-if="tableColumns.includes('status')">
										<venue-booking-status
											:venueBooking="booking"
											:tooltip="true"
										></venue-booking-status>
									</td>
									<td v-if="tableColumns.includes('price')" class="text-end">
										<span :class="{ 'text-neutral-400': !booking.price }">{{
											booking.price | currency
										}}</span>
									</td>
									<td v-if="tableColumns.includes('payment_status')">
										<div v-if="booking.payment">
											<span class="badge" :class="paymentStatuses[booking.payment].class">{{
												paymentStatuses[booking.payment].name
											}}</span>
										</div>
										<div v-else class="d-none"></div>
									</td>
									<td v-if="tableColumns.includes('deposit')" class="text-end">
										<span :class="{ 'text-neutral-400': !booking.deposit }">{{
											booking.deposit | currency
										}}</span>
									</td>
									<td v-if="tableColumns.includes('deposit_status')">
										<span
											v-if="paymentStatuses[booking.deposit_status]"
											class="badge"
											:class="paymentStatuses[booking.deposit_status].class"
											>{{ paymentStatuses[booking.deposit_status].name }}</span
										>
									</td>

									<td v-if="tableColumns.includes('booked_at')">
										{{ booking.booked_at || booking.created_at | dateLocal }}
									</td>
								</router-link>
							</template>
						</tbody>
						<tfoot>
							<tr v-if="states.bookings === 'loading'">
								<td colspan="10" class="text-center py-5">
									<span class="spinner-border spinner-border-sm" role="status"></span> Loading
									reservations..
								</td>
							</tr>
							<tr v-else-if="!bookings.length">
								<td colspan="10" class="text-center py-5">
									<div v-if="hasActiveFilters">
										<img
											src="https://files.heygov.com/illustrations/illustration-arms-documents.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead">No reservations found. Try changing the filters</p>
									</div>
									<div v-else>
										<img
											src="https://files.heygov.com/illustrations/illustration-building-people.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead">Awaiting reservations</p>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<div v-if="pag.total" class="row">
					<div class="col">
						<span class="me-2 text-muted">Per page</span>
						<div class="d-inline-flex">
							<select class="form-select form-select-sm" v-model="pag.perPage">
								<option value="5">5</option>
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>

					<!-- Pagination -->
					<div class="col-auto">
						<nav aria-label="Page navigation example">
							<ul v-if="pag.pages" class="pagination mb-0">
								<!-- TODO add these buttons when there are > 20 pages -->
								<!--
									<li class="page-item">
										<a class="page-link" @click="pag.page = 1">First</a>
									</li> -->
								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="{ active: p == pag.page }"
								>
									<a class="page-link three-dots" @click="pag.page = p">
										{{ p }}
									</a>
								</li>
								<!-- <li class="page-item">
										<a class="page-link" @click="pag.page = pag.pages">Last</a>
									</li>
								-->
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
//import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import heyGovApi from '@/api.js'
import { paymentStatuses } from '@/actions/payments.js'

import VenueBookingStatus from '@/components/venues/VenueBookingStatus.vue'
import PersonLink from '@/components/PersonLink.vue'

export default {
	name: 'VenueGroupReservations',
	components: { PersonLink, VenueBookingStatus },
	props: {
		venueGroup: {
			type: Object,
			required: true,
		},
		venues: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			paymentStatuses,
			states: {
				bookings: 'loading',
			},
			bookings: [],
			selectedReservations: [],
			allReservationsSelected: false,

			filters: {
				status: this.$route.query.status || '',
				payment: this.$route.query.payment || '',
				person_id: this.$route.query.person_id || '',
				date_start: this.$route.query.date_start || '',
				date_end: this.$route.query.date_end || '',
				booking_start: this.$route.query.booking_start || '',
				booking_end: this.$route.query.booking_end || '',
			},
			sorting: {
				orderBy: localStorage.getItem('venue-bookings-orderBy') || 'starts_at',
				order: localStorage.getItem('venue-bookings-order') || 'desc',
			},
			pag: {
				total: 0,
				page: this.$route.query.page || 1,
				pages: 0,
				perPage: localStorage.getItem('venue-bookings-per-page') || 10,
			},

			tableColumns: [
				'person_id',
				'starts_at',
				'venue_id',
				'status',
				'price',
				//'deposit',
				'payment_status',
				//'deposit_status',
				'booked_at',
			],
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['currentRole', 'isStaff']),
	},
	created() {
		this.loadBookings()
	},
	methods: {
		loadBookings() {
			this.states.bookings = 'loading'

			const params = {
				venue_group_id: this.venueGroup.id,
				...this.filters,
				...this.sorting,
				perPage: this.pag.perPage,
				page: this.pag.page,
			}

			heyGovApi.get(`${this.j.slug}/venue-bookings`, { params }).then(
				({ data, headers }) => {
					this.pag.total = Number(headers['x-total'])
					this.pag.pages = Math.ceil(this.pag.total / this.pag.perPage)

					this.bookings.push(...data)
					this.states.bookings = 'loaded'
				},
				error => {
					alert(error.message)
					this.states.settings = 'error'
				}
			)
		},

		selectAll($event) {
			if ($event.target.checked) {
				this.selectedReservations = this.bookings.map(r => r.id)
			} else {
				this.selectedReservations = []
			}
		},
		sortTable(orderBy, defaultOrder = 'asc') {
			if (this.sorting.orderBy === orderBy) {
				// if the same column is clicked, reverse the sort order
				this.sorting.order = this.sorting.order === 'asc' ? 'desc' : 'asc'
			} else {
				// if a new column is clicked, start with the default order
				this.sorting.order = defaultOrder
			}

			this.sorting.orderBy = orderBy
		},
	},
	watch: {
		sorting: {
			deep: true,
			handler() {
				this.bookings = []
				this.loadBookings()
				localStorage.setItem('venue-bookings-orderBy', this.sorting.orderBy)
				localStorage.setItem('venue-bookings-order', this.sorting.order)
			},
		},
		'pag.page'() {
			this.bookings = []
			this.loadBookings()
		},
		'pag.perPage'() {
			this.bookings = []
			this.loadBookings()
			localStorage.setItem('venue-bookings-per-page', this.pag.perPage)
		},
		selectedReservations() {
			setTimeout(() => {
				this.allReservationsSelected =
					this.bookings.length && this.selectedReservations.length === this.bookings.length
				this.$refs.selectAllReservations.indeterminate =
					this.selectedReservations.length && !this.allReservationsSelected
			}, 50)
		},
	},
}
</script>
