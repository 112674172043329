<template>
	<div class="venue-group">
		<div class="row">
			<div class="col">
				<h3 class="mb-3">
					<router-link :to="`/${j.slug}/venues`">HeyReserve</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
					{{ venueGroup ? venueGroup.name : $route.params.group }}
				</h3>
			</div>
			<div v-if="isStaff" class="col-auto">
				<!-- <button class="btn btn-sm btn-outline-dark">menu</button> -->
			</div>
		</div>

		<nav v-if="currentRole !== 'CITIZEN'" class="mb-4" :class="{ 'opacity-50': $route.name === 'VenueGroupBook' }">
			<ul
				class="nav nav-pills nav-pills-form hide-scrollbar mb-2"
				style="flex-wrap: nowrap;min-width: 100%;overflow-x: scroll;"
			>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/venues/group/${this.$route.params.group}`"
						class="nav-link"
						:class="{ active: $route.name === 'VenueGroup' }"
						>Overview</router-link
					>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/venues/group/${this.$route.params.group}/reservations`"
						class="nav-link"
						:class="{ active: $route.name === 'VenueGroupReservations' }"
						>Reservations
					</router-link>
				</li>
				<li class="nav-item">
					<router-link
						:to="`/${j.slug}/venues/group/${this.$route.params.group}/settings`"
						class="nav-link"
						:class="{ active: $route.name === 'VenueGroupSettings' }"
						>Settings
					</router-link>
				</li>

				<li class="nav-item ms-auto">
					<router-link
						:to="`/${j.slug}/venues/group/${this.$route.params.group}/book`"
						class="nav-link"
						:class="{ active: $route.name === 'VenueGroupBook' }"
						>Public page</router-link
					>
				</li>
			</ul>
		</nav>

		<div v-if="states.group === 'loading'" class="text-center">
			<span class="spinner-border spinner-border-sm" role="status"></span> Loading..
		</div>
		<router-view
			v-else-if="states.group === 'loaded'"
			:venueGroup="venueGroup"
			:venues="venues"
			@updateVenueGroup="payload => updateVenueGroup(payload.fields, payload.message)"
		></router-view>
		<div v-else class="alert alert-danger text-center mx-6">
			Error loading venue group "{{ $route.params.group }}" ({{ venueGroupError }})
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'

import heyGovApi, { handleResponseError } from '@/api.js'

export default {
	name: 'VenueGroup',
	metaInfo() {
		return {
			title: this.venueGroup?.name || this.$route.params.group,
		}
	},
	components: {},
	data() {
		return {
			states: {
				group: 'loading',
			},
			venueGroup: null,
			venueGroupError: '',
			venues: [],
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['currentRole', 'isStaff']),
	},
	created() {
		this.loadVenueGroup()

		if (this.currentRole === 'CITIZEN' && this.$route.name !== 'VenueGroupBook') {
			this.$router.replace(`/${this.j.slug}/venues/group/${this.$route.params.group}/book`)
		}
	},
	methods: {
		loadVenueGroup() {
			this.states.group = 'loading'
			this.venueGroup = null
			this.venues = []

			heyGovApi.get(`${this.j.slug}/venue-groups/${this.$route.params.group}`).then(
				({ data }) => {
					this.venueGroup = data
					this.states.group = 'loaded'
					this.loadVenues()
				},
				error => {
					this.states.group = 'error'

					if (error.response?.data?.message) {
						this.venueGroupError = error.response.data.message
					} else if (error.response) {
						this.venueGroupError = `${error.response.status} ${error.response.statusText}`
					} else {
						this.venueGroupError = error.message
					}
				}
			)
		},

		updateVenueGroup(data, successMessage = 'Venue group updated') {
			heyGovApi.put(`${this.j.slug}/venue-groups/${this.venueGroup.slug}`, data).then(() => {
				for (const key in data) {
					this.venueGroup[key] = data[key]
				}

				Vue.toasted.success(successMessage)
			}, handleResponseError('Error updating venue group ({error})'))
		},

		loadVenues() {
			const params = {
				venue_group_id: this.venueGroup.id,
				//status: 'public',
			}

			heyGovApi.get(`${this.j.slug}/venues`, { params }).then(({ data }) => {
				this.venues = data
			}, handleResponseError('Error loading venues ({error})'))
		},
	},
	watch: {
		'$route.params.group'() {
			this.loadVenueGroup()
		},
	},
}
</script>
