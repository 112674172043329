<template>
	<gmap-map
		class="muni-map"
		:center="center || j.location"
		:zoom="j.location.zoom"
		:style="{ height: `${height}px` }"
		:options="mapOptions"
		ref="muniMap"
	>
	</gmap-map>
</template>

<style scoped>
.muni-map {
	width: 100%;
	min-height: 100px;
	border-radius: 8px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'
import { getGoogleMapsAPI } from 'gmap-vue'

// docs for region boundary
// https://developers.google.com/maps/documentation/javascript/dds-boundaries/start

// docs for Advanced Markers
// https://developers.google.com/maps/documentation/javascript/advanced-markers/overview

export default {
	name: 'MuniMap',
	props: {
		venueGroup: {
			type: Object,
			required: true,
		},
		center: {
			type: Object,
			default: null,
		},
		mapTypeControl: {
			type: Boolean,
			default: true,
		},
		fullscreenControl: {
			type: Boolean,
			default: true,
		},
		height: Number,
		venues: {
			type: Array,
			required: true,
		},
		activeVenue: {
			type: Number,
			default: null,
		},
		availability: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			map: null,
			mapOptions: {
				mapId: process.env.VUE_APP_GOOGLE_MAPS_MAP_ID,
				mapTypeId: this.venueGroup.map !== 'hidden' ? this.venueGroup.map : 'satellite',
				mapTypeControl: this.mapTypeControl,
				streetViewControl: false,
				fullscreenControl: this.fullscreenControl,
			},
			infoWindow: null,
			venueMarkers: {},
			venuePins: {},
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['currentRole']),
		google: getGoogleMapsAPI,
	},
	created() {},
	mounted() {
		// At this point, the child GmapMap has been mounted, but its map has not been initialized.

		this.$refs.muniMap.$mapPromise.then(map => {
			this.map = map
			this.infoWindow = new this.google.maps.InfoWindow()

			this.mapMuniMarkers(map)

			this.zoomOnVenues()
		})
	},
	methods: {
		mapMuniMarkers(map) {
			this.venues
				.filter(venue => venue.location.lat && venue.location.lng)
				.forEach(venue => {
					const position = new this.google.maps.LatLng(venue.location.lat, venue.location.lng)

					let glyph

					const nameParts = venue.name.split(' ')

					if (Number.isInteger(Number(nameParts.at(-1)))) {
						glyph = nameParts.at(-1)
					} else if (venue.location.icon_svg) {
						glyph = new URL(venue.location.icon_svg.replace('.svg', '.png'))
					}

					this.venuePins[venue.id] = new this.google.maps.marker.PinElement({
						background: '#f7f6ff',
						borderColor: '#7879f1',
						glyphColor: '#7879f1',
						glyph,
						scale: venue.id === this.activeVenue ? 1.5 : 1,
					})

					this.venueMarkers[venue.id] = new this.google.maps.marker.AdvancedMarkerElement({
						content: this.venuePins[venue.id].element,
						map,
						position,
						title: `Boat slip: ${venue.name}`,
					})

					this.venueMarkers[venue.id].addListener('click', () => {
						//const { target } = domEvent;

						const roleInfo =
							this.currentRole === 'CITIZEN'
								? ''
								: `<p class="mb-2">Next reservation: <strong>-</strong></p>`

						let attributes = venue.attributes.map(attr => {
							return `<p class="mb-2">${attr.label}: <strong>${attr.value}</strong></p>`
						})

						const content = `<div class="info-window">
                            <h6>Boat slip: ${venue.name}</h6>
                            ${attributes.join('')}
                            ${roleInfo}
                        </div>`

						this.infoWindow.close()
						this.infoWindow.setContent(content)
						this.infoWindow.open(this.venueMarkers[venue.id].map, this.venueMarkers[venue.id])
					})
				})
		},

		updateAvailability(availability) {
			for (const id in this.venueMarkers) {
				const available = availability[id]?.available

				if (available) {
					this.venueMarkers[id].setMap(this.map)
				} else {
					this.venueMarkers[id].setMap(null)
				}
			}
		},

		zoomOnVenues(padding = 10) {
			const bounds = new this.google.maps.LatLngBounds()
			let pins = 0

			// get visible markers and zoom to fit them
			//bounds.extend(position)

			Object.values(this.venueMarkers).forEach(marker => {
				if (marker.map) {
					bounds.extend(marker.position)
					pins++
				}
			})

			// if no pins, zoom to fit whole marina
			if (!pins) {
				Object.values(this.venueMarkers).forEach(marker => {
					bounds.extend(marker.position)
				})
			}

			this.$refs.muniMap.fitBounds(bounds, padding)
		},
	},
	watch: {
		activeVenue(venueId, prevVenueId) {
			if (venueId) {
				this.venueMarkers[venueId].zIndex = this.venuePins[venueId].scale = 1.5
			} else {
				this.venuePins[prevVenueId].scale = 1
				this.venueMarkers[prevVenueId].zIndex = null
			}
		},
	},
}
</script>
