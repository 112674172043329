<template>
	<div v-if="!currentField.pdf" class="row">
		<div v-if="currentField.data.firstName" class="col-md form-group mb-0">
			<label :for="'fsf-' + currentField.id">
				{{ currentField.data.firstNameLabel }}
				<span title="Redacted" v-if="currentField.redacted" class="file-redacted">*</span>
			</label>
			<input
				:class="'form-control ' + currentField.class"
				:id="'fsf-' + currentField.id"
				:required="currentField.required == 1"
				v-model="value[0]"
				autocomplete="given-name"
			/>
		</div>
		<div v-if="currentField.data.middleName" class="col-md form-group mb-0">
			<label :for="'fsf-' + currentField.id + '-middle'">
				{{ currentField.data.middleNameLabel }}
				<span title="Redacted" v-if="currentField.redacted" class="file-redacted">*</span>
			</label>
			<input
				:class="'form-control ' + currentField.class"
				:id="'fsf-' + currentField.id + '-middle'"
				v-model="value[1]"
				autocomplete="additional-name"
			/>
		</div>
		<div v-if="currentField.data.lastName" class="col-md form-group mb-0">
			<label :for="'fsf-' + currentField.id + '-name'">
				{{ currentField.data.lastNameLabel }}
				<span title="Redacted" v-if="currentField.redacted" class="file-redacted">*</span>
			</label>
			<input
				:class="'form-control ' + currentField.class"
				:id="'fsf-' + currentField.id + '-name'"
				:required="currentField.required == 1"
				v-model="value[2]"
				autocomplete="family-name"
			/>
		</div>
	</div>
	<!-- PDF View -->
	<div v-else>
		<div v-if="currentField.display !== 'form'">
			<div class="row">
				<div v-if="currentField.data.firstName" class="col">
					<label :for="'fsf-' + currentField.id + '-first'" class="form-label">
						{{ currentField.data.firstNameLabel }}
					</label>
					<div v-if="currentField.redacted && this.$route.query.redacted" class="pdf-redacted"></div>
					<div
						v-else
						class="pdf-field
					"
					>
						{{ currentField.value ? currentField.value.split(',')[0] : '' }}
					</div>
				</div>
				<div v-if="currentField.data.middleName" class="col">
					<label :for="'fsf-' + currentField.id + '-middle'" class="form-label">
						{{ currentField.data.middleNameLabel }}</label
					>
					<div v-if="currentField.redacted && this.$route.query.redacted" class="pdf-redacted"></div>
					<div v-else class="pdf-field ">
						{{ currentField.value ? currentField.value.split(',')[1] : '' }}
					</div>
				</div>
				<div v-if="currentField.data.lastName" class="col">
					<label :for="'fsf-' + currentField.id + '-last'" class="form-label">
						{{ currentField.data.lastNameLabel }}
					</label>
					<div v-if="currentField.redacted && this.$route.query.redacted" class="pdf-redacted"></div>
					<div v-else class="pdf-field">
						{{ currentField.value ? currentField.value.split(',')[2] : '' }}
					</div>
				</div>
			</div>
		</div>
		<small v-if="currentField.help" class="text-muted pdf-help">{{ currentField.help }}</small>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'NameInput',
	props: {
		currentField: {
			type: Object,
			required: true,
		},
		mode: {
			type: String,
			default: 'fill',
		},
	},
	data() {
		return {
			value: ['', '', ''],
			canUpdateAccountName: null,
		}
	},
	computed: {
		...mapState(['account']),
	},
	created() {
		if (this.currentField.value && this.currentField.value !== ',,') {
			this.value = this.currentField.value.split(',')
		} else if (
			this.currentField.data.prefillWithAccount &&
			this.mode !== 'edit' &&
			this.account?.first_name &&
			this.account?.last_name
		) {
			if (this.currentField.data.firstName) {
				this.value[0] = this.account.first_name
			}
			if (this.currentField.data.middleName) {
				this.value[1] = this.account.middle_name
			}
			if (this.currentField.data.lastName) {
				this.value[2] = this.account.last_name
			}

			this.currentField.value = this.value.join(',')
		}
	},
	updated() {
		this.currentField.value = this.value.join(',')

		// if account.name is empty, prefill with value from NameInput
		// likely this is the account owner
		if (this.canUpdateAccountName === null) {
			this.canUpdateAccountName = Boolean(!this.account?.name)
		}

		/* if (this.canUpdateAccountName) {
			this.account.name = this.value
				.filter(Boolean)
				.join(' ')
				.trim()
		} */
	},
}
</script>
