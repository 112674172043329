<template>
	<div class="person-info-card row align-items-center gx-3">
		<div class="col-auto">
			<person-avatar :person="person" :size="avatarSize"></person-avatar>
		</div>
		<div class="col">
			<p class="mb-1">
				Name: <strong>{{ person.name }}</strong>
			</p>
			<p class="mb-1">
				Email:
				<template v-if="person.email">
					<strong class="me-1">{{ person.email }}</strong>
					<span
						v-if="person.verified"
						class="text-success-400"
						data-bs-toggle="tooltip"
						title="Email address verified by tapping a link in email"
						>✓</span
					>
				</template>
				<small v-else class="text-neutral-400">(no email)</small>
			</p>
			<p class="mb-1">
				Phone:
				<strong>{{ person.phone }}</strong>
			</p>
			<p class="mb-0">
				Address: <strong>{{ person.address }}</strong>
			</p>
		</div>
	</div>
</template>

<script>
import { Tooltip } from 'bootstrap'

import PersonAvatar from '@/components/PersonAvatar.vue'

export default {
	name: 'PersonCard',
	components: { PersonAvatar },
	props: {
		person: {
			type: Object,
			required: true,
		},
		avatarSize: {
			type: Number,
			default: 100,
		},
	},
	mounted() {
		document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
			new Tooltip(el)
		})
	},
}
</script>
