<template>
	<div class="venue-availability">
		<div v-if="venue">
			<venue-header :venue="venue"></venue-header>

			<h2>Blocked dates</h2>

			<div class="card mb-4">
				<div class="card-body">
					<p>All blocked dates for this venue</p>

					<div class="card-table">
						<table class="table table-hover">
							<thead>
								<tr>
									<th>Event name</th>
									<th>Created by</th>
									<th>Privacy</th>
									<th>Starts at</th>
									<th>Ends at</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="event in events" :key="event.id">
									<td>{{ event.name }}</td>
									<td><person-link :id="event.person_id"></person-link></td>
									<td>{{ event.status }}</td>
									<td>
										{{
											new Date(event.starts_at).toLocaleString('en-US', { timeZone: j.timezone })
										}}
									</td>
									<td>
										{{ new Date(event.ends_at).toLocaleString('en-US', { timeZone: j.timezone }) }}
									</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="text-center py-5" role="status">
			<span class="spinner-border"></span>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
//import { format, differenceInHours, addHours, startOfHour, parseISO, parse } from 'date-fns'

import heyGovApi from '@/api.js'
//import { handleResponseError } from '@/utils.js'

import { handleResponseError } from '../../utils'

import PersonLink from '@/components/PersonLink.vue'
import VenueHeader from './VenueHeader.vue'

export default {
	name: 'VenueAvailability',
	metaInfo() {
		return {
			title: `Blocked dates for ${this.venue?.name || 'venue'}`,
		}
	},
	components: { PersonLink, VenueHeader },
	data() {
		return {
			states: {
				venue: 'loading',
			},
			venue: null,
			events: [],
		}
	},
	computed: {
		...mapState(['j', 'authPerson']),
		...mapGetters(['currentRole', 'isStaff']),
	},
	created() {
		this.getVenue()
	},
	methods: {
		// Get Venue
		getVenue() {
			this.states.venue = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}`).then(
				({ data }) => {
					this.venue = data
					this.states.venue = 'idle'

					this.loadVenueEvents()
				},
				error => {
					this.states.venue = 'error'
					Vue.toasted.error(`Error loading venue info (${error.response.data.message})`)
				}
			)
		},
		loadVenueEvents() {
			heyGovApi.get(`${this.j.slug}/venue-events?venue_id=${this.venue.id}`).then(({ data }) => {
				this.events.push(...data)
			}, handleResponseError(`Error loading venue events ({error})`))
		},
	},
}
</script>
