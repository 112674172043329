<template>
	<div class="thread-detail">
		<div v-if="thread" class="row">
			<div class="col-md-8 mb-3 order-2 order-md-1">
				<h5 v-if="currentRole === 'CITIZEN'" class="mb-3">
					{{ thread.title || 'Reported Issue' }}
				</h5>
				<h5 v-else class="mb-3">
					<input
						type="text"
						v-debounce.3s="updateTitle"
						class="form-control form-control-editable-in-place ps-3"
						v-model="thread.title"
						placeholder="Give this issue a name (if you want)"
					/>
				</h5>

				<div class="ticket-messages-wrap rounded bg-white shadow p-3 mb-3">
					<div class="ticket-messages">
						<div v-for="(message, index) in messages" :key="message.id" class="row-message mb-2">
							<p
								v-if="
									!messages[index - 1] ||
										new Date(message.created_at).toLocaleDateString() !=
											new Date(messages[index - 1].created_at).toLocaleDateString()
								"
								class="text-over-line my-3"
							>
								<span class="text-primary">{{
									new Date(message.created_at).toLocaleDateString()
								}}</span>
							</p>

							<div v-if="people[message.person_id]">
								<div v-if="message.type === 'activity'">
									<p class="text-center mb-1">
										<small class="text-muted"
											>{{
												new Date(message.created_at).toLocaleTimeString('en', {
													timeStyle: 'short',
												})
											}}
											&middot;
										</small>
										<small class="" v-html="nicerActivityMessage(message)"></small>
									</p>
								</div>

								<div v-else class="row">
									<div
										class="col-2 col-lg-1"
										:class="{ 'order-3': personIsStaff(people[message.person_id]) }"
									>
										<person-avatar
											role="button"
											v-if="
												!messages[index - 1] ||
													message.person_id !== messages[index - 1].person_id ||
													messages[index - 1].type === 'activity'
											"
											:person="people[message.person_id]"
											:size="32"
											:preview="true"
										></person-avatar>
									</div>

									<div
										class="col px-0 order-2"
										:class="{ 'text-end': personIsStaff(people[message.person_id]) }"
									>
										<p class="mb-1">
											<!-- TODO instead of "Anonymous" string, use anonymous name from db -->
											<a
												role="button"
												@click="personPreview(message.person_id)"
												v-if="
													!messages[index - 1] ||
														message.person_id !== messages[index - 1].person_id ||
														messages[index - 1].type === 'activity'
												"
												class="link-primary mx-1"
												:class="{ 'float-end': personIsStaff(people[message.person_id]) }"
												>{{
													message.anonymous
														? people[message.person_id].anonymous_name || 'Anonymous'
														: people[message.person_id].name
												}}</a
											>
											<small class="text-muted mx-1">{{
												new Date(message.created_at).toLocaleTimeString('en', {
													timeStyle: 'short',
												})
											}}</small>
											<small v-if="!message.id" class="text-muted mx-1">sending..</small>
											<small
												v-if="
													currentRole !== 'CITIZEN' &&
														message.source &&
														message.source !== 'app'
												"
												class="text-muted"
												>from {{ message.source }}</small
											>
										</p>

										<div
											v-if="message.type === 'text'"
											class="message-text"
											:class="{
												'by-citizen': !personIsStaff(people[message.person_id]),
												'by-staff': personIsStaff(people[message.person_id]),
											}"
										>
											<span
												class="d-inline-block px-2 py-1"
												v-html="processMessage(message.message)"
											></span>
										</div>
										<div
											v-else-if="message.type === 'note' && currentRole !== 'CITIZEN'"
											class="d-inline-block message-note mw-50"
										>
											<p class="m-0 p-0 text-secondary pe-1"><small>Internal note</small></p>
											<span
												class="d-inline-block px-2 py-1"
												v-html="processMessage(message.message)"
											></span>
										</div>
										<div
											v-else-if="
												message.type === 'file' && message.data.type.startsWith('image/')
											"
											class="message-image"
										>
											<p class="mb-0">
												<a role="button" @click="showImg(index, [{ src: message.data.url }])"
													><img
														:src="message.data.urlThumb || message.data.url"
														:alt="message.message"
														width="180"
														class="img-thumbnail"
												/></a>
											</p>
											<p v-if="isStaff && isTestingApp" class="mb-0 p-1 rounded glowing">
												<span
													v-for="label in message.data.labelAnnotations"
													:key="label.description"
													class="badge text-info bg-info-lighter me-1"
													>{{ label.description }} {{ Math.round(label.score * 100) }}%</span
												>
											</p>
											<p
												v-if="message.data.exif && currentRole !== 'CITIZEN'"
												class="mb-1 text-muted"
											>
												<small
													>Taken
													<span v-if="message.data.exif.Make || message.data.exif.Model">
														with {{ message.data.exif.Make || '' }}
														{{ message.data.exif.Model || '' }}</span
													>
													<span v-if="message.data.location && message.data.location.name">
														at
														<span class="text-primary">{{
															message.data.location.name
														}}</span></span
													>
												</small>
											</p>
										</div>
										<div
											v-else-if="
												message.type === 'file' && message.data.type.startsWith('video/')
											"
											class="message-video"
										>
											<p class="mb-0">
												<video controls width="200">
													<source :src="message.data.url" :type="message.data.type" />
													Sorry, your browser doesn't support embedded videos.
												</video>
											</p>
										</div>
										<div
											v-else-if="
												message.type === 'file' && message.data.type.startsWith('audio/')
											"
											class="message-audio"
										>
											<p class="mb-0"><audio controls :src="message.data.url"></audio></p>
											<p class="mb-1 text-muted">
												<small>{{
													new Date(message.created_at).toLocaleTimeString('en', {
														timeStyle: 'short',
													})
												}}</small>
												<span v-if="message.data.exif"
													><small>
														&middot; Taken
														<span v-if="message.data.exif.Make || message.data.exif.Model">
															with {{ message.data.exif.Make || '' }}
															{{ message.data.exif.Model || '' }}</span
														>
														<span v-if="message.data.exif.latitude">
															at
															<span class="text-primary">{{
																message.data.location
															}}</span></span
														>
													</small></span
												>
											</p>
										</div>
										<div
											v-else-if="
												message.type === 'file' && message.data.type == 'application/pdf'
											"
											class="message-document"
										>
											<a
												:href="message.data.url"
												target="_blank"
												class="d-flex align-items-center justify-content-center text-center bg-neutral-50 border rounded-1 p-2"
												style="width: 300px; height: 170px"
											>
												{{ message.data.url.split('/').pop() }}
											</a>
										</div>
										<pre v-else-if="isTestingApp && isStaff">{{ message }}</pre>
									</div>

									<div
										class="col-1"
										:class="[`order-${personIsStaff(people[message.person_id]) ? 1 : 3}`]"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div v-if="auth" class="rounded bg-white shadow px-3 py-2 mb-3">
					<form
						@submit.prevent="sendMessage"
						class="form-new-message"
						:class="[`form-new-message-${newMessage.type}`]"
					>
						<ul v-if="currentRole !== 'CITIZEN'" class="nav nav-tabs mb-3 nav-message-type">
							<li class="nav-item">
								<span
									class="nav-link nav-link-text cursor-pointer"
									:class="{ active: newMessage.type === 'text' }"
									@click="newMessage.type = 'text'"
									>Reply</span
								>
							</li>
							<li class="nav-item">
								<span
									class="nav-link nav-link-note cursor-pointer text-secondary"
									:class="{ active: newMessage.type === 'note' }"
									@click="newMessage.type = 'note'"
								>
									Internal note</span
								>
							</li>
						</ul>

						<div class="form-group mb-2">
							<textarea
								class="form-control px-3 py-2"
								rows="3"
								v-model="newMessage.message"
								required
								placeholder="Type your message"
							></textarea>
						</div>

						<div class="row align-items-center">
							<div class="col">
								📜 📁 🏞 📎
							</div>
							<div class="col-7 col-sm-6 col-md-4">
								<div class="form-group mb-0 float-end">
									<button
										class="btn btn-sm btn-block btn-primary m-0"
										:class="{ 'd-none': newMessage.type !== 'text' }"
									>
										{{ currentRole === 'CITIZEN' ? 'Send reply & join issue' : 'Send reply' }}
									</button>
									<button
										class="btn btn-sm btn-block btn-secondary m-0"
										:class="{ 'd-none': newMessage.type !== 'note' }"
									>
										Add internal note
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>
				<div v-else class="mb-4">
					<router-link :to="`/${j.slug}/threads/create`" class="card card-link bg-primary text-white mt-5">
						<div class="card-body text-center">
							<h6 class="card-title mb-0">⚠️ Report another {{ j.type }} issue</h6>
						</div>
					</router-link>
				</div>
			</div>

			<div class="col-md-4 mb-3 order-1 order-md-2">
				<div class="sticky-top" style="top: 60px; z-index: 10">
					<h5>Request overview</h5>
					<div class="card thread-info-card mb-4">
						<div class="card-body">
							<div v-if="currentRole === 'CITIZEN'">
								<p class="mb-2">
									<span class="text-neutral-300 me-2">Date:</span> {{ thread.created_at | dateLocal }}
								</p>
								<p class="mb-2">
									<span class="text-neutral-300 me-2">Department:</span>
									{{
										thread.agency_id && departments
											? departments.find(s => s.id === thread.agency_id).name
											: 'Unknown'
									}}
								</p>
								<p class="mb-2">
									<span class="text-neutral-300 me-2">Category:</span>
									{{
										thread.service_id && services
											? services.find(s => s.id === thread.service_id).name
											: 'Unknown'
									}}
								</p>
								<p class="mb-0">
									<span class="text-neutral-300 me-2">Status:</span>
									<span :class="[statuses[thread.status].class]">{{
										statuses[thread.status].name
									}}</span>
								</p>
							</div>

							<div v-else>
								<div class="form-group row mb-2">
									<label for="thread-status" class="col-4 col-sm-12 col-xl-4 col-form-label"
										>Status</label
									>
									<div class="col-8 col-sm-12 col-xl-8">
										<select
											class="form-select form-select-sm"
											id="thread-status"
											v-model="thread.status"
										>
											<option value="new">Received</option>
											<option value="working">Working on it</option>
											<option value="resolved">Resolved</option>
											<option value="closed">Declined</option>
										</select>
									</div>
								</div>
								<div class="form-group row mb-2">
									<label for="thread-visibility" class="col-4 col-sm-12 col-xl-4 col-form-label"
										>Visbility</label
									>
									<div class="col-8 col-sm-12 col-xl-8">
										<select
											class="form-select form-select-sm"
											id="thread-visibility"
											v-model="thread.visibility"
										>
											<option value="private">Private</option>
											<option value="public">Public</option>
										</select>
									</div>
								</div>
								<div class="form-group row mb-2">
									<label for="thread-agency" class="col-4 col-sm-12 col-xl-4 col-form-label"
										>Assign to</label
									>
									<div class="col-8 col-sm-12 col-xl-8">
										<select
											class="form-select form-select-sm"
											:class="{ 'is-invalid': !thread.agency_id }"
											id="thread-agency"
											v-model="thread.agency_id"
										>
											<option
												v-for="agency in activeDepartments"
												:key="agency.id"
												:value="agency.id"
												>{{ agency.name }}</option
											>
										</select>
									</div>
								</div>
								<div class="form-group row mb-2">
									<label for="thread-service" class="col-4 col-sm-12 col-xl-4 col-form-label"
										>Category</label
									>
									<div class="col-8 col-sm-12 col-xl-8">
										<select
											class="form-select form-select-sm"
											id="thread-service"
											:class="{ 'is-invalid': !thread.service_id }"
											v-model="thread.service_id"
										>
											<optgroup
												v-for="(services, group) in servicesByGroup"
												:key="group"
												:label="group"
											>
												<option
													v-for="service in services"
													:key="service.id"
													:value="service.id"
													>{{ service.name }}</option
												>
											</optgroup>
										</select>
									</div>
								</div>

								<div class="row align-items-center">
									<div class="col">
										<p class="mb-0">{{ thread.created_at | dateLocal }}</p>
										<p class="mb-0">
											{{ thread.created_at | timeLocal }}
										</p>
									</div>
									<div class="col-auto text-end">
										<p class="text-muted mb-0">
											<small
												class="text-muted"
												v-html="threadSources[thread.source] || 'unknown source'"
											></small>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div v-if="currentRole !== 'CITIZEN'" class="d-none d-md-block">
						<h5
							class="bg-dark-on-hover cursor-pointer"
							@click="states.widgetParticipants = !states.widgetParticipants"
						>
							<span class="float-end text-muted">{{ states.widgetParticipants ? '▲' : '▼' }}</span>
							Participants
						</h5>
						<div class="card thread-info-card mb-4">
							<div v-if="states.widgetParticipants" class="card-body py-2">
								<p class="mb-1 text-uppercase">
									<small><strong>Reported by</strong></small>
								</p>
								<div
									v-for="personId in threadParticipants.filter(participantsCitizens)"
									:key="personId"
									class="row align-items-center no-gutters mb-2"
								>
									<div class="col-auto">
										<person-avatar
											role="button"
											:person="people[personId]"
											:size="40"
											:preview="true"
										></person-avatar>
									</div>
									<div class="col">
										<p class="mb-0">
											<a class="link-primary" role="button" @click="personPreview(personId)">{{
												thread.anonymous
													? people[personId].anonymous_name || 'Anonymous'
													: people[personId].name
											}}</a>
										</p>
										<p class="mb-0 text-muted">
											<small>{{ new Date(thread.created_at).toLocaleDateString() }}</small>
										</p>
									</div>
								</div>

								<p class="mb-1 text-uppercase">
									<small><strong>Internal</strong></small>
								</p>
								<div
									v-for="personId in threadParticipants.filter(participantsInternal)"
									:key="personId"
									class="row align-items-center no-gutters mb-2"
								>
									<div class="col-auto">
										<person-avatar
											role="button"
											:person="people[personId]"
											:size="40"
											:preview="true"
										></person-avatar>
									</div>
									<div class="col">
										<p class="mb-0">
											<a class="link-primary" role="button" @click="personPreview(personId)">{{
												thread.anonymous
													? people[personId].anonymous_name || 'Anonymous'
													: people[personId].name
											}}</a>
										</p>
										<p class="mb-0 text-muted">
											<small>
												{{
													getPersonsDepartment(people[personId].roles[0].department_id)
												}}</small
											>
										</p>
									</div>
								</div>
							</div>
							<div v-else class="card-body py-2">
								<div class="row">
									<div class="col-auto">
										<p class="mb-1 text-uppercase">
											<small><strong>Reported by</strong></small>
										</p>
										<p class="mb-1">
											<span
												v-for="personId in threadParticipants.filter(participantsCitizens)"
												:key="personId"
											>
												<person-avatar
													role="button"
													:person="people[personId]"
													:size="40"
													:preview="true"
												></person-avatar>
											</span>
										</p>
									</div>
									<div class="col text-end">
										<p class="mb-1 text-uppercase">
											<small><strong>Internal</strong></small>
										</p>
										<p class="mb-1">
											<person-avatar
												v-for="personId in threadParticipants.filter(participantsInternal)"
												:key="personId"
												role="button"
												:person="people[personId]"
												:size="40"
												:preview="true"
											></person-avatar>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="d-none d-md-block">
						<h5 class="bg-dark-on-hover cursor-pointer" @click="states.widgetFiles = !states.widgetFiles">
							<span v-if="fileMessages.length > 0" class="float-end text-muted">{{
								states.widgetFiles ? '▲' : '▼'
							}}</span>
							Photos &amp; Files
						</h5>
						<div class="card files-card mb-4">
							<div v-if="states.widgetFiles" class="card-body py-2">
								<div v-for="file in fileMessages" :key="file.id" class="media my-2">
									<a
										role="button"
										@click="showImg(1, [{ src: file.data.url }])"
										v-if="file.data.type.startsWith('image/')"
									>
										<img
											:src="file.data.urlThumb || file.data.url"
											class="align-self-center object-fit-cover rounded-sm me-3"
											width="75"
											height="75"
											:alt="file.message"
										/>
									</a>
									<a
										:href="file.data.url"
										v-else-if="file.data.type.startsWith('video/')"
										target="_blank"
									>
										<img
											src="https://files.heygov.com/assets/icon-file-video.png"
											class="align-self-center object-fit-cover rounded-sm me-3"
											width="75"
											height="75"
											:alt="file.message"
										/>
									</a>
									<a :href="file.data.url" v-else>
										<img
											src="https://files.heygov.com/assets/icon-file.png"
											class="align-self-center object-fit-cover rounded-sm me-3"
											width="75"
											height="75"
											:alt="file.message"
										/>
									</a>
									<div class="media-body">
										<p class="mb-1">{{ file.message }}</p>
										<p
											v-if="file.data.exif && (file.data.exif.Make || file.data.exif.Model)"
											class="text-muted mb-1"
										>
											<small
												>Taken with {{ file.data.exif.Make || '' }}
												{{ file.data.exif.Model || '' }}</small
											>
										</p>
										<p v-if="file.data.exif && file.data.exif.latitude" class="text-muted mb-1">
											<small
												>📍 <span class="text-primary">{{ file.data.location }}</span></small
											>
										</p>
										<p class="mb-0">
											<small class="text-muted float-end">{{
												new Date(file.created_at).toLocaleString()
											}}</small>
										</p>
									</div>
								</div>
								<p v-if="!fileMessages.length" class="mb-0 text-muted text-center">
									<small><i>No files or photos added to this issue</i></small>
								</p>
							</div>
							<div v-else class="card-body py-2 cursor-pointer" @click="states.widgetFiles = true">
								<span v-for="file in fileMessages" :key="file.id">
									<img
										v-if="file.data.type.startsWith('image/')"
										:src="file.data.urlThumb || file.data.url"
										:alt="file.message"
										width="50"
										height="50"
										class="img-thumbnail my-1 me-2"
									/>
									<img
										v-else-if="file.data.type.startsWith('video/')"
										src="https://files.heygov.com/assets/icon-file-video.png"
										:alt="file.message"
										width="50"
										height="50"
										class="img-thumbnail my-1 me-2"
									/>
									<img
										v-else
										src="https://files.heygov.com/assets/icon-file.png"
										:alt="file.message"
										width="50"
										height="50"
										class="img-thumbnail my-1 me-2"
									/>
								</span>
								<p v-if="!fileMessages.length" class="mb-0 text-muted text-center">
									<small><i>No files or photos added to this issue</i></small>
								</p>
							</div>
						</div>
					</div>

					<h5 class="bg-dark-on-hover cursor-pointer" @click="states.widgetLocation = !states.widgetLocation">
						<span class="float-end text-muted">{{ states.widgetLocation ? '▲' : '▼' }}</span>
						Location
					</h5>
					<div class="card thread-info-card mb-4">
						<div class="card-body py-2">
							<div v-if="states.widgetLocation" class="mt-3">
								<div v-if="currentRole !== 'CITIZEN'" class="form-group">
									<gmap-autocomplete @place_changed="setThreadLocation" :select-first-on-enter="true">
										<template v-slot:default="slotProps">
											<input
												id="thread-location"
												class="form-control form-control-sm mb-2"
												ref="input"
												:value="thread.location.name || ''"
												placeholder="311 request location"
												required
												v-on="slotProps.listeners"
											/>
										</template>
									</gmap-autocomplete>
								</div>
								<p v-else>📍 {{ thread.location.name || 'Unknown location' }}</p>

								<muni-map
									v-if="thread.location && thread.location.lat"
									:includeVenues="false"
									:center="{ lat: thread.location.lat, lng: thread.location.lng }"
									:adjustZoom="-1"
									:markers="[{ position: thread.location, icon: threadMarkerIcon(thread) }]"
									:height="300"
									class="mb-3"
								></muni-map>

								<p class="card-text">
									This address is
									{{
										distanceBetweenCoordinates(
											thread.location.lat,
											thread.location.lng,
											this.j.location.lat,
											this.j.location.lng,
											'miles'
										)
									}}
									miles away from {{ j.name }}.
								</p>

								<!--
								<h6>Other issues at this address</h6>
								<ul class="">
									<li>This issue</li>
									<li>That issue</li>
								</ul>
								-->
							</div>

							<p v-else class="mb-0 cursor-pointer" @click="states.widgetLocation = true">
								📍 {{ thread.location.name || 'Unknown location' }}
							</p>
						</div>
					</div>

					<div v-if="isStaff" class="card thread-info-card glowing mb-4">
						<div class="card-header">
							<h5 class="my-0">Only for us at HeyGov 🕵️</h5>
						</div>
						<div class="card-body py-2">
							<p class="mb-1">
								<strong>From URL:</strong>
								<a :href="thread.from_referer" target="_blank">{{ thread.from_referer }}</a>
							</p>
							<p class="mb-1">
								<strong>IP:</strong>
								<a :href="`https://ipinfo.io/${thread.from_ip}`" target="_blank">{{
									thread.from_ip
								}}</a>
							</p>
							<p class="mb-3"><strong>User-Agent:</strong> {{ thread.from_user_agent }})</p>

							<p class="card-text">
								<button class="btn btn-sm btn-outline-danger" @click="deleteThread">
									Delete 311 request
								</button>
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>

		<vue-easy-lightbox :visible="visible" :imgs="imgs" :index="index" @hide="handleHide"></vue-easy-lightbox>

		<div v-if="state === 'loading'" class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>
	</div>
</template>

<style lang="scss">
.ticket-messages-wrap {
	max-height: 550px;
	padding: 1rem;
	overflow-y: auto;
	direction: ltr;
	scrollbar-color: #d4aa70 #e4e4e4;
	scrollbar-width: thin;
}

.ticket-messages-wrap::-webkit-scrollbar {
	width: 18px;
}

.ticket-messages-wrap::-webkit-scrollbar-track {
	background-color: #e4e4e4;
	border-radius: 100px;
}

.ticket-messages-wrap::-webkit-scrollbar-thumb {
	border-radius: 100px;
	border: 5px solid transparent;
	background-clip: content-box;
	background-color: var(--bs-primary);
}
.message-text {
	span {
		background-color: var(--bs-primary);
		border-radius: 14px 4px 14px 14px;
		color: #fff;
	}

	&.by-citizen span {
		background-color: #f0f0f0;
		border-radius: 4px 14px 14px 14px;
		color: #111;
	}

	&.by-staff a {
		color: #111 !important;
	}
}

.message-note {
	span {
		background-color: var(--bs-secondary);
		border-radius: 14px 4px 14px 14px;
		color: #333;
	}
}

.files-card .img-thumbnail {
	height: 50px;
	object-fit: cover;
}

.form-new-message {
	border-top: none !important;
	.nav-message-type {
		.nav-link {
			border-bottom: 2px solid transparent;
			padding-left: 2px;
			padding-right: 2px;
			margin-right: 1rem;
		}

		.nav-link-text.active {
			color: var(--bs-primary);
			border-color: var(--bs-primary);
		}
		.nav-link-note.active {
			color: var(--bs-secondary);
			border-color: var(--bs-secondary);
		}
	}

	textarea {
		background-color: #fdfdfd;
	}

	&.form-new-message-note textarea {
		background-color: #ffb8001f;
	}
}
</style>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { uniq } from 'lodash'
import { Offcanvas } from 'bootstrap'

import heyGovApi from '@/api.js'
import { distanceBetweenCoordinates } from '@/lib/geo.js'

import MuniMap from '@/components/MuniMap.vue'
import PersonAvatar from '../components/PersonAvatar.vue'

export default {
	name: 'Thread',
	components: { MuniMap, PersonAvatar },
	data() {
		return {
			state: 'idle',
			states: {
				widgetParticipants: false,
				widgetFiles: false,
				widgetLocation: false,
			},
			thread: null,
			messages: [],
			newMessage: {
				type: 'text',
				message: '',
			},
			visible: false, // For image preview
			index: 0, // For image prevoew
			imgs: [], // Images for preview
		}
	},
	created() {
		this.$store.dispatch('loadServices')
		this.$store.dispatch('loadDepartments')
		this.loadThread()
	},

	computed: {
		...mapState(['j', 'threadSources', 'departments', 'services', 'statuses', 'people']),
		...mapGetters(['auth', 'currentRole', 'servicesByGroup', 'activeDepartments', 'isTestingApp', 'isStaff']),
		fileMessages() {
			const fileMessages = this.messages.filter(m => m.type === 'file')

			return fileMessages
		},
		threadParticipants() {
			let p = this.messages.map(m => m.person_id).filter(Boolean)

			return uniq(p)
		},
	},
	methods: {
		distanceBetweenCoordinates,

		loadThread() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.id}/threads/${this.$route.params.threadId}`).then(
				({ data }) => {
					this.thread = data
					this.state = 'idle'

					if (this.$route.params.threadId !== data.uuid) {
						this.$router.replace({ path: `/threads/${data.uuid}`, query: this.$route.query })
					}

					this.loadMessages()
				},
				error => {
					if (error.response && error.response.status === 404) {
						this.$router.replace({ path: `/not-found` })
					} else {
						this.state = 'error'
						Vue.toasted.show(error.message, { type: 'error' })
					}
				}
			)
		},
		loadMessages() {
			heyGovApi.get(`${this.j.id}/threads/${this.thread.uuid}/messages`).then(
				({ data }) => {
					this.messages.push(...data)

					let peopleIds = data.map(m => m.person_id).filter(Boolean)
					this.$store.dispatch('loadPeople', uniq(peopleIds))

					this.scrollToLastMessage()
				},
				error => {
					console.error(`Error loading messages`, error)
				}
			)
		},
		scrollToLastMessage() {
			document.querySelector('.ticket-messages-wrap').scrollTo({ top: 9999, left: 0, behavior: 'smooth' })

			setTimeout(() => {
				document.querySelector('.ticket-messages-wrap').scrollTo({ top: 9999, left: 0, behavior: 'smooth' })
			}, 300)
		},

		setThreadLocation(place) {
			console.log(place)

			if (place.formatted_address.includes(place.name)) {
				this.thread.location.name = place.formatted_address
			} else {
				this.thread.location.name = `${place.name}, ${place.formatted_address}`
			}

			this.thread.location.lat = place.geometry.location.lat()
			this.thread.location.lng = place.geometry.location.lng()

			this.$store
				.dispatch('updateThread', {
					thread: this.thread,
					fields: { location: JSON.stringify(this.thread.location) },
					messages: this.messages,
				})
				.then(() => {
					this.scrollToLastMessage()
				})
		},
		updateTitle() {
			this.$store.dispatch('updateThread', {
				thread: this.thread,
				fields: { title: this.thread.title },
				messages: this.messages,
			})
		},
		changeNewMessageType(type) {
			if (!this.newMessage.type) {
				setTimeout(() => {
					this.newMessage.type = type
				}, 100)
			}
		},
		sendMessage() {
			if (!this.newMessage.message) {
				alert("But you didn't add a message 😐")
				return
			}

			let message = {
				thread_id: this.thread.id,
				person_id: this.auth.id,
				type: this.newMessage.type,
				message: this.newMessage.message,
				created_at: new Date(),
				source: 'app',
			}

			this.messages.push(message)

			heyGovApi.post(`${this.j.id}/threads/${this.thread.uuid}/messages`, message).then(
				({ data }) => {
					this.$set(message, 'id', data.id)

					Vue.toasted.show('message sent')
				},
				error => {
					Vue.toasted.show(error, { type: 'error' })
				}
			)

			this.scrollToLastMessage()
			this.$store.dispatch('loadPerson', this.auth.id)

			this.newMessage.message = ''
		},
		personIsStaff(person) {
			const staffRoles = ['ADMIN', 'EDITOR', 'WORKER']

			// check if person is Issue reporter
			if (person && this.thread && person.id == this.thread.person_id) {
				return false
			}

			return person && person.roles && person.roles.find(r => staffRoles.includes(r.role))
		},
		personIs(person, role) {
			return person && person.roles.find(r => r.role === role)
		},
		personIsNot(person, role) {
			return !this.personIs(person, role)
		},
		participantsCitizens(personId) {
			return this.people[personId] && !this.personIsStaff(this.people[personId])
		},
		participantsInternal(personId) {
			return this.people[personId] && this.personIsStaff(this.people[personId])
		},

		nicerActivityMessage(message) {
			let msg = ''
			let sep = 'changed'

			if (this.people[message.person_id]) {
				msg += `<strong>${this.people[message.person_id].name}</strong> `
			}

			const changes = []

			for (const field in message.data) {
				if (field === 'agency_id') {
					const foundDepartment = this.departments.find(d => d.id === message.data[field])
					changes.push(`department to "${foundDepartment ? foundDepartment.name : message.data[field]}"`)
				} else if (field === 'status') {
					changes.push(
						`status to <span class="${this.statuses[message.data[field]].class}">${
							this.statuses[message.data[field]].name
						}<span>`
					)
				} else if (field === 'service_id') {
					const foundService = this.services.find(s => s.id === message.data[field])
					changes.push(`category to "${foundService ? foundService.name : message.data[field]}"`)
				} else if (field === 'location') {
					const location = JSON.parse(message.data[field]) || message.data[field]
					changes.push(`location to "${location.name}"`)
				} else if (field === 'merged') {
					changes.push(
						`${message.data[field].length} issues (${message.data[field].join(', ')}) into this one`
					)
					sep = 'merged'
				} else {
					changes.push(`${field} to "${message.data[field]}"`)
				}
			}

			msg += sep + ' ' + changes.join(', ')

			return msg
		},
		processMessage(message) {
			// make links clickable
			message = message.replace(/(http[s]?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>')

			return message
		},
		threadMarkerIcon(thread) {
			return `https://files.heygov.com/assets/icon-map-pin-${thread.status}.svg`
		},
		personPreview(personId) {
			const of = new Offcanvas(document.getElementById('person-preview'))
			of.show()
			this.$store.commit('setPreviewPersonId', personId)
		},

		getPersonsDepartment(departmentId) {
			return this.departments.find(department => (department.id = departmentId)).name
		},

		handleHide() {
			this.visible = false
		},
		showImg(index, images) {
			this.imgs = images
			this.index = index
			this.visible = true
		},

		deleteThread() {
			if (confirm('Are you sure you want to delete this 311 request?')) {
				heyGovApi.delete(`/${this.j.id}/threads/${this.thread.id}`).then(
					() => {
						Vue.toasted.error(`311 request is deleted`)
						this.$router.push(`/${this.j.slug}/threads`)
					},
					error => {
						Vue.toasted.error(`Error deleting ~ ${error}`)
					}
				)
			}
		},
	},
	watch: {
		'thread.status'(status, statusOld) {
			if (status && statusOld !== undefined && status !== statusOld) {
				this.$store
					.dispatch('updateThread', {
						thread: this.thread,
						fields: { status },
						fieldsOld: { status: statusOld },
						messages: this.messages,
					})
					.then(() => {
						this.scrollToLastMessage()
					})
			}
		},
		'thread.visibility'(visibility, visibilityOld) {
			if (visibility && visibilityOld !== undefined && visibility !== visibilityOld) {
				this.$store
					.dispatch('updateThread', {
						thread: this.thread,
						fields: { visibility },
						fieldsOld: { visibility: visibilityOld },
						messages: this.messages,
					})
					.then(() => {
						this.scrollToLastMessage()
					})
			}
		},
		'thread.service_id'(service_id, serviceIdOld) {
			if (service_id && serviceIdOld !== undefined && service_id !== serviceIdOld) {
				this.$store
					.dispatch('updateThread', {
						thread: this.thread,
						fields: { service_id },
						fieldsOld: { service_id: serviceIdOld },
						messages: this.messages,
					})
					.then(() => {
						this.scrollToLastMessage()
					})
			}
		},
		'thread.agency_id'(agency_id, agencyIdOld) {
			if (agency_id && agencyIdOld !== undefined && agency_id !== agencyIdOld) {
				this.$store
					.dispatch('updateThread', {
						thread: this.thread,
						fields: { agency_id },
						fieldsOld: { agency_id: agencyIdOld },
						messages: this.messages,
					})
					.then(() => {
						this.scrollToLastMessage()
					})
			}
		},
	},
}
</script>
