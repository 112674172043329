<template>
	<span
		class="badge"
		:class="venueBookingStatuses[venueBooking.status].class"
		:title="venueBookingStatuses[venueBooking.status].text"
		><font-awesome-icon
			v-if="format === 'icon'"
			:icon="['fas', venueBookingStatuses[venueBooking.status].icon || 'fa-bars']"
		/><template v-else>{{ venueBookingStatuses[venueBooking.status].name }}</template></span
	>
</template>

<script>
import { Tooltip } from 'bootstrap'
import { venueBookingStatuses } from '@/actions/venues.js'

export default {
	name: 'VenueBookingStatus',
	props: {
		venueBooking: {
			type: Object,
			required: true,
		},
		tooltip: Boolean,
		format: {
			type: String,
			default: 'label',
		},
	},
	data() {
		return {
			venueBookingStatuses,
		}
	},
	mounted() {
		if (this.tooltip) {
			new Tooltip(this.$el)
		}
	},
}
</script>
