<template>
	<div class="venue-availability">
		<div v-if="venue">
			<venue-header :venue="venue"></venue-header>
			<h2>Reservation options</h2>
			<div class="card mb-4">
				<div class="card-body">
					<!-- Reservation mode -->
					<p class="mb-2">How will guest reserve this venue?</p>

					<div class="row mb-4">
						<div class="col">
							<div
								class="border rounded-1 p-3 cursor-pointer"
								:class="{ 'border-success': venue.reservation_mode === 'slots' }"
								@click="updateVenue({ reservation_mode: 'slots' }, 'Reservation mode is updated')"
							>
								<h5>
									Slots 🕐
									<span
										v-if="venue.reservation_mode === 'slots'"
										class="badge bg-success-50 text-success ms-1"
										>Active</span
									>
								</h5>
								<p class="mb-2">Reservation slots are predefined blocks of time that can be booked.</p>
								<p class="mb-0">Ex: define morning and evening slots for a park.</p>
							</div>
						</div>
						<div class="col">
							<div
								class="border rounded-1 p-3 cursor-pointer"
								:class="{ 'border-success': venue.reservation_mode === 'time' }"
								@click="updateVenue({ reservation_mode: 'time' }, 'Reservation mode is updated')"
							>
								<h5>
									Custom time ⏲️
									<span
										v-if="venue.reservation_mode === 'time'"
										class="badge bg-success-50 text-success ms-1"
										>Active</span
									>
								</h5>
								<p class="mb-2">Guests can choose the amount of time they need.</p>
								<p class="mb-0">Ex: book a sports hall for 2 hours.</p>
							</div>
						</div>
					</div>

					<div v-if="venue.reservation_mode === 'slots'" class="row align-items-center mb-1">
						<div class="col">
							<h5 class="my-0">Venue booking slots</h5>
						</div>
						<div class="col-auto">
							<button
								data-bs-toggle="modal"
								data-bs-target="#slot-add"
								type="button"
								class="btn btn-sm btn-big-icon btn-outline-primary"
								@click="setActiveSlot(newSlot)"
							>
								Add slot <font-awesome-icon :icon="['fas', 'plus-circle']" />
							</button>
						</div>
					</div>
					<h5 v-else class="mb-3">Available days &amp; hours</h5>

					<div v-if="venue.reservation_mode === 'slots'" class="mb-4">
						<!-- Venue booking slots -->
						<div v-if="venue.slots.length" class="card-table">
							<table class="table table-hover">
								<thead>
									<tr>
										<th>Name</th>
										<th>Type</th>
										<th>Start &amp; end</th>
										<th>Days</th>
										<th>Price</th>
										<th>Deposit</th>
										<th>Bookings</th>
										<th></th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="slot in venue.slots"
										:key="slot.id"
										class="on-parent"
										:class="{
											'bg-danger-50': slot.deleted_at,
										}"
									>
										<td>{{ slot.name }}</td>
										<td>
											<span
												v-if="slot.type === 'daily'"
												class="badge bg-neutral-50 text-primary-300"
												>One day</span
											>
											<span
												v-else-if="slot.type === 'multi'"
												class="badge bg-neutral-100 text-primary-300"
												>Multiple days</span
											>
											<span
												v-else-if="slot.type === 'dates'"
												class="badge bg-neutral-200 text-primary-400"
												>Seasonal</span
											>
										</td>
										<td>
											<template v-if="slot.type === 'dates'"
												>{{ slot.date_starts_at | dateTimeLocal }} →
												{{ slot.date_ends_at | dateTimeLocal }}</template
											>
											<template v-else
												>{{ slot.starts_at | slotLocalTime }} →
												{{ slot.ends_at | slotLocalTime }}</template
											>
										</td>
										<td>
											<small v-if="slot.type === 'dates'" class="text-neutral-300">-</small>
											<span v-else-if="slot.type === 'multi' || slot.days.length === 7"
												>Any day</span
											>
											<span v-else>{{ slot.days.join(', ') }}</span>
										</td>
										<td>{{ slot.price | currency }}</td>
										<td>{{ slot.deposit | currency }}</td>
										<td>
											<span :class="slot.bookings_count ? 'fw-bolder' : 'text-neutral-300'">{{
												slot.bookings_count.toLocaleString()
											}}</span>
										</td>
										<td class="text-end">
											<button
												v-if="slot.deleted_at"
												class="btn btn-sm btn-icon-info m-1"
												@click="restoreSlot(slot)"
												title="Restore"
											>
												<font-awesome-icon :icon="['fas', 'undo']" />
											</button>
											<span v-else class="show-on-hover">
												<button
													data-bs-toggle="modal"
													data-bs-target="#slot-add"
													@click="setActiveSlot(slot)"
													class="btn btn-icon-success bg-primary text-white btn-sm m-1"
												>
													<font-awesome-icon :icon="['fas', 'pencil-alt']" />
												</button>

												<button
													class="btn btn-icon-danger btn-sm m-1"
													@click="removeSlot(slot)"
												>
													<font-awesome-icon :icon="['fas', 'times']" />
												</button>
											</span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div v-else class="rounded bg-warning-lighter border border-dashed p-3 text-center">
							<p class="my-3">
								At least one slot is required to start accepting reservations.
							</p>
							<p>
								<button
									data-bs-toggle="modal"
									data-bs-target="#slot-add"
									type="button"
									class="btn btn-sm btn-big-icon btn-outline-primary"
									@click="setActiveSlot(newSlot)"
								>
									Add the first slot <font-awesome-icon :icon="['fas', 'plus-circle']" />
								</button>
							</p>
						</div>
					</div>

					<form @submit.prevent="updateAvailability">
						<div class="mb-3">
							<div class="row">
								<div v-if="venue.reservation_mode === 'time'" class="col-lg-8">
									<div
										v-for="day in days"
										:key="`venue-days-${day}`"
										class="rounded-1 p-2 mb-3"
										:class="
											venue.availability.days[day].enabled ? 'bg-success-50' : 'bg-neutral-50'
										"
									>
										<div class="row gx-2 align-items-center">
											<div class="col-3 ps-2">
												<div class="form-check">
													<input
														type="checkbox"
														class="form-check-input"
														:id="`venue-days-${day}`"
														v-model="venue.availability.days[day].enabled"
													/>
													<label class="form-check-label" :for="`venue-days-${day}`">{{
														day
													}}</label>
												</div>
											</div>
											<div class="col-3">
												<input
													type="time"
													class="form-control form-control-sm"
													placeholder="Opening at"
													v-model="venue.availability.days[day].starts_at"
													:disabled="!venue.availability.days[day].enabled"
												/>
											</div>
											<div class="col-3">
												<input
													type="time"
													class="form-control form-control-sm"
													placeholder="Closes at"
													v-model="venue.availability.days[day].ends_at"
													:disabled="!venue.availability.days[day].enabled"
												/>
											</div>
											<div class="col-3">
												<div class="input-group input-group-sm">
													<span class="input-group-text">$/h</span>
													<input
														type="number"
														class="form-control"
														placeholder="Price per hour"
														step="0.01"
														min="0"
														max="9999.99"
														required
														v-model="venue.availability.days[day].price"
														:disabled="!venue.availability.days[day].enabled"
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-4">
									<div v-if="venue.reservation_mode === 'time'" class="form-group mb-3">
										<label class="form-label" for="venue-availability-deposit"
											>Booking deposit</label
										>

										<div class="input-group input-group-sm">
											<span class="input-group-text">$</span>
											<input
												type="number"
												class="form-control"
												id="venue-availability-deposit"
												placeholder="Deposit per booking"
												min="0"
												max="9999.99"
												required
												v-model="venue.availability.deposit"
											/>
										</div>
									</div>

									<div v-if="venue.reservation_mode === 'time'" class="form-group mb-3">
										<label class="form-label" for="venue-availability-min"
											>Reservation minimum time per day</label
										>

										<input
											type="number"
											class="form-control"
											id="venue-availability-min"
											placeholder="Minimum reservation time"
											min="1"
											max="24"
											required
											v-model="venue.availability.min"
										/>
										<small class="form-text">In hours, ex: 1 or 2</small>
									</div>

									<div v-if="venue.reservation_mode === 'time'" class="form-group mb-3">
										<label class="form-label" for="venue-availability-max"
											>Reservation maximum time per day</label
										>

										<input
											type="number"
											class="form-control"
											id="venue-availability-max"
											placeholder="Maximum reservation time"
											min="1"
											max="24"
											required
											v-model="venue.availability.max"
										/>
										<small class="form-text">In hours, ex: 4 or 6</small>
									</div>

									<div class="form-group mb-3">
										<label class="form-label" for="venue-availability-how-soon"
											>Buffer time between bookings</label
										>

										<div class="input-group">
											<input
												type="number"
												class="form-control"
												id="venue-availability-how-soon"
												v-model="venue.availability.howSoonBooking.value"
											/>

											<select
												class="form-select"
												v-model="venue.availability.howSoonBooking.unit"
											>
												<option value="hours">Hours</option>
												<option value="days">Days</option>
											</select>
										</div>
									</div>

									<div class="form-group mb-3">
										<label class="form-label" for="venue-availability-how-far"
											>Furthest booking</label
										>

										<div class="input-group">
											<input
												type="number"
												class="form-control"
												aria-label="Text input with dropdown button"
												v-model="venue.availability.howFarBooking.value"
											/>

											<select class="form-select" v-model="venue.availability.howFarBooking.unit">
												<option value="days">Days</option>
												<option value="months">Months</option>
											</select>
										</div>
										<small class="form-text">How far into the future can a booking be made?</small>
									</div>
								</div>
							</div>
						</div>

						<p class="card-text text-center">
							<button
								class="btn px-5"
								:class="states.availabilityChanged ? 'btn-primary' : 'btn-outline-primary'"
							>
								Update availability
							</button>
						</p>
					</form>
				</div>
			</div>

			<h2>Calendar</h2>
			<div class="card">
				<div class="card-body">
					<VenueCalendarAdmin
						:venue="venue"
						:update="calendarKey"
						:options="calendarOptions"
						v-on:addEvent="addEvent"
						v-on:editEvent="editEvent"
						class="mb-2"
					/>

					<p v-if="venue.tw_sync" class="card-text">
						🤝 Events added in TownWeb Dashboard can block availability for this venue.
					</p>
					<p v-else class="card-text">
						🙋 Contact HeyGov if you want events from TownWeb Dashboard to block availability for this
						venue.
					</p>
				</div>
			</div>
		</div>
		<div v-else class="text-center py-5" role="status">
			<span class="spinner-border"></span>
		</div>

		<!-- Add/Update venue slot Modal -->
		<div class="modal fade" id="slot-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered">
				<div v-if="activeSlot" class="modal-content">
					<form @submit.prevent="saveSlot">
						<div class="modal-header">
							<h5 class="modal-title my-0">{{ activeSlot.id ? 'Update slot' : 'Create slot' }}</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label for="slotName" class="form-label">Name</label>
								<input
									type="text"
									required
									id="slotName"
									class="form-control form-control-sm"
									placeholder="Slot name"
									v-model="activeSlot.name"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="slot-type" class="form-label">Type</label>

								<select class="form-select form-select-sm" id="slot-type" v-model="activeSlot.type">
									<option value="daily">One day slot</option>
									<option value="multi">Multiple days slot</option>
									<option value="dates">Seasonal slot</option>
								</select>
							</div>

							<div v-if="activeSlot.type === 'dates'" class="row gx-2">
								<div class="col-6 form-group mb-3">
									<label for="slotStartsAt" class="form-label">Start date &amp; time</label>
									<input
										required
										id="slotStartsAt"
										type="datetime-local"
										class="form-control form-control-sm"
										v-model="activeSlot.date_starts_at"
									/>
								</div>
								<div class="col-6 form-group mb-3">
									<label for="slotEndsAt" class="form-label">End date &amp; time</label>
									<input
										required
										id="slotEndsAt"
										type="datetime-local"
										class="form-control form-control-sm"
										v-model="activeSlot.date_ends_at"
									/>
								</div>
							</div>
							<div v-else class="row">
								<div class="col-6 form-group mb-3">
									<label for="slotStartsAt" class="form-label">{{
										activeSlot.type === 'daily' ? 'Start time' : 'Check in time'
									}}</label>
									<input
										required
										id="slotStartsAt"
										type="time"
										class="form-control form-control-sm"
										v-model="activeSlot.starts_at"
									/>
								</div>
								<div class="col-6 form-group mb-3">
									<label for="slotStartsAt" class="form-label">{{
										activeSlot.type === 'daily' ? 'End time' : 'Check out time'
									}}</label>
									<input
										required
										id="slotEndsAt"
										type="time"
										class="form-control form-control-sm"
										v-model="activeSlot.ends_at"
									/>
								</div>
							</div>

							<div v-if="activeSlot.type === 'daily'" class="form-group mb-3">
								<label for="slotDays" class="form-label">Days available</label>

								<div>
									<div
										v-for="day in days"
										:key="`slot-days-${day}`"
										class="form-check form-check-inline hover me-2"
									>
										<input
											type="checkbox"
											class="form-check-input"
											:id="`slot-days-${day}`"
											v-model="activeSlot.days"
											:value="day"
										/>
										<label class="form-check-label" :for="`slot-days-${day}`">{{ day }}</label>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-6 form-group mb-3">
									<label for="slotDeposit" class="form-label">Deposit</label>
									<div class="input-group input-group-sm">
										<span class="input-group-text ">$</span>
										<input
											id="slotDeposit"
											type="number"
											class="form-control"
											v-model="activeSlot.deposit"
											step="0.01"
											required
										/>
									</div>
								</div>
								<div class="col-6 form-group mb-3">
									<label for="slotPrice" class="form-label">Price</label>
									<div class="input-group input-group-sm">
										<span class="input-group-text ">$</span>
										<input
											id="slotPrice"
											type="number"
											class="form-control"
											v-model="activeSlot.price"
											step="0.01"
											required
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer text-end">
							<button class="btn btn-primary">
								<template v-if="activeSlot.id">Update</template><template v-else>Create</template> Slot
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- Add event Modal -->
		<div class="modal fade" id="event-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<form @submit.prevent="saveEvent">
						<div class="modal-header">
							<h5 class="modal-title">{{ selectedEvent.id ? 'Update' : 'Add' }} blocked date</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label class="form-label" for="event-status-private"
									>Privacy <span class="text-danger">*</span></label
								>

								<div>
									<div class="form-check form-check-inline">
										<input
											class="form-check-input"
											type="radio"
											id="event-status-private"
											v-model="selectedEvent.status"
											value="private"
										/>
										<label class="form-check-label" for="event-status-private"
											>Private <small class="text-muted">(name is not public)</small></label
										>
									</div>

									<div class="form-check form-check-inline">
										<input
											class="form-check-input"
											type="radio"
											id="event-status-public"
											v-model="selectedEvent.status"
											value="public"
										/>
										<label class="form-check-label" for="event-status-public"
											>Public
											<small class="text-muted">(for maintenace, holidays, etc)</small></label
										>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="name">Name <span class="text-danger">*</span></label>
								<input
									class="form-control"
									id="name"
									v-model="selectedEvent.name"
									placeholder="Event name"
									required
								/>
							</div>

							<div class="row gx-2">
								<div class="col-6 form-group mb-3">
									<label class="form-label" for="new-event-datetime-start"
										>From <span class="text-danger">*</span></label
									>
									<input
										type="datetime-local"
										class="form-control form-control-sm"
										id="new-event-datetime-start"
										v-model="selectedEvent.input_starts_at"
										required
									/>
								</div>

								<div class="col-6 form-group mb-3">
									<label class="form-label" for="new-event-datetime-end"
										>To <span class="text-danger">*</span></label
									>

									<input
										type="datetime-local"
										class="form-control form-control-sm"
										id="new-event-datetime-end"
										v-model="selectedEvent.input_ends_at"
										required
									/>
								</div>
							</div>

							<p>
								Duration:
								<strong
									>{{
										differenceInHours(
											new Date(selectedEvent.venue_ends_at),
											new Date(selectedEvent.venue_starts_at)
										)
									}}
									hours</strong
								>
							</p>

							<p class="mb-0 text-muted">
								Event times are in <code>{{ j.timezone }}</code> timezone.
							</p>
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary">{{ selectedEvent.id ? 'Update' : 'Add' }}</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { Modal } from 'bootstrap'
import { format, differenceInHours, addHours, startOfHour, parseISO, parse } from 'date-fns'
import { pick } from 'lodash'

import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'

import VenueHeader from './VenueHeader.vue'
import VenueCalendarAdmin from '@/components/venues/VenueCalendarAdmin'

export default {
	name: 'VenueAvailability',
	metaInfo() {
		return {
			title: `Availability for ${this.venue?.name || 'venue'}`,
		}
	},
	components: { VenueHeader, VenueCalendarAdmin },
	data() {
		return {
			states: {
				venue: 'idle',
				availabilityChanged: false,
			},
			venue: null,

			newSlot: {
				name: '',
				type: 'daily',
				starts_at: '09:00',
				ends_at: '17:00',
				date_starts_at: null,
				date_ends_at: null,
				deposit: 0,
				price: '',
				currency: 'usd',
				days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
			},
			activeSlot: null,
			newEvent: {
				name: '',
				type: 'event',
				status: 'private',
				venue_id: null,
				venue_slot_id: null,
				starts_at: null,
				ends_at: null,
				venue_starts_at: null,
				venue_ends_at: null,
				// for date/time inputs
				input_starts_at: null,
				input_ends_at: null,
			},
			selectedEvent: {
				name: '',
				type: 'event',
				status: 'private',
				venue_id: null,
				venue_slot_id: null,
				starts_at: null,
				ends_at: null,
				venue_starts_at: null,
				venue_ends_at: null,
				// for date/time inputs
				input_starts_at: null,
				input_ends_at: null,
			},
			event: {},
			calendarOptions: {
				todayBtn: true,
				hoverActions: true,
			},
			days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
			calendarKey: 1,
			blockedDate: {
				starts_at: '',
				ends_at: '',
			},
		}
	},
	computed: {
		...mapState(['j', 'authPerson']),
		...mapGetters(['currentRole', 'isStaff']),
	},
	created() {
		this.getVenue()
	},
	mounted() {
		this.$modalSlot = new Modal(document.getElementById('slot-add'))
		this.$modalEvent = new Modal(document.getElementById('event-add'))
	},
	methods: {
		differenceInHours,
		handleResponseError,

		// Get Venue
		getVenue() {
			this.states.venue = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}`).then(
				({ data }) => {
					this.venue = data
					this.states.venue = 'idle'
				},
				error => {
					this.states.venue = 'error'
					Vue.toasted.error(`Error loading venue info (${error.response.data.message})`)
				}
			)
		},

		// Function that updates venue data
		updateVenue(fields, successMessage = 'Venue is updated') {
			heyGovApi.put(`/${this.j.slug}/venues/${this.venue.slug}`, fields).then(() => {
				for (const field in fields) {
					this.venue[field] = fields[field]
				}

				Vue.toasted.success(successMessage)
				this.calendarKey = this.calendarKey + 1
			}, handleResponseError(`Error updating venue ({error})`))
		},

		// Function that updates availability of venue
		updateAvailability() {
			this.updateVenue(
				{
					availability: this.venue.availability,
				},
				`Venue availability is updated`
			)

			this.states.availabilityChanged = false
		},

		// Insert new slot or update existing
		saveSlot() {
			if (this.activeSlot.type !== 'dates') {
				delete this.activeSlot.date_starts_at
				delete this.activeSlot.date_ends_at
			}

			if (this.activeSlot.id) {
				heyGovApi
					.put(`/${this.j.slug}/venues/${this.venue.slug}/slots/${this.activeSlot.id}`, {
						name: this.activeSlot.name,
						type: this.activeSlot.type,
						starts_at: this.activeSlot.starts_at,
						ends_at: this.activeSlot.ends_at,
						date_starts_at: this.activeSlot.date_starts_at || null,
						date_ends_at: this.activeSlot.date_ends_at || null,
						deposit: this.activeSlot.deposit,
						price: this.activeSlot.price,
						days: this.activeSlot.days,
					})
					.then(({ data }) => {
						const index = this.venue.slots.findIndex(s => s.id === data.id)
						this.venue.slots.splice(index, 1, data)

						this.$modalSlot.hide()
						this.calendarKey = this.calendarKey + 1
						Vue.toasted.success(`Slot "${data.name}" is updated`)
					}, handleResponseError("Couldn't update slot ({error})"))
			} else {
				heyGovApi.post(`/${this.j.slug}/venues/${this.venue.slug}/slots`, this.activeSlot).then(({ data }) => {
					this.venue.slots.push(data)

					this.calendarKey = this.calendarKey + 1

					this.$modalSlot.hide()
					Vue.toasted.success(`Slot successfully created!`)
				}, handleResponseError('Error adding slot ({error})'))
			}
		},
		removeSlot(slot) {
			if (confirm('Are you sure want to delete this slot?')) {
				heyGovApi.delete(`/${this.j.slug}/venues/${this.venue.slug}/slots/${slot.id}`).then(() => {
					if (slot.bookings_count) {
						this.$set(slot, 'deleted_at', true)
					} else {
						this.venue.slots = this.venue.slots.filter(s => s.id !== slot.id)
					}

					Vue.toasted.show(`Slot successfully deleted`)
				}, handleResponseError(`Error deleting slot ({error})`))
			}
		},

		// Restore soft deleted slot
		restoreSlot(slot) {
			if (confirm('Are you sure want to restore this slot?')) {
				heyGovApi
					.put(`/${this.j.slug}/venue-slots/${slot.id}`, {
						deleted_at: null,
					})
					.then(
						() => {
							slot.deleted_at = null
							Vue.toasted.success(`Slot successfully restored!`)
						},
						error => {
							Vue.toasted.error(error)
						}
					)
			}
		},

		// Set active slot
		setActiveSlot(slot) {
			if (slot.date_starts_at) {
				slot.date_starts_at = format(parseISO(slot.date_starts_at), "yyyy-MM-dd'T'HH:mm")
				slot.date_ends_at = format(parseISO(slot.date_ends_at), "yyyy-MM-dd'T'HH:mm")
			}

			this.activeSlot = window.structuredClone(slot)
		},

		// Add Event to current venue
		addEvent(date) {
			this.$set(this, 'selectedEvent', this.newEvent)
			this.setSelectedEventDate(date)
			this.$modalEvent.show()
		},

		// Edit event
		editEvent(event) {
			this.selectedEvent.id = event.id
			this.selectedEvent.name = event.name
			this.selectedEvent.type = event.type
			this.selectedEvent.status = event.status
			this.selectedEvent.venue_id = event.venue_id
			this.selectedEvent.venue_slot_id = event.venue_slot_id
			this.selectedEvent.starts_at = parseISO(event.starts_at)
			this.selectedEvent.ends_at = parseISO(event.ends_at)
			this.selectedEvent.venue_starts_at = event.venue_starts_at
			this.selectedEvent.venue_ends_at = event.venue_ends_at
			this.setSelectedEventDate(null, event)
			this.$modalEvent.show()
		},

		// Set initial date range for adding/updating an event
		setSelectedEventDate(date, event) {
			date = date || new Date()

			if (event) {
				this.selectedEvent.input_starts_at = event.venue_starts_at.slice(0, 16)
				this.selectedEvent.input_ends_at = event.venue_ends_at.slice(0, 16)
			} else {
				let startsAt = addHours(startOfHour(date), 2)
				let endsAt = addHours(startOfHour(date), 6)
				this.selectedEvent.input_starts_at = format(startsAt, "yyyy-MM-dd'T'HH:mm")
				this.selectedEvent.input_ends_at = format(endsAt, "yyyy-MM-dd'T'HH:mm")
			}

			document.getElementById('new-event-datetime-end').min = this.selectedEvent.input_starts_at
		},

		// Process datetime (venue & utc) based on date/time inputs
		updateSelectedEventDates() {
			if (this.selectedEvent.input_starts_at && this.selectedEvent.input_ends_at) {
				// dates in venue timezone
				let blockedStartDate = new Date(this.selectedEvent.input_starts_at)
				let blockedEndDate = new Date(this.selectedEvent.input_ends_at)
				this.selectedEvent.venue_starts_at = format(blockedStartDate, 'yyyy-MM-dd HH:mm:ss')
				this.selectedEvent.venue_ends_at = format(blockedEndDate, 'yyyy-MM-dd HH:mm:ss')

				// add min date for event end date picker
				document.getElementById('new-event-datetime-end').min = this.selectedEvent.input_starts_at
			}
		},

		// Add new event
		saveEvent() {
			if (this.selectedEvent.id) {
				heyGovApi
					.put(
						`/${this.j.slug}/venue-events/${this.selectedEvent.id}`,
						pick(this.selectedEvent, ['name', 'status', 'venue_starts_at', 'venue_ends_at'])
					)
					.then(
						() => {
							this.calendarKey = this.calendarKey + 1
							Vue.toasted.success(`Event successfully updated!`)
							this.$modalEvent.hide()
						},
						error => {
							Vue.toasted.error(`Opps, couldn't update this event (${error})`)
						}
					)
			} else {
				heyGovApi.post(`/${this.j.slug}/venues/${this.venue.id}/events`, this.selectedEvent).then(
					() => {
						// Rerender events in HeyCalendar
						this.calendarKey = this.calendarKey + 1
						Vue.toasted.success(`Event successfully created`)
						this.$modalEvent.hide()
					},
					error => {
						Vue.toasted.error(`Opps, couldn't create the event (${error})`)
					}
				)
			}
		},
	},
	watch: {
		'venue.availability': {
			deep: true,
			handler(availability, oldAvailability) {
				if (oldAvailability) {
					this.states.availabilityChanged = true
				}
			},
		},
		'selectedEvent.input_starts_at'() {
			this.updateSelectedEventDates()
		},
		'selectedEvent.input_ends_at'() {
			this.updateSelectedEventDates()
		},
	},
	filters: {
		slotLocalTime: function(time) {
			if (!time) return ''

			const date = parse(time, 'HH:mm:ss', new Date())
			const text = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })

			return text.startsWith(0) ? text.substr(1) : text
		},
	},
}
</script>
