<template>
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title my-0">
				Edit transcript line
			</h5>
			<button
				type="button"
				class="btn-close"
				data-bs-dismiss="modal"
				aria-label="Close"
				@click="$emit('close')"
			/>
		</div>
		<div class="modal-body">
			<div class="form-group mb-3">
				<label class="form-label">Current transcript text</label>

				<div class="rounded-1 bg-neutral-50 p-2">
					{{ line.text }}
				</div>
			</div>

			<div class="form-group">
				<label class="form-label">New transcript text</label>

				<textarea class="form-control" rows="5" v-model="text" required placeholder="🙈 can't be empty" />
			</div>
		</div>
		<div class="modal-footer justify-content-between">
			<button class="btn btn-sm" type="button" data-bs-dismiss="modal" @click="$emit('close')">
				Cancel
			</button>
			<button
				class="btn btn-sm btn-primary"
				data-bs-dismiss="modal"
				:disabled="text === line.text"
				@click="$emit('save', { ...line, text })"
			>
				Save
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'EditTranscriptLineForm',
	props: {
		line: {
			type: Object,
			required: true,
		},
	},
	data() {
		return { text: this.line.text }
	},
}
</script>
