<template>
	<a
		:href="url"
		target="_blank"
		class="badge fw-normal rounded-1 px-3"
		style="background: rgb(219, 216, 249); color: rgb(64, 51, 176)"
		><font-awesome-icon :icon="['fas', 'book-open']" :class="{ 'me-2': text }" /><template v-if="text">{{
			text
		}}</template></a
	>
</template>

<script>
export default {
	props: {
		url: {
			type: String,
			required: true,
		},
		text: {
			type: String,
		},
	},
}
</script>
