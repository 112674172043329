<template>
	<div v-if="!currentField.pdf">
		<select
			:id="'fsf-' + currentField.id"
			:class="'form-select ' + currentField.class"
			v-model="currentField.value"
			:multiple="multiple"
			:required="currentField.required == 1"
		>
			<option
				v-for="item in currentField.options.filter(o => !o.removed)"
				:key="`fsf-${currentField.id}-opt-${item.optionValue}`"
				:value="item.optionValue"
				:disabled="item.disabled"
				>{{ item.optionLabel }}</option
			>
		</select>
	</div>
	<!-- PDF View -->
	<div v-else>
		<div v-if="currentField.display !== 'form'" class="d-inline-flex mt-3">
			<label class="pdf-label form-label ms-0 pe-2" for="">{{ currentField.label }}</label>
			<div v-if="currentField.redacted && $route.query.redacted" class="pdf-redacted"></div>
			<div v-else>
				<div
					v-for="option in currentField.options"
					:key="`option-label-for-${option.optionLabel}`"
					class="d-inline-flex mx-1"
				>
					<!-- Multi Select -->
					<div
						v-if="currentField.data.multiple === true"
						style="width: 14px; height: 14px; border: 1px solid black"
					>
						<div
							v-for="value in currentField.value"
							:key="value"
							class="d-flex align-items-center justify-content-center"
						>
							<font-awesome-icon v-if="option.optionValue === value" :icon="['fas', 'check']" />
						</div>
					</div>
					<!-- Regular Select -->
					<div v-else style="width: 14px; height: 14px; border: 1px solid black">
						<div class="d-flex align-items-center justify-content-center">
							<font-awesome-icon
								v-if="option.optionValue === currentField.value"
								:icon="['fas', 'check']"
							/>
						</div>
					</div>
					<span class="ms-1">{{ option.optionLabel }}</span>
				</div>
			</div>
		</div>
		<div v-if="currentField.help" class="text-muted pdf-help">{{ currentField.help }}</div>
	</div>
</template>

<script>
export default {
	name: 'SelectList',
	props: ['currentField'],
	data() {
		return {}
	},
	created() {
		// Prevents error when send form request page is loaded
		if (!this.currentField.value && this.currentField.data.multiple) {
			this.currentField.value = []
		}
	},
	computed: {
		multiple: function() {
			return Boolean(this.currentField.data.multiple)
		},
	},
	watch: {
		'currentField.value'() {
			this.$emit('update', { field: this.currentField, value: this.currentField.value })
		},
	},
}
</script>
