<template>

	<div class="home text-center py-6">

		<h1>👷 Page in development</h1>
		<p class="lead">We're working hard to bring this page online</p>

	</div>

</template>

<script>
export default {
	name: 'PageNotFound',
}
</script>
