<template>
	<div class="page-workflows">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h4 class="my-0">Workflows</h4>
			</div>
			<div class="col-auto">
				<button class="btn btn-primary btn-sm btn-big-icon" disabled>
					<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add workflow
				</button>
			</div>
		</div>

		<div class="card">
			<div class="card-body">
				<div class="btn-group mb-3">
					<button class="btn btn-sm btn-primary">All</button>
					<button class="btn btn-sm btn-outline-primary">Forms</button>
					<button class="btn btn-sm btn-outline-primary">Meetings</button>
					<button class="btn btn-sm btn-outline-primary">Payments</button>
					<button class="btn btn-sm btn-outline-primary">Venues</button>
				</div>

				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Workflow</th>
								<th>Trigger</th>
								<th>Actions</th>
								<th>Added by</th>
								<th>Added on</th>
								<th>Runs</th>
								<th>Last run</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Send email to Atanas</td>
								<td><code>form.created</code></td>
								<td>
									<span class="badge bg-primary-50 text-primary-400">Send email</span>
								</td>
								<td>Andrei</td>
								<td>{{ '2022-10-17 13:13:13' | dateLocal }}</td>
								<td>713</td>
								<td>1h ago</td>
							</tr>
							<tr>
								<td>Download meeting recording</td>
								<td><code>meeting.updated.video_public_url</code></td>
								<td>
									<span class="badge bg-warning-50 text-warning-400">Download video</span>
								</td>
								<td>Andrei</td>
								<td>{{ '2024-07-06 13:13:13' | dateLocal }}</td>
								<td>17</td>
								<td>20m ago</td>
							</tr>
							<tr>
								<td>Notify canceled reservation</td>
								<td><code>venue_booking.canceled</code></td>
								<td>
									<span class="badge bg-primary-50 text-primary-400">Send email</span>
								</td>
								<td>Andrei</td>
								<td>{{ '2022-07-10 13:13:13' | dateLocal }}</td>
								<td>0</td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
	name: 'Workflows',
	data() {
		return {}
	},
	computed: {
		...mapState(['j']),
	},
}
</script>
