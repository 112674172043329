<template>
	<div class="municipality-select">
		<div v-if="!onboarded && !j" class="text-center pt-4">
			<h2 class="text-center mt-0">Welcome to</h2>
			<img
				src="https://files.heygov.com/assets/heygov-logo.png"
				width="70"
				height="70"
				class="mb-4"
				alt="HeyGov"
			/>

			<p class="text-center mb-1">Connect with your municipality and ⬇️</p>
			<p class="text-center mb-1">✔️ Report Issues so they get resolved</p>
			<p class="text-center mb-1">🔎 Keep track of issues you've reported</p>
			<p class="text-center mb-1">💬 Communicate with your civil servants</p>
			<p class="text-center mb-5">🤝 Share your opinion with your local government</p>
		</div>

		<h3 class="mb-3 text-center">🚦 Select your municipality</h3>

		<div class="row justify-content-center">
			<div class="col-md-8 col-lg-6">
				<div class="card mb-3">
					<div class="card-body px-3">
						<div class="input-group mb-3">
							<input
								autocomplete="off"
								type="search"
								id="muni-search"
								class="form-control"
								v-model="q"
								placeholder="Search…"
								@keydown="handleKeyboard"
							/>
							<button v-if="!geolocation" class="btn btn-sm" @click="askGeoLocation">📍</button>
						</div>

						<div v-if="state === 'idle'" class="list-group mb-1 munis-search-results rounded-1">
							<span
								v-for="(jurisdiction, index) in filteredJurisdictions"
								:key="`jurisdictions-search-${jurisdiction.id}`"
								class="list-group-item list-group-item-action cursor-pointer"
								:class="{ 'bg-info-lighter': index === highlight }"
								@click="selectJurisdiction(jurisdiction)"
								@mouseover="mouseOver(index)"
							>
								{{ jurisdiction.name
								}}<small
									v-if="jurisdiction.location.state || jurisdiction.location.county"
									class="text-muted"
									>,
									{{
										[jurisdiction.location.county, jurisdiction.location.state]
											.filter(Boolean)
											.join(', ')
									}}</small
								>
								<span
									v-if="jurisdiction.role && roles[jurisdiction.role]"
									class="badge mx-1"
									:class="[roles[jurisdiction.role].class]"
									>{{ roles[jurisdiction.role].name }}</span
								>
								<span v-if="jurisdiction.testmode" class="badge bg-danger-lighter text-danger mx-1"
									>Test</span
								>
								<small v-if="jurisdiction.distance" class="float-end text-muted"
									>{{ convertDistance(jurisdiction.distance, 'miles') }}mi away</small
								>
							</span>
						</div>
						<div v-else-if="state === 'loading'" class="text-center py-3">
							<div class="spinner-border" role="status"></div>
						</div>
						<div v-else-if="state === 'error'" class="py-2">
							<div class="alert alert-danger">
								{{ error || 'Unknown error' }}. <small class="text-muted">Retrying in 10s</small>
							</div>
						</div>
					</div>
				</div>

				<p class="text-center">
					<router-link to="/municipality-not-using-heygov" class="text-muted"
						>Municipality not found?</router-link
					>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { sortBy } from 'lodash'
import Vue from 'vue'
import { Geolocation } from '@capacitor/geolocation'

import { distanceBetweenCoordinates } from '@/lib/geo.js'
import heyGovApi from '@/api.js'

export default {
	name: 'AccountJurisdiction',
	data() {
		return {
			state: 'loading',
			munis: [],
			account: {
				email: '',
				password: '',
			},
			error: '',
			q: '',
			highlight: 0,
		}
	},
	created() {
		this.loadJurisdictions()
	},
	mounted() {
		if (!this.isMobile) {
			document.getElementById('muni-search').focus()
		}
	},
	computed: {
		...mapGetters(['isStaff']),
		...mapState(['j', 'isMobile', 'jurisdictions', 'onboarded', 'roles', 'geolocation', 'device']),
		filteredJurisdictions() {
			let jurisdictions = this.munis.map(j => {
				// jurisdictions assigned to current auth person
				if (this.jurisdictions) {
					const authJurisdiction = this.jurisdictions.find(j2 => j2.id == j.id)

					// if auth has jurisdiction, append the role
					if (authJurisdiction) {
						j.role = authJurisdiction.role
						j.roles = authJurisdiction.roles
						j.department_id = authJurisdiction.department_id
					}
				}

				return j
			})

			let q = this.q.toLowerCase()

			// shortcut for heyville.org
			if (q === 'heyville') {
				q = 'heyville.org'
			}

			// filter by person role, show test munis only to HeyGov staff
			if (!this.isStaff) {
				jurisdictions = jurisdictions.filter(j => j.public || j.slug === q)
			}

			// filter by search query
			if (q) {
				jurisdictions = jurisdictions.filter(j => j.name.toLowerCase().includes(q) || j.slug.includes(q))
			}

			if (this.geolocation) {
				jurisdictions = jurisdictions.map(j => {
					j.distance = this.distanceBetweenCoordinates(
						j.location.lat,
						j.location.lng,
						this.geolocation.latitude,
						this.geolocation.longitude,
						'miles'
					)

					return j
				})

				jurisdictions = sortBy(jurisdictions, j => j.distance)
			}

			return jurisdictions
		},
	},
	methods: {
		distanceBetweenCoordinates,

		loadJurisdictions() {
			this.state = 'loading'

			heyGovApi('/jurisdictions').then(
				({ data }) => {
					this.munis.push(...data)
					this.state = 'idle'
				},
				error => {
					this.state = 'error'
					this.error = `Failed to load list of jurisdictions (${error.message})`

					setTimeout(() => {
						this.loadJurisdictions()
					}, 10000)
				}
			)
		},

		selectJurisdiction(j) {
			// send app open event
			if (this.j?.slug !== j.slug) {
				heyGovApi.get(`/${j.slug}/say-this/app-open`, {
					params: {
						hey: this.device.uuid || localStorage.getItem('heygov-hey'),
					},
				})
			}

			// empty data for current jurisdicton
			this.$store.dispatch('clearJurisdictionData')
			this.$store.commit('setCurrentJurisdiction', j)

			if (this.$route.query.redirect) {
				this.$router.push(this.$route.query.redirect.replace(':jurisdiction', this.j.slug))
			} else if (j.role && ['WORKER', 'EDITOR', 'ADMIN'].includes(j.role)) {
				this.$router.push(`/${j.slug}/dashboard`)
			} else {
				this.$router.push(`/${this.j.slug}`)
			}
		},
		convertDistance(km, toUnit) {
			if (toUnit === 'miles') {
				km = km / 1.609344
			}

			return Math.round(km)
		},
		askGeoLocation() {
			Geolocation.getCurrentPosition().then(
				position => {
					this.$store.commit('setGeolocation', {
						latitude: position.coords.latitude,
						longitude: position.coords.longitude,
						altitude: position.coords.altitude || null,
					})

					setTimeout(() => {
						if (this.filteredJurisdictions[0].distance < 10) {
							this.selectJurisdiction(this.filteredJurisdictions[0])
							Vue.toasted.show(`Welcome to "${this.filteredJurisdictions[0].name}"`, { type: 'success' })
						} else {
							Vue.toasted.show(`Your municipality is not using HeyGov yet 😕`)
						}
					}, 250)
				},
				error => {
					Vue.toasted.error(error)
				}
			)
		},
		handleKeyboard: function(event) {
			switch (event.keyCode) {
				case 13: {
					const highlightedJurisdiction = this.filteredJurisdictions.find((j, index) => {
						return index === this.highlight
					})

					if (highlightedJurisdiction) {
						this.selectJurisdiction(highlightedJurisdiction)
					} else {
						Vue.toasted.show('No jurisdiction selected 😬')
					}

					break
				}
				case 38:
					if (this.highlight === 0) {
						this.highlight = this.filteredJurisdictions.length - 1
					} else {
						this.highlight--
					}
					break
				case 40:
					if (this.highlight === this.filteredJurisdictions.length - 1) {
						this.highlight = 0
					} else {
						this.highlight++
					}
					break
			}
		},
		mouseOver(index) {
			this.highlight = index
		},
	},
	watch: {
		q() {
			this.highlight = 0
		},
	},
}
</script>
