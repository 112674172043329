<template>
	<div v-if="venue" ref="pageVenueEmbed" class="page-venue-embed d-flex justify-content-center">
		<div class="col-venue-tabs">
			<div class="scrolling-tabs mb-3">
				<nav class="hide-scrollbar">
					<ul
						class="nav nav-pills nav-pills-sm hide-scrollbar"
						style="flex-wrap: nowrap; min-width: 100%; overflow-x: scroll;"
					>
						<li class="nav-item">
							<span class="nav-link" :class="{ active: tab == 'calendar' }" @click="setTab('calendar')"
								>Availability
							</span>
						</li>
						<li class="nav-item">
							<span class="nav-link" :class="{ active: tab == 'info' }" @click="setTab('info')"
								>Venue info</span
							>
						</li>
						<li class="nav-item">
							<span class="nav-link" :class="{ active: tab == 'location' }" @click="setTab('location')"
								>Location</span
							>
						</li>
						<li
							class="nav-item"
							v-if="venue.text_rules || venue.message_check_in || venue.message_check_out"
						>
							<span
								class="nav-link"
								:class="{ active: tab == 'rules-guides' }"
								@click="setTab('rules-guides')"
								>Rules &amp; guides</span
							>
						</li>
					</ul>
				</nav>
			</div>

			<VenueCalendarPublic
				v-if="tab === 'calendar'"
				:venue="venue"
				:isEmbed="true"
				:options="calendarOptions"
				@calendar-updated="setIframeHeight"
			/>

			<div v-else-if="tab === 'info'">
				<div v-html="enrichContent(venue.description)" class="mb-3"></div>

				<div class="venue-images mb-4 d-flex flex-wrap gap-2">
					<img
						v-for="(img, index) in venue.images"
						:key="`${img}-${index}`"
						:src="img.src"
						:alt="img.title"
						class="rounded-1 cursor-pointer"
						height="150"
						@click="showImg(index)"
					/>
					<!-- <figure class="figure">
						<img :src="img.src" class="figure-img img-fluid rounded" :alt="img.alt" />
						<figcaption class="figure-caption">{{ img.alt }}</figcaption>
					</figure> -->
				</div>

				<vue-easy-lightbox
					:visible="visible"
					:imgs="venue.images"
					:index="index"
					@hide="handleHide"
				></vue-easy-lightbox>

				<div v-if="venue.amenities_text">
					<h5>Amenities</h5>

					<div v-html="venue.amenities_text"></div>
				</div>
			</div>

			<div v-else-if="tab === 'location'">
				<h5 class="mb-3">{{ venue.location.name }}</h5>

				<p><span class="text-neutral-400">Address:</span> {{ venue.location.address }}</p>

				<muni-map
					class="mb-3"
					:center="{ lat: venue.location.lat, lng: venue.location.lng }"
					:height="400"
					:includeDepartments="false"
					:includeVenues="venue.id"
				></muni-map>

				<p class="card-text">
					<a :href="venue.location.url" target="_blank">Open Google Maps</a>
				</p>
			</div>

			<div v-else-if="tab === 'rules-guides'">
				<div v-if="venue.message_check_in" class="mb-4">
					<h5>Check in</h5>

					<p class="card-text">{{ venue.message_check_in }}</p>
				</div>

				<div v-if="venue.message_check_in" class="mb-4">
					<h5>Check out</h5>

					<p class="card-text">{{ venue.message_check_out }}</p>
				</div>

				<div v-if="venue.text_rules">
					<h5>Rules &amp; guides</h5>

					<div v-html="venue.text_rules"></div>
				</div>

				<div v-if="venue.public_files.length">
					<h6>Documents &amp; files</h6>

					<ul class="nicer-list">
						<li v-for="file in venue.public_files" :key="`venue-file-${file.file}`">
							<a :href="getPublicFileUrl(file.file)" target="_blank">{{ file.name }}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.col-venue-tabs {
	width: 100%;
	max-width: 600px;
}
</style>

<script>
import { mapState } from 'vuex'
//import { addHours, format, addMinutes } from 'date-fns'

import heyGovApi from '@/api.js'
import { enrichContent, firstParagraphOrWords, getPublicFileUrl } from '@/utils.js'

import VenueCalendarPublic from '@/components/venues/VenueCalendarPublic'
import MuniMap from '@/components/MuniMap.vue'

export default {
	name: 'VenuePublicPage',
	metaInfo() {
		return {
			title: this.venue?.name || this.$route.params.venueSlug,
		}
	},
	components: { VenueCalendarPublic, MuniMap },
	data() {
		return {
			venue: null,
			tab: this.$route.params.tab || 'calendar',
			calendarOptions: {
				todayBtn: true, // Display button that returns you to today date
			},
			loadingSlots: false,
			state: 'idle',
			venueError: '',

			visible: false, // For image preview
			index: 0, // For image preview
		}
	},
	computed: {
		...mapState(['j']),
	},
	created() {
		this.getVenue()
		this.$store.dispatch('accountInfo')
	},
	methods: {
		enrichContent,
		firstParagraphOrWords,
		getPublicFileUrl,

		getVenue() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}?expand=images`).then(
				({ data }) => {
					this.venue = data
					this.state = 'idle'

					setTimeout(() => {
						this.setIframeHeight()
					}, 50)
				},
				error => {
					this.venueError = error.message
					this.state = 'error'
				}
			)
		},

		setTab(tab) {
			this.tab = tab
			//this.$router.push({ name: 'venue', params: { venueSlug: this.venue.slug, tab } })

			this.setIframeHeight()
		},

		setIframeHeight() {
			const $page = this.$refs.pageVenueEmbed

			if ($page) {
				const box = $page.getBoundingClientRect()

				const message = {
					heygov_action: 'set-element-style',
					element: `#heygov-venue-${this.venue.slug}`,
					styles: {
						height: `${Math.ceil(box.height)}px`,
					},
				}

				setTimeout(() => {
					window.parent.postMessage(message, '*')
				}, 50)
			}
		},

		showImg(index) {
			this.index = index
			this.visible = true
		},
		handleHide() {
			this.visible = false
		},
	},
}
</script>
