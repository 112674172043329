const paymentPlatforms = {
	heygov: {
		name: 'HeyGov Pay',
		description: 'Cash and check payments',
		logo: 'https://app.heygov.com/img/icons/favicon-32x32.png',
		flows: [],
	},
	stripe: {
		name: 'Stripe',
		description: 'ACH Direct Debit, credit & debit card payments, online and with card reader',
		faIcon: ['fab', 'cc-stripe'],
		brandColor: '#008CDD',
		flows: ['connect', 'create'],
	},
	justifi: {
		name: 'JustiFi',
		description: 'Credit and debit card payments, only online',
		logo: 'https://storage.googleapis.com/edge.heygov.com/integrations/justifi-icon.png',
		brandColor: '#FCCC32',
		flows: ['create'],
	},
	paygov: {
		name: 'PayGov',
		description: 'Credit and debit card payments, only online',
		logo: 'https://edge.heygov.com/integrations/paygovus-logo.png',
		flows: ['connect'],
	},
	municipay: {
		name: 'MuniciPay',
		description: 'Credit and debit card payments, only online',
		logo: 'https://edge.heygov.com/integrations/municipay-logo.png',
		flows: ['connect'],
	},
	bridgepay: {
		name: 'BridgePay',
		description: 'Credit and debit card payments, only online',
		logo: 'https://app.heygov.com/img/icons/favicon-32x32.png',
		flows: [],
	},
}

const paymentStatuses = {
	succeeded: {
		name: 'Paid',
		class: 'bg-success-lighter text-success',
		text: 'The amount was successfully transferred to muni balance',
	},
	requires_payment_method: {
		name: 'Waiting',
		class: 'bg-light text-gray',
		text: 'Payment is started, requires payment method',
	},
	pending: {
		name: 'Pending',
		class: 'bg-light text-gray',
		text: 'Payment is started, requires payment method',
	},
	requires_capture: {
		name: 'On hold',
		class: 'bg-warning-lighter text-warning',
		text: 'Money was authorized from card, but not yet transferred',
	},
	processing: {
		name: 'Processing',
		class: 'bg-warning-lighter text-warning',
		text: 'The bank is processing the payment',
	},
	refunded: {
		name: 'Refunded',
		class: 'bg-info-lighter text-info',
		text: 'Paid amount was refunded',
	},
	refunded_partial: {
		name: 'Partial refund',
		class: 'bg-info-lighter text-info',
		text: 'Partial amount was refunded',
	},
	refunded_full: {
		name: 'Full refund',
		class: 'bg-info-lighter text-info',
		text: 'Paid amount + fee was refunded',
	},
	canceled: {
		name: 'Canceled',
		class: 'bg-danger-lighter text-danger',
		text: 'Payment was canceled, no money was taken from card',
	},
	expired: {
		name: 'Expired',
		class: 'bg-danger-lighter text-danger',
		text: 'Payment request expired (7 days from start), no money was taken from card',
	},
}

const paymentIntegrations = {
	workhorse: {
		name: 'Workhorse Utility Billing',
		billingAccounts: true,
		acceptFile: '.csv,text/csv',
	},
	banyon: {
		name: 'Banyon Accounting',
		billingAccounts: true,
		acceptFile: '.csv,text/csv',
	},
	'southern-software': {
		name: 'Southern Software',
		billingAccounts: true,
		acceptFile: '.txt,text/plain',
	},
	pelorus: {
		name: 'Pelorus Financial',
		billingAccounts: true,
		acceptFile: '.csv,text/csv',
	},
	nemrc: {
		name: 'NEMRC',
		billingAccounts: true,
		acceptFile: '.csv,text/csv',
	},
	ubmax: {
		name: 'UBMax',
		billingAccounts: true,
		acceptFile: '.csv,text/csv',
	},
}

const paymentFeeText = (platform, method = 'card') => {
	let text = '-'

	if (platform === 'stripe') {
		if (method === 'card') {
			text = `2.9% + 30¢`
		} else if (method === 'card_present' || method === 'card-present') {
			text = `2.9% + 30¢`
		}
	} else if (platform === 'justifi') {
		if (method === 'card') {
			text = `2.9% + 30¢`
		} else if (method === 'card_present' || method === 'card-present') {
			text = `2.9% + 30¢`
		}
	} else if (platform === 'paygov') {
		if (method === 'card') {
			text = `$1`
		}
	} else if (platform === 'municipay') {
		if (method === 'card') {
			text = `2.65%, minimum $3`
		}
	} else if (platform === 'bridgepay') {
		if (method === 'card') {
			text = `3%, minimum $2`
		}
	}

	return text
}

const paymentFee = (platform, method, amount, rate) => {
	amount = Number(amount)
	let fee = 0

	if (platform === 'stripe') {
		if (method === 'card') {
			fee = stripeFee(amount, 'online')
		} else if (method === 'card_present' || method === 'card-present') {
			fee = stripeFee(amount, 'in-person')
		} else if (method === 'us_bank_account') {
			fee = amount / (1 - 0.008) - amount
			fee = Math.min(fee, 5)
		}
	} else if (platform === 'justifi') {
		if (method === 'card') {
			fee = stripeFee(amount, 'online')
		} else if (method === 'card_present' || method === 'card-present') {
			fee = stripeFee(amount, 'in-person')
		}
	} else if (platform === 'paygov') {
		if (method === 'card') {
			fee = paygovFee(amount, rate)
		}
	} else if (platform === 'municipay') {
		if (method === 'card') {
			fee = Math.max(amount * 0.0265, 3)
		}
	} else if (platform === 'bridgepay') {
		if (method === 'card') {
			fee = Math.max(amount * 0.03, 2)
		}
	}

	// round to only 2 decimal places
	fee = Math.round(fee * 100) / 100

	return fee
}

const stripeFee = (amount, type) => {
	if (!type || !['online', 'in-person'].includes(type)) {
		throw new TypeError('Type is required')
	}

	amount = Number(amount)

	let fee = type === 'online' ? (amount + 0.3) / (1 - 0.029) - amount : (amount + 0.05) / (1 - 0.027) - amount
	//console.log('stripe fee', { amount, type, fee })

	fee = Math.round(fee * 100) / 100
	//console.log('stripe fee', { amount, type, fee })

	return fee
}

const paygovFee = (amount, rate) => {
	let fee = 0
	amount = Number(amount)

	//console.log('paygov fee', { amount, rate })

	const applyRate = r => {
		r = String(r)

		if (r.endsWith('%')) {
			fee = (amount * Number(r.slice(0, -1))) / 100
		} else {
			fee = Number(r)
		}
	}

	if (rate.includes(';')) {
		const rates = rate.split(';')
		const bestRate = rates.find(r => amount > Number(r.split('-')[0]))

		if (bestRate) {
			applyRate(bestRate.split('-')[1])
		}
	} else {
		applyRate(rate)
	}

	fee = Math.round(fee * 100) / 100
	//console.log('stripe fee', { amount, type, fee })

	//console.log('paygov fee', { amount, rate })

	return fee
}

const bridgePayAccounts = {
	heyg013test: 'tokenpay16008api20232718032720716',
	'15882457supe': 'tokenpay31826api20230824050843766',
}

export {
	paymentPlatforms,
	paymentStatuses,
	paymentIntegrations,
	paymentFeeText,
	paymentFee,
	paygovFee,
	stripeFee,
	bridgePayAccounts,
}
