<template>
	<div v-if="mode === 'pdf'">
		<div v-if="currentField.display !== 'form'">
			<label class="pdf-label form-label" for="">{{ currentField.label }}</label>
			<div v-if="currentField.redacted && this.$route.query.redacted" class="pdf-redacted"></div>
			<div v-else class="pdf-field">
				{{ isAddressObject(currentField.value) ? formatAddress(currentField.value) : currentField.value }}
			</div>
		</div>
		<small v-if="currentField.help" class="text-muted pdf-help">{{ currentField.help }}</small>
	</div>
	<div v-else :class="currentField.class">
		<gmap-autocomplete @place_changed="setfFieldValue" :select-first-on-enter="true" :options="autocompleteOptions">
			<template v-slot:default="slotProps">
				<div class="form-floating mb-3">
					<input
						:id="'fsf-' + currentField.id"
						class="form-control"
						:class="{ 'is-invalid': states.address === 'error' }"
						ref="input"
						:value="currentField.value.line1"
						placeholder="Street address"
						autocomplete="address-line1"
						:required="currentField.required == 1"
						v-on="slotProps.listeners"
						@focus="states.address = 'idle'"
						@blur="setAddressPart"
					/>
					<label :for="'fsf-' + currentField.id">Address</label>
				</div>
			</template>
		</gmap-autocomplete>

		<div class="form-floating mb-3">
			<input
				type="text"
				class="form-control"
				:id="`fsf-${currentField.id}-line2`"
				placeholder="Apartment, suite, etc. (optional)"
				v-model="currentField.value.line2"
				autocomplete="address-line2"
			/>
			<label :for="`fsf-${currentField.id}-line2`">Apartment, suite, etc. (optional)</label>
		</div>

		<div class="row gx-3">
			<div class="col-12 col-lg-5">
				<div class="form-floating mb-3">
					<input
						type="text"
						class="form-control"
						:id="`fsf-${currentField.id}-city`"
						placeholder="City"
						v-model="currentField.value.city"
						:required="currentField.required == 1"
						autocomplete="address-level2"
					/>
					<label :for="`fsf-${currentField.id}-city`">City</label>
				</div>
			</div>
			<div class="col-6 col-lg-3">
				<div class="form-floating mb-3">
					<select
						class="form-select"
						:id="`fsf-${currentField.id}-state`"
						placeholder="State"
						v-model="currentField.value.state"
						:required="currentField.required == 1"
						autocomplete="address-level1"
					>
						<option v-for="(label, code) in usStates" :key="code" :value="code">{{ label }}</option>
					</select>
					<label :for="`fsf-${currentField.id}-state`">State</label>
				</div>
			</div>
			<div class="col-6 col-lg-4">
				<div class="form-floating mb-3">
					<input
						type="text"
						class="form-control"
						:id="`fsf-${currentField.id}-postal-code`"
						placeholder="Postal code"
						v-model="currentField.value.postal_code"
						:required="currentField.required == 1"
						inputmode="numeric"
						minlength="5"
						autocomplete="postal-code"
					/>
					<label :for="`fsf-${currentField.id}-postal-code`">ZIP code</label>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { isAddressObject, usStates } from '@/lib/geo.js'
import { formatAddress } from '@/utils.js'

export default {
	name: 'AddressInput',
	props: {
		currentField: {
			type: Object,
			required: true,
		},
		mode: {
			type: String,
			default: 'fill',
		},
	},
	data() {
		return {
			states: {
				address: 'idle',
			},
			autocompleteOptions: {
				componentRestrictions: { country: 'us' },
				fields: ['geometry', 'address_components', 'url', 'place_id'],
			},
			usStates,
		}
	},
	created() {
		if (!isAddressObject(this.currentField.value)) {
			this.currentField.value = {
				lat: null,
				lng: null,
				line1: '',
				line2: '',
				city: '',
				state: '',
				postal_code: '',
				country: 'US',
			}
		}
	},
	methods: {
		isAddressObject,
		formatAddress,

		setfFieldValue(place) {
			let street = ''
			let route = ''

			place.address_components.forEach(part => {
				if (part.types.includes('street_number')) {
					street = part.long_name
				} else if (part.types.includes('route')) {
					route = part.long_name
				} else if (part.types.includes('locality')) {
					this.currentField.value.city = part.long_name
				} else if (part.types.includes('administrative_area_level_1')) {
					this.currentField.value.state = part.short_name
				} else if (part.types.includes('postal_code')) {
					this.currentField.value.postal_code = part.long_name
				} else if (part.types.includes('country')) {
					this.currentField.value.country = part.short_name
				}
			})

			this.currentField.value.line1 = `${street} ${route}`.trim()
			this.currentField.value.lat = place.geometry.location.lat()
			this.currentField.value.lng = place.geometry.location.lng()
		},
		setAddressPart($event) {
			this.currentField.value.line1 = $event.target.value
		},
	},
}
</script>
