<template>
	<span
		class="badge"
		:class="formRequestStatuses[formRequest.status].class"
		:title="formRequestStatuses[formRequest.status].text"
		>{{ formRequestStatuses[formRequest.status].name }}</span
	>
</template>

<script>
import { Tooltip } from 'bootstrap'
import { formRequestStatuses } from '@/actions/forms.js'

export default {
	name: 'FormRequestStatus',
	props: {
		formRequest: Object,
		tooltip: Boolean,
	},
	data() {
		return {
			formRequestStatuses,
		}
	},
	mounted() {
		if (this.tooltip) {
			new Tooltip(this.$el)
		}
	},
}
</script>
