<template>
	<div class="poll-detail">
		<div v-if="poll">
			<h2 class="text-primary">
				{{ poll.title }}
				<small v-if="currentRole === 'ADMIN'" class="badge bg-danger-lighter text-danger">{{
					poll.status
				}}</small>
			</h2>

			<ul v-if="currentRole !== 'CITIZEN'" class="nav nav-tabs mb-4">
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}`" class="nav-link active"
						>Straw poll page</router-link
					>
				</li>
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}/edit`" class="nav-link disabled">Edit</router-link>
				</li>
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}/responses`" class="nav-link"
						>Responses <span class="badge bg-light text-gray">{{ poll.responses }}</span></router-link
					>
				</li>
				<li class="nav-item">
					<router-link :to="`/${j.slug}/polls/${poll.slug}/summary`" class="nav-link">Summary</router-link>
				</li>
			</ul>

			<div class="row">
				<div class="col">
					<p>✅ {{ poll.responses }} {{ poll.responses == 1 ? 'response' : 'responses' }}</p>
				</div>
				<div class="col-auto text-end">
					<p v-if="poll.status === 'active'">
						<small class="text-danger"
							>🕒 only {{ formatDistanceToNow(new Date(poll.closes_at)) }} left</small
						>
					</p>
					<p v-if="['closed', 'completed'].includes(poll.status)">
						<small class="text-muted"
							>🕒 Straw poll closed
							{{ formatDistanceToNow(new Date(poll.closes_at), { addSuffix: true }) }}</small
						>
					</p>
				</div>
			</div>

			<div class="row justify-content-center">
				<div class="col-md-10 col-lg-8">
					<div v-if="poll.status !== 'completed'" class="card mb-4">
						<div class="card-body">
							<p class="card-text">
								{{ states.shortDescription ? pollDescriptionShort : poll.description }}
								<button
									v-if="states.shortDescription"
									class="btn btn-sm btn-link"
									@click="states.shortDescription = false"
								>
									Read more
								</button>
							</p>
						</div>
					</div>

					<div v-if="poll.status === 'completed'" class="my-4">
						<div class="card mb-4">
							<div class="card-body">
								<h2 class="card-title">{{ poll.summary_title }}</h2>
								<p class="card-text">{{ poll.summary_text }}</p>

								<div class="row">
									<div v-for="file in poll.summary_files" :key="`poll-file-${file}`" class="col-4">
										<a :href="file" target="_blank">
											<img
												v-if="file.includes('.pdf')"
												src="https://files.heygov.com/assets/icon-pdf.jpg"
												class="img-thumbnail"
												alt="Image"
											/>
											<img v-else :src="file" class="img-thumbnail" alt="Image" />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div class="card mb-4">
							<div class="card-body">
								<h2 class="card-title">Straw poll info</h2>
								<p>
									This straw poll ran from {{ new Date(poll.created_at).toLocaleDateString() }} to
									{{ new Date(poll.closes_at).toLocaleDateString() }}.
								</p>
								<p>{{ poll.description }}</p>
								<p class="lead">Questions asked:</p>
								<ol>
									<li v-for="question in poll.questions" :key="question.id" class="card-text">
										{{ question.title }}
									</li>
								</ol>
							</div>
						</div>

						<div class="poll-actions text-center">
							<router-link :to="`/${j.slug}`" class="btn btn-outline-primary m-2"
								>← <span class="text-capitalize">{{ j.type }}</span> overview</router-link
							>
							<button class="btn btn-primary m-2" @click="sharePoll">📣 Share the results</button>
						</div>
					</div>

					<div v-else-if="answered" class="text-center my-5">
						<p>
							<img
								src="https://files.heygov.com/assets/illustration-pablo-friendship.jpg"
								class="rounded"
								width="200"
								alt="HeyGov Sent"
							/>
						</p>
						<h3 class="mb-4">We got your response</h3>
						<p>
							Your response is sent to <strong>{{ j.name }}</strong
							>. We'll let you know about when the straw poll is completed and we have all results.
						</p>

						<div class="poll-actions">
							<router-link :to="`/${j.slug}`" class="btn btn-outline-primary m-2"
								>← <span class="text-capitalize">{{ j.type }}</span> overview</router-link
							>
							<button class="btn btn-primary m-2" @click="sharePoll">📣 Share this straw poll</button>
						</div>

						<p
							v-if="currentRole !== 'CITIZEN'"
							class="bg-white border border-dashed border-secondary rounded py-2 px-4 text-center mt-5"
						>
							<button class="btn btn-secondary btn-big-icon mx-2" @click="newResponse(true)">
								Add another response
								<font-awesome-icon :icon="['fas', 'plus-circle']" />
							</button>
							<small>Option available only to {{ j.type }} staff</small>
						</p>
					</div>

					<div v-else-if="poll.status !== 'active'" class="text-center my-5">
						<h3>This straw poll is closed for new responses</h3>
						<p>Register on HeyGov to be notified about the next straw poll.</p>
						<p>
							<img
								src="https://files.heygov.com/assets/illustration-pablo-success.jpg"
								class="rounded"
								width="350"
								alt="HeyGov Sent"
							/>
						</p>
					</div>

					<form v-else @submit.prevent="saveAnswers" class="py-4">
						<h6>Questions</h6>

						<div v-for="(question, index) in poll.questions" :key="question.id" class="card mb-4">
							<div class="card-body">
								<h3>
									<label :for="`question-${question.id}`" class="mb-0"
										>{{ index + 1 }}) <span v-html="doMarkdown(question.title)"></span
									></label>
								</h3>

								<div v-if="question.type === 'boolean'">
									<div class="custom-control custom-radio">
										<input
											type="radio"
											:id="`question-${question.id}`"
											:name="`question-${question.id}`"
											v-model="question.answer"
											value="1"
											:required="question.required"
											class="custom-control-input"
										/>
										<label class="custom-control-label" :for="`question-${question.id}`"
											>Yes, I support this</label
										>
									</div>
									<div class="custom-control custom-radio">
										<input
											type="radio"
											:id="`question-${question.id}-0`"
											:name="`question-${question.id}`"
											v-model="question.answer"
											value="0"
											:required="question.required"
											class="custom-control-input"
										/>
										<label class="custom-control-label" :for="`question-${question.id}-0`"
											>No, I do not support this</label
										>
									</div>
								</div>
								<div v-if="question.type === 'text'">
									<textarea
										class="form-control"
										:id="`question-${question.id}`"
										placeholder="Write your opinion here"
										v-model="question.answer"
										:required="question.required"
									></textarea>
								</div>
								<div v-if="question.type === 'contact'">
									<div class="form-group mb-2">
										<label for="person-name">Your name</label>
										<input
											type="text"
											autocomplete="name"
											class="form-control"
											v-model="question.answer.name"
											:required="question.required"
											id="person-name"
										/>
									</div>
									<div class="form-group mb-2">
										<label for="person-email">Your email address</label>
										<input
											type="email"
											autocomplete="email"
											class="form-control"
											v-model="question.answer.email"
											:required="question.required"
											id="person-email"
										/>
									</div>
									<div class="form-group mb-2">
										<label for="person-address">Your address</label>
										<gmap-autocomplete
											class="form-control"
											id="person-address"
											:required="question.required"
											autocomplete="address"
											:value="question.answer.address"
											@place_changed="setAnswerAddress"
											:select-first-on-enter="true"
										>
										</gmap-autocomplete>
									</div>
								</div>
							</div>
						</div>

						<p v-if="poll.disclaimer" class="text-center text-muted">
							<small>{{ poll.disclaimer }}</small>
						</p>

						<p class="text-center">
							<button class="btn btn-primary px-5" :disabled="state === 'sending'">Send answers</button>
						</p>
					</form>
				</div>
			</div>
		</div>

		<div v-if="state === 'loading'" class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>
	</div>
</template>

<script>
import heyGovApi from '@/api.js'
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { pick, random } from 'lodash'
import { formatDistanceToNow } from 'date-fns'
import { Share } from '@capacitor/share'
import { copyToClipboard } from '@/utils.js'

export default {
	name: 'Poll',
	components: {},
	data() {
		return {
			state: 'idle',
			states: {
				shortDescription: true,
			},
			poll: null,
			answered: false,
		}
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadPoll()

		if (this.pollAnswers[this.$route.params.pollSlug]) {
			this.answered = true
		}
	},
	computed: {
		...mapState(['j', 'device', 'account', 'departments', 'pollAnswers']),
		...mapGetters(['auth', 'currentRole']),
		pollDescriptionShort() {
			let text = ''

			if (this.poll) {
				const words = this.poll.description.split(' ')

				if (words.length > 32) {
					text = words.splice(0, 30).join(' ') + '…'
				}
			}

			return text
		},
	},
	methods: {
		formatDistanceToNow,
		loadPoll() {
			this.state = 'loading'
			const params = {}

			if (this.currentRole === 'ADMIN') {
				params.nocache = random(0, 999999)
			}

			heyGovApi.get(`${this.j.slug}/polls/${this.$route.params.pollSlug}`, { params }).then(
				({ data }) => {
					this.poll = data

					if (this.$route.query.newResponse === 'yes') {
						this.newResponse()
					} else {
						this.prefillAnswers()
					}

					this.state = 'idle'
				},
				error => {
					Vue.toasted.show(error, { type: 'error' })
					this.state = 'error'
				}
			)
		},
		prefillAnswers(forGuest) {
			this.poll.questions = this.poll.questions.map(question => {
				if (question.type === 'boolean') {
					question.answer = null
				} else if (question.type === 'text') {
					question.answer = ''
				} else if (question.type === 'contact') {
					// if account info is loaded
					if (forGuest) {
						question.answer = {
							name: '',
							email: '',
							address: '',
						}
					} else if (this.account) {
						question.answer = {
							name: this.account.name || this.auth.name || '',
							email: this.account.email || this.auth.email || '',
							address: this.account.address || '',
						}
					} else {
						question.answer = {
							name: this?.auth?.name || '',
							email: this?.auth?.email || '',
							address: '',
						}
					}
				}

				return question
			})
		},
		newResponse() {
			this.prefillAnswers(true)
			this.answered = false
		},

		setAnswerAddress(place) {
			// TODO fix this hack/workaround
			this.poll.questions.forEach(q => {
				if (q.type === 'contact') {
					q.answer.address = place.formatted_address
				}
			})
		},
		saveAnswers() {
			this.state = 'sending'

			heyGovApi
				.post(
					`${this.j.slug}/polls/${this.$route.params.pollSlug}/answers`,
					{
						answers: this.poll.questions.map(q => pick(q, ['id', 'answer'])),
					},
					{ params: { hey: this.device.uuid } }
				)
				.then(
					({ data }) => {
						this.poll.responses++

						this.$store.commit('pollAnswered', { poll: this.poll.slug, answer: data.uuid })

						Vue.toasted.show('Your response is sent', { type: 'success' })
						this.answered = true
						this.state = 'idle'
					},
					error => {
						this.state = 'error'
						Vue.toasted.show(`Oops, server says: "${error}"`, { type: 'error' })
					}
				)
		},
		doMarkdown(html) {
			// TODO add markdown parser

			html = html.replace(/__([a-z0-9 ]+)__/gi, '<u>$1</u>')

			return html
		},
		sharePoll() {
			const url = `https://app.heygov.com/${this.j.slug}/polls/${this.poll.slug}`

			Share.share({
				title: this.poll.title,
				text: `${this.j.name} wants your opinion. ${this.poll.title}`,
				url,
				dialogTitle: 'Share Straw Poll',
			}).catch(error => {
				if (error.message.includes('API not available')) {
					copyToClipboard(url)
					Vue.toasted.show('Straw poll link is copied to clipboard')
				}
			})
		},
	},
}
</script>
