<template>
	<div v-if="!currentField.pdf">
		<select
			:class="'form-select ' + currentField.class"
			:id="'fsf-' + currentField.id"
			v-model="currentField.value"
		>
			<option v-for="(item, index) in yearsArray" :key="`fsf-${currentField.id}-${index}`" :value="item">{{
				item
			}}</option>
		</select>
	</div>
	<!-- PDF View -->
	<div v-else>
		<div v-if="currentField.display !== 'form'">
			<label class="pdf-label form-label" for="">{{ currentField.label }}</label>
			<div v-if="getRedactedFields() && currentField.redacted" class="pdf-redacted"></div>
			<div v-else class="pdf-field">
				{{ currentField.value }}
			</div>
		</div>
		<small v-if="currentField.help" class="text-muted pdf-help">{{ currentField.help }}</small>
	</div>
</template>

<script>
export default {
	name: 'Year',
	props: ['currentField'],
	computed: {
		yearsArray: function() {
			let yArray = []
			let min = this.currentField.data.minYear
			let max = this.currentField.data.maxYear

			if (this.currentField.data.minToMax) {
				while (min <= max) {
					yArray.push(min)
					min++
				}
			} else {
				while (max >= min) {
					yArray.push(max)
					max--
				}
			}

			return yArray
		},
	},
	methods: {
		getRedactedFields() {
			if (this.$route.query.redacted) {
				return true
			}
		},
	},
}
</script>
