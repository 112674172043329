<template>
	<div class="threads-page">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">Straw Polls</h2>
			</div>
			<div v-if="['EDITOR', 'ADMIN'].includes(currentRole)" class="col-auto">
				<button class="btn btn-primary btn-big-icon" disabled title="Coming soon">
					Add Straw Poll <font-awesome-icon :icon="['fas', 'plus-circle']" />
				</button>
			</div>
		</div>

		<div class="row mb-3">
			<div v-for="poll in polls" :key="poll.id" class="col-lg-6">
				<poll-card :poll="poll"></poll-card>
			</div>
		</div>

		<div v-if="state === 'idle' && !polls.length" class="text-center">
			<p>There are no Straw Polls for your {{ j.type }} yet</p>
			<p>
				<router-link to="/" class="btn text-primary">← Back to {{ j.type }} overview</router-link>
			</p>
		</div>

		<div v-if="state === 'loading'" class="text-center">
			<div class="spinner-border" role="status"></div>
		</div>
	</div>
</template>

<script>
import heyGovApi from '@/api.js'
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { formatDistanceToNow } from 'date-fns'
import PollCard from '@/components/PollCard.vue'

export default {
	name: 'Polls',
	components: { PollCard },
	data() {
		return {
			state: 'idle',
			polls: [],
			pag: {
				total: 0,
				page: 1,
				perPage: 50,
			},
		}
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadPolls()
	},
	computed: {
		...mapState(['j', 'departments']),
		...mapGetters(['currentRole']),
	},
	methods: {
		formatDistanceToNow,
		loadPolls() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.slug}/polls`, { params: this.filters }).then(
				({ data }) => {
					this.polls.push(...data)
					this.state = 'idle'
				},
				error => {
					this.state = 'error'
					Vue.toasted.show(error, { type: 'error' })
				}
			)
		},
	},
}
</script>
