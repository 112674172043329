import { multiPolygon, point } from '@turf/helpers'
import { booleanPointInPolygon } from '@turf/boolean-point-in-polygon'

export const distanceBetweenCoordinates = (lat1, lon1, lat2, lon2, unit) => {
	const degreesToRadians = degrees => (degrees * Math.PI) / 180
	const earthRadiusKm = unit === 'miles' ? 3959 : 6371

	const dLat = degreesToRadians(lat2 - lat1)
	const dLon = degreesToRadians(lon2 - lon1)

	lat1 = degreesToRadians(lat1)
	lat2 = degreesToRadians(lat2)

	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

	return (earthRadiusKm * c).toFixed(2)
}

export function googleMapsApi(endpoint, init) {
	const sep = endpoint.includes('?') ? '&' : '?'
	const apiKey = process.env.VUE_APP_GOOGLE_API_KEY || process.env.VUE_APP_GOOGLE_MAPS_API_KEY
	const url = `https://maps.googleapis.com/maps/api/${endpoint + sep}key=${apiKey}`

	return fetch(url, init).then(res => res.json())
}

export function isAddressObject(address) {
	return (
		address !== null &&
		typeof address === 'object' &&
		Object.hasOwn(address, 'line1') &&
		Object.hasOwn(address, 'city') &&
		Object.hasOwn(address, 'state') &&
		Object.hasOwn(address, 'postal_code') &&
		Object.hasOwn(address, 'country')
	)
}

export const usStates = {
	AL: 'Alabama',
	AK: 'Alaska',
	AS: 'American Samoa',
	AZ: 'Arizona',
	AR: 'Arkansas',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DE: 'Delaware',
	DC: 'District Of Columbia',
	FM: 'Federated States Of Micronesia',
	FL: 'Florida',
	GA: 'Georgia',
	GU: 'Guam',
	HI: 'Hawaii',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MH: 'Marshall Islands',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	MP: 'Northern Mariana Islands',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PW: 'Palau',
	PA: 'Pennsylvania',
	PR: 'Puerto Rico',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UT: 'Utah',
	VT: 'Vermont',
	VI: 'Virgin Islands',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
}

/**
 * Check if a point is within a polygon
 *
 * @param {number} lat
 * @param {number} lng
 * @param {number[]} poly
 * @returns {boolean}
 */
export function isLatLngInPolygon(lat, lng, poly) {
	if (!poly) {
		return false
	}

	if (!Number.isFinite(lat) || !Number.isFinite(lng)) {
		return false
	}

	const turfPoint = point([lng, lat])
	const turfPolygon = multiPolygon(poly)

	return booleanPointInPolygon(turfPoint, turfPolygon)
}
