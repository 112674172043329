<template>
	<div class="page-form-submissions">
		<h3 class="mb-3">
			<router-link :to="`/${j.slug}/forms`">HeyLicense</router-link>
			<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" /> All submissions
		</h3>

		<div class="bar-filters bar-filters-form-requests border rounded bg-white p-1">
			<div class="row align-items-center justify-content-around g-2">
				<div class="col-sm-6 col-lg-2">
					<select class="form-select" v-model="filters.form_id">
						<option value="">Form</option>
						<option v-for="form in forms" :key="`filter-form-${form.id}`" :value="form.id">{{
							form.name
						}}</option>
					</select>
				</div>
				<div class="col-sm-6 col-lg-2">
					<select class="form-select" v-model="filters.status">
						<option value="">Status</option>
						<option v-for="(status, index) in formRequestStatuses" :key="index" :value="index">{{
							status.name
						}}</option>
					</select>
				</div>

				<div class="col-sm-6 col-lg-2">
					<people-search
						placeholder="🔍 Applicant"
						:limit="10"
						:fields="['name', 'email']"
						@person="setPersonFilter"
						@noPerson="clearPersonFilter"
					></people-search>
				</div>
				<div class="col-sm-6 col-lg-2">
					<div class="input-group rounded">
						<select class="form-select select-date" v-model="dateType">
							<option value="received">Received</option>
							<option value="expires">Expires</option>
						</select>
						<date-picker
							:formatter="dateFormatter"
							v-model="dateRange"
							:editable="false"
							:clearable="true"
							input-class="form-control"
							range
							@change="setDateFilters"
							placeholder="Any time"
						>
							<template v-if="dateType === 'received'" v-slot:sidebar="{ emit }">
								<div class="text-secondary">
									Current
									<div style="text-align: left">
										<button class="mx-btn text-dark border-0 btn-sm" @click="getDPThisWeek(emit)">
											This Week
										</button>
									</div>
									<div style="text-align: left">
										<button class="mx-btn text-dark border-0 btn-sm" @click="getDPThisMonth(emit)">
											This Month
										</button>
									</div>
									<div style="text-align: left">
										<button
											class="mx-btn text-dark border-0 btn-sm"
											@click="getDPThisQuarter(emit)"
										>
											This Quarter
										</button>
									</div>
									<div style="text-align: left">
										<button class="mx-btn text-dark border-0 btn-sm" @click="getDPThisYear(emit)">
											This Year
										</button>
									</div>
								</div>
								<div class="text-secondary">
									Previous
									<div style="text-align: left">
										<button class="mx-btn text-dark border-0 btn-sm" @click="getDPLastWeek(emit)">
											Last Week
										</button>
									</div>
									<div style="text-align: left">
										<button class="mx-btn text-dark border-0 btn-sm" @click="getDPLastMonth(emit)">
											Last Month
										</button>
									</div>
									<div style="text-align: left">
										<button
											class="mx-btn text-dark border-0 btn-sm"
											@click="getDPLastQuarter(emit)"
										>
											Last Quarter
										</button>
									</div>
									<div style="text-align: left">
										<button class="mx-btn text-dark border-0 btn-sm" @click="getDPLastYear(emit)">
											Last Year
										</button>
									</div>
								</div>
							</template>
						</date-picker>
					</div>
				</div>

				<div class="col-sm-6 col-lg-2">
					<select class="form-select" v-model="filters.payment">
						<option value="">Payment</option>
						<option value="requires_payment_method">Not Paid</option>
						<option value="requires_capture">Authorized</option>
						<option value="succeeded">Paid</option>
					</select>
				</div>
				<div class="col-sm-6 col-lg-2">
					<select class="form-select" v-model="filters.department">
						<option value="">Department</option>
						<option
							v-for="(department, index) in activeDepartments"
							:key="`department-${index}`"
							:value="department.id"
							>{{ department.name }}</option
						>
					</select>
				</div>
			</div>
		</div>

		<div class="my-2 bg-white rounded px-3 py-2 shadow-sm">
			<div class="row align-items-center">
				<div class="col">
					<label v-if="!requestsSelected.length" for="select-all-submissions" class="text-neutral-400"
						>Select submissions to see actions</label
					>
					<span v-else>
						<span class="text-muted">{{ requestsSelected.length }} selected</span>
						<!-- <button
							class="btn btn-sm btn-primary mx-1"
							data-bs-toggle="modal"
							data-bs-target="#send-message-to-customers"
						>
							<font-awesome-icon :icon="['fas', 'paper-plane']" class="me-1" />
							Send Message
						</button> -->

						<a
							:href="licensePrintUrl"
							target="_blank"
							class="btn btn-sm btn-outline-success ms-2 mx-1"
							:class="{
								disabled:
									!selectedForm ||
									!formsWithLicenseTemplates.includes(`${j.slug}/${selectedForm.slug}`),
							}"
						>
							<font-awesome-icon :icon="['fas', 'stamp']" class="me-1" />
							Print license/certificate
						</a>
						<button class="btn btn-sm btn-outline-danger mx-1" @click="declineRequests">
							<font-awesome-icon :icon="['fas', 'hand-paper']" class="me-1" />
							Decline {{ requestsSelected.length > 1 ? 'submissions' : 'submission' }}
						</button>
						<button v-if="isStaff" class="btn btn-sm btn-danger mx-1" @click="deleteRequests">
							<font-awesome-icon :icon="['fas', 'trash']" class="me-1" />
							Delete {{ requestsSelected.length > 1 ? 'applications' : 'application' }}
						</button>
					</span>
				</div>
				<div v-if="state === 'idle'" class="col-auto">
					<span v-if="pag.total"
						>Showing {{ pag.limit * (pag.page - 1) + 1 }}-{{ Math.min(pag.limit * pag.page, pag.total) }} of
						{{ pag.total }}</span
					>
					<span v-else>No results</span>
				</div>
				<div class="col-auto ps-0">
					<div class="dropdown">
						<button
							class="btn btn-sm px-2"
							type="button"
							data-bs-toggle="dropdown"
							title="Customize table columns"
						>
							<font-awesome-icon :icon="['fas', 'columns']" />
						</button>
						<ul class="dropdown-menu">
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="person_id" /> Applicant
									name</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="sent_at" /> Received
									date</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="sent_at_time" /> Received
									time</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="form_id" /> Form name</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="status" /> Status</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="info1" /> Info column 1</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="info_column2" /> Info column
									2</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="geo_column_in_jurisdiction" />
									Geo column: Inside {{ j.type }} limits</label
								>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="geo_column_distance" /> Geo
									column: Distance to {{ j.type }}</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="price" /> Form fee</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="payment_status" /> Payment
									status</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="payment" /> Paid amount</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="payment_method" /> Payment
									method</label
								>
							</li>
							<li>
								<label class="dropdown-item"
									><input type="checkbox" v-model="tableColumns" value="valid_until" /> Expiry</label
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>

		<div class="card mb-4">
			<div class="card-body">
				<div class="card-table mb-3" style="overflow-x: scroll;">
					<table class="table table-hover">
						<thead>
							<tr>
								<th class="ps-1">
									<label class="ps-3 pe-2" for="select-all-submissions">
										<input
											type="checkbox"
											id="select-all-submissions"
											ref="selectAllSubmissions"
											v-model="allSubmissionsSelected"
											@click.prevent="selectAll"
										/>
									</label>
								</th>
								<th>#</th>
								<th v-if="tableColumns.includes('person_id')">Applicant</th>
								<th
									v-if="tableColumns.includes('sent_at')"
									class="d-none d-md-table-cell hover cursor-pointer"
									@click="sortTable('sent_at', 'asc')"
								>
									Received
									<small v-if="sorting.orderBy === 'sent_at'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
								<th v-if="tableColumns.includes('form_id')" class="d-none d-md-table-cell">Form</th>
								<th v-if="tableColumns.includes('status')">Status</th>
								<th v-if="tableColumns.includes('info1')">Info 1</th>
								<th v-if="tableColumns.includes('info_column2')">Info 2</th>
								<th v-if="tableColumns.includes('geo_column_in_jurisdiction')">
									Inside {{ j.type }} limits
								</th>
								<th v-if="tableColumns.includes('geo_column_distance')">Distance to {{ j.type }}</th>
								<th v-if="tableColumns.includes('price')" class="d-none d-md-table-cell">
									Form fee
								</th>
								<th v-if="tableColumns.includes('payment_status')">Payment Status</th>
								<th v-if="tableColumns.includes('payment')" class="d-none d-md-table-cell">
									Paid Amount
								</th>
								<th v-if="tableColumns.includes('payment_method')" class="d-none d-md-table-cell">
									Payment Method
								</th>
								<th
									v-if="tableColumns.includes('valid_until')"
									@click="sortTable('valid_until', 'asc')"
									class="d-none d-md-table-cell hover cursor-pointer"
								>
									Expiry
									<small v-if="sorting.orderBy === 'valid_until'">{{
										sorting.order === 'asc' ? '▲' : '▼'
									}}</small>
								</th>
							</tr>
						</thead>
						<tbody>
							<template v-for="request in formRequests">
								<router-link
									v-if="forms.length > 0"
									:key="`form-request-${request.id}`"
									class="on-parent"
									:class="{ 'bg-primary-50': requestsSelected.includes(request.id) }"
									:to="
										`/${j.slug}/forms/${formInfo(request.form_id).slug}/request-review/${
											request.uuid
										}`
									"
									tag="tr"
								>
									<td class="pe-0 ps-1 py-0">
										<label
											class="p-2 ps-3"
											:for="`fr-check-${request.id}`"
											@click="$event.stopPropagation()"
										>
											<input
												type="checkbox"
												v-model="requestsSelected"
												:value="request.id"
												:id="`fr-check-${request.id}`"
											/>
										</label>
									</td>
									<td>
										{{ request.application_number || request.id }}
									</td>
									<td v-if="tableColumns.includes('person_id')">
										<person-link
											v-if="people[request.person_id]"
											:id="request.person_id"
											:avatar="18"
										></person-link>
									</td>
									<td class="d-none d-md-table-cell" v-if="tableColumns.includes('sent_at')">
										{{ (request.sent_at || request.created_at) | dateLocal }}
										<span v-if="tableColumns.includes('sent_at_time')">{{
											(request.sent_at || request.created_at) | timeLocal
										}}</span>
									</td>
									<td class="d-none d-md-table-cell" v-if="tableColumns.includes('form_id')">
										<div v-if="forms" class="text-truncate" style="max-width:200px">
											{{ formInfo(request.form_id).name }}
										</div>
									</td>
									<td v-if="tableColumns.includes('status')">
										<div class="badge d-block" :class="[formRequestStatuses[request.status].class]">
											{{ formRequestStatuses[request.status].name }}
										</div>
									</td>
									<td v-if="tableColumns.includes('info1')">
										<span
											v-if="
												formInfo(request.form_id).info_column1 &&
													formInfo(request.form_id).steps.length
											"
										>
											{{
												displayFieldAnswer(
													formInfo(request.form_id)
														.steps.map(s => s.fields)
														.flat()
														.find(f => f.id == formInfo(request.form_id).info_column1),
													request.answers[formInfo(request.form_id).info_column1],
													true
												)
											}}
										</span>
										<span v-else-if="formInfo(request.form_id).info_column1">
											{{ request.answers[formInfo(request.form_id).info_column1] }}
										</span>
									</td>
									<td v-if="tableColumns.includes('info_column2')">
										<span
											v-if="
												formInfo(request.form_id).info_column2 &&
													formInfo(request.form_id).steps.length
											"
										>
											{{
												displayFieldAnswer(
													formInfo(request.form_id)
														.steps.map(s => s.fields)
														.flat()
														.find(f => f.id == formInfo(request.form_id).info_column2),
													request.answers[formInfo(request.form_id).info_column2],
													true
												)
											}}
										</span>
										<span v-else-if="formInfo(request.form_id).info_column2">
											{{ request.answers[formInfo(request.form_id).info_column2] }}
										</span>
									</td>
									<td v-if="tableColumns.includes('geo_column_in_jurisdiction')">
										<span
											v-if="
												formInfo(request.form_id).geo_column &&
													request.answers[formInfo(request.form_id).geo_column] &&
													request.answers[formInfo(request.form_id).geo_column].lat
											"
										>
											{{
												isLatLngInJurisdiction(
													request.answers[formInfo(request.form_id).geo_column].lat,
													request.answers[formInfo(request.form_id).geo_column].lng
												)
													? '✅ Yes'
													: '❌ No'
											}}
										</span>
										<small v-else class="text-neutral-300">-</small>
									</td>
									<td v-if="tableColumns.includes('geo_column_distance')">
										<span
											v-if="
												formInfo(request.form_id).geo_column &&
													request.answers[formInfo(request.form_id).geo_column] &&
													request.answers[formInfo(request.form_id).geo_column].lat
											"
										>
											{{
												distanceBetweenCoordinates(
													request.answers[formInfo(request.form_id).geo_column].lat,
													request.answers[formInfo(request.form_id).geo_column].lng,
													j.location.lat,
													j.location.lng,
													'miles'
												)
											}}
											mi
										</span>
										<small v-else class="text-neutral-300">-</small>
									</td>
									<td v-if="tableColumns.includes('price')">
										<span class="badge text-dark" v-if="'payment' in request.answers">
											{{ request.answers.payment.price | currency }}
										</span>
									</td>
									<td v-if="tableColumns.includes('payment_status')">
										<payment-status
											v-if="'payment' in request.answers"
											:payment="request.answers.payment"
											:tooltip="false"
										/>
									</td>
									<td v-if="tableColumns.includes('payment')">
										<span
											v-if="
												'payment' in request.answers &&
													['succeeded', 'requires_capture'].includes(
														request.answers.payment.status
													)
											"
											class="badge text-dark"
										>
											{{ (request.answers.payment.amount / 100) | currency }}
										</span>
									</td>
									<td v-if="tableColumns.includes('payment_method')">
										<!-- Online payment with card, dipslay onli if payment status is succedeed -->
										<template v-if="'payment' in request.answers">
											<span
												v-if="
													['card', 'card_present'].includes(
														request.answers.payment.payment_method
													)
												"
												class="text-muted"
											>
												<span v-if="request.answers.payment.status === 'succeeded'">
													<font-awesome-icon
														:icon="['far', 'credit-card']"
														class="text-primary"
													/>
													Card
												</span>
											</span>
											<!-- Offline payment -->
											<span
												v-else-if="request.answers.payment.payment_method === 'cash'"
												class="text-muted"
												><font-awesome-icon
													:icon="['fas', 'money-bill-wave']"
													class="text-primary"
												/>
												Cash</span
											>
											<span
												v-else-if="request.answers.payment.payment_method === 'check'"
												class="text-muted"
												><font-awesome-icon
													:icon="['fas', 'money-check']"
													class="text-primary"
												/>
												Check
												<!-- Check number -->
												<!-- <code v-if="request.answers.payment.note"
												>#{{ request.answers.payment.note }}
											</code> --></span
											>
										</template>
									</td>
									<td v-if="tableColumns.includes('valid_until')">
										<span v-if="request.valid_until">{{
											new Date(request.valid_until).toLocaleDateString('default', {
												timeZone: 'UTC',
											})
										}}</span>
									</td>
								</router-link>
							</template>
						</tbody>
						<tfoot>
							<tr v-if="state === 'loading'">
								<td colspan="9" class="text-center py-3">
									<div class="spinner-border spinner-border-sm" role="state"></div>
								</td>
							</tr>
							<tr v-else-if="state === 'idle' && !formRequests.length">
								<td colspan="9" class="text-center py-3">
									<div v-if="hasActiveFilters">
										<img
											src="https://edge.heygov.com/illustrations/arms-documents.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead">No form applications found. Try changing the filters</p>
									</div>
									<div v-else>
										<img
											src="https://edge.heygov.com/illustrations/form-applications.jpg"
											width="300"
											class="mb-3"
										/>
										<p class="lead">Awaiting form applications</p>
									</div>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>

				<!-- Pagination -->
				<div v-if="pag.total" class="row">
					<div class="col">
						<span class="me-2 text-muted">Per page</span>
						<div class="d-inline-flex">
							<select class="form-select form-select-sm" v-model="pag.limit">
								<option value="10">10</option>
								<option value="25">25</option>
								<option value="50">50</option>
								<option value="100">100</option>
							</select>
						</div>
					</div>
					<div class="col-auto">
						<nav v-if="pag.pages > 1">
							<ul class="pagination mb-0">
								<li class="page-item">
									<a class="page-link" @click="pag.page = 1">First</a>
								</li>
								<li
									class="page-item"
									v-for="p in pag.pages"
									:key="`${p}-number`"
									:class="p == pag.page ? 'active' : ''"
								>
									<span v-if="Math.abs(pag.page - p) < 3">
										<a class="page-link" @click="pag.page = p">
											{{ p }}
										</a>
									</span>
								</li>
								<li class="page-item">
									<a class="page-link" @click="pag.page = pag.pages">Last</a>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</div>
		</div>

		<!-- Send message Modal -->
		<div class="modal fade" id="send-message-to-customers" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<form @submit.prevent="sendMessageToCustomer">
						<div class="modal-header">
							<h5 class="modal-title">Send message to customer(s)</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label class="form-label" for="customer-message">Message</label>
								<textarea
									class="form-control"
									id="customer-message"
									rows="3"
									v-model="requestsSelected.message"
									required
									placeholder="Type a nice message 😀"
								></textarea>
							</div>
						</div>
						<div class="modal-footer text-end">
							<button class="btn btn-primary">Send Message</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
@import '@/assets/date-picker';
</style>

<style lang="scss" scoped>
.select-date {
	max-width: 50%;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { isObject } from 'lodash'
import {
	format,
	startOfWeek,
	endOfWeek,
	startOfMonth,
	getDaysInMonth,
	startOfYear,
	startOfQuarter,
	lastDayOfQuarter,
	subDays,
} from 'date-fns'
import { Modal } from 'bootstrap'

import heyGovApi, { handleResponseError } from '@/api.js'
import { setting } from '@/utils.js'
import { formRequestStatuses, formsWithLicenseTemplates } from '@/actions/forms.js'
import { displayFieldAnswer } from '@/actions/form-builder.js'
import { distanceBetweenCoordinates, isLatLngInPolygon } from '@/lib/geo.js'

import PersonLink from '@/components/PersonLink.vue'
import PeopleSearch from '@/components/PeopleSearch.vue'
import PaymentStatus from '@/components/payments/PaymentStatus.vue'
//import PaymentMethod from '@/components/payments/PaymentMethod.vue'

export default {
	name: 'FormSubmissions',
	metaInfo: {
		title: `All form submissions`,
	},
	components: { PersonLink, DatePicker, PeopleSearch, PaymentStatus },
	data() {
		return {
			state: 'loading',
			filters: {
				form_id: this.$route.query.form_id || '',
				status: this.$route.query.status || '',
				payment: this.$route.query.payment || '',
				person_id: this.$route.query.person_id || '',
				dateStart: this.$route.query.dateStart || '',
				dateEnd: this.$route.query.dateEnd || '',
				expireStart: this.$route.query.expireStart || '',
				expireEnd: this.$route.query.expireEnd || '',
				department: this.$route.query.department || '',
			},
			formRequests: [],
			formRequestStatuses,
			dateType: 'received',
			dateRange: [],
			label: '',
			pag: {
				total: 0,
				page: this.$route.query.page || 1,
				pages: 0,
				limit: localStorage.getItem('form-requests-per-page') || 25,
			},
			sorting: {
				orderBy: localStorage.getItem('form-requests-orderBy') || 'sent_at',
				order: localStorage.getItem('form-requests-order') || 'desc',
			},
			requestsSelected: [],
			allSubmissionsSelected: false,
			tableColumns: [
				'person_id',
				'sent_at',
				'form_id',
				'status',
				'info1',
				'info_column2',
				'geo_column_in_jurisdiction',
				'price',
				'payment_status',
				'payment',
				'valid_until',
			],
			dateFormatter: {
				stringify(date) {
					const currentYear = new Date().getFullYear()

					if (currentYear === date.getFullYear()) {
						return date.toLocaleDateString(undefined, {
							month: 'short',
							day: 'numeric',
						})
					} else {
						return date.toLocaleDateString()
					}
				},
			},

			formsWithLicenseTemplates,
		}
	},
	computed: {
		...mapState(['j', 'apiUrl', 'people', 'forms']),
		...mapGetters(['currentRole', 'isStaff', 'activeDepartments']),
		hasActiveFilters() {
			return Object.values(this.filters).some(filter => filter)
		},
		selectedForm() {
			return this.forms.find(form => form.id == this.filters.form_id)
		},
		licensePrintUrl() {
			const selectedFormRequests = this.formRequests.filter(r => this.requestsSelected.includes(r.id))

			return `${this.apiUrl + this.j.slug}/forms/${this.selectedForm?.slug ||
				'-'}/license-template?frs=${selectedFormRequests.map(fr => fr.uuid).join(',')}&print=1`
		},
	},
	created() {
		this.preloadDates()
		this.getForms()
		this.loadFormRequests()
		this.$store.dispatch('loadDepartments')

		// load table columns preference from local storage
		setting('form-submissions-table-columns').then(columns => {
			if (columns) {
				this.tableColumns = columns
			}
		})
	},
	mounted() {
		this.$modalSendMessage = new Modal(document.getElementById('send-message-to-customers'))
	},
	methods: {
		displayFieldAnswer,
		distanceBetweenCoordinates,

		preloadDates() {
			if (this.$route.query.dateStart && this.$route.query.dateEnd) {
				this.dateRange = [new Date(this.$route.query.dateStart), new Date(this.$route.query.dateEnd)]
			} else if (this.$route.query.expireStart && this.$route.query.expireEnd) {
				this.dateRange = [new Date(this.$route.query.expireStart), new Date(this.$route.query.expireEnd)]
				this.dateType = 'expires'
			}
		},
		setPersonFilter(person) {
			this.filters.person_id = person.id
		},
		clearPersonFilter(noPerson) {
			if (noPerson === true) {
				this.filters.person_id = ''
			}
		},
		loadFormRequests() {
			this.state = 'loading'
			this.requestsSelected = []

			const params = {
				...this.filters,
				...this.sorting,
				limit: this.pag.limit,
				page: this.pag.page,
			}

			if (params.status === '') {
				params.status = 'sent,working,approved,declined,expired'
			}

			heyGovApi.get(`/${this.j.slug}/form-requests`, { params }).then(
				({ data, headers }) => {
					data.forEach(request => {
						for (let value of Object.entries(request.answers)) {
							if (isObject(value[1]) && 'price' in value[1]) {
								request.answers.payment = value[1]
							}
						}
					})

					this.pag.total = parseInt(headers['x-total'], 10)
					this.pag.pages = Math.ceil(this.pag.total / this.pag.limit)

					this.formRequests.push(...data)
					this.$store.dispatch(
						'loadPeople',
						data.map(fr => fr.person_id)
					)
					this.state = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading submissions (${error.message})`)
					this.state = 'error'
				}
			)
		},
		updatePageUrl() {
			let query = {}

			for (const filter in this.filters) {
				if (this.filters[filter] && ['dateStart', 'dateEnd', 'expireStart', 'expireEnd'].includes(filter)) {
					query[filter] = format(this.filters[filter], 'yyyy-MM-dd')
				} else if (this.filters[filter]) {
					query[filter] = this.filters[filter]
				}
			}
			if (this.pag.page && this.pag.page != 1) {
				query.page = this.pag.page
			}
			this.$router.replace({ path: `/${this.j.slug}/form-requests`, query })
		},
		getForms() {
			this.$store.dispatch('getForms').then(forms => {
				// load steps for each form
				forms
					.filter(f => !f.steps.length)
					.forEach(form => {
						heyGovApi.get(`${this.j.slug}/forms/${form.slug}/steps-fields`).then(({ data }) => {
							form.steps.push(...data)
						})
					})
			}, handleResponseError(`Error loading forms ({error})`))
		},
		formInfo(formId) {
			return this.forms.find(form => form.id === formId)
		},

		setDateFilters() {
			const hasDateRange = this.dateRange.length && this.dateRange[0]
			if (hasDateRange && this.dateType === 'received') {
				this.filters.dateStart = this.dateRange[0]
				this.filters.dateEnd = this.dateRange[1]
				this.filters.expireStart = null
				this.filters.expireEnd = null
			} else if (hasDateRange && this.dateType === 'expires') {
				this.filters.dateStart = null
				this.filters.dateEnd = null
				this.filters.expireStart = this.dateRange[0]
				this.filters.expireEnd = this.dateRange[1]
			} else {
				this.filters.dateStart = null
				this.filters.dateEnd = null
				this.filters.expireStart = null
				this.filters.expireEnd = null
			}
		},
		getDay() {
			let d = new Date()
			let day = d.getDay()
			this.filters.days.days = day
			return day
		},
		getDaysInCurrentWeek() {
			let startDay = startOfWeek(new Date())
			let endDay = new Date()
			return [startDay, endDay]
		},
		getDaysInCurrentMonth() {
			let startDay = startOfMonth(new Date())
			let endDay = new Date()
			return [startDay, endDay]
		},
		getDaysInCurrentYear() {
			let startDay = startOfYear(new Date())
			let endDay = new Date()
			this.filters.dateStart = startDay
			this.filters.dateEnd = endDay
			return [startDay, endDay]
		},
		getDaysInCurrentQuarter() {
			let startDay = startOfQuarter(new Date())
			let endDay = lastDayOfQuarter(new Date())
			this.filters.dateStart = startDay
			this.filters.dateEnd = endDay
			return [startDay, endDay]
		},
		getLastWeek() {
			let lastMonday = startOfWeek(new Date())
			lastMonday = subDays(lastMonday, 6)
			let lastSunday = endOfWeek(new Date())
			lastSunday = subDays(lastSunday, 6)
			this.filters.dateStart = lastMonday
			this.filters.dateEnd = lastSunday
			return [lastMonday, lastSunday]
		},
		getLastMonth() {
			let month = startOfMonth(new Date())
			let m = new Date().getMonth() - 1
			let days = getDaysInMonth(m) - 1
			let firstDay = subDays(month, days)
			let lastDay = subDays(month, 1)
			this.filters.dateStart = firstDay
			this.filters.dateEnd = lastDay
			return [firstDay, lastDay]
		},
		getLastQuarter() {
			let date = new Date(),
				y = date.getFullYear(),
				m = date.getMonth()
			let start = Math.floor(m / 3) * 2 - 1
			let end = start + 2
			let startDate = new Date(start + '-01-' + y)
			this.filters.dateStart = startDate
			let endDate = new Date(y, end, 0)
			this.filters.dateEnd = endDate
			return [startDate, endDate]
		},
		getLastYear() {
			let currentDate = new Date()
			let theFirst = new Date(currentDate.getFullYear() - 1, 0, 1)
			this.filters.dateStart = theFirst
			let theLast = new Date(currentDate.getFullYear() - 1, 11, 31)
			this.filters.dateEnd = theLast
			return [theFirst, theLast]
		},

		getDPThisWeek(emit) {
			let date = this.getDaysInCurrentWeek()
			emit(date)
		},
		getDPThisMonth(emit) {
			let date = this.getDaysInCurrentMonth()
			emit(date)
		},
		getDPThisQuarter(emit) {
			let date = this.getDaysInCurrentQuarter()
			emit(date)
		},
		getDPThisYear(emit) {
			let date = this.getDaysInCurrentYear()
			emit(date)
		},
		getDPLastWeek(emit) {
			let date = this.getLastWeek()
			emit(date)
		},
		getDPLastMonth(emit) {
			let date = this.getLastMonth()
			emit(date)
		},
		getDPLastQuarter(emit) {
			let date = this.getLastQuarter()
			emit(date)
		},
		getDPLastYear(emit) {
			let date = this.getLastYear()
			emit(date)
		},

		selectAll($event) {
			if ($event.target.checked) {
				this.requestsSelected = this.formRequests.map(r => r.id)
			} else {
				this.requestsSelected = []
			}
		},
		// TODO Need a method to send message
		sendMessageToCustomer() {
			console.log(this.requestsSelected)
			console.log('message')
			alert('This feature is not available yet')
		},

		declineRequests() {
			alert(`COMING SOON - decline requests ${this.requestsSelected.join(', ')}`)
		},

		deleteRequests() {
			if (confirm(`For sure delete ${this.requestsSelected.length} form applications?`)) {
				this.requestsSelected.forEach(id => {
					this.deleteFormRequest(id)
				})
				this.requestsSelected = []
				Vue.toasted.info(`Form submission are deleted`)
			}
		},
		deleteFormRequest(id) {
			heyGovApi.delete(`/${this.j.slug}/form-requests/${id}`).then(
				() => {
					this.formRequests = this.formRequests.filter(fr => fr.id !== id)
				},
				error => {
					Vue.toasted.error(`Error deleting form request (${error})`)
				}
			)
		},
		sortTable(orderBy, defaultOrder) {
			if (this.sorting.orderBy === orderBy) {
				// if the same column is clicked, reverse the sort order
				this.sorting.order = this.sorting.order === 'asc' ? 'desc' : 'asc'
			} else {
				// if a new column is clicked, start with the default order
				this.sorting.order = defaultOrder
			}

			this.sorting.orderBy = orderBy
		},

		isLatLngInJurisdiction(lat, lng) {
			return isLatLngInPolygon(lat, lng, this.j.location.polygon)
		},
	},
	watch: {
		dateType() {
			this.setDateFilters()
		},
		'pag.page'() {
			this.formRequests = []
			this.updatePageUrl()
			this.loadFormRequests()
		},
		'pag.limit'() {
			this.formRequests = []
			this.loadFormRequests()
			localStorage.setItem('form-requests-per-page', this.pag.limit)
		},
		filters: {
			deep: true,
			handler() {
				this.formRequests = []
				this.updatePageUrl()
				this.loadFormRequests()
			},
		},
		sorting: {
			deep: true,
			handler() {
				if (this.pag.page !== 1) {
					this.pag.page = 1
				} else {
					this.formRequests = []
					this.loadFormRequests()
				}
				localStorage.setItem('form-requests-orderBy', this.sorting.orderBy)
				localStorage.setItem('form-requests-order', this.sorting.order)
			},
		},

		tableColumns() {
			// save table columns preference to local storage
			setting('form-submissions-table-columns', this.tableColumns)
		},
		requestsSelected() {
			setTimeout(() => {
				this.allSubmissionsSelected =
					this.formRequests.length && this.requestsSelected.length === this.formRequests.length
				this.$refs.selectAllSubmissions.indeterminate =
					this.requestsSelected.length && !this.allSubmissionsSelected
			}, 50)
		},
	},
}
</script>
