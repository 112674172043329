<template>
	<div class="page-payments">
		<div class="row justify-content-center">
			<div class="col-md-10 col-lg-8 col-xl-6">
				<h2 class="text-center">
					{{
						j.features_options && j.features_options.payments_options
							? j.features_options.payments_options.title || 'What do you want to pay?'
							: 'What do you want to pay?'
					}}
				</h2>
				<p
					v-if="
						j.features_options &&
							j.features_options.payments_options &&
							j.features_options.payments_options.description
					"
					class="text-center"
				>
					{{ j.features_options.payments_options.description }}
				</p>

				<div class="card mb-4">
					<div class="card-body">
						<!-- popular payments -->

						<div v-if="departmentsWithBillingAccounts.length" class="payment-dept">
							<div class="row mb-3">
								<div class="col">
									<h5 class="my-0">Utility &amp; tax payments</h5>
								</div>
								<div v-if="!states.billingAccountsSearchShow" class="col-auto">
									<span
										class="d-inline-block px-2 text-primary-400 cursor-pointer"
										@click="states.billingAccountsSearchShow = true"
									>
										Find account
									</span>
								</div>
							</div>

							<form v-if="states.billingAccountsSearchShow" @submit.prevent="findBillingAccount">
								<p class="mb-2">Find your billing account to see bills and balance.</p>

								<div class="row gx-3 align-items-end">
									<div v-if="billingAccountsSettings.fields.account_number.enabled" class="col-md">
										<div class="form-group mb-3">
											<label for="billing-account-number" class="form-label"
												>Account number
												<small
													v-if="billingAccountsSettings.fields.account_number.required"
													class="text-danger-400"
													>*</small
												></label
											>

											<input
												type="search"
												class="form-control form-control-sm"
												id="billing-account-number"
												v-model="billingAccountSearch.account_number"
												:required="billingAccountsSettings.fields.account_number.required"
												minlength="2"
												placeholder="000-0000-00"
											/>
										</div>
									</div>

									<div v-if="billingAccountsSettings.fields.name.enabled" class="col-md">
										<div class="form-group mb-3">
											<label for="billing-account-name" class="form-label"
												>Name
												<small
													v-if="billingAccountsSettings.fields.name.required"
													class="text-danger-400"
													>*</small
												></label
											>

											<input
												type="text"
												autocomplete="family-name"
												class="form-control form-control-sm"
												id="billing-account-name"
												v-model="billingAccountSearch.name"
												minlength="3"
												:required="billingAccountsSettings.fields.name.required"
												placeholder="Last name or Company name"
											/>
										</div>
									</div>

									<div v-if="billingAccountsSettings.fields.address_line1.enabled" class="col-md">
										<div class="form-group mb-3">
											<label for="billing-account-address-line1" class="form-label"
												>Address
												<small
													v-if="billingAccountsSettings.fields.address_line1.required"
													class="text-danger-400"
													>*</small
												></label
											>

											<input
												type="text"
												autocomplete="street-address"
												class="form-control form-control-sm"
												id="billing-account-address-line1"
												v-model="billingAccountSearch.address_line1"
												:required="billingAccountsSettings.fields.address_line1.required"
												minlength="3"
												placeholder="13 Main St"
											/>
										</div>
									</div>

									<div
										v-if="billingAccountsSettings.fields.address_postal_code.enabled"
										class="col-md"
									>
										<div class="form-group mb-3">
											<label for="billing-account-address-postal-code" class="form-label"
												>ZIP code
												<small
													v-if="billingAccountsSettings.fields.address_postal_code.required"
													class="text-danger-400"
													>*</small
												></label
											>

											<input
												type="text"
												inputmode="numeric"
												class="form-control form-control-sm"
												id="billing-account-address-postal-code"
												v-model="billingAccountSearch.address_postal_code"
												:required="billingAccountsSettings.fields.address_postal_code.required"
												minlength="5"
												placeholder="12345"
											/>
										</div>
									</div>

									<div class="col-12 mb-3">
										<button
											class="btn btn-sm px-4"
											:class="
												billingAccountSearch.accounts.length
													? 'btn-outline-primary'
													: 'btn-primary'
											"
										>
											Find account
										</button>

										<span v-if="states.billingAccountsSearch === 'loading'" class="ms-2">
											<span class="spinner-border spinner-border-sm"></span> Loading..
										</span>

										<span
											v-if="
												states.billingAccountsSearch === 'loaded' &&
													!billingAccountSearch.accounts.length
											"
											class="ms-2"
										>
											⚠️ Couldn't find any billing accounts.
										</span>
									</div>
								</div>
							</form>

							<router-link
								:to="`/${j.slug}/quick-pay/billing-account/${account.uuid}`"
								v-for="account in billingAccountSearch.accounts"
								:key="`ut-account-${account.id}`"
								class="d-block bg-primary-50 text-dark p-3 rounded-1 mb-3"
							>
								<button class="btn btn-sm btn-primary float-end">View bills</button>
								<p class="lead mb-2">
									Account <strong>{{ account.account_number }}</strong>
								</p>

								<div class="row align-items-center">
									<div class="col-6">
										<p class="mb-2">
											<span class="text-neutral-400">Name:</span> {{ account.name }}
										</p>
										<p class="mb-0">
											<span class="text-neutral-400">Address:</span>
											{{ account.address | formatAddress }}
										</p>
									</div>
									<div class="col-6">
										<p class="mb-2">
											<span class="text-neutral-400">Balance:</span>
											{{ account.balance | currency }}
										</p>
										<p class="mb-0">
											<span class="text-neutral-400">Due date:</span>
											{{ account.last_bill_due_date | dateLocal }}
										</p>
									</div>
								</div>
							</router-link>
						</div>

						<div
							v-if="0 && frequentPayments.length > 8 && availablePayments.length > 2"
							class="payment-dept"
						>
							<h5 class="mb-3">Most frequent</h5>

							<div class="row row-cols-2 row-cols-lg-4 gx-1">
								<div
									v-for="payment in frequentPayments.slice(0, cols)"
									:key="`freq-${payment.object.id || payment.object.value}`"
									class="col text-center mb-2"
								>
									<router-link
										v-if="payment.type === 'quick-pay'"
										:to="`/${j.slug}/quick-pay/pay?pay=${payment.object.value}`"
										class="payment-type"
									>
										<div class="payment-icon mb-2">
											<font-awesome-icon :icon="['fas', 'dollar-sign']" />
										</div>

										<p class="mb-0 text-neutral-500">
											{{ payment.object.name }}
										</p>
									</router-link>
									<router-link
										v-else-if="payment.type === 'form'"
										:to="`/${j.slug}/forms/${payment.object.slug}/send`"
										class="payment-type"
									>
										<div class="payment-icon mb-2">
											<font-awesome-icon :icon="['fas', payment.object.icon || 'dollar-sign']" />
										</div>

										<p class="mb-1 text-neutral-500">
											{{ payment.object.name }}
										</p>
										<p class="mb-0 text-neutral-400">
											{{ payment.object.price }}
										</p>
									</router-link>
									<router-link
										v-else-if="payment.type === 'venue'"
										:to="`/${j.slug}/venues/${payment.object.slug}/info`"
										class="payment-type"
									>
										<div class="payment-icon mb-2">
											<font-awesome-icon :icon="['fas', payment.object.icon || 'dollar-sign']" />
										</div>

										<p class="mb-1 text-neutral-500">
											{{ payment.object.name }}
										</p>
										<p class="mb-0 text-neutral-400">
											From
											{{
												Math.min(...payment.object.slots.map(s => s.price))
													| currency('usd', 'en-US', true)
											}}
										</p>
									</router-link>
									<router-link
										v-else-if="payment.type === 'billing-account'"
										:to="`/${j.slug}/quick-pay/billing-account/${payment.object.uuid}`"
										class="payment-type"
									>
										<div class="payment-icon mb-2">
											<font-awesome-icon :icon="['fas', 'receipt']" />
										</div>

										<p class="mb-1 text-neutral-500">Account {{ payment.object.account_number }}</p>
										<p class="mb-0 text-neutral-400">
											{{ payment.object.balance | currency('usd', 'en-US', true) }}
										</p>
									</router-link>
								</div>
							</div>
						</div>

						<div
							v-for="payments in availablePayments"
							:key="`payment-depts-${payments.department.id}`"
							class="payment-dept"
						>
							<div class="row align-items-center mb-3">
								<div class="col">
									<h5 class="my-0">{{ payments.department.name }}</h5>
								</div>
								<div
									v-if="payments.payments.length > cols && availablePayments.length > 1"
									class="col-auto"
								>
									<small
										class="text-primary-400 text-uppercase cursor-pointer"
										@click="toggleDept(payments.department.id)"
									>
										<span v-if="states.departmentsShowAll[payments.department.id]"
											>↖ Show less</span
										>
										<span v-else>View all →</span>
									</small>
								</div>
							</div>

							<div
								class="row row-cols-2 row-cols-lg-4 gx-1"
								:style="{ '--dept-color': payments.department.color }"
							>
								<div
									v-for="payment in payments.payments.slice(
										0,
										states.departmentsShowAll[payments.department.id] ||
											availablePayments.length === 1
											? 99
											: cols
									)"
									:key="`dept-${payments.department.id}-${payment.object.id || payment.object.value}`"
									class="col text-center mb-2"
								>
									<router-link
										v-if="payment.type === 'quick-pay'"
										:to="`/${j.slug}/quick-pay/pay?pay=${payment.object.value}`"
										class="payment-type"
									>
										<div class="payment-icon mb-2">
											<font-awesome-icon :icon="['fas', 'dollar-sign']" />
										</div>

										<p class="mb-0 text-neutral-500">
											{{ payment.object.name }}
										</p>
									</router-link>
									<router-link
										v-else-if="payment.type === 'form'"
										:to="`/${j.slug}/forms/${payment.object.slug}/send`"
										class="payment-type"
									>
										<div class="payment-icon mb-2">
											<font-awesome-icon :icon="['fas', payment.object.icon || 'dollar-sign']" />
										</div>

										<p class="mb-1 text-neutral-500">
											{{ payment.object.name }}
										</p>
										<p class="mb-0 text-neutral-400">
											{{ payment.object.price }}
										</p>
									</router-link>
									<router-link
										v-else-if="payment.type === 'venue'"
										:to="`/${j.slug}/venues/${payment.object.slug}/info`"
										class="payment-type"
									>
										<div class="payment-icon mb-2">
											<font-awesome-icon :icon="['fas', payment.object.icon || 'dollar-sign']" />
										</div>

										<p class="mb-1 text-neutral-500">
											{{ payment.object.name }}
										</p>
										<p class="mb-0 text-neutral-400">
											From
											{{
												Math.min(...payment.object.slots.map(s => s.price))
													| currency('usd', 'en-US', true)
											}}
										</p>
									</router-link>
									<router-link
										v-else-if="payment.type === 'billing-account'"
										:to="`/${j.slug}/quick-pay/billing-account/${payment.object.uuid}`"
										class="payment-type"
									>
										<div class="payment-icon mb-2">
											<font-awesome-icon :icon="['fas', 'receipt']" />
										</div>

										<p class="mb-1 text-neutral-500">Account {{ payment.object.account_number }}</p>
										<p class="mb-0 text-neutral-400">
											{{ payment.object.balance | currency('usd', 'en-US', true) }}
										</p>
									</router-link>
								</div>
							</div>
						</div>

						<p v-if="!availablePayments.length" class="lead text-center">
							No payments available at this time 🤷
						</p>
					</div>
					<div v-if="availablePayments.length" class="card-footer">
						<router-link :to="`/${j.slug}/quick-pay/payments`" class="btn">
							<font-awesome-icon :icon="['fas', 'list']" class="me-1" /> Payments history
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import '@/assets/variables';

.payment-dept {
	--dept-color: $primary;
	border-bottom: 1px solid $neutral-100;
	margin-bottom: 1rem;

	&:last-child {
		border-bottom: none;
		margin-bottom: 0;
	}
}

.payment-type {
	display: block;
	border-radius: 4px;
	padding: 6px;
	transition: background-color 0.2s ease-in-out;

	.payment-icon {
		display: inline-block;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: $neutral-50;
		color: var(--dept-color);
		font-size: 18px;
		line-height: 40px;
		transition: background-color 0.2s ease-in-out;
	}

	&:hover {
		background-color: $neutral-50;
		text-decoration: none;

		.payment-icon {
			background-color: $neutral-100;
		}
	}
}
</style>

<script>
import { mapState, mapGetters } from 'vuex'

import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'

//import FormCard from '@/components/forms/FormCard.vue'

export default {
	name: 'HeyGovPay',
	metaInfo: {
		title: 'Payments',
	},
	components: {},
	data() {
		return {
			states: {
				payments: 'idle',
				departmentsShowAll: {},
				billingAccounts: 'idle',
				billingAccountsSearch: 'idle',
				billingAccountsSearchShow: true,
			},
			cols: 4,
			billingAccountSearch: {
				account_number: '',
				name: '',
				address_line1: '',
				address_postal_code: '',
				accounts: [],
				loading: false,
			},
			billingAccounts: [],
			billingAccountsSettings: null,
		}
	},
	computed: {
		...mapState(['j', 'departments', 'forms', 'venues']),
		...mapGetters(['currentRole', 'auth']),
		departmentsWithBillingAccounts() {
			return (this.departments || []).filter(d => d.billing_accounts)
		},
		formsWithPrice() {
			return this.forms.filter(f => f.status !== 'archived' && f.price)
		},
		availablePayments() {
			const payments = []

			if (this.departments) {
				this.departments.forEach(department => {
					const payment = {
						department,
						payments: [],
					}

					// find QuickPay payments
					if (this.j.features_options?.payments_options?.enabled) {
						payment.payments.push(
							...this.j.features_options.payments_options.types
								.filter(pt => pt.department === department.id)
								.map(paymentType => {
									return {
										type: 'quick-pay',
										counts: 0,
										object: paymentType,
									}
								})
						)
					}

					// find form with price
					this.forms
						.filter(f => f.status === 'public' && f.department_id === department.id && f.price)
						.forEach(form => {
							payment.payments.push({
								type: 'form',
								counts: form.requests_count,
								object: form,
							})
						})

					// add venues
					this.venues
						.filter(venue => venue.status === 'public' && venue.department_id === department.id)
						.forEach(venue => {
							payment.payments.push({
								type: 'venue',
								counts: venue.bookings_count,
								object: venue,
							})
						})

					// add billing accounts
					this.billingAccounts
						.filter(billingAccount => billingAccount.department_id === department.id)
						.forEach(billingAccount => {
							payment.payments.push({
								type: 'billing-account',
								counts: 999,
								object: billingAccount,
							})
						})

					// sort payments
					payment.payments.sort((a, b) => {
						const nameA = a.object.name.toLowerCase()
						const nameB = b.object.name.toLowerCase()

						if (nameA < nameB || nameA === 'other' || nameB === 'miscellaneous') {
							return -1
						} else if (nameA > nameB) {
							return 1
						}

						return 0
					})

					payments.push(payment)
				})
			}

			return payments.filter(p => p.payments.length)
		},
		frequentPayments() {
			const payments = this.availablePayments.map(p => p.payments).flat()

			// sort by payment counts
			payments.sort((a, b) => {
				if (a.counts > b.counts) {
					return -1
				} else if (a.counts < b.counts) {
					return 1
				}

				return 0
			})

			return payments
		},
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.$store.dispatch('getForms')
		this.$store.dispatch('getVenues')

		if (this.auth) {
			this.loadBillingAccounts()
		}

		this.billingAccountsSettings = this.j.features_options?.billing_accounts || {
			fields: {
				account_number: {
					enabled: true,
					required: true,
				},
				name: {
					enabled: false,
					required: false,
				},
				address_line1: {
					enabled: false,
					required: false,
				},
				address_postal_code: {
					enabled: false,
					required: false,
				},
			},
		}
	},
	methods: {
		toggleDept(departmentId) {
			this.$set(this.states.departmentsShowAll, departmentId, !this.states.departmentsShowAll[departmentId])
		},
		loadBillingAccounts() {
			this.states.billingAccounts = 'loading'
			this.states.billingAccountsSearchShow = false

			heyGovApi
				.get(`${this.j.slug}/billing-accounts/own`)
				.then(({ data }) => {
					this.billingAccounts = data

					if (!data.length) {
						this.states.billingAccountsSearchShow = true
					}
				}, handleResponseError(`Couldn't load billing accounts ({error})`))
				.finally(() => {
					this.states.billingAccounts = 'loaded'
				})
		},
		findBillingAccount() {
			this.states.billingAccountsSearch = 'loading'
			this.billingAccountSearch.accounts = []

			heyGovApi
				.post(`${this.j.slug}/billing-accounts/find`, {
					account_number: this.billingAccountSearch.account_number,
					name: this.billingAccountSearch.name,
					address_line1: this.billingAccountSearch.address_line1,
					address_postal_code: this.billingAccountSearch.address_postal_code,
				})
				.then(({ data }) => {
					this.billingAccountSearch.accounts = data
				}, handleResponseError(`Error searching billing accounts ({error})`))
				.finally(() => {
					this.states.billingAccountsSearch = 'loaded'
				})
		},
	},
}
</script>
