<template>
	<div class="venue-public-calendar-wrapper">
		<div class="row">
			<!-- Venue Public Calendar -->
			<div class="venue-public-calendar col-12">
				<!-- Calendar header -->
				<div class="venue-public-calendar-header mb-2">
					<div class="d-flex">
						<div class="calendar-back">
							<button variant="light" @click="subtractMonth">
								<font-awesome-icon :icon="['fas', 'chevron-left']" class="me-3" />
							</button>
						</div>
						<div class="calendar-text">
							<div v-if="loading" class="spinner-border spinner-border-sm" role="status">
								<span class="visually-hidden">Loading...</span>
							</div>
							<template v-else>
								<span class="me-1">{{ month }}</span>
								<span>{{ year }}</span>
							</template>
						</div>
						<div class="calendar-forward">
							<button variant="light" @click="addMonth">
								<font-awesome-icon :icon="['fas', 'chevron-right']" class="ms-3" />
							</button>
						</div>
					</div>
					<div>
						<button
							v-if="options.todayBtn && format(todayDate, 'Y-MM-dd') !== format(selectedDate, 'Y-MM-dd')"
							@click="goToday"
							class="btn-link btn-today"
						>
							Today
						</button>
					</div>
				</div>

				<!-- Calendar body -->
				<div class="venue-public-calendar-body">
					<!-- Weekdays -->
					<div class="venue-public-calendar-weekdays">
						<div class="weekday-text text-center" v-for="(day, index) in days" :key="index">
							<strong>{{ day }}</strong>
						</div>
					</div>

					<!-- Calendar Dates -->
					<div class="venue-public-calendar-dates">
						<div
							class="venue-public-calendar-date"
							v-for="date in monthDaysList"
							:key="date.key"
							:data-date="date.date"
							role="button"
							@click="setSelectedDate(date)"
							@dblclick="showInTable()"
						>
							<!-- Display dates -->
							<div
								class="hey-date"
								:class="{
									'today-date': date.today,
									'active-date': date.selected,
									'not-current-month-date': date.type === 'prev-month' || date.type === 'next-month',
								}"
							>
								<span class="day-text">{{ date.dayString }}</span>

								<template v-if="date.haveEvents">
									<span class="day-dot-blink" v-if="date.status === 'pending'">
										<div class="spinner-grow spinner-grow-sm text-warning" role="status">
											<span class="visually-hidden">Loading...</span>
										</div>
									</span>
									<span v-else class="day-dot"></span>
								</template>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- List of bookings -->
			<div>
				<h5>Reservations for {{ selectedDayAndMonth.month }} {{ selectedDayAndMonth.day }}</h5>

				<div v-if="!dayEventLoading">
					<div v-if="events.length > 0">
						<div
							v-for="event in events"
							:key="event.id"
							class="event-item p-3 mb-3"
							:class="venueBookingStatuses[event.status].class"
							@mouseover="highlightVenue(event)"
							@mouseleave="highlightVenue(null)"
						>
							<div class="event-header d-flex justify-content-between mb-2">
								<h5 class="cursor-pointer hover-underline" @click="bookingDetails(event)">
									{{ venueBookingStatuses[event.status].name }}
								</h5>
								<span class="text-black">
									{{ utcToZonedTime(event.starts_at, j.timezone) | bookingTime }}
									→
									{{ utcToZonedTime(event.ends_at, j.timezone) | bookingTime }}
								</span>
							</div>
							<div class="event-body py-2"></div>
							<div class="event-footer d-flex justify-content-between mt-2">
								<span class="d-flex text-black">
									<small>{{ findVenue(event.venue_id).name }}</small>
								</span>
								<person-link :id="event.person_id" :avatar="18"></person-link>
							</div>
						</div>
						<div class="d-flex justify-content-center align-items-center">
							<router-link
								v-if="events.length > 6"
								:to="
									`/${j.slug}/venues/bookings?booking_start=${selectedDateString}&booking_end=${selectedDateString}&perPage=10`
								"
								class="btn btn-primary btn-sm mt-2"
							>
								View all bookings
							</router-link>
						</div>
					</div>
					<div
						v-else
						class="bg-light p-3 pt-4 rounded rounded-1 d-flex justify-content-center align-items-center"
					>
						<p class="my-0">No reservations found</p>
					</div>
				</div>
				<div v-else>
					<div class="text-center">
						<div class="spinner-border" role="status">
							<span class="visually-hidden">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { parseISO, format, getDaysInMonth, getDate, subDays, subMonths, startOfDay, addMonths, getDay } from 'date-fns'
import { mapGetters, mapState } from 'vuex'
import { utcToZonedTime } from 'date-fns-tz'
import heyGovApi from '@/api.js'
import Vue from 'vue'

import { venueBookingStatuses } from '@/actions/venues.js'
import PersonLink from '@/components/PersonLink.vue'

export default {
	name: 'VenuesCalendar',
	components: { PersonLink },
	props: ['options', 'venues'],
	data() {
		return {
			loading: false,
			today: new Date(),
			todayDate: startOfDay(new Date()),
			todayMonth: parseInt(format(new Date(), 'MM')),
			todayYear: parseInt(format(new Date(), 'Y')),
			todayDay: parseInt(format(new Date(), 'dd')),
			dateContext: new Date(),
			selectedDate: new Date(),
			startDay: 'Sun',
			monthDays: [],
			monthDaysList: [],
			monthsCache: {},
			yearMonth: format(new Date(), 'Y-MM'),
			selectedDateCalendarSlots: [],
			events: [],
			selectedDateString: '',
			forceNewData: false,
			blockedDatesToHover: [],
			blockedEventToHover: [],
			days: [],
			daysFromPreviousMonth: [],
			daysInPreviousMonth: null,
			nextMonth: null,
			previousMonth: null,
			year: null,
			month: null,
			daysInMonth: null,
			currentDate: null,
			initialDate: null,
			initialMonth: null,
			initialYear: null,
			yearMonthNext: null,
			yearMonthPrev: null,
			selectedDay: null,
			selectedMonth: null,
			selectedYear: null,
			selectedDayAndMonth: {
				day: '',
				month: '',
			},
			venueBookingStatuses,
			dayEventLoading: false,
		}
	},

	computed: {
		...mapState(['j', 'account', 'departments']),
		...mapGetters(['currentRole', 'auth', 'isStaff']),
	},
	created() {
		this.startDay = this.account?.start_of_week || 'Sun'

		// Starting days
		if (this.startDay === 'Mon') {
			this.days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
		} else {
			this.days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
		}

		this.updateMonthVariables()
		this.getVenuesEvents(this.today)

		this.getVenuesMonthAvailability(this.yearMonth).then(() => {
			this.selectedDate = this.monthDaysList.find(
				day => day.dayString == ('0' + this.selectedDayAndMonth.day).slice(-2)
			).date
			this.selectedDateCalendarSlots = this.monthDaysList.find(
				day => day.dayString == ('0' + this.selectedDayAndMonth.day).slice(-2)
			).slots
		})

		// preload availability for prev and next month
		this.getVenuesMonthAvailability(this.yearMonthNext)
		this.getVenuesMonthAvailability(this.yearMonthPrev)
	},
	methods: {
		format,
		utcToZonedTime,

		// Check is today in current month and year
		todayInCurrentMonthAndYear() {
			return this.month === this.initialMonth && this.year === this.initialYear
		},

		// Get days from prev month that fits in week row
		getDaysFromPreviousMonth: function() {
			let daysList = []
			let count =
				getDaysInMonth(subMonths(this.dateContext, 1)) -
				getDay(subDays(this.dateContext, this.currentDate)) -
				this.additionalDayNum()

			while (count < getDaysInMonth(subMonths(this.dateContext, 1))) {
				count++
				daysList[count] = count
			}
			return daysList.filter(function() {
				return true
			})
		},

		// Add additional day based on based on start day
		additionalDayNum() {
			return this.startDay === 'Mon' ? 0 : 1
		},

		// Update all variables that creates calendar
		updateMonthVariables() {
			this.year = format(this.dateContext, 'Y')
			this.month = format(this.dateContext, 'MMMM')
			this.daysInPreviousMonth = getDaysInMonth(subMonths(this.dateContext, 1))
			this.previousMonth = subMonths(this.dateContext, 1)
			this.nextMonth = addMonths(this.dateContext, 1)
			this.daysInMonth = getDaysInMonth(this.dateContext)
			this.currentDate = getDate(this.dateContext)
			this.initialDate = parseInt(this.formattingDay(getDate(this.today)))
			this.initialMonth = format(this.today, 'MMMM')
			this.initialYear = format(this.today, 'Y')
			this.selectedDay = parseInt(format(this.selectedDate, 'dd'))
			this.selectedMonth = parseInt(format(this.selectedDate, 'MM'))
			this.selectedYear = parseInt(format(this.selectedDate, 'Y'))
			this.yearMonthNext = format(this.nextMonth, 'Y') + '-' + format(this.nextMonth, 'MM')
			this.yearMonthPrev = format(this.previousMonth, 'Y') + '-' + format(this.previousMonth, 'MM')
			this.selectedDayAndMonth.day = format(this.selectedDate, 'd')
			this.selectedDayAndMonth.month = format(this.selectedDate, 'MMMM')
			this.daysFromPreviousMonth = this.getDaysFromPreviousMonth()
		},

		// Function getVenueAvailability fetching venue data from API
		getVenuesMonthAvailability(yearMonth) {
			return new Promise((resolve, reject) => {
				if (this.monthsCache[yearMonth] && !this.forceNewData) {
					this.generateMonthDays()
					resolve(this.monthsCache[yearMonth])
				} else {
					heyGovApi.get(`/${this.j.slug}/venues/venues-availability/${yearMonth}`).then(
						({ data }) => {
							this.monthsCache[yearMonth] = data.days
							this.generateMonthDays()
							resolve(this.monthsCache[yearMonth])
						},
						error => {
							reject(error)
							Vue.toasted.error(`Error loading venue events ~ ${error}`)
						}
					)
				}
			})
		},

		// Function getVenuesEvents
		getVenuesEvents(date) {
			this.dayEventLoading = true
			let dateEvents = format(date, 'Y-MM-dd')
			this.selectedDateString = dateEvents
			heyGovApi
				.get(
					`/${this.j.slug}/venue-bookings?starts_after=${dateEvents}T00:00:00.000Z&starts_before=${dateEvents}T23:59:59.000Z&limit=5&order=asc&orderBy=starts_at`
				)
				.then(
					({ data }) => {
						this.events = data
						this.dayEventLoading = false
					},
					error => {
						Vue.toasted.error(`Error loading venue events ~ ${error}`)
					}
				)
		},

		// Function generateMonthDays creating all dates for calendar
		generateMonthDays() {
			let days = []
			let prevMonthDaysCount = 0
			let currMonthDaysCount = 0
			let currMonth = this.yearMonth.slice(-2)
			let currYear = this.yearMonth.slice(-8, -3)
			let prevMonth =
				('0' + (parseInt(currMonth) - 1)).slice(-2) === '00'
					? '12'
					: ('0' + (parseInt(currMonth) - 1)).slice(-2)
			let prevYear = ('0' + (parseInt(currYear) - 1)).slice(-4)
			let nextMonth =
				('0' + (parseInt(currMonth) + 1)).slice(-2) === '13'
					? '01'
					: ('0' + (parseInt(currMonth) + 1)).slice(-2)
			let nextYear = ('0' + (parseInt(currYear) + 1)).slice(-4)

			// Handle first month in year
			if (!(nextMonth === '01')) {
				nextYear = currYear
			}
			// Handle last month in year
			if (!(prevMonth === '12')) {
				prevYear = currYear
			}

			let visibleDaysCounter = 0
			// Filling in dates from previous month just to populate missing dates in week ------------------
			if (this.daysFromPreviousMonth.length < 7) {
				this.daysFromPreviousMonth.forEach(function(date) {
					let dayString = ('0' + date).slice(-2)
					days[visibleDaysCounter] = {
						dateString: prevYear + '-' + prevMonth + '-' + dayString,
						dayString: dayString,
						monthString: prevMonth,
						yearString: prevYear,
						key: visibleDaysCounter,
						type: 'prev-month',
						today: false,
						slots: [],
						selected: false,
						date: parseISO(prevYear + '-' + prevMonth + '-' + dayString),
						haveEvents: false,
						status: null,
					}
					visibleDaysCounter++
					prevMonthDaysCount++
				})
			}

			// Filling in dates from current month -----------------------------------------------------------
			if (this.monthsCache[this.yearMonth]) {
				Object.values(this.monthsCache[this.yearMonth]).forEach((day, index) => {
					let dayString = ('0' + (index + 1)).slice(-2)
					days[visibleDaysCounter] = {
						dateString: currYear + '-' + currMonth + '-' + dayString,
						dayString: dayString,
						monthString: currMonth,
						yearString: currYear,
						key: visibleDaysCounter,
						type: 'curr-month',
						today: dayString == this.initialDate && this.todayInCurrentMonthAndYear(),

						selected:
							('0' + this.selectedDay).slice(-2) == dayString &&
							('0' + this.selectedMonth).slice(-2) == currMonth
								? true
								: false,
						date: parseISO(currYear + '-' + currMonth + '-' + dayString),
						haveEvents: day.haveEvents,
						status: day.status,
					}
					currMonthDaysCount++
					visibleDaysCounter++
				})
			}

			// Filling in dates from next month just to populate missing dates in week -----------------------
			let totalPrevCurr = currMonthDaysCount + prevMonthDaysCount
			let nextMonthCount = 7 - (totalPrevCurr % 7) > 6 ? 0 : 7 - (totalPrevCurr % 7)

			let nextMonthDays = 1

			while (nextMonthDays < nextMonthCount + 1) {
				let dayString = ('0' + nextMonthDays).slice(-2)
				days[visibleDaysCounter] = {
					dateString: nextYear + '-' + nextMonth + '-' + dayString,
					dayString: dayString,
					monthString: nextMonth,
					yearString: nextYear,
					key: visibleDaysCounter,
					type: 'next-month',
					today: false,
					slots: [],
					selected: false,
					date: parseISO(nextYear + '-' + nextMonth + '-' + dayString),
					haveEvents: false,
					status: null,
				}
				nextMonthDays++
				visibleDaysCounter++
			}

			this.monthDaysList = days
		},

		// Function addMonth moving calendar view to next month
		addMonth: function() {
			return new Promise((resolve, reject) => {
				this.yearMonth = format(this.nextMonth, 'Y') + '-' + format(this.nextMonth, 'MM')
				this.dateContext = this.nextMonth
				this.updateMonthVariables()
				this.getVenuesMonthAvailability(this.yearMonthNext)
					.then(() => {
						this.generateMonthDays()
						resolve()
					})
					.catch(reject)
			})
		},

		// Function subtractMonth moving calendar view to previous month
		subtractMonth: function() {
			return new Promise((resolve, reject) => {
				this.yearMonth = format(this.previousMonth, 'Y') + '-' + format(this.previousMonth, 'MM')
				this.dateContext = this.previousMonth
				this.updateMonthVariables()
				this.getVenuesMonthAvailability(this.yearMonthPrev)
					.then(() => {
						this.generateMonthDays()
						resolve()
					})
					.catch(reject)
			})
		},

		// Function setSelectedDate triggered when click on some date on calendar
		setSelectedDate: function(date) {
			if (date.type !== 'prev-month' && date.type !== 'next-month' /* && this.options.mode === 'admin' */) {
				this.selectedDate = date.date
				this.selectedDateCalendarSlots = date.slots
				this.deselectDates()
				date.selected = true
			} else if (date.type === 'prev-month') {
				this.selectedDate = date.date
				this.deselectDates()
				date.selected = true
				this.subtractMonth().then(
					(this.selectedDateCalendarSlots = this.monthDaysList.find(
						day => day.dayString == ('0' + this.selectedDayAndMonth.day).slice(-2)
					).slots)
				)
			} else if (date.type === 'next-month') {
				this.selectedDate = date.date
				this.deselectDates()
				date.selected = true
				this.addMonth().then(
					(this.selectedDateCalendarSlots = this.monthDaysList.find(
						day => day.dayString == ('0' + this.selectedDayAndMonth.day).slice(-2)
					).slots)
				)
			}

			this.getVenuesEvents(date.date)
			this.updateMonthVariables()
		},

		// Deselect all dates in monthDaysList
		deselectDates() {
			this.monthDayList = this.monthDaysList.forEach(d => {
				if (d.selected) {
					d.selected = false
				}
			})
		},

		// Function goToday returns to current month and day from any calendar view
		goToday: function() {
			this.selectedDate = this.today
			this.dateContext = this.today
			this.yearMonth = format(this.selectedDate, 'Y') + '-' + format(this.selectedDate, 'MM')
			this.updateMonthVariables()
			this.getVenuesMonthAvailability(this.yearMonth).then(
				(this.selectedDateCalendarSlots = this.monthDaysList.find(
					day => day.dayString == this.selectedDayAndMonth.day
				).slots)
			)
		},

		// Function formattingDay formatting 1 to 01 for date
		formattingDay: function(day) {
			return ('0' + day).slice(-2)
		},

		// Function findVenue finds venue in venues array
		findVenue(venueId) {
			return this.venues.find(v => v.id == venueId)
		},

		// Go on table view for all bookings with double click on date in calendar
		showInTable() {
			this.$router.push(
				`/${this.j.slug}/venues/bookings?booking_start=${this.selectedDateString}&booking_end=${this.selectedDateString}`
			)
		},

		// Go to booking details
		bookingDetails(event) {
			let venue = this.venues.find(v => v.id === event.venue_id)
			this.$router.push(`/${this.j.slug}/venues/${venue.slug}/bookings/${event.uuid}`)
		},

		// Function to highlight venue in venue list - FOR FUTURE
		highlightVenue(event) {
			if (event) {
				let venue = this.venues.find(v => v.id === event.venue_id)
				this.$emit('highlightVenue', venue)
			} else {
				this.$emit('highlightVenue', null)
			}
		},
	},
	filters: {
		bookingTime(date) {
			return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
		},
	},
}
</script>

<style lang="scss" scoped>
.event-item {
	border-radius: 1rem;
	.event-date {
		position: absolute;
		margin-left: 10px;
		margin-top: -25px;
		font-size: 0.875rem;
		background-color: #e4f7e6;
		border-radius: 1rem;
		padding: 0px 10px;
	}
}
.venue-public-calendar-wrapper {
	.venue-public-calendar {
		margin-bottom: 2rem;
		width: 100%;
		.venue-public-calendar-header {
			font-size: 1rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.calendar-back {
				button {
					background-color: transparent;
					border: none;
				}
			}
			.calendar-forward {
				button {
					background-color: transparent;
					border: none;
				}
			}
			.btn-today {
				background-color: transparent;
				border: none;
			}
		}
		.venue-public-calendar-body {
			border-radius: 1rem;
			background-color: #f9f9f9;
			.venue-public-calendar-weekdays {
				display: flex;
				padding-top: 0.875rem;
				padding-bottom: 0.875rem;
				.weekday-text {
					width: calc(100% / 7);
					padding: 0.25rem 0.5rem;
				}
			}
			.venue-public-calendar-dates {
				padding: 5px;
				display: flex;
				flex-wrap: wrap;
				position: relative;
				text-align: center;
				border-radius: 1rem;
				.venue-public-calendar-date {
					background-color: rgb(249, 249, 249);
					width: calc(100% / 7);
					margin: 3px auto;
					.hey-date {
						display: flex;
						align-items: center;
						justify-content: center;
						// Active date (date when clicked on it)
						&.active-date {
							.day-text {
								border-radius: 50%;
								font-weight: bold;
								color: white !important;
								background-color: var(--bs-primary) !important;
								border: 2px solid var(--bs-primary);
							}
						}

						// Look of todays date
						&.today-date {
							.day-text {
								border-radius: 50%;
								font-weight: bold;
								background-color: transparent;
								border: 2px solid var(--bs-primary);
								color: var(--bs-primary);
							}
						}

						// Look of dates from Previous and Next month that populates calendar page
						&.not-current-month-date {
							.day-text {
								color: #ccc;
								font-weight: 400;
								text-decoration: none;
							}
						}

						.day-text {
							display: flex;
							width: 100%;
							justify-content: center;
							align-items: center;
							text-align: center;
							border: 1px solid transparent;
							width: 50px;
							height: 50px;
						}

						// Day dot
						.day-dot-blink {
							width: 5px;
							margin-right: -5px;
							height: 5px;
							border-radius: 50px;
							position: relative;
							left: -28px;
							div {
								width: 7px;
								height: 7px;
							}
						}

						// Day dot
						.day-dot {
							width: 7px;
							margin-right: -7px;
							height: 7px;
							border-radius: 50px;
							background: #2a9836;
							position: relative;
							left: -28px;
							bottom: -15px;
						}
					}
				}
			}
		}
	}

	.venue-public-slots-availability {
		.slot {
			margin-bottom: 1rem;
			background-color: #e4f7e6;
			padding: 1rem;
			border-radius: 1rem;

			.slot-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.slot-info {
					display: flex;
					justify-content: center;
					align-items: flex-start;
					flex-direction: column;
					text-transform: uppercase;
					.slot-time {
						font-weight: 800;
					}
				}
			}
			&.disabled {
				color: #898c90 !important;
				background-color: #dfdede !important;
			}
		}
	}

	@media (max-width: 480px) {
		.weekday-text {
			width: calc(100% / 7);
			padding: 0.25rem 0.5rem;
			font-size: 0.7rem !important;
		}

		.venue-public-calendar-date {
			margin-top: 0px !important;
			margin-bottom: 0px !important;
		}

		.slot-info {
			.slot-name {
				font-size: 0.875rem !important;
			}
			.slot-time {
				font-size: 0.875rem !important;
			}
		}

		.day-text {
			font-size: 0.875rem !important;
			width: 40px !important;
			height: 40px !important;
		}
	}
}
</style>
