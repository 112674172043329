<template>
	<div class="page-meeting-files">
		<div class="card">
			<div class="card-body">
				<h5 class="mb-3">All files linked to this meeting</h5>

				<div class="card-table mb-4">
					<table class="table table-hover">
						<thead>
							<tr>
								<th scope="col">Section</th>
								<th scope="col">File</th>
								<th scope="col">Type</th>
								<th scope="col">Uploaded on</th>
								<th scope="col">Uploaded by</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="file in files" :key="file.url">
								<td>{{ file.section }}</td>
								<td>
									<a :href="file.url" target="_blank">
										{{ file.name }}
									</a>
								</td>
								<td>
									{{ file.extension }}
								</td>
								<td>
									<small class="text-neutral-300">-</small>
								</td>
								<td>
									<small class="text-neutral-300">-</small>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<!-- <div class="row row-cols-md-2 row-cols-lg-3 row-cols-xl-4 mb-4">
					<div v-if="meeting.agenda_file_path" class="col">
						<div class="bg-neutral-100 px-2 py-3 border rounded-1 text-center position-relative on-parent">
							<font-awesome-icon :icon="['fas', 'file-pdf']" class="text-primary mb-2" />

							<p class="text-neutral-500 mb-0 text-truncate" :title="meeting.title">
								<small>Agenda</small>
							</p>
						</div>
					</div>

					<div v-if="meeting.agenda_pack_file_path" class="col">
						<div class="form-group">
							<label class="form-label">Agenda packet</label>

							<a
								:href="getPublicFileUrl(meeting.agenda_pack_file_path)"
								class="d-block ratio ratio-16x9 border border-primary rounded-1 bg-primary-50"
							>
								<span class="d-inline-block  p-3">
									<font-awesome-icon :icon="['fas', 'file']" />
									{{
										meeting.agenda_pack_file_path
											.split('/')
											.pop()
											.slice(-30)
									}}
								</span>
							</a>
						</div>
					</div>
				</div> -->

				<div class="row justify-content-center">
					<div class="col-md-10 col-lg-8 col-xl-6">
						<div class="bg-light p-3 border border-dashed rounded-1 px-4 py-5 text-center">
							Use the specific tabs above, Agenda/Recording/Minutes, to add files to this meeting.
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getPublicFileUrl } from '@/utils.js'

//import heyGovApi from '@/api.js'

export default {
	name: 'MeetingFiles',
	metaInfo() {
		return {
			title: `Files for ${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
		meetingPlayer: {
			type: Object,
			required: true,
		},
	},
	computed: {
		files() {
			const files = []

			if (this.meeting.agenda_file_path) {
				files.push({
					section: 'Agenda',
					name: this.meeting.agenda_file_path.split('/').at(-1),
					extension: this.meeting.agenda_file_path.split('.').at(-1),
					path: this.meeting.agenda_file_path,
					url: getPublicFileUrl(this.meeting.agenda_file_path),
				})
			}

			if (this.meeting.agenda_pack_file_path) {
				files.push({
					section: 'Agenda',
					name: this.meeting.agenda_pack_file_path.split('/').at(-1),
					extension: this.meeting.agenda_pack_file_path.split('.').at(-1),
					path: this.meeting.agenda_pack_file_path,
					url: getPublicFileUrl(this.meeting.agenda_pack_file_path),
				})
			}

			if (this.meeting.video_file_path) {
				files.push({
					section: 'Recording',
					name: this.meeting.video_file_path.split('/').at(-1),
					extension: this.meeting.video_file_path.split('.').at(-1),
					path: this.meeting.video_file_path,
					url: getPublicFileUrl(this.meeting.video_file_path),
				})
			}

			if (this.meeting.audio_file_path) {
				files.push({
					section: 'Recording',
					name: this.meeting.audio_file_path.split('/').at(-1),
					extension: this.meeting.audio_file_path.split('.').at(-1),
					path: this.meeting.audio_file_path,
					url: getPublicFileUrl(this.meeting.audio_file_path),
				})
			}

			if (this.meeting.meeting_minutes_file_path) {
				files.push({
					section: 'Recording',
					name: this.meeting.meeting_minutes_file_path.split('/').at(-1),
					extension: this.meeting.meeting_minutes_file_path.split('.').at(-1),
					path: this.meeting.meeting_minutes_file_path,
					url: getPublicFileUrl(this.meeting.meeting_minutes_file_path),
				})
			}

			if (this.meeting.additional_file_path) {
				files.push({
					section: 'Other',
					name: this.meeting.additional_file_path.split('/').at(-1),
					extension: this.meeting.additional_file_path.split('.').at(-1),
					path: this.meeting.additional_file_path,
					url: getPublicFileUrl(this.additional_file_path.audio_file_path),
				})
			}

			return files
		},
	},
	created() {
		//heyGovApi.post()
	},
	mounted() {
		if (this.meetingPlayer.position === 'default') {
			this.$emit('playerStyles', {
				position: 'fixed',
				width: `300px`,
				//top: `${$playerPosition.top + window.scrollY}px`,
				//left: `${$playerPosition.left}px`,
				right: '16px',
				bottom: '16px',
			})
		}
	},
	methods: {
		getPublicFileUrl,
	},
}
</script>
