<template>
	<textarea
		v-if="!currentField.pdf"
		:class="'form-control ' + currentField.class"
		:rows="3"
		:id="'fsf-' + currentField.id"
		:placeholder="currentField.placeholder"
		:required="currentField.required == 1"
		v-model="currentField.value"
	></textarea>

	<div v-else>
		<!-- Empty textarea field display three lines -->
		<div v-if="currentField.display !== 'form'">
			<div v-if="currentField.redacted === 1 && $route.query.redacted && currentField.display !== 'form'">
				<div class="pdf-redacted"></div>
				<div class="pdf-redacted"></div>
				<div class="pdf-redacted"></div>
			</div>
			<div v-else-if="currentField.value === ''">
				<div class="pdf-field"></div>
				<div class="pdf-field"></div>
				<div class="pdf-field"></div>
			</div>
			<div v-else>
				<div v-if="currentField.data.multiple" class="w-100">
					<p v-for="file in currentField.value" :key="`file-${file}`">
						{{ file }}
					</p>
				</div>
				<div v-else>
					<p class="pdf-field">{{ currentField.value }}</p>
				</div>
			</div>
		</div>
		<small v-if="currentField.help" class="text-muted pdf-help">{{ currentField.help }}</small>
	</div>
</template>

<script>
export default {
	name: 'LongTextInput',
	props: ['currentField'],
}
</script>
