<template>
	<div class="jurisdiction-dashboard">
		<div class="row g-0 align-items-center mb-3">
			<div class="col">
				<h4 class="my-0">
					<span class="text-capitalize">{{ j.type }}</span> dashboard
				</h4>
			</div>
			<div class="col-auto">
				<select class="form-select form-select-sm ps-2" v-model="filters.days">
					<option value="7">Last 7 days</option>
					<option value="14">Last 14 days</option>
					<option value="30">Last 30 days</option>
				</select>
			</div>
		</div>

		<!--
		<p class="status-legend mb-2">
			<label class="text-secondary text-uppercase me-3">⬤ Received</label>
			<label class="text-primary text-uppercase me-3">⬤ In progress</label>
			<label class="text-success text-uppercase me-3">⬤ Resolved</label>
			<label class="text-danger text-uppercase me-3">⬤ Declined</label>
		</p>
		-->

		<div class="row">
			<div class="col-lg-6">
				<div class="card mb-4">
					<div class="card-body">
						<h5 class="mb-4">Daily activity</h5>

						<stats-bar-chart
							v-if="stats"
							:data="statsNewIssuesChart"
							:options="statsNewIssuesChartOptions"
							:height="300"
						></stats-bar-chart>
						<div v-else class="spinner-border" role="status"></div>
					</div>
				</div>
			</div>

			<div class="col-lg-6">
				<muni-map class="mb-3" :height="395" :heatmap="markers"></muni-map>
			</div>
		</div>

		<div v-if="j.features.includes('forms')" class="card mb-4">
			<div class="card-body">
				<router-link v-if="forms.length" :to="`/${j.slug}/form-requests`" class="float-end"
					>See all →</router-link
				>
				<h5 class="mb-3">
					<img
						src="https://files.heygov.com/assets/icon-heylicense.png"
						height="20"
						class="float-start me-2"
						alt="HeyLicense"
					/>
					Latest <span class="d-none d-md-inline-block">license &amp; permit</span> applications
				</h5>

				<div class="card-table"><form-requests-table :forms="forms"></form-requests-table></div>
			</div>
		</div>

		<div v-if="j.features.includes('issues')" class="card mb-4">
			<div class="card-body">
				<div class="row">
					<div class="col">
						<h5 class="mb-4">
							<img
								src="https://files.heygov.com/assets/icon-hey311.png"
								height="20"
								class="float-start me-2"
								alt="Hey311"
							/>
							311 Requests status
						</h5>

						<div v-if="stats">
							<div v-for="(departmentStatuses, departmentId) in stats.byDepartment" :key="departmentId">
								<p class="mb-1">
									<router-link :to="`/threads?agency_id=${departmentId}`" class="text-dark"
										>{{
											departments
												? departments.find(d => d.id == departmentId).name
												: departmentId
										}}
										<small class="text-muted"
											>{{ departmentStatuses.total }} Issues</small
										></router-link
									>
								</p>
								<div class="progress mb-3">
									<router-link
										v-for="(statusInfo, status) in statuses"
										:key="status"
										:to="`/threads?agency_id=${departmentId}&status=${status}`"
										class="progress-bar"
										:class="[statusInfo.bgClass]"
										role="progressbar"
										:style="{
											width: `${(departmentStatuses[status] / statsDepartmentMax) * 100}%`,
										}"
										>{{ departmentStatuses[status] }}
										{{
											departmentStatuses[status] / statsDepartmentMax > 0.15
												? statusInfo.name
												: ''
										}}</router-link
									>
								</div>
							</div>
						</div>
						<div v-else class="spinner-border" role="status"></div>
					</div>

					<div class="col-lg-3">
						<stats-pie-chart
							v-if="stats"
							:data="statsIssueStatusChart"
							:options="statsIssueStatusChartOptions"
							:plugins="[statsIssueStatusPlugin]"
							:height="200"
						></stats-pie-chart>
						<div v-else class="spinner-border" role="status"></div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="j.features.includes('polls')" class="card mb-4">
			<div class="card-body">
				<h5 class="mb-4">
					<img
						src="https://files.heygov.com/assets/icon-heypolls.png"
						height="20"
						class="float-start me-2"
						alt="HeyPooling"
					/>
					Straw polls status
				</h5>

				<table v-if="states.polls === 'idle' && polls.length" class="table mb-0">
					<thead>
						<tr>
							<th class="border-0">Title</th>
							<th class="border-0">Responses</th>
							<th class="border-0">Closes at</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="poll in polls" :key="`poll-${poll.id}`">
							<td>
								<router-link :to="`/${j.slug}/polls/${poll.slug}`">{{ poll.title }}</router-link>
							</td>
							<td>
								<router-link
									:to="`/${j.slug}/polls/${poll.slug}/responses`"
									class="btn btn-sm btn-outline-dark"
									>View {{ poll.responses }} responses</router-link
								>
							</td>
							<td>{{ new Date(poll.closes_at).toLocaleString() }}</td>
						</tr>
					</tbody>
				</table>

				<div v-else-if="states.polls === 'idle'">
					<p class="text-center text-muted">
						<small
							>There are no Straw Polls yet.
							<router-link :to="`/${j.slug}/polls/create`">Create one now</router-link></small
						>
					</p>
				</div>

				<div v-else-if="states.polls === 'loading'" class="spinner-border" role="status"></div>
			</div>
		</div>

		<div
			v-if="j.features.includes('payments') || j.features.includes('forms') || j.features.includes('venues')"
			class="card mb-4"
		>
			<div class="card-body">
				<router-link v-if="payments.length" :to="`/${j.slug}/payments`" class="float-end"
					>See all →</router-link
				>
				<h5 class="mb-4">
					<img
						src="https://files.heygov.com/assets/icon-heygovpay.png"
						height="20"
						class="float-start me-2"
						alt="HeyGov Pay"
					/>
					Payments
				</h5>

				<div class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Amount</th>
								<th>Fee</th>
								<th>Date</th>
								<th>Status</th>
								<th class="d-none d-md-table-cell">Payment method</th>
								<th class="d-none d-md-table-cell">Person</th>
								<th class="d-none d-md-table-cell">Description</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="payment in payments" :key="payment.id">
								<td>
									{{ payment.amount | currency }}
								</td>
								<td>
									{{ payment.fee | currency }}
								</td>
								<td>{{ payment.created_at | dateLocal }}</td>
								<td>
									<payment-status :payment="payment" :tooltip="true" />
								</td>
								<td class="d-none d-md-table-cell">
									<payment-method
										v-if="payment.payment_method"
										:payment-method="payment.payment_method"
									/>
								</td>
								<td class="d-none d-md-table-cell">
									<person-link v-if="payment.person_id" :id="payment.person_id" :avatar="18" />
									<span v-else>-</span>
								</td>
								<td class="d-none d-md-table-cell">
									<entity-link
										v-if="payment.source && payment.source.includes('_')"
										:entity="payment.source"
									></entity-link>
									<span v-else>
										<font-awesome-icon
											v-if="payment.source === 'link'"
											:icon="['fas', 'comment-dollar']"
										/>
										<font-awesome-icon
											v-else-if="payment.source === 'quick-pay'"
											icon="fa-solid fa-forward"
										/>
										<font-awesome-icon
											v-else-if="payment.source === 'card-reader'"
											:icon="['far', 'credit-card']"
										/>
										<pre v-else>{{ payment.source }}</pre>
										{{ payment.description }}
									</span>
								</td>
							</tr>
						</tbody>
						<tfoot>
							<tr v-if="states.payments === 'loading'">
								<td colspan="7">
									<p class="text-center">loading..</p>
								</td>
							</tr>
							<tr v-else-if="states.payments === 'loaded' && !payments.length">
								<td colspan="7">
									<p class="text-center">There are no payments yet 🤷</p>
								</td>
							</tr>
						</tfoot>
					</table>
				</div>
			</div>
		</div>

		<canvas id="canvas"></canvas>
	</div>
</template>

<style lang="scss">
.rounded .vue-map {
	border-radius: 1rem !important;
}

.status-legend {
	font-size: 10px;
	font-weight: 700;
}

.progress {
	background-color: #f9f9f9;

	.progress-bar {
		transition: transform 0.2s ease-out;

		&:hover {
			color: white;
			text-decoration: underline;
			transform: scale(1.05);
		}
	}
}

.card-notifications .row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import { format, parseISO } from 'date-fns'
import heyGovApi from '@/api.js'

import StatsBarChart from '@/components/StatsBarChart.vue'
//import StatsLineChart from '@/components/StatsLineChart.vue'
import StatsPieChart from '@/components/StatsPieChart.vue'
//import Notification from '@/components/Notification.vue'
import MuniMap from '@/components/MuniMap.vue'

import FormRequestsTable from '@/views/Forms/FormRequestsTable.vue'
import PersonLink from '@/components/PersonLink.vue'
import EntityLink from '@/components/EntityLink.vue'
import PaymentMethod from '@/components/payments/PaymentMethod.vue'
import PaymentStatus from '@/components/payments/PaymentStatus.vue'

export default {
	name: 'JurisdictionDashboard',
	components: {
		StatsBarChart,
		StatsPieChart,
		FormRequestsTable,
		PersonLink,
		EntityLink,
		PaymentMethod,
		PaymentStatus,
		MuniMap,
	},
	data() {
		return {
			states: {
				polls: 'loading',
				payments: 'loading',
			},
			filters: {
				days: localStorage.getItem('heygov-dashboard-stats-days') || 14,
			},

			stats: null,
			statsFormRequests: null,
			statsDepartmentMax: 0,

			statsNewIssuesChartOptions: {
				plugins: {
					legend: {
						display: false,
					},
				},
				maintainAspectRatio: false,
				scales: {
					x: {
						grid: {
							display: false,
						},
					},
					y: {
						ticks: {
							//beginAtZero: true,
							//callback: function (value) { if (Number.isInteger(value)) { return value; } },
							stepSize: 3,
						},
					},
				},
			},
			statsNewIssuesChart: {
				labels: [],
				datasets: [
					{
						label: '311 requests',
						data: [],
						fill: true,
						borderWidth: 0,
						backgroundColor: '#ff5d46',
					},
					{
						label: 'Form applications',
						data: [],
						fill: true,
						borderWidth: 0,
						backgroundColor: '#22a6ff',
					},
					{
						label: 'Payments',
						data: [],
						fill: true,
						borderWidth: 0,
						backgroundColor: '#02cea7',
					},
				],
			},

			statsIssueStatusPlugin: {
				beforeDraw: function(chart) {
					const config = chart.config.options.elements.center

					var width = chart.chart.width,
						height = chart.chart.height,
						ctx = chart.chart.ctx

					ctx.restore()
					var fontSize = (height / 150).toFixed(2)
					ctx.font = `${fontSize}em ${config.fontStyle || 'Arial'}`
					ctx.textBaseline = 'middle'

					var textX = Math.round((width - ctx.measureText(config.text).width) / 2),
						textY = height / 2

					ctx.fillText(config.text, textX, textY)
					ctx.save()
				},
			},
			statsIssueStatusChartOptions: {
				cutoutPercentage: 70,
				maintainAspectRatio: false,
				plugins: {
					legend: {
						display: false,
					},
				},
				elements: {
					center: {
						text: '- requests',
						fontStyle: 'Agrandir',
					},
				},
			},
			statsIssueStatusChart: {
				labels: [],
				dats: {},
				datasets: [],
			},

			polls: [],
			payments: [],
		}
	},
	computed: {
		...mapState(['j', 'forms', 'venues', 'statuses', 'departments', 'notifications', 'people']),
		...mapGetters(['auth', 'currentRole']),
		markers() {
			if (this.stats) {
				const threads = this.stats.threads
					.filter(t => t.location && t.location.lat)
					.map(t => {
						return {
							location: { lat: t.location.lat, lng: t.location.lng },
							weight: t.status == 'closed' ? 1 : 2,
						}
					})

				const payments = this.payments
					.filter(t => t.location && t.location.lat)
					.map(t => {
						return {
							location: { lat: t.location.lat, lng: t.location.lng },
							weight: 1,
						}
					})

				return [...threads, ...payments]
			}

			return []
		},
	},
	created() {
		if (this.currentRole === 'CITIZEN') {
			this.$router.replace(`/${this.j.slug}`)
		} else {
			this.$store.dispatch('loadDepartments')
			this.loadStats()
			this.loadPolls()
			this.loadForms()
			this.loadVenues()
			this.loadPayments()
		}
	},
	mounted() {
		// add fill gradient for Issues chart
		/* const ctx = document.getElementById('canvas').getContext('2d')
		const gradient = ctx.createLinearGradient(0, 0, 500, 0)
		gradient.addColorStop(0, 'rgba(245, 245, 245, 0.22)')
		gradient.addColorStop(0.5, 'rgba(55, 81, 255, 0)')
		gradient.addColorStop(1, 'rgba(245, 245, 245, 0.22)') */
		//this.statsNewIssuesChart.datasets[0].backgroundColor = gradient
	},
	methods: {
		loadStats() {
			this.stats = null

			// show New Issues totals
			this.statsNewIssuesChart.labels = []
			this.statsNewIssuesChart.datasets[0].data = []

			Promise.all([
				heyGovApi(`${this.j.slug}/stats`, { params: this.filters }),
				heyGovApi(`${this.j.slug}/stats/for-form-requests`, { params: this.filters }),
				heyGovApi(`${this.j.slug}/stats/for-payments`, { params: this.filters }),
			]).then(responses => {
				const data = responses[0].data
				const dataFormRequests = responses[1].data
				const dataPayments = responses[2].data

				// add stats for Issues
				for (const c in data.byDay) {
					this.statsNewIssuesChart.labels.push(format(parseISO(data.byDay[c].date), 'MMM d'))
					this.statsNewIssuesChart.datasets[0].data.push(data.byDay[c].total)
				}

				// show Issues by status
				this.statsIssueStatusChartOptions.elements.center.text = `${data.total} requests`
				this.statsIssueStatusChart = {
					labels: ['Received', 'In progress', 'Resolved', 'Closed'],
					datasets: [
						{
							label: 'Issues',
							data: Object.values(data.byStatus),
							backgroundColor: [`#FFB800`, `#5E81F4`, '#8DD384', '#FF7459'],
						},
					],
				}

				// find the max issues in a department
				// used to calculate percentages
				for (const departmentId in data.byDepartment) {
					if (data.byDepartment[departmentId].total > this.statsDepartmentMax) {
						this.statsDepartmentMax = data.byDepartment[departmentId].total
					}
				}

				// add stats for FormRequests
				for (const c in dataFormRequests.byDay) {
					this.statsNewIssuesChart.datasets[1].data.push(dataFormRequests.byDay[c].total)
				}

				// add stats for Payments
				for (const c in dataPayments.byDay) {
					this.statsNewIssuesChart.datasets[2].data.push(dataPayments.byDay[c].total)
				}

				this.stats = data
				this.statsFormRequests = dataFormRequests
				this.statsPayments = dataPayments
			})
		},
		loadPolls() {
			this.states.polls = 'loading'

			heyGovApi.get(`${this.j.slug}/polls`).then(
				({ data }) => {
					this.polls.push(...data)

					this.states.polls = 'idle'
				},
				() => {
					this.states.polls = 'error'
				}
			)
		},

		loadForms() {
			if (this.j.features.includes('forms')) {
				this.states.forms = 'loading'

				this.$store.dispatch('getForms').then(
					() => {
						this.states.forms = 'idle'
					},
					() => {
						this.states.forms = 'error'
					}
				)
			}
		},

		loadVenues() {
			if (this.j.features.includes('venues')) {
				this.$store.dispatch('getVenues')
			}
		},

		loadPayments() {
			if (
				this.j.features.includes('payments') ||
				this.j.features.includes('forms') ||
				this.j.features.includes('venues')
			) {
				const params = {
					limit: 10,
				}

				heyGovApi(`${this.j.slug}/payments`, { params }).then(
					({ data }) => {
						this.payments = data
						this.states.payments = 'loaded'
					},
					() => {
						this.states.payments = 'error'
					}
				)
			}
		},
	},
	watch: {
		filters: {
			deep: true,
			handler(filters) {
				localStorage.setItem('heygov-dashboard-stats-days', filters.days)
				this.loadStats()
			},
		},
	},
}
</script>
