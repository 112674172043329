<template>
	<div v-if="!currentField.pdf">
		<div :id="'fsf-' + currentField.id">
			<div
				v-for="item in currentField.options.filter(i => !i.removed)"
				:class="'form-check ' + inlineClass + ' ' + currentField.class"
				:key="`fr-checkbox-${currentField.id}-${item.optionValue}`"
			>
				<input
					class="form-check-input"
					type="checkbox"
					v-model="currentField.value.values"
					:value="item.optionValue"
					:required="item.required == 1"
					:id="`field-${currentField.id}-value-${item.optionValue}`"
				/>
				<label class="form-check-label" :for="`field-${currentField.id}-value-${item.optionValue}`">
					{{ item.optionLabel }}
				</label>
				<input
					v-if="visibleAdditionalValue(item, currentField)"
					type="text"
					class="border rounded px-2 ms-2"
					v-model="currentField.value.extra[item.optionValue]"
				/>
			</div>
		</div>
	</div>

	<!-- PDF View -->
	<div v-else>
		<div v-if="currentField.display !== 'form'">
			<div class="d-inline-flex">
				<label class="pdf-label form-label ms-0 pe-2" for="">{{ currentField.label }}</label>
				<div v-if="currentField.redacted && $route.query.redacted" class="pdf-redacted"></div>
				<div v-else class="pdf-radio d-flex flex-wrap">
					<template v-for="item in currentField.options">
						<div
							:key="item.optionValue"
							:class="'d-flex ms-1 form-check ' + inlineClass + ' ' + currentField.class"
						>
							<div
								class="d-flex align-items-center justify-content-center"
								style="width: 14px; height: 14px; border: 1px solid black"
							>
								<font-awesome-icon
									v-if="
										item.optionValue === currentField.value.values ||
											currentField.value.values.includes(item.optionValue)
									"
									:icon="['fas', 'check']"
								/>
							</div>

							<label
								:key="item.optionLabel"
								:for="`field-${currentField.id}-value-${item.optionValue}`"
								class="form-check-label ms-1"
								v-if="!item.removed"
								>{{ item.optionLabel }}</label
							>
							<div v-if="visibleAdditionalValue(item, currentField)" class="ms-2">
								<span class="text-decoration-underline">{{
									currentField.value.extra[item.optionValue]
								}}</span>
							</div>
						</div>
					</template>
				</div>
			</div>
			<div v-if="currentField.help" class="text-muted pdf-help">{{ currentField.help }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Checkbox',
	props: ['currentField'],
	computed: {
		inlineClass() {
			return this.currentField.data.check_radio_inline ? 'form-check-inline' : ''
		},
	},
	created() {
		if (!this.currentField.value || !this.currentField.value.extra) {
			this.currentField.value = {
				values: [],
				extra: {},
			}
		}
	},
	methods: {
		visibleAdditionalValue(item, currentField) {
			return (
				(item.addAdditionalValue === true || item.optionValue === 'other') &&
				currentField.value.values.includes(item.optionValue)
			)
		},
	},
}
</script>

<style></style>
