<template>
	<div
		class="card casrd-link thread-card rounded-1 mx-0"
		:class="[`thread-status-${thread.status}`]"
		@mouseover="thread._local.hover = true"
		@mouseleave="thread._local.hover = false"
	>
		<div class="card-body p-1">
			<div class="row align-item-centers">
				<div class="col order-3 order-md-1 ps-md-4 pe-md-0">
					<p class="mb-1">
						<input
							type="checkbox"
							class="me-2 mt-2 float-left d-none d-md-inline-block"
							v-model="thread._local.selected"
						/>
						<router-link
							:to="`/${j.slug}/threads/${thread.uuid}`"
							:class="{ lead: threadTitle }"
							v-html="threadTitle || '<i>no message</i>'"
						></router-link>
					</p>

					<p class="mb-2 text-muted">
						<small
							v-if="thread.location && thread.location.name"
							class="me-3 cursor-pointer"
							@click="setMapCenter(thread.location.lat, thread.location.lng)"
							>📍 {{ thread.location.name }}</small
						>
						<small>{{
							formatDistance(new Date(thread.created_at), new Date(), { addSuffix: true })
						}}</small>
					</p>

					<div class="row">
						<div class="col-6">
							<div class="form-group mb-1">
								<label :for="`thread-service-${thread.id}`">Category</label>

								<p v-if="currentRole === 'CITIZEN'">
									{{
										thread.service_id && services
											? services.find(s => s.id === thread.service_id).name
											: 'Unknown'
									}}
								</p>
								<select
									v-else
									class="form-select form-select-sm"
									:class="{ 'is-invalid': !thread.service_id }"
									:id="`thread-service-${thread.id}`"
									v-model="thread.service_id"
								>
									<optgroup v-for="(services, group) in servicesByGroup" :key="group" :label="group">
										<option v-for="service in services" :key="service.id" :value="service.id">{{
											service.name
										}}</option>
									</optgroup>
								</select>
							</div>
						</div>
						<div class="col-6">
							<div class="form-group mb-1">
								<label :for="`thread-agency-${thread.id}`">{{
									currentRole === 'CITIZEN' ? 'Department' : 'Assign to'
								}}</label>

								<p v-if="currentRole === 'CITIZEN'">
									{{
										thread.agency_id && departments
											? departments.find(s => s.id === thread.agency_id).name
											: 'Unknown'
									}}
								</p>
								<select
									v-else
									class="form-select form-select-sm"
									:class="{ 'is-invalid': !thread.agency_id }"
									:id="`thread-agency-${thread.id}`"
									v-model="thread.agency_id"
								>
									<option v-for="agency in activeDepartments" :key="agency.id" :value="agency.id">{{
										agency.name
									}}</option>
								</select>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-3 order-1 order-md-2 text-md-end pe-md-0">
					<p v-if="person" class="mb-2 d-inline-block d-md-block">
						<a role="button" class="link-primary" @click="personPreview()">
							<person-avatar :preview="true" :person="person" :size="18"></person-avatar>
							{{ thread.anonymous ? person.anonymous_name || 'Anonymous' : person.name }}
						</a>
					</p>
					<p v-else class="mb-1">
						<i>Anonymous</i>
					</p>

					<p class="mb-md-4 text-muted d-inline-block d-md-block float-end">
						<span class="me-3">{{ new Date(thread.created_at).toLocaleDateString() }}</span>
						<span>{{ new Date(thread.created_at).toLocaleTimeString('en', { timeStyle: 'short' }) }}</span>
					</p>
					<p class="mb-0 pt-3 d-none">
						<small class="text-muted" v-html="threadSources[thread.source] || 'unknown source'"></small>
					</p>
				</div>

				<div
					class="col-12 col-md-auto order-2 order-md-3 mb-2 mb-md-0 d-md-block"
					:class="{ 'd-none': !imageMessages.length }"
				>
					<div
						v-if="imageMessages.length"
						:id="`carousel-${thread.id}`"
						class="carousel slide"
						data-bs-ride="carousel"
						style="width:125px"
					>
						<div class="carousel-inner">
							<div
								v-for="(file, index) in imageMessages"
								:key="file.id"
								class="carousel-item"
								:class="{ active: !index }"
							>
								<img
									:src="file.data.urlThumb || file.data.url"
									class="rounded-1"
									width="125"
									:alt="file.message"
									@click="showImg(index, imageMessages)"
									role="button"
								/>
							</div>
						</div>
						<a
							v-if="imageMessages.length > 1"
							class="carousel-control-prev"
							role="button"
							:data-bs-target="`#carousel-${thread.id}`"
							data-bs-slide="prev"
						>
							<span class="carousel-control-prev-icon" aria-hidden="true"></span>
							<span class="sr-only">Previous</span>
						</a>
						<a
							v-if="imageMessages.length > 1"
							class="carousel-control-next"
							:data-bs-target="`#carousel-${thread.id}`"
							role="button"
							data-bs-slide="next"
						>
							<span class="carousel-control-next-icon" aria-hidden="true"></span>
							<span class="sr-only">Next</span>
						</a>
					</div>
					<div v-else>
						<img src="/images/placeholder.jpg" class="rounded opacity-50" width="125" alt="No image" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.thread-card {
	border: 1px solid transparent !important;
	border-left-width: 6px !important;
	transition: transform ease-out 0.2s, border-color ease-out 0.2s !important;

	&.highlighted {
		transform: scale(1.04);
	}

	&.bg-highlighted {
		background-color: lightblue;
	}

	&.thread-status-new {
		border-left-color: var(--bs-warning) !important;

		&.highlighted {
			border-color: var(--bs-warning) !important;
		}
	}

	&.thread-status-approved {
		border-left-color: var(--bs-primary) !important;

		&.highlighted {
			border-color: var(--bs-primary) !important;
		}
	}

	&.thread-status-working {
		border-left-color: var(--bs-info) !important;

		&.highlighted {
			border-color: var(--bs-info) !important;
		}
	}

	&.thread-status-resolved {
		border-left-color: var(--bs-success) !important;

		&.highlighted {
			border-color: var(--bs-success) !important;
		}
	}

	&.thread-status-closed {
		border-left-color: var(--bs-danger) !important;

		&.highlighted {
			border-color: var(--bs-danger) !important;
		}
	}
}

@media (max-width: 575.98px) {
	.thread-card {
		border-left-width: 1px !important;
		border-top-width: 6px !important;

		&.thread-status-new {
			border-top-color: var(--bs-warning) !important;
			border-left-color: transparent !important;
		}

		&.thread-status-approved {
			border-top-color: var(--bs-primary) !important;
			border-left-color: transparent !important;
		}

		&.thread-status-working {
			border-top-color: var(--bs-info) !important;
			border-left-color: transparent !important;
		}

		&.thread-status-resolved {
			border-top-color: var(--bs-success) !important;
			border-left-color: transparent !important;
		}

		&.thread-status-closed {
			border-top-color: var(--bs-danger) !important;
			border-left-color: transparent !important;
		}
	}
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import { formatDistance } from 'date-fns'
import PersonAvatar from './PersonAvatar.vue'
import { Offcanvas } from 'bootstrap'

export default {
	name: 'ThreadCard',
	props: ['thread', 'setMapCenter'],
	components: { PersonAvatar },
	data() {
		return {}
	},
	methods: {
		formatDistance,

		personPreview() {
			const of = new Offcanvas(document.getElementById('person-preview'))
			of.show()
			this.$store.commit('setPreviewPersonId', this.person.id)
		},
		showImg(index, images) {
			this.$emit('showImage', { index, images })
		},
	},
	computed: {
		...mapState(['j', 'threadSources', 'people', 'departments', 'services']),
		...mapGetters(['servicesByGroup', 'activeDepartments', 'currentRole']),
		threadTitle() {
			const textMessages = this.thread.messages.filter(m => m.type === 'text')

			if (this.thread.title) {
				return this.thread.title
			}

			if (textMessages.length) {
				let txt = textMessages[0].message
				const words = txt.split(' ')

				if (words.length > 15) {
					txt = words.slice(0, 13).join(' ') + '…'
				}

				return txt
			}

			return null
		},
		fileMessages() {
			const fileMessages = this.thread.messages.filter(m => m.type === 'file')

			return fileMessages
		},
		imageMessages() {
			return this.thread.messages.filter(m => m.type === 'file' && m.data.type.startsWith('image/'))
		},
		person() {
			return this.people[this.thread.person_id] || null
		},
	},
	watch: {
		'thread.service_id'(service_id, serviceIdOld) {
			this.$store.dispatch('updateThread', {
				thread: this.thread,
				fields: { service_id },
				fieldsOld: { service_id: serviceIdOld },
				messages: this.thread.messages,
			})
		},
		'thread.agency_id'(agency_id, agencyIdOld) {
			this.$store.dispatch('updateThread', {
				thread: this.thread,
				fields: { agency_id },
				fieldsOld: { agency_id: agencyIdOld },
				messages: this.thread.messages,
			})
		},
	},
}
</script>
