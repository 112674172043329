<template>
	<div class="page-venue-group-book">
		<div class="row justify-content-center">
			<div class="col-xl-9 col-lg-10 col-md-11">
				<form
					@submit.prevent="checkAvailability"
					class="bar-filters bar-filters-venue-group-book border rounded bg-white p-1 mb-2"
				>
					<div class="row align-items-center g-2">
						<div v-if="venueGroup.slot_type === 'both'" class="col-sm-6 col-lg">
							<div class="row gx-2">
								<div class="col">
									<label class="d-block rounded-1 px-1 py-2 hover" for="slot-type-multi">
										<div class="form-check form-check-inline me-1">
											<input
												class="form-check-input"
												type="radio"
												v-model="filters.slot_type"
												id="slot-type-multi"
												value="multi"
											/>
											<label class="form-check-label" for="slot-type-multi">{{
												j.slug === 'townoftregowi.com' ? 'Daily' : 'Transient'
											}}</label>
										</div>
									</label>
								</div>
								<div class="col">
									<label class="d-block rounded-1 px-1 py-2 hover" for="slot-type-dates">
										<div class="form-check form-check-inline">
											<input
												class="form-check-input"
												type="radio"
												v-model="filters.slot_type"
												id="slot-type-dates"
												value="dates"
											/>
											<label class="form-check-label" for="slot-type-dates">{{
												j.slug === 'townoftregowi.com' ? 'Seasonal' : 'Half Season'
											}}</label>
										</div>
									</label>
								</div>
							</div>
						</div>

						<div class="col-sm-6 col-lg">
							<date-picker
								v-if="filters.slot_type === 'multi'"
								:formatter="dateFormatter"
								:disabled-date="disabledDate"
								v-model="filters.dates"
								:editable="false"
								:clearable="false"
								input-class="form-control"
								range
								range-separator=" - "
								placeholder="Add dates"
							>
							</date-picker>
							<input
								v-else
								type="text"
								readonly
								class="form-control"
								:value="j.slug === 'townoftregowi.com' ? 'May 1 → Sep 30' : 'Jul 15 → Oct 15'"
							/>
						</div>

						<div v-if="venueGroup.filter1 === 'length'" class="col-sm-6 col-lg">
							<select class="form-select" v-model="filters.filter1">
								<option :value="null">Any dock length</option>
								<option v-for="val in venuesAttributeValues('length')" :key="val" :value="val">{{
									val
								}}</option>
							</select>
						</div>
						<div v-else-if="venueGroup.filter1" class="col-sm-6 col-lg">
							<select class="form-select" v-model="filters.filter1">
								<option :value="null">{{ capitalize(venueGroup.filter1) }}</option>
								<option
									v-for="val in venuesAttributeValues(venueGroup.filter1)"
									:key="val"
									:value="val"
									>{{ val }}</option
								>
							</select>
						</div>

						<div v-if="venueGroup.filter2" class="col-sm-6 col-lg">
							<select class="form-select" v-model="filters.filter2">
								<option :value="null">{{ capitalize(venueGroup.filter2) }}</option>
								<option
									v-for="val in venuesAttributeValues(venueGroup.filter2)"
									:key="val"
									:value="val"
									>{{ val }}</option
								>
							</select>
						</div>

						<div class="col-auto">
							<button class="btn btn-primary">
								<font-awesome-icon :icon="['fas', 'magnifying-glass']" />
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<div v-if="states.search === 'idle'">
			<div class="card mb-3">
				<div class="card-body text-center">
					<h5 class="mb-3">👋 Welcome to {{ venueGroup.name }}</h5>
					<p class="card-text">{{ venueGroup.message_welcome }}</p>
				</div>
			</div>

			<div class="row g-3 row-cols row-cols-2 row-cols-lg-3 row-cols-xl-4">
				<div class="col" v-for="venue in venues" :key="venue.id">
					<venue-card :venue="venue"></venue-card>
				</div>
			</div>
		</div>
		<div v-else class="row">
			<div class="col">
				<div v-if="states.search === 'loading'" class="card">
					<div class="card-body text-center">
						<span class="spinner-border spinner-border-sm"></span> Checking availability..
					</div>
				</div>
				<div v-else-if="states.search === 'error'" class="card">
					<div class="card-body text-center">
						<p class="card-text text-danger-400">⚠️ Error loading availability ({{ availabilityError }})</p>
						<p class="card-text text-danger-400">Please try again later</p>
					</div>
				</div>
				<div v-else-if="states.search === 'loaded' && availability">
					<div
						v-if="Object.values(availability).filter(a => a.available).length"
						class="row g-3 row-cols row-cols-2 row-cols-lg-3"
					>
						<template v-for="venue in venues">
							<div
								v-if="availability[venue.id] && availability[venue.id].available"
								class="col"
								:key="venue.id"
							>
								<venue-card-book
									:venue="venue"
									:availability="availability[venue.id]"
								></venue-card-book>
							</div>
						</template>
					</div>
					<div v-else class="alert alert-warning text-dark">
						ℹ️ There's nothing available for the dates selected.
					</div>
				</div>
				<div v-else class="card">
					<div class="card-body text-center">
						There's nothing available for the dates and filters you selected
					</div>
				</div>
			</div>
			<div v-if="venueGroup.map !== 'hidden'" class="col-lg-5">
				<venue-group-map
					ref="muniMap"
					class="rounded"
					:venueGroup="venueGroup"
					:venues="venues"
					:height="600"
					:mapTypeControl="false"
					:fullscreenControl="false"
				></venue-group-map>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { add, format, startOfDay } from 'date-fns'
import { capitalize } from 'lodash-es'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

import heyGovApi from '@/api.js'

import VenueCard from '@/components/venues/VenueCard.vue'
import VenueCardBook from '@/components/venues/VenueCardBook.vue'
import VenueGroupMap from '@/components/venues/VenueGroupMap.vue'

export default {
	name: 'VenueGroupBook',
	components: { DatePicker, VenueGroupMap, VenueCard, VenueCardBook },
	props: {
		venueGroup: {
			type: Object,
			required: true,
		},
		venues: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			states: {
				search: 'idle',
			},
			filters: {
				dates: [],
				slot_type: this.venueGroup.slot_type === 'both' ? 'multi' : this.venueGroup.slot_type,
				filter1: null,
				filter2: null,
			},
			availability: null,
			availabilityError: '',

			// date picker options
			dateFormatter: {
				stringify(date) {
					const currentYear = new Date().getFullYear()

					if (currentYear === date.getFullYear()) {
						return date.toLocaleDateString(undefined, {
							month: 'short',
							day: 'numeric',
						})
					} else {
						return date.toLocaleDateString()
					}
				},
			},
			disabledDate(date) {
				return date < startOfDay(new Date())
			},
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['currentRole', 'isStaff']),
	},
	created() {
		//this.loadVenues()
	},
	methods: {
		capitalize,

		venuesAttributeValues(attributeValue) {
			const values = []

			this.venues.forEach(venue => {
				venue.attributes.forEach(attr => {
					if (attr.id === attributeValue && !values.includes(attr.value)) {
						values.push(attr.value)
					}
				})
			})

			return values
		},
		checkAvailability() {
			if (this.filters.slot_type === 'dates' || this.filters.dates.length === 2) {
				this.states.search = 'loading'
				this.availability = null
				this.availabilityError = ''

				const params = {
					slot_type: this.filters.slot_type,
					filter1: this.filters.filter1,
					filter2: this.filters.filter2,
				}

				if (this.filters.slot_type === 'multi') {
					params.start = format(this.filters.dates[0], 'yyyy-MM-dd')
					params.end = format(this.filters.dates[1], 'yyyy-MM-dd')

					if (params.start === params.end) {
						params.end = format(add(this.filters.dates[1], { days: 1 }), 'yyyy-MM-dd')
					}
				}

				heyGovApi.post(`${this.j.slug}/venue-groups/${this.venueGroup.slug}/availability`, params).then(
					({ data }) => {
						// on first map load, allow 150ms for map load
						if (!this.availability) {
							setTimeout(() => {
								this.$refs.muniMap.updateAvailability(data)
								this.$refs.muniMap.zoomOnVenues(100)
							}, 150)
						}

						this.availability = data
						this.states.search = 'loaded'

						this.$refs.muniMap.updateAvailability(data)
						this.$refs.muniMap.zoomOnVenues(100)
					},
					error => {
						this.availabilityError = error.message
						this.states.search = 'error'
					}
				)
			} else {
				Vue.toasted.error('Please select the dates you want to book')
			}
		},
	},
}
</script>
