<template>
	<div v-if="!currentField.pdf" :class="currentField.class" :id="'fsf-' + currentField.id">
		<template v-for="item in currentField.options">
			<div
				v-if="!item.removed"
				:key="item.optionValue"
				:class="'form-check ' + inlineClass + ' ' + currentField.class"
			>
				<input
					class="form-check-input"
					type="radio"
					:id="`field-${currentField.id}-value-${item.optionValue}`"
					:required="item.required == 1"
					:value="item.optionValue"
					:disabled="mode === 'preview'"
					v-model="currentField.value.value"
				/>
				<label :for="`field-${currentField.id}-value-${item.optionValue}`" class="form-check-label">{{
					item.optionLabel
				}}</label>
				<input
					v-if="visibleAdditionalValue(item, currentField)"
					type="text"
					class="border rounded px-2 ms-2"
					v-model="currentField.value.extra"
				/>
			</div>
		</template>
	</div>

	<!-- PDF View -->
	<div v-else>
		<div v-if="currentField.display !== 'form'" class="d-inline-flex">
			<label class="pdf-label form-label ms-0 pe-2" for="">{{ currentField.label }}</label>

			<div v-if="currentField.redacted && $route.query.redacted" class="pdf-redacted"></div>
			<div v-else class="pdf-radio d-flex flex-wrap">
				<template v-for="item in currentField.options">
					<div
						:key="item.optionValue"
						:class="'d-flex ms-1 form-check ' + inlineClass + ' ' + currentField.class"
					>
						<div
							class="d-flex align-items-center justify-content-center"
							style="width: 14px; height: 14px; border: 1px solid black"
						>
							<font-awesome-icon
								v-if="item.optionValue === currentField.value.value"
								:icon="['fas', 'check']"
							/>
						</div>

						<label
							:key="item.optionLabel"
							:for="`field-${currentField.id}-value-${item.optionValue}`"
							class="form-check-label ms-1"
							v-if="!item.removed"
							>{{ item.optionLabel }}</label
						>
						<div v-if="visibleAdditionalValue(item, currentField)" class="ms-2">
							<span class="text-decoration-underline"> {{ currentField.value.extra }}</span>
						</div>
					</div>
				</template>
			</div>
		</div>
		<div v-if="currentField.help" class="text-muted pdf-help">{{ currentField.help }}</div>
	</div>
</template>

<script>
export default {
	name: 'RadioButton',
	props: {
		currentField: Object,
		mode: String,
	},
	computed: {
		inlineClass() {
			return this.currentField.data.check_radio_inline ? 'form-check-inline' : ''
		},
	},
	created() {
		if (!this.currentField.value || !(typeof this.currentField.value === 'object')) {
			this.currentField.value = {
				value: '',
				extra: '',
			}
		}
	},
	methods: {
		visibleAdditionalValue(item, currentField) {
			return (
				(item.addAdditionalValue === true || item.optionValue === 'other') &&
				currentField.value.value == item.optionValue
			)
		},
	},
	watch: {
		'currentField.value': {
			deep: true,
			handler() {
				this.$emit('update', { field: this.currentField, value: this.currentField.value })
			},
		},
	},
}
</script>
