var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-tests"},[_c('div',{staticClass:"venue-availability"},[_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"cursor-pointer",class:{ 'month-disabled': _vm.monthPrev.getMonth() < _vm.today.getMonth() },on:{"click":function($event){_vm.setMonth(new Date(_vm.month.getFullYear(), _vm.month.getMonth() - 1, 1))}}},[_vm._v("← "+_vm._s(_vm.months[_vm.monthPrev.getMonth()].slice(0, 3)))])]),_c('div',{staticClass:"col text-center"},[_c('strong',[_vm._v(_vm._s(_vm.months[_vm.month.getMonth()])+" "),(_vm.month.getFullYear() !== _vm.today.getFullYear())?_c('span',[_vm._v(_vm._s(_vm.month.getFullYear()))]):_vm._e()])]),_c('div',{staticClass:"col text-end"},[_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.setMonth(new Date(_vm.month.getFullYear(), _vm.month.getMonth() + 1, 1))}}},[_vm._v(_vm._s(_vm.months[_vm.monthNext.getMonth()].slice(0, 3))+" →")])])]),_vm._m(0),_c('div',{staticClass:"venue-availability-dates mb-2"},[_vm._l((_vm.month.getDay()),function(i){return _c('div',{key:("venue-availability-day-empty-" + i)},[_vm._v("   ")])}),_vm._l((_vm.monthDays),function(day){return _c('div',{key:("venue-availability-day-" + day),staticClass:"venue-availability-date",class:{
					'venue-availability-date-today':
						_vm.getMonthDate(day).toLocaleDateString() === _vm.today.toLocaleDateString(),
					'venue-availability-date-available':
						_vm.availability[_vm.getMonthYMD(day)] && _vm.availability[_vm.getMonthYMD(day)].available,
					'venue-availability-date-selected':
						_vm.getMonthDate(day).toLocaleDateString() === _vm.daySelected.toLocaleDateString(),
				},on:{"click":function($event){_vm.daySelected = _vm.getMonthDate(day)}}},[_vm._v(" "+_vm._s(day)+" ")])})],2),(_vm.availability[_vm.dateYMD(_vm.daySelected)])?_c('div',[_c('h5',[_vm._v("Availability for "+_vm._s(_vm.daySelected.toLocaleDateString()))]),_vm._l((_vm.availability[_vm.dateYMD(_vm.daySelected)].slots),function(slot){return _c('a',{key:("venue-slot-" + (slot.id)),staticClass:"venue-slot",class:slot.available ? 'venue-slot-available' : 'venue-slot-unavailable',attrs:{"href":("https://app.heygov.com/" + _vm.jurisdiction + "/venues/" + _vm.venue + "/booking/" + (_vm.dateYMD(_vm.daySelected)) + "/" + (slot.id)),"target":"heygov"}},[_c('span',[_vm._v(_vm._s(_vm.slotTime(slot.starts_at))+" → "+_vm._s(_vm.slotTime(slot.ends_at)))]),(slot.available)?_c('span',{staticClass:"venue-slot-book-btn"},[_vm._v("Book now "+_vm._s(Number(slot.price).toLocaleString('en-US', { style: 'currency', currency: 'usd', })))]):_c('span',[_vm._v(_vm._s(slot.reason))])])})],2):_c('p',{staticClass:"text-muted"},[_vm._v("There are no available slots for "+_vm._s(_vm.daySelected.toLocaleDateString()))])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"venue-availability-days"},[_c('div',[_vm._v("Sun")]),_c('div',[_vm._v("Mon")]),_c('div',[_vm._v("Tue")]),_c('div',[_vm._v("Wed")]),_c('div',[_vm._v("Thu")]),_c('div',[_vm._v("Fri")]),_c('div',[_vm._v("Sat")])])}]

export { render, staticRenderFns }