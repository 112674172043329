<template>
	<div v-if="mode === 'preview'" class="field-file-upload-preview">
		<div v-if="currentField.value && currentField.value.length" class="row">
			<div v-for="path in currentField.value" :key="`field-${currentField.id}-${path}`" class="col-auto">
				<a :href="signedUrls[path] || path" target="_blank">
					<img
						v-if="urlIsImage(path)"
						:src="signedUrls[path] || path"
						alt="Uploaded file"
						class="img-thumbnail rounded-1"
						width="100"
					/>
					<span
						v-else-if="urlIsDocument(path)"
						class="d-block bg-info-lighter border rounded-1 py-4 text-center"
					>
						<p class="mb-0">
							<font-awesome-icon :icon="['fas', 'file-pdf']" class="fs-3" />
						</p>
						<small>{{ lastPath(path) }}</small>
					</span>
					<video
						controls
						v-else-if="urlIsVideo(path)"
						class="w-100 bg-info-lighter border border-light rounded-1"
					>
						<source v-if="path.endsWith('.mp4')" :src="signedUrls[path] || path" type="video/mp4" />
						<source v-else-if="path.endsWith('.webm')" :src="signedUrls[path] || path" type="video/webm" />
						{{ lastPath(path) }}
					</video>
					<span v-else class="d-block bg-info-lighter border rounded-1 py-4 text-center">
						<p class="mb-0">
							<font-awesome-icon :icon="['fas', 'file']" class="fs-3" />
						</p>
						<small>{{ lastPath(path) }}</small>
					</span>
				</a>
			</div>
		</div>
		<i v-else class="text-muted">No files or images</i>
	</div>

	<div v-else-if="!currentField.pdf">
		<div class="row row-cols-2 row-cols-md-3 row-cols-lg-4">
			<div v-for="(path, index) in currentField.value" :key="index" class="col position-relative">
				<a
					class="btn btn-close bg-danger-lighter position-absolute"
					@click="removeFile(index)"
					style="right: 3px; top: -3px;"
				></a>

				<img
					v-if="urlIsImage(path)"
					:src="signedUrls[path] || path"
					alt="Uploaded file"
					class="img-thumbnail"
				/>
				<div v-else-if="urlIsDocument(path)" class="bg-light border rounded-1 p-3 text-center">
					<p class="mt-2 mb-1"><font-awesome-icon :icon="['fas', 'file-pdf']" /></p>
					<p class="mb-0">{{ lastPath(path) }}</p>
				</div>
				<div v-else-if="urlIsVideo(path)" class="bg-light border rounded-1 p-3 text-center">
					<p class="mt-2 mb-1"><font-awesome-icon :icon="['fas', 'video']" /></p>
					<p class="mb-0">{{ lastPath(path) }}</p>
				</div>
				<div v-else class="bg-light border rounded-1 p-3 text-center">
					<p class="mt-2 mb-1"><font-awesome-icon :icon="['fas', 'file']" /></p>
					<p class="mb-0">{{ lastPath(path) }}</p>
				</div>
			</div>
			<div v-if="states.fileUploading" class="col">
				<div
					class="card rounded-1 bg-light border justify-content-center align-items-center"
					style="aspect-ratio: 16/9"
				>
					<div class="spinner-border my-2" role="status"></div>
					{{ truncateString(uploadedFileName, 15) }}
				</div>
			</div>
			<div v-if="currentField.data.multiple || !currentField.value.length" class="col">
				<label
					class="d-block bg-info-lighter p-3 border border-dashed rounded-1 text-center cursor-pointer"
					style="aspect-ratio: 16/9"
				>
					<span>
						<p class="mt-2 mb-1">
							<font-awesome-icon :icon="['fas', fileType === 'document' ? 'file-pdf' : fileType]" />
						</p>
						<span class="">Upload {{ fileType }}</span>

						<input
							:multiple="currentField.data.multiple"
							type="file"
							:accept="currentField.data.accept_type"
							:id="'fsf-' + currentField.id"
							class="d-none"
							@change="uploadFile($event)"
						/>
					</span>
				</label>
			</div>
		</div>
	</div>

	<!-- PDF preview -->
	<div v-else-if="currentField.display !== 'form'">
		<div v-if="currentField.redacted === 1 && $route.query.redacted && currentField.display !== 'form'">
			<div class="pdf-redacted"></div>
			<div class="pdf-redacted"></div>
			<div class="pdf-redacted"></div>
		</div>
		<div v-else-if="!currentField.value.length">
			<div class="pdf-field"></div>
			<div class="pdf-field"></div>
			<div class="pdf-field"></div>
		</div>
		<div v-else class="row row-cols-3 gx-2">
			<div v-for="file in currentField.value" :key="`file-${file}`" class="col mb-2">
				<img
					v-if="urlIsImage(file) && signedUrls[file]"
					:src="signedUrls[file]"
					alt="Uploaded file"
					class="rounded-1 bg-white img-thumbnail"
				/>
				<span v-else-if="urlIsDocument(file)" class="d-block bg-white border text-center rounded-1 py-5">
					<p class="mb-2"><font-awesome-icon :icon="['fas', 'file-pdf']" class="fs-1" /></p>
					<p class="mb-0">{{ lastPath(file, 55) }}</p>
				</span>
				<span v-else-if="urlIsVideo(file)" class="d-block bg-white border text-center rounded-1 py-5">
					<p class="mb-2"><font-awesome-icon :icon="['fas', 'video']" class="fs-1" /></p>
					<p class="mb-0">{{ lastPath(file, 55) }}</p>
				</span>
				<span v-else class="d-block bg-white border text-center rounded-1 py-5">
					<p class="mb-2"><font-awesome-icon :icon="['fas', 'file']" class="fs-1" /></p>
					<p class="mb-0">{{ lastPath(file, 55) }}</p>
				</span>
			</div>
		</div>

		<p v-if="currentField.value.length && (formRequest || venueBooking)" class="mb-1">
			<a :href="`https://heygov.co/${j.slug}/${formRequest.uuid}`" target="_blank" class="text-muted"
				>Visit
				{{ `heygov.co/${j.slug}/${formRequest.uuid}` }}
				to view or download full files.
			</a>
		</p>
		<small v-if="currentField.help" class="text-muted pdf-help">{{ currentField.help }}</small>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import heyGovApi, { handleResponseError } from '@/api.js'
import { urlIsImage, urlIsDocument, urlIsVideo, truncateString } from '@/utils.js'

export default {
	name: 'FileUpload',
	props: {
		currentField: {
			type: Object,
			required: true,
		},
		formRequest: Object,
		venueBooking: Object,
		mode: {
			type: String,
			default: 'edit',
		},
	},
	data() {
		return {
			states: {
				fileUploading: false,
			},
			uploadedFileName: '',
			fileApi: null,
			signedUrls: {},
		}
	},
	computed: {
		...mapState(['j']),
		fileType() {
			let type = 'file'

			if (this.currentField.data.accept_type.startsWith('image/')) {
				type = 'image'
			} else if (
				this.currentField.data.accept_type.includes('pdf') ||
				this.currentField.data.accept_type.includes('doc') ||
				this.currentField.data.accept_type.includes('xls') ||
				this.currentField.data.accept_type.includes('csv')
			) {
				type = 'document'
			} else if (
				this.currentField.data.accept_type.startsWith('video/') ||
				this.currentField.data.accept_type.includes('mp4') ||
				this.currentField.data.accept_type.includes('mov') ||
				this.currentField.data.accept_type.includes('m4v')
			) {
				type = 'video'
			}

			return type
		},
	},
	created() {
		if (!Array.isArray(this.currentField.value)) {
			this.currentField.value = JSON.parse(this.currentField.value || '[]')
		}
	},
	mounted() {
		// sometimes the formRequest or VenueBooking is not available on created
		// so mount is used instead to set the fileApi
		this.setFileApi()

		// sign urls for preview
		this.signFileUrls()
	},
	methods: {
		urlIsImage,
		urlIsDocument,
		urlIsVideo,
		truncateString,

		setFileApi() {
			if (!this.fileApi && this.formRequest) {
				this.fileApi = `/${this.j.slug}/form-requests/${this.formRequest.id}/file`
			} else if (!this.fileApi && this.venueBooking?.pid) {
				this.fileApi = `/${this.j.slug}/venue-bookings/${this.venueBooking.pid}/file`
			}
		},

		uploadFile($event) {
			this.setFileApi()

			if (!this.fileApi) {
				Vue.toasted.error('File uploading works only when submitting a form or booking a venue')
				return false
			}

			$event.target.files.forEach(file => {
				if (file.size / 1024 / 1024 > 50) {
					Vue.toasted.error("Sorry, we can't upload files larger than 50MB")
				} else {
					this.states.fileUploading = true
					this.uploadedFileName = file.name

					// prepare file data
					var formFile = new FormData()
					formFile.append('file', file)

					// upload the file
					heyGovApi.post(this.fileApi, formFile).then(
						({ data }) => {
							if (data.path) {
								this.signedUrls[data.path] = data.url
								this.currentField.value.push(data.path)
								this.states.fileUploading = false
							}
						},
						error => {
							Vue.toasted.error(`Error uploading file (${error.message})`)
							this.states.fileUploading = false
						}
					)
				}
			})
		},
		removeFile(index) {
			if (confirm(`Are you sure you want to remove this ${this.fileType}?`)) {
				// TODO: Remove image from files on google storage
				this.currentField.value.splice(index, 1)
			}
		},

		signFileUrls() {
			this.currentField.value.forEach(path => {
				if (!this.signedUrls[path] && this.fileApi) {
					heyGovApi.post(`${this.fileApi}-sign-url`, { path }).then(({ data }) => {
						if (data.path) {
							this.$set(this.signedUrls, data.path, data.url)
						}
					}, handleResponseError(`Error signing file url ({error})`))
				}
			})
		},

		lastPath(url, textLength = 20) {
			return truncateString(url.split('/').pop(), textLength)
		},
	},
	watch: {
		'currentField.value'() {
			this.signFileUrls()
		},
	},
}
</script>
