<template>
	<div class="page-tests">
		<div v-cloak class="venue-availability">
			<div class="row mb-3">
				<div class="col">
					<span
						class="cursor-pointer"
						:class="{ 'month-disabled': monthPrev.getMonth() < today.getMonth() }"
						@click="setMonth(new Date(month.getFullYear(), month.getMonth() - 1, 1))"
						>← {{ months[monthPrev.getMonth()].slice(0, 3) }}</span
					>
				</div>
				<div class="col text-center">
					<strong
						>{{ months[month.getMonth()] }}
						<span v-if="month.getFullYear() !== today.getFullYear()">{{
							month.getFullYear()
						}}</span></strong
					>
				</div>
				<div class="col text-end">
					<span
						class="cursor-pointer"
						@click="setMonth(new Date(month.getFullYear(), month.getMonth() + 1, 1))"
						>{{ months[monthNext.getMonth()].slice(0, 3) }} →</span
					>
				</div>
			</div>

			<div class="venue-availability-days">
				<div>Sun</div>
				<div>Mon</div>
				<div>Tue</div>
				<div>Wed</div>
				<div>Thu</div>
				<div>Fri</div>
				<div>Sat</div>
			</div>

			<div class="venue-availability-dates mb-2">
				<div v-for="i in month.getDay()" :key="`venue-availability-day-empty-${i}`">
					&nbsp;
				</div>

				<div
					v-for="day in monthDays"
					:key="`venue-availability-day-${day}`"
					class="venue-availability-date"
					:class="{
						'venue-availability-date-today':
							getMonthDate(day).toLocaleDateString() === today.toLocaleDateString(),
						'venue-availability-date-available':
							availability[getMonthYMD(day)] && availability[getMonthYMD(day)].available,
						'venue-availability-date-selected':
							getMonthDate(day).toLocaleDateString() === daySelected.toLocaleDateString(),
					}"
					@click="daySelected = getMonthDate(day)"
				>
					{{ day }}
				</div>
			</div>

			<div v-if="availability[dateYMD(daySelected)]">
				<h5>Availability for {{ daySelected.toLocaleDateString() }}</h5>

				<a
					v-for="slot in availability[dateYMD(daySelected)].slots"
					:key="`venue-slot-${slot.id}`"
					:href="
						`https://app.heygov.com/${jurisdiction}/venues/${venue}/booking/${dateYMD(daySelected)}/${
							slot.id
						}`
					"
					target="heygov"
					class="venue-slot"
					:class="slot.available ? 'venue-slot-available' : 'venue-slot-unavailable'"
				>
					<span>{{ slotTime(slot.starts_at) }} → {{ slotTime(slot.ends_at) }}</span>
					<span v-if="slot.available" class="venue-slot-book-btn"
						>Book now
						{{
							Number(slot.price).toLocaleString('en-US', {
								style: 'currency',
								currency: 'usd',
							})
						}}</span
					>
					<span v-else>{{ slot.reason }}</span>
				</a>
			</div>
			<p v-else class="text-muted">There are no available slots for {{ daySelected.toLocaleDateString() }}</p>
		</div>
	</div>
</template>

<style scoped>
.venue-availability {
	background: #f9f9f9;
	border-radius: 12px;
	padding: 10px 10px 6px;
	width: calc(420px + 20px);
	margin: 24px auto;
}

.venue-availability .month-disabled {
	color: #999;
	pointer-events: none;
}

.venue-availability-days,
.venue-availability-dates {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
}

.venue-availability-days > * {
	font-size: 14px;
	color: #666;
	text-align: center;
}

.venue-availability-dates .venue-availability-date {
	display: block;
	cursor: not-allowed;
	line-height: 60px;
	font-size: 16px;
	border-radius: 2rem;
	text-align: center;
	color: #898c90;
	text-decoration: line-through;
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.venue-availability-dates .venue-availability-date-today {
	background-color: #ecf2ff;
}

.venue-availability-dates .venue-availability-date-available {
	font-weight: bold;
	color: #2a9836;
	cursor: pointer;
	text-decoration: none;
}

.venue-availability-dates .venue-availability-date-available:hover {
	background-color: rgba(0, 0, 0, 0.06);
}

.venue-availability-dates .venue-availability-date-selected {
	font-weight: bold;
	color: #fff;
	background-color: #5e81f4;
}

.venue-availability-dates .venue-availability-date-selected:hover {
	background-color: #5e81f4;
	color: #ecf2ff;
}

.venue-slot {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #e4f7e6;
	border-radius: 12px;
	padding: 15px;
	margin-bottom: 15px;
	color: #222;
	transition: transform ease-in-out 0.2s;
}

.venue-slot:hover {
	text-decoration: none;
	transform: scale(1.02);
}

.venue-slot-book-btn {
	display: inline-block;
	background-color: #5e81f4;
	color: #fff;
	padding: 5px 10px;
	border-radius: 12px;
	font-size: 14px;
}

.venue-slot-unavailable {
	pointer-events: none;
	background-color: #d6d6d6;
	color: #666;
}
</style>

<script>
export default {
	name: 'PageTests',
	data() {
		return {
			jurisdiction: 'heyville.org',
			venue: 'heygov-hq',
			months: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December',
			],
			today: new Date(),
			daySelected: new Date(),
			month: null,
			monthDays: 0,
			monthPrev: null,
			monthNext: null,

			availabilityLoaded: [],
			availability: {},
		}
	},
	created() {
		this.loadAvailability(`${this.today.getFullYear()}-${String(this.today.getMonth() + 1).padStart(2, '0')}`).then(
			() => {
				this.daySelected = this.today
			}
		)
		this.setMonth(new Date(this.today.getFullYear(), this.today.getMonth(), 1))
	},
	methods: {
		setMonth(date) {
			this.month = date
			this.monthDays = new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
			this.monthPrev = new Date(date.getFullYear(), date.getMonth() - 1, 1)
			this.monthNext = new Date(date.getFullYear(), date.getMonth() + 1, 1)

			// load availability for next month
			this.loadAvailability(
				`${this.monthNext.getFullYear()}-${String(this.monthNext.getMonth() + 1).padStart(2, '0')}`
			)
		},
		dateYMD(date) {
			const month = String(date.getMonth() + 1).padStart(2, '0')
			const day = String(date.getDate()).padStart(2, '0')

			return `${date.getFullYear()}-${month}-${day}`
		},
		getMonthDate(dayNumber) {
			return new Date(this.month.getFullYear(), this.month.getMonth(), dayNumber)
		},
		getMonthYMD(dayNumber) {
			return this.dateYMD(this.getMonthDate(dayNumber))
		},
		slotTime(time) {
			time = time.split(':')
			const datetime = new Date(
				this.daySelected.getFullYear(),
				this.daySelected.getMonth(),
				this.daySelected.getDate(),
				time[0],
				time[1],
				time[2]
			)
			return datetime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
		},
		loadAvailability(forDate) {
			return new Promise((resolve, reject) => {
				if (this.availabilityLoaded.includes(forDate)) {
					resolve(this.availability)
				} else {
					fetch(`https://api.heygov.com/${this.jurisdiction}/venues/${this.venue}/availability/${forDate}`)
						.then(response => response.json())
						.then(data => {
							this.availability = {
								...this.availability,
								...data.days,
							}
							resolve(this.availability)
						})
						.catch(error => {
							alert(`Couldn't load venue availability for ${forDate} (${error})`)
							reject(error)
						})

					this.availabilityLoaded.push(forDate)
				}
			})
		},
	},
}
</script>
