<template>
	<router-link :to="`/${j.slug}/venues/${venue.slug}`" class="card card-link card-venue rounded-1 p-2 text-dark">
		<div class="card-image">
			<img
				:src="venue.main_image || '/images/placeholder.jpg'"
				class="card-img-top rounded-1"
				:alt="venue.name"
			/>
		</div>

		<div class="card-body px-0 py-2">
			<h5 class="card-title my-0">
				{{ venue.name }}
				<span
					v-if="isStaff"
					class="badge text-capitalize me-1"
					:class="
						venue.reservation_mode === 'slots'
							? 'bg-primary-50 text-primary-400'
							: 'bg-warning-50 text-warning-400'
					"
					>{{ venue.reservation_mode }}</span
				>
				<span v-if="venue.status !== 'public'" class="badge" :class="venueStatuses[venue.status].class">{{
					venueStatuses[venue.status].name
				}}</span>
			</h5>

			<div v-if="venue.attributes.length" class="row row-cols row-cols-2 mt-1">
				<div v-for="attr in venue.attributes" :key="attr.id" class="mt-1">
					{{ attr.label }}: <strong>{{ attr.value }}</strong>
				</div>
			</div>
		</div>

		<div v-if="prices.length" class="card-footer px-0 pb-0 pt-2">
			<small class="text-neutral-400 me-1">From</small> <strong>{{ Math.min(...prices) | currency }}</strong>
		</div>
	</router-link>
</template>

<style scoped lang="scss">
.card-img-top {
	aspect-ratio: 3 / 2;
	object-fit: cover;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import { venueStatuses } from '@/actions/venues.js'

export default {
	name: 'VenueCard',
	props: {
		venue: Object,
	},
	data() {
		return {
			venueStatuses,
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['currentRole', 'isStaff']),
		prices() {
			let prices = []

			if (this.venue.reservation_mode === 'slots') {
				prices = this.venue.slots.filter(s => !s.deleted_at).map(s => s.price)
			} else if (this.venue.reservation_mode === 'time') {
				for (const day in this.venue.availability.days) {
					if (this.venue.availability.days[day].enabled) {
						prices.push(Number(this.venue.availability.days[day].price || 0))
					}
				}
			}

			return prices
		},
	},
}
</script>
