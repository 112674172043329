<template>
	<div class="forms-page bg-white p-3 rounded-1 mb-3">
		<div v-if="form">
			<nav class="nav-breadcrumbs text-muted mb-3">
				<router-link :to="`/${j.slug}/forms`" class="text-muted">{{
					currentRole === 'CITIZEN' ? 'Licenses & Permits' : 'HeyLicense'
				}}</router-link>
				<span class="mx-1">/</span>
				<template v-if="formRequest">
					<router-link :to="`/${j.slug}/forms/${form.slug}`" class="text-muted">{{ form.name }}</router-link>
					<span class="mx-1">/</span>
					<strong class="text-primary-200"
						>Application {{ form.application_prefix
						}}{{ (form.application_prefix || '').endsWith('-') ? '' : '-' }}DRAFT-{{
							formRequest.created_at | dateLocal
						}}</strong
					>
				</template>
				<strong v-else class="text-primary-200">{{ form.name }}</strong>
			</nav>

			<!--
			<pre>{{ formRequest.answers[currentPaymentField.id] }}</pre>
			<pre>{{ currentPaymentField }}</pre>
			<pre>{{ formRequest }}</pre>
			-->

			<div v-if="states.authRequired" class="row justify-content-center">
				<div class="col-md-10 col-lg-8">
					<div class="card">
						<div class="card-body text-center">
							<p class="card-text">✋ You need to be logged in to resume this application.</p>
							<p class="card-text">
								<router-link
									:to="`/account/login?redirect=${encodeURIComponent($route.fullPath)}`"
									class="btn btn-sm btn-primary mx-2"
									>Log in</router-link
								>
								<router-link :to="`/${j.slug}/forms`" class="btn btn-sm btn-outline-primary mx-2"
									>Start new application</router-link
								>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div v-else-if="form.steps.length && formRequestLoaded">
				<div v-if="currentStep === 'auth'" class="row justify-content-center">
					<div class="col-md-8 col-lg-6">
						<div class="card">
							<div class="card-body">
								<h3 class="mb-3 text-center">Start {{ form.type }} application</h3>
								<p class="mb-3 text-center">First, what's your email address?</p>

								<login-form
									:redirectUrl="jLink(`forms/${this.form.slug}/send/step-0`)"
									:allowRegistration="true"
									loginMessage="Great, logged in to your account"
									registerMessage="Now you have a HeyGov account 🙌"
									@auth="createFormRequest"
								></login-form>
							</div>
						</div>
					</div>
				</div>
				<div v-else-if="currentStep === 'start'" class="row justify-content-center">
					<div class="col-md-8 col-lg-6">
						<div class="card mb-3">
							<div class="card-body">
								<p>
									<span class="float-end">{{ Math.ceil(formFields.length / 3) }} minutes</span>
									<span class="text-muted"
										><font-awesome-icon :icon="['fas', 'clock']" class="fa-fw me-1" /> Time to
										complete</span
									>
								</p>
								<!--
							<p class="mb-2">
								<font-awesome-icon :icon="['fas', 'clock']" class="fa-fw me-1" /> Average time to review: 1
								business day
							</p>
							-->

								<p v-if="form.price && currentPaymentField">
									<span class="float-end">{{ form.price }}</span>
									<span class="text-muted"
										><font-awesome-icon :icon="['fas', 'money-bill']" class="fa-fw me-1" />
										{{ currentPaymentField.label }}</span
									>
								</p>

								<p class="">{{ form.description }}</p>

								<hr class="bg-gray" />

								<p class="mb-2 text-muted">
									<font-awesome-icon :icon="['fas', 'list-ol']" class="fa-fw me-1" /> Sections to
									fill:
								</p>

								<ol class="timeline-steps mb-4">
									<li v-for="step in form.steps" :key="`fs-${step.id}`">
										{{ step.name }}
									</li>
								</ol>

								<div class="d-grid">
									<button
										v-for="draft in formRequestDrafts"
										:key="`btn-draft-${draft.id}`"
										class="btn btn-outline-primary mb-3"
										@click="formStart(draft)"
									>
										Resume application {{ draft.id }} from
										{{ draft.created_at | dateLocal }}
									</button>

									<button id="form-btn-start" class="btn btn-primary" @click="formStart(false)">
										<span v-if="formRequest">Continue application</span>
										<span v-else-if="form.textAction"
											>{{ form.textAction }}
											<span v-if="formRequestDrafts.length">(new)</span></span
										>
										<span v-else-if="formRequestDrafts.length">Start new application</span>
										<span v-else>Start application</span>
									</button>
								</div>
							</div>
						</div>

						<div
							v-if="
								!['heyville.org', 'sheboyganwi.gov', 'ci.superior.wi.us'].includes(j.slug) &&
									form.slug !== 'transfer-station-permit'
							"
							class="card"
						>
							<div class="card-body">
								<p class="mb-0">
									In case you want to submit it manually,
									<a :href="`${apiUrl + j.slug}/forms/${form.slug}/pdf`" target="_blank"
										>download the PDF<font-awesome-icon :icon="['fas', 'file-pdf']" class="ms-1"
									/></a>
									and print it out.
								</p>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="row row-form-content gx-5">
					<div class="col-12 col-lg-5 col-xl-4">
						<div class="section-steps d-none d-lg-block mb-3">
							<div
								v-for="(step, index) in form.steps"
								:key="`form-review-step-control-${step.id}`"
								class="form-review-step cursor-pointer rounded-1 mb-3 p-3"
								:class="{ 'form-review-step-selected': `step-${index}` === currentStep }"
								@click="formToStep(`step-${index}`, step)"
							>
								<div class="row gx-3">
									<div class="col-auto text-muted text-end">
										<p class="mb-0">{{ String(index + 1).padStart(2, '0') }}</p>
									</div>
									<div class="col">
										<small
											v-if="`step-${index}` === currentStep"
											class="text-warning-300 float-end mb-1 ms-2"
										>
											<span class="icon-circle"></span>
											In Progress
										</small>
										<small
											v-else-if="!formRequest || !formRequest.steps[step.id]"
											class="text-neutral-300 float-end mb-1 ms-2"
										>
											<span class="icon-circle"></span> Not Started
										</small>
										<small
											v-else
											:class="`text-${stepStatuses[formRequest.steps[step.id].status].color}`"
											class="float-end mb-1 ms-2"
										>
											<span class="icon-circle"></span>
											{{ stepStatuses[formRequest.steps[step.id].status].name }}
										</small>

										<p class="mb-0 text-neutral-700">{{ step.name }}</p>
										<p v-if="step.description" class="text-muted mt-2 mb-0">
											{{ step.description }}
										</p>
									</div>
								</div>
							</div>

							<div
								class="form-review-step cursor-pointer rounded-1 mb-3 p-3"
								:class="{ 'form-review-step-selected': `review` === currentStep }"
								@click="formToStep('review')"
							>
								<div class="row gx-3">
									<div class="col-auto text-muted text-end">
										<p class="mb-0">{{ String(form.steps.length + 1).padStart(2, '0') }}</p>
									</div>
									<div class="col">
										<p class="mb-0">Review &amp; Send</p>
									</div>
								</div>
							</div>
						</div>

						<div class="dropdown d-grid d-lg-none mb-4">
							<button
								class="btn bg-neutral-100 rounded-1 px-2 dropdown-toggle"
								type="button"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<span v-if="currentStep === 'review'">Review and Submit</span>
								<span v-else>Step {{ formStepIndex + 1 }} — {{ formStepData.name }}</span>
							</button>

							<ul class="dropdown-menu w-100">
								<li
									v-for="(step, index) in form.steps"
									:key="`form-review-step-dropdown-${step.id}`"
									@click="formToStep(`step-${index}`, step)"
								>
									<span class="dropdown-item py-3"
										>{{ index + 1 }} - {{ truncateString(step.name, 28) }}
										<small v-if="`step-${index}` === currentStep" class="text-warning-300 ms-1">
											<span class="icon-circle"></span>
										</small>
										<small
											v-else-if="!formRequest || !formRequest.steps[step.id]"
											class="text-neutral-300 ms-1"
										>
											<span class="icon-circle"></span>
										</small>
										<small
											v-else
											:class="`text-${stepStatuses[formRequest.steps[step.id].status].color}`"
											class="ms-1"
										>
											<span class="icon-circle"></span>
										</small>
									</span>
								</li>
								<li @click="formToStep('review')">
									<span class="dropdown-item py-3"
										>{{ form.steps.length + 1 }} - Review and Submit</span
									>
								</li>
							</ul>
						</div>
					</div>
					<div class="col col-form-fields">
						<div v-if="currentStep.startsWith('step-') && formStepData">
							<form @submit.prevent="formNext">
								<div v-for="field in formStepData.fields" :key="`fsf-${field.id}`">
									<div v-if="elementIsVisible(field, formFields)">
										<div
											v-if="['TextElement'].includes(field.type)"
											class="element"
											:class="[field.class, `element-${field.type}`]"
											v-html="textFillVariables(field.value)"
										></div>

										<div
											v-else-if="
												[
													'TextInputElement',
													'EmailInputElement',
													'PhoneInputElement',
													'DatePickerElement',
													'TimeElement',
													'RadioButtonElement',
													'SelectListElement',
													'TextareaInputElement',
													'NameInputElement',
													'YearElement',
													'NumberInputElement',
													'AddressInputElement',
													'CheckboxElement',
													'TableElement',
													'FileUploadElement',
													'SignatureElement',
												].includes(field.type)
											"
											class="form-group mb-3"
										>
											<label :for="`fsf-${field.id}`" class="form-label"
												>{{ field.label }}
												<strong v-if="field.required" class="text-danger">*</strong></label
											>

											<component
												:is="field.type"
												:currentField="field"
												:formRequest="formRequest"
												class="element"
											></component>

											<div v-if="field.help" class="form-text">{{ field.help }}</div>
										</div>

										<div v-else-if="field.type === 'PaymentElement'" class="form-group mb-3">
											<label :for="`fsf-${field.id}`" class="form-label"
												>{{ field.label }}
												<strong v-if="field.required" class="text-danger">*</strong></label
											>

											<div class="bg-light p-3 rounded-1">
												<p class="mb-2">
													<span class="text-muted">Subtotal: </span>
													<strong>{{ field.value | currency }}</strong>
												</p>

												<p v-if="!field.value" class="mb-0 text-warning-300">
													Please fill the previous steps to see the final price.
												</p>
												<p
													v-else-if="
														field.data.paymentType.includes('online') &&
															field.data.paymentType.includes('offline')
													"
													class="mb-0"
												>
													You can choose to pay by credit card or in person on the Review
													page.
												</p>
												<p v-else-if="field.data.paymentType.includes('online')" class="mb-0">
													Credit card payment is required on Review page.
												</p>
												<p v-else-if="field.data.paymentType.includes('offline')" class="mb-0">
													This amount can be paid online, mailed or in person at
													<strong v-if="getDept(field.data.paymentDepartment, 'location')">
														{{
															getDept(field.data.paymentDepartment, 'location').name
														}} </strong
													><strong v-else
														>{{ j.name }}
														{{ getDept(field.data.paymentDepartment, 'name') }}</strong
													><span v-if="getDept(field.data.paymentDepartment, 'location')"
														>,
														{{
															getDept(field.data.paymentDepartment, 'location').address
														}}</span
													>.
												</p>
											</div>

											<div v-if="field.help" class="form-text">{{ field.help }}</div>
										</div>

										<pre v-else>{{ field }}</pre>
									</div>
								</div>

								<div class="row justify-content-between align-items-center mt-5">
									<div class="col-auto">
										<button class="btn text-neutral-300" type="button" @click.prevent="formPrev">
											Back
										</button>
									</div>
									<div class="col-auto">
										<button
											v-if="formStepData.fields.filter(f => f.required).length"
											class="btn text-primary me-2"
											@click.prevent="formNext($event, true)"
										>
											Skip
										</button>
										<button class="btn btn-primary" :disabled="state === 'loading'">
											<span
												v-if="state === 'loading'"
												class="spinner-border spinner-border-sm"
											></span>
											Continue
										</button>
									</div>
								</div>
							</form>
						</div>

						<div v-else-if="currentStep === 'review'">
							<div
								v-for="(step, index) in form.steps"
								:key="`form-review-step-${step.id}`"
								class="form-review-step"
							>
								<h5 @click="formToStep(`step-${index}`, step)" class="cursor-pointer mb-4">
									{{ String(index + 1).padStart(2, '0') }} — {{ step.name }}
								</h5>

								<div
									v-for="field in step.fields.filter(f => f.type !== 'TextElement')"
									:key="`form-review-step-field-${field.id}`"
									class="row row-form-review-field"
								>
									<template v-if="field.type === 'PaymentElement'">
										<div class="col mb-3">
											<div class="bg-light p-3 rounded-1 mt-3">
												<p class="mb-2">
													<span class="text-neutral-500">{{ field.label }}: </span>
													<strong>{{ field.value | currency }}</strong>
												</p>

												<div v-if="states.stepsCompleted">
													<div v-if="field.data.paymentType.length > 1" class="mb-2">
														<span class="text-neutral-500 me-2">Payment method: </span>
														<div
															v-for="paymentType in field.data.paymentType"
															:key="`form-pay-${paymentType}`"
															class="form-check form-check-inline"
														>
															<input
																class="form-check-input"
																type="radio"
																:id="`form-pay-${paymentType}`"
																v-model="paymentMethod"
																:value="paymentType"
															/>
															<label
																class="form-check-label"
																:for="`form-pay-${paymentType}`"
															>
																{{
																	paymentType === 'offline'
																		? 'In person'
																		: paymentType
																}}
															</label>
														</div>
													</div>

													<div v-show="paymentMethod === 'online'">
														<p class="mb-2">
															<span class="text-neutral-500">Online payment fee: </span>

															<strong>{{
																formRequest.answers[field.id].fee | currency
															}}</strong>
															<small
																class="text-muted cursor-pointer ms-2"
																@click="states.feeInfo = !states.feeInfo"
																><span
																	v-if="
																		field.data.paymentFlow === 'automatic' &&
																			extraDisclaimers.includes(j.slug)
																	"
																	class="me-1"
																	>non-refundable</span
																>
																<font-awesome-icon :icon="['fas', 'info-circle']"
															/></small>
														</p>
														<p v-if="states.feeInfo" class="mb-2 text-muted">
															{{
																field.data.paymentFlow === 'manual'
																	? `If the ${form.type} is approved, the payment fee becomes non-refundable.`
																	: 'Payment fees are non-refundable.'
															}}
															This fee is paid directly to the payment processor. Please
															refer to our Terms and Conditions for more info.
														</p>
														<p class="lead mb-0">
															<span class="text-neutral-500">Total: </span>
															<strong>{{
																(field.value + formRequest.answers[field.id].fee)
																	| currency
															}}</strong>
															<small v-if="!field.value" class="text-warning-300 ms-1"
																>(Add answers in previous sections to calculate the
																price)</small
															>
														</p>

														<div
															v-if="
																departments.find(
																	d => d.id === field.data.paymentDepartment
																).paygov_account
															"
														>
															<!-- paygov payment -->
														</div>
														<div
															v-else-if="
																departments.find(
																	d => d.id === field.data.paymentDepartment
																).stripe_account
															"
															id="payment-element"
															class="payment-element-stripe mt-3"
														>
															<!--Stripe.js injects the Payment Element-->
														</div>
														<div
															v-else-if="
																departments.find(
																	d => d.id === field.data.paymentDepartment
																).bridgepay_account
															"
															class="mt-3"
														>
															<div
																id="payment-element"
																class="payment-element-bridgepay"
															></div>
															<div
																id="payment-element-error-bridgepay"
																class="alert alert-danger mt-3 mb-0"
																style="display: none;"
															></div>
														</div>
														<div v-else>
															[invalid payment process]
														</div>

														<div v-if="paymentError" class="alert alert-danger mt-3 mb-0">
															{{ paymentError }}
														</div>
													</div>
													<div v-show="paymentMethod === 'offline'">
														<p class="mb-1 text-neutral-700">
															Please click <strong>Submit application</strong>, then pay
															this amount online, mailed or in person at
															<strong
																v-if="getDept(field.data.paymentDepartment, 'location')"
															>
																{{
																	getDept(field.data.paymentDepartment, 'location')
																		.name
																}} </strong
															><strong v-else
																>{{ j.name }}
																{{
																	getDept(field.data.paymentDepartment, 'name')
																}}</strong
															><span
																v-if="getDept(field.data.paymentDepartment, 'location')"
																>,
																{{
																	getDept(field.data.paymentDepartment, 'location')
																		.address
																}}</span
															>.
														</p>
														<ul class="nicer-list mb-0">
															<li>
																Check or cash:
																<strong>{{ field.value | currency }}</strong>
															</li>
															<li>
																Card:
																<strong>{{
																	(field.value +
																		paymentFee(
																			'stripe',
																			'card_present',
																			field.value
																		))
																		| currency
																}}</strong>
															</li>
														</ul>
													</div>
												</div>
												<div v-else class="text-warning-300">
													Please fill all required fields to calculate price and pay.
												</div>
											</div>
										</div>
									</template>
									<template v-else-if="elementIsVisible(field, formFields)">
										<div class="col text-muted mb-2">
											{{ field.label }}
											<small v-if="field.required" class="text-danger">*</small>
										</div>
										<div class="col-auto mb-3">
											<signature-element
												v-if="field.type === 'SignatureElement'"
												:currentField="{
													...field,
													value: formRequest.answers[field.id],
												}"
												mode="preview"
											></signature-element>
											<file-upload-element
												v-else-if="field.type === 'FileUploadElement'"
												:currentField="{
													...field,
													value: formRequest.answers[field.id],
												}"
												:formRequest="formRequest"
												mode="preview"
											></file-upload-element>
											<table-element
												v-else-if="field.type === 'TableElement'"
												:currentField="{
													...field,
													value: formRequest.answers[field.id],
												}"
												mode="preview"
											></table-element>
											<div v-else-if="formRequest.answers[field.id]">
												{{ displayFieldAnswer(field, formRequest.answers[field.id], true) }}
											</div>
											<i v-else class="text-muted">
												No answer
											</i>
										</div>
									</template>
								</div>

								<hr v-if="index + 1 < form.steps.length" class="bg-gray" />
							</div>

							<div class="row justify-content-between align-items-center mt-5">
								<div class="col-auto">
									<button class="btn text-gray" @click.prevent="formPrev">
										Back
									</button>
								</div>
								<div class="col-auto">
									<form
										v-if="
											currentPaymentField &&
												paymentMethod === 'online' &&
												departments.find(
													d => d.id === currentPaymentField.data.paymentDepartment
												).paygov_account
										"
										name="form1"
										method="post"
										:action="
											j.testmode
												? 'https://qa-pay.paygov.us/API/PostAPI.aspx'
												: 'https://pay.paygov.us/API/PostAPI.aspx'
										"
									>
										<input
											name="ttid"
											type="hidden"
											:value="
												departments
													.find(d => d.id === currentPaymentField.data.paymentDepartment)
													.paygov_account.split(':')[0]
											"
										/>
										<!-- Required-->
										<input
											name="apiPassword"
											type="hidden"
											:value="
												departments
													.find(d => d.id === currentPaymentField.data.paymentDepartment)
													.paygov_account.split(':')[1]
											"
										/>
										<!-- Required-->
										<input
											name="paymentAmount"
											type="hidden"
											:value="formRequest.answers[currentPaymentField.id].price"
										/>
										<!-- Required-->
										<input
											name="SuccessURL"
											type="hidden"
											:value="
												`${apiUrl + j.slug}/payments/${
													formRequest.answers[currentPaymentField.id].payment_uuid
												}/payment-status-succeeded?send=1`
											"
										/>
										<!-- Required-->
										<input name="OrderToken" type="hidden" :value="formRequest.uuid" />
										<!-- Required-->

										<button class="btn btn-primary">Pay with PayGOV.US</button>
									</form>

									<button
										v-else
										class="btn btn-primary"
										:disabled="state === 'loading' || !states.stepsCompleted"
										@click.prevent="formPayAndSubmit"
									>
										<span
											v-if="state === 'loading'"
											class="spinner-border spinner-border-sm me-2"
										></span>
										<template v-if="currentPaymentField && paymentMethod === 'online'"
											>Pay and submit</template
										>
										<template v-else>Submit application</template>
									</button>
								</div>
							</div>

							<!-- <pre>{{ formRequest }}</pre>
								<pre>{{ form.steps }}</pre> -->
						</div>

						<div v-else-if="currentStep === 'end'">
							<div class="card">
								<div class="card-body text-center">
									<span class="spinner-border"></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="state === 'error'" class="alert alert-danger my-3">
			Oops, we couldn't load this form for some reason 🤷
		</div>
		<div v-else class="text-center py-3">
			<span class="spinner-border"></span>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.timeline-steps {
	//border: 1px solid var(--primary);
	border-left: 2px solid var(--primary);
	border-radius: 2px;
	margin-left: 0.7rem;
	counter-reset: line-number;
	padding-left: 1rem;

	li {
		list-style: none;
		position: relative;
		line-height: 1.9;
		//border: 1px solid yellow;
		counter-increment: line-number;
	}

	li:before {
		content: counter(line-number);
		position: absolute;
		border: 1px solid var(--primary);
		background: white;
		border-radius: 50%;
		font-size: 12px;
		line-height: 1rem;
		width: 1rem;
		text-align: center;
		color: var(--primary);
		top: 0.3rem;
		left: -1.5rem;
	}
}

.icon-circle {
	display: inline-block;
	border-radius: 50%;
	width: 9px;
	height: 9px;
	background-color: currentColor;
}

.row-form-content {
	//border-top: 1px solid #e8e9ec;
}

.section-steps .form-review-step {
	border: 2px solid transparent;
}

.section-steps .form-review-step-selected {
	border-color: #6e7191;
}

.section-steps .form-review-step:hover {
	background: #f7f7f7;
}

.col-form-fields {
	border-left: 1px solid #e8e9ec;
}

.element-TextElement img {
	width: 100%;
}

.payment-element-bridgepay {
	max-height: 120px;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'

import { appUrl, jLink, tracker, handleResponseError, truncateString } from '@/utils.js'
import heyGovApi from '@/api.js'
import { elementIsVisible, handlePayment, fillTextWithVariables } from '@/actions/forms.js'
import { displayFieldAnswer } from '@/actions/form-builder.js'
import { paymentFee, bridgePayAccounts } from '@/actions/payments.js'
import { isAddressObject } from '@/lib/geo.js'

import FormPageHead from '@/views/Forms/FormPageHead.vue'
import LoginForm from '@/components/LoginForm.vue'
import TextInputElement from '@/components/form-builder/TextInputElement'
import TextareaInputElement from '@/components/form-builder/TextareaInputElement'
import NumberInputElement from '@/components/form-builder/NumberInputElement'
import SelectListElement from '@/components/form-builder/SelectListElement'
import RadioButtonElement from '@/components/form-builder/RadioButtonElement'
import CheckboxElement from '@/components/form-builder/CheckboxElement'
import DatePickerElement from '@/components/form-builder/DatePickerElement'
import TimeElement from '@/components/form-builder/TimeElement'
import FileUploadElement from '@/components/form-builder/FileUploadElement'
import EmailInputElement from '@/components/form-builder/EmailInputElement'
import PhoneInputElement from '@/components/form-builder/PhoneInputElement'
import TableElement from '@/components/form-builder/TableElement'
import YearElement from '@/components/form-builder/YearElement'
import SignatureElement from '@/components/form-builder/SignatureElement'
import PaymentElement from '@/components/form-builder/PaymentElement'
import AddressInputElement from '@/components/form-builder/AddressInputElement'
import NameInputElement from '@/components/form-builder/NameInputElement'

export default {
	name: 'FormsSend',
	components: {
		FormPageHead,
		LoginForm,
		TextInputElement,
		TextareaInputElement,
		NumberInputElement,
		SelectListElement,
		RadioButtonElement,
		CheckboxElement,
		DatePickerElement,
		TimeElement,
		FileUploadElement,
		EmailInputElement,
		PhoneInputElement,
		TableElement,
		YearElement,
		SignatureElement,
		PaymentElement,
		AddressInputElement,
		NameInputElement,
	},
	data() {
		return {
			states: {
				feeInfo: false,
				authRequired: false,
				stepsCompleted: false,
			},
			state: 'loading',
			form: null,
			formRequest: null,
			formRequestDrafts: [],
			formFields: [],

			currentPaymentField: null,
			inputStyle: {
				padding: '0.5rem 1rem',
				backgroundColor: '#ffffff',
				border: '1px solid #dedede',
				borderRadius: '1.56rem',
				fontSize: '1.125rem',
				fontFamily: 'Agrandir',
			},
			stripe: null,
			stripeElements: null,
			paymentMethod: 'card',
			paymentError: '',

			url: window.location.protocol + '//' + window.location.hostname,

			stepStatuses: {
				'not-started': {
					name: 'Not Started',
					color: 'neutral-300',
				},
				'in-progress': {
					name: 'In Progress',
					color: 'warning-300',
				},
				filled: {
					name: 'Completed',
					color: 'success-300',
				},
				skipped: {
					name: 'Needs attention',
					color: 'danger-300',
				},
			},
		}
	},
	computed: {
		...mapState([
			'j',
			'apiUrl',
			'departments',
			'account',
			'stripePublishableKey',
			'stripePublishableTestKey',
			'extraDisclaimers',
		]),
		...mapGetters(['auth', 'currentRole']),
		currentStep() {
			return this.$route.params.step || 'start'
		},
		formStepIndex() {
			let index = null

			if (this.currentStep.startsWith('step-')) {
				index = parseInt(this.currentStep.replace('step-', ''), 10)
			}

			return index
		},
		formStepData() {
			let step = null

			if (this.currentStep && this.currentStep.startsWith('step-')) {
				step = this.form.steps[this.currentStep.replace('step-', '')] || null
			}

			return step
		},
		formRequestLoaded() {
			let ok = true

			if (this.$route.query.formRequest && !this.formRequest) {
				ok = false
			}

			return ok
		},
	},
	created() {
		tracker.event('form-send-start', this.$route.params.formSlug)
		this.$store.dispatch('loadDepartments')
		this.loadForm()
	},
	mounted() {
		//todo inject Stripe script only if form has payment field
		if (!('Stripe' in window)) {
			let StripeScript = document.createElement('script')
			StripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
			document.head.appendChild(StripeScript)
		}

		//todo inject tokenpay script only if form has payment field
		if (!('TokenPay' in window)) {
			const TokenPayScript = document.createElement('script')
			TokenPayScript.setAttribute(
				'src',
				this.j.testmode
					? 'https://www.bridgepaynetsecuretest.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js'
					: 'https://www.bridgepaynetsecuretx.com/Bridgepay.WebSecurity/TokenPay/js/tokenPay.js'
			)
			document.head.appendChild(TokenPayScript)
		}
	},
	methods: {
		jLink,
		elementIsVisible,
		paymentFee,
		handleResponseError,
		displayFieldAnswer,
		truncateString,

		loadForm() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.slug}/forms/${this.$route.params.formSlug}?expand=steps`).then(
				({ data }) => {
					data.steps = data.steps
						.filter(s => s.visible_to === 'EVERYONE')
						.map(step => {
							// fill default values
							step.fields = step.fields.filter(f => !f.removed)

							step.fields = step.fields.map(field => {
								//todo remove when api returns data&options as JSON objects
								if (!field.data || typeof field.data === 'string') {
									field.data = JSON.parse(field.data || '{}')
								}
								if (field.options && typeof field.options === 'string') {
									field.options = JSON.parse(field.options)
								}

								if (field.type === 'FileUploadElement') {
									field.value = JSON.parse(field.value)
								}

								if (
									field.type === 'AddressInputElement' &&
									field.data.prefillWithAccount &&
									this.account?.address
								) {
									field.value = this.account.address
								}

								if (
									field.type === 'PhoneInputElement' &&
									field.data.prefillWithAccount &&
									this.account?.phone
								) {
									field.value = this.account.phone
								}

								if (
									field.type === 'DatePickerElement' &&
									field.data.prefillWithAccount &&
									this.account?.dob
								) {
									field.value = this.account.dob
								}

								if (field.type === 'PaymentElement') {
									this.currentPaymentField = field
								}

								return field
							})

							this.formFields.push(...step.fields)

							return step
						})

					this.form = data

					this.loadFormRequests()

					this.state = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading form (${error.message})`)
					this.state = 'error'
				}
			)
		},

		loadFormRequests() {
			if (this.$route.query.formRequest) {
				heyGovApi.get(`${this.j.slug}/form-requests/${this.$route.query.formRequest}`).then(
					({ data }) => {
						this.formRequest = data
						this.resumeDraftAnswers()
					},
					error => {
						if (error.response?.status === 401) {
							this.states.authRequired = true
						} else {
							Vue.toasted.error(`Error loading form submission (${error})`)
						}
					}
				)
			} else if (this.auth) {
				const params = {
					own: 1,
					form_id: this.form.id,
					status: 'draft',
				}

				heyGovApi.get(`${this.j.slug}/form-requests`, { params }).then(({ data }) => {
					this.formRequestDrafts.push(...data)
				}, handleResponseError('Error loading draft applications ({error})'))
			}
		},
		resumeDraftAnswers() {
			this.form.steps.forEach(step => {
				step.fields.forEach(field => {
					// prefill answers from FromRequest
					if (this.formRequest.answers[field.id]) {
						field.value = this.formRequest.answers[field.id]
					}

					// select the first payment method as default
					if (field.type === 'PaymentElement') {
						this.currentPaymentField = field
						this.paymentMethod = field.data.paymentType[0]
					}
				})
			})
		},

		formStart(formRequest) {
			let nextStep = `forms/${this.form.slug}/send/step-0`

			if (!this.auth) {
				nextStep = `forms/${this.form.slug}/send/auth`
			} else if (formRequest) {
				this.formRequest = formRequest
				this.resumeDraftAnswers()
			} else if (!this.formRequest) {
				this.createFormRequest()
			}

			this.$router.push(jLink(nextStep))
		},
		createFormRequest() {
			heyGovApi
				.post(`${this.j.slug}/form-requests`, {
					form_id: this.form.id,
				})
				.then(({ data }) => {
					this.formRequest = data
				}, handleResponseError(`Oops, couldn't start the application ({error})`))
		},
		saveFormRequestAnswers() {
			return new Promise((resolve, reject) => {
				heyGovApi
					.put(`${this.j.slug}/form-requests/${this.formRequest.id}`, {
						steps: this.formRequest.steps,
						answers: this.formRequest.answers,
					})
					.then(resolve)
					.catch(error => {
						handleResponseError(`Couldn't save application answers ({error})`)(error)
						reject(error)
					})
			})
		},

		formClose() {
			if (confirm('Close form? No worries, current progress will be saved')) {
				this.$router.push(jLink('forms?progress=1'))
			}
		},
		formPrev() {
			//todo make request to save current step

			let stepData = this.form.steps[this.formStepIndex - 1]
			let prevStep = stepData ? `step-${this.formStepIndex - 1}` : 'start'

			if (this.currentStep === 'review') {
				stepData = this.form.steps[this.form.steps.length - 1]
				prevStep = `step-${this.form.steps.length - 1}`
			}

			console.log('[FormSend]', this.currentStep, 'go prev step to', prevStep)

			this.formToStep(prevStep, stepData)
		},
		formNext($event, skipValidation) {
			console.log('[FormSend]', this.currentStep, 'go next step')
			let nextStepOk = true

			// save info about current step
			const stepInfo = this.form.steps[this.formStepIndex]

			if (!this.formRequest.steps[this.formStepData.id]) {
				this.formRequest.steps[this.formStepData.id] = {
					status: 'not-started',
				}
			}

			if (skipValidation) {
				// go directly to next step
				// this was probably called by successful payment or Skip button

				if (this.formRequest.steps[stepInfo.id].status === 'not-started') {
					//todo check if completely done or required fields are missing
					this.formRequest.steps[stepInfo.id].status = 'skipped'
				}
			} else {
				// validate & handle all steps: payment, signature, etc

				// store data that can be updated in account
				const accountUpdate = {}

				this.formStepData.fields.forEach(field => {
					// save answer in Account
					if (field.value && field.data.prefillWithAccount) {
						if (
							field.type === 'NameInputElement' &&
							field.value !== ',,' &&
							!this.account.first_name &&
							!this.account.last_name
						) {
							const nameParts = field.value.split(',')
							accountUpdate.first_name = String(nameParts[0]).trim()
							accountUpdate.middle_name = String(nameParts[1]).trim()
							accountUpdate.last_name = String(nameParts[2]).trim()
						} else if (field.type === 'PhoneInputElement' && !this.account.phone) {
							accountUpdate.phone = field.value
						} else if (
							field.type === 'AddressInputElement' &&
							isAddressObject(field.value) &&
							field.value.line1 &&
							!this.account.address_json?.line1 &&
							!this.account.address_json?.city
						) {
							accountUpdate.address_json = field.value
						} else if (
							field.type === 'DatePickerElement' &&
							field.label.toLowerCase().includes('birth') &&
							!this.account.dob
						) {
							accountUpdate.dob = field.value
						}
					}

					if (field.type === 'PaymentElement') {
						// payment info is displayed in step
						// and payment is handled on formPayAndSubmit()
					} else if (this.elementIsVisible(field, this.formFields)) {
						let value = field.value

						if (field.type === 'RadioButtonElement') {
							value = value?.value
						} else if (field.type === 'CheckboxElement') {
							value = value?.values
						}

						let validValue = false

						if (Array.isArray(value)) {
							validValue = value.length > 0
						} else if (typeof value === 'string') {
							validValue = value.trim().length > 0
						} else {
							validValue = Boolean(value)
						}

						if (field.required && !validValue) {
							nextStepOk = false
							Vue.toasted.show(`Field ${field.label} is required`)
						} else if (field.value && field.type !== 'TextElement') {
							this.$set(this.formRequest.answers, field.id, field.value)
						}
					}
				})

				// update account data, if new info found
				if (nextStepOk && Object.keys(accountUpdate).length) {
					this.$store.dispatch('accountUpdate', accountUpdate)
				}

				this.formRequest.steps[stepInfo.id].status = 'filled'
			}

			if (nextStepOk) {
				let nextStep = this.form.steps[this.formStepIndex + 1] ? `step-${this.formStepIndex + 1}` : 'review'

				this.saveFormRequestAnswers()

				this.formToStep(nextStep, this.form.steps[this.formStepIndex + 1])
			} else {
				//alert(`can't continye yet`)
			}
		},
		formToStep(step, stepData) {
			console.log(this.formRequest.pid, 'go to step', step, stepData)

			// check if all steps are completed
			const steps = Object.values(this.formRequest.steps)
			this.states.stepsCompleted = this.form.steps.length === steps.filter(s => s.status === 'filled').length

			if (stepData) {
				/* setTimeout(() => {
					document.getElementById(`form-step-nav-${stepData.id}`).scrollIntoView({ behavior: 'smooth' })
				}, 100) */

				stepData.fields.forEach(field => {
					if (field.type === 'PaymentElement') {
						handlePayment(field, this.formFields)
					}
				})
			}

			if (step === 'review' && this.currentPaymentField && this.states.stepsCompleted) {
				this.startPayment(this.currentPaymentField)
			}

			this.$router.replace(jLink(`forms/${this.form.slug}/send/${step}?formRequest=${this.formRequest.pid}`))
		},
		async formPayAndSubmit() {
			this.state = 'loading'

			if (this.currentPaymentField) {
				if (this.paymentMethod === 'offline' || this.paymentMethod === 'in-person') {
					await this.saveFormRequestAnswers()
					this.formFinish()
				} else if (this.paymentMethod === 'online' || this.paymentMethod === 'card') {
					// do card payment
					this.makePayment(this.currentPaymentField)
				}
			} else {
				this.formFinish()
			}
		},
		formFinish() {
			heyGovApi
				.put(`${this.j.slug}/form-requests/${this.formRequest.id}`, {
					status: 'sent',
				})
				.then(() => {
					this.$router.push(jLink(`forms/${this.form.slug}/request-info/${this.formRequest.uuid}?sent=1`))
				})
				.catch(handleResponseError(`Couldn't submit the submission ({error})`))
		},

		fieldEditable(field) {
			let canEdit = true

			if (this.formRequest && this.currentPaymentField?.data?.priceConditions?.length) {
				const dependsOn = this.currentPaymentField.data.priceConditions.map(c => c.element)
				const paymentStatus = this.formRequest.answers[this.currentPaymentField.id]?.status

				canEdit = !dependsOn.includes(field.id) || !['succeeded', 'requires_capture'].includes(paymentStatus)
			}

			return canEdit
		},

		startPayment(field) {
			const paymentDept = this.departments.find(d => d.id == field.data.paymentDepartment)

			// calculate price based on fields conditions
			handlePayment(field, this.formFields)

			//todo remove this
			if (field.data.paymentType[0] === 'card') {
				field.data.paymentType[0] = 'online'
			} else if (field.data.paymentType[0] === 'in-person') {
				field.data.paymentType[0] = 'offline'
			}

			// set the default payment method
			this.paymentMethod = field.data.paymentType[0]

			const paymentAnswer = {
				price: field.value,
				type: this.paymentMethod,
			}

			this.$set(this.formRequest.answers, field.id, paymentAnswer)

			if (field.value && (field.data.paymentType.includes('online') || field.data.paymentType.includes('card'))) {
				console.log(this.formRequest.pid, 'Start payment', field)

				if (paymentDept.paygov_account) {
					// fee for PayGov online transaction
					const fee = paymentFee('paygov', 'card', field.value, paymentDept.paygov_account.split(':')[2])

					heyGovApi
						.post(`${this.j.slug}/form-requests/${this.formRequest.id}/payment-intent/${field.id}`, {
							amount: (field.value + fee).toFixed(2),
							price: field.value,
							fee,
						})
						.then(({ data }) => {
							console.log('loaded payment intent', data)

							// set PaymentIntent as field answer
							this.$set(this.formRequest.answers, field.id, data)
						})
				} else if (paymentDept.stripe_account) {
					this.stripe = new window.Stripe(
						this.j.testmode ? this.stripePublishableTestKey : this.stripePublishableKey,
						{
							stripeAccount: this.departments.find(d => d.id == field.data.paymentDepartment)
								.stripe_account,
						}
					)

					// fee for Stripe online transaction
					const fee = paymentFee('stripe', 'card', field.value)

					heyGovApi
						.post(`${this.j.slug}/form-requests/${this.formRequest.id}/payment-intent/${field.id}`, {
							amount: (field.value + fee).toFixed(2),
							price: field.value,
							fee,
						})
						.then(({ data }) => {
							console.log('loaded payment intent', data)

							// set PaymentIntent as field answer
							this.$set(this.formRequest.answers, field.id, data)

							this.stripeElements = this.stripe.elements({
								appearance: {
									theme: 'stripe',
								},
								clientSecret: data.client_secret,
							})

							const paymentElement = this.stripeElements.create('payment')
							paymentElement.on('focus', () => {
								this.paymentError = ''
							})
							paymentElement.mount('#payment-element')
						})
				} else if (paymentDept.bridgepay_account) {
					this.tokenpay = window.TokenPay(bridgePayAccounts[paymentDept.bridgepay_account])

					// fee for Stripe online transaction
					const fee = paymentFee('bridgepay', 'card', field.value)

					heyGovApi
						.post(`${this.j.slug}/form-requests/${this.formRequest.id}/payment-intent/${field.id}`, {
							amount: (field.value + fee).toFixed(2),
							price: field.value,
							fee,
						})
						.then(({ data }) => {
							console.log('loaded payment intent', data)

							// set PaymentIntent as field answer
							this.$set(this.formRequest.answers, field.id, data)

							this.tokenpay.initialize({
								dataElement: '#payment-element',
								errorElement: '#payment-element-error-bridgepay',
								amountElement: '#amount',
								//if displaying all 4 fields then useStyles=false, disableZip=false, disableCvv=false
								//if displaying 3 out of 4 fields then useStyles=false, and set disableZip or disableCvv equal to true
								//if displaying 2 out of 4 fields then useStyles=true, disableZip=true, disableCvv=true
								useStyles: false,
								useACH: false,
								disableZip: false,
								disableCvv: false,
							})

							this.states.paymentElementReady = true
						})
				}
			} else {
				paymentAnswer.fee = 0
				paymentAnswer.status = 'requires_payment_method'
				this.$set(this.formRequest.answers, field.id, paymentAnswer)
			}
		},
		async makePayment(field) {
			console.log('makePayment', field)
			const paymentDept = this.departments.find(d => d.id == field.data.paymentDepartment)
			this.paymentError = ''

			if (paymentDept.stripe_account) {
				const result = await this.stripe.confirmPayment({
					elements: this.stripeElements,
					confirmParams: {
						// Make sure to change this to your payment completion page
						return_url: appUrl(jLink(`forms/${this.form.slug}/send/${this.currentStep}`)),
					},
					redirect: 'if_required',
				})

				if (result.error) {
					// Show error to in payment form
					this.paymentError = result.error.message
				} else if (['succeeded', 'requires_capture'].includes(result.paymentIntent?.status)) {
					this.formRequest.answers[this.currentPaymentField.id].status = result.paymentIntent.status
					this.formRequest.answers[this.currentPaymentField.id].payment_method = 'card'

					await this.saveFormRequestAnswers()

					this.formFinish()
				} else {
					alert(`Payment error in console ~ `)
					console.log(result)
				}

				this.state = 'idle'
			} else if (paymentDept.bridgepay_account) {
				this.tokenpay.createToken(
					result => {
						const paymentFieldData = this.formRequest.answers[field.id]

						heyGovApi
							.post(`${this.j.slug}/payments/${paymentFieldData.payment_uuid}/bridgepay-token`, {
								token: result.token,
								capture_method: field.data.paymentFlow,
							})
							.then(async ({ data }) => {
								this.formRequest.answers[this.currentPaymentField.id].status = data.status
								this.formRequest.answers[this.currentPaymentField.id].payment_method = 'card'

								await this.saveFormRequestAnswers()

								this.formFinish()
							})
							.catch(error => {
								const msg =
									error.response?.data?.message ||
									error.response?.data?.error ||
									error.response?.statusText ||
									error.message

								this.paymentError = `Payment failed: ${msg}`
							})
							.finally(() => {
								this.state = 'idle'
							})
					},
					error => {
						this.state = 'idle'
						console.log('TokenPay error', error)
					}
				)
			}
		},

		textFillVariables(text) {
			return fillTextWithVariables(text, this.formFields)
		},

		// TODO move some of these functions to actions/forms.js
		getDept(id, field) {
			const dept = (this.departments || []).find(d => d.id == id)

			return dept ? dept[field] : null
		},
		fieldType(fieldType) {
			return this.formFields.find(f => f.type === fieldType)
		},
		fieldTypeAnswer(fieldType) {
			const field = this.fieldType(fieldType)

			return field && this.formRequest.answers[field.id] ? this.formRequest.answers[field.id] : null
		},
	},
	watch: {
		auth() {
			this.$store.dispatch('accountInfo')
			if (!this.formRequest) {
				this.createFormRequest()
			}
		},
	},
}
</script>
