<template>
	<div class="bg-neutral-100 border rounded-1 text-center position-relative on-parent px-2 py-3">
		<div class="btn-file-remove position-absolute bg-danger-50 rounded-circle show-on-hover" @click="removeFile">
			<font-awesome-icon :icon="['fas', 'trash']" class="text-danger-400" />
		</div>

		<font-awesome-icon v-if="urlIsImage(fileName)" :icon="['fas', 'image']" class="text-primary mb-2" />
		<font-awesome-icon v-else-if="urlIsDocument(fileName)" :icon="['fas', 'file-pdf']" class="text-primary mb-2" />
		<font-awesome-icon v-else-if="urlIsVideo(fileName)" :icon="['fas', 'video']" class="text-primary mb-2" />
		<font-awesome-icon v-else :icon="['fas', 'file']" class="text-primary mb-2" />

		<p class="text-neutral-500 mb-0 text-truncate" :title="fileName">
			<small>{{ fileName }}</small>
		</p>
	</div>
</template>

<style scoped>
.btn-file-remove {
	top: -6px;
	right: -5px;
	width: 28px;
	line-height: 28px;
	font-size: 0.8rem;
	cursor: pointer;
}
</style>

<script>
import { urlIsImage, urlIsDocument, urlIsVideo } from '@/utils.js'

export default {
	props: {
		filePath: {
			type: String,
			required: true,
		},
	},
	computed: {
		fileName() {
			const parts = this.filePath.split('/')
			return parts[parts.length - 1]
		},
	},
	methods: {
		urlIsImage,
		urlIsDocument,
		urlIsVideo,
		removeFile() {
			this.$emit('remove', this.filePath)
		},
	},
}
</script>
