/**
 * Load multiple fonts from Google Fonts
 * @param {Object[]} fonts - An array of font objects.
 * @param {string} fonts[].family - The font family.
 * @param {number[]} fonts[].weights - An array of font weights.
 * @param {number[]} fonts[].italics - An array of italic values.
 * @returns {void}
 */
export function loadGoogleFonts(fonts) {
	const fontUrl = new URL('https://fonts.googleapis.com/css2')
	fontUrl.searchParams.set('display', 'swap')

	fonts.forEach(font => {
		const weights = font.weights || ['400']
		fontUrl.searchParams.append('family', `${font.family}:wght@${weights.join(';')}`)
	})

	const link = document.createElement('link')
	link.rel = 'stylesheet'
	link.href = fontUrl.toString()
	document.head.appendChild(link)
}
