<template>
	<div class="venue-admin-calendar-wrapper">
		<div class="row">
			<!-- Venue Admin Calendar -->
			<div class="venue-admin-calendar" :class="options.calendarSize ? options.calendarSize : 'col-md-6'">
				<!-- Calendar header -->
				<div class="venue-admin-calendar-header mb-2">
					<div class="d-flex">
						<div class="calendar-back">
							<button variant="light" @click="subtractMonth">
								<font-awesome-icon :icon="['fas', 'chevron-left']" class="me-3" />
							</button>
						</div>
						<div class="calendar-text">
							<span class="me-1">{{ month }}</span>
							<span>{{ year }}</span>
						</div>
						<div class="calendar-forward">
							<button variant="light" @click="addMonth">
								<font-awesome-icon :icon="['fas', 'chevron-right']" class="ms-3" />
							</button>
						</div>
					</div>

					<div v-if="options.todayBtn && format(today, 'yyyy-MM-dd') !== format(selectedDate, 'yyyy-MM-dd')">
						<button @click="goToday" class="btn-link btn-today">Today</button>
					</div>
				</div>

				<!-- Calendar body -->
				<div class="venue-admin-calendar-body">
					<!-- Weekdays -->
					<div class="venue-admin-calendar-weekdays">
						<div class="weekday-text text-center text-muted" v-for="(day, index) in days" :key="index">
							<strong>{{ day }}</strong>
						</div>
					</div>

					<!-- Calendar Dates -->
					<div class="venue-admin-calendar-dates">
						<div
							class="venue-admin-calendar-date"
							v-for="date in monthDaysList"
							:key="date.key"
							:data-date="date.date"
							role="button"
							@click="selectDate(date)"
						>
							<!-- Display dates -->
							<div
								class="hey-date"
								:class="{
									'blocked-date-admin': date.blocked,
									'blocked-first': checkIsFirstBlocked(date),
									'blocked-last': checkIsLastBlocked(date),
									'blocked-middle': checkIsMiddleBlocked(date),
									'blocked-full': checkIsFullBlocked(date),
									'past-date': date.date < today,
									'today-date': date.today,
									'unavailable-date': date.date >= today && !date.available,
									'active-date': date.selected,
									'not-current-month-date': date.type === 'prev-month' || date.type === 'next-month',
									'window-block': date.window,
									highlight: highlightBlockedDate(date),
								}"
								@mouseover="eventToHover(date, true)"
								@mouseleave="eventToHover(date, false)"
							>
								<span class="day-text">{{ date.dayString }}</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Venue Admin events -->
			<div :key="update" :class="options.calendarSize ? options.eventsSize : 'col-md-6'">
				<!-- List blocking date events -->
				<div class="venue-admin-blocked-dates mb-4">
					<div class="row justify-content-between align-items-center mb-2">
						<div class="col">
							<h5 class="m-0">Blocked dates</h5>
						</div>

						<div class="col-auto">
							<button
								v-if="!displayAddBlockedDatesBtn"
								class="btn btn-sm btn-big-icon btn-primary m-1"
								@click="addEvent(selectedDate)"
							>
								Add blocked date <font-awesome-icon :icon="['fas', 'plus-circle']" />
							</button>
						</div>
					</div>

					<template v-for="event in blockingEventsCache[yearMonth]">
						<div :key="'block-' + event.id" class="blocked-item row align-items-center mb-2">
							<div class="col-4">
								<strong>
									{{ blockingDateDuration(event.venue_starts_at, event.venue_ends_at) }}
								</strong>
							</div>
							<div
								class="col blocked-item-name rounded p-2"
								:class="{
									highlight: highlightBlockedEvent(event),
								}"
								@mouseover="datesToHover(event, true)"
								@mouseleave="datesToHover(event, false)"
							>
								{{ event.name }}
							</div>

							<button
								v-if="options.hoverActions === true"
								class="btn btn-icon-success bg-primary text-white btn-sm m-1 edit-btn"
								@click="editEvent(event)"
							>
								<font-awesome-icon :icon="['fas', 'pencil-alt']" />
							</button>
							<button
								v-if="options.hoverActions === true"
								class="btn btn-icon-danger btn-sm m-1 remove-btn"
								@click="deleteEvent(event)"
							>
								<font-awesome-icon :icon="['fas', 'times']" />
							</button>
						</div>
					</template>
					<template v-if="blockingEventsCache[yearMonth] && blockingEventsCache[yearMonth].length < 1">
						<div class="rounded-1 bg-light d-flex justify-content-center align-items-center p-3">
							No blocked dates in this month
						</div>
					</template>
				</div>

				<!-- List venue availability for selected date -->
				<div class="venue-admin-availability">
					<h5 class="mb-2">Reservations for {{ selectedDate | dateLocal }}</h5>

					<div
						v-if="selectedDateCalendarSlots.length && selectedDateCalendarSlots[0].window"
						class="bg-warning-lighter p-3 rounded-1 mb-2 text-center"
					>
						<p class="lead mb-1">⚠️ Guests cannot book on this date</p>
						<p class="mb-0">{{ selectedDateCalendarSlots[0].reason }}</p>
					</div>

					<div
						v-for="slot in selectedDateCalendarSlots"
						:key="slot.id"
						class="slot"
						:class="{
							'bg-danger-lighter': slot.slotEvent && slot.id === slot.slotEvent.venue_slot_id,
							disabled: !slot.available && slot.id !== slot.slotEvent.venue_slot_id,
						}"
						v-show="!isSlotRemoved(slot)"
					>
						<div class="slot-wrapper">
							<div class="slot-info">
								<span class="slot-name"> {{ findVenueSlot(slot.id).name }} </span>
								<span class="slot-time">
									{{ slot.starts_at | slotLocalTime }}
									→
									{{ slot.ends_at | slotLocalTime }}
								</span>
							</div>
							<div class="slot-status">
								<router-link
									v-if="slot.available && slot.type === 'daily'"
									:to="
										`/${j.slug}/venues/${venue.slug}/booking-admin/${format(
											selectedDate,
											'yyyy-MM-dd'
										)}/${slot.id}`
									"
									class="btn btn-primary btn-sm"
								>
									<span class="fs-6">Book now</span>
								</router-link>
								<button
									v-else-if="slot.available && slot.type === 'multi'"
									class="btn btn-primary btn-sm"
									@click="bookMultiSlot(slot)"
									data-bs-toggle="modal"
									data-bs-target="#modal-book-multi-slot"
								>
									<span class="fs-6">Book now</span>
								</button>
								<div v-else class="d-flex justify-content-center align-items-center disabled">
									<template v-if="slot.id !== slot.slotEvent.venue_slot_id">
										<span class="fw-bold fs-6 pe-3">{{ slot.reason }}</span>
									</template>
									<template v-else>
										<button
											class="btn btn-primary btn-sm"
											v-if="slot.id === slot.slotEvent.venue_slot_id"
											@click="goToBookingDetails(slot.slotEvent)"
										>
											{{ slot.slotEvent.name }}
										</button>
									</template>
								</div>
							</div>
						</div>
					</div>

					<router-link
						v-if="venue.reservation_mode === 'time'"
						:to="`/${j.slug}/venues/${venue.slug}/create-booking/${format(selectedDate, 'yyyy-MM-dd')}`"
						class="btn btn-sm btn-outline-primary"
						>Add booking</router-link
					>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-book-multi-slot" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<form @submit.prevent="bookMultiSlotSubmit">
					<div v-if="bookingNew" class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Start a booking</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-row mb-3">
								<label class="form-label" for="booking-new-date">Start date</label>

								<div class="row">
									<div class="col-6">
										<input
											type="date"
											id="booking-new-date"
											class="form-control"
											v-model="bookingNew.date_start"
										/>
									</div>
									<div class="col-6">
										<input
											type="time"
											class="col-6 form-control"
											v-model="bookingNew.slot.starts_at"
											readonly
										/>
									</div>
								</div>
							</div>

							<div class="form-row mb-3">
								<label class="form-label" for="booking-new-date-end">End date</label>

								<div class="row">
									<div class="col-6">
										<input
											type="date"
											id="booking-new-date-end"
											class="form-control"
											v-model="bookingNew.date_end"
											required
										/>
									</div>
									<div class="col-6">
										<input
											type="time"
											class="col-6 form-control"
											v-model="bookingNew.slot.ends_at"
											readonly
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button class="btn btn-outline-primary">
								Continue →
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { Modal } from 'bootstrap'
import {
	parseISO,
	format,
	getDaysInMonth,
	getDate,
	subDays,
	subMonths,
	addMonths,
	getDay,
	addDays,
	startOfDay,
} from 'date-fns'
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'

import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'

export default {
	name: 'VenueCalendarAdmin',
	props: ['options', 'venue', 'update'],
	data() {
		const date = this.$route.query.date ? parseISO(this.$route.query.date) : new Date()

		return {
			today: startOfDay(new Date()),
			dateContext: date,
			selectedDate: date,
			monthDays: [],
			monthDaysList: [],
			monthsCache: {},
			blockingEventsCache: {},
			yearMonth: format(date, 'yyyy-MM'),
			selectedDateCalendarSlots: [],
			hoveredDates: [],
			forceNewData: false,
			blockedDatesToHover: [],
			blockedEventToHover: [],
			daysFromPreviousMonth: [],
			daysInPreviousMonth: null,
			nextMonth: null,
			previousMonth: null,
			year: null,
			month: null,
			daysInMonth: null,
			currentDate: null,
			initialDate: null,
			initialMonth: null,
			initialYear: null,
			yearMonthNext: null,
			yearMonthPrev: null,
			selectedDay: null,
			selectedMonth: null,
			selectedYear: null,
			selectedDayAndMonth: {
				day: '',
				month: '',
			},
			firstDayOfMonth: null,
			displayAddSlotBtn: false,
			displayAddBlockedDatesBtn: false,

			//slots booking
			$modalBookMulti: null,
			selectedSlot: null,
			bookingNew: null,
		}
	},
	computed: {
		...mapState(['j', 'account', 'departments']),
		...mapGetters(['currentRole', 'auth', 'isStaff']),
		days() {
			return this.account?.start_of_week === 'Mon'
				? ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
				: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
		},
	},
	mounted() {
		this.$modalBookMulti = new Modal(document.getElementById('modal-book-multi-slot'))
	},
	created() {
		// If page is availability then show button for adding blocked dates
		if (this.$route.path.indexOf('availability') === -1) {
			this.displayAddSlotBtn = true
			this.displayAddBlockedDatesBtn = true
		}

		// Set calendar
		this.updateMonthVariables()

		// Get initial venue month availability
		this.getVenueMonthAvailability(this.yearMonth).then(() => {
			this.selectedDate = this.monthDaysList.find(
				day => day.dayString == ('0' + this.selectedDayAndMonth.day).slice(-2)
			).date
			this.selectedDateCalendarSlots = this.monthDaysList.find(
				day => day.dayString == ('0' + this.selectedDayAndMonth.day).slice(-2)
			).slots
		})

		// Get initial months - current month and prev and next month
		this.getInitialMonths()
	},
	methods: {
		format,

		// Check is today in current month and year
		todayInCurrentMonthAndYear() {
			return this.month === this.initialMonth && this.year === this.initialYear
		},

		// Get days from prev month that fits in week row
		getDaysFromPreviousMonth: function() {
			let daysList = []
			let count =
				getDaysInMonth(subMonths(this.dateContext, 1)) -
				getDay(subDays(this.dateContext, this.currentDate)) -
				this.additionalDayNum()

			while (count < getDaysInMonth(subMonths(this.dateContext, 1))) {
				count++
				daysList[count] = count
			}
			return daysList.filter(function() {
				return true
			})
		},

		// Add additional day based on based on start day
		additionalDayNum() {
			return this.account?.start_of_week === 'Mon' ? 0 : 1
		},

		// Update all variables that creates calendar
		updateMonthVariables() {
			this.year = format(this.dateContext, 'Y')
			this.month = format(this.dateContext, 'MMMM')
			this.daysInPreviousMonth = getDaysInMonth(subMonths(this.dateContext, 1))
			this.previousMonth = subMonths(this.dateContext, 1)
			this.nextMonth = addMonths(this.dateContext, 1)
			this.daysInMonth = getDaysInMonth(this.dateContext)
			this.currentDate = getDate(this.dateContext)
			this.initialDate = parseInt(this.formattingDay(getDate(this.today)))
			this.initialMonth = format(this.today, 'MMMM')
			this.initialYear = format(this.today, 'Y')
			this.selectedDay = parseInt(format(this.selectedDate, 'dd'))
			this.selectedMonth = parseInt(format(this.selectedDate, 'MM'))
			this.selectedYear = parseInt(format(this.selectedDate, 'Y'))
			this.yearMonthNext = format(this.nextMonth, 'yyyy-MM')
			this.yearMonthPrev = format(this.previousMonth, 'yyyy-MM')
			this.selectedDayAndMonth.day = format(this.selectedDate, 'd')
			this.selectedDayAndMonth.month = format(this.selectedDate, 'MMMM')
			this.firstDayOfMonth = getDay(subDays(this.dateContext, this.currentDate)) + this.additionalDayNum
			this.daysFromPreviousMonth = this.getDaysFromPreviousMonth()
		},

		// Get three months at the start (prev, current and next)
		getInitialMonths() {
			// Get month data for next and prev month
			this.getVenueMonthAvailability(this.yearMonthNext)
			this.getVenueMonthAvailability(this.yearMonthPrev)
		},

		/* Getting data from api for selected month, if there is data in monthCache calendar uses that data, if not calling API
		   If there is update or insert, forceNewData flag will be true and then will get fresh data from API */
		getVenueMonthAvailability(yearMonth) {
			return new Promise((resolve, reject) => {
				if (this.monthsCache[yearMonth] && !this.forceNewData) {
					this.generateMonthDays()
					resolve(this.monthsCache[yearMonth])
				} else {
					heyGovApi.get(`/${this.j.slug}/venues/${this.venue.slug}/admin-availability/${yearMonth}`).then(
						({ data }) => {
							this.forceNewData = false
							this.monthsCache[yearMonth] = data.days
							this.blockingEventsCache[yearMonth] = data.blockingEvents
							this.generateMonthDays()
							resolve(this.monthsCache[yearMonth])
						},
						error => {
							reject(error)
							Vue.toasted.error(`Error loading venue availability for ${yearMonth} (${error})`)
						}
					)
				}
			})
		},

		// Creating calendar days for displaying on string, including prev month dates and next month dates
		generateMonthDays() {
			let days = []
			let prevMonthDaysCount = 0
			let currMonthDaysCount = 0
			let currMonth = this.yearMonth.slice(-2)
			let currYear = this.yearMonth.slice(-8, -3)
			let prevMonth =
				('0' + (parseInt(currMonth) - 1)).slice(-2) === '00'
					? '12'
					: ('0' + (parseInt(currMonth) - 1)).slice(-2)
			let prevYear = ('0' + (parseInt(currYear) - 1)).slice(-4)
			let nextMonth =
				('0' + (parseInt(currMonth) + 1)).slice(-2) === '13'
					? '01'
					: ('0' + (parseInt(currMonth) + 1)).slice(-2)
			let nextYear = ('0' + (parseInt(currYear) + 1)).slice(-4)

			// Handle first month in year
			if (!(nextMonth === '01')) {
				nextYear = currYear
			}
			// Handle last month in year
			if (!(prevMonth === '12')) {
				prevYear = currYear
			}

			let visibleDaysCounter = 0

			// Filling in dates from previous month just to populate missing dates in week ------------------
			if (this.daysFromPreviousMonth.length < 7) {
				this.daysFromPreviousMonth.forEach(function(date) {
					let dayString = ('0' + date).slice(-2)
					days[visibleDaysCounter] = {
						dateString: prevYear + '-' + prevMonth + '-' + dayString,
						dayString: dayString,
						monthString: prevMonth,
						yearString: prevYear,
						key: visibleDaysCounter,
						type: 'prev-month',
						today: false,
						slots: [],
						available: false,
						selected: false,
						blocked: false,
						date: parseISO(prevYear + '-' + prevMonth + '-' + dayString),
						window: false,
					}
					visibleDaysCounter++
					prevMonthDaysCount++
				})
			}

			// Filling in dates from current month -----------------------------------------------------------
			if (this.monthsCache[this.yearMonth]) {
				Object.values(this.monthsCache[this.yearMonth]).forEach((day, index) => {
					let dayString = ('0' + (index + 1)).slice(-2)
					days[visibleDaysCounter] = {
						dateString: currYear + '-' + currMonth + '-' + dayString,
						dayString: dayString,
						monthString: currMonth,
						yearString: currYear,
						key: visibleDaysCounter,
						type: 'curr-month',
						today: dayString == this.initialDate && this.todayInCurrentMonthAndYear(),
						slots: day.slots,
						available: day.available,
						selected:
							('0' + this.selectedDay).slice(-2) == dayString &&
							('0' + this.selectedMonth).slice(-2) == currMonth
								? true
								: false,
						blocked: day.blockedDay,
						date: parseISO(currYear + '-' + currMonth + '-' + dayString),
						window: day.slots.length > 0 ? day.slots[0].window : false,
					}
					currMonthDaysCount++
					visibleDaysCounter++
				})
			}

			// Filling in dates from next month just to populate missing dates in week -----------------------
			let totalPrevCurr = currMonthDaysCount + prevMonthDaysCount
			let nextMonthCount = 7 - (totalPrevCurr % 7) > 6 ? 0 : 7 - (totalPrevCurr % 7)

			let nextMonthDays = 1

			while (nextMonthDays < nextMonthCount + 1) {
				let dayString = ('0' + nextMonthDays).slice(-2)
				days[visibleDaysCounter] = {
					dateString: nextYear + '-' + nextMonth + '-' + dayString,
					dayString: dayString,
					monthString: nextMonth,
					yearString: nextYear,
					key: visibleDaysCounter,
					type: 'next-month',
					today: false,
					slots: [],
					available: false,
					selected: false,
					blocked: false,
					date: parseISO(nextYear + '-' + nextMonth + '-' + dayString),
					window: false,
				}
				nextMonthDays++
				visibleDaysCounter++
			}

			this.monthDaysList = days
		},

		// Go to next month
		addMonth: function() {
			return new Promise((resolve, reject) => {
				this.yearMonth = format(this.nextMonth, 'Y') + '-' + format(this.nextMonth, 'MM')
				this.dateContext = this.nextMonth
				this.updateMonthVariables()
				this.getVenueMonthAvailability(this.yearMonthNext)
					.then(resolve)
					.error(reject)
			})
		},

		// Go to previous month
		subtractMonth: function() {
			return new Promise((resolve, reject) => {
				this.yearMonth = format(this.previousMonth, 'Y') + '-' + format(this.previousMonth, 'MM')
				this.dateContext = this.previousMonth
				this.updateMonthVariables()
				this.getVenueMonthAvailability(this.yearMonthPrev)
					.then(resolve)
					.error(reject)
			})
		},

		// Selecting date with click on date in calendar
		selectDate: function(date) {
			if (date.type !== 'prev-month' && date.type !== 'next-month') {
				this.selectedDate = date.date
				this.selectedDateCalendarSlots = date.slots
				this.deselectDates()
				date.selected = true
			}
			if (date.type === 'prev-month' && !this.disablePrevMonth) {
				this.selectedDate = date.date
				this.deselectDates()
				date.selected = true
				this.subtractMonth().then(
					(this.selectedDateCalendarSlots = this.monthDaysList.find(
						day => day.dayString == ('0' + this.selectedDayAndMonth.day).slice(-2)
					).slots)
				)
			}
			if (date.type === 'next-month') {
				this.selectedDate = date.date
				this.deselectDates()
				date.selected = true
				this.addMonth().then(
					(this.selectedDateCalendarSlots = this.monthDaysList.find(
						day => day.dayString == ('0' + this.selectedDayAndMonth.day).slice(-2)
					).slots)
				)
			}
		},

		// Deselect all dates from current month
		deselectDates() {
			this.monthDayList = this.monthDaysList.forEach(d => {
				if (d.selected) {
					d.selected = false
				}
			})
		},

		// Go to today's date
		goToday: function() {
			this.selectedDate = this.today
			this.dateContext = this.today
			this.yearMonth = format(this.selectedDate, 'Y') + '-' + format(this.selectedDate, 'MM')
			this.updateMonthVariables()
			this.getVenueMonthAvailability(this.yearMonth).then(
				(this.selectedDateCalendarSlots = this.monthDaysList.find(
					day => day.dayString == this.selectedDayAndMonth.day
				).slots)
			)
		},

		// Formatting date from 1 to 01
		formattingDay: function(day) {
			return ('0' + day).slice(-2)
		},

		// Get venue slot object
		findVenueSlot(slotId) {
			return this.venue.slots.find(s => s.id == slotId)
		},

		// Calculate blocking date duration
		blockingDateDuration(start, end) {
			let st = format(parseISO(start), 'MMM dd')
			let en = format(parseISO(end), 'MMM dd')
			return st === en ? st : st + ' - ' + en
		},

		// Add new event
		addEvent(date) {
			this.$emit('addEvent', date)
		},

		// Edit event
		editEvent(event) {
			this.$emit('editEvent', event)
		},

		// For blocked dates check is start date equal to date
		checkIsFirstBlocked(date) {
			const event = date.slots.find(s => s.blockedDate && s.slotEvent)?.slotEvent

			if (event) {
				return (
					format(parseISO(event.venue_starts_at), 'yyyy-MM-dd') === date.dateString &&
					format(parseISO(event.venue_ends_at), 'yyyy-MM-dd') !== date.dateString
				)
			} else {
				return false
			}
		},

		// For blocked dates check is end date equal to date
		checkIsLastBlocked(date) {
			const event = date.slots.find(s => s.blockedDate && s.slotEvent)?.slotEvent

			if (event) {
				return (
					format(parseISO(event.venue_ends_at), 'yyyy-MM-dd') === date.dateString &&
					format(parseISO(event.venue_starts_at), 'yyyy-MM-dd') !== date.dateString
				)
			} else {
				return false
			}
		},

		// For blocked dates check is date between start adn end date
		checkIsMiddleBlocked(date) {
			const event = date.slots.find(s => s.blockedDate && s.slotEvent)?.slotEvent

			if (event) {
				return (
					format(parseISO(event.venue_starts_at), 'yyyy-MM-dd') !== date.dateString &&
					format(parseISO(event.venue_ends_at), 'yyyy-MM-dd') !== date.dateString
				)
			} else {
				return false
			}
		},

		// For blocked dates check is start date and end date equal to date
		checkIsFullBlocked(date) {
			const event = date.slots.find(s => s.blockedDate && s.slotEvent)?.slotEvent

			if (event) {
				return (
					format(parseISO(event.venue_starts_at), 'yyyy-MM-dd') === date.dateString &&
					format(parseISO(event.venue_ends_at), 'yyyy-MM-dd') === date.dateString
				)
			} else {
				return false
			}
		},

		// When hover over blocked event add class to that dates in calendar
		datesToHover(event, status) {
			if (status == true) {
				let firstDate = format(parseISO(event.venue_starts_at), 'yyyy-MM-dd')
				let lastDate = format(parseISO(event.venue_ends_at), 'yyyy-MM-dd')

				while (firstDate <= lastDate) {
					this.blockedDatesToHover.push(firstDate)
					firstDate = format(addDays(parseISO(firstDate), 1), 'yyyy-MM-dd')
				}
			} else {
				this.blockedDatesToHover = []
			}
		},

		// Check is date highlighted (blocked dates to add class on event)
		highlightBlockedDate(date) {
			return this.blockedDatesToHover.includes(format(date.date, 'yyyy-MM-dd'))
		},

		// When hover on date to add class on blocked event
		eventToHover(date, status) {
			if (status == true) {
				this.blockingEventsCache[this.yearMonth].forEach(event => {
					if (
						format(date.date, 'yyyy-MM-dd') >= format(parseISO(event.venue_starts_at), 'yyyy-MM-dd') &&
						format(date.date, 'yyyy-MM-dd') <= format(parseISO(event.venue_ends_at), 'yyyy-MM-dd') &&
						event.type === 'event'
					) {
						this.blockedEventToHover.push(format(parseISO(event.venue_starts_at), 'yyyy-MM-dd'))
					}
				})
			} else {
				this.blockingEventsCache[this.yearMonth].forEach(event => {
					if (
						format(date.date, 'yyyy-MM-dd') >= format(parseISO(event.venue_starts_at), 'yyyy-MM-dd') &&
						format(date.date, 'yyyy-MM-dd') <= format(parseISO(event.venue_ends_at), 'yyyy-MM-dd') &&
						event.type === 'event'
					) {
						this.blockedEventToHover = []
					}
				})
			}
		},

		// Check is blocked event highlighted (blocked dates to add class on dates)
		highlightBlockedEvent(event) {
			return this.blockedEventToHover.includes(format(parseISO(event.starts_at), 'yyyy-MM-dd'))
		},

		// Go to booking details when there is booking on slot
		goToBookingDetails(event) {
			heyGovApi.get(`/${this.j.slug}/venue-bookings/${event.booking_id}`).then(({ data }) => {
				this.$router.push(`/${this.j.slug}/venues/${this.venue.slug}/bookings/${data.uuid}`)
			}, handleResponseError(`Error loading reservation details ({error})`))
		},

		// Delete event
		deleteEvent(event) {
			if (confirm('Are you sure you want to delete this event?')) {
				heyGovApi.put(`/${this.j.slug}/venue-events/${event.id}/delete`).then(() => {
					this.forceNewData = true
					this.getVenueMonthAvailability(this.yearMonth)
					this.forceNewData = false
					Vue.toasted.success(`Event successfully deleted`)
				}, handleResponseError(`Error deleting event ({error})`))
			}
		},

		// Check is slot removed or not
		isSlotRemoved(slot) {
			return this.venue.slots.find(s => s.id === slot.id)?.deleted_at
		},

		bookMultiSlot(slot) {
			this.bookingNew = {
				date_start: format(this.selectedDate, 'yyyy-MM-dd'),
				date_end: format(addDays(this.selectedDate, 1), 'yyyy-MM-dd'),
				slot: window.structuredClone(slot),
			}
		},
		bookMultiSlotSubmit() {
			this.$router.push(
				`/${this.j.slug}/venues/${this.venue.slug}/booking-admin/${this.bookingNew.date_start}:${this.bookingNew.date_end}/${this.bookingNew.slot.id}`
			)
			this.$modalBookMulti.hide()
		},
	},
	filters: {
		slotLocalTime: function(value) {
			if (!value) return ''
			// Adding time to any date just to create date so it can convert to proper time format based on settings
			value = new Date('2022-06-08 ' + value)
			return value.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
		},
	},
	watch: {
		update() {
			this.forceNewData = true
			this.getVenueMonthAvailability(this.yearMonth)
			this.getVenueMonthAvailability(this.yearMonthNext)
		},
	},
}
</script>

<style lang="scss" scoped>
.venue-admin-calendar-wrapper {
	.venue-admin-calendar {
		margin-bottom: 2rem;
		.venue-admin-calendar-header {
			font-size: 1rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.calendar-back {
				button {
					background-color: transparent;
					border: none;
				}

				&.disabled {
					button {
						color: #898c90;
					}
				}
			}
			.calendar-forward {
				button {
					background-color: transparent;
					border: none;
				}
			}
			.btn-today {
				background-color: transparent;
				border: none;
			}
		}
		.venue-admin-calendar-body {
			border-radius: 1rem;
			background-color: #f9f9f9;
			.venue-admin-calendar-weekdays {
				display: flex;
				padding-top: 0.875rem;
				padding-bottom: 0.875rem;
				.weekday-text {
					width: calc(100% / 7);
					padding: 0.25rem 0.5rem;
				}
			}
			.venue-admin-calendar-dates {
				padding: 5px;
				display: flex;
				flex-wrap: wrap;
				position: relative;
				text-align: center;
				border-radius: 1rem;
				.venue-admin-calendar-date {
					background-color: rgb(249, 249, 249);
					width: calc(100% / 7);
					margin: 3px auto;
					.hey-date {
						display: flex;
						align-items: center;
						justify-content: center;
						&.blocked-date-admin {
							background-color: #e9e9e9;
							border: 1px solid #898c90;
							.current-month-day {
								color: #898c90;
							}
						}

						&.blocked-first {
							border-top-left-radius: 1rem;
							border-bottom-left-radius: 1rem;
							border-right: none;
						}

						&.blocked-last {
							border-top-right-radius: 1rem;
							border-bottom-right-radius: 1rem;
							border-left: none;
						}

						&.blocked-middle {
							border-left: none;
							border-right: none;
						}

						&.blocked-full {
							border-radius: 1rem;
						}

						// Unavailable date
						&.unavailable-date {
							.day-text {
								color: black;
								text-decoration: line-through;
								font-weight: 400;
							}
						}

						// Unavailable date
						&.window-block {
							.day-text {
								color: black;
								text-decoration: line-through;
								font-weight: 400;
							}
						}

						// Active date (date when clicked on it)
						&.active-date {
							.day-text {
								border-radius: 50%;
								font-weight: bold;
								color: white !important;
								background-color: var(--bs-primary) !important;
								border: 1px solid var(--bs-primary);
							}
						}

						// Look of todays date
						&.today-date {
							.day-text {
								border-radius: 50%;
								font-weight: bold;
								background-color: transparent;
								border: 1px solid var(--bs-primary);
								color: var(--bs-primary);
							}
						}

						// Look of dates that are lest than today
						&.past-date {
							cursor: not-allowed;
							.day-text {
								color: #898c90;
								font-weight: 400;
								text-decoration: line-through !important;
							}
						}

						// Look of dates from Previous and Next month that populates calendar page
						&.not-current-month-date {
							.day-text {
								color: #ccc;
								font-weight: 400;
								text-decoration: none;
							}
						}

						// Highlight dates when hovered over blocked dates
						&.highlight {
							transition: all 0.9s ease;
							background: none !important;
							background-color: var(--bs-info) !important;
							border-color: var(--bs-info) !important;
							.day-text {
								color: white !important;
								font-weight: 400;
								text-decoration: none;
							}
						}

						.day-text {
							color: #2a9836;
							font-weight: bold;
							display: flex;
							width: 100%;
							justify-content: center;
							align-items: center;
							text-align: center;
							border: 1px solid transparent;
							width: 50px;
							height: 50px;
						}
					}
				}
			}
		}
	}

	.venue-admin-availability {
		.slot {
			margin-bottom: 0.5rem;
			background-color: #e4f7e6;
			padding: 0.75rem;
			border-radius: 1rem;
			.slot-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
				.slot-info {
					display: flex;
					justify-content: center;
					align-items: flex-start;
					flex-direction: column;
					text-transform: uppercase;
					.slot-time {
						font-weight: 800;
					}
				}
			}
			&.disabled {
				color: #898c90 !important;
				background-color: #dfdede !important;
			}
		}
	}

	.venue-admin-blocked-dates {
		.blocked-item {
			.blocked-item-name {
				transition: border-color 0.3s ease-in-out, transform 0.3s ease-in-out;
				border: 1px solid #dee2e6;

				&.highlight {
					border-color: var(--bs-info);
					transform: scale(1.05);
				}
			}

			.remove-btn {
				display: none;
				position: absolute;
				right: 25px;
				z-index: 999;
			}

			.edit-btn {
				display: none;
				position: absolute;
				right: 63px;
				z-index: 999;
			}

			&:hover {
				.remove-btn,
				.edit-btn {
					display: block;
				}
			}
		}
	}

	@media (max-width: 480px) {
		.weekday-text {
			padding: 0.25rem 0.5rem;
			font-size: 0.7rem !important;
		}

		.venue-admin-calendar-date {
			margin-top: 0px !important;
			margin-bottom: 0px !important;
		}

		.day-text {
			font-size: 0.875rem !important;
			width: 40px !important;
			height: 40px !important;
		}

		.slot-info {
			.slot-name,
			.slot-time {
				font-size: 0.875rem !important;
			}
		}
	}
}
</style>
