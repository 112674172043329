<template>
	<div class="page-events">
		<div class="row align-items-center mb-3">
			<div class="col">
				<h2 class="my-0">Events</h2>
			</div>
			<div class="col-auto">
				<!-- <button class="btn btn-primary btn-sm me-2" @click="createCategory">
					Add category
				</button> -->
				<button
					class="btn btn-primary btn-sm btn-big-icon"
					data-bs-toggle="modal"
					data-bs-target="#modal-event-add"
					@click="startEventAdd"
				>
					<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add event
				</button>
			</div>
		</div>

		<div class="card">
			<div class="card-body events-calendar">
				<div class="row align-items-center mb-3">
					<div class="col-4">
						<button class="btn btn-sm" @click="states.showFilters = !states.showFilters">
							<font-awesome-icon :icon="['fas', 'filter']" /> Filters
						</button>
					</div>
					<div class="col">
						<div class="row align-items-center">
							<div class="col-auto">
								<button class="calendar-back py-1 px-3" @click="changeCalendarMonth(1)">
									<font-awesome-icon :icon="['fas', 'chevron-left']" />
								</button>
							</div>
							<div class="col text-center">
								<h5 class="my-0">
									{{ format(currentDay, 'MMMM') }}
									<span class="fw-normal">{{ format(currentDay, 'yyyy') }}</span>
								</h5>
							</div>
							<div class="col-auto">
								<button class="calendar-forward py-1 px-3" @click="changeCalendarMonth(-1)">
									<font-awesome-icon :icon="['fas', 'chevron-right']" />
								</button>
							</div>
						</div>
					</div>
					<div class="col-4 text-end">
						<div class="btn-group" role="group" aria-label="Events view">
							<button
								type="button"
								class="btn btn-sm"
								:class="states.view === 'list' ? 'btn-dark' : 'btn-outline-dark'"
								@click="states.view = 'list'"
							>
								List
							</button>
							<button
								type="button"
								class="btn btn-sm"
								:class="states.view === 'calendar' ? 'btn-dark' : 'btn-outline-dark'"
								@click="states.view = 'calendar'"
							>
								Calendar
							</button>
						</div>
					</div>
				</div>

				<div v-if="states.showFilters" class="row align-items-center g-2 mb-3">
					<div class="col-sm-6 col-lg">
						<input
							type="search"
							class="form-control form-control-sm"
							v-model="filters.q"
							placeholder="Search.."
						/>
					</div>

					<div class="col-sm-6 col-lg">
						<select class="form-select form-select-sm" v-model="filters.category">
							<option value="">All categories</option>
							<option v-for="category in categories" :key="category.id" :value="category.id">{{
								category.name
							}}</option>
						</select>
					</div>

					<div class="col-sm-6 col-lg">
						<select class="form-select form-select-sm" v-model="filters.venue">
							<option value="">Any venue</option>
							<option v-for="venue in venues" :key="venue.id" :value="venue.id">{{ venue.name }}</option>
						</select>
					</div>
				</div>

				<hr class="bg-primary-50" />

				<div v-if="states.view === 'list'" class="card-table">
					<table class="table table-hover">
						<thead>
							<tr>
								<th>Event</th>
								<th>Date</th>
								<th>Duration</th>
								<th>Category</th>
								<th>Venue</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="event in events" :key="event.id">
								<td>
									{{ event.name }}
									<span v-if="event.status !== 'public'" class="badge bg-danger-50 text-danger-300">{{
										event.status
									}}</span>

									<span v-if="event.type === 'recurrent'" class="badge bg-primary-50">🔁</span>
								</td>
								<td>
									<template
										v-if="
											new Date(event.starts_at).toLocaleDateString('default', {
												timeZone: j.timezone,
											}) ===
												new Date(event.ends_at).toLocaleDateString('default', {
													timeZone: j.timezone,
												})
										"
									>
										{{
											new Date(event.starts_at).toLocaleString('default', {
												timeZone: j.timezone,
												dateStyle: 'medium',
												timeStyle: 'short',
											})
										}}
										→
										{{
											new Date(event.ends_at).toLocaleTimeString('default', {
												timeZone: j.timezone,
												timeStyle: 'short',
											})
										}}
									</template>
									<template v-else>
										{{
											new Date(event.starts_at).toLocaleString('default', {
												timeZone: j.timezone,
												dateStyle: 'medium',
												timeStyle: 'short',
											})
										}}
										→
										{{
											new Date(event.ends_at).toLocaleString('default', {
												timeZone: j.timezone,
												dateStyle: 'medium',
												timeStyle: 'short',
											})
										}}
									</template>
								</td>
								<td>{{ differenceInHours(new Date(event.ends_at), new Date(event.starts_at)) }}h</td>
								<td>
									<template v-for="category in event.categories">
										<span
											v-if="categories.find(c => c.id === category)"
											:key="`ev-${event.id}-cat-${category}`"
											class="badge bg-neutral-100 text-gray mb-1 me-1"
											>{{ categories.find(c => c.id === category).name }}</span
										>
										<span
											v-else
											class="badge bg-danger-50 text-danger-400"
											:key="`ev-${event.id}-catid-${category}`"
											>[CAT={{ category }}]</span
										>
									</template>
									<span v-if="!event.categories.length" class="text-neutral-300">-</span>
								</td>
								<td>
									<template v-if="event.venues.length">
										<router-link
											v-if="venues.find(v => v.id == event.venues[0])"
											:key="event.venues[0]"
											:to="`/${j.slug}/venues/${venues.find(v => event.venues[0] == v.id).slug}`"
											class="badge bg-warning-50 text-dark me-1 mb-1"
										>
											📍 {{ venues.find(v => event.venues[0] == v.id).name }}
										</router-link>

										<router-link
											v-if="
												event.venues.length === 2 && venues.find(v => v.id == event.venues[1])
											"
											:key="event.venues[1]"
											:to="`/${j.slug}/venues/${venues.find(v => event.venues[1] == v.id).slug}`"
											class="badge bg-warning-50 text-dark me-1 mb-1"
										>
											📍 {{ venues.find(v => event.venues[1] == v.id).name }}
										</router-link>
										<span
											v-if="event.venues.length > 2"
											class="badge bg-warning-50 text-neutral-400 mb-1"
											>{{ event.venues.length - 1 }} other</span
										>
									</template>
									<span v-else class="text-neutral-300">-</span>
								</td>
							</tr>

							<tr v-if="states.events === 'loading'">
								<td colspan="5" class="text-center py-4">
									<span class="spinner-border spinner-border-sm"></span> Loading events..
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-else class="bg-warning-50 p-6 rounded-1 border border-dashed text-center">
					Calendar view comign soon. For now, use the list view.
				</div>
			</div>
		</div>

		<!-- Add Event Modal -->
		<div class="modal fade" id="modal-event-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div v-if="newEvent" class="modal-content">
					<form @submit.prevent="addEvent">
						<div class="modal-header">
							<h5 class="modal-title">Add event</h5>

							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label class="form-label" for="event-name"
									>Name <span class="text-danger">*</span></label
								>
								<input
									class="form-control"
									id="event-name"
									v-model="newEvent.name"
									placeholder="Event name"
									required
								/>
							</div>

							<div class="row">
								<div class="col-lg-6 form-group mb-3">
									<label class="form-label" for="event-starts-at"
										>Start <span class="text-danger">*</span></label
									>
									<input
										type="datetime-local"
										class="form-control"
										id="event-starts-at"
										v-model="newEvent.starts_at_local"
										required
									/>
								</div>

								<div class="col-lg-6 form-group mb-3">
									<label class="form-label" for="event-ends-at"
										>End <span class="text-danger">*</span></label
									>
									<input
										type="datetime-local"
										class="form-control"
										id="event-ends-at"
										v-model="newEvent.ends_at_local"
										required
									/>
								</div>
							</div>

							<template v-if="states.addEventAllFields">
								<div class="row">
									<div class="col-lg-6 form-group mb-3">
										<label class="form-label" for="event-department">Department</label>
										<select
											class="form-select"
											id="event-department"
											v-model="newEvent.department_id"
										>
											<option :value="null">Select a department</option>
											<option
												v-for="department in activeDepartments"
												:key="`event-dept-${department.id}`"
												:value="department.id"
												>{{ department.name }}</option
											>
										</select>
									</div>

									<div class="col-lg-6 form-group mb-3">
										<label class="form-label" for="event-status">Privacy</label>
										<select class="form-select" id="event-status" v-model="newEvent.status">
											<option value="private">Private</option>
											<option value="public">Public</option>
										</select>
									</div>
								</div>

								<div class="row">
									<div class="col-lg-6 form-group mb-3">
										<label class="form-label" for="event-categories">Category</label>

										<multiselect
											v-model="newEvent.categories"
											:multiple="true"
											:options="categories"
											track-by="id"
											label="name"
											placeholder="Select categories"
											:hideSelected="true"
										>
											<template slot="singleLabel" slot-scope="{ option }">{{
												option.name
											}}</template>
										</multiselect>
									</div>

									<div class="col-lg-6 form-group mb-3">
										<label class="form-label" for="event-venues">Venues</label>

										<multiselect
											v-model="newEvent.venues"
											:multiple="true"
											:options="venues"
											track-by="id"
											label="name"
											placeholder="Select venues"
											:hideSelected="true"
										>
											<template slot="singleLabel" slot-scope="{ option }">{{
												option.name
											}}</template>
										</multiselect>
									</div>
								</div>

								<div class="form-group mb-3">
									<label class="form-label" for="event-description">Description</label>
									<textarea
										rows="2"
										class="form-control"
										id="event-description"
										v-model="newEvent.description"
										placeholder="Short description"
									/>
								</div>

								<p class="card-text"><code>Recurrence options are coming soon</code></p>
							</template>

							<p v-else class="card-text">
								<button class="btn btn-sm" @click="states.addEventAllFields = true">
									Add recurrence, category, and more
								</button>
							</p>
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary">Create event</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import '@/assets/variables';

.events-calendar {
	.calendar-back,
	.calendar-forward {
		background-color: transparent;
		border: none;
		border-radius: 0.5rem;

		&:disabled {
			cursor: not-allowed;
			color: #898c90;
		}

		&:hover {
			background-color: $neutral-50;
		}
	}
}
</style>

<script>
import { Modal } from 'bootstrap'
import { mapState, mapGetters } from 'vuex'
import Vue from 'vue'
import { format, differenceInHours, subMonths, startOfMonth } from 'date-fns'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import heyGovApi, { handleResponseError } from '@/api.js'
import { sortTable } from '@/actions/tables'

export default {
	name: 'Events',
	components: { Multiselect },
	data() {
		return {
			states: {
				events: 'loading',
				showFilters: false,
				categories: 'loading',
				view: 'list',
				addEventAllFields: false,
			},
			filters: {
				q: this.$route.query.q || '',
				category: this.$route.query.category || '',
				venue: '',
			},
			events: [],
			categories: [],
			currentDay: this.$route.query.month ? new Date(`${this.$route.query.month}-01`) : new Date(),

			blankEvent: {
				name: '',
				department_id: null,
				status: 'private',
				categories: [],
				venues: [],
				description: '',
				starts_at_local: null,
				ends_at_local: null,
			},
			newEvent: null,
		}
	},
	computed: {
		...mapState(['j', 'venues']),
		...mapGetters(['activeDepartments', 'currentRole']),
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadCategories()
		this.loadEvents()
		this.$store.dispatch('getVenues')
	},
	mounted() {
		this.$modalEventAdd = new Modal(document.getElementById('modal-event-add'), {
			keyboard: false,
			backdrop: 'static',
		})
	},
	methods: {
		sortTable,
		format,
		differenceInHours,

		loadEvents() {
			this.states.events = 'loading'
			const params = {
				...this.filters,
				month: format(this.currentDay, 'yyyy-MM'),
				limit: 100,
			}

			heyGovApi.get(`/${this.j.slug}/events`, { params }).then(
				data => {
					this.events = data.data

					this.states.events = 'loaded'
				},
				error => {
					Vue.toasted.error(`Error loading events (${error.message})`)
					this.states.events = 'error'
				}
			)
		},
		loadCategories() {
			this.states.categories = 'loading'

			heyGovApi.get(`/${this.j.slug}/events/categories`).then(
				({ data }) => {
					this.categories.push(...data)
					this.states.categories = 'loaded'
				},
				error => {
					Vue.toasted.error(`Error loading event categories (${error.message})`)
					this.states.categories = 'error'
				}
			)
		},
		updatePageUrl(filters, router) {
			let query = {}
			for (const filter in filters) {
				if (filters[filter]) {
					query[filter] = filters[filter]
				}
			}

			router.replace({ path: `/${this.j.slug}/events-embed`, query }).catch(() => {})
		},

		changeCalendarMonth(months) {
			this.currentDay = subMonths(startOfMonth(this.currentDay), months)
		},

		startEventAdd() {
			this.states.addEventAllFields = false
			this.newEvent = window.structuredClone(this.blankEvent)
		},
		addEvent() {
			this.newEvent.starts_at_local = format(new Date(this.newEvent.starts_at_local), 'yyyy-MM-dd HH:mm:ss')
			this.newEvent.ends_at_local = format(new Date(this.newEvent.ends_at_local), 'yyyy-MM-dd HH:mm:ss')
			this.newEvent.categories = this.newEvent.categories.map(c => c.id || c)
			this.newEvent.venues = this.newEvent.venues.map(v => v.id || v)

			heyGovApi.post(`/${this.j.slug}/events`, this.newEvent).then(({ data }) => {
				console.log(data)

				//todo reload events only if event is in same month like `currentDay`
				this.loadEvents()

				this.newEvent = null
				Vue.toasted.success('Event created')
				this.$modalEventAdd.hide()
			}, handleResponseError(`Error creating event ({error})`))
		},

		createCategory() {
			const data = {
				name: 'Garbage & Recycling',
				style: { backgroundColor: '#1e8d1c', color: '', fontSize: '', fontStyle: '', fontWeight: '' },
			}

			heyGovApi.post(`/${this.j.slug}/events/categories`, data).then(({ data }) => {
				console.log(data)
				Vue.toasted.success('Category created')
			}, handleResponseError(`Error creating category ({error})`))
		},
	},
	watch: {
		currentDay() {
			this.events = []
			this.loadEvents()
		},
		filters: {
			deep: true,
			handler() {
				this.events = []
				this.loadEvents()
			},
		},
	},
}
</script>
