<template>
	<form @submit.prevent="login">
		<div v-if="error" class="alert alert-warning" role="alert">{{ error }}</div>
		<div v-if="info" class="alert alert-info" role="alert">{{ info }}</div>

		<div class="form-group mb-2">
			<label for="email" class="form-label">Email</label>
			<input
				type="email"
				class="form-control"
				id="email"
				autocomplete="username"
				autofocus
				v-model="account.email"
				placeholder="Your email"
				required
			/>
		</div>

		<div v-if="loginType === 'code'" class="form-group mb-2">
			<p v-if="codeSent" class="text-muted text-center mb-3">
				We sent you a login link. Please check your inbox
			</p>

			<button
				id="resend-code"
				type="button"
				class="btn btn-sm float-end"
				@click="resendCode"
				aria-label="Resend login code"
			>
				Re-send code
			</button>
			<label for="code" class="form-label">Login code</label>
			<input
				type="text"
				class="form-control"
				id="code"
				autocomplete="one-time-code"
				v-model="account.code"
				placeholder="Paste login code"
				required
			/>
		</div>
		<div v-else-if="loginType === 'password'">
			<div class="form-group mb-2">
				<button
					id="toggle-password"
					type="button"
					class="btn btn-sm float-end"
					@click="togglePassword"
					aria-label="Show password as plain text. Warning: this will display your password on the screen."
				>
					Show password
				</button>
				<label for="password" class="form-label">Password</label>
				<input
					type="password"
					class="form-control"
					id="password"
					v-model="account.password"
					autocomplete="current-password"
					placeholder="Your password"
					required
					minlength="6"
				/>
			</div>
		</div>

		<div class="form-group d-grid">
			<button class="btn btn-primary" id="login-btn" :disabled="state === 'loading'">
				Continue
			</button>
		</div>

		<div v-if="loginType === 'password'" class="mt-5">
			<hr class="mb-4" />

			<p class="text-center mb-1">
				<button class="btn btn-sm" @click="resendCode">Login with link instead</button>
			</p>
		</div>
	</form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'

import { identify } from '@/utils.js'
import { validateEmail } from '../lib/strings.js'

export default {
	name: 'LoginForm',
	emits: ['loginStart', 'loginSuccess', 'loginError'],
	props: {
		redirectUrl: String,
		loginMessage: String,
		registerMessage: String,
		allowRegistration: Boolean,
	},
	data() {
		return {
			state: 'idle',
			codeSent: false,
			loginType: 'email',
			account: {
				email: '',
				code: '',
				password: '',
				preferLink: false,
				allowRegistration: false,
			},
			error: '',
			info: '',
			isLoginFromLink: false,
			$loginBtn: null,
		}
	},
	computed: {
		...mapState(['j']),
		...mapGetters(['auth']),
	},
	created() {
		if (this.allowRegistration) {
			this.account.allowRegistration = true
		}

		if (this.j) {
			this.account.jurisdictionId = this.j.id
		}

		if (this.auth) {
			// person is already logged in
			this.$router.push(this.$route.query.redirect || '/')
		} else if (this.$route.query.email && this.$route.query.code) {
			this.loginType = 'code'
			this.account.email = atob(this.$route.query.email)
			this.account.code = this.$route.query.code
			this.isLoginFromLink = true
			this.login()
		}
	},
	mounted() {
		this.$loginBtn = document.getElementById('login-btn')
	},
	methods: {
		togglePassword($event) {
			const $password = document.getElementById('password')

			if ($password.type === 'password') {
				$password.type = 'text'
				$event.target.textContent = 'Hide password'
				$event.target.setAttribute('aria-label', 'Hide password.')
			} else {
				$password.type = 'password'
				$event.target.textContent = 'Show password'
				$event.target.setAttribute(
					'aria-label',
					'Show password as plain text. ' + 'Warning: this will display your password on the screen.'
				)
			}
		},
		resendCode() {
			this.loginType = 'link'
			this.account.code = ''
			this.account.password = ''
			this.account.preferLink = true
			this.login()
		},
		login() {
			this.error = ''

			try {
				this.account.email = validateEmail(this.account.email)
			} catch (error) {
				this.error = `Email error: ${error.message}`
				return
			}

			this.state = 'loading'
			this.$emit('loginStart', this.account)

			this.$store.dispatch('authRequest', this.account).then(
				({ data }) => {
					this.state = 'idle'

					// This is a successful authentication
					if (data.auth) {
						this.$emit('loginSuccess', data)

						if (data.action === 'login' && this.loginMessage) {
							Vue.toasted.success(this.loginMessage)
						} else if (data.action === 'register' && this.registerMessage) {
							Vue.toasted.success(this.registerMessage)
						}

						let goTo

						if (this.redirectUrl) {
							goTo = this.redirectUrl
						} else if (this.j || data.jurisdictions.length === 1) {
							goTo = this.$route.query.redirect || '/'
						} else {
							goTo = { path: '/select-municipality', query: this.$route.query }
						}

						// change url only if need to go to a different page
						if (window.location.pathname !== goTo) {
							this.$router.push(goTo)
						}

						// identify for app events
						identify(this.auth)
					} else if (data?.loginType === 'code') {
						this.loginType = data.loginType
						this.codeSent = true

						setTimeout(() => {
							// wait a bit until html elements are rendered
							this.$loginBtn.innerText = `Login with code`
							document.getElementById('code').focus()
							Vue.toasted.show('Login code is sent to your inbox')
						}, 100)
					} else if (data.loginType) {
						this.loginType = data.loginType

						setTimeout(() => {
							// wait a bit until html elements are rendered
							this.$loginBtn.innerText = `Login with password`
							document.getElementById('password').focus()
						}, 100)
					}
				},
				error => {
					if (this.isLoginFromLink && error.response.data.detail.includes('invalid')) {
						this.isLoginFromLink = false

						this.info = 'The login link you used expired, and we just sent you a new one over email'
						this.account.code = ''
						this.login()
					} else {
						this.state = 'error'
						this.error = error.response ? error.response.data.detail : error.message
						console.error(error)
					}

					this.$emit('loginError', error)
				}
			)
		},
	},
	watch: {
		'account.email'() {
			if (this.loginType !== 'email') {
				this.loginType = 'email'
				this.codeSent = false
				this.$loginBtn.innerText = `Continue`
			}
		},
	},
}
</script>
