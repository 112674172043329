<template>
	<div class="venues-page">
		<div v-if="states.venues === 'loaded' && venues.length">
			<div v-if="['ADMIN', 'EDITOR'].includes(currentRole)" class="d-none d-md-block d-lg-block mb-5">
				<div class="row align-items-center mb-2">
					<div class="col">
						<h4 class="my-0 d-inline-block">Latest bookings</h4>
						<router-link :to="`/${j.slug}/venues/bookings`" class="btn btn-link btn-sm ms-1">
							View all →
						</router-link>
					</div>
					<div class="col-auto">
						<!-- TODO: Add reservation modal and functionality -->
						<button class="btn btn-primary btn-sm btn-big-icon" disabled>
							<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add reservation
						</button>
					</div>
				</div>

				<div class="card">
					<div class="card-body">
						<div class="card-table">
							<table class="table table-hover">
								<thead>
									<tr>
										<th class="d-none d-md-table-cell">Venue</th>
										<th class="d-none d-md-table-cell">Date</th>
										<th>Guest</th>
										<th class="text-center">Status</th>
									</tr>
								</thead>
								<tbody v-if="states.venues !== 'loading' || states.bookings !== 'loading'">
									<router-link
										tag="tr"
										v-for="booking in venueBookings"
										:key="booking.id"
										:to="
											`/${j.slug}/venues/${
												venues.find(v => v.id == booking.venue_id).slug
											}/bookings/${booking.uuid}`
										"
										class="cursor-pointer"
									>
										<td>
											{{ venues.find(v => v.id === booking.venue_id).name }}
										</td>
										<td>
											<venue-booking-datetime :venueBooking="booking" />
										</td>
										<td>
											<person-link :id="booking.person_id" :avatar="16">{{
												booking.person_id
											}}</person-link>
										</td>
										<td class="text-center">
											<venue-booking-status :venueBooking="booking"></venue-booking-status>
										</td>
									</router-link>

									<tr v-if="!venueBookings.length">
										<td colspan="4" class="text-center py-3">
											<span class="text-muted">Awaiting reservations</span>
										</td>
									</tr>
								</tbody>
								<tfoot v-else>
									<tr>
										<td colspan="4" class="text-center py-4">
											<span class="spinner-border" role="status"></span>
										</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
			</div>

			<!-- Mitch pov bookings -->
			<div v-if="auth" class="mb-5">
				<h4>Your bookings</h4>

				<div class="card mb-3">
					<div class="card-body p-3">
						<div
							class="row align-items-center cursor-pointer"
							@click="states.ownBookingsUpcoming = !states.ownBookingsUpcoming"
						>
							<div class="col">
								<h6 class="my-0">Upcoming bookings</h6>
							</div>
							<div class="col-auto">
								<span class="badge text-success-lighter bg-success">
									{{ ownVenueBookingsUpcoming.length }}
									<font-awesome-icon :icon="['fas', 'chevron-down']" class="ms-1"
								/></span>
							</div>
						</div>

						<div v-if="states.venues === 'loaded' && states.ownBookingsUpcoming">
							<router-link
								v-for="(booking, index) in ownVenueBookingsUpcoming"
								:key="`own-venue-booking-${booking.id}`"
								:to="
									`/${j.slug}/venues/${
										venues.find(v => booking.venue_id === v.id).slug
									}/bookings-info/${booking.uuid}`
								"
								class="d-block text-dark my-3 border-light pb-3"
								:class="{
									'border-bottom': index < ownVenueBookingsUpcoming.length - 1,
								}"
							>
								<h6>{{ venues.find(v => booking.venue_id === v.id).name }}</h6>

								<div class="row">
									<div class="col-6">
										<venue-booking-status :venueBooking="booking" />
									</div>
									<div class="col-6 text-end">
										<small>
											{{
												formatDistanceToNow(new Date(booking.starts_at), { addSuffix: true })
											}}</small
										>
									</div>
								</div>
							</router-link>
						</div>
					</div>
				</div>

				<div class="card mb-4">
					<div class="card-body p-3">
						<div
							class="row align-items-center cursor-pointer"
							@click="states.ownBookingsPast = !states.ownBookingsPast"
						>
							<div class="col">
								<h6 class="my-0">Past bookings</h6>
							</div>
							<div class="col-auto">
								<span class="badge text-info-lighter bg-info">
									{{ ownVenueBookingsPast.length }}
									<font-awesome-icon :icon="['fas', 'chevron-down']" class="ms-1"
								/></span>
							</div>
						</div>

						<div v-if="states.venues === 'loaded' && states.ownBookingsPast">
							<router-link
								v-for="(booking, index) in ownVenueBookingsPast"
								:key="`own-venue-booking-${booking.id}`"
								:to="
									`/${j.slug}/venues/${
										venues.find(v => booking.venue_id === v.id).slug
									}/bookings-info/${booking.uuid}`
								"
								class="d-block text-dark my-3 border-light pb-3"
								:class="{
									'border-bottom': index < ownVenueBookingsPast.length - 1,
								}"
							>
								<h6>{{ venues.find(v => booking.venue_id === v.id).name }}</h6>

								<div class="row">
									<div class="col-6">
										<venue-booking-status :venueBooking="booking" />
									</div>
									<div class="col-6 text-end">
										<small>
											{{
												formatDistanceToNow(new Date(booking.starts_at), { addSuffix: true })
											}}</small
										>
									</div>
								</div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<!-- END Mitch pov bookings -->

			<div class="row">
				<div class="col mb-5">
					<div class="d-flex mb-3 justify-content-between align-items-center">
						<h4 class="my-0">Venues</h4>

						<div>
							<!-- <button
								v-if="['ADMIN', 'EDITOR'].includes(currentRole)"
								class="btn btn-sm btn-big-icon me-2 d-none d-md-inline-block"
								:class="venues.length ? 'btn-outline-primary' : 'btn-primary'"
								data-bs-toggle="modal"
								data-bs-target="#venue-group-add"
							>
								<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add venue group
							</button> -->

							<button
								v-if="['ADMIN', 'EDITOR'].includes(currentRole)"
								class="btn btn-sm btn-big-icon d-none d-md-inline-block"
								:class="venues.length ? 'btn-outline-primary' : 'btn-primary'"
								data-bs-toggle="modal"
								data-bs-target="#venue-add"
							>
								<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add venue
							</button>
						</div>
					</div>

					<div v-for="venueGroup in availableVenueGroups" :key="venueGroup.id">
						<router-link
							:to="`/${j.slug}/venues/group/${venueGroup.slug}`"
							class="card card-link card-venue-group rounded-1 p-2 text-dark mb-3"
						>
							<div class="card-image">
								<img
									:src="
										venueGroup.image
											? getPublicFileUrl(venueGroup.image)
											: '/images/placeholder.jpg'
									"
									class="card-img-top fit-cover rounded-1"
									height="100"
									:alt="venueGroup.name"
								/>
							</div>

							<div class="card-body px-0 pt-2 pb-1">
								<h5 class="card-title my-0">
									{{ venueGroup.name }}

									<span
										v-if="venueGroup.status !== 'public'"
										class="badge bg-warning-50 text-warning-400"
										>{{ venueGroup.status }}</span
									>
								</h5>
							</div>
						</router-link>
					</div>

					<div
						v-if="availableVenues.length"
						class="row g-3 row-cols row-cols-2"
						:class="{ 'row-cols-lg-3 row-cols-xl-4': currentRole === 'CITIZEN' }"
					>
						<div class="col" v-for="venue in availableVenues" :key="`${venue.id}`">
							<venue-card :venue="venue"></venue-card>
						</div>
					</div>
					<div v-else class="text-center">
						<p class="mt-2">No venues for your {{ j.type }} yet 😕</p>
					</div>
				</div>

				<div v-if="['ADMIN', 'EDITOR'].includes(currentRole)" class="col-lg-6 mb-5">
					<h4 class="my-0 mb-4">Reservation Calendar</h4>
					<div class="card ps-0 pe-0">
						<div class="card-body p-3">
							<VenuesCalendar :venues="venues" :options="calendarOptions" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else-if="states.venues === 'loaded' && !venues.length" class="row justify-content-center">
			<div class="col-md-10 col-lg-8">
				<div class="card text-center">
					<div v-if="['ADMIN', 'EDITOR'].includes(currentRole)" class="card-body">
						<h3 class="mt-0 mb-3">HeyReserve</h3>
						<img
							src="https://files.heygov.com/illustrations/illustration-building-people.jpg"
							width="300"
							class="mb-3"
						/>
						<p class="lead">
							Create the first venue in your {{ j.type }} to start accepting online reservations
						</p>
						<p>
							<button
								class="btn btn-primary btn-big-icon"
								data-bs-toggle="modal"
								data-bs-target="#venue-add"
							>
								<font-awesome-icon :icon="['fas', 'plus-circle']" /> Add venue
							</button>
						</p>
					</div>
					<div v-else class="card-body">
						<img
							src="https://files.heygov.com/illustrations/illustration-building-people.jpg"
							width="300"
							class="mb-3"
						/>
						<p class="lead">Online venue reservations are coming soon for your {{ j.type }}!</p>
					</div>
				</div>
			</div>
		</div>

		<!-- Add Venue Modal -->
		<div class="modal fade" id="venue-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<form @submit.prevent="addVenue">
						<div class="modal-header">
							<h5 class="modal-title">Create Venue</h5>

							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label class="form-label" for="name">Name <span class="text-danger">*</span></label>
								<input
									class="form-control"
									id="name"
									v-model="newVenue.name"
									placeholder="Venue name"
									required
								/>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="venue-add-location"
									>Location <span class="text-danger">*</span></label
								>
								<gmap-autocomplete
									v-if="newVenue"
									@place_changed="setLocation"
									:options="autocompleteOptions"
								>
									<template v-slot:default="slotProps">
										<input
											id="venue-add-location"
											ref="input"
											:value="newVenue.location.name"
											placeholder="Venue address"
											class="form-control"
											required
											v-on="slotProps.listeners"
										/>
									</template>
								</gmap-autocomplete>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="venue-department"
									>Department <span class="text-danger">*</span></label
								>
								<select
									class="form-select"
									id="venue-department"
									v-model="newVenue.department_id"
									required
								>
									<option value="">Select a department</option>
									<option
										v-for="department in activeDepartments"
										:key="`venue-dept-${department.id}`"
										:value="department.id"
										>{{ department.name }}</option
									>
								</select>
								<span class="form-text"
									>ℹ️ Online and in person payments will be routed to this department</span
								>
							</div>

							<div class="form-group mb-3">
								<label class="form-label" for="venue-group">Group</label>
								<select class="form-select" id="venue-group" v-model="newVenue.venue_group_id">
									<option :value="null">No group</option>
									<option
										v-for="venueGroup in venueGroups"
										:key="venueGroup.id"
										:value="venueGroup.id"
										>{{ venueGroup.name }}</option
									>
								</select>
							</div>

							<!-- <div class="form-group mb-3">
								<label class="form-label" for="description">Description</label>
								<textarea
									rows="3"
									class="form-control"
									id="description"
									v-model="newVenue.description"
									placeholder="Short description"
								/>
							</div> -->
						</div>
						<div class="modal-footer">
							<button class="btn btn-primary">Create venue</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- Add event Modal -->
		<div class="modal fade" id="event-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<form @submit.prevent="addEvent">
						<div class="modal-header">
							<h5 class="modal-title">Create Event</h5>
							<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div class="modal-body">
							<div class="form-group mb-3">
								<label class="form-label" for="name">Name <span class="text-danger">*</span></label>
								<input
									class="form-control"
									id="name"
									v-model="newEvent.name"
									placeholder="Event name"
									required
								/>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="type"
									>Event Type <span class="text-danger">*</span></label
								>
								<select class="form-select" id="type" v-model="newEvent.type" required>
									<option value="">Select type</option>
									<option value="maintenance">Maintenance</option>
									<option value="not-working">Not working</option>
									<option value="other">Other</option>
								</select>
							</div>
							<div class="form-group mb-3">
								<label class="form-label" for="venue">Venue <span class="text-danger">*</span></label>
								<select
									class="form-select"
									@change="loadSlots(newEvent.venue_id)"
									id="venue"
									v-model="newEvent.venue_id"
									required
								>
									<option value="">Select a venue</option>
									<option v-for="venue in venues" :key="`form-venue-${venue.id}`" :value="venue.id">{{
										venue.name
									}}</option>
								</select>
							</div>
							<div v-if="newEvent.venue_id" class="form-group mb-3">
								<label class="form-label" for="venue"
									>Venue slot <span class="text-danger">*</span></label
								>
								<select class="form-select" id="venue" v-model="newEvent.venue_slot_id" required>
									<option value="">Select slot</option>
									<option
										v-for="slot in newEventActiveSlots"
										:key="`form-slot-${slot.id}`"
										:value="slot.id"
										>{{ slot.name }}</option
									>
								</select>
							</div>
							<div class="form-group mb-3" v-if="newEvent.venue_slot_id">
								<label class="form-label" for="event-date"
									>Date <span class="text-danger">*</span></label
								>
								<input type="date" class="form-control" id="event-date" v-model="newEvent.date" />
							</div>
						</div>
						<div class="modal-footer text-end">
							<button class="btn btn-primary">Create Event</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<style>
/**Only like this works in modal **/
.pac-container {
	z-index: 100000 !important;
}
</style>
<style lang="scss" scoped>
.starting-price {
	font-size: 12px;
}
.venue-mobile-image {
	border-radius: 8px;
	height: 111px;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import { Modal } from 'bootstrap'
import { addHours, format, addMinutes, formatDistanceToNow } from 'date-fns'

import heyGovApi, { handleResponseError } from '@/api.js'
import { getPublicFileUrl } from '@/utils.js'
import { venueBookingStatuses } from '@/actions/venues.js'

import VenueCard from '@/components/venues/VenueCard.vue'
import VenueBookingStatus from '@/components/venues/VenueBookingStatus.vue'
import VenueBookingDatetime from '@/components/venues/VenueBookingDatetime.vue'
import PersonLink from '@/components/PersonLink.vue'
import VenuesCalendar from '@/components/venues/VenuesCalendar'

export default {
	components: { VenueCard, VenueBookingStatus, VenueBookingDatetime, PersonLink, VenuesCalendar },
	name: 'Venues',
	data() {
		return {
			states: {
				venues: 'loading',
				bookings: 'loading',
				ownBookingsUpcoming: false,
				ownBookingsPast: false,
			},
			venues: [],
			newVenue: {
				name: '',
				description: '',
				department_id: '',
				venue_group_id: this.$route.query.venue_group_id || null,
				location: {},
			},
			autocompleteOptions: {
				componentRestrictions: { country: 'us' },
				fields: ['formatted_address', 'name', 'geometry', 'place_id', 'url', 'icon', 'icon_mask_base_uri'],
			},

			venueBookingStatuses,
			venueBookings: [],
			ownVenueBookings: [],

			newEvent: {
				name: '',
				type: '',
				venue_id: null,
				booking_id: null,
				venue_slot_id: null,
				person_id: 1,
				starts_at: '',
				ends_at: '',
				date: '',
			},
			newEventActiveSlots: [],
			events: [],
			calendarOptions: {
				todayBtn: true, // Display button that returns you to today date
				timezone: '', // Added after
			},
		}
	},
	computed: {
		...mapState(['j', 'authPerson', 'venueGroups']),
		...mapGetters(['auth', 'currentRole', 'activeDepartments']),
		availableVenues() {
			let venues = this.venues
				.filter(v => !v.venue_group_id) // not in groups
				.filter(v => v.status !== 'archived') // not archived

			if (this.currentRole === 'CITIZEN') {
				venues = venues.filter(v => v.status === 'public')
			}

			return venues
		},
		availableVenueGroups() {
			let groups = this.venueGroups.filter(g => g.status !== 'archived')

			if (this.currentRole === 'CITIZEN') {
				groups = groups.filter(g => g.status === 'public')
			}

			return groups
		},
		ownVenueBookingsUpcoming() {
			return this.ownVenueBookings.filter(b => ['pending', 'approved', 'progress'].includes(b.status))
		},
		ownVenueBookingsPast() {
			return this.ownVenueBookings.filter(b => ['ended', 'completed'].includes(b.status))
		},
	},
	created() {
		this.calendarOptions.timezone = this.j.timezone
		this.$store.dispatch('loadDepartments')
		this.$store.dispatch('getVenueGroups')
		this.loadAllVenues()

		if (this.auth) {
			this.loadOwnVenueBookings()

			if (this.currentRole !== 'CITIZEN') {
				this.loadVenueBookings()
			}
		}
	},
	mounted() {
		this.$modalVenue = new Modal(document.getElementById('venue-add'))
		this.$modalEvent = new Modal(document.getElementById('event-add'))

		if (this.$route.query.action === 'create-venue') {
			this.$modalVenue.show()
		}
	},
	methods: {
		formatDistanceToNow,
		getPublicFileUrl,

		loadAllVenues() {
			this.states.venues = 'loading'

			heyGovApi.get(`/${this.j.slug}/venues`).then(({ data }) => {
				this.venues.push(...data)
				this.states.venues = 'loaded'
			}, handleResponseError(`Error loading venues ({error})`))
		},

		setLocation(place) {
			this.newVenue.location.name = place.name
			this.newVenue.location.address = place.formatted_address
			this.newVenue.location.place_id = place.place_id
			this.newVenue.location.lat = place.geometry.location.lat()
			this.newVenue.location.lng = place.geometry.location.lng()
			this.newVenue.location.url = place.url
			this.newVenue.location.icon = place.icon
			this.newVenue.location.icon_svg = `${place.icon_mask_base_uri}.svg`
		},
		addVenue() {
			heyGovApi
				.post(`/${this.j.slug}/venues`, {
					name: this.newVenue.name,
					description: this.newVenue.description,
					department_id: this.newVenue.department_id,
					venue_group_id: this.newVenue.venue_group_id,
					location: this.newVenue.location,
				})
				.then(({ data }) => {
					this.venues.push(data)
					this.$modalVenue.hide()
					this.$router.push(`/${this.j.slug}/venues/${data.slug}?created=true`)
				}, handleResponseError(`Error creating venue ({error})`))
		},

		addEvent() {
			this.newEvent.date = new Date(this.newEvent.date + ' 00:00:00')
			this.newEvent.starts_at = this.newEventActiveSlots.find(
				slot => (slot.id = this.newEvent.venue_slot_id)
			).starts_at
			this.newEvent.ends_at = this.newEventActiveSlots.find(
				slot => (slot.id = this.newEvent.venue_slot_id)
			).ends_at
			this.newEvent.starts_at = this.addTimeToDate(this.newEvent.date, this.newEvent.starts_at)
			this.newEvent.ends_at = this.addTimeToDate(this.newEvent.date, this.newEvent.ends_at)

			this.events.push(this.newEvent)
			this.newEvent = {
				name: '',
				type: '',
				venue_id: null,
				booking_id: null,
				venue_slot_id: null,
				person_id: null,
				starts_at: '',
				ends_at: '',
				date: '',
			}
			this.newEventActiveSlots = []
			this.$modalEvent.hide()
		},

		// Add Time to Chosen date
		addTimeToDate(date, time) {
			let hours = parseInt(time.substring(0, 2))
			let minutes = parseInt(time.substring(3, time.length))
			return format(addMinutes(addHours(new Date(date), hours), minutes), 'Y-M-d HH:mm:ss')
		},

		loadVenueBookings() {
			this.states.bookings = 'loading'

			heyGovApi.get(`/${this.j.slug}/venue-bookings?perPage=5`).then(
				({ data }) => {
					this.venueBookings.push(...data.slice(0, 5))
					this.states.bookings = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading reservations (${error})`)
					this.states.bookings = 'error'
				}
			)
		},

		loadOwnVenueBookings() {
			heyGovApi.get(`/${this.j.slug}/venue-bookings?own=1&perPage=50`).then(({ data }) => {
				this.ownVenueBookings.push(...data)
			}, handleResponseError(`Error loading your bookings ({error})`))
		},
	},
}
</script>
