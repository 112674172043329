<template>
	<div v-if="!currentField.pdf" :class="{ 'input-group': currentField.data.prepend || currentField.data.append }">
		<span v-if="currentField.data.prepend" class="input-group-text">
			{{ currentField.data.prepend }}
		</span>

		<input
			:class="'form-control ' + currentField.class"
			v-model="currentField.value"
			:placeholder="currentField.placeholder"
			:id="'fsf-' + currentField.id"
			:required="currentField.required == 1"
		/>

		<span v-if="currentField.data.append" class="input-group-text">
			{{ currentField.data.append }}
		</span>
	</div>
	<!-- PDF View -->
	<div v-else>
		<div v-if="currentField.display !== 'form'">
			<label class="pdf-label form-label" for="">{{ currentField.label }}</label>
			<div v-if="this.$route.query.redacted && currentField.redacted" class="pdf-redacted"></div>
			<div v-else class="d-flex">
				<span v-if="currentField.data.prepend" class="mt-2">
					{{ currentField.data.prepend }}
				</span>
				<div class="pdf-field w-100 mx-1">
					{{ currentField.value }}
				</div>
				<span v-if="currentField.data.append" class="mt-2">
					{{ currentField.data.append }}
				</span>
			</div>
		</div>
		<small v-if="currentField.help" class="text-muted ps-1 pdf-help">{{ currentField.help }}</small>
	</div>
</template>

<script>
export default {
	name: 'TextInput',
	props: ['currentField'],
	watch: {
		'currentField.value'() {
			this.$emit('update', { field: this.currentField, value: this.currentField.value })
		},
	},
}
</script>
