<template>
	<div :class="{ 'modal-content': isForModal }">
		<form @submit.prevent="handleSubmit">
			<div v-if="isForModal" class="modal-header">
				<h5 class="modal-title my-0">Edit agenda item</h5>
				<button @click.prevent="$emit('close')" type="button" class="btn-close" aria-label="Close"></button>
			</div>
			<div :class="{ 'modal-body': isForModal }">
				<div class="form-row mb-3">
					<label class="form-label" for="agenda-item-title"
						>Agenda item <small class="text-danger-400">*</small></label
					>
					<input
						type="text"
						class="form-control"
						id="agenda-item-title"
						v-model="item.title"
						required
						minlength="3"
						placeholder="What should be discussed?"
					/>
				</div>

				<div class="form-row mb-3">
					<label class="form-label" for="agenda-item-text">Text / Description</label>
					<textarea
						class="form-control"
						id="agenda-item-text"
						v-model="item.text"
						placeholder="More information about this agenda item (optional)"
					></textarea>
				</div>

				<div v-if="isForModal" class="form-row mb-3">
					<label class="form-label" for="agenda-item-internal-note">Internal note</label>
					<textarea
						class="form-control"
						id="agenda-item-internal-note"
						v-model="item.internal_note"
						placeholder="Comment that only logged in clerks see, to share notes about an agenda item (optional)"
					></textarea>
				</div>

				<div class="form-group mb-3">
					<label for="speaker-presenter" class="form-label">Speaker / Presenter</label>
					<div v-if="item.speaker_id">
						<person-link :id="item.speaker_id" :avatar="24" />
						<button class="btn btn-sm btn-outline-secondary ms-2" @click.prevent="clearSpeaker">
							Unlink speaker
						</button>
					</div>
					<div v-else>
						<people-search
							id="speaker-presenter"
							:limit="5"
							:required="false"
							:placeholder="'Name/email/phone of a person from jurisdiction'"
							@person="setSpeaker"
						></people-search>
					</div>
				</div>

				<div class="form-row">
					<label class="form-label" for="agenda-item-file">Attachments (documents or other files)</label>
					<draggable
						v-model="item.files"
						group="files"
						class="row row-cols-3 gap-0"
						:options="{ draggable: '.file-path-preview' }"
					>
						<div v-for="filePath in item.files" :key="filePath" class="col mb-3 file-path-preview">
							<file-path-preview :filePath="filePath" @remove="removeExistingFile" />
						</div>
						<div class="col mb-3">
							<label
								class="d-block bg-light border border-dashed rounded-1 p-3 text-center cursor-pointer position-relative"
							>
								<font-awesome-icon :icon="['fas', 'file']" class="text-primary mb-2" />
								<p class="mb-0">Upload file</p>
								<input
									type="file"
									class="form-control d-none"
									id="agenda-item-file"
									@change="addNewFile"
									multiple
									:disabled="state.loading || state.fileUploading"
								/>
								<div
									v-if="state.fileUploading"
									class="position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-white bg-opacity-75"
								>
									<div class="spinner-border text-primary" role="status">
										<span class="visually-hidden">Loading...</span>
									</div>
								</div>
							</label>
						</div>
						<div v-if="isForModal" class="col mb-3 d-flex align-items-center">
							<button
								class="btn btn-sm btn-outline-primary d-flex align-items-center"
								@click.prevent="$emit('request-file')"
							>
								<font-awesome-icon :icon="['fas', 'paper-plane']" class="me-2" />
								Request files
							</button>
						</div>
					</draggable>
				</div>
			</div>
			<div v-if="isForModal" class="modal-footer">
				<button :disabled="state.loading || state.fileUploading" class="btn btn-primary">Save</button>
			</div>
			<div v-else>
				<button :disabled="state.loading || state.fileUploading" class="btn btn-primary">
					Update agenda item
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import draggable from 'vuedraggable'

import PeopleSearch from '@/components/PeopleSearch.vue'
import FilePathPreview from '@/components/FilePathPreview.vue'
import PersonLink from '@/components/PersonLink'

export default {
	components: { PeopleSearch, FilePathPreview, PersonLink, draggable },
	props: {
		defaultItem: { type: Object, required: true },
		// HACK: isForModal is not best solution, it would be better to have a different component configuration.
		// This is actually used to determine if it's used from one page or another.
		isForModal: { type: Boolean, required: true },
	},
	data() {
		return {
			item: {
				id: this.defaultItem.id,
				parent_id: this.defaultItem.parent_id,
				title: this.defaultItem.title,
				text: this.defaultItem.text,
				order: this.defaultItem.order,
				internal_note: this.defaultItem.internal_note,
				speaker_id: this.defaultItem.speaker_id,
				files: this.defaultItem.files,
			},
			state: {
				loading: false,
				fileUploading: false,
			},
		}
	},
	watch: {
		defaultItem(newVal) {
			this.item = { ...newVal }
		},
	},
	methods: {
		setSpeaker(speaker) {
			this.item.speaker_id = speaker.id
		},
		clearSpeaker() {
			this.item.speaker_id = null
		},
		handleSubmit() {
			this.state.loading = true
			this.$emit(
				'submit',
				{
					id: this.item.id,
					parent_id: this.item.parent_id,
					title: this.item.title,
					text: this.item.text,
					internal_note: this.item.internal_note,
					speaker_id: this.item.speaker_id,
					files: this.item.files,
				},
				() => {
					this.state.loading = false
				}
			)
		},
		addNewFile(event) {
			this.state.fileUploading = true
			this.$emit(
				'file-upload',
				{
					itemId: this.item.id,
					files: event.target.files,
				},
				() => {
					this.state.fileUploading = false
				}
			)
		},
		removeExistingFile(file) {
			this.item.files = this.item.files.filter(f => f !== file)
		},
	},
}
</script>
