<template>
	<div class="home py-5">
		<h3 class="mb-3 text-center">So you forgot the password 😐</h3>

		<div class="row justify-content-center">
			<div class="col-8 col-md-6 col-lg-4">
				<div class="card">
					<div class="card-body">
						<form @submit.prevent="resetPass">
							<p class="mb-4">or <router-link to="/account/login">Log in</router-link></p>

							<div v-if="success" class="alert alert-success" role="alert">{{ success }}</div>
							<div v-if="info" class="alert alert-info" role="alert">{{ info }}</div>

							<div class="form-group">
								<label for="email">Email</label>
								<input
									type="email"
									class="form-control"
									id="email"
									v-model="account.email"
									autocomplete="username"
									placeholder="Your email"
									required
								/>
							</div>

							<button class="btn btn-primary" :disabled="state === 'loading'">Continue</button>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import heyGovApi from '@/api.js'

export default {
	name: 'AccountResetPass',
	data() {
		return {
			state: 'idle',
			account: {
				email: ''
			},
			info: "Fill your email address, and we'll send a message with instructions for resetting your password.",
			success: ''
		}
	},
	methods: {
		resetPass() {
			this.state = 'loading'
			this.info = ''
			this.success = ''

			heyGovApi
				.post('account/reset-pass', this.account)
				.then(() => {
					this.success =
						'If an account with this email exists, we sent detaild instructions for resetting the password.'
					this.state = 'idle'
				})
				.catch(error => {
					this.state = 'error'
					Vue.toasted.show(error, { type: 'error' })
				})
		}
	}
}
</script>
