import axios from 'axios'
import { isPlainObject } from 'lodash-es'
import store from './store'
import Vue from 'vue'

/**
 * @deprecated
 */
const heyGovApi = axios.create({
	baseURL: store.state.apiUrl,
	//withCredentials: true,
	//timeout: 1000,
	headers: {
		//'access-control-request-headers':	'link, x-total',
		'x-app-version': store.state.appVersion,
	},
})

heyGovApi.interceptors.request.use(config => {
	if (store.state.token) {
		config.headers['Authorization'] = `Bearer ${store.state.token}`
	}

	config.headers['x-app-platform'] = store.state.device.platform

	// TODO remove `_local` field from POST JSON data

	return config
})

heyGovApi.interceptors.response.use(undefined, err => {
	return new Promise(() => {
		if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
			store.dispatch('authLogout')
			Vue.toasted.show(`Auth token not valid anymore`, { type: 'error' })
		}

		throw err
	})
})

export default heyGovApi

/**
 * Fetch data from HeyGov API
 * This function is a wrapper around fetch() with some additional features:
 * - prepend API URL
 * - add required headers
 * - add auth token
 * - stringify JSON body
 * - parse JSON response
 *
 * @param {string} input
 * @param {object} options
 * @returns {Promise}
 */
//todo rename to hgFetch
export function hgApi(input, options = {}) {
	const url = new URL(input, store.state.apiUrl)

	options.headers = new Headers({
		'x-app-platform': store.state.device.platform,
		'x-app-version': store.state.appVersion,
		...(options.headers || {}),
	})

	// handle HeyGov auth
	if (store.state.token) {
		options.headers.set('authorization', `Bearer ${store.state.token}`)
	}

	// handle query params
	if (options.query) {
		Object.keys(options.query).forEach(key => url.searchParams.append(key, options.query[key]))
		delete options.query
	}

	// handle json body, deprecated
	if (options.json) {
		options.body = options.json
		delete options.json
	}

	// helper: change method to POST if body is present
	if (options.body) {
		options.method ||= 'POST'

		// check if body can be stringified
		// TODO do we need this log?
		// console.log('body type', options.body.constructor.name)
		if (Array.isArray(options.body) || isPlainObject(options.body)) {
			options.body = JSON.stringify(options.body)

			if (!options.headers.has('content-type')) {
				options.headers.set('content-type', 'application/json')
			}
		}
	}

	return fetch(url, options)
}

export function handleResponseError(messageTemplate = 'Error encountered {error}') {
	return error => {
		// generic error message
		let msg = messageTemplate.replace('{error}', error.message)

		// specific error message for this endpoint
		if (error.response?.data?.message) {
			msg = error.response.data.message

			if (error.response.data.fields) {
				msg += ': '
				msg += error.response.data.fields.map(field => `${field.param} - ${field.msg}`).join(', ')
			}
		}

		// @ts-ignore
		Vue.toasted.error(msg)
	}
}
