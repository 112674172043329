<template>
	<div v-if="venue" class="page-venue-info">
		<div class="row justify-content-center">
			<div class="col-md-10 col-lg-8 mb-3 pt-0">
				<div class="venue-cover mb-3">
					<img
						:src="venue.main_image || '/images/placeholder.jpg'"
						alt="Venue image"
						class="w-100 rounded-1"
					/>

					<div class="venue-image-layer rounded-1 d-flex align-items-center">
						<div class="p-4">
							<h1 class="venue-name mb-2">{{ venue.name }}</h1>
							<p class="fw-normal venue-description mb-0">
								{{ firstParagraphOrWords(venue.description, 20) }}
							</p>
						</div>
					</div>
				</div>

				<div class="scrolling-tabs">
					<nav class="hide-scrollbar">
						<ul
							class="nav nav-pills nav-pills-sm hide-scrollbar"
							style="flex-wrap: nowrap; min-width: 100%; overflow-x: scroll;"
						>
							<li class="nav-item">
								<span class="nav-link" :class="{ active: tab == 'general' }" @click="tab = 'general'"
									>Availability
								</span>
							</li>
							<li class="nav-item">
								<span class="nav-link" :class="{ active: tab == 'info' }" @click="tab = 'info'"
									>Venue info</span
								>
							</li>
							<li class="nav-item">
								<span class="nav-link" :class="{ active: tab == 'location' }" @click="tab = 'location'"
									>Location</span
								>
							</li>
							<li
								class="nav-item"
								v-if="venue.text_rules || venue.message_check_in || venue.message_check_out"
							>
								<span
									class="nav-link"
									:class="{ active: tab == 'rules-guides' }"
									@click="tab = 'rules-guides'"
									>Rules &amp; guides</span
								>
							</li>
						</ul>
					</nav>
				</div>
			</div>

			<div v-if="tab === 'general'" class="col-md-10 col-lg-8">
				<div class="card mb-3">
					<div class="card-body">
						<div v-if="venue.venue_group_id" class="text-center py-4">
							This venue can be booked through
							<router-link
								:to="
									`/${j.slug}/venues/group/${
										venueGroups.find(g => g.id == venue.venue_group_id).slug
									}/book`
								"
								>{{ venueGroups.find(g => g.id == venue.venue_group_id).name }}</router-link
							>
						</div>

						<VenueCalendarPublic v-else :venue="venue" :options="calendarOptions" />
					</div>
				</div>
			</div>

			<div v-else-if="tab === 'info'" class="col-md-10 col-lg-8">
				<div class="card mb-3">
					<div class="card-body">
						<div v-html="enrichContent(venue.description)" class="mb-3"></div>

						<div class="venue-images mb-4 d-flex flex-wrap gap-2">
							<img
								v-for="(img, index) in venue.images"
								:key="`${img}-${index}`"
								:src="img.src"
								:alt="img.title"
								class="rounded-1 cursor-pointer"
								height="150"
								@click="showImg(index)"
							/>
							<!-- <figure class="figure">
									<img :src="img.src" class="figure-img img-fluid rounded" :alt="img.alt" />
									<figcaption class="figure-caption">{{ img.alt }}</figcaption>
								</figure> -->
						</div>

						<vue-easy-lightbox
							:visible="visible"
							:imgs="venue.images"
							:index="index"
							@hide="handleHide"
						></vue-easy-lightbox>

						<div v-if="venue.amenities_text">
							<h5>Amenities</h5>

							<div v-html="venue.amenities_text"></div>
						</div>
					</div>
				</div>
			</div>

			<div v-else-if="tab === 'location'" class="col-md-10 col-lg-8">
				<div class="card mb-3">
					<div class="card-body">
						<h5 class="mb-3">{{ venue.location.name }}</h5>

						<p><span class="text-neutral-400">Address:</span> {{ venue.location.address }}</p>

						<muni-map
							class="mb-3"
							:center="{ lat: venue.location.lat, lng: venue.location.lng }"
							:height="400"
							:includeDepartments="false"
							:includeVenues="venue.id"
						></muni-map>

						<p class="card-text">
							<a :href="venue.location.url" target="_blank">Open Google Maps</a>
						</p>
					</div>
				</div>
			</div>

			<div v-else-if="tab === 'rules-guides'" class="col-md-10 col-lg-8">
				<div class="card mb-3">
					<div class="card-body">
						<div v-if="venue.message_check_in" class="mb-4">
							<h5>Check in</h5>

							<p class="card-text">{{ venue.message_check_in }}</p>
						</div>

						<div v-if="venue.message_check_in" class="mb-4">
							<h5>Check out</h5>

							<p class="card-text">{{ venue.message_check_out }}</p>
						</div>

						<div v-if="venue.text_rules">
							<h5>Rules &amp; guides</h5>

							<div v-html="venue.text_rules"></div>
						</div>

						<div v-if="venue.public_files.length">
							<h6>Documents &amp; files</h6>

							<ul class="nicer-list">
								<li v-for="file in venue.public_files" :key="`venue-file-${file.file}`">
									<a :href="getPublicFileUrl(file.file)" target="_blank">{{ file.name }}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="tab !== 'general'" class="row justify-content-center">
			<div class="col-md-10 col-lg-8 mb-3 d-grid">
				<button class="btn btn-primary" @click="tab = 'general'">Book venue</button>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.venue-cover {
	position: relative;

	img {
		aspect-ratio: 16 / 9;
		object-fit: cover;
	}
}

.venue-image-layer {
	position: absolute;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	* {
		color: #fff;
	}
}

@media (max-width: 575.98px) {
	.venue-cover {
		margin-left: -0.75rem;
		margin-right: -0.75rem;

		img,
		.venue-image-layer {
			border-radius: 0 !important;
		}
	}
}
</style>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
//import { addHours, format, addMinutes } from 'date-fns'

import heyGovApi from '@/api.js'
import { enrichContent, firstParagraphOrWords, getPublicFileUrl } from '@/utils.js'

import VenueCalendarPublic from '@/components/venues/VenueCalendarPublic'
import MuniMap from '@/components/MuniMap.vue'

export default {
	name: 'VenuePublicPage',
	metaInfo() {
		return {
			title: this.venue?.name || this.$route.params.venueSlug,
		}
	},
	components: { VenueCalendarPublic, MuniMap },
	data() {
		return {
			venue: null,
			tab: this.$route.params.tab || 'general',
			calendarOptions: {
				todayBtn: true, // Display button that returns you to today date
			},
			loadingSlots: false,
			state: 'idle',
			visible: false, // For image preview
			index: 0, // For image preview
		}
	},
	computed: {
		...mapState(['j', 'venueGroups']),
	},
	created() {
		this.$store.dispatch('getVenueGroups')
		this.getVenue()
	},
	methods: {
		enrichContent,
		firstParagraphOrWords,
		getPublicFileUrl,

		getVenue() {
			this.state = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}?expand=images`).then(
				({ data }) => {
					this.venue = data
					this.state = 'idle'
				},
				error => {
					Vue.toasted.error(`Error loading venue info (${error})`)
					this.state = 'error'
				}
			)
		},
		showImg(index) {
			this.index = index
			this.visible = true
		},
		handleHide() {
			this.visible = false
		},
	},
}
</script>
