import { isObject, uniq } from 'lodash-es'
import parsePhoneNumber from 'libphonenumber-js'

import { formatAddress, numberToCurrency } from '@/utils.js'
import { isAddressObject } from '@/lib/geo.js'

// Process answer value for display
// ex: shows radio/checkbox label instead of value,
// or formatted name
const displayFieldAnswer = (field, answer, short = false) => {
	//console.log('1️⃣ displayFieldAnswer', field, answer, short)

	if (answer) {
		if ('RadioButtonElement' === field.type) {
			if (!isObject(answer)) {
				answer = {
					value: answer,
					extra: '',
				}
			}

			const options = field.options
				.filter(option => !option.removed)
				.filter(option => {
					let include = true

					if (short) {
						include = answer.value == option.optionValue
					}

					return include
				})
				.map(option => {
					let value = option.optionLabel

					if (!short) {
						value = `${answer.value == option.optionValue ? '✅' : '⬜️'} ${value}`
					}

					if (
						(option.addAdditionalValue || option.optionValue === 'other') &&
						answer.value == option.optionValue &&
						answer.extra
					) {
						value += ` (${answer.extra})`
					}

					return value
				})

			answer = options.join('  ')
		} else if (field.type === 'CheckboxElement' && Array.isArray(answer.values)) {
			const options = field.options
				.filter(option => !option.removed)
				.filter(option => {
					let include = true

					if (short) {
						include = answer.values.includes(option.optionValue)
					}

					return include
				})
				.map(option => {
					let value = option.optionLabel

					if (!short) {
						value = `${answer.values.includes(option.optionValue) ? '✅' : '⬜️'} ${value}`
					}

					if (
						(option.addAdditionalValue || option.optionValue === 'other') &&
						answer.values.includes(option.optionValue) &&
						answer.extra[option.optionValue]
					) {
						value += ` (${answer.extra[option.optionValue]})`
					}

					return value
				})

			answer = options.join(' ')
		} else if (field.type === 'SelectListElement') {
			const option = field.options.find(opt => opt.optionValue === answer)
			if (option) {
				answer = option.optionLabel
			}
		} else if (field.type === 'PaymentElement') {
			if (answer.status === 'succeeded') {
				answer = `Paid ${numberToCurrency(answer.amount / 100)} with ${answer.payment_method}`
			} else if (answer.status === 'requires_capture') {
				answer = `Authorized ${numberToCurrency(answer.amount / 100)} from ${answer.payment_method}`
			} else if (answer.status === 'requires_payment_method') {
				answer = `Waiting for ${numberToCurrency(answer.price)} payment`
			}
		} else if (field.type === 'NameInputElement') {
			answer = answer
				.split(',')
				.filter(Boolean)
				.join(' ')
		} else if (field.type === 'DatePickerElement') {
			answer = new Date(answer).toLocaleDateString('default', {
				timeZone: 'UTC',
			})
		} else if (field.type === 'PhoneInputElement') {
			const phoneParsed = parsePhoneNumber(answer, 'US')
			answer = ['US', 'CA'].includes(phoneParsed.country)
				? phoneParsed.formatNational()
				: phoneParsed.formatInternational()
		} else if (field.type === 'TableElement') {
			const firstColumn = field.data.tableColumns[0]

			if (firstColumn && answer.length) {
				let values = answer.map(row => row[firstColumn.prop])

				if (firstColumn.inputType === 'date') {
					values = values.map(value =>
						new Date(value).toLocaleDateString('default', {
							timeZone: 'UTC',
						})
					)
				}

				answer = uniq(values).join(', ')
			} else {
				answer = '(empty)'
			}
		} else if (field.type === 'AddressInputElement') {
			if (isAddressObject(answer)) {
				answer = formatAddress(answer)
			} else {
				answer = answer.value || answer
			}
		}
	}

	//console.log('2️⃣ displayFieldAnswer', answer)

	return answer
}

export { displayFieldAnswer }
