import { parse } from 'tldts'

const pluralRulesOrdinal = new Intl.PluralRules('en-US', { type: 'ordinal' })

const numberSuffixes = new Map([
	['one', 'st'],
	['two', 'nd'],
	['few', 'rd'],
	['other', 'th'],
])

export const formatOrdinals = n => {
	const rule = pluralRulesOrdinal.select(n)
	const suffix = numberSuffixes.get(rule)
	return `${n}${suffix}`
}

export function timestampToMinutes(timestamp) {
	const hours = Math.floor(timestamp / 3600)
	const minutes = Math.floor(timestamp / 60) - hours * 60
	const seconds = Math.round(timestamp % 60)

	let ts = `${minutes}:${String(seconds).padStart(2, '0')}`

	if (hours > 0) {
		ts = `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
	}

	return ts
}

const forbiddenEmailDomains = [
	'1.com',
	'email.com',
	'example.com',
	'example.org',
	'example.net',
	'exmaple.com',
	'gmail.cm',
	'gmial.com',
	'gmal.com',
	'gmal.net',
	'gamil.co',
	'gmail.cl',
	'gmail.co',
	'gmail.org',
	'gnail.com',
	'gmail.co.com',
	'gmwil.com',
	'gmqil.com',
	'glail.com',
	'icoud.com',
	'icloud.co',
	'ickoud.com',
	'no.com',
	'test.com',
	'test.org',
	'testing.com',
	'testing.org',
	'yahoo.co',
	'yahooo.com',
	'uahoo.com',
	'hahoo.com',
	'wi.r.com',
	'test124.com',
]
const forbiddenEmails = [
	'a@bc.com',
	'x@x.com',
	'no@gmail.com',
	'nno@gmail.com',
	'test@gmail.com',
	'abc@123.com',
	'unknown@unknown.com',
]
const mispelledEmailDomains = ['gamil.com', 'hotmal.com', 'yaho.com', 'outlook.com', 'tahoo.com', 'tagoo.co']

export function validateEmail(email) {
	email = String(email)
		.trim()
		.toLowerCase()

	if (!email) {
		throw new Error('no email provided')
	}

	if (forbiddenEmails.includes(email) || !/\S+@\S+\.\S+/.test(email)) {
		throw new Error('invalid email format')
	}

	const emailDomain = parse(email)

	if (!emailDomain.domain || !emailDomain.isIcann) {
		throw new Error('invalid email domain')
	}

	if (forbiddenEmailDomains.includes(emailDomain.domain)) {
		throw new Error('blocked email domain')
	}

	if (mispelledEmailDomains.includes(emailDomain.domain)) {
		//throw new Error('mispelled email domain')
	}

	return email
}

// truncate a string to a max length
// if length is longer than limit, add ellipsis symbol at the end
export function truncateString(str, length = 25, replace = '…') {
	if (str?.length > length) {
		str = str.slice(0, length) + replace
	}

	return str
}
