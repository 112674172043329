<template>
	<div class="page-meeting-agenda">
		<div class="xx-row xx-justify-content-center">
			<div class="xx-col-lg-8">
				<div class="card mb-4">
					<div class="card-header">
						<div class="d-flex align-items-center justify-content-between">
							<h5 class="my-0">Agenda</h5>
							<div class="btn-group">
								<button
									class="btn btn-sm btn-outline-primary"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
									:disabled="!meeting.agenda_items.length"
								>
									Export
									<font-awesome-icon class="ms-2" :icon="['fas', 'caret-down']" />
								</button>
								<ul class="dropdown-menu">
									<li>
										<a
											class="dropdown-item"
											:href="`${apiUrl + j.slug}/meetings/${meeting.pid}/agenda-html?print=1`"
											target="_blank"
											>Agenda PDF</a
										>
									</li>
									<li>
										<a
											class="dropdown-item"
											:href="`${apiUrl + j.slug}/meetings/${meeting.pid}/agenda-packet-pdf`"
											target="_blank"
											>Agenda Packet PDF</a
										>
									</li>
								</ul>

								<button
									class="btn btn-sm btn-outline-primary"
									type="button"
									data-bs-toggle="dropdown"
									aria-expanded="false"
									:disabled="!meeting.agenda_items.length"
								>
									Print
									<font-awesome-icon class="ms-2" :icon="['fas', 'caret-down']" />
								</button>
								<ul class="dropdown-menu">
									<li>
										<a class="dropdown-item" @click="alert('Not implemented yet')">Agenda PDF</a>
									</li>
									<li>
										<a class="dropdown-item" @click="alert('Not implemented yet')"
											>Agenda Packet PDF</a
										>
									</li>
								</ul>

								<button class="btn btn-sm btn-outline-primary" @click="alert('Not implemented yet')">
									Share
								</button>

								<button
									v-if="meeting.status !== 'public'"
									class="btn btn-sm btn-outline-primary"
									@click="alert('Not implemented yet')"
									:disabled="!meeting.agenda_items.length"
								>
									Publish
								</button>

								<button class="btn btn-sm btn-outline-primary" @click="openSettingsModal">
									Settings
								</button>
							</div>
						</div>
					</div>

					<div v-if="meeting.agenda_items.length" class="card-body">
						<div v-if="states.agenda_help" class="bg-warning-50 p-3 rounded-1 mb-4">
							<div class="row">
								<div class="col">
									<h6 class="mb-3">
										🙋 Your agenda is now imported! But first, let's review it to make sure it's
										correct.
									</h6>
								</div>
								<div class="col-auto">
									<button class="btn-close" @click="states.agenda_help = false"></button>
								</div>
							</div>

							<p>
								Look through the individual numbered line items below. Check for three things: (1) the
								verbiage is correct; (2) no agenda item is missing; (3) The meeting structure/hierarchy
								is correct.
							</p>

							<hr class="bg-warning-100" />

							<p class="mb-0">
								<small class="badge bg-warning-50 text-warning-300">Pro tip</small> You can re-order and
								indent agenda items by dragging and dropping them.
								<a
									href="https://townweb.notion.site/Create-and-edit-Agenda-items-fb3152b74c3e4699b75c52d473c23618"
									target="clerk-minutes-help"
									>How to manage agendas</a
								>
							</p>
						</div>

						<agenda-items-list
							v-model="agendaItemsOrder"
							:allowApprove="canApproveMeetingItems"
							:requestChangesAllowed="changesAllowed"
							@requestChanges="handleRequestChanges"
							@editItem="handleEditItemClick"
							@removeItem="removeAgendaItem"
							@approve="handleApproveItem"
							class="mb-3"
						></agenda-items-list>

						<p class="card-text text-center">
							<button
								class="btn btn-outline-primary me-3"
								@click="startAgendaItem(0, meeting.agenda_items.filter(i => i.parent_id === 0).length)"
							>
								<font-awesome-icon :icon="['fas', 'plus']" /> Add agenda item
							</button>
							<button
								@click="openRequestItemModal"
								class="btn btn-outline-primary"
								:disabled="!changesAllowed"
							>
								<font-awesome-icon :icon="['fas', 'paper-plane']" class="me-1" /> Request Agenda Item
							</button>
						</p>
					</div>
					<div
						v-else
						class="card-body file-drop text-center py-6"
						@dragover="dragover"
						@dragleave="dragleave"
						@drop="dropMeetingAgendaFile"
					>
						<p class="lead text-neutral-500 mb-4">Let's create the agenda for this meeting</p>

						<p v-if="states.agenda_file_path === 'idle'" class="card-text">
							<button class="btn btn-sm btn-outline-primary" @click="startAgendaItem(0, 0)">
								<font-awesome-icon :icon="['fas', 'plus']" /> Add first agenda item
							</button>
							<span class="mx-3">or</span>
							<button class="btn btn-sm btn-outline-primary" @click="openRequestItemModal">
								<font-awesome-icon :icon="['fas', 'paper-plane']" class="me-1" /> Request Agenda Item
							</button>
							<span class="mx-3">or</span>
							<label for="meeting-agenda-file" class="decoration-underline text-primary cursor-pointer">
								<font-awesome-icon :icon="['fas', 'file-import']" class="me-1" />
								<u>Import existing PDF agenda</u>
							</label>
						</p>
						<p v-else-if="states.agenda_file_path === 'loading'" class="card-text text-info">
							<span class="spinner-border spinner-border-sm me-1"></span> Processing agenda
							<small class="text-neutral-400">(1-2 minutes max)</small>
						</p>

						<div v-if="agendaUploadError" class="alert alert-danger">
							<strong>Agenda processing error:</strong> {{ agendaUploadError }}
						</div>

						<input
							type="file"
							id="meeting-agenda-file"
							class="d-none"
							@change="handleMeetingAgendaFile"
							accept="application/pdf"
						/>
					</div>

					<div
						v-if="meeting.agenda_file_path"
						class="card-footer d-flex align-items-center justify-content-between"
					>
						<a :href="getPublicFileUrl(meeting.agenda_file_path)" target="_blank"
							><font-awesome-icon :icon="['fas', 'file-pdf']" class="me-1" />
							{{ meeting.agenda_file_path.split('/').pop() }}
						</a>
						<button class="btn btn-sm text-danger" @click="meetingAgendaRemove">Remove agenda</button>
					</div>
					<div v-else class="card-footer">
						🙋
						<a
							href="https://townweb.notion.site/Create-and-edit-Agenda-items-fb3152b74c3e4699b75c52d473c23618"
							target="clerk-minutes-help"
							>How to create the best agendas</a
						>
					</div>
				</div>
			</div>
		</div>

		<div class="modal fade" id="modal-agenda-item-add" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<add-agenda-item-form @submit="handleAddItemSubmit" />
			</div>
		</div>

		<div class="modal fade" id="modal-agenda-item-edit" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<edit-agenda-item-form
					v-if="editableAgendaItem"
					:defaultItem="editableAgendaItem"
					:isForModal="true"
					@submit="handleEditItemSubmit"
					@close="handleCloseEditItemForm"
					@request-file="openRequestFileModal"
					@file-upload="handleFileUpload"
				/>
			</div>
		</div>

		<div class="modal fade" id="modal-agenda-item-request" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<request-agenda-item-form
					:title="'Request new agenda item'"
					@submit="handleRequestItemSubmit"
					@close="handleCloseRequestItemForm"
				/>
			</div>
		</div>

		<div class="modal fade" id="modal-agenda-item-request-changes" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<request-agenda-item-changes-form
					v-if="editableAgendaItem"
					:defaultSpeakerId="editableAgendaItem.speaker_id"
					:title="'Request changes for this agenda item'"
					@submit="handleRequestChangesSubmit"
					@close="handleCloseRequestChangesForm"
				/>
			</div>
		</div>

		<div class="modal fade" id="modal-agenda-item-request-file" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<request-agenda-item-form
					@submit="handleRequestFileSubmit"
					@close="handleCloseRequestFileForm"
					:title="'Request files for this agenda item'"
					:defaultText="'Please provide the necessary files for this agenda item.'"
				/>
			</div>
		</div>

		<div class="modal fade" id="modal-meeting-settings" tabindex="-1" aria-hidden="true">
			<div class="modal-dialog">
				<meeting-settings-form
					:departmentId="meeting.department_id"
					@submit="handleSettingsSubmit"
					@close="handleCloseSettingsForm"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { Modal } from 'bootstrap'

import heyGovApi from '@/api.js'
import { getPublicFileUrl, handleResponseError } from '@/utils.js'

import AgendaItemsList from '@/views/Meetings/AgendaItemsList.vue'
import EditAgendaItemForm from '@/views/Meetings/EditAgendaItemForm/EditAgendaItemForm.vue'
import AddAgendaItemForm from '@/views/Meetings/AddAgendaItemForm/AddAgendaItemForm.vue'
import RequestAgendaItemForm from '@/views/Meetings/RequestItemForm/RequestItemForm.vue'
import MeetingSettingsForm from './MeetingSettingsForm.vue'

export default {
	name: 'MeetingAgenda',
	components: {
		AgendaItemsList,
		EditAgendaItemForm,
		AddAgendaItemForm,
		RequestAgendaItemForm,
		RequestAgendaItemChangesForm: RequestAgendaItemForm,
		MeetingSettingsForm,
	},
	metaInfo() {
		return {
			title: `Agenda for ${this.meeting?.title || this.$route.params.meetingId} - Meetings`,
		}
	},
	props: {
		meeting: {
			type: Object,
			required: true,
		},
		meetingPlayer: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			editableAgendaItem: null,
			states: {
				agenda_file_path: 'idle',
				agenda_help: false,
			},
			agendaUploadError: '',
		}
	},
	computed: {
		...mapState(['j', 'apiUrl']),
		...mapGetters(['auth']),
		agendaItemsOrder: {
			get() {
				console.log('get agendaItemsOrder')

				//todo sort by item.order
				return this.meeting.agenda_items
					.filter(item => item.parent_id === 0)
					.map(item => {
						item.subitems = this.meeting.agenda_items.filter(i => i.parent_id === item.id)

						return item
					})
			},
			set(value) {
				console.log('set agendaItemsOrder', value)

				// re-create agenda_items list in one flat array
				const flat = []
				const level1 = value.map(item => item.id)

				value.forEach((item, index) => {
					item.order = index
					item.parent_id = 0

					const subitems = item.subitems || []
					delete item.subitems

					flat.push(item)

					subitems
						.filter(subitem => !level1.includes(subitem.id))
						.forEach((subitem, index2) => {
							subitem.order = index2
							subitem.parent_id = item.id

							flat.push(subitem)
						})
				})

				this.saveAgendaItemsOrder(flat)

				this.meeting.agenda_items = flat
			},
		},
		canApproveMeetingItems() {
			if (!this.meeting.department_id) {
				return true
			}
			return this.j.roles && this.j.roles.some(role => role.department_id === this.meeting.department_id)
		},
		changesAllowed() {
			const now = new Date()
			const meetingStart = new Date(this.meeting.starts_at)
			if (now > meetingStart) {
				return false
			}
			if (!this.meeting.agenda_cut_off_hours) {
				return true
			}
			const cutoffTime = new Date(meetingStart.getTime() - this.meeting.agenda_cut_off_hours * 60 * 60 * 1000)
			return now < cutoffTime
		},
	},
	mounted() {
		this.$modalAgendaItemAdd = new Modal(document.getElementById('modal-agenda-item-add'))
		this.$modalAgendaItemEdit = new Modal(document.getElementById('modal-agenda-item-edit'))
		this.$modalAgendaItemRequest = new Modal(document.getElementById('modal-agenda-item-request'))
		this.$modalAgendaItemRequestChanges = new Modal(document.getElementById('modal-agenda-item-request-changes'))
		this.$modalAgendaItemRequestFile = new Modal(document.getElementById('modal-agenda-item-request-file'))
		this.$modalMeetingSettings = new Modal(document.getElementById('modal-meeting-settings'))

		if (this.meetingPlayer.position === 'default') {
			this.$emit('playerStyles', {
				zIndex: 99,
				position: 'fixed',
				width: `400px`,
				right: '16px',
				bottom: '16px',
			})
		}
	},
	methods: {
		getPublicFileUrl,
		dragover(event) {
			event.preventDefault()

			if (!event.currentTarget.classList.contains('dragover')) {
				event.currentTarget.classList.add('dragover')
			}
		},
		dragleave(event) {
			event.currentTarget.classList.remove('dragover')
		},
		dropMeetingAgendaFile(event) {
			event.preventDefault()
			this.dragleave(event)

			if (event.dataTransfer.files.length) {
				this.uploadMeetingAgenda(event.dataTransfer.files[0])
			} else {
				alert('No files dropped 🤷')
			}
		},
		handleMeetingAgendaFile($event) {
			this.uploadMeetingAgenda($event.target.files[0])
		},
		validateFileBeforeUpload(file, allowImages = false) {
			const allowedFiles = [
				'application/pdf', // pdf
				'application/msword', // doc
				'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
				'text/plain', // txt
			]

			if (file.size / 1024 / 1024 > 30) {
				return 'File size is too big (max 30MB)'
			}

			const isDoc = allowedFiles.includes(file.type)
			const isImg = file.type.startsWith('image/')

			if (allowImages) {
				if (!isDoc && !isImg) {
					return 'Only document and images files are allowed 🤷'
				}
			} else {
				if (!isDoc) {
					return 'Only document files (PDF, Word) are allowed 🤷'
				}
			}

			return ''
		},
		uploadMeetingAgenda(file) {
			const errStr = this.validateFileBeforeUpload(file, false)
			if (errStr) {
				alert(errStr)
				return
			}

			this.states.agenda_file_path = 'loading'
			this.agendaUploadError = ''

			// prepare file data
			var form = new FormData()
			form.append('file', file)

			heyGovApi
				.post(`${this.j.slug}/meetings/${this.meeting.pid}/upload-agenda-file?await_processing=1`, form)
				.then(
					({ data }) => {
						this.meeting.agenda_file_path = data.agenda_file_path
						this.meeting.agenda_text = data.agenda_text
						this.meeting.agenda_items.push(...data.agenda_items)

						this.states.agenda_help = true
					},
					error => {
						console.log(error.response?.status)

						if (error.response?.status >= 400 && error.response?.status < 600) {
							this.agendaUploadError =
								error.response?.data?.message || error.response?.statusText || error.message
						} else {
							handleResponseError('Error processing agenda ({error})')(error)
						}
					}
				)
				.finally(() => {
					this.states.agenda_file_path = 'idle'
				})
		},
		saveAgendaItemsOrder(agendaItems) {
			const order = agendaItems.map(item => {
				return {
					id: item.id,
					parent_id: item.parent_id,
					order: item.order,
				}
			})

			heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/order`, order).then(() => {
				Vue.toasted.success('Items order is saved')
			}, handleResponseError('Error saving items order ({error})'))
		},
		meetingAgendaRemove() {
			if (confirm('All agenda items and minutes will be removed, is this ok?')) {
				heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/remove-agenda`).then(() => {
					this.meeting.agenda_file_path = null
					this.meeting.agenda_text = ''
					this.meeting.agenda_items = []

					Vue.toasted.show('Agenda is removed')
				}, handleResponseError('Error removing agenda ({error})'))
			}
		},
		startAgendaItem(parent_id, order) {
			this.newItemParentId = parent_id
			this.newItemOrder = order
			this.$modalAgendaItemAdd.show()
		},
		openRequestItemModal() {
			this.$modalAgendaItemRequest.show()
		},
		async handleRequestItemSubmit({ comment, speaker_id, after }) {
			try {
				await heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-item-request`, {
					comment: comment,
					to_speaker_id: speaker_id,
				})
				Vue.toasted.success('Agenda item request submitted successfully')
			} catch (error) {
				handleResponseError('Error submitting agenda item request ({error})')(error)
			} finally {
				after()
				this.$modalAgendaItemRequest.hide()
			}
		},
		handleCloseRequestItemForm() {
			this.$modalAgendaItemRequest.hide()
		},
		async handleAddItemSubmit({ files, ...item }, after) {
			try {
				const { data: created } = await heyGovApi.post(
					`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items`,
					{
						...item,
						parent_id: this.newItemParentId,
						order: this.newItemOrder,
					}
				)
				this.meeting.agenda_items.push(created)
				Vue.toasted.success('Agenda item is added')

				if (!files.length) {
					return
				}

				const form = new FormData()
				for (const file of files) {
					const errStr = this.validateFileBeforeUpload(file, true)
					if (errStr) {
						alert(errStr)
						return
					}
					form.append('files', file)
				}

				const { data: createdFiles } = await heyGovApi.post(
					`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${created.id}/files`,
					form
				)
				created.files.push(...createdFiles)
				Vue.toasted.success('Agenda item files are uploaded')
			} catch (error) {
				handleResponseError('Error adding agenda item ({error})')(error)
			} finally {
				this.newItemParentId = 0
				this.newItemOrder = 0
				this.$modalAgendaItemAdd.hide()
				after()
			}
		},

		handleCloseEditItemForm() {
			this.$modalAgendaItemEdit.hide()
			this.editableAgendaItem = null
		},

		async handleEditItemSubmit({ id, ...fields }, after) {
			try {
				const resp = await heyGovApi.put(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${id}`, {
					...fields,
					status: 'ok', // Auto-approve when edited by meeting manager
				})

				this.meeting.agenda_items = this.meeting.agenda_items.map(item => {
					if (item.id === id) {
						return resp.data
					}
					return item
				})

				Vue.toasted.success('Agenda item updated')
			} catch (error) {
				handleResponseError('Error editing agenda item ({error})')(error)
			} finally {
				after()
				this.handleCloseEditItemForm()
			}
		},

		handleEditItemClick(item) {
			this.editableAgendaItem = item
			this.$modalAgendaItemEdit.show()
		},

		removeAgendaItem(item) {
			const msg = item.subitems?.length
				? '🚨 This agenda item has subitems. Are you sure you want to remove it?'
				: 'For sure remove this agenda item?'

			if (confirm(msg)) {
				heyGovApi.delete(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${item.id}`).then(() => {
					this.meeting.agenda_items = this.meeting.agenda_items.filter(i => i.id !== item.id)
					Vue.toasted.show('Agenda item is removed')
				}, handleResponseError('Error removing agenda item ({error})'))
			}
		},

		async handleRequestChangesSubmit({ comment, speaker_id, after }) {
			try {
				const resp = await heyGovApi.post(
					`${this.j.slug}/meetings/${this.meeting.pid}/agenda-item-request-changes`,
					{
						comment: comment,
						to_speaker_id: speaker_id,
						agenda_item_id: this.editableAgendaItem.id,
					}
				)

				// find and replace the updated agenda item
				const { data: updatedAgendaItem } = resp
				this.meeting.agenda_items = this.meeting.agenda_items.map(item => {
					if (item.id === updatedAgendaItem.id) {
						return updatedAgendaItem
					}
					return item
				})

				Vue.toasted.success('Agenda item change request submitted successfully')
			} catch (error) {
				handleResponseError('Error submitting agenda item change request ({error})')(error)
			} finally {
				after()
				this.$modalAgendaItemRequestChanges.hide()
			}
		},

		handleCloseRequestChangesForm() {
			this.$modalAgendaItemRequestChanges.hide()
		},

		handleRequestChanges(item) {
			this.editableAgendaItem = item
			this.$modalAgendaItemRequestChanges.show()
		},

		async handleApproveItem({ id }) {
			try {
				const { data } = await heyGovApi.put(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${id}`, {
					status: 'ok',
				})

				const updatedItem = this.meeting.agenda_items.find(i => i.id === id)
				if (updatedItem) {
					updatedItem.status = data.status
				}

				Vue.toasted.success('Agenda item approved successfully')
			} catch (error) {
				handleResponseError('Error approving agenda item ({error})')(error)
			}
		},

		openRequestFileModal() {
			this.$modalAgendaItemEdit.hide()
			this.$modalAgendaItemRequestFile.show()
		},

		async handleRequestFileSubmit({ comment, speaker_id, after }) {
			try {
				await heyGovApi.post(`${this.j.slug}/meetings/${this.meeting.pid}/agenda-item-request-changes`, {
					comment: comment,
					to_speaker_id: speaker_id,
					agenda_item_id: this.editableAgendaItem.id,
				})
				Vue.toasted.success('File request submitted successfully')
			} catch (error) {
				handleResponseError('Error submitting file request ({error})')(error)
			} finally {
				after()
				this.$modalAgendaItemRequestFile.hide()
				this.$modalAgendaItemEdit.show()
			}
		},

		handleCloseRequestFileForm() {
			this.$modalAgendaItemRequestFile.hide()
			this.$modalAgendaItemEdit.show()
		},

		async handleFileUpload({ files, itemId }, after) {
			if (!files.length) return

			const form = new FormData()
			for (const file of files) {
				const errStr = this.validateFileBeforeUpload(file, true)
				if (errStr) {
					alert(errStr)
					return
				}
				form.append('files', file)
			}

			try {
				const { data: newFilePaths } = await heyGovApi.post(
					`${this.j.slug}/meetings/${this.meeting.pid}/agenda-items/${itemId}/files`,
					form
				)

				const existing = this.meeting.agenda_items.find(item => item.id === itemId)
				existing.files.push(...newFilePaths)

				Vue.toasted.success('Agenda item files have been updated')
			} catch (error) {
				handleResponseError('Error uploading agenda item files ({error})')(error)
			} finally {
				after()
			}
		},
		alert(msg) {
			window.alert(msg)
		},
		openSettingsModal() {
			this.$modalMeetingSettings.show()
		},
		async handleSettingsSubmit() {
			Vue.toasted.success('Meeting settings updated')
			this.$modalMeetingSettings.hide()
		},
		handleCloseSettingsForm() {
			this.$modalMeetingSettings.hide()
		},
	},
}
</script>

