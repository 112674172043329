<template>
	<span title="Time conversions" data-bs-toggle="popover" data-bs-trigger="hover focus" data-bs-placement="top">{{
		date | dateLocal
	}}</span>
</template>

<script>
import { mapState } from 'vuex'
import { Popover } from 'bootstrap'

export default {
	name: 'DateTimezones',
	props: {
		date: {
			type: [Date, String],
			required: true,
		},
		timezones: {
			type: Array,
			//default: () => ['UTC', 'EST', 'CST', 'MST', 'PST'],
		},
	},
	data() {
		return {
			locale: 'en-US', // TODO get from person prefs or region settings
			localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			timezoneShort: {
				'America/Chicago': 'CDT',
				'Europe/Bucharest': 'EEST',
			},
			deviceLabel: 'computer',
		}
	},
	computed: {
		...mapState(['j', 'device']),
	},
	created() {
		if (['ios', 'android'].includes(this.device.platform)) {
			this.deviceLabel = 'phone'
		}
	},
	mounted() {
		const that = this

		new Popover(this.$el, {
			html: true,
			content() {
				const localTimezoneHtml = `<p class="mb-0">
                        <strong>${that.timezoneShort[that.localTimezone] || that.localTimezone} ${
					that.deviceLabel === 'phone' ? '📱' : '🖥️'
				}</strong>:
                        ${new Date(that.date).toLocaleString(that.locale, {
							dateStyle: 'short',
							timeStyle: 'short',
						})} 
                    </p>`

				return `
                    <p class="mb-2">
                        <strong>${that.timezoneShort[that.j.timezone] || that.j.timezone}</strong>:
                        ${new Date(that.date).toLocaleString(that.locale, {
							dateStyle: 'short',
							timeStyle: 'short',
							timeZone: that.j.timezone,
						})}
                    </p>
                    ${that.localTimezone !== that.j.timezone ? localTimezoneHtml : ''}
                `
			},
		})
	},
}
</script>
