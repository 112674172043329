<template>
	<div class="venue-availability">
		<div v-if="venue">
			<venue-header :venue="venue"></venue-header>

			<h2>Bulk import of reservations</h2>

			<div class="card mb-4">
				<div class="card-body">
					<div class="form-group">
						<label for="import file" class="form-label">Reservations source file (CSV)</label>

						<input
							type="file"
							id="import-file"
							class="form-control"
							accept=".csv"
							@change="handleFileUpload"
						/>
					</div>

					<hr class="bg-primary-100 my-4" />

					<p v-if="reservations.length" class="mb-2">
						Found <strong>{{ reservations.length }}</strong> reservations to import.
					</p>

					<div class="card-table mb-3">
						<table class="table table-hover">
							<thead>
								<tr>
									<th v-if="importOptions.venueNameMatching">Venue</th>
									<th>Guest name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Address</th>
									<th>Starts at</th>
									<th>Ends at</th>
									<th>Duration</th>
									<th>Price</th>
									<th>Deposit</th>
									<th>Message</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="reservation in reservations" :key="reservation.id">
									<th v-if="importOptions.venueNameMatching">
										<span v-if="reservation.venue">{{ reservation.venue.name }}</span>
										<span v-else class="badge bg-danger-50 text-danger-400">MISSING</span>
									</th>
									<td>{{ reservation.person_name }}</td>
									<td>
										<span v-if="reservation.person_email">{{ reservation.person_email }}</span>
										<small v-else class="text-neutral-400">-</small>
									</td>
									<td>{{ reservation.person_phone }}</td>
									<td>{{ reservation.person_address }}</td>
									<td>
										<span v-if="reservation.starts_at">
											{{
												new Date(reservation.starts_at).toLocaleString('en-US', {
													timeZone: j.timezone,
												})
											}}
										</span>
										<span v-else class="badge bg-danger-50 text-danger-400">MISSING</span>
									</td>
									<td>
										<span v-if="reservation.ends_at">
											{{
												new Date(reservation.ends_at).toLocaleString('en-US', {
													timeZone: j.timezone,
												})
											}}
										</span>
										<span v-else class="badge bg-danger-50 text-danger-400">MISSING</span>
									</td>
									<td>
										<span v-if="reservation.starts_at && reservation.ends_at">
											{{
												differenceInHours(
													new Date(reservation.ends_at),
													new Date(reservation.starts_at)
												)
											}}h
										</span>
										<small v-else class="text-danger-400">-</small>
									</td>
									<td>
										<span v-if="reservation.price">
											{{ numberToCurrency(reservation.price) }}
										</span>
										<span v-else class="badge bg-warning-50 text-warning-400">MISSING</span>
									</td>
									<td>
										<span v-if="reservation.deposit">
											{{ numberToCurrency(reservation.deposit) }}
										</span>
										<small v-else class="text-neutral-400">-</small>
									</td>
									<td>
										{{ reservation.message || '-' }}
									</td>
								</tr>
								<tr v-if="states.import === 'idle'">
									<td colspan="10" class="text-center py-5">
										<p class="lead mb-3">
											Import existing reservations for <strong>{{ venue.name }}</strong
											>, from a <code>.csv</code> file.
										</p>

										<p class="mb-1">
											Required fields: <code>name</code>, <code>starts_at</code>,
											<code>ends_at</code>
										</p>
										<p class="mb-1">
											Optional fields:
											<code class="text-neutral-400">email</code>,
											<code class="text-neutral-400">phone</code>,
											<code class="text-neutral-400">price</code>,
											<code class="text-neutral-400">deposit</code>,
											<code class="text-neutral-400">message</code>,
											<code class="text-neutral-400">created_at</code>
										</p>
										<p class="mb-0">
											Download a
											<a
												href="https://edge.heygov.com/sample-data/heygov-venue-reservations.csv"
												target="_blank"
												download
												>CSV reservations template</a
											>.
										</p>

										<div v-if="isStaff" class="bg-danger-50 p-3 rounded-1 mt-4">
											<div class="form-check">
												<input
													class="form-check-input"
													type="checkbox"
													v-model="importOptions.venueNameMatching"
													id="import-venue-name"
												/>
												<label class="form-check-label" for="import-venue-name">
													Enable experimental venue name matching
												</label>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<template v-if="states.import !== 'idle'">
						<form v-if="states.import === 'uploaded'" @submit.prevent="importReservations">
							<p class="card-text">
								<button class="btn btn-primary me-2">
									Import {{ reservations.length }} reservations
								</button>

								<span v-if="reservationsWithErrors.length" class="text-danger-400">
									{{ reservationsWithErrors.length }} reservations have missing data and cannot be
									imported.
								</span>
							</p>
						</form>

						<div v-else-if="states.import === 'importing'">
							<button type="button" class="btn btn-primary" disabled>
								<span class="spinner-border spinner-border-sm"></span>
								Importing...
							</button>
						</div>
						<div v-else-if="states.import === 'imported'">
							<ul>
								<li v-for="booking in imported" :key="booking">
									<router-link :to="`/${j.slug}/venues/${venue.slug}/bookings/${booking}`">{{
										booking
									}}</router-link>
								</li>
							</ul>
							<p class="card-text text-success-400">Success, these bookings are imported</p>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div v-else class="text-center py-5" role="status">
			<span class="spinner-border"></span>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { differenceInHours } from 'date-fns'

import heyGovApi from '@/api.js'
import { handleResponseError, numberToCurrency } from '@/utils.js'

import VenueHeader from './VenueHeader.vue'

export default {
	name: 'VenueImport',
	metaInfo() {
		return {
			title: `Import reservations into ${this.venue?.name || 'venue'}`,
		}
	},
	components: { VenueHeader },
	data() {
		return {
			states: {
				venue: 'loading',
				import: 'idle',
			},
			venue: null,
			importFile: null,
			reservations: [],
			imported: [],

			importOptions: {
				venueNameMatching: false,
			},
		}
	},
	computed: {
		...mapState(['j', 'authPerson']),
		...mapGetters(['currentRole', 'isStaff']),
		reservationsWithErrors() {
			return this.reservations.filter(r => !r.starts_at || !r.ends_at || !r.person_name)
		},
	},
	created() {
		this.getVenue()
	},
	methods: {
		differenceInHours,
		numberToCurrency,

		// Get Venue
		getVenue() {
			this.states.venue = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}`).then(
				({ data }) => {
					this.venue = data
					this.states.venue = 'loaded'
				},
				error => {
					this.states.venue = 'error'
					Vue.toasted.error(`Error loading venue info (${error.response.data.message})`)
				}
			)
		},

		handleFileUpload($event) {
			this.states.import = 'uploading'
			this.reservations = []
			this.importFile = $event.target.files[0]

			const data = new FormData()
			data.append('file', this.importFile)

			if (this.importOptions.venueNameMatching) {
				data.append('venue', 1)
			}

			heyGovApi.post(`${this.j.slug}/venues/${this.venue.slug}/import-reservations`, data).then(
				({ data }) => {
					this.reservations.push(...data.reservations)

					this.states.import = 'uploaded'
				},
				error => {
					handleResponseError(`Error importing reservations ({error})`)(error)
					this.states.import = 'idle'
				}
			)
		},

		importReservations() {
			this.states.import = 'importing'

			const data = new FormData()
			data.append('file', this.importFile)

			if (this.importOptions.venueNameMatching) {
				data.append('venue', 1)
			}

			heyGovApi.post(`${this.j.slug}/venues/${this.venue.slug}/import-reservations?import=1`, data).then(
				({ data }) => {
					this.imported.push(...data.createdBookings)

					this.states.import = 'imported'
				},
				error => {
					handleResponseError(`Error importing reservations ({error})`)(error)
					this.states.import = 'error'
				}
			)
		},
	},
}
</script>
