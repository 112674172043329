const sortTable = (orderBy, defaultOrder, sorting) => {
	if (sorting.orderBy === orderBy) {
		// if the same column is clicked, reverse the sort order
		sorting.order = sorting.order === 'asc' ? 'desc' : 'asc'
	} else {
		// if a new column is clicked, start with the default order
		sorting.order = defaultOrder
	}

	sorting.orderBy = orderBy
}

const updatePageUrl = (pag, filters, router, pageName) => {
	let query = {}
	for (const filter in filters) {
		if (filters[filter]) {
			query[filter] = filters[filter]
		}
	}
	if (pag.page && pag.page != 1) {
		query.page = pag.page
	}
	router.replace({ path: `/heygov-admin/${pageName}`, query }).catch(() => {})
}

export { sortTable, updatePageUrl }
