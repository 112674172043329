<template>
	<div class="page-venue-admin-booking">
		<div v-if="venue" class="row justify-content-center">
			<div class="col-lg-8 col-md-10">
				<h3 class="mb-3">
					<router-link :to="`/${j.slug}/venues`">Venues</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted mx-2" />
					<router-link :to="`/${j.slug}/venues/${venue.slug}?date=${$route.params.date}`">{{
						venue.name
					}}</router-link>
					<font-awesome-icon :icon="['fas', 'angle-right']" class="text-muted ms-2" />
					Book for
					{{
						($route.params.date.includes(':') ? $route.params.date.split(':').at(0) : $route.params.date)
							| dateLocal
					}}
				</h3>

				<div class="card mb-4">
					<div class="row g-0">
						<div class="col-5">
							<img :src="venue.main_image" class="img-fluid rounded-start m-1" :alt="venue.name" />
						</div>
						<div class="col-7">
							<div class="card-body px-3 py-2">
								<h4 class="card-title">{{ venue.name }}</h4>

								<p class="card-text mb-0">
									<small class="text-secondary">From</small>
								</p>
								<p class="card-text mb-1">
									<strong>{{ bookingInfo.starts_at_date | dateTimeLocal }}</strong>
								</p>

								<p class="card-text mb-0">
									<small class="text-secondary">Until</small>
								</p>
								<p class="card-text mb-1">
									<strong>{{ bookingInfo.ends_at_date | dateTimeLocal }}</strong>
								</p>

								<div class="row">
									<div class="col">
										<p class="card-text mb-0">
											<small class="text-secondary">Deposit</small>
										</p>
										<p class="card-text mb-0">
											<strong>{{ bookingInfo.deposit | currency }}</strong>
										</p>
									</div>
									<div class="col">
										<p class="card-text mb-0">
											<small class="text-secondary">Price</small>
										</p>
										<p class="card-text mb-0">
											<strong>{{ bookingInfo.price | currency }}</strong>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="card mb-3">
					<div class="card-body">
						<h3 class="mb-3 text-muted">Who is this reservation for?</h3>

						<div v-if="booking && booking.person_id" class="row align-items-center">
							<div class="col">
								<person-link :id="booking.person_id" :avatar="30"></person-link>
							</div>
							<div class="col-auto">
								<button class="btn btn-sm btn-light" :disabled="true">Change</button>
							</div>
						</div>

						<div v-else>
							<div class="form-group">
								<label class="form-label" for="booking-person-search">Guest</label>

								<people-search
									@person="setBookingPerson"
									@personQuery="setBookingPersonQuery"
									:limit="7"
									id="booking-person-search"
									:placeholder="'Type name or email address'"
								></people-search>
							</div>

							<form @submit.prevent="setPerson" class="mt-3">
								<div v-if="person && person.id" class="bg-success-lighter rounded-1 py-2 px-3 mb-3">
									Reservation notifications will be sent to <code>{{ person.email }}</code
									><span v-if="person.phone">
										and <code>{{ person.phone }}</code></span
									>. <small class="disabled text-gray cursor-not-allowed">Edit details</small>
								</div>
								<div v-else-if="person">
									<div class="alert alert-warning text-dark">
										"{{ personQuery }}" is not in the system yet. Please fill their info below.
									</div>

									<div class="form-group mb-3">
										<label class="form-label" for="booking-person-name">Full name</label>

										<input
											type="text"
											class="form-control"
											id="booking-person-name"
											v-model="person.name"
											placeholder="Full name please"
											required
										/>
									</div>

									<div class="form-group mb-3">
										<label class="form-label" for="booking-person-email">Email</label>

										<input
											type="email"
											class="form-control"
											id="booking-person-email"
											v-model="person.email"
											placeholder="Email address"
										/>
									</div>

									<div class="form-group mb-3">
										<label class="form-label" for="booking-person-phone">Phone</label>

										<input
											type="tel"
											class="form-control"
											id="booking-person-phone"
											v-model="person.phone"
											placeholder="Phone number"
										/>
									</div>
								</div>

								<p class="card-text text-center">
									<button
										class="btn btn-primary px-5"
										:disabled="!person || states.booking === 'loading'"
									>
										Continue
									</button>
								</p>
							</form>
						</div>
					</div>
				</div>

				<form v-if="booking" @submit.prevent="bookingUpdate">
					<div v-if="venue.fields.length" class="card mb-3">
						<div class="card-body">
							<h3 class="mb-3 text-neutral-400">
								Reservation info <small class="text-neutral-300">(to ask the guest)</small>
							</h3>

							<form @submit.prevent="confirmBooking">
								<!-- Display venue fields -->
								<div v-for="field in venue.fields" :key="`vf-${field.id}`" class="form-group mb-3">
									<label class="form-label" :for="`fsf-${field.id}`"
										>{{ field.label }}
										<small v-if="field.required" class="text-danger">*</small></label
									>

									<component
										:is="field.type"
										:currentField="field"
										:venueBooking="booking"
										class="element"
										@update="fieldsUpdate"
									></component>

									<span v-if="field.help" class="form-text">{{ field.help }}</span>
								</div>
							</form>
						</div>
					</div>

					<div class="card mb-2">
						<div class="card-body">
							<h5>Payment</h5>

							<div class="bg-light rounded-1 p-2 mb-3">
								<p class="mb-2">
									Price:
									<strong v-if="booking.price === bookingInfo.price">{{
										booking.price | currency
									}}</strong>
									<span v-else>
										<strike class="text-muted">{{ bookingInfo.price | currency }}</strike>
										<strong class="mx-2">{{ booking.price | currency }}</strong>
										<span
											v-for="priceCondition in priceRules.filter(
												p => !p.type.endsWith('-deposit')
											)"
											:key="priceCondition.id"
											class="badge bg-info-lighter text-info me-2"
											><strong>{{ priceConditionText(priceCondition) }}</strong> ({{
												priceCondition.name
											}})</span
										>
									</span>
								</p>
								<p class="mb-2">
									Deposit:
									<strong v-if="booking.deposit === bookingInfo.deposit">{{
										booking.deposit | currency
									}}</strong>
									<span v-else>
										<strike class="text-muted">{{ bookingInfo.deposit | currency }}</strike>
										<strong class="mx-2">{{ booking.deposit | currency }}</strong>
										<span
											v-for="priceCondition in priceRules.filter(p =>
												p.type.endsWith('-deposit')
											)"
											:key="priceCondition.id"
											class="badge bg-info-lighter text-info me-2"
											><strong>{{ priceConditionText(priceCondition) }}</strong> ({{
												priceCondition.name
											}})</span
										>
									</span>
								</p>
								<p class="lead mb-0">
									Subtotal:
									<strong>{{ (booking.price + booking.deposit) | currency }}</strong>
								</p>
							</div>

							<p class="mb-2">How does {{ person.name }} wants to pay?</p>

							<ul class="nav nav-tabs mb-3">
								<li class="nav-item">
									<span
										class="nav-link"
										:class="{ active: paymentData.method === 'card_present' }"
										@click="paymentData.method = 'card_present'"
										>Card reader</span
									>
								</li>
								<li class="nav-item">
									<span
										class="nav-link"
										:class="{ active: paymentData.method === 'cash' }"
										@click="paymentData.method = 'cash'"
										>Cash</span
									>
								</li>
								<li class="nav-item">
									<span
										class="nav-link"
										:class="{ active: paymentData.method === 'check' }"
										@click="paymentData.method = 'check'"
										>Check</span
									>
								</li>
								<li class="nav-item">
									<span
										class="nav-link"
										:class="{ active: paymentData.method === 'card' }"
										@click="paymentData.method = 'card'"
										>Card payment request</span
									>
								</li>
							</ul>

							<div v-if="paymentData.method === 'card_present'">
								<p class="mb-2">
									Payment fee: <strong>{{ paymentFee | currency }}</strong>
									<small class="ms-1">(2.7% + 5¢)</small>
								</p>
								<p class="lead">
									Total:
									<strong>{{ priceTotal | currency }}</strong>
								</p>
							</div>
							<div v-else-if="paymentData.method === 'cash'">
								<div class="form-group mb-3">
									<label class="form-label" for="booking-payment-note">Payment note</label>

									<input
										type="text"
										class="form-control"
										id="booking-payment-note"
										v-model="paymentData.note"
										placeholder="Add a payment note"
									/>
								</div>

								<div class="form-group form-check mb-3">
									<input
										type="checkbox"
										class="form-check-input"
										id="booking-payment-confirm"
										required
									/>
									<label class="form-check-label" for="booking-payment-confirm"
										>Confirm that cash payment has been taken</label
									>
								</div>
							</div>
							<div v-else-if="paymentData.method === 'check'">
								<div class="form-group mb-3">
									<label class="form-label" for="booking-payment-note">Check number</label>

									<input
										type="text"
										class="form-control"
										id="booking-payment-note"
										v-model="paymentData.note"
										required
										placeholder="What's the check number used to pay?"
									/>
								</div>

								<div class="form-group form-check mb-3">
									<input
										type="checkbox"
										class="form-check-input"
										id="booking-payment-confirm"
										required
									/>
									<label class="form-check-label" for="booking-payment-confirm"
										>Confirm that check has been taken</label
									>
								</div>
							</div>
							<div v-else-if="paymentData.method === 'card'">
								<p class="mb-2">
									Payment fee: <strong>{{ paymentFeeOnline | currency }}</strong>
									<small class="ms-1">(fee is 2.9% + 30¢)</small>
								</p>
								<p class="lead mb-2">
									Total:
									<strong>{{ priceTotal | currency }}</strong>
								</p>
								<p>
									A payment link will be sent to {{ person.name }}'s email and phone, from where they
									can pay securely. The reservation will be confirmed after they pay.
								</p>
							</div>
							<div v-else class="alert alert-warning text-dark">
								Choose a payment method to start collecting payment
							</div>

							<p class="card-text text-center">
								<button class="btn btn-primary" :disabled="!paymentData.method">
									Pay and confirm reservation
								</button>
							</p>
						</div>
					</div>
				</form>
			</div>
		</div>
		<div v-else class="text-center py-5" role="status">
			<span class="spinner-border"></span>
		</div>

		<!-- Card reader payment -->
		<div
			class="modal fade"
			id="modal-card-reader-payment"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			tabindex="-1"
			aria-hidden="true"
		>
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">
							Payment with Card Reader
						</h5>
					</div>
					<div v-if="payment" class="modal-body">
						<p class="mb-3">
							Your card reader will work if your venue department ({{
								departments.find(d => d.id === venue.department_id).name
							}}) has the card reader connected in <strong class="text-neutral-400">HeyGov Pay</strong>.
						</p>
						<hr class="my-3 bg-primary-100" />

						<div class="mb-3">
							Card readers:
							<span
								v-for="(reader, index) in terminalReaders"
								:key="reader.id"
								class="badge mx-1"
								:class="index ? 'bg-light text-dark' : 'bg-primary-50 text-primary-400'"
								>{{ reader.label }}</span
							>
							<span v-if="isStaff" class="badge bg-light text-gray mx-1">{{ terminalReaderState }}</span>
						</div>

						<template v-if="terminalError">
							<div class="alert alert-danger mb-3">{{ terminalError }}</div>

							<p class="text-center">
								<button class="btn btn-sm btn-outline-dark" @click="stripeTerminalDiscoverReaders">
									Try again
								</button>

								<button class="btn btn-sm btn-outline-dark mx-1" data-bs-dismiss="modal">
									Cancel payment
								</button>
							</p>
						</template>

						<p
							v-else-if="['discovering', 'connecting'].includes(terminalReaderState)"
							class="text-center py-4"
						>
							Connecting to card readers <span class="spinner-border spinner-border-sm"></span>
						</p>

						<div v-else-if="terminalReaderState === 'no-readers'" class="">
							<p class="text-center card-text my-5">There are no connected card readers.</p>
							<p class="text-center card-text">
								<a
									class="btn btn-sm btn-outline-dark mx-2"
									href="https://heygov.com/heygov-shop#POS-Terminal"
									target="_blank"
									>Buy a card reader</a
								>
								or
								<code>Connect a card reader in HeyGov Pay</code>
							</p>
						</div>

						<div v-else>
							<div class="bg-light rounded-1 px-3 py-2 mb-3">
								<p class="mb-1">
									Amount:
									<strong>{{ payment.amount | currency }}</strong>
								</p>
								<p class="mb-1">
									Payment fee:
									<strong>{{ payment.fee | currency }}</strong>
								</p>
								<p class="lead mb-0">
									Total:
									<strong>{{ payment.total_paid | currency }}</strong>
								</p>
							</div>

							<ol v-if="payment.status === 'requires_payment_method'" class="mb-3">
								<li>
									Awaiting card
									<span
										v-if="this.states.terminalCollectPayment === 'loading'"
										class="spinner-border spinner-border-sm"
									></span>
									<button
										v-if="this.states.terminalCollectPayment === 'loading'"
										class="btn btn-sm btn-outline-dark ms-2"
										@click="cancelPaymentInTerminal"
									>
										Cancel
									</button>
								</li>
								<li>Capturing payment</li>
								<li>Payment done</li>
							</ol>
							<ol v-else-if="payment.status === 'requires_capture'" class="mb-3">
								<li>Awaiting card ✅</li>
								<li>
									Capturing payment
									<span
										v-if="!paymentCardReaderError"
										class="spinner-border spinner-border-sm"
									></span>
								</li>
								<li>Payment done</li>
							</ol>
							<ol v-else-if="payment.status === 'succeeded'" class="mb-3">
								<li>Awaiting card ✅</li>
								<li>Capturing payment ✅</li>
								<li>Payment done ✅</li>
							</ol>

							<div v-if="paymentCardReaderError">
								<div class="alert alert-danger" v-html="paymentCardReaderError"></div>
							</div>

							<div v-if="this.states.terminalCollectPayment === 'error'" class="text-center">
								<button class="btn btn-sm btn-primary mx-1" @click="stripeTerminalCollectPayment">
									Try again
								</button>
								<button class="btn btn-sm btn-outline-dark mx-1" data-bs-dismiss="modal">
									Cancel payment
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { differenceInHours, differenceInDays, format, parseISO } from 'date-fns'
import { Modal } from 'bootstrap'

import heyGovApi from '@/api.js'
import { isEmail } from '@/utils.js'
import { calculatePrice } from '@/actions/forms.js'
import { stripeFee } from '@/actions/payments.js'

import TextInputElement from '@/components/form-builder/TextInputElement'
import TextareaInputElement from '@/components/form-builder/TextareaInputElement'
import TextElement from '@/components/form-builder/TextElement'
import NumberInputElement from '@/components/form-builder/NumberInputElement'
import SelectListElement from '@/components/form-builder/SelectListElement'
import RadioButtonElement from '@/components/form-builder/RadioButtonElement'
import CheckboxElement from '@/components/form-builder/CheckboxElement'
import DatePickerElement from '@/components/form-builder/DatePickerElement'
import TimeElement from '@/components/form-builder/TimeElement'
import FileUploadElement from '@/components/form-builder/FileUploadElement'
import EmailInputElement from '@/components/form-builder/EmailInputElement'
import PhoneInputElement from '@/components/form-builder/PhoneInputElement'
import SignatureElement from '@/components/form-builder/SignatureElement'
import PaymentElement from '@/components/form-builder/PaymentElement'
import AddressInputElement from '@/components/form-builder/AddressInputElement'
import NameInputElement from '@/components/form-builder/NameInputElement'
import PeopleSearch from '@/components/PeopleSearch.vue'
import PersonLink from '@/components/PersonLink.vue'

export default {
	components: {
		TextInputElement,
		TextareaInputElement,
		TextElement,
		NumberInputElement,
		SelectListElement,
		RadioButtonElement,
		CheckboxElement,
		DatePickerElement,
		TimeElement,
		FileUploadElement,
		EmailInputElement,
		PhoneInputElement,
		SignatureElement,
		PaymentElement,
		AddressInputElement,
		NameInputElement,
		PeopleSearch,
		PersonLink,
	},
	data() {
		return {
			states: {
				venue: 'loading',
				booking: 'idle',
				paymentCardReader: 'idle',
				terminalCollectPayment: 'idle',
			},
			venue: null,

			personQuery: null,
			person: null,

			bookingInfo: {
				type: null,
				date: null,
				starts_at: null,
				ends_at: null,
				starts_at_date: null,
				ends_at_date: null,
				slot: null,
				price: 0,
				deposit: 0,
			},
			booking: null,
			paymentData: {
				method: '',
				note: '',
			},
			payment: null,

			priceRules: [],

			// Stripe Terminal
			terminal: null,
			terminalError: '',
			terminalReaderConnected: false,
			terminalReaderState: 'discovering',
			terminalReaders: [],
			paymentCardReaderError: '',
		}
	},
	computed: {
		...mapState(['j', 'departments', 'stripePublishableTestKey', 'stripePublishableKey']),
		...mapGetters(['auth', 'isStaff']),
		paymentFeeOnline() {
			const amount = Number(this.booking.price) + Number(this.booking.deposit)
			return stripeFee(amount, 'online')
		},
		paymentFee() {
			const amount = Number(this.booking.price) + Number(this.booking.deposit)
			return stripeFee(amount, 'in-person')
		},
		priceTotal() {
			// TODO other calculations based on fields & conditions

			return Number(this.booking.price) + Number(this.booking.deposit) + Number(this.paymentFee)
		},
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.loadVenue()
	},
	mounted() {
		if (!('Stripe' in window)) {
			let StripeScript = document.createElement('script')
			StripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
			document.head.appendChild(StripeScript)
		}

		if (!('StripeTerminal' in window)) {
			let stripeScript = document.createElement('script')
			stripeScript.setAttribute('src', 'https://js.stripe.com/terminal/v1/')
			document.head.appendChild(stripeScript)
		}

		this.$modalCardReader = new Modal(document.getElementById('modal-card-reader-payment'))
	},
	methods: {
		loadVenue() {
			this.states.venue = 'loading'

			heyGovApi.get(`${this.j.slug}/venues/${this.$route.params.venueSlug}?expand=fields`).then(
				({ data }) => {
					this.bookingInfo.date = this.$route.params.date

					if (data.reservation_mode === 'slots') {
						const slot = data.slots.find(slot => slot.id == this.$route.params.slot)
						this.bookingInfo.type = 'slot'
						this.bookingInfo.slot = slot

						this.bookingInfo.starts_at = slot.starts_at
						this.bookingInfo.ends_at = slot.ends_at
						this.bookingInfo.price = slot.price
						this.bookingInfo.deposit = slot.deposit

						if (slot.type === 'dates') {
							this.bookingInfo.starts_at_date = parseISO(slot.date_starts_at)
							this.bookingInfo.ends_at_date = parseISO(slot.date_ends_at)
						} else if (this.$route.params.date.includes(':')) {
							const dates = this.$route.params.date.split(':')
							this.bookingInfo.starts_at_date = parseISO(`${dates[0]} ${slot.starts_at}`)
							this.bookingInfo.ends_at_date = parseISO(`${dates[1]} ${slot.ends_at}`)

							let days = differenceInDays(
								new Date(`${dates[1]} 23:59:59`),
								new Date(`${dates[0]} 00:00:00`)
							)

							if (slot.ends_at > slot.starts_at) {
								days++
							}

							this.bookingInfo.price = slot.price * days
						} else {
							this.bookingInfo.starts_at_date = parseISO(`${this.$route.params.date} ${slot.starts_at}`)
							this.bookingInfo.ends_at_date = parseISO(`${this.$route.params.date} ${slot.ends_at}`)
						}
					} else {
						const times = this.$route.params.slot.split('-')
						this.bookingInfo.type = 'time'
						this.bookingInfo.starts_at = times[0]
						this.bookingInfo.ends_at = times[1]
						this.bookingInfo.starts_at_date = parseISO(`${this.$route.params.date} ${times[0]}`)
						this.bookingInfo.ends_at_date = parseISO(`${this.$route.params.date} ${times[1]}`)
						this.bookingInfo.deposit = data.availability.deposit || 0

						const hours = differenceInHours(this.bookingInfo.ends_at_date, this.bookingInfo.starts_at_date)

						if (hours >= data.availability.min && hours <= data.availability.max) {
							const dayName = format(this.bookingInfo.starts_at_date, 'E')
							const day = data.availability.days[dayName]
							this.bookingInfo.price = day.price * hours
						} else {
							Vue.toasted.error('Invalid booking time')
							this.state = 'error'
							return
						}
					}

					data.fields = data.fields.map(f => {
						if (f.type === 'CheckboxElement' || f.type === 'FileUploadElement') {
							f.value = JSON.parse(f.value)
						}

						return f
					})

					this.venue = data
					this.states.venue = 'loaded'

					//this.startBooking()
				},
				error => {
					Vue.toasted.error(`Erorr loading venue data (${error.message})`)
					this.states.venue = 'error'
				}
			)
		},

		setBookingPersonQuery(query) {
			this.personQuery = query
			this.person = null

			if (query && isEmail(query)) {
				this.person = {
					email: query,
					name: '',
					phone: '',
				}
			} else if (query) {
				this.person = {
					email: '',
					name: query,
					phone: '',
				}
			}
		},
		setBookingPerson(person) {
			if (person) {
				this.person = person
			} else {
				this.person = null
				this.personQuery = ''
			}
		},
		setPerson() {
			if (!this.person) {
				Vue.toasted.error('Something is not right here')
			} else if (this.person.id) {
				this.startBooking(this.person.id)
			} else {
				this.states.booking = 'loading'

				heyGovApi.post(`${this.j.slug}/people`, this.person).then(
					({ data }) => {
						this.startBooking(data.id)
					},
					error => {
						Vue.toasted.error(`Error adding person to HeyGov (${error.message})`)
					}
				)
			}
		},

		startBooking(person_id) {
			this.states.booking = 'loading'

			heyGovApi
				.post(`${this.j.slug}/venue-bookings`, {
					venue_id: this.venue.id,
					date: this.$route.params.date,
					slot: this.$route.params.slot,
					person_id,
				})
				.then(
					({ data }) => {
						this.booking = data
						this.states.booking = 'loaded'
						//this.startPayment()
					},
					error => {
						this.states.booking = 'error'
						Vue.toasted.error(`Error starting the booking (${error.message})`)
					}
				)
		},

		fieldsUpdate({ field, value }) {
			// early exit
			if (!this.booking?.fields_answers || !this.venue?.fields) {
				return
			}

			// update booking answers, to store in db
			this.booking.fields_answers[field.id] = value

			// calculate price
			const { price, deposit, matchedRules } = calculatePrice(
				Number(this.bookingInfo.price),
				Number(this.bookingInfo.deposit),
				this.venue.price_conditions.filter(p => p.slot === null || p.slot === this.bookingInfo.slot?.id),
				this.venue.fields,
				this.booking.fields_answers
			)
			this.priceRules = matchedRules

			// if price is changed, update Payment ref
			if (this.booking.price != price || this.booking.deposit != deposit) {
				if (this.booking.price != price) {
					this.booking.price = price
				}
				if (this.booking.deposit != deposit) {
					this.booking.deposit = deposit
				}
			}
		},
		priceConditionText(priceCondition) {
			let text = priceCondition.type === 'sub' ? '-' : '+'

			if (priceCondition.amountType === 'fixed') {
				text += `$${priceCondition.amount}`
			} else if (priceCondition.amountType === 'percentage') {
				text += `${priceCondition.amount}%`
			}

			return text
		},

		async bookingUpdate() {
			Vue.toasted.show('Starting with payment')

			const paymentDepartment = this.departments.find(d => d.id === this.venue.department_id)

			if (['cash', 'check'].includes(this.paymentData.method)) {
				const payment_method = {
					type: this.paymentData.method,
				}
				payment_method[this.paymentData.method] = {
					note: this.paymentData.note,
				}

				heyGovApi
					.post(`${this.j.slug}/payments?by=staff`, {
						platform: 'heygov',
						department_id: this.venue.department_id,
						person_id: this.booking.person_id,
						amount: this.booking.price,
						description: this.paymentData.note,
						source: this.booking.uuid,
						payment_method,
					})
					.then(
						({ data }) => {
							Vue.toasted.success('Reservation payment succeeded')
							this.confirmBooking(data.id)
						},
						error => {
							Vue.toasted.error(`Error storing payment (${error.message})`)
						}
					)
			} else if (this.paymentData.method === 'card_present') {
				if (!paymentDepartment.stripe_account) {
					Vue.toasted.error('Stripe account is not connected to this department')
					return
				}

				const bookingDate = new Date(this.booking.starts_at)

				const { data } = await heyGovApi.post(`${this.j.slug}/payments?by=staff`, {
					department_id: this.venue.department_id,
					person_id: this.booking.person_id,
					amount: this.booking.price + this.booking.deposit,
					fee: this.paymentFee,
					description: `${this.venue.name} - ${bookingDate.toLocaleDateString('en-US', {
						timeZone: this.j.timezone,
					})}`,
					source: this.booking.pid || this.booking.uuid,
					payment_method_types: 'card_present',
					capture_method: 'manual',
				})

				this.payment = data

				Vue.toasted.show('Starting payment with Card Reader')

				this.$modalCardReader.show()
				this.stripeTerminalStart()

				//todo open card reader
			} else {
				Vue.toasted.error('Payment method not supported, yet')
			}
		},
		confirmBooking(payment_id) {
			const answers = {}

			for (const field of this.venue.fields) {
				answers[field.id] = field.value
			}

			heyGovApi
				.post(`${this.j.slug}/venue-bookings/${this.booking.uuid}/review-approved`, {
					message: 'Reservation added manually',
					price: this.booking.price,
					deposit: this.booking.deposit,
					payment_id,
					fields_answers: answers,
				})
				.then(
					() => {
						Vue.toasted.success('Reservation is confirmed')
						this.$router.push(`/${this.j.slug}/venues/${this.venue.slug}/bookings/${this.booking.uuid}`)
					},
					error => {
						Vue.toasted.error(`Error confirming booking (${error.message})`)
						this.states.booking = 'error'
					}
				)
		},

		stripeTerminalStart() {
			this.paymentCardReaderError = ''

			if (this.terminalReaderState !== 'connected') {
				this.terminal = window.StripeTerminal.create({
					onFetchConnectionToken: this.stripeTerminalConnectionToken,
					onUnexpectedReaderDisconnect() {
						// In this function, your app should notify the user that the reader disconnected.
						// You can also include a way to attempt to reconnect to a reader.
						this.terminalError = 'Disconnected from reader'
					},
					onConnectionStatusChange(status) {
						console.log('terminal status changed', status)
					},
				})

				this.stripeTerminalDiscoverReaders()
			}
		},
		cancelPaymentInTerminal() {
			this.terminal.cancelCollectPaymentMethod().then(() => {
				this.paymentCardReaderError = 'Payment was canceled'
				this.$modalCardReader.hide()
			})
		},
		async stripeTerminalConnectionToken() {
			const re = await heyGovApi(
				`${this.j.slug}/departments/${this.venue.department_id}/stripe-terminal-connection-token`
			)

			return re.data.secret
		},
		stripeTerminalDiscoverReaders() {
			var config = {
				simulated: ['localhost'].includes(window.location.hostname),
			}

			this.terminalError = ''
			this.terminalReaders = []
			this.terminalReaderState = 'discovering'

			this.terminal.discoverReaders(config).then(discoverResult => {
				if (discoverResult.error) {
					this.terminalError = discoverResult.error
				} else if (discoverResult.discoveredReaders.length === 0) {
					this.terminalReaderState = 'no-readers'
				} else {
					this.terminalReaders = discoverResult.discoveredReaders
					this.stripeTerminalConnectReader(this.terminalReaders[0])
				}
			})
		},
		stripeTerminalConnectReader(reader, cancelCurrentPaymentCollect = false) {
			if (cancelCurrentPaymentCollect) {
				this.cancelPaymentInTerminal()
			}

			this.terminalReaderState = 'connecting'

			this.terminal.connectReader(reader).then(connectResult => {
				if (connectResult.error) {
					this.terminalReaderState = 'error'
					this.terminalError = `Error connecting to "${reader.label}". ${connectResult.error.message}`
				} else {
					this.terminalReaderState = 'connected'
					this.terminalReaderConnected = reader.id
					console.log('terminal.connectReader', connectResult)
					this.stripeTerminalCollectPayment()
				}
			})
		},

		stripeTerminalCollectPayment() {
			//this.terminal.setSimulatorConfiguration({ testCardNumber: '4242424242424242' })
			//this.terminal.setSimulatorConfiguration({ testCardNumber: '4000000000009995' })
			this.states.terminalCollectPayment = 'loading'
			this.paymentCardReaderError = ''

			this.terminal.collectPaymentMethod(this.payment.paymentIntent.client_secret).then(result => {
				console.log('terminal.collectPaymentMethod', result)

				if (result.error) {
					this.paymentCardReaderError = result.error
					this.states.terminalCollectPayment = 'error'
				} else {
					this.terminal.processPayment(result.paymentIntent).then(result => {
						console.log('terminal.processPayment', result)

						if (result.error) {
							let errorCode = ''

							/* if (result.error.decline_code) {
								errorCode += ` (${result.error.decline_code})`
							}

							if (result.error.doc_url) {
								errorCode += ` <a href="${result.error.doc_url}" target="_blank">See detailed error info</a>`
							} */

							this.paymentCardReaderError = result.error.message + errorCode
							this.states.terminalCollectPayment = 'error'
						} else if (result.paymentIntent) {
							const cardCountry =
								result.paymentIntent.charges.data[0].payment_method_details.card_present.country

							if (cardCountry === 'US') {
								this.payment.status = result.paymentIntent.status
								this.payment.paymentIntent.status = result.paymentIntent.status
								this.states.terminalCollectPayment = 'success'
								this.terminalCapturePayment()
							} else {
								this.paymentCardReaderError = 'Payment error, non-US card used'
								this.states.terminalCollectPayment = 'error'
							}
						}
					})
				}
			})
		},
		terminalCapturePayment() {
			heyGovApi.post(`/${this.j.slug}/payments/${this.payment.uuid}/capture`).then(
				({ data }) => {
					this.payment.status = data.status
					this.$modalCardReader.hide()
					Vue.toasted.success('Card reader payment is successful')

					setTimeout(() => {
						this.confirmBooking(data.id)
					}, 3000)
				},
				error => {
					this.paymentCardReaderError = error.message
				}
			)
		},
	},
}
</script>
